import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage, DomEditor } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'
import { uploadBase64 } from '@api'
import classnames from 'classnames'
import { toBase64 } from '@utils/index'
import CONFIG from '@constant/config'
import { useSelector } from 'react-redux'
i18nChangeLanguage('en')

const uploadImg = async (image: File, selectedPropertyId) => {
  if (image) {
    const base64Image = await toBase64(image)
    const s3Image: any = await uploadBase64({
      base64Image,
      fileName: `Html/${selectedPropertyId}/${Date.now()}_${image.name}`,
      bucket: CONFIG.s3_Bucket,
    })
    return s3Image.imageUrl as string
  }
}

const useStyles = makeStyles((theme) => ({
  rootContainer: (props: { width?: number; height?: number }) => ({
    flexDirection: 'column',
    background: '#f7f7f7',
    dispaly: 'flex',
    border: '1px solid #BCBCBC',
    width: props?.width || '100%',
    height: props?.height || '100%',
    borderRadius: 4,
    marginTop: 24,
    overflowY: 'auto',
  }),
  paper: {
    padding: 20,
    marginBottom: 20,
  },
  editor: {
    width: 500,
    height: 800,
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 345,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Roboto',
    marginLeft: 8,
  },
  image: {
    width: 24,
    height: 24,
  },
}))

interface RichTextEditProps {
  onSubmit: (html: string, raw: any) => void
  width?: number
  height?: number
  htmlContent?: string
  rawContents?: string
  className?: string
  disabledEdit?: boolean
}

// api https://www.wangeditor.com/v5/for-frame.html#demo-2
const Index: React.FC<RichTextEditProps> = ({
  onSubmit,
  width,
  height,
  htmlContent,
  rawContents,
  disabledEdit,
  className,
}) => {
  const classes = useStyles({ width, height })
  const { selectedPropertyId } = useSelector((state) => state.profile)

  // editor 实例
  const [editor, setEditor] = useState<IDomEditor | null>(null)

  // 编辑器内容
  const [html, setHtml] = useState(htmlContent || '')

  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = {
    excludeKeys: [
      '|',
      'fontFamily',
      'emotion',
      // 'uploadImage',
      'group-video',
      'insertTable',
      'fullScreen',
    ],
  }

  // 编辑器配置
  const editorConfig: Partial<IEditorConfig> = {
    placeholder: '',
    readOnly: disabledEdit,
    MENU_CONF: {
      uploadImage: {
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 100 * 1024 * 1024, //100M
        async customUpload(file: File, insertFn) {
          const url = await uploadImg(file, selectedPropertyId)
          insertFn(url, '', '')
        },
      },
    },
  }

  useEffect(() => {
    onSubmit(html, '')
  }, [html])
  useEffect(() => {
    setHtml(htmlContent || '')
  }, [htmlContent])

  useEffect(() => {
    //@ts-ignore
    const toolbar = DomEditor.getToolbar(editor)
    const curToolbarConfig = toolbar?.getConfig()
    if (editor) {
      editor.on('modalOrPanelShow', (modalOrPanel) => {
        //隐藏 Image link
        const elements = document.getElementsByClassName('babel-container')
        for (let i = 0; i < elements?.length; i++) {
          //@ts-ignore
          if (elements[i].firstChild?.innerHTML === 'Image link') {
            //@ts-ignore
            elements[i].style.display = 'none'
          }
        }
      })
    }
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor])

  return (
    <Box className={classnames(classes.rootContainer, className)}>
      <Toolbar
        editor={editor}
        defaultConfig={toolbarConfig}
        mode="default"
        style={{ borderBottom: '1px solid #ccc' }}
      />
      <Editor
        defaultConfig={editorConfig}
        value={html}
        onCreated={setEditor}
        onChange={(editor) => {
          setHtml(editor.getHtml())
        }}
        mode="default"
        style={{ height: '500px', overflowY: 'hidden' }}
      />
      {/* 样式调整 */}
      {/* <RichTextViewer htmlContent={html}/> */}
    </Box>
  )
}

export default Index
