import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { ProductCategoriesItemITF, StateEnum } from '@/account/UpsellInfo'
import { CustomI18NITF } from '@activate-inc/activate-ui/dist/types/common.types'

export interface TableBoxConfig {
  label: string
  emptyText?: string
  source?: string
  isNumber?: boolean
  [key: string]: any
}

export interface HeaderConfig {
  label: string
  style?: React.CSSProperties
  className?: string
}

interface Props {
  className?: string
  height?: number
  headerWidth?: number
  header?: HeaderConfig[]
  data?: Array<{ BuildingName: CustomI18NITF; Products: ProductCategoriesItemITF[] }>
  config: TableBoxConfig[]
}
export const ColorMap = {
  [StateEnum.Active]: '#D8FFF7',
  [StateEnum.Implementing]: '#FFF6DA',
  [StateEnum.Interested]: '#FFDBDB',
  [StateEnum.Never]: '#F3F3F3',
}
const HEAD_STYLE = { color: '#211F1F', fontWeight: 700, justifyContent: 'center' }
const UpsellMatrix: React.FC<Props> = ({ className, header, headerWidth = 92, height, data, config }) => {
  const classes = useStyles()
  if (!data?.length) {
    return <Typography className={classes.empty}>No data yet</Typography>
  }

  return (
    <Box className={`${classes.table} ${className}`} style={{ height }}>
      {header && (
        <Box className={`${classes.tableColumn} ${classes.tableHeader}`} style={{ boxShadow: 'none' }}>
          <Box className={`${classes.headerCell} ${classes.cell}`} style={{ flex: 1, ...HEAD_STYLE }}>
            Category
          </Box>
          {header.map((item, index) => {
            const { style, label } = item
            return (
              <Box className={`${classes.headerCell} ${classes.cell}`} style={{ ...style }} key={index}>
                {label}
              </Box>
            )
          })}
        </Box>
      )}

      <Box className={`${classes.tableColumn} ${classes.tableHeader}`} style={{ width: headerWidth }}>
        <Box className={`${classes.headerCell} ${classes.cell}`} style={{ height: '60px', ...HEAD_STYLE }}>
          Product
        </Box>
        {config.map((item, index) => {
          return (
            <Box className={`${classes.headerCell} ${classes.cell}`} key={index} style={{ height: '60px' }}>
              {item.label}
            </Box>
          )
        })}
      </Box>

      <Box className={classes.tableBody}>
        {data.map((item, index: number) => {
          return (
            <Box className={classes.tableColumn} key={index}>
              <Box
                className={`${classes.headerCell} ${classes.cell}`}
                style={{ height: '60px', whiteSpace: 'nowrap', ...HEAD_STYLE }}
              >
                {item.BuildingName?.en}
              </Box>
              {config.map((v, index) => {
                const stateData = item.Products.find((item) => item.Text === v.label)
                const style = stateData?.State ? { backgroundColor: ColorMap[stateData?.State] } : {}
                return (
                  <Box className={classnames(classes.cell)} key={index} style={{ height: '60px', ...style }}>
                    {stateData?.State || 'N/A'}
                  </Box>
                )
              })}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  table: {
    borderRadius: 4,
    border: '1px solid #E8E8E8',
    display: 'flex',
    fontFamily: 'Roboto',
  },
  tableHeader: {
    boxShadow: '5px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    fontSize: '14px',
    color: '#211F1F',
    fontWeight: 500,
    minWidth: 127,
    borderBottom: '1px solid #E8E8E8',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  tableBody: {
    flex: 1,
    display: 'flex',
    overflowX: 'scroll',
  },
  tableColumn: {
    borderRight: '1px solid #E8E8E8',
    display: 'flex',
    flexDirection: 'column',
  },
  headerCell: {
    color: '#707070',
    fontWeight: 400,
    justifyContent: 'flex-start',
  },
  empty: {
    color: '#707070',
    fontFamily: 'Roboto',
    margin: '16px auto',
    textAlign: 'center',
  },
}))

export default UpsellMatrix
