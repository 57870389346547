import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useNotify } from 'react-admin'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { PropertyInfo, QRType, Template } from '@/types/entity'
import { makeStyles } from '@mui/styles'
import 'sanity-mobile-preview/dist/index.css'
import { INSPECTION_TYPE, QRLimit, ROUND_EQUIPMENT_TYPE, TAG_TYPE } from '@constant/qrcode'
import MobileFrame from '../components/MobileFrame/MobileFrame'
import { CommunicationMockData } from '@constant/mock'
import SnackBar from '@component/SnackBar'
import EditAllPanel from '@component/EditPanel/EditAllPanel'
import PickADesign from '@component/QRCode/PickADesign'
import CustomTextField from '@component/Form/CustomTextField'
import { useGetQRTypes } from '@hooks/useGetQRTypes'
import { getEntityTypes, SaveEntity } from '@api/index'
import { getPropertyDetail, getTenantList } from '@api'
import BackButton from '@component/BackButton/BackButton'
import { QRCodConfigContextValue, QRCodeConfigEnum, QRCodeFunctionType, QRCodeType } from '../types'
import { QRCodeProvider } from './QRCodeProvider'
import { MemoizedQRConfig } from './Inner'
import { DisabledBuildingQr, DynamicComponentsMaps } from './DynamicForm'
import EditPanelContextProvider, { EditPanelContext } from '@context/EditPanelContext'
import { FormDataContextProvider } from '@context/FormDataContext'
import { useLocation } from 'react-router'
import useSetState from '@hooks/useSetState'
import { ExpandMore } from '@mui/icons-material'

interface TabPanelProps {
  children?: React.ReactNode
  className: any
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={value === index ? {} : { width: 0, height: 0, overflow: 'hidden' }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box display="flex" sx={{ p: 1, width: '100%', flexDirection: 'column', boxSizing: 'border-box' }}>
        <>{children}</>
      </Box>
    </div>
  )
}

const DEFAULT = {
  floor: '',
  entityType: '',
  location: '',
  unit: '',
  areaId: '',
}
const BuilderQR = () => {
  const location = useLocation()
  const { bId } = useParams<Record<string, string>>()
  // @ts-ignore
  const { type, qrName } = useMemo(() => location.state || {}, [location.state])
  const isRound = type === QRLimit.Round
  const isCustomerPortal = type === 'CustomerPortal'
  const isInspection = type === QRLimit.Inspection
  const classes = useStyles()
  const notify = useNotify()
  const navigate = useNavigate()
  const user = useSelector((state) => state.profile.user)
  const { selectedGroupId } = useSelector((state) => state.profile)
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const qrCodeOptions = useRef<{ [key: string]: string | boolean }>({})
  const { selectedPropertyId } = useSelector((state) => state.profile)
  const [properties, setProperties] = React.useState<PropertyInfo>()
  const { panelData, editPanelIndex = -1 } = useContext(EditPanelContext)
  const [state, setState] = useSetState<Record<string, any>>({
    StopType: 'Equipment',
    InspectionType: '',
    Accessible: '',
    TenantId: '',
    TenantName: '',
    TenantList: [] as any[],
    TagType: TAG_TYPE[0].value,
    Skip1FA: false,
  })
  const [qrId, setQrId] = React.useState('')
  const [qrType, setQRType] = useState<QRType | null>()
  const [entity, setEntity] = useState<any>(DEFAULT)
  // @ts-ignore
  const { name, MechanicalSpace, EquipmentType, StopType, InspectionType, Accessible, TenantId, TenantName } = state
  const { TenantList, TagType, Skip1FA } = state
  const isBuilding = useMemo(() => entity?.entityType === 'Building', [entity?.entityType])
  const [templateEntity, setTemplateEntity] = useState<any>()
  const [NameEn, setNameEn] = React.useState('')
  const { selectedPropertyName } = useSelector((state) => state.profile)
  const [tabId, setTabId] = React.useState(1)
  const [types, setTypes] = useState<Record<string, any>[]>([])
  const [shouldGetEntity, setShouldGetEntity] = useState(false)
  const [template, setTemplate] = useState<Template | null>()
  const [refreshTime, setRefreshTime] = useState(0)
  const [generatorTime, setGeneratorTime] = useState(0)
  const [canGenerator, setCanGenerator] = useState(false)
  const setQRCodeRefreshTime = () => setRefreshTime(new Date().getTime())
  const { limit, qrNameLabel } = useMemo(() => {
    const specialQr = { limit: QRLimit.QrCode, qrNameLabel: 'QR Name' }
    if (type === QRLimit.Round) {
      specialQr.limit = QRLimit.Round
      specialQr.qrNameLabel = 'QR Name /Equipment Name'
    }
    if (type === QRLimit.Inspection) {
      specialQr.limit = QRLimit.Inspection
      specialQr.qrNameLabel = 'Inspection Name'
    }
    return specialQr
  }, [type])
  const { data: qrTypeList } = useGetQRTypes(limit, bId)
  const templateId = template?.acId || template?.TemplateId
  const isHub = qrType?.FunctionalityType === QRCodeFunctionType.QrHub
  const hiddenEntity =
    (qrType?.FunctionalityType &&
      [
        QRCodeFunctionType.CustomerFeedback,
        QRCodeFunctionType.Inventory,
        QRCodeFunctionType.ActivitySchedule,
        QRCodeFunctionType.Permit,
      ].includes(qrType.FunctionalityType)) ||
    isCustomerPortal
  const isBathroomService = qrType?.FunctionalityType === QRCodeFunctionType.BathroomService

  const defaultValue = {
    [QRCodeConfigEnum.QRType]: QRCodeType.Activate,
    [QRCodeConfigEnum.BGColor]: '#FFF',
    [QRCodeConfigEnum.FillColor]: '#000',
    [QRCodeConfigEnum.EyeColor]: '#000',
    [QRCodeConfigEnum.ECLevel]: '2',
    [QRCodeConfigEnum.EyeFrameShape]: '',
    [QRCodeConfigEnum.EyeBallShape]: '',
    [QRCodeConfigEnum.BodyShape]: '',
    [QRCodeConfigEnum.StripeText]: 'Activate',
    [QRCodeConfigEnum.Size]: '1000',
    [QRCodeConfigEnum.FileType]: 'eps',
    [QRCodeConfigEnum.BackgroundDimming]: 'rgba(255, 255, 255, .2)',
  }
  const qrCodeContextValue = useMemo<QRCodConfigContextValue>(
    () => ({
      getOptionValue: (name) => qrCodeOptions.current[name] || defaultValue[name],
      //@ts-ignore
      setOptionValue: (name: string, value: string) => {
        //@ts-ignore
        qrCodeOptions.current[name] = value
        setQRCodeRefreshTime()
      },
    }),
    []
  )

  const childRef = useRef({})

  const qrInfo = {
    HeaderLogo:
      (properties && properties.LogoFilename) ||
      'https://activate-demo-env.s3.us-east-2.amazonaws.com/logo/gr333n_white_logo.png',
  }

  const getFromProps = (functionalityType: string | null | undefined, showQrType: string | undefined) => {
    let newTemplate = template
    const editPanelButton = panelData?.Items?.[editPanelIndex]
    let qrTypeName = name
    if (isHub && editPanelButton) {
      newTemplate = editPanelButton.TemplateFormData as unknown as Template
      qrTypeName = editPanelButton?.Name
    }
    // @ts-ignore
    const baseProps = {
      functionalityType: functionalityType,
      qrType: showQrType,
      isHubPanel: true,
      language: 'en',
      selectedPropertyName,
      qrTypeName,
      logo: qrInfo.HeaderLogo,
      entity: templateEntity,
    }
    if (functionalityType === QRCodeFunctionType.QrHub) {
      Object.assign(baseProps, { data: newTemplate, bg: newTemplate?.HeaderImage })
    }
    switch (functionalityType) {
      case QRCodeFunctionType.Static:
      case QRCodeFunctionType.EmbeddedStaticUrl:
      case QRCodeFunctionType.PDF:
      case QRCodeFunctionType.Video: {
        // @ts-ignore
        const url = newTemplate?.Url
        return {
          ...baseProps,
          url: url,
          title: newTemplate?.Title,
          isPdf: QRCodeFunctionType.PDF === functionalityType,
        }
      }
      case QRCodeFunctionType.EmbeddedFile: {
        // @ts-ignore
        const url = newTemplate?.Url
        return { ...baseProps, url: url }
      }
      case QRCodeFunctionType.BathroomService:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.SimpleForm:
        return { ...baseProps, data: newTemplate?.Fields || newTemplate || [] }
      case QRCodeFunctionType.Html:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.SocialMedia:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.Feedback:
      case QRCodeFunctionType.CustomerFeedback:
        return { ...baseProps, data: newTemplate?.Questions }
      case QRCodeFunctionType.Purchase:
      case QRCodeFunctionType.Communication:
        return { ...baseProps, data: CommunicationMockData.Fields, title: CommunicationMockData.title }
      case QRCodeFunctionType.SupplierCheckin:
      case QRCodeFunctionType.StudentCheckin:
      case QRCodeFunctionType.GuestCheckin:
      case QRCodeFunctionType.TourCheckin:
      case QRCodeFunctionType.RoofAccess:
      case QRCodeFunctionType.RoofAccessCarousel:
      case QRCodeFunctionType.TenantHVAC:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          EnableSelfie: newTemplate?.EnableSelfie,
        }
      case QRCodeFunctionType.FieldEvent:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.JoinWifi:
        return { ...baseProps, isFormData: true }
      case QRCodeFunctionType.Vacancy:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          title: newTemplate?.Title?.en,
          btnText: 'SEND REQUEST',
          btnWidth: '50%',
        }
      case QRCodeFunctionType.GoogleMap:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.Renewal:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.MoveOut:
      case QRCodeFunctionType.MoveIn:
      case QRCodeFunctionType.MoveOutResident:
        return { ...baseProps, data: newTemplate, type: functionalityType }
      case QRCodeFunctionType.FrontDesk:
      case QRCodeFunctionType.DownloadApp:
      case QRCodeFunctionType.AEDInspection:
      case QRCodeFunctionType.FireExtinguisher:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.AngusServiceRequest:
      case QRCodeFunctionType.BEServiceRequest:
      case QRCodeFunctionType.Maintenance:
      case QRCodeFunctionType.MaintenanceRequest:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.Support:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.RoomRental:
      case QRCodeFunctionType.LeadCapture:
      case QRCodeFunctionType.EVRequest:
      case QRCodeFunctionType.CheckIn:
      case QRCodeFunctionType.FieldMarketing:
      case QRCodeFunctionType.EventRegistration:
        return { ...baseProps, template: newTemplate, title: newTemplate?.Title?.en, type: functionalityType }
      case QRCodeFunctionType.LadderSafety:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.Inventory:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.BurnPermit:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.CommentCard:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          qrTypeName: 'Comment Card',
          btnText: 'Send Feedback',
          btnWidth: '50%',
        }
      case QRCodeFunctionType.ResidentCheckOut:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          btnText: 'CHECK-OUT',
          btnWidth: '50%',
        }
      default:
        return { ...baseProps }
    }
  }

  const { showMobileFunctionalityType, showQrType } = useMemo(() => {
    const editPanelButton = panelData?.Items?.[editPanelIndex]
    if (isHub && editPanelButton) {
      return { showMobileFunctionalityType: editPanelButton.FunctionalityType, showQrType: editPanelButton.QrType }
    }
    return { showMobileFunctionalityType: qrType?.FunctionalityType, showQrType: qrType?.QrType }
  }, [qrType, panelData, editPanelIndex])

  // @ts-ignore
  const FormCompName = DynamicComponentsMaps[showMobileFunctionalityType]

  const passProps = getFromProps(showMobileFunctionalityType, showQrType)
  const handleGeneratorCode = () => {
    if (!name) {
      notify('Please enter QRCode name', { type: 'info' })
      return
    }
    setGeneratorTime(new Date().getTime())
  }

  const handleSuccess = (imageUrl: string, result: any) => {
    setTabId(2)
    setQrId(result?.qrId)
  }

  const customEntityHandel = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target
    if (!name) return
    const newValue = { ...entity, [name]: value }
    // if (name === 'entityType' && value != 'InUnit') {
    //   Object.assign(newValue, { unit: '' })
    // }
    if (name === 'entityType' && value === 'Building') {
      Object.assign(newValue, { floor: '' })
      setNameEn('')
    }
    // @ts-ignore
    setEntity(newValue)
    if (name === 'entityType') {
      setShouldGetEntity(true)
      if (StopType === 'Location') {
        setState({ EquipmentType: value as string })
      }
    }
  }

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setState({ [name]: value })
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabId(newValue)
  }

  const onChangeDestination = (event: React.ChangeEvent<{}>, value: QRType | null) => {
    setQRType?.(value)
    setTemplate?.(null)
    const newState = { name: value?.DefaultQrName || value?.DisplayText?.en || '' }
    if (value?.FunctionalityType === QRCodeFunctionType.ActivitySchedule) {
      newState.name = 'Re-occurring Repair'
    }
    setState(newState)
    if (value && value.Location) {
      setEntity((pre) => {
        const nextData = { ...pre, entityType: value?.Location }
        return nextData
      })
    }

    if (value?.FunctionalityType === QRCodeFunctionType.OpenAI) {
      // @ts-ignore
      setTemplate?.({
        TemplateId: '889b2720-a75d-11ed-b311-4f1f9659e967',
      })
    }
    if (
      value?.FunctionalityType &&
      [
        QRCodeFunctionType.CustomerFeedback,
        QRCodeFunctionType.Inventory,
        QRCodeFunctionType.ActivitySchedule,
        QRCodeFunctionType.Permit,
      ].includes(value.FunctionalityType)
    ) {
      setTemplateEntity({ acId: bId })
    }
  }

  const onChangeStopType = (value: string) => {
    setState({ StopType: value, EquipmentType: value === 'Location' ? entity?.entityType : '' })
  }
  const equipmentTypeOptions = useMemo(
    () => (StopType === 'Equipment' ? ROUND_EQUIPMENT_TYPE : types),
    [types, StopType]
  )
  const onChangeEquipmentType = (event: React.ChangeEvent<{}>, value) => {
    if (StopType === 'Location') {
      // @ts-ignore
      return customEntityHandel({ target: { name: 'entityType', value: value?.Name } })
    }
    setState({ EquipmentType: value?.Name || '' })
  }

  const onChangeTemplate = useCallback(
    (data) => {
      setTemplate?.(data)
    },
    [qrType]
  )

  const multilineChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    const { name, value } = event.target
    if (!name) return
    setNameEn(value)
  }

  const textFieldBlur = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    setShouldGetEntity(true)
  }

  const fetchTypes = useCallback(async () => {
    try {
      const data = await getEntityTypes({})
      // @ts-ignore
      setTypes(data.sort((a, b) => a.DisplayText?.['en'].localeCompare(b.DisplayText?.['en'])))
    } catch (e) {
      SnackBar({ msg: e, type: 'error', duration: 3000 })
    }
  }, [])

  const fetchProperties = async () => {
    if (!selectedPropertyId) return
    const data: any = await getPropertyDetail({ eId: selectedPropertyId })
    setProperties(data)
  }

  const saveEntity = useCallback(async () => {
    if (isBuilding) {
      setTemplateEntity({ ...entity, acId: bId })
      setShouldGetEntity(false)
      return
    }
    const Type = entity.entityType || entity.Type
    if (!Type) return
    try {
      const params = {
        GroupId: currentGroupId,
        BuildingId: bId,
        Type,
        Floor: entity.floor,
        Unit: entity.unit,
        NameEn: NameEn,
        Location: Type,
        EntityId: templateEntity && templateEntity.acId === bId ? '' : templateEntity?.acId || '',
      }
      const data = await SaveEntity(params)

      // @ts-ignore
      setTemplateEntity({ ...entity, ...data })
      setShouldGetEntity(false)
    } catch (e) {
      SnackBar({ msg: e, type: 'error', duration: 3000 })
    }
  }, [entity, NameEn, isBuilding, bId, currentGroupId])

  const isAngus = [QRCodeFunctionType.AngusServiceRequest, QRCodeFunctionType.BEServiceRequest].includes(
    showMobileFunctionalityType as QRCodeFunctionType
  )

  useEffect(() => {
    if (isRound) {
      const qrT = qrTypeList.find((item) => item.FunctionalityType === QRCodeFunctionType.EquipmentInspection)
      setQRType?.(qrT)
      if (qrT?.Location) setEntity((entity) => ({ ...entity, entityType: qrT.Location }))
    }
    if (isCustomerPortal) {
      const qrT = qrTypeList.find((item) => item.Name === qrName)
      setQRType?.(qrT)
      setState({ name: qrT?.DisplayText?.en || '' })
      setEntity((entity) => ({ ...entity, entityType: 'Digital' }))
      saveEntity()
    }
    if (isInspection) {
      const qrT = qrTypeList.find((item) => item.FunctionalityType === QRCodeFunctionType.Inspection)
      setQRType?.(qrT)
      setEntity((entity) => ({ ...entity, entityType: 'Digital' }))
    }
  }, [isRound, qrTypeList, isCustomerPortal, qrName, isInspection])

  useEffect(() => {
    if (isRound && (!name || !EquipmentType || !StopType)) {
      setCanGenerator(false)
      return
    }

    if (isInspection && (!name || !InspectionType || !NameEn)) {
      setCanGenerator(false)
      return
    }

    if (
      qrType &&
      [QRCodeFunctionType.AngusServiceRequest, QRCodeFunctionType.BEServiceRequest].includes(qrType?.FunctionalityType)
    ) {
      if (!(name && entity && (entity.entityType || entity.Type) && NameEn)) {
        setCanGenerator(false)
      } else {
        setCanGenerator(true)
      }
      return
    }

    if (!hiddenEntity && !isInspection) {
      if (
        (!isBuilding && !(name && entity && entity.floor && (entity.entityType || entity.Type) && NameEn)) ||
        (isBuilding && !(name && entity && (entity.entityType || entity.Type)))
      ) {
        setCanGenerator(false)
        return
      }
    }
    if (
      templateEntity?.acId &&
      qrType?.FunctionalityType &&
      [
        QRCodeFunctionType.Announcements,
        QRCodeFunctionType.OpenAI,
        QRCodeFunctionType.NewSupplier,
        QRCodeFunctionType.RoundReport,
        QRCodeFunctionType.SupplierCheckReport,
        QRCodeFunctionType.FeedbackLocationReport,
        QRCodeFunctionType.TenantHealth,
        QRCodeFunctionType.TenantPerformance,
        QRCodeFunctionType.Permit,
        QRCodeFunctionType.EquipmentLookup,
        QRCodeFunctionType.CleaningRoundReport,
        QRCodeFunctionType.SupplierList,
        QRCodeFunctionType.BathroomServiceReport,
        QRCodeFunctionType.PortfolioInspectionDashboard,
        QRCodeFunctionType.BuildingInspectionDashboard,
        QRCodeFunctionType.SecurityPatrolDashboard,
      ].includes(qrType?.FunctionalityType)
    ) {
      setCanGenerator(true)
      return
    }
    // @ts-ignore
    setCanGenerator((hiddenEntity || templateEntity?.acId) && template?.CanSave)
  }, [
    template,
    templateEntity,
    isBuilding,
    qrType,
    name,
    entity,
    NameEn,
    isRound,
    EquipmentType,
    hiddenEntity,
    StopType,
    isInspection,
    InspectionType,
  ])

  useEffect(() => {
    fetchTypes()
  }, [])

  useEffect(() => {
    const entityType = entity?.entityType || entity?.Type
    const entityChecked = entityType && entity?.floor
    const inspectionCanSave = isInspection && entityType && NameEn
    const angusCanSave = isAngus && entityType && NameEn

    if (
      (shouldGetEntity && !isBuilding && entityChecked && NameEn) ||
      (isBuilding && entityType) ||
      inspectionCanSave ||
      angusCanSave
    ) {
      saveEntity()
    }
  }, [currentGroupId, isBuilding, bId, entity, NameEn, shouldGetEntity, isInspection, isAngus])

  useEffect(() => {
    fetchProperties()
  }, [selectedPropertyId])

  useEffect(() => {
    getTenantList({
      filter: JSON.stringify({ bId, gId: selectedGroupId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      setState({ TenantList: res.Tenants })
    })
  }, [isBathroomService, selectedGroupId, bId, setState])

  const isAngusNotIsHub = isAngus && !isHub
  const disabledSelectBuilding = DisabledBuildingQr.includes(qrType?.FunctionalityType as QRCodeFunctionType)

  return (
    <QRCodeProvider value={qrCodeContextValue}>
      <FormDataContextProvider>
        <div className={classes.root}>
          <Box className={classes.backButton}>
            <BackButton handleBack={goBack} />
          </Box>

          <Box className={classes.container}>
            <Grid item xs={12} md={5} className={classes.left}>
              <MobileFrame logo={qrInfo.HeaderLogo}>
                {FormCompName ? (
                  <FormCompName {...passProps} />
                ) : (
                  <Typography className={classes.noType}>Preview is not available for this type</Typography>
                )}
              </MobileFrame>
            </Grid>

            <Grid item xs={12} md={9} className={classes.right}>
              <Tabs value={tabId} onChange={handleTabChange} className={classes.tabSection}>
                {!isCustomerPortal && <Tab label="Design" value={0} className={classes.tabLabel} />}
                <Tab label="Content" value={1} className={classes.tabLabel} />
                <Tab label={isCustomerPortal ? 'Delivery' : 'Digital & Print'} value={2} className={classes.tabLabel} />
              </Tabs>

              <TabPanel value={tabId} index={0} className={classes.tabPanel}>
                <PickADesign gId={currentGroupId as string} bId={bId as string}></PickADesign>
              </TabPanel>

              <TabPanel value={tabId} index={1} className={classes.tabPanel}>
                <Box className={classes.contentBox}>
                  <Autocomplete
                    value={qrType || null}
                    disabled={!!type}
                    onChange={onChangeDestination}
                    className={classes.autoComplete}
                    id="function-type"
                    options={qrTypeList}
                    getOptionLabel={(option) => option.DisplayText?.en || ''}
                    style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                    renderInput={(params) => <TextField {...params} label="QR Type" variant="outlined" />}
                    popupIcon={<ExpandMore />}
                  />

                  {qrType && (
                    <CustomTextField
                      className={classes.formControl}
                      label={qrNameLabel}
                      disabled={false}
                      name="name"
                      placeholder="Enter here"
                      onChange={handleChange}
                      onBlur={textFieldBlur}
                      value={name}
                      required
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  {qrType && (
                    <Autocomplete
                      value={TAG_TYPE.find((item) => item.value === TagType) || null}
                      onChange={(e, value) => setState({ TagType: value?.value || '' })}
                      options={TAG_TYPE}
                      getOptionLabel={(option) => option.label || ''}
                      className={classes.autoComplete}
                      style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                      renderInput={(params) => <TextField {...params} label="Tag Type" variant="outlined" required />}
                      popupIcon={<ExpandMore />}
                    />
                  )}
                  {isRound && (
                    <Autocomplete
                      value={StopType || null}
                      onChange={(e, value) => onChangeStopType(value as string)}
                      id="stop-type"
                      options={['Equipment', 'Location']}
                      className={classes.autoComplete}
                      style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                      renderInput={(params) => <TextField {...params} label="Stop Type" variant="outlined" required />}
                      popupIcon={<ExpandMore />}
                    />
                  )}
                  {isRound && (
                    <Autocomplete
                      value={equipmentTypeOptions.find((item) => item.Name === EquipmentType) || null}
                      onChange={onChangeEquipmentType}
                      id="equipment-type"
                      options={equipmentTypeOptions}
                      getOptionLabel={(option) => option.DisplayText?.en || ''}
                      className={classes.autoComplete}
                      style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                      renderInput={(params) => (
                        <TextField {...params} label="Equipment/Location Type" variant="outlined" required />
                      )}
                      popupIcon={<ExpandMore />}
                    />
                  )}
                  {isInspection && (
                    <Autocomplete
                      value={INSPECTION_TYPE.find((item) => item.Name === InspectionType) || null}
                      onChange={(e, value) => setState({ InspectionType: value?.Name || '' })}
                      options={INSPECTION_TYPE}
                      getOptionLabel={(option) => option.DisplayText?.en || ''}
                      className={classes.autoComplete}
                      style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                      renderInput={(params) => (
                        <TextField {...params} label="Inspection Type" variant="outlined" required />
                      )}
                      popupIcon={<ExpandMore />}
                    />
                  )}
                  {!hiddenEntity && qrType && (
                    <Accordion className={classes.accordion} defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        classes={{
                          root: classes.accordionSummary,
                          expanded: classes.accordionSummaryExpanded,
                        }}
                      >
                        <Typography>QR Sign Location</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        {qrType?.FunctionalityType && (
                          <>
                            {![isBuilding, isAngusNotIsHub, isInspection].includes(true) && (
                              <CustomTextField
                                label="Floor"
                                required={!isBuilding}
                                disabled={isBuilding}
                                name="floor"
                                onChange={customEntityHandel}
                                onBlur={textFieldBlur}
                                placeholder="Enter here"
                                value={entity.floor}
                                className={classes.formControl}
                                variant="outlined"
                              />
                            )}
                            <FormControl
                              className={classes.formControl}
                              required
                              style={{ marginBottom: '12px', marginTop: '12px' }}
                            >
                              <InputLabel id="entity-label" className={classes.menuLabel}>
                                Location
                              </InputLabel>
                              <Select
                                value={entity.entityType || entity.Type || ''}
                                labelId="Entity Type"
                                id="entityType"
                                name="entityType"
                                //@ts-ignore
                                onChange={customEntityHandel}
                                disabled={qrId ? true : false}
                                IconComponent={ExpandMore}
                              >
                                {types.map((item) => {
                                  const style =
                                    disabledSelectBuilding && item.Name === 'Building' ? { display: 'none' } : undefined

                                  if (
                                    qrType?.FunctionalityType === QRCodeFunctionType.BathroomService &&
                                    item.Label !== 'Bathroom'
                                  ) {
                                    return null
                                  }
                                  return (
                                    <MenuItem value={item.Name} key={item.Name} style={style}>
                                      {item.DisplayText?.['en']}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                            {isRound && (
                              <CustomTextField
                                label="Mechanical Space"
                                disabled={false}
                                required
                                name="MechanicalSpace"
                                onChange={handleChange}
                                placeholder="Enter here"
                                value={MechanicalSpace}
                                className={classes.formControl}
                                variant="outlined"
                              />
                            )}
                            {![isRound, isAngusNotIsHub, isInspection].includes(true) && (
                              <CustomTextField
                                label="Unit"
                                disabled={false}
                                required={false}
                                name="unit"
                                onChange={customEntityHandel}
                                onBlur={textFieldBlur}
                                placeholder="Enter here"
                                value={entity.unit}
                                className={classes.formControl}
                                variant="outlined"
                              />
                            )}
                            {isBathroomService && (
                              <>
                                <Autocomplete
                                  value={Accessible || null}
                                  onChange={(e, value) => {
                                    setState({ Accessible: value, TenantId: '', TenantName: '' })
                                  }}
                                  id="Accessible"
                                  options={['Public', 'Private']}
                                  className={classes.autoComplete}
                                  style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                                  renderInput={(params) => (
                                    <TextField {...params} label=" Private or Public" variant="outlined" />
                                  )}
                                  popupIcon={<ExpandMore />}
                                />
                                {Accessible === 'Private' && (
                                  <Autocomplete
                                    value={TenantList.find((item) => item.acId === TenantId) || null}
                                    onChange={(e, value) =>
                                      setState({ TenantId: value?.acId, TenantName: value?.Name })
                                    }
                                    options={TenantList}
                                    getOptionLabel={(option) => option.Name || ''}
                                    className={classes.autoComplete}
                                    style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Tenant Name" variant="outlined" />
                                    )}
                                    popupIcon={<ExpandMore />}
                                  />
                                )}
                              </>
                            )}
                            {!isBuilding && (
                              <TextField
                                value={NameEn}
                                required={!isBuilding}
                                placeholder="Enter here"
                                className={classes.formControl}
                                id="outlined-multiline-static"
                                label="Installation Note"
                                disabled={isBuilding}
                                onBlur={textFieldBlur}
                                name="NameEn"
                                variant="outlined"
                                onChange={multilineChange}
                              />
                            )}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <EditAllPanel
                    getTemplate={true}
                    isHub={isHub}
                    groupId={currentGroupId as string}
                    onChange={onChangeTemplate}
                    entity={templateEntity}
                    nameEn={NameEn}
                    qrType={qrType}
                    defaultTemplate={template}
                    templateId={templateId}
                    bId={bId as string}
                    TagType={TagType}
                    Skip1FA={Skip1FA}
                    setState={setState}
                  />
                </Box>
              </TabPanel>

              <TabPanel value={tabId} index={2} className={classes.tabPanel}>
                <MemoizedQRConfig
                  childRef={childRef}
                  groupId={currentGroupId as string}
                  buildingId={bId as string}
                  qrType={qrType}
                  nameEn={NameEn}
                  qrId={qrId}
                  templateId={templateId}
                  mechanicalSpace={MechanicalSpace}
                  entityId={templateEntity?.acId}
                  customEntity={templateEntity}
                  editTemplate={template}
                  refreshTime={refreshTime}
                  canGenerator={canGenerator}
                  generatorTime={generatorTime}
                  qrName={name}
                  equipmentType={EquipmentType}
                  stopType={StopType}
                  inspectionType={InspectionType}
                  handleSuccess={handleSuccess}
                  Accessible={Accessible}
                  TenantId={TenantId}
                  TenantName={TenantName}
                  TagType={TagType}
                  Skip1FA={Skip1FA}
                />
              </TabPanel>

              {(tabId === 0 || tabId === 1) && (
                <Box className={classes.buttonBox}>
                  <Button onClick={goBack} variant="outlined" style={{ marginRight: '15px' }}>
                    Cancel
                  </Button>
                  <Button disabled={!canGenerator} onClick={handleGeneratorCode} color="primary" variant="contained">
                    Generate QR Code
                  </Button>
                </Box>
              )}
            </Grid>
          </Box>
        </div>
      </FormDataContextProvider>
    </QRCodeProvider>
  )
}

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#f4f4f4',
    flex: 1,
  },
  backButton: {
    minWidth: '100%',
  },
  container: {
    display: 'flex',
    margin: '20px auto 0',
    width: '90%',
    paddingLeft: '24px',
  },
  left: {
    justifyContent: 'center',
  },
  noType: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    marginTop: 200,
    fontSize: 16,
    fontWeight: 400,
  },
  information: {
    color: '#4e4949',
    fontSize: 14,
    textAlign: 'center',
  },
  typography: {
    marginTop: 16,
    color: '#4e4949',
    fontSize: 20,
    textAlign: 'center',
  },
  right: {
    flex: 1,
    boxSizing: 'border-box',
    marginLeft: 24,
    position: 'relative',
    backgroundColor: '#fff',
    paddingBottom: 24,
  },
  title: {
    marginTop: 20,
    marginLeft: 5,
    marginBottom: 10,
    fontSize: 20,
    color: '#386BBF',
    fontFamily: 'Roboto',
  },
  tabSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    background: '#fff',
    borderBottom: '1px solid #BCBCBC',
    marginBottom: 16,
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: 'Roboto',
    height: 20,
    lineHeight: 20,
    borderRadius: 4,
    opacity: '1',
  },
  tabPanel: {
    display: 'flex',
    background: '#fff',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 16px',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  autoComplete: {},
  hiddenBuilding: {},
  rootEntity: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectEntity: {
    display: 'flex',
    flexDirection: 'row',
  },
  swtich: {
    marginLeft: 20,
    display: 'flex',
    height: 60,
  },
  rootCustom: {
    display: 'flex',
    flexDirection: 'column',
    // width: 200
  },
  formControl: {
    margin: '8px 0',
    minWidth: 200,
  },

  commonBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: 450
  },
  summary: {
    backgroundColor: '#E9ECEF',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginRight: 24,
  },
  menuLabel: {
    backgroundColor: '#F3F5F9',
  },
  accordion: {
    border: 'none',
    boxShadow: 'none',
    margin: '2px 0 0 !important',
    '&:before': {
      height: 0,
    },
  },
  accordionSummary: {
    padding: '0 12px',
    margin: '4px 0 0',
    border: '1px solid #BCBCBC',
    borderRadius: 4,
    height: 56,
    minHeight: 'unset !important',
  },
  accordionSummaryExpanded: {
    minHeight: 'unset !important',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F3F5F9',
  },
}))

const BuilderQRWrapper: React.FC = ({ children }) => {
  return (
    <EditPanelContextProvider>
      <BuilderQR />
    </EditPanelContextProvider>
  )
}
export default BuilderQRWrapper
