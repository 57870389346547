import * as React from 'react'
import { Card, Box, IconButton, TextField, InputAdornment, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useListContext } from 'react-admin'
import { ArrowDropDownRounded, ArrowLeftRounded, HighlightOff } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import FilterListItem from '@/qrcodes/myqrcode/FilterListItem'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'

const language = 'en'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    padding: '8px 12px 24px',
    textAlign: 'right',
  },
  formName: {
    width: '100%',
  },
  name: {
    width: '100%',
    fontSize: 12,
    backgroundColor: '#F4F6F8',
    borderRadius: 5,
    padding: 8,
    height: 32,
    marginBottom: 8,
    '&:before': {
      border: 'none',
    },
  },
  clearBtn: {
    padding: 0,
    marginBottom: 8,
  },
  clear: {
    '&:hover': {
      color: '#D5352B',
    },
  },
  popoverItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 35,
    paddingRight: 12,
  },
  popoverContainer: {
    padding: '16px 8px',
    maxHeight: 500,
  },
  popoverItemLabel: {
    color: '#637381',
    fontSize: 14,
    '&:hover': {
      color: '#386BBF',
      cursor: 'pointer',
    },
  },
}))

interface PopoverListProps {
  label: string
  isEmpty?: boolean
  popupId?: string
}

export const PopoverList: React.FC<PopoverListProps> = ({
  label,
  children,
  isEmpty,
  popupId = 'filterPopoverItem',
}) => {
  const classes = useStyles()
  return (
    <PopupState variant="popover" popupId={popupId}>
      {(popupState) => {
        return (
          <Box className={classes.popoverItem} {...bindHover(popupState)}>
            {popupState.isOpen ? (
              <ArrowLeftRounded fontSize="large" htmlColor="#386BBF" />
            ) : (
              <ArrowDropDownRounded fontSize="large" />
            )}
            <Typography className={classes.popoverItemLabel}>{label}</Typography>
            {!isEmpty && (
              <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                classes={{ paper: classes.popoverContainer }}
              >
                {children}
              </HoverPopover>
            )}
          </Box>
        )
      }}
    </PopupState>
  )
}

const Aside = (props) => {
  const {
    filterData = { Floor: {}, Location: {}, QrType: {}, EntityType: {}, ModifiedDate: [], TagType: {} },
    onClose,
  } = props
  const { setFilters, filterValues, displayedFilters } = useListContext()
  const floors = Object.keys(filterData?.Floor)
  const entityTypes = Object.keys(filterData?.EntityType)
  const modifiedDates = filterData?.ModifiedDate
  const qrType = Object.keys(filterData?.QrType)
  const tagType = Object.keys(filterData?.TagType)
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <IconButton onClick={onClose} classes={{ root: classes.clearBtn }}>
        <HighlightOff className={classes.clear} />
      </IconButton>
      <TextField
        onChange={(event) => {
          if (event.target) {
            setFilters({ ...filterValues, Name: event.target.value }, { ...displayedFilters, Name: event.target.value })
          } else {
            const { Name: _, ...filters } = filterValues
            const { Name: __, ...dfilters } = displayedFilters
            setFilters(filters, dfilters)
          }
        }}
        size="small"
        placeholder={'Search'}
        className={classes.formName}
        InputProps={{
          classes: { underline: classes.name },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon htmlColor="#637381" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <PopoverList isEmpty={!floors?.length} label="Floor">
        {floors?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${item}`}
            count={filterData.Floor[item]}
            key={item}
            keyName="Floor"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList isEmpty={!qrType?.length} label="QR Filter">
        {qrType?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${filterData.QrType[item]?.DisplayText?.[language] || ''}`}
            count={filterData.QrType[item]?.['Count']}
            key={item}
            keyName="QrType"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList isEmpty={!entityTypes?.length} label="Location">
        {entityTypes?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${item}`}
            count={filterData.EntityType[item]?.['Count']}
            key={item}
            keyName="EntityType"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList label="Modified Date">
        {Array.isArray(modifiedDates) &&
          modifiedDates.map((item) => {
            return (
              <FilterListItem
                key={item.label}
                toggle={onClose}
                label={item.label}
                keyName="ModifiedDate"
                value={item.value}
              />
            )
          })}
      </PopoverList>
      <PopoverList label="Tag Type">
        {tagType?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${filterData.TagType[item]?.DisplayText?.[language] || ''}`}
            count={filterData.TagType[item]?.['Count']}
            key={item}
            keyName="TagType"
            value={item}
          />
        ))}
      </PopoverList>
    </Card>
  )
}

export default Aside
