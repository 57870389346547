import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocaleState,
  useRecordContext,
  useRedirect,
  Datagrid,
  List,
  ListProps,
  TopToolbar,
  TextField,
  usePermissions,
  useTranslate,
  useListContext,
  TextFieldProps,
} from 'react-admin'
import { Box, Button, Typography, IconButton, Tooltip } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PeopleIcon from '@mui/icons-material/People'
import BusinessIcon from '@mui/icons-material/Business'
import CropFreeIcon from '@mui/icons-material/CropFree'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { makeStyles } from '@mui/styles'
import { selectedProperty } from '@reducer/profile'
import { Identifier, RaRecord } from 'ra-core'
import Images from '@assets/images'
import ImportModal from '@component/Modal/ImportModal'
import { useState } from 'react'
import { uploadPropertyFile } from '@api'
import SnackBar from '@component/SnackBar'
import Config from '@constant/config'
import { RowClickFunction } from 'ra-ui-materialui/src/list/datagrid/DatagridRow'

const useStyles = makeStyles(() => ({
  root: {
    padding: '36px 24px 24px',
    backgroundColor: '#F5F5F5',
  },
  toolbar: {
    background: '#fff',
    width: '100%',
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 20,
    fontFamily: 'Roboto',
    color: '#4E4D4D',
  },
  addButton: {
    fontSize: 12,
    fontFamily: 'Roboto',
    textTransform: 'none',
  },
  minWidth: {
    minWidth: 200,
  },
}))

const PropertyListActions: React.FC<{ gId: string }> = ({ gId }) => {
  const classes = useStyles()
  const redirect = useRedirect()
  const { permissions } = usePermissions()
  const { GlobalManageEntities } = permissions || {}
  const [showImport, setShowImport] = useState(false)
  const handleAdd = () => redirect('/propertyAdd')
  const onImport = (fileUrl: string) => {
    uploadPropertyFile({
      FilePath: fileUrl,
      GroupId: gId,
    })
      .then(() => {
        SnackBar({ msg: 'Import Successful', type: 'success' })
        setShowImport(false)
      })
      .catch((err) => {
        SnackBar({ msg: err, duration: 3000 })
      })
  }

  return (
    <TopToolbar className={classes.toolbar}>
      <Typography className={classes.title}>List of Buildings</Typography>
      {GlobalManageEntities && (
        <>
          <Button className={classes.addButton} onClick={() => setShowImport(true)}>
            <img src={Images.iconImportTwo} style={{ marginRight: 6, height: 24 }} />
            Import
          </Button>
          <Button onClick={handleAdd} className={classes.addButton}>
            <AddCircleIcon
              color="primary"
              style={{
                fontSize: 24,
                marginRight: 5,
              }}
            />
            Create New Building
          </Button>
        </>
      )}
      {showImport && (
        <ImportModal
          onClose={() => setShowImport(false)}
          onImport={(fileUrl) => onImport(fileUrl)}
          templateUrl={Config.propertyImportTemplate}
          title="Building"
          filePath={`${gId}/ImportProperty`}
        />
      )}
    </TopToolbar>
  )
}

const PurpleTextField: React.FC<TextFieldProps> = () => {
  const record = useRecordContext()
  const [locale] = useLocaleState()
  const translate = useTranslate()
  const { permissions } = usePermissions()
  const { ViewCompanyAllBuildings } = permissions || {}
  const { user } = useSelector((state) => state.profile)
  const redirect = useRedirect()
  const dispatch = useDispatch()
  const AccessBuildingIds = user?.UserContext?.AccessBuildingIds || []

  const setSelectedProperty = () => {
    dispatch(
      selectedProperty({
        propertyId: record.id,
        propertyName: record.Name[locale],
        buildingCode: record.Code,
        propertyTag: record.PropertyTag || {},
      })
    )
  }

  const onSelectedProperty = (event: React.ChangeEvent<{}>) => {
    event.stopPropagation()
    setSelectedProperty()
    redirect(`/propertyAdd/${record.id}`)
  }
  const onSelectedQRLib = (event: React.ChangeEvent<{}>) => {
    event.stopPropagation()
    setSelectedProperty()
    redirect('/library/qrcodelist')
  }
  const onSelectedContact = (event: React.ChangeEvent<{}>) => {
    event.stopPropagation()
    setSelectedProperty()
    redirect('/contacts/contactList')
  }
  const onSelectedAnalytics = (event: React.ChangeEvent<{}>) => {
    event.stopPropagation()
    setSelectedProperty()
    redirect(ViewCompanyAllBuildings ? '/globalReport' : `/analyticsFeedback/${AccessBuildingIds[0]}`)
  }
  const buttons = [
    { onclick: onSelectedProperty, title: translate('pos.menu.propertyInfo'), Com: BusinessIcon },
    { onclick: onSelectedQRLib, title: translate('pos.menu.qrcode'), Com: CropFreeIcon },
    { onclick: onSelectedAnalytics, title: translate('pos.menu.analytics'), Com: AssessmentIcon },
    { onclick: onSelectedContact, title: translate('pos.menu.contacts'), Com: PeopleIcon },
  ]
  return (
    <div style={{ color: 'purple', textAlign: 'center', width: '100%', whiteSpace: 'nowrap' }}>
      {buttons.map((item) => {
        const { onclick, title, Com } = item
        return (
          <IconButton onClick={onclick} key={title} sx={{ padding: 0, marginRight: '12px' }}>
            <Tooltip title={title} placement="top">
              <Com color="primary" style={{ fontSize: 24 }} />
            </Tooltip>
          </IconButton>
        )
      })}
    </div>
  )
}

const CustomerDatagrid: React.FC<{ rowClick: RowClickFunction }> = ({ rowClick }) => {
  const classes = useStyles()
  const listContext = useListContext()
  const isLoading = listContext.isLoading
  return (
    <Datagrid optimized bulkActionButtons={false} rowClick={rowClick} isLoading={isLoading}>
      <TextField source="Name.en" label="Building Name" noWrap />
      <TextField source="PropertyTag.Name" label="Property Tag" />
      <TextField source="PropertyType" label="Building Type" emptyText="Single" />
      <TextField source="AddressLine1.en" label="Address" align="left" cellClassName={classes.minWidth} />
      <TextField source="BuildingType" label="Vertical" align="left" />
      <TextField source="Region" label="Region" align="left" />
      <TextField source="Market" label="Market" align="left" />
      <PurpleTextField source="acId" label="Quick Links" align="center" />
    </Datagrid>
  )
}
const PropertyList = (props: ListProps) => {
  const classes = useStyles()
  const { selectedGroupId, selectedPropertyId, selectedGroupName } = useSelector((state) => state.profile)
  const redirect = useRedirect()
  const [locale] = useLocaleState()
  const dispatch = useDispatch()
  const rowClick: RowClickFunction = (id: Identifier, path: string, record: RaRecord) => {
    dispatch(selectedProperty({ propertyId: record.id, propertyName: record.Name[locale], buildingCode: record.Code }))
    redirect(`/propertyAdd/${record.id}`)
    return false
  }
  return (
    <Box className={classes.root}>
      <List
        {...props}
        filter={{ gId: selectedGroupId, BuildingId: selectedPropertyId }}
        perPage={10}
        hasCreate={true}
        exporter={false}
        actions={<PropertyListActions gId={selectedGroupId} />}
        empty={false}
      >
        <CustomerDatagrid rowClick={rowClick} />
      </List>
    </Box>
  )
}

export default PropertyList
