import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { ProductITF, VerticalToProductITF } from '@/types/entity'
import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  item: {
    paddingBottom: '24px',
    borderBottom: '1px solid #BCBCBC',
    marginBottom: '24px',
    display: 'block',
  },
  noBorder: {
    borderBottom: 'none',
  },
  group: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    display: 'grid',
  },
  question: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 16,
    display: 'inline-block',
  },
  radioLabel: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

interface Props {
  options: Array<VerticalToProductITF>
  title: string
  data?: string
  noBorder?: boolean
  handleChange: (item: string, products: Array<ProductITF>) => void
}
const AddtionalSingleQuestion: React.FC<Props> = ({ title, options, data, handleChange, noBorder }) => {
  const classes = useStyles()
  return (
    <FormControl className={classnames(classes.item, { [classes.noBorder]: noBorder })}>
      <FormLabel className={classes.question}>{title}</FormLabel>
      <RadioGroup row value={data} classes={{ root: classes.group }}>
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.Key}
              value={option.Key}
              onChange={(e) => handleChange(option.Key, option.Products)}
              sx={{ marginRight: '32px', fontSize: '14px' }}
              classes={{ label: classes.radioLabel }}
              control={<Radio size="small" />}
              label={option.Label}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

interface AddtionalCheckboxQuestionProps {
  title?: string
  options: Array<ProductITF>
  data?: Array<ProductITF>
  noBorder?: boolean
  handleChange: (item: Array<ProductITF>) => void
}

export const AddtionalCheckboxQuestion: React.FC<AddtionalCheckboxQuestionProps> = ({
  title,
  options,
  data,
  handleChange,
  noBorder,
}) => {
  const classes = useStyles()
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      className={classnames(classes.item, { [classes.noBorder]: noBorder })}
    >
      <FormLabel className={classes.question}>{title}</FormLabel>
      <FormGroup row classes={{ root: classes.group }}>
        {options.map((option) => {
          const { Type, ProductName } = option
          const checkedIndex = (data || [])?.findIndex((item) => item.Type === Type && item.ProductName === ProductName)
          return (
            <FormControlLabel
              key={Type}
              sx={{ marginRight: '32px', fontSize: '14px' }}
              control={
                <Checkbox
                  checked={checkedIndex > -1}
                  onChange={(e) => {
                    const next = data || []
                    if (checkedIndex > -1) {
                      next?.splice(checkedIndex, 1)
                    } else {
                      next.push(option)
                    }
                    handleChange(next)
                  }}
                  size="small"
                />
              }
              label={option.ProductName}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}

export default React.memo(AddtionalSingleQuestion)
