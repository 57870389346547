import React from 'react'
import { makeStyles } from '@mui/styles'
import WithDefaultData from '@/hoc/WithDefaultData'
import { Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  title: {
    color: '#466CB5',
    fontSize: 20,
    fontWeight: 600,
  },
  desc: {
    color: '#211F1F',
  },
}))

const AngusServiceRequestPanel: React.FC<{ isHub?: boolean }> = ({ isHub }) => {
  const classes = useStyles()
  if (isHub) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.desc}>
        Due to the API integration, the content for this QR Code. Please refer to the mobile preview.{' '}
      </Typography>
    </div>
  )
}

export default WithDefaultData(AngusServiceRequestPanel)
