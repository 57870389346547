import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Typography, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import BackButton from '@component/BackButton/BackButton'
import ProgressBarWithTitle from '@pages/survey/components/ProgressBarWithTitle'
import { useLocation } from 'react-router-dom'
import { getCurrentPrintSettings, savePrintProofs } from '@api'
import { Loading } from 'ra-ui-materialui'
import useSetState from '@hooks/useSetState'
import { ThumbUp, ThumbDown, CheckCircle, AddCircle, ExpandMore } from '@mui/icons-material'
import classnames from 'classnames'
import SnackBar from '@component/SnackBar'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import DateFnsAdapter from '@date-io/date-fns'
import NoteModal from '@component/Modal/NoteModal'
import { PrintSettingITF } from '@/types/survey'
import PrintSettingSelected from '@component/Modal/PrintSettingSelected'

const useStyles = makeStyles(() => {
  return {
    root: {
      paddingTop: 24,
      minHeight: '100vh',
      backgroundColor: '#F7F7F7',
      padding: '0 24px 24px',
      fontFamily: 'Roboto',
    },
    progress: {
      marginTop: 24,
      marginBottom: 32,
    },
    title: {
      color: '#000',
      fontSize: 30,
    },
    bold: {
      fontWeight: 700,
    },
    comment: {
      margin: '32px 0 24px',
    },
    typography: {
      marginTop: 16,
      color: '#4e4949',
      fontSize: 20,
      textAlign: 'center',
    },
    information: {
      color: '#4e4949',
      fontSize: 14,
      textAlign: 'center',
    },
    complete: {
      backgroundColor: '#fff',
      borderRadius: 8,
      padding: 24,
      textAlign: 'center',
    },
    noType: {
      textAlign: 'center',
      fontFamily: 'Roboto',
      marginTop: 200,
      fontSize: 16,
      fontWeight: 400,
    },
    qrTypeName: {
      marginTop: 24,
      marginBottom: 24,
      paddingLeft: 8,
      color: '#211F1F',
      fontWeight: 600,
      fontSize: 34,
      lineHeight: '36px',
    },
    itemBox: {
      width: '100%',
      height: 80,
      background: '#FFFFFF',
      boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: 16,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      margin: '0 0 16px 0',
      cursor: 'pointer',
    },
    name: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#211F1F',
      fontSize: 20,
      wordBreak: 'break-word',
    },
    iconHub: {
      width: 48,
      height: 48,
    },
    approve: {
      opacity: 0.5,
    },
    activateItem: {
      border: '2px solid #1976D2',
      opacity: 1,
    },
    count: {
      borderRadius: 8,
      background: '#F0F6FC',
      color: '#466CB5',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '32px',
      width: 55,
      height: 32,
    },
    right: {
      flex: 1,
      marginLeft: 16,
      minWidth: 600,
    },
    bottom: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 16,
      padding: '24px 24px 36px',
      backgroundColor: 'white',
    },
    qrName: {
      fontWeight: 600,
      fontSize: 24,
      fontColor: '#211F1F',
    },
    bottomContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 8,
    },
    item: {
      borderRadius: 16,
      padding: 24,
      border: '1px solid #707070',
      marginLeft: 10,
      marginRight: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      height: 118,
      cursor: 'pointer',
    },
    itemActive: {
      border: '3px solid #1976D2',
    },
    itemDisabled: {
      border: '3px solid #BCBCBC',
    },
    icon: {
      width: 70,
      height: 70,
      marginRight: 20,
      borderRadius: 35,
      backgroundColor: '#50B258',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    add: {
      justifyContent: 'center',
      backgroundColor: '#F0F6FC',
      border: '1px dashed #BCBCBC',
      color: '#386BBF',
      fontSize: 16,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    notPad: {
      display: 'flex',
      flexDirection: 'column',
    },
    noteItem: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid  #E8E8E8',
      marginTop: 16,
      borderRadius: 8,
    },
    noteItemHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
    },
    sort: {
      backgroundColor: '#466CB5',
      width: 36,
      textAlign: 'center',
      height: 36,
      lineHeight: '36px',
      borderRadius: 18,
      color: 'white',
    },
    note: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingLeft: 12,
      paddingRight: 12,
    },
    noteName: {
      color: '#1C1B1F',
      fontSize: 14,
    },
    noteText: {
      color: '#1C1B1F',
      fontSize: 18,
      lineHeight: '27px',
      wordBreak: 'break-word',
    },
    noteTextLine: {
      // position: 'absolute',
      backgroundColor: 'white',
      fontSize: 18,
      lineHeight: '27px',
      // right: 0,
      // top: 0,
    },
    noteItemBottom: {
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #E8E8E8',
      padding: '6px 16px',
    },
    type: {
      padding: '4px 8px',
      borderRadius: 22,
      background: '#F0F6FC',
      color: '#1976D2',
      fontSize: 14,
    },
    date: {
      flex: 1,
      fontSize: 14,
      textAlign: 'right',
    },
    card: {
      marginBottom: 24,
      padding: '24px 24px 36px',
      display: 'flex',
      backgroundColor: 'white',
      flexDirection: 'column',
    },
    cardTitle: {
      paddingBottom: 24,
      color: '#211F1F',
      fontSize: 24,
      fontWeight: 600,
    },
    cardImgBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: '1px solid  #E8E8E8',
      borderRadius: 8,
      height: 360,
      width: 576,
    },
    cardImg: {
      maxHeight: 358,
      maxWidth: 576,
    },
    btnOption: {
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 8,
      height: 48,
    },
  }
})

interface NoteITF {
  CreatedDate: number
  Note: string
  Type: string
}

const dateFns = new DateFnsAdapter()
const NotePadItem: React.FC<{ data: NoteITF; sort: number; name: string }> = ({ data, sort, name }) => {
  const { CreatedDate, Type, Note } = data
  const classes = useStyles()
  const [state, setState] = useSetState({ showExpandIcon: false, expand: true })
  const { showExpandIcon, expand } = state
  const boxRef = useRef<HTMLDivElement>()
  const onExpand = () => {
    setState((prevState) => {
      return { ...prevState, expand: !prevState.expand }
    })
  }
  useLayoutEffect(() => {
    const showExpandIcon = (boxRef?.current?.clientHeight || 0) > 27
    setState({ showExpandIcon })
  }, [Note, setState])
  return (
    <Box className={classes.noteItem}>
      <Box className={classes.noteItemHeader}>
        <Typography className={classes.sort}>{sort}</Typography>
        <Box className={classes.note}>
          <Typography className={classes.noteName}>{name}</Typography>
          <Box
            ref={boxRef}
            style={{
              display: 'flex',
              height: showExpandIcon && expand ? '27px' : 'unset',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Typography className={classes.noteText}>{Note}</Typography>
            {showExpandIcon && expand && <Typography className={classes.noteTextLine}>...</Typography>}
          </Box>
        </Box>
        {showExpandIcon && (
          <IconButton
            style={{ transform: expand ? 'rotate(0deg)' : 'rotate(180deg)', color: '#989898' }}
            onClick={onExpand}
          >
            <ExpandMore />
          </IconButton>
        )}
      </Box>
      <Box className={classes.noteItemBottom}>
        <Typography className={classes.type}>{Type}</Typography>
        <Typography className={classes.date}>{dateFns.format(new Date(Number(CreatedDate)), 'fullDate')}</Typography>
      </Box>
    </Box>
  )
}
const NotePad: React.FC<{
  data?: NoteITF[]
  onChange: (data: any[]) => void
  name: string
  forwardRef: React.MutableRefObject<{ showDialog: () => void }>
}> = ({ data, onChange, name, forwardRef }) => {
  const classes = useStyles()
  const [state, setState] = useSetState({
    showDialog: false,
    title: 'Add New Note',
    list: data || [],
  })
  const { showDialog, list, title } = state
  useImperativeHandle(
    forwardRef,
    () => ({
      showDialog: () => {
        setState({ showDialog: true, title: 'Content Rejection Note' })
      },
    }),
    [setState]
  )
  const onAdd = () => {
    setState({ showDialog: true, title: 'Add New Note' })
  }
  const onClose = () => {
    setState({ showDialog: false })
  }
  const onSave = (item) => {
    setState((prevState) => {
      const list = [item, ...(prevState.list || [])]
      onChange(list)
      return { ...prevState, showDialog: false, list }
    })
  }
  const len = list.length
  return (
    <Box className={classes.notPad}>
      {showDialog && <NoteModal onSave={onSave} onClose={onClose} title={title} />}
      <Button aria-label="add" className={classes.add} onClick={onAdd}>
        <AddCircle color="primary" style={{ marginRight: 10 }} />
        Add New Note
      </Button>
      {list.map((item, index) => {
        return <NotePadItem data={item} key={item.CreatedDate} sort={len - index} name={name} />
      })}
    </Box>
  )
}
interface CurrentPrintSettingITF {
  Completion?: { Complete: number; Total: number }
  CurrentPrintSettings?: {
    PrintSetting?: PrintSettingITF[]
    QrName?: string
  }
  Approve?: string
  Notes?: Array<NoteITF>
}
const SignProof: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const { gId, bId, propertyName } = useMemo(() => location.state || {}, [location.state])
  const forwardRef = useRef<{ showDialog: () => void }>({
    showDialog: () => {},
  })
  const [data, setData] = useState<CurrentPrintSettingITF>({})
  const { Completion, Approve, Notes = [], CurrentPrintSettings } = data || {}
  const { QrName, PrintSetting } = CurrentPrintSettings || {}
  const { Total = 1, Complete = 0 } = Completion || {}
  const [{ reRender, reGetData, showOption, selectOption }, setState] = useSetState<{
    selectOption?: PrintSettingITF
    reRender: boolean
    reGetData: boolean
    showOption: boolean
  }>({ reRender: false, reGetData: false, showOption: false })
  const { best, printSetting } = useMemo(() => {
    let best
    let printSetting = PrintSetting?.filter((item) => {
      const isBest = item.Default === 'Y'
      if (isBest && !best) {
        best = item
        return false
      }
      return true
    })
    if (!best) {
      best = printSetting?.[0]
      printSetting = printSetting?.slice(1)
    }
    return { best, printSetting }
  }, [PrintSetting])
  const getData = useCallback(async () => {
    const result = await getCurrentPrintSettings<CurrentPrintSettingITF>({ bId, gId })
    setData(result)
    const selectOption = result.CurrentPrintSettings?.PrintSetting?.find((item) => item.Selected)
    setState({ selectOption })
  }, [bId, gId, setData, setState])

  useEffect(() => {
    getData()
  }, [getData, reGetData])

  if (!Completion) return <Loading />

  const nextDisabled = !Approve || (Approve === 'Reject' && !Notes?.length)

  const onNext = () => {
    let PrintSettings: PrintSettingITF[] = []
    if (selectOption) {
      selectOption.Selected = true
      PrintSettings = [selectOption]
    }
    if (best) {
      best.Selected = true
      PrintSettings.push(best)
    }
    savePrintProofs({ GroupId: gId, BuildingId: bId, QrName, PrintSettings, Notes, Approve })
      .then(() => {
        setState({ reGetData: !reGetData })
      })
      .catch((err) => {
        SnackBar({ msg: err })
      })
  }
  const itemClick = (item: string) => {
    setData((prevState) => {
      return Object.assign({}, prevState, { Approve: item })
    })
    if (item === 'Reject') {
      forwardRef.current.showDialog()
    }
    setState({ reRender: !reRender })
  }
  const onClickOption = () => {
    setState({ showOption: true })
  }
  const onClose = () => {
    setState({ showOption: false })
  }
  const onSelect = (item: PrintSettingITF) => {
    setState({ selectOption: item, showOption: false })
  }
  const onChangeNotes = (notes) => {
    setData((prevState) => {
      return Object.assign({}, prevState, { Notes: notes || [] })
    })
    setState({ reRender: !reRender })
  }
  const name = `${QrName}`
  return (
    <Box className={classes.root}>
      <BackButton border title={`${propertyName || ''} - Sign proof`} disabledBack />
      <ProgressBarWithTitle percent={Complete / Math.max(Total, 1)} className={classes.progress} />
      <Box sx={{ display: 'flex' }} style={{ display: Complete === Total ? 'none' : '' }}>
        <Box sx={{ marginRight: '16px', minWidth: '576px' }}>
          {best && (
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Best Practice</Typography>
              <Box className={classes.cardImgBox}>
                <img className={classes.cardImg} src={best.PrintTemplateImage} />
              </Box>
            </Box>
          )}
          {selectOption && (
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Alternative Selection</Typography>
              <Box className={classes.cardImgBox}>
                <img className={classes.cardImg} src={selectOption?.PrintTemplateImage} alt="" />
              </Box>
            </Box>
          )}
          <Button className={classes.btnOption} onClick={onClickOption}>
            Other Options
            <ExpandMore htmlColor="#466CB5" style={{ verticalAlign: 'middle' }} />
          </Button>
        </Box>
        <Box className={classes.right}>
          <Box className={classes.bottom}>
            <Typography className={classes.qrName}>{name}</Typography>
            <Box className={classes.bottomContent}>
              <IconButton color="primary" disabled>
                <ArrowBackIcon />
              </IconButton>
              <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                {['Approve', 'Reject'].map((item, index) => {
                  const isActive = Approve === item
                  return (
                    <Box
                      key={item}
                      className={classnames(classes.item, {
                        [classes.itemActive]: isActive,
                      })}
                      onClick={() => itemClick(item)}
                    >
                      <Box
                        className={classnames(classes.icon, { [classes.itemActive]: isActive })}
                        style={{ backgroundColor: item === 'Reject' ? '#E04F4F' : '#50B258' }}
                      >
                        {item === 'Approve' ? (
                          <ThumbUp style={{ fontSize: 32, color: '#fff' }} />
                        ) : (
                          <ThumbDown style={{ fontSize: 32, color: '#fff' }} />
                        )}
                      </Box>
                      <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>{item.toLocaleUpperCase()}</Typography>
                    </Box>
                  )
                })}
              </Box>
              <IconButton color="primary" onClick={onNext} disabled={nextDisabled}>
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: '#fff', borderRadius: '8px', padding: '16px 32px' }}>
            <NotePad data={Notes} onChange={onChangeNotes} name={name} forwardRef={forwardRef} key={`item-${name}`} />
          </Box>
        </Box>
      </Box>
      {showOption && (
        <PrintSettingSelected
          qrName={QrName}
          printSettings={printSetting}
          selected={selectOption}
          onClose={onClose}
          onSelect={onSelect}
        />
      )}
      <Box style={{ display: Complete === Total ? '' : 'none' }} className={classes.complete}>
        <CheckCircle style={{ fontSize: 120, color: '#50B258' }} />
        <Typography sx={{ fontSize: 30, textAlign: 'center' }}>
          Thank you for submitting your review of this proof!
        </Typography>
      </Box>
    </Box>
  )
}

export default SignProof
