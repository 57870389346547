import React, { useReducer, createContext } from 'react'
import { clearDefaultData } from '@utils/globalData'

const actions = {
  UPDATE: 'UPDATE',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
}

export interface AppValues {
  currentGroupId: string
  currentGroupName: string
  currentPropertyId: string
  currentPropertyName: string
  currentProperty: any
}

const defaultValues = {
  currentGroupId: '',
  currentGroupName: '',
  currentPropertyId: '',
  currentPropertyName: '',
  currentProperty: '',
}

export const AppContext = createContext<any>(defaultValues)

export const AppReducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE:
      return { ...state, ...action.data }
    default:
      return state
  }
}

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, defaultValues)
  const value = {
    state,
    updateGroup: (data) => {
      clearDefaultData()
      dispatch({ type: actions.UPDATE, data })
    },
    updateData: (data) => {
      clearDefaultData()
      dispatch({ type: actions.UPDATE, data })
    },
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
