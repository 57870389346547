import { PanelChoice } from '@/types/panel'
import { QRCodeFunctionType } from '@/types'
import { getRecentWeekly } from '@utils/DateUtil'

const ButtonStyleChoice: PanelChoice[] = [
  {
    label: 'Squares',
    value: 'Squares',
  },
  {
    label: 'Rectangles',
    value: 'Rectangles',
  },
  {
    label: 'Compact',
    value: 'Compact',
  },
]

export const WeeklyDay: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const QRCodeFunctionTypeChoiceFunc: () => PanelChoice[] = () => {
  const choices: PanelChoice[] = []
  for (const key in QRCodeFunctionType) {
    // @ts-ignore
    if (![QRCodeFunctionType.JoinWifi, QRCodeFunctionType.Portfolio].includes(QRCodeFunctionType[key])) {
      choices.push({
        label: key,
        // @ts-ignore
        value: QRCodeFunctionType?.[key],
      })
    }
  }
  return choices
}
const NumberOfButtonsFun = () => {
  return new Array(50).fill(0).map((_, index) => {
    const v = index + 1
    return {
      label: `${v}`,
      value: v,
    }
  })
}
const QRCodeFunctionTypeChoice = QRCodeFunctionTypeChoiceFunc()
const NumberOfButtons = NumberOfButtonsFun()

export { ButtonStyleChoice, NumberOfButtons, QRCodeFunctionTypeChoice }
