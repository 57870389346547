import React from 'react'
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#000000',
    opacity: 0.6,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  lastText: {
    color: '#000000',
    opacity: 0.85,
    cursor: 'default',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  separator: {
    marginRight: 0,
  },
}))

export interface BreadcrumbsLink {
  text: string
  to?: string
}

interface Props {
  links?: BreadcrumbsLink[]
  onClick: (item: BreadcrumbsLink, index: number) => void
}

const Breadcrumbs: React.FC<Props> = ({ links = [], onClick }) => {
  const classes = useStyles()
  const len = links.length
  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator="" classes={{ separator: classes.separator }}>
      {links?.map((item, index) => {
        const isLast = len - 1 === index
        return (
          <div className={classes.item} key={item.text + index}>
            <NavigateNext fontSize="small" className={classnames({ [classes.lastText]: isLast })} />
            <Typography
              className={classnames(classes.text, { [classes.lastText]: isLast })}
              onClick={() => {
                if (isLast) return
                onClick?.(item, index)
              }}
            >
              {item.text}
            </Typography>
          </div>
        )
      })}
    </MUIBreadcrumbs>
  )
}
export default Breadcrumbs
