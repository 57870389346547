import React, { useCallback, useEffect, useState } from 'react'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import { makeStyles } from '@mui/styles'
import { Autocomplete, TextField } from '@mui/material'
import { getRounds } from '@api'
import { ExpandMore } from '@mui/icons-material'

interface Props {
  onChange?: (staticForm: any) => void
  value?: any
  disabledEdit?: boolean
  groupId
  buildingId
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      padding: '0px 16px',
    },
    title: {
      color: '#0D1F2C',
      fontSize: 12,
    },
    date: {
      margin: '20px 0 0',
    },
  }
})

export const ROUNDINSPECTION_REQUIRED = {
  TemplateId: '',
}

const RoundInspectionPanel: React.FC<Props> = ({ onChange, value, disabledEdit = false, groupId, buildingId }) => {
  const [state, setState] = useState(value || {})
  const classes = useStyles()
  const [list, setList] = useState<Record<string, any>[]>([])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, oriValue) => {
    const newValue = event.target?.value || oriValue
    const itemData = {
      TemplateId: newValue.RoundId,
      Name: newValue.QrName,
    }
    setState((prevState: any) => {
      const newData = Object.assign({}, prevState, { ...itemData, isChange: true })
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.RoundInspection, newData) })
      setState(newData)
      onChange?.(newData)
      return { ...prevState, ...itemData }
    })
  }, [])

  useEffect(() => {
    getRounds({ filter: JSON.stringify({ gId: groupId, bId: buildingId }) }).then((res: any) => {
      setList(res?.Rounds)
    })
  }, [groupId, buildingId])

  useEffect(() => {
    if (!value?.TemplateId) {
      //@ts-ignore
      handleChange({}, {})
    }
  }, [])

  return (
    <div className={classes.container}>
      <Autocomplete
        value={list.find((v) => v.RoundId === state?.TemplateId) || null}
        //@ts-ignore
        onChange={handleChange}
        options={list}
        style={{ marginTop: 16 }}
        isOptionEqualToValue={(option, value) => option?.RoundId === value?.RoundId}
        getOptionLabel={(option) => option.QrName || ''}
        renderInput={(params) => <TextField {...params} label="Round" />}
        disabled={disabledEdit}
        popupIcon={<ExpandMore />}
      />
    </div>
  )
}

export default RoundInspectionPanel
