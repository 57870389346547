import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { SocialForm, SocialInfo } from '@activate-inc/activate-ui'
import { FormDataContext } from '../../context/FormDataContext'
import { QRCodeFunctionType } from '../../types'

const SocialFormWrapper = (props: any) => {
  const { state } = useContext(FormDataContext)
  const contextTemplate = state?.[QRCodeFunctionType.SocialMedia]
  const template: SocialInfo = props.template
  const showTemplate = props.isHubPanel ? template : contextTemplate
  return template ? (
    <SocialForm socialInfo={showTemplate} {...props} />
  ) : (
    <Typography align="center">Please select one template</Typography>
  )
}

export default SocialFormWrapper
