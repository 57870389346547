import { Box, IconButton, Typography } from '@mui/material'
import { CancelRounded, InsertDriveFile, Replay } from '@mui/icons-material'
import Images from '@assets/images'
import { excelAccept } from '@constant/app'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { toBase64 } from '@/utils'
import { uploadFileBase64 } from '@api'
import CONFIG from '@constant/config'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import SnackBar from '@component/SnackBar'
import { format } from 'date-fns'

const useStyles = makeStyles(() => {
  return {
    container: {
      flex: 1,
      border: '1px dashed #BCBCBC',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#211F1F',
      fontSize: 20,
      padding: '36px 16px',
      fontWeight: 600,
      textAlign: 'center',
      marginTop: 24,
    },
    xlsx: {
      color: '#707070',
      fontSize: 16,
      fontWeight: 400,
      paddingTop: 16,
    },
    uploadText: {
      fontSizes: 20,
      fontWeight: 600,
      paddingTop: 16,
      cursor: 'pointer',
    },
    imgBox: {
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: 'rgba(25, 118, 210, 0.2)',
      '& img': {
        display: 'block',
      },
    },
    list: {
      width: '90%',
      borderBottom: '1px solid #BCBCBC',
    },
    fileName: {
      background: '#F5F5F5',
      display: 'flex',
      padding: 16,
      alignItems: 'center',
      textAlign: 'left',
      borderRadius: 8,
      marginBottom: 16,
    },
  }
})

export interface DragUploadSuccessFile {
  fileUrl: string
  fileName: string
}

interface Props {
  filePath?: string
  maxSize?: boolean
  uploadSuffix?: string
  type?: 'picture' | 'excel' | 'default'
  onSuccess?: (file: DragUploadSuccessFile[]) => void
  onDelete?: (file: DragUploadSuccessFile[], index: number) => void
  className?: string
  defaultUrl?: string | DragUploadSuccessFile[]
  multiline?: boolean
  disabled?: boolean
}
const TYPE_MAP: { [key in string]: { mMaxSize?: number; mAccept?: string; mAcceptText?: string } } = {
  picture: {
    mMaxSize: 3 * 1024 * 1024,
    mAccept: 'image/*',
    mAcceptText: 'SVG, PNG, JPG (Max 3MB)',
  },
  excel: {
    mAccept: excelAccept,
    mAcceptText: 'XLSX format',
  },
  default: {},
}
const DragUploadFile: React.FC<Props> = ({
  filePath,
  maxSize,
  type = 'default',
  onDelete,
  onSuccess,
  className,
  uploadSuffix,
  defaultUrl,
  multiline = false,
  disabled,
}) => {
  const classes = useStyles()
  const htmlFor = useMemo(() => `drag-upload-${Math.random()}`, [])
  const { selectedGroupId, selectedPropertyId, user: { acId } = {} } = useSelector((state) => state.profile)
  const [data, setData] = useState({
    uploading: false,
    isIncorrect: false,
    files: [] as DragUploadSuccessFile[],
  })
  const { uploading, isIncorrect, files } = data
  const { mAccept, mAcceptText, mMaxSize } = TYPE_MAP[type] || TYPE_MAP.default
  const filesLen = files?.length
  const importState = !uploading || multiline || (multiline! && filesLen === 0)
  const FilePath = useMemo(() => {
    if (filePath) return filePath
    return `${selectedGroupId}/${selectedPropertyId}/${acId}`
  }, [selectedGroupId, selectedPropertyId, acId, filePath])
  const onDeleteCall = (index: number) => {
    setData((pre) => {
      pre.files.splice(index, 1)
      onDelete?.(pre.files, index)
      return { ...data }
    })
  }

  const onFileChange = useCallback(
    async (event: any) => {
      event.preventDefault()
      let file = event.target.files?.[0] as File
      if (!file) {
        file = event.dataTransfer.files[0] as File
      }
      const { name, type, size } = file
      let isIncorrect = false
      if (type === 'excel') {
        isIncorrect = !excelAccept.includes(type)
      } else if (type === 'picture') {
        isIncorrect = !/^image\/[a-z]*$/.test(type)
      }
      if (isIncorrect) {
        setData((data) => ({ ...data, isIncorrect: true }))
        return SnackBar({ msg: `The file is not type ${mAcceptText}`, type: 'error', duration: 3000 })
      }
      if (mMaxSize && size > 3 * mMaxSize) {
        return SnackBar({
          msg: `The file is larger than ${Math.floor(mMaxSize / 1024 / 1024)}MB`,
          type: 'error',
          duration: 3000,
        })
      }
      setData((data) => ({ ...data, uploading: true, fileType: true }))
      const base64 = await toBase64(file)
      const result: any = await uploadFileBase64({
        fileType: type,
        base64,
        fileName: `${FilePath}/${format(new Date(), 'MM-dd-yyyy-HH-mm')}_${name}`,
        bucket: CONFIG.s3_Bucket,
      }).finally(() => setData((data) => ({ ...data, uploading: false })))
      const newItem = { fileUrl: result?.fileUrl, fileName: name }
      setData((pre) => {
        if (multiline) {
          pre.files.push(newItem)
        } else {
          pre.files = [newItem]
        }
        onSuccess?.(pre.files)
        return { ...pre, uploading: false, isIncorrect: false }
      })
    },
    [FilePath, mAcceptText, mMaxSize, onSuccess, multiline]
  )

  useEffect(() => {
    if (!multiline && defaultUrl && typeof defaultUrl === 'string') {
      setData((data) => ({
        ...data,
        files: [
          {
            fileUrl: defaultUrl,
            fileName: defaultUrl.substring(defaultUrl.lastIndexOf('/') + 1) as string,
          },
        ],
      }))
    }
    if (multiline && Array.isArray(defaultUrl) && defaultUrl.length) {
      const files = defaultUrl.map((v) => {
        const fileUrl = v?.fileUrl || ''
        return {
          ...v,
          fileName: v.fileName || fileUrl.substring(fileUrl.lastIndexOf('/') + 1),
        }
      })
      setData((data) => ({
        ...data,
        files,
      }))
    }
  }, [defaultUrl, multiline])
  return (
    <Box
      className={classnames(classes.container, className)}
      onDrop={onFileChange}
      draggable={!disabled}
      onDragOver={(e) => e.preventDefault()}
    >
      {!filesLen && (
        <Box className={classes.imgBox}>
          {uploading ? <Replay htmlColor="#1976D2" /> : <img src={Images.iconImport} alt="logo" />}
        </Box>
      )}
      {!!filesLen && (
        <Box className={classes.list}>
          {files.map((v, i) => {
            return (
              <Box
                className={classes.fileName}
                key={i}
                onClick={() => {
                  window.open(v?.fileUrl, '_blank')
                }}
              >
                <InsertDriveFile htmlColor="#1976D2" />
                <Typography style={{ flex: 1, paddingLeft: 16 }}>{v?.fileName}</Typography>
                <IconButton>
                  <CancelRounded
                    htmlColor="rgba(0,0,0,0.5)"
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteCall(i)
                    }}
                  />
                </IconButton>
              </Box>
            )
          })}
        </Box>
      )}
      {uploading && (
        <Typography className={classes.uploadText} style={{ color: '#1976D2' }}>
          Uploading...
        </Typography>
      )}

      <input
        accept={mAccept}
        style={{ display: 'none' }}
        disabled={disabled}
        id={htmlFor}
        onChange={onFileChange}
        type="file"
      />
      <label htmlFor={htmlFor} style={{ display: importState ? '' : 'none' }}>
        <Typography className={classes.uploadText}>
          Drag & drop or <span style={{ color: '#1976D2' }}>choose file</span> to upload {uploadSuffix || ''}
        </Typography>
      </label>

      <Typography style={{ display: importState ? '' : 'none' }} className={classes.xlsx}>
        {mAcceptText}
      </Typography>

      {isIncorrect && (
        <Box className={classes.fileName} style={{ width: 'auto', padding: '6px 12px' }}>
          <img src={Images.iconWarn} width="18" alt="logo" />
          <Typography style={{ paddingLeft: 16, color: '#E04F4F' }}>Incorrect file type, please try again</Typography>
        </Box>
      )}
    </Box>
  )
}

export default DragUploadFile
