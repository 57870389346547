import * as React from 'react'
import { ReactElement } from 'react'
import { IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

const useStyles = makeStyles(
  () => ({
    listItem: {
      paddingLeft: 16,
      minHeight: 35,
      width: 230,
    },
    listItemText: {
      color: '#A2A2A2',
      fontSize: 12,
      fontWeight: 400,
      margin: 0,
      wordBreak: 'break-word',
      paddingLeft: 5,
    },
    listItemRoot: {
      flex: 1,
    },
  }),
  { name: 'FilterItem' }
)

const FilterItem = (props: {
  label?: string | ReactElement
  value: any
  isSelected?: boolean
  count?: number
  toggleFilter?: () => void
}) => {
  const { label, isSelected, toggleFilter, count } = props
  const classes = useStyles()
  return (
    <ListItem button onClick={toggleFilter} className={classes.listItem}>
      <IconButton
        size="small"
        onClick={(event) => {
          event.stopPropagation()
          toggleFilter?.()
        }}
      >
        {isSelected ? <CheckBox htmlColor="#386BBF" /> : <CheckBoxOutlineBlank />}
      </IconButton>
      <ListItemText
        primary={label}
        classes={{
          root: classes.listItemRoot,
          primary: classes.listItemText,
        }}
        data-selected={isSelected ? 'true' : 'false'}
      />
      {!!count && <Typography className={classes.listItemText}>({count})</Typography>}
    </ListItem>
  )
}
export default FilterItem
