import React, { useEffect, useState } from 'react'
import { useLocaleState, useRedirect } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from '@mui/styles'
import { getPropertyList } from '@api'
import { selectedProperty } from '@reducer/profile'
import { IEntity } from '../types'
import { ExpandMore } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    height: 48,
    backgroundColor: 'white',
    '&[class*=MuiOutlinedInput-root]': {
      padding: '0 9px',
    },
  },
  label: {
    transform: 'translate(14px, 12px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
}))

interface SearchProps {
  style?: React.CSSProperties
  isBarSearch?: boolean
  label?: string
  gId?: string
  bId?: string
  onChange?: (property: IEntity) => void
  className?: string
  inputRootClass?: string
}
const disabledToPropertyInfo = (path: string) => {
  return (
    path.includes('/analytics/dashboard') ||
    path.includes('/analytics/notifications') ||
    path.includes('/analytics/reports') ||
    path.includes('/analytics/subscription')
  )
}
const Search = (props: SearchProps) => {
  const { style = {}, isBarSearch, gId, bId, onChange, label, className = '', inputRootClass } = props
  const { user, isCreateBuilding, selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const [loading, setLoading] = useState(true)
  const groupId = typeof gId === 'undefined' ? selectedGroupId || user?.UserContext?.CurrentGroupId : gId
  const propertyId = typeof bId === 'undefined' ? bId || selectedPropertyId : bId
  const [entities, setEntities] = React.useState<IEntity[]>([])
  const [disabled, setDisabled] = React.useState(false)
  const [locale] = useLocaleState()
  const redirectTo = useRedirect()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const classes = useStyles()
  const fetchProperties = async (gId?: string) => {
    if (!gId) return
    setLoading(true)
    const { Properties, Total } = await getPropertyList<{ Properties: IEntity[]; Total: number }>({
      filter: JSON.stringify({ gId }),
      pagination: JSON.stringify({ page: 0, perPage: 999 }),
    })
    setEntities(Properties)
    setLoading(false)
    setDisabled(false)
    if (Total === 1) {
      if (onChange) {
        return onChange(Properties[0])
      }
      setDisabled(true)
      const name = Properties[0].Name?.[locale] || ''
      const buildingCode = Properties[0].Code
      const propertyId = Properties[0].id
      const propertyTag = Properties[0].PropertyTag || {}
      dispatch(selectedProperty({ propertyId, propertyName: name, isOneProperty: true, buildingCode, propertyTag }))
      if (disabledToPropertyInfo(pathname)) return
      if (pathname.includes('/properties')) {
        redirectTo('/library/qrcodelist')
      }
    }
  }

  useEffect(() => {
    fetchProperties(groupId)
  }, [groupId])
  useEffect(() => {
    if (!isCreateBuilding) return
    fetchProperties(groupId)
  }, [groupId, isCreateBuilding])

  const option = entities?.find((v) => v.id === propertyId)
  return (
    <Autocomplete
      id="free-solo-2-demo"
      disableClearable
      disabled={disabled}
      loading={loading}
      className={className}
      classes={{ inputRoot: `${classes.textField}  ${inputRootClass}` }}
      onChange={(_, Property) => {
        const { id, Name, Code, PropertyTag = {} } = Property as IEntity
        if (onChange) {
          return onChange(Property)
        }
        dispatch(
          selectedProperty({ propertyId: id, propertyName: Name[locale], buildingCode: Code, propertyTag: PropertyTag })
        )
        if (disabledToPropertyInfo(pathname)) return
        redirectTo(`/propertyAdd/${id}`)
      }}
      style={style}
      options={entities}
      // @ts-ignore
      value={option || null}
      getOptionLabel={(option) => `${option.Name?.[locale]}` || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ classes: { root: classes.label } }}
          label={label}
          placeholder="Select Building"
          variant="outlined"
        />
      )}
      popupIcon={<ExpandMore />}
    />
  )
}

export const PropertySearch = React.memo(Search)
