import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, IconButton, TextField, Typography, Modal, MenuItem } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { MaterialKey, MaterialObj, MaterialOptions } from '@constant/qrcode'
import { getPair, getPrintTemplates, getTwoQrLocations, getTwoQRTypesByLocation, qrCodePair } from '@api'
import { useSelector } from 'react-redux'
import SnackBar from '@component/SnackBar'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import classnames from 'classnames'
import PanelSelection from '@component/EditPanel/PanelSelection'
import useSetState from '@hooks/useSetState'
import { PanelChoice } from '@/types/panel'
import QrSelect from '@pages/twoQRLibrary/components/QrSelect'
import { useNavigate } from 'react-router-dom'
import { Loading } from 'react-admin'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#386BBF',
    fontSize: 16,
    marginTop: 24,
    marginBottom: 12,
  },
  textField: {
    width: '100%',
    border: 'none',
  },
  select: {
    maxHeight: 300,
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  cellInputRoot: {
    borderRadius: 4,
    height: 56,
  },
  cellText: {
    color: '#676767',
    fontSize: 12,
  },
  header: {
    backgroundColor: '#edf0f5',
  },
  headerTitle: {
    color: '#676767',
    boxSizing: 'border-box',
    fontWeight: 'normal',
    fontSize: 14,
  },
  btn: {
    margin: 30,
    alignSelf: 'center',
    height: 34,
    textTransform: 'none',
    color: 'white',
    padding: '8px 30px',
    backgroundColor: '#1976D2',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#1976D2',
    },
    '&.Mui-disabled': {
      color: 'white',
      opacity: 0.5,
    },
  },
  close: {
    position: 'sticky',
    top: 0,
    right: 0,
    textAlign: 'right',
    background: '#fff',
    zIndex: 2,
    marginRight: -36,
  },
  center: {
    textAlign: 'center',
  },
  mt: {
    marginBottom: 24,
  },
  display: {
    width: '100%',
    border: '1px solid #BCBCBC',
    margin: '8px 12px',
    borderRadius: 4,
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'row',
  },
  body: {},
  column: {
    flexDirection: 'column',
  },
  displayItem: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fpo: {
    width: 168,
    height: 168,
    background: '#D9D9D9',
    borderRadius: 6,
    textAlign: 'center',
    lineHeight: '168px',
    color: 'rgba(0,0,0,0.7)',
    margin: 12,
  },
  borderBox: {
    boxSizing: 'border-box',
  },
}))
const getConfigData = (option: any) => {
  const configData = [
    { name: 'FPO1', title: 'FPO Placement', option: ['FPO 1', 'FPO 2'] },
    { name: 'FPO2', title: 'FPO Placement', disabled: true, option: ['FPO 1', 'FPO 2'] },
    { name: 'Orientation', title: 'Orientation', option: ['Landscape', 'Portrait'], gridSize: 12 },
    { name: 'Display', title: '', option: [['[CTA Text 1]', '[CTA Text 2]'], ['FPO 1', 'FPO 2'], ['Point & Scan']] },
    { name: 'Quantity', title: 'Quantity' },
    { name: 'Material', title: 'Material', option: MaterialOptions, width: 150 },
    { name: 'Size', title: 'Size', option: [] },
    { name: 'Color', title: 'Color', option: ['Light Gray', 'Light Blue', 'Dark Blue', 'Dark Gray'] },
    {
      name: 'PrintTemplate',
      title: 'Print Template',
      option: option.PrintTemplate || [],
      isObj: true,
      valueKey: 'Code',
      displayKey: 'FileName',
      width: 200,
    },
    { name: 'FileFormat', title: 'File Format', option: ['png', 'svg', 'eps', 'pdf'] },
  ]
  return { configData }
}

interface MyObject {
  [key: string]: any
}

interface PrintKitPanelProps {
  configData: Array<MyObject>
  gId: string
  bId: string
  onGenerateSuccess: () => void
  language: LanguageType
  printKit: null | MyObject
  pair: null | MyObject
  disabledEdit?: boolean
}

const PrintKitPanel: React.FC<PrintKitPanelProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { configData, gId, bId, onGenerateSuccess, language, printKit, pair, disabledEdit } = props
  const [state, setState] = useSetState<{
    locations: Array<PanelChoice>
    qrTypes: Array<PanelChoice>
    qrCodeIds: Array<string | undefined>
    location?: string
    pId?: string
    qrType: string
    btnText: string
    fpoList: string[]
    selectQrTypes?: Array<string>
  }>({
    locations: [],
    qrTypes: [],
    fpoList:
      pair?.QrCodes?.map((v: any) => {
        return `${v?.SignId} ${v?.Name}`
      }) || [],
    location: pair?.Location || '',
    pId: pair?.acId,
    btnText: pair?.acId ? 'Edit 2QR' : 'Create 2QR',
    qrType: pair?.Type || '',
    qrCodeIds: pair?.QrCodeIds || [undefined, undefined],
    selectQrTypes: pair?.QrCodes?.map((v: any) => v.Type) || [],
  })
  const { locations, qrTypes, location, qrType, selectQrTypes, qrCodeIds, fpoList, pId, btnText } = state
  const initData = useMemo(
    () => ({
      FPOPlacement: 'FPO 1',
      Quantity: '',
      Material: '3mm Sintra',
      Size: '5 × 8',
      Orientation: 'Landscape',
      Color: 'Light Gray',
      PrintTemplate: '',
      FileFormat: 'eps',
      ...printKit,
    }),
    [printKit]
  )
  const [data, setData] = useState<MyObject>(initData)
  const { Material, FPOPlacement, Orientation } = data

  useEffect(() => {
    setData({ ...initData })
  }, [initData, printKit])

  const onGenerate = useCallback(async () => {
    const { label, FPOPlacement, ...extra } = data
    const params = { ...extra, FPOPlacement, QrCodeIds: qrCodeIds, Type: qrType, Location: location }
    if ([data.Quantity, data.PrintTemplate].filter((v) => ['', undefined].includes(v)).length) {
      return SnackBar({ msg: 'Quantity, Print Template are Required' })
    }

    const FPO1 = fpoList[FPOPlacement === 'FPO 1' ? 0 : 1]
    const FPO2 = fpoList[FPOPlacement === 'FPO 1' ? 1 : 0]
    Object.assign(params, { QrPairId: pId, GroupId: gId, FPO1, FPO2 })
    qrCodePair(params).then((res: any) => {
      !pId && setState({ pId: res.QrPair?.acId })
      SnackBar({ msg: `${pId ? 'Edit' : 'Save'} Successful`, type: 'success' })
      onGenerateSuccess()
    })
  }, [data, qrCodeIds, qrType, fpoList, pId, gId, setState, onGenerateSuccess, location])

  const handleChange = useCallback(
    (name, rowValue, valueKey, displayKey) => {
      // @ts-ignore
      const value = typeof rowValue === 'string' ? rowValue : rowValue[valueKey || 'Name']
      // @ts-ignore
      const displayText =
        typeof rowValue === 'string' ? rowValue : rowValue[displayKey]?.[language] || rowValue[displayKey]
      setData((pre) => {
        if (name === 'Quantity' && !/^[0-9]*$/.test(value)) {
          return pre
        }
        pre[name] = value
        if (!pre.label) pre.label = {}
        pre.label[name] = displayText
        const { Material } = pre
        if (name === 'Material') {
          pre['Size'] = MaterialObj[Material as MaterialKey]['default']
        }
        if (name === 'FPO1') {
          pre['FPOPlacement'] = value
        }
        return { ...pre }
      })
    },
    [language]
  )
  const getLocation = useCallback(() => {
    getTwoQrLocations({}).then((res: any) => {
      setState({
        locations: (res?.Locations || [])?.map((item) => ({ label: item?.DisplayText?.en, value: item?.Name })),
      })
    })
  }, [setState])
  const getQrTypes = useCallback(
    (location: string) => {
      getTwoQRTypesByLocation({ location }).then((res: any) => {
        setState({
          qrTypes: (res?.Types || [])?.map((item) => ({ label: item?.DisplayText?.en, value: item?.Name, ...item })),
        })
      })
    },
    [setState]
  )
  const onChangeLocation = useCallback(
    (value: PanelChoice | null) => {
      const location = value?.value as string
      setState({ location, qrTypes: [], qrType: '' })
      getQrTypes(location)
    },
    [setState, getQrTypes]
  )
  const onOnChangeQr1 = useCallback(
    (qrId: string, data: any) => {
      const newData = [...qrCodeIds]
      const newFpoList = !fpoList || fpoList.length !== 2 ? ['', ''] : fpoList
      newFpoList[0] = `${data?.SignId} ${data?.Name}`
      newData[0] = qrId
      setState({ qrCodeIds: newData, fpoList: newFpoList })
    },
    [qrCodeIds, setState, fpoList]
  )

  const onOnChangeQr2 = useCallback(
    (qrId: string, data: any) => {
      const newData = [...qrCodeIds]
      newData[1] = qrId
      const newFpoList = !fpoList || fpoList.length !== 2 ? ['', ''] : fpoList
      newFpoList[1] = `${data?.SignId} ${data?.Name}`
      setState({ qrCodeIds: newData, fpoList: newFpoList })
    },
    [qrCodeIds, setState]
  )
  const onChangeQrType = useCallback(
    (value: PanelChoice | null) => {
      setState({ qrType: value?.value as string, selectQrTypes: value?.QrTypes })
    },
    [setState]
  )
  useEffect(() => {
    if (pair?.acId && pair.Location) {
      getQrTypes(pair.Location)
    }
  }, [getQrTypes, pair])

  useEffect(() => {
    getLocation()
  }, [getLocation])
  const isShowQrType = !!location
  const isShowSelectQr = isShowQrType && !!qrType
  const isShowPrint = isShowSelectQr && qrCodeIds && qrCodeIds.findIndex((item) => !item) === -1

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PanelSelection
            height={50}
            width="100%"
            label="Location"
            value={locations.find((item) => item.value === location) || null}
            choices={locations || []}
            onChange={onChangeLocation}
          />
        </Grid>
        {isShowQrType && (
          <Grid item xs={12}>
            <PanelSelection
              height={50}
              width="100%"
              label="2QR Type"
              value={qrTypes.find((item) => item.value === qrType) || null}
              choices={qrTypes || []}
              onChange={onChangeQrType}
            />
          </Grid>
        )}
        {isShowSelectQr && (
          <>
            <Grid item xs={6}>
              <QrSelect
                label="QR Code1"
                qrType={selectQrTypes?.[0]}
                location={location}
                bId={bId}
                gId={gId}
                qrId={qrCodeIds?.[0]}
                onChange={onOnChangeQr1}
              />
            </Grid>
            <Grid item xs={6}>
              <QrSelect
                label="QR Code2"
                qrType={selectQrTypes?.[1]}
                location={location}
                bId={bId}
                gId={gId}
                qrId={qrCodeIds?.[1]}
                onChange={onOnChangeQr2}
              />
            </Grid>
          </>
        )}
        {isShowPrint &&
          configData.map((v, j) => {
            const { name, option = [], isObj, title, disabled, valueKey, displayKey, gridSize = 6 } = v
            let options = option
            if (name === 'Size') {
              const key = Material as MaterialKey
              options = MaterialObj[key]['option']
            }

            if (name === 'Display') {
              return (
                <Box
                  key={j}
                  className={classnames({
                    [classes.display]: true,
                    [classes.column]: Orientation === 'Landscape',
                  })}
                >
                  {options.map((v: string[], i: number) => {
                    return (
                      <Box
                        key={`${i}`}
                        className={classnames({
                          [classes.displayItem]: true,
                          [classes.column]: Orientation === 'Portrait',
                        })}
                      >
                        {v.map((text: string) => {
                          return (
                            <Box key={text} className={classes.displayItem}>
                              <Typography
                                className={classnames({
                                  [classes.fpo]: i === 1,
                                })}
                              >
                                {text}
                              </Typography>
                            </Box>
                          )
                        })}
                      </Box>
                    )
                  })}
                </Box>
              )
            }

            // @ts-ignore
            let value = isObj ? options.find((item) => item[valueKey || 'Name'] === data[name]) : data[name]
            if (['FPO1', 'FPO2'].includes(name)) {
              value = name === 'FPO1' ? FPOPlacement : option.find((v) => v != FPOPlacement)
            }

            return (
              <Grid item key={name} xs={gridSize}>
                <TextField
                  name={name}
                  select={options.length > 0}
                  value={value || ''}
                  variant="outlined"
                  label={title}
                  disabled={disabledEdit || disabled}
                  placeholder={title}
                  className={classes.textField}
                  SelectProps={{
                    displayEmpty: true,
                    classes: {
                      select: classes.select,
                    },
                    type: 'search',
                    MenuProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cellInputRoot,
                    },
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    handleChange(event.target.name, event.target.value, valueKey, displayKey)
                  }
                >
                  {options.map((v: any) => {
                    // @ts-ignore
                    const label = typeof v === 'string' ? v : v[displayKey]?.[language] || v[displayKey]
                    const key = typeof v === 'string' ? v : v[valueKey || 'Name']
                    return (
                      <MenuItem key={key} value={v}>
                        {label}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            )
          })}
      </Grid>

      {isShowPrint && (
        <Box sx={{ display: 'flex', alignSelf: 'flex-end', alignItems: 'center' }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button onClick={onGenerate} className={classes.btn} disabled={disabledEdit}>
            {btnText}
          </Button>
        </Box>
      )}
    </Box>
  )
}

interface Props {
  pId?: string
  disabledEdit?: boolean
}

const TwoQrContent: React.FC<Props> = ({ pId, disabledEdit }) => {
  const classes = useStyles()
  const language = 'en'
  const [option, setOption] = useState({})
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const { configData } = useMemo(() => getConfigData(option), [option])
  const [{ PrintKit, Pair }, setPairData] = useState({ PrintKit: {}, Pair: {} })

  const onGenerateSuccess = useCallback(() => {}, [])

  useEffect(() => {
    if (pId) {
      getPair({ pId })
        .then((res: any) => {
          setPairData({ Pair: res?.Pair, PrintKit: res?.PrintKit || {} })
        })
        .catch((e) => SnackBar({ msg: e }))
    }

    getPrintTemplates({ pair: true }).then((res) => {
      setOption((value) => Object.assign({}, value, { PrintTemplate: res }))
    })
  }, [pId])
  if (pId && Object.keys(PrintKit).length === 0) {
    return (
      <Box className={classes.body}>
        <Loading />
      </Box>
    )
  }
  return (
    <Box className={classes.body}>
      <PrintKitPanel
        configData={configData}
        pair={Pair}
        language={language}
        gId={selectedGroupId}
        bId={selectedPropertyId}
        printKit={PrintKit}
        disabledEdit={disabledEdit}
        onGenerateSuccess={onGenerateSuccess}
      />
    </Box>
  )
}

export default TwoQrContent
