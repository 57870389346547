import React, { useCallback, useState } from 'react'
import { FieldForm, SuccessForm } from '@activate-inc/activate-ui'
import { Box, Typography } from '@mui/material'
import { Field } from '@activate-inc/activate-ui/dist/types/components/types'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { EntityITF } from '@/types/entity'

interface Props {
  data: Field[]
  title: string
  qrTypeName: string
  language: LanguageType
  entity: EntityITF
}

const CustomerSuportQRWrapper: React.FC<Props> = ({ data, language, title, entity, qrTypeName }) => {
  const [submit, setSubmit] = useState(false)
  const onSubmit = useCallback((data) => {
    setSubmit(true)
  }, [])

  if (submit) {
    return <SuccessForm text={'Your Request has been submitted. We will be in touch soon!'} />
  }

  if (!data) {
    return (
      <Box>
        <Typography align="center">Please select a template</Typography>
      </Box>
    )
  } else {
    return (
      <FieldForm
        isCenter={false}
        qrTypeName={qrTypeName}
        data={data}
        language={language}
        onSubmit={onSubmit}
        btnWidth="100%"
        disabledVerify
      />
    )
  }
}
export default CustomerSuportQRWrapper
