import { createSlice } from '@reduxjs/toolkit'
import { setAutoFreeze } from 'immer'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

setAutoFreeze(false)
const INITIAL_STATE = {
  selectedGroupId: '',
  selectedGroupName: '',
  selectedPropertyId: '',
  selectedPropertyName: '',
  buildingCode: '',
  user: null,
  isCreateBuilding: false,
  requestsBadgeCount: 0,
  groupDetail: {},
  propertyTag: {
    PropertyTagId: '',
    Name: '',
  },
}

let profileSlice = createSlice({
  name: 'profile',
  initialState: INITIAL_STATE,
  reducers: {
    // selectedProperty: function (state, action) {
    //     state.currentProperty = action.payload.groupId
    //     state.currentPropertyId = action.payload.groupName
    // },
    selectedGroup: function (state, action) {
      state.selectedGroupId = action.payload.groupId
      state.selectedGroupName = action.payload.groupName
      state.groupDetail = action.payload.group
      state.isCreateBuilding = false
    },
    clearGroup: function (state) {
      state.selectedGroupId = ''
      state.selectedGroupName = ''
      state.groupDetail = {}
      state.isCreateBuilding = false
    },
    selectedProperty: function (state, action) {
      state.selectedPropertyId = action.payload.propertyId
      state.selectedPropertyName = action.payload.propertyName
      state.buildingCode = action.payload.buildingCode
      state.propertyTag = action.payload.propertyTag
      state.isCreateBuilding = action.payload.isCreateBuilding
    },
    setUserData: function (state, action) {
      state.user = action.payload
      state.isCreateBuilding = false
    },
    resetReducer: function () {
      return {
        currentProperty: '',
        currentPropertyId: '',
        selectedGroupId: '',
        selectedGroupName: '',
        selectedPropertyId: '',
        selectedPropertyName: '',
        isCreateBuilding: false,
        buildingCode: '',
        user: null,
        requestsBadgeCount: 0,
        groupDetail: {},
      }
    },
  },
})

const { actions: reducerActions, reducer: profileReducer } = profileSlice

const profileSliceSaga = createSliceSaga({
  name: 'profile',
  caseSagas: {
    // eslint-disable-next-line require-yield
    selectedProperty: function* () {
      try {
        //   yield put(reducerActions.fetchUserInfoProcessing());
        //   const { payload } = action;
        //   const { data } = yield call(loginApis.fetchUserInfo);
        //   let userInfo = {};
        //   if (data) {
        //     userInfo = data.find(
        //       item =>
        //         item.username === payload.username &&
        //         item.password === payload.password,
        //     );
        //   }
        //   const { password, ...dataMapped } = userInfo;
        //   yield put(
        //     reducerActions.loginSuccess({ firebase: false, ...dataMapped }),
        //   );
      } catch (error) {
        //yield put(reducerActions.loginFailure(error));
      }
    },
  },
  sagaType: SagaType.TakeEvery,
})

const { actions: sagaActions, saga: profileSaga } = profileSliceSaga
const profileActions = { ...reducerActions, ...sagaActions }

export const { selectedProperty, selectedGroup, setUserData, clearGroup, resetReducer } = profileSlice.actions
export default profileSlice.reducer

export { profileSliceSaga, profileReducer, profileActions, profileSaga }
