import React, { useCallback, useRef } from 'react'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import { QRCodeFunctionType } from '@/types'
import useSetState from '@hooks/useSetState'
import { FieldItem } from '@utils/FieldType'
import { isCanSavaQr } from '@/utils'
import WithDefaultData from '@/hoc/WithDefaultData'
interface Props {
  onChange?: (data: any) => void
  value: any
  groupId: string
  buildingId: string
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
}
const language = 'en'

const BurnPermitPanel: React.FC<Props> = ({ onChange, value, functionType, disabledEdit, groupId, buildingId }) => {
  const refValue = useRef(value)
  const [state, setState] = useSetState({
    Fields: (value?.Fields || []) as FieldItem[],
  })
  const { Fields } = state
  const onChangeField = useCallback(
    (fields: FieldItem[]) => {
      const data = Object.assign({}, refValue.current, { Fields: fields || [] })
      Object.assign(data, { CanSave: isCanSavaQr(functionType, data) })
      onChange?.(data)
      setState({ Fields: fields || [] })
    },
    [functionType, onChange, setState]
  )
  return (
    <div>
      <DragFormPanel disabled={disabledEdit} language={language} data={Fields} onChangeField={onChangeField} />
    </div>
  )
}

export default WithDefaultData(BurnPermitPanel)
