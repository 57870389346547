import * as React from 'react'
import { useSelector } from 'react-redux'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getEventRatingSummary, getEventComments } from '@api'
import Echarts, { BarProps, EchartsProps, HeatMapProps, SeriesData, WordCloudProps } from '@component/Chart/Echarts'
import Rating from '@mui/lab/Rating'
import { formatPhoneNumber } from '@/utils'
import OverAllCard from '@component/Card/OverAllCard'
import CommentReviews from '@analytics/feedback/CommentReviews'
import { IComment, IStarBucket } from '@/types'
import {
  BooleanField,
  EmailField,
  Datagrid,
  DatagridProps,
  DateField,
  Identifier,
  List,
  ListContextProvider,
  ListProps,
  TextField,
  useListContext,
  useRecordContext,
  FunctionField,
  ExportButton,
  Toolbar,
} from 'react-admin'
import { Divider, Tabs, Tab, TextField as MaTextField, Grid, ListItemText } from '@mui/material'
import get from 'lodash/get'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import { PERIOD_OPTIONS, STAR_OPTIONS, COLOR_MAP, DEFAULT_PERIOD_YEAR_TO_DATE } from '@/global'
import {
  DateRange as DateRangeIcon,
  Schedule as ScheduleIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material'
import PhoneNumberFiled from '@component/List/PhoneNumberFiled'

const useDatagridStyles = makeStyles({
  backIcon: {
    borderRadius: '0px',
    color: '#374053',
    flexGrow: 0,
    fontFamily: 'Roboto, Regular',
    fontSize: '20px',
    textTransform: 'none',
    border: 'none',
    padding: '0px',
    height: 35,
    marginRight: 16,
    cursor: 'pointer',
    '&.Mui-disabled': {
      color: 'transparent',
      opacity: 0.5,
    },
  },
  total: {
    fontWeight: 'bold',
  },
  tab: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
    height: 70,
  },
  list: {
    '& .RaList-main': {
      marginTop: -30,
    },
  },

  dataGird: {
    marginTop: 60,
  },
  image: {
    '&>img': {
      width: 60,
      height: 60,
      marign: 0,
    },
  },
  dataGrid: {
    '& .RaDatagrid-headerCell': {
      border: 'none',
      backgroundColor: '#F4F6F8',
      fontWeight: 700,
      fontSize: 16,
      fontFamily: 'Roboto',
      color: '#211F1F',
      background: '#FFF',
      height: 48,
      whiteSpace: 'nowrap',
      padding: '12px 16px',
      boxSizing: 'border-box',
    },
    '& .RaDatagrid-rowCell': {
      padding: '12px 16px',
    },
    '& .RaDatagrid-row': {
      borderBottom: '1px solid #F5F5F5',
      '&:hover': {
        background: '#F0F6FC !important',
      },
    },
  },
  filterDate: {
    display: 'flex',
    marginLeft: '10px',
  },
  content: {
    margin: '0px 10px 0',
  },
  head: {
    color: '#4E4D4D',
    fontSize: 20,
    padding: '7px 0 15px',
    fontFamily: 'Roboto, Regular',
  },
  period: {
    height: 48,
    width: 200,
    color: '#4D4D4D',
    fontSize: 16,
    borderRadius: 6,
    backgroundColor: 'white',
  },
  notchedOutline: {
    borderColor: '#A29D9D',
  },
  container: {
    display: 'flex',
    padding: '16px 16px 21px',
  },
  listItemText: {
    overflowY: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  icon: { fontSize: 25, color: '#ADBACE', marginLeft: '10px', marginRight: '5px' },
  iconRow: { display: 'flex', justifyContent: 'flex-end', paddingRight: '16px', alignItems: 'center' },
})

const CommentPlaceHolder = () => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <img
      src="https://cdn.dribbble.com/users/2382015/screenshots/6065978/media/1273880a5b9cc8008301089835f37939.gif"
      height={300}
    />
  </Box>
)

const getBarOption = (seriesData: SeriesData): BarProps & EchartsProps => {
  return {
    id: 'bar',
    type: 'bar',
    width: '100%',
    height: '100%',
    xAxisData: ['5-Star', '4-Star', '3-Star', '2-Star', '1-Star'],
    seriesData,
  }
}

interface TabbedDatagridProps extends DatagridProps {}

const TabbedDatagrid = (props: TabbedDatagridProps & { bId?: string }) => {
  const listContext = useListContext()
  const { bId } = props
  const { filterValues, setFilters, displayedFilters } = listContext
  const { selectedGroupId, selectedPropertyId, user } = useSelector((state) => state.profile)
  const classes = useDatagridStyles()
  const [oldFilter, setOldFilter] = React.useState({})
  const [period, setPeriod] = React.useState<IOption>(DEFAULT_PERIOD_YEAR_TO_DATE)
  const [selectItem, setSelectItem] = React.useState<Record<string, any>>({})
  const [averageScore, setAverageScore] = useState<number | null>(null)
  const [barDataOption, setBarDataOption] = useState<BarProps & EchartsProps>()
  const [comments, setComments] = useState<IComment[] | null>(null)
  const [filterComments, setFilterComments] = useState<IComment[] | null>(null)

  const [loadingBarData, setLoadingBarData] = useState(true)
  const [loadingComments, setLoadingComments] = useState(true)

  const getSubList = Object.keys(selectItem).length !== 0
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const reviews = filterComments || comments
  const permissions = JSON.parse(localStorage.getItem('evnetSelectItem') || '{}')
  const cacheSelectItem = [selectItem, permissions].find((i) => i?.EventName && i?.StartDate && i?.EndDate)

  const handleDateChange = useCallback(
    (event: any, newValue: IOption) => {
      setPeriod(newValue)
      const filter = Object.assign(filterValues, { tu: newValue.value })
      setFilters && setFilters(filter, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )

  const eventClick = useCallback(
    (id: any, path: string, record: any) => {
      setOldFilter(filterValues)
      setSelectItem(record)
      localStorage.setItem('evnetSelectItem', JSON.stringify(record))
      const filterVal = { ...filterValues, eventId: record.acId, getSubList: true }
      setFilters && setFilters(filterVal, displayedFilters)
    },
    [setFilters, filterValues]
  )

  const onBack = useCallback(() => {
    setSelectItem({})
    const filterVal = { ...oldFilter, tu: period.value, getSubList: false }
    setFilters && setFilters(filterVal, displayedFilters)
  }, [period, oldFilter, displayedFilters])

  const onActionChange = useCallback(
    (option: IOption) => {
      option.value
        ? setFilterComments(
            (comments || []).filter((item: IComment) => {
              return item.Score === option.value
            })
          )
        : setFilterComments(null)
    },
    [comments]
  )

  const fetchEventRatingSummary = useCallback(async () => {
    const { AverageScore, StarBuckets } = await getEventRatingSummary({
      eventId: selectItem?.acId,
      gId: currentGroupId,
    })
    //@ts-ignore
    const seriesData: SeriesData =
      Array.isArray(StarBuckets) &&
      StarBuckets.map((item: IStarBucket) => {
        return {
          value: item.Count,
          itemStyle: COLOR_MAP[`Score${item.Score}`],
        }
      })
    setLoadingBarData(false)
    setAverageScore(AverageScore)
    setBarDataOption(getBarOption(seriesData))
  }, [selectItem, currentGroupId])

  const fetchEventComments = useCallback(async () => {
    // @ts-ignore
    const eventComments: any = await getEventComments({
      eventId: selectItem.acId,
      gId: currentGroupId,
      take: 100,
      skip: 0,
    })
    setLoadingComments(false)
    setComments(eventComments?.Comments)
  }, [selectItem, currentGroupId])

  useEffect(() => {
    if (currentGroupId !== filterValues.gId || (filterValues.bId && selectedPropertyId !== filterValues.bId)) {
      //防止头部 切换group时 gid不变
      setFilters && setFilters({ ...filterValues, gId: currentGroupId, bId }, displayedFilters)
    }
    if (filterValues.getSubList) {
      setOldFilter(filterValues)
      setSelectItem({ acId: filterValues.eventId })
    }
  }, [])

  useEffect(() => {
    const tabNameKey = 'tenantevent' + '-' + (getSubList ? 2 : 1)
    if (tabNameKey === 'tenantevent-2') {
      setLoadingBarData(true)
      setLoadingComments(true)
      fetchEventRatingSummary()
      fetchEventComments()
    }
  }, [getSubList])
  return (
    <Fragment>
      <div>
        <Box
          className={classes.filterDate}
          style={
            Object.keys(selectItem).length === 0
              ? { justifyContent: 'flex-end', padding: '15px 10px 0' }
              : { justifyContent: 'flex-start', padding: '14.5px 10px' }
          }
        >
          {Object.keys(selectItem).length === 0 ? (
            <Autocomplete
              //@ts-ignore
              value={period}
              onChange={handleDateChange}
              disableClearable
              id="controllable-states-demo"
              options={PERIOD_OPTIONS}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => {
                return (
                  <MaTextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      className: classes.period,
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      readOnly: true,
                    }}
                    placeholder="All Comments"
                  />
                )
              }}
            />
          ) : (
            <Box>
              <IconButton edge="start" color="inherit" className={classes.backIcon} onClick={onBack}>
                <ArrowBackIcon style={{ marginRight: 8 }} /> {cacheSelectItem.EventName}
              </IconButton>
            </Box>
          )}
        </Box>
        {Object.keys(selectItem || {}).length === 0 ? (
          <Box className={classes.content}>
            <Box className={classes.head}>List Of Event</Box>
            <ListContextProvider value={{ ...listContext }}>
              {/* @ts-ignore */}
              <Datagrid
                {...props}
                optimized
                //@ts-ignore
                rowClick={eventClick}
                className={classes.dataGrid}
                bulkActionButtons={false}
              >
                <TextField source="EventName" label="Title" />
                <DateField source="StartDate" label="Start Date" showTime />
                <DateField source="EndDate" label="End Date" showTime />
                <TextField source="EventLocation" label="Location" />
                <TextField source="ParticipantNumber" label="No. of Registrants" />
              </Datagrid>
            </ListContextProvider>
          </Box>
        ) : (
          <Box className={classes.content}>
            {cacheSelectItem?.StartDate && cacheSelectItem?.EndDate && (
              <Box className={classes.iconRow}>
                <DateRangeIcon className={classes.icon} />
                {new Date(cacheSelectItem.StartDate).toLocaleDateString()}
                <ScheduleIcon className={classes.icon} />
                {new Date(cacheSelectItem.StartDate).toTimeString().substring(0, 5)} -{' '}
                {new Date(cacheSelectItem.EndDate).toTimeString().substring(0, 5)}
              </Box>
            )}
            <Grid container spacing={1} className={classes.container}>
              <OverAllCard
                title={`Average Rating ${averageScore || ''}`}
                showRating
                ratingValue={averageScore}
                loading={loadingBarData}
              >
                {barDataOption?.id && <Echarts {...barDataOption} id="barchart" />}
              </OverAllCard>
              <OverAllCard
                title="Comments"
                loading={loadingComments}
                showDivider
                actions={STAR_OPTIONS}
                defaultAction={STAR_OPTIONS[0]}
                extensible={false}
                dataSize={(reviews && reviews.length) || 0}
                onActionChange={onActionChange}
              >
                {reviews && reviews.length > 0 ? (
                  <CommentReviews reviews={reviews} dataSize={(reviews && reviews.length) || 0} />
                ) : (
                  <CommentPlaceHolder />
                )}
              </OverAllCard>
            </Grid>
            <Box className={classes.head}>Event Registrants</Box>
            <ListContextProvider value={{ ...listContext }}>
              {/* @ts-ignore */}
              <Datagrid {...props} optimized bulkActionButtons={false}>
                <FunctionField
                  label="Rating"
                  render={(record) => (
                    <div>
                      <ListItemText
                        primary={<Rating readOnly defaultValue={record.Score} name={record.acId} />}
                        classes={{
                          secondary: classes.listItemText,
                        }}
                        style={{ paddingRight: 0 }}
                      />
                    </div>
                  )}
                />
                <TextField source="Name" label="Name" />
                <FunctionField
                  label="Attended"
                  render={(record) => (
                    <div>{record.Attended ? <CheckCircleIcon style={{ color: '#3D9E4A' }} /> : null}</div>
                  )}
                />
                <PhoneNumberFiled source="PhoneNumber" label="Phone No." />
                <EmailField source="Email" label="Email Address" />
              </Datagrid>
            </ListContextProvider>
          </Box>
        )}
      </div>
    </Fragment>
  )
}

const TenantExperienceEvent: React.FC<{ bId?: string }> = ({ bId }) => {
  const classes = useDatagridStyles()
  const { selectedGroupId, user } = useSelector((state) => state.profile)
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  return (
    <List
      disableSyncWithLocation
      className={classes.list}
      filter={{ tableName: 'tenantevent', gId: currentGroupId, bId }}
      filterDefaultValues={{
        tableName: 'tenantevent',
        gId: currentGroupId,
        bId,
        tu: DEFAULT_PERIOD_YEAR_TO_DATE.value,
      }}
      sort={{ field: 'CreatedDate', order: 'DESC' }}
      perPage={25}
    >
      <TabbedDatagrid bId={bId} />
    </List>
  )
}

export default TenantExperienceEvent
