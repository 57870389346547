import React, { useCallback, useState } from 'react'
import { Box } from '@mui/material'
import { PhoneCallTemplate } from '@/types/panel'
import { makeStyles } from '@mui/styles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import MobilePhone from '@/account/MobilePhone'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  input: {
    '& .form-control': {
      width: '100%',
      borderRadius: 10,
    },
    '& .flag-dropdown': {
      height: 56,
    },
  },
}))

interface StaticFormProps {
  onChange?: (staticForm: PhoneCallTemplate) => void
  value?: PhoneCallTemplate
  disabledEdit?: boolean
}

export const PHONE_REQUIRED = {
  PhoneNumber: '',
}

const PhoneCallPanel: React.FC<StaticFormProps> = ({ onChange, value, disabledEdit = false }) => {
  const classes = useStyles()
  const [data, setData] = useState<PhoneCallTemplate | undefined>(value)

  const onChangePhone = useCallback(
    (phoneStr: string) => {
      const newData = Object.assign({}, data, { PhoneNumber: phoneStr, CanSave: true })
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.PhoneCall, newData) })
      setData(newData)
      onChange?.(newData)
    },
    [data]
  )

  return (
    <Box className={classes.container}>
      <MobilePhone
        label="Mobile Number"
        className="phone"
        variant="standard"
        disabled={disabledEdit}
        showFirst={false}
        onChangePhone={onChangePhone}
        value={data?.PhoneNumber}
      />
    </Box>
  )
}
export default PhoneCallPanel
