import React, { useCallback, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import 'echarts-wordcloud'
import { ECBasicOption } from 'echarts/types/dist/shared'
import { getOptions } from './echartUtil'
import { EChartsType } from 'echarts/types/dist/echarts'

export interface EchartsProps {
  id: string
  type?: 'bar' | 'heatmap' | 'wordCloud' | 'pie'
  width: string
  height?: string
  grid?: any
  xAxisPosition?: string
  xAxisData?: string[]
  options?: ECBasicOption
}

export interface HeatMapProps {
  type: 'heatmap'
  xAxisData: string[]
  seriesData: Array<[number, number, number]>
}

export type SeriesData = Array<
  | number
  | {
      value: number
      itemStyle?: {
        color: string
      }
    }
>

export interface BarProps {
  type: 'bar'
  xAxisData: string[]
  seriesData: SeriesData
}

export interface WordCloudProps {
  type: 'wordCloud'
  xAxisData?: string[]
  seriesData: Array<{ name: string; value: number; textStyle?: { color: string } }>
}

const Echarts: React.FC<EchartsProps & (HeatMapProps | BarProps | WordCloudProps)> = ({
  id,
  width,
  height,
  xAxisData,
  options,
  seriesData,
  type,
  grid,
  xAxisPosition,
}) => {
  const cacheChart = useRef<EChartsType | undefined>()

  const setOptions = useCallback(() => {
    const el = document.getElementById(`echarts_${id}`)
    if (!el) return
    let chart = cacheChart.current
    if (!cacheChart.current) {
      chart = echarts.init(el)
      cacheChart.current = chart
    }
    const currentOptions = options ? options : getOptions({ xAxisData, seriesData, type, grid, xAxisPosition })
    chart?.clear()
    chart?.setOption(currentOptions, true)
    chart?.resize()
  }, [id, options, xAxisData, seriesData])
  useEffect(() => {
    window.onresize = function () {
      cacheChart.current?.resize()
    }
  }, [])
  useEffect(() => {
    setOptions()
  }, [setOptions, seriesData])

  return <div id={`echarts_${id}`} style={{ width: width || '300px', height: height || '300px' }} />
}

export default Echarts
