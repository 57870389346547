import { PERIOD_OPTIONS } from '@/global'
import { TextField as MaTextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import * as React from 'react'
import { makeStyles } from '@mui/styles'
import { useCallback } from 'react'
import { ExpandMore } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  root: {
    width: 223,
  },
  input: {
    color: '#211F1F',
    borderRadius: 4,
    fontSize: 20,
    height: 56,
    paddingRight: '9px !important',
  },
}))
interface Props {
  period?: any
  options?: any[]
  onChange?: (value: any) => void
  disabled?: boolean
  defaultValue?: any
  label?: string
  className?: string
  style?: React.CSSProperties
}
const PeriodSelect: React.FC<Props> = ({
  label = 'Date Range',
  period,
  options,
  disabled,
  onChange,
  defaultValue,
  className,
  style,
}) => {
  const classes = useStyles()
  const handleDateChange = useCallback((event: any, newValue: IOption) => {
    onChange?.(newValue)
  }, [])
  return (
    <Autocomplete
      disabled={disabled}
      value={period}
      defaultValue={defaultValue}
      onChange={handleDateChange}
      disableClearable
      id="controllable-states-demo"
      options={options || PERIOD_OPTIONS}
      classes={{ root: classes.root, inputRoot: className }}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
        return (
          <MaTextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: { root: classes.input },
              readOnly: true,
            }}
            label={label}
            placeholder="Date Range"
          />
        )
      }}
      style={style}
      popupIcon={<ExpandMore />}
    />
  )
}

export default PeriodSelect
