import React, { useCallback, useContext } from 'react'
import { EditPanelContext } from '@context/EditPanelContext'
import { HubPage } from '@activate-inc/activate-ui'
import CONFIG from '@constant/config'

interface HubWrapperProps {
  data: any
  qrType?: string
  logo?: string
  bg?: string
}

const HubWrapper: React.FC<HubWrapperProps> = ({ data, qrType, logo, bg }) => {
  const { panelData, editPanelIndex = -1 } = useContext(EditPanelContext)
  const { setCurrentEditPanelIndex } = useContext(EditPanelContext)
  const { Items, Style = 'Rectangles', Name, HubName } = panelData || data || {}
  const handleOpen = useCallback((item, index) => {
    setCurrentEditPanelIndex?.(index)
  }, [])
  if (!Style) return null
  const showHelp = qrType === 'GMPMHub'
  return (
    <HubPage
      qrTypeName={Name || HubName}
      items={Items || []}
      handleOpen={handleOpen}
      buttonStyle={Style}
      showHelp={showHelp}
      logo={logo}
      headerBg={bg}
      s3Host={CONFIG.iconFolder}
      s3SvgHost={CONFIG.iconSvgFolder}
    />
  )
}

export default HubWrapper
