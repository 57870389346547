import React, { useCallback, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import { ConnectivityITF, ProgressITF } from '@/types/entity'
import { makeStyles } from '@mui/styles'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import uuid from 'react-uuid'
import { AddCircle } from '@mui/icons-material'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { PanelChoice } from '@/types/panel'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  add: {
    marginTop: 20,
    alignSelf: 'flex-start',
  },
  save: {
    width: 90,
    marginTop: 20,
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  iconBtn: {
    padding: 0,
  },
  netType: {
    marginTop: 20,
  },
}))
interface Props {
  data?: ConnectivityITF[]
  onChangeData?: (data: ConnectivityITF[], progress: ProgressITF) => void
  onSave?: (data: { Connectivities: ConnectivityITF[]; ConnectivityProgress: ProgressITF }) => void
}
interface WifiFormProps {
  data: ConnectivityITF
  index: number
  onChange?: (item: ConnectivityITF, index: number) => void
}
const NETWORK_TYPE = [
  { label: 'WPA', value: 'WPA' },
  { label: 'WPA2', value: 'WPA2' },
  { label: 'Unsecured', value: 'nopass' },
]
const calculate = (data) => {
  const Denominator = 1
  const Numerator = data.filter(
    (item) => item.NetworkType && item.SSID && (item.NetworkType === 'nopass' ? true : item.Password)
  ).length
  return { Numerator: Numerator > 0 ? 1 : 0, Denominator }
}
const WifiForm: React.FC<WifiFormProps> = ({ data, index, onChange }) => {
  const classes = useStyles()
  const [state, setState] = useState(data || {})
  const { SSID, Password, NetworkType } = state
  const [visibility, setVisibility] = useState(true)
  const onClickVisibility = useCallback(() => {
    setVisibility(!visibility)
  }, [visibility])
  const onInputChange = useCallback(
    (value: string, name: string) => {
      setState((prevState) => {
        //@ts-ignore
        const cur = Object.assign({}, prevState, { [name]: value }) as ConnectivityITF
        if (name === 'NetworkType' && value === 'nopass') {
          Object.assign(cur, { Password: '' })
        }
        onChange?.(cur, index)
        return cur
      })
    },
    [onChange, index]
  )
  return (
    <Grid container rowSpacing={0} columnSpacing={2}>
      <Grid item xs={4}>
        <CustomTextField
          label="Wifi name"
          value={SSID}
          name="SSID"
          fullWidth
          helperText="Wi-Fi name should be capitalized correctly"
          variant="outlined"
          disabled={false}
          placeholder="Enter here"
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomTextField
          label="Password"
          name="Password"
          fullWidth
          disabled={NetworkType === 'nopass'}
          placeholder="Enter here"
          variant="outlined"
          type={visibility ? 'password' : ''}
          value={Password || ''}
          onInputChange={onInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton className={classes.iconBtn} onClick={onClickVisibility}>
                  {visibility ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <PanelSelection
          height={56}
          className={classes.netType}
          disableClearable={false}
          width="100%"
          label="Network Type (Security)"
          // @ts-ignore
          value={NETWORK_TYPE.find((item) => item.value === NetworkType)}
          // @ts-ignore
          choices={NETWORK_TYPE}
          onChange={(item) => onInputChange(item?.value as string, 'NetworkType')}
          getOptionLabel={(option: PanelChoice) => option?.label || ''}
        />
      </Grid>
    </Grid>
  )
}
const Connectivity: React.FC<Props> = ({ data, onChangeData, onSave }) => {
  const classes = useStyles()
  const [state, setState] = useState(data?.map((item) => ({ ...item, FrontItemKey: uuid() } as ConnectivityITF)) || [])
  const onChange = useCallback((item: ConnectivityITF, index: number) => {
    setState((prevState) => {
      const data = prevState || []
      data.splice(index, 1, item)
      return [...data]
    })
  }, [])
  const onAdd = useCallback(() => {
    // @ts-ignore
    setState((prevState) => {
      const data = [...prevState, { FrontItemKey: uuid() } as ConnectivityITF]
      onChangeData?.(data, calculate(data))
      return data
    })
  }, [onChangeData])
  const onClick = useCallback(() => {
    onSave?.({ Connectivities: state, ConnectivityProgress: calculate(state) })
  }, [onSave, state])
  return (
    <Box className={classes.container}>
      <Typography>
        {
          "Please add your property's secured and unsecured wifi networks and password. Please make sure that the network and password's spelling and title casing are exact"
        }
      </Typography>
      {state?.map((item, index) => {
        return <WifiForm data={item} key={item.FrontItemKey} index={index} onChange={onChange} />
      })}
      <Button className={classes.add} onClick={onAdd}>
        <AddCircle style={{ marginRight: 8 }} />
        Add Network
      </Button>
      <Button variant="contained" classes={{ contained: classes.save }} onClick={onClick}>
        Save
      </Button>
    </Box>
  )
}

export default Connectivity
