import * as React from 'react'
import { List, ListItem, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Rating from '@mui/lab/Rating'
import { IComment } from '@/types'

interface Props {
  reviews?: IComment[] | null
  dataSize?: number
  showRating?: boolean
}

const CommentReviews = ({ reviews = [], dataSize = 0, showRating = true }: Props) => {
  const classes = useStyles({ dataSize })
  return (
    <List classes={{ root: classes.root }}>
      {reviews?.map((record: IComment) => (
        <ListItem
          key={`${record.acId}${record.SubmitDate}`}
          classes={{
            root: classes.listItem,
          }}
          // component={Link}
          // to={`/reviews/${record.EntityId}`}
          alignItems="flex-start"
        >
          <ListItemText
            primary={showRating ? <Rating readOnly defaultValue={record.Score} name={record.acId} /> : null}
            secondary={record.Text}
            classes={{
              secondary: classes.listItemText,
            }}
            style={{ paddingRight: 0 }}
          />
        </ListItem>
      ))}
    </List>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.background.paper,
  },

  root: {
    width: '100%',
    overflow: 'scroll',
    maxHeight: (props: { dataSize: number }) => {
      return props?.dataSize > 20 ? 700 : 340
    },
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItemText: {
    overflowY: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
}))

export default CommentReviews
