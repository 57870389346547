import { QRCodeFunctionType } from '@/types'
import { DynamicEditePanelComponentsMaps, isSaveTemplateId } from '@/qrcodes/DynamicForm'
import SelectTemplatePanel from '@component/EditPanel/SelectTemplatePanel'
import EditPanel from '@component/EditPanel/EditPanel'
import React, { useCallback } from 'react'
import { QRCodeType } from '@/types'
import { Template } from '@/types/entity'
import { QRType } from '@/types/entity'
import { makeStyles } from '@mui/styles'
import { Box, Divider, Typography, FormControlLabel, Switch } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const showSkip1FA = process.env.REACT_APP_TYPE !== 'PRODUCTION'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  save: {
    marginTop: 16,
    alignSelf: 'flex-end',
    height: 34,
    width: 89,
    color: 'white',
    marginLeft: 16,
    backgroundColor: '#1976D2',
    borderRadius: 4,
  },
  outlined: {
    marginTop: 16,
    height: 34,
    width: 89,
    borderRadius: 4,
  },
  qrContent: {
    color: '#1976D2',
    margin: '24px 0 4px',
  },
  switch: {
    alignSelf: 'self-start',
  },
}))

const noEditCom = [
  QRCodeFunctionType.OpenAI,
  QRCodeFunctionType.NewSupplier,
  QRCodeFunctionType.AngusOnDemandWorkOrder,
  QRCodeFunctionType.ServiceRequests,
  QRCodeFunctionType.MechanicalSpace,
]
interface Props {
  qrType?: QRType | null
  isHub?: boolean
  groupId: string
  entity?: any
  defaultTemplate?: Template | null
  bId: string
  nameEn?: string
  name?: string
  qrId?: string
  templateId?: string
  onChange: (template: Template) => void
  setTemplate?: (template: Template) => void
  saveSuccess?: (value?: any) => void
  isEdit?: boolean
  getTemplate?: boolean
  qrStyle?: QRCodeType
  disabledEdit?: boolean
  qrStatus?: string
  Accessible?: string
  TenantId?: string
  TenantName?: string
  TagType?: string
  Skip1FA?: boolean
  setState: (value: any) => void
}

const EditAllPanel: React.FC<Props> = ({
  qrType,
  groupId,
  entity,
  defaultTemplate,
  bId,
  onChange,
  qrId,
  templateId,
  isHub,
  setTemplate,
  isEdit = false,
  saveSuccess,
  getTemplate = false,
  name,
  nameEn,
  qrStyle = QRCodeType.Activate,
  disabledEdit,
  qrStatus,
  Accessible,
  TenantId,
  TenantName,
  TagType,
  ...props
}) => {
  const classes = useStyles()
  const { setState, Skip1FA } = props
  const { Type, acId: entityId } = entity || {}
  // @ts-ignore
  let EditPanelCom =
    qrType && qrType.FunctionalityType !== QRCodeFunctionType.Announcements
      ? DynamicEditePanelComponentsMaps[qrType.FunctionalityType] || SelectTemplatePanel
      : null
  if (qrType?.FunctionalityType === QRCodeFunctionType.QrHub) {
    EditPanelCom = EditPanel
  }
  // @ts-ignore
  if (noEditCom.includes(qrType?.FunctionalityType)) {
    EditPanelCom = null
  }
  const isTemplate = isSaveTemplateId(qrType?.FunctionalityType)

  const onChangeTemplate = useCallback(
    (value) => {
      const returnData = value
      if (isTemplate && returnData) {
        Object.assign(returnData, { CanSave: value.CanSave })
      }
      onChange(returnData)
    },
    [isTemplate]
  )
  if (!EditPanelCom || !qrType) return null
  if (isHub) {
    return (
      <>
        <EditPanel
          isHub={isHub}
          disabledEdit={disabledEdit}
          isEdit={isEdit}
          getTemplate={getTemplate}
          hubInfo={defaultTemplate}
          onChange={onChangeTemplate}
          saveSuccess={saveSuccess}
          groupId={groupId}
          entity={entity}
          buildingId={bId}
          qrType={qrType}
          qrId={qrId}
          qrStatus={qrStatus}
        />
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch checked={Skip1FA} onChange={(_, checked) => setState({ Skip1FA: checked })} name="Skip1FA" />
          }
          label="Anonymous User"
          labelPlacement="start"
          sx={{ margin: '16px 16px 16px 0', display: showSkip1FA ? '' : 'none' }}
        />
      </>
    )
  }
  const showCancel = [QRCodeFunctionType.FireExtinguisher, QRCodeFunctionType.AEDInspection].includes(
    qrType.FunctionalityType
  )
  return (
    <div className={classes.container}>
      <Divider sx={{ borderColor: '#BCBCBC', margin: '24px 0px 0px' }} />
      <Typography className={classes.qrContent}>QR Content</Typography>
      <Box sx={{ margin: '0 -16px' }}>
        <EditPanelCom
          isEdit={isEdit}
          groupId={groupId}
          entityType={Type}
          isHubPanel
          entity={entity}
          disabledEdit={disabledEdit}
          key={qrType?.Name + qrType?.DisplayText?.en}
          functionType={qrType?.FunctionalityType}
          qrType={qrType}
          defaultTemplate={defaultTemplate}
          onChange={onChangeTemplate}
          value={defaultTemplate}
          buildingId={bId}
        />
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch checked={Skip1FA} onChange={(_, checked) => setState({ Skip1FA: checked })} name="Skip1FA" />
          }
          label="Anonymous User"
          labelPlacement="start"
          sx={{ margin: '16px', display: showSkip1FA ? '' : 'none' }}
        />
      </Box>
    </div>
  )
}

export default EditAllPanel
