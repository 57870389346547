import React, { useMemo } from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import CircularProgress from '@mui/material/CircularProgress'
import { toPercentText } from '@utils/common'
import Echarts from '@component/Chart/Echarts'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: 18,
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    marginRight: 24,
    borderRadius: 12,
    '&:last-child': {
      marginRight: 0,
    },
  },
  circleProgress: {
    justifyContent: 'center',
  },
  label: {
    color: '#211F1F',
    fontSize: 28,
    fontWeight: 600,
  },
  value: {
    fontSize: 40,
    fontWeight: 600,
    color: '#211F1F',
    lineHeight: '42px',
    marginTop: 10,
  },
  left: {
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5',
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  icon: {
    height: 48,
    width: 48,
  },
  desc: {
    color: '#211F1F',
    fontSize: 18,
    marginTop: 10,
  },
}))

interface Props {
  className?: string
  label: string
  value?: string | number
  valueText?: string | number
  desc?: string
  loading?: boolean
  icon?: string
  isPercent?: boolean
  labelStyle?: React.CSSProperties
}

const getOption = (value: number) => {
  const num = value || 0
  if (typeof num !== 'number') return {}
  const options = {
    type: 'pie',
    id: 'piechart-1' + Math.random(),
    width: '80px',
    height: '80px',
    options: {
      series: [
        {
          name: 'Round',
          type: 'pie',
          legendHoverLink: true,
          radius: ['67%', '100%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [{ value: 1 - num, name: 'uncomplete', itemStyle: { color: '#F5F5F5' } }],
        },
      ],
      emphasis: {
        disabled: true,
      },
    },
  }
  if (Math.round(num * 100) > 0) {
    options.options.series[0].data.unshift({ value: num, name: 'complete', itemStyle: { color: '#466CB5' } })
  }
  return options
}

const CommonCard: React.FC<Props> = ({
  className,
  label,
  value,
  loading,
  icon,
  labelStyle,
  isPercent,
  valueText,
  desc,
}) => {
  const classes = useStyles()
  const options = useMemo(() => getOption(value as number), [value])

  if (loading) {
    return (
      <div className={classnames(classes.container, className, classes.circleProgress)}>
        <CircularProgress color="primary" />
      </div>
    )
  }

  const text = valueText || (isPercent ? toPercentText(value as number) : value)

  return (
    <div className={classnames(classes.container, className)}>
      <div className={classes.left}>
        <Typography className={classes.label} style={labelStyle}>
          {label}
        </Typography>
        <Typography className={classes.value}>{text}</Typography>
        {!!desc && <Typography className={classes.desc}>{desc}</Typography>}
      </div>
      {isPercent ? (
        //@ts-ignore
        <Echarts {...options} />
      ) : (
        <div className={classes.circle}>
          <img src={icon} className={classes.icon} />
        </div>
      )}
    </div>
  )
}

export default CommonCard
