import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Modal } from '@mui/material'
import { Box, Button, Checkbox, TextField, Typography, IconButton } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AddCircle, DragHandle, Close, Clear } from '@mui/icons-material'
import { ErrorRuleOption, QuestionType } from '@constant/qrcode'
import classnames from 'classnames'
import useStyles from './questionStyles'
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd'
import { getItemStyle } from '../builder/engineer/EquipmentTable'
import { configOption, canSave } from '../builder/engineer/QuestionTable'
import SnackBar from '@component/SnackBar'
import Dialog from '@component/Modal/Dialog'
import Images from '@assets/images'
import { useSelector } from 'react-redux'
import { saveDefaultQuestions, getDefaultQuestions } from '@api'

const configQuestion = [
  { name: 'QuestionType', title: 'Question Type*', isObj: true, option: QuestionType, width: 180 },
  { name: 'Question', title: 'Question*', width: 332, multiline: true },
  { name: 'Required', title: 'Required', option: ['Yes', 'No'] },
  { name: 'PhotosEnable', title: 'Photo/Video', isBoolean: true, width: 100 },
  { name: 'AnswerOption1', title: 'Answer Option 1' },
  { name: 'AnswerOption2', title: 'Answer Option 2' },
  { name: 'AnswerOption3', title: 'Answer Option 3' },
  { name: 'AnswerOption4', title: 'Answer Option 4' },
  { name: 'AnswerOption5', title: 'Answer Option 5' },
  { name: 'AnswerOption6', title: 'Answer Option 6' },
  { name: 'AnswerOption7', title: 'Answer Option 7' },
  { name: 'AnswerOption8', title: 'Answer Option 8' },
  { name: 'AnswerOption9', title: 'Answer Option 9' },
  { name: 'AnswerOption10', title: 'Answer Option 10' },
  { name: 'ErrorReporting', title: 'Error Reporting', option: ['Yes', 'No'] },
  { name: 'ErrorRule', title: 'Error Rule', option: ErrorRuleOption, isObj: true },
  { name: 'ErrorValue', title: 'Error Value' },
  { name: 'ToleranceMin', title: 'Tolerance min.' },
  { name: 'ToleranceMax', title: 'Tolerance max.' },
]

const TEMPLATE = {
  QuestionType: '',
  Question: '',
  Required: false,
  AnswerOption1: '',
  AnswerOption2: '',
  AnswerOption3: '',
  AnswerOption4: '',
  AnswerOption5: '',
  AnswerOption6: '',
  AnswerOption7: '',
  AnswerOption8: '',
  AnswerOption9: '',
  AnswerOption10: '',
  ErrorReporting: false,
  ErrorRule: '',
  ErrorValue: '',
  ToleranceMin: null,
  ToleranceMax: null,
  Code: '',
  checked: false,
}

type QuestionItem = typeof TEMPLATE & Record<string, string | boolean>

interface Props {
  onClose: () => void
  sType: string
  type: string
}

const EditQuestion: React.FC<Props> = React.memo(({ onClose, sType, type }) => {
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const classes = useStyles()
  const [FormTemplateFields, setFormTemplateFields] = useState<QuestionItem[]>([])
  const [, setRender] = useState(false)
  const [action, setAction] = useState(false)
  const [actionClear, setActionClear] = useState(false)
  const changeRef = useRef({ isChanges: false, actionClear, TemplateId: '' })

  const { selectedLength, checkedAll } = useMemo(() => {
    const selectedLength = FormTemplateFields.reduce((pre, cur) => {
      let length: number = pre
      if (cur.checked) length++
      return length
    }, 0)
    return {
      selectedLength,
      canCopy: selectedLength === 1,
      checkedAll: !!selectedLength && selectedLength === FormTemplateFields.length,
    }
  }, [FormTemplateFields, action])

  const onCheckedAll = () => {
    const hasChecked = selectedLength > 0
    FormTemplateFields.forEach((v) => {
      if (!['TakePhoto'].includes(v.QuestionType) && v.Readonly !== true) {
        v.checked = !hasChecked
      }
    })
    setAction((v) => !v)
  }

  const showAction = useMemo(() => {
    const { actionClear: preActionClear } = changeRef.current
    changeRef.current.actionClear = actionClear
    if (preActionClear !== actionClear) return false
    return !!selectedLength
  }, [selectedLength, actionClear])

  const onAdd = () => {
    FormTemplateFields.splice(-1, 0, {
      ...JSON.parse(JSON.stringify(TEMPLATE)),
      Code: String(Date.now()),
    })
    changeRef.current.isChanges = true
    setRender((v) => !v)
  }

  const onDelete = () => {
    Dialog.show({
      title: 'Delete Question',
      desc: 'Are you sure?  This action cannot be undone.',
      agreeText: 'Delete',
      onAgree: () => {
        for (let j = FormTemplateFields.length - 1; j >= 0; j--) {
          if (FormTemplateFields[j].checked) {
            FormTemplateFields.splice(j, 1)
          }
        }
        changeRef.current.isChanges = true
        setAction((v) => !v)
      },
    })
  }

  const booleanChange = (idx: number, name: string, value: boolean) => {
    //@ts-ignore
    FormTemplateFields[idx][name] = value
    changeRef.current.isChanges = true
    setRender((v) => !v)
    if (name === 'checked') {
      setAction((v) => !v)
    }
  }

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {}>,
      idx: number,
      type?: string,
      option?: any
    ) => {
      const { name = type, value: newValue } = event.target as any
      const rowValue = option || newValue
      // @ts-ignore
      const value = typeof rowValue === 'string' ? rowValue : (rowValue.Name as string)
      const pre = FormTemplateFields[idx]
      const { QuestionType } = pre
      if (['ToleranceMin', 'ToleranceMax'].includes(name) && !/^[0-9]*$/.test(value)) {
        return pre
      }
      if (QuestionType === 'Number' && name === 'ErrorValue' && !/^[0-9]*$/.test(value)) {
        return pre
      }

      pre[name] = value

      if (['Required', 'ErrorReporting'].includes(name)) {
        pre[name] = value === 'Yes' ? true : false
      }
      if (name === 'QuestionType' && QuestionType) {
        pre.AnswerOption1 = ''
        pre.AnswerOption2 = ''
        pre.AnswerOption3 = ''
        pre.AnswerOption4 = ''
        pre.AnswerOption5 = ''
        pre.AnswerOption6 = ''
        pre.AnswerOption7 = ''
        pre.AnswerOption8 = ''
        pre.AnswerOption9 = ''
        pre.AnswerOption10 = ''
        pre.ErrorReporting = false
        pre.ErrorRule = ''
        pre.ErrorValue = ''
        pre.ToleranceMin = null
        pre.ToleranceMax = null
      }
      if (name === 'QuestionType' && value === 'BoolSingleChoice') {
        pre.AnswerOption1 = 'Yes'
        pre.AnswerOption2 = 'No'
      }
      if (name === 'QuestionType' && value === 'BoolOnOff') {
        pre.Question = 'Is the power on or off?'
        pre.AnswerOption1 = 'ON'
        pre.AnswerOption2 = 'OFF'
      }
      changeRef.current.isChanges = true
      setRender((v) => !v)
    },
    [FormTemplateFields]
  )

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const [removed] = FormTemplateFields.splice(source.index, 1)
    FormTemplateFields.splice(destination.index, 0, removed)
    changeRef.current.isChanges = true
    setRender((v) => !v)
  }

  const onSave = () => {
    let msg = ''
    const hasError = FormTemplateFields.some((v) => {
      const value = v?.canSave === false
      if (value) msg = v?.errMsg as string
      return value
    })

    if (hasError) {
      SnackBar({ msg })
    } else {
      saveDefaultQuestions({
        GroupId: selectedGroupId,
        BuildingId: selectedPropertyId,
        Fields: FormTemplateFields,
        TemplateId: changeRef.current.TemplateId,
        StopType: sType,
        Type: type,
      }).then(() => {
        SnackBar({ msg: 'Saved successfully.', type: 'success' })
        onClose()
      })
    }
  }

  useEffect(() => {
    getDefaultQuestions({ gId: selectedGroupId, bId: selectedPropertyId, sType, type })
      .then((res: any) => {
        changeRef.current.TemplateId = res?.TemplateId || ''
        const Fields = res?.Fields || []
        if (Fields.length === 0) {
          Fields.push({
            ...JSON.parse(JSON.stringify(TEMPLATE)),
            QuestionType: 'TakePhoto',
            Question: 'Tap to Take Photo',
            Code: String(Date.now()),
          })
          changeRef.current.isChanges = true
        }
        setFormTemplateFields(Fields)
      })
      .catch((e) => {
        SnackBar({ msg: e })
      })
  }, [])

  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.body}>
        <Box className={classes.header}>
          <Box>Edit Questions</Box>
          <IconButton onClick={onClose} style={{ padding: 0 }}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>

        <Box className={classes.action} style={{ display: showAction ? '' : 'none' }}>
          <IconButton onClick={() => setActionClear((v) => !v)} style={{ padding: 4, marginRight: 8 }}>
            <Clear className={classes.color} style={{ fontSize: 20 }} />
          </IconButton>
          <Typography style={{ flex: 1 }}>{selectedLength} item selected</Typography>
          {selectedLength && (
            <Box onClick={() => onDelete()} className={classes.actionIcon}>
              <img src={Images.iconDelete} style={{ marginRight: 6 }} alt="Delete" />
              Delete
            </Box>
          )}
        </Box>

        <Box style={{ overflowX: 'auto', flex: '1' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="QuestionTable">
              {(provided) => (
                <Table
                  className={classnames({ [classes.table]: true })}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <colgroup>
                    <col width={100} />
                    {configQuestion.map((v, i) => (
                      <col key={i} width={v.width || 182} />
                    ))}
                  </colgroup>

                  <TableHead className={classnames(classes.tableHead, classes.sticky)}>
                    {/* <TableCell key='check' classes={{ root: classes.cell }} /> */}
                    <TableCell classes={{ root: classes.cell }}>
                      <Checkbox
                        checked={checkedAll}
                        onChange={onCheckedAll}
                        color="primary"
                        indeterminate={selectedLength > 0 && !checkedAll}
                        classes={{ indeterminate: classes.indeterminate }}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </TableCell>
                    {configQuestion.map((v) => (
                      <TableCell key={v.title} classes={{ root: classes.cell }}>
                        {v.title}
                      </TableCell>
                    ))}
                  </TableHead>

                  <TableBody>
                    {FormTemplateFields.map((data, idx) => {
                      const { checked = false, QuestionType, Readonly } = data
                      canSave(data, `${idx + 1}`)
                      const checkDisabled = ['TakePhoto'].includes(QuestionType) || !!Readonly
                      const key = String(data?.addTime || idx)
                      return (
                        <Draggable key={key} draggableId={key} index={idx} isDragDisabled={checkDisabled}>
                          {(draggableProvided, draggableSnapshot) => (
                            <TableRow
                              key={key}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              // @ts-ignore
                              style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                              className={classnames(classes.tableRow, { [classes.checked]: checked })}
                            >
                              <TableCell>
                                <Box className={classes.firstCell}>
                                  <Checkbox
                                    checked={!!checked}
                                    disabled={checkDisabled}
                                    onChange={() => booleanChange(idx, 'checked', !checked)}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                                  <div {...draggableProvided.dragHandleProps}>
                                    <IconButton size="small">
                                      <DragHandle className={classes.color} fontSize="medium" />
                                    </IconButton>
                                  </div>
                                </Box>
                              </TableCell>

                              {configQuestion.map((v, j) => {
                                const { name, isObj = false, multiline, isBoolean } = v
                                const { disabled, options } = configOption({
                                  dataItem: data,
                                  configItem: v,
                                  PhotoRequired: data.Required,
                                })

                                // @ts-ignore
                                let value = isObj ? options.find((item) => item.Name === data[name]) : data[name]
                                value = typeof value === 'number' ? value.toString() : value
                                if (['Required', 'ErrorReporting'].includes(name)) {
                                  value = value ? 'Yes' : 'No'
                                }

                                if (isBoolean) {
                                  return (
                                    <TableCell key={j}>
                                      <Checkbox
                                        checked={value}
                                        onChange={() => booleanChange(idx, name, !value)}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled || checkDisabled}
                                      />
                                    </TableCell>
                                  )
                                }
                                if (options.length > 0) {
                                  return (
                                    <TableCell key={j}>
                                      <Autocomplete
                                        disableClearable
                                        disabled={disabled}
                                        onChange={(e, value) => handleChange(e, idx, name, value)}
                                        className={classes.textField}
                                        options={options}
                                        // ListboxProps={{ name }}
                                        value={value || ''}
                                        getOptionLabel={(option: any) => option?.DisplayText?.en || option}
                                        classes={{
                                          inputRoot: classes.autocomplete,
                                          popper: classes.option,
                                          listbox: classes.listbox,
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name={name}
                                            variant="outlined"
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: { disabled: classes.disabled },
                                            }}
                                          />
                                        )}
                                      />
                                    </TableCell>
                                  )
                                }
                                return (
                                  <TableCell key={j}>
                                    <TextField
                                      name={name}
                                      value={value || ''}
                                      variant="outlined"
                                      disabled={disabled}
                                      multiline={multiline}
                                      className={classes.textField}
                                      SelectProps={{
                                        displayEmpty: true,
                                        classes: {
                                          select: classes.select,
                                        },
                                        type: 'search',
                                        MenuProps: {
                                          style: {
                                            maxHeight: 300,
                                          },
                                        },
                                      }}
                                      InputProps={{
                                        classes: {
                                          root: classes.cellInputRoot,
                                          input: classes.cellInput,
                                          disabled: classes.disabled,
                                          multiline: classes.multiline,
                                        },
                                      }}
                                      onChange={(e) => handleChange(e, idx, '', '')}
                                    ></TextField>
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          )}
                        </Draggable>
                      )
                    })}
                  </TableBody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>

          <Button className={classes.add} onClick={onAdd}>
            <AddCircle style={{ marginRight: 8 }} />
            Add New Questions
          </Button>
        </Box>

        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={onClose} className={classes.modalBtn}>
            Cancel
          </Button>
          <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            className={classes.modalBtn}
            disabled={!changeRef.current.isChanges}
          >
            SAVE
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

EditQuestion.displayName = 'EditQuestion'
export default EditQuestion
