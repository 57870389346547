import React, { useState } from 'react'
import { Typography, Box, Select, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import { QRCodeConfigEnum } from '../types'

export const EYE_FRAME_SHAPE = [
  'frame0',
  'frame1',
  'frame2',
  'frame3',
  'frame4',
  'frame5',
  'frame6',
  'frame7',
  'frame8',
  'frame10',
  'frame11',
  'frame12',
  'frame13',
  'frame14',
  'frame16',
]

export const EYE_BALL_SHAPE = [
  'ball0',
  'ball1',
  'ball2',
  'ball3',
  'ball5',
  'ball6',
  'ball7',
  'ball8',
  'ball10',
  'ball11',
  'ball12',
  'ball13',
  'ball14',
  'ball15',
  'ball16',
  'ball17',
  'ball18',
  'ball19',
]
export const BODY_SHAPE_MAP = [
  'rounded-pointed',
  'square',
  'rounded-in',
  'rounded-in-smooth',
  'round',
  'pointed',
  'pointed-smooth',
  'pointed-in',
  'pointed-in-smooth',
  'pointed-edge-cut',
  'mosaic',
  'leaf',
  'japnese',
  'edge-cut',
  'edge-cut-smooth',
  'dot',
  'diamond',
  'circular',
  'circle',
  'circle-zebra',
  'circle-zebra-vertical',
]

// eslint-disable-next-line
const OneIn = `1' x 1'`
// eslint-disable-next-line
const TwoIn = `2' x 2'`
// eslint-disable-next-line
const ThreeIn = `3' x 3'`
// eslint-disable-next-line
const FourIn = `4' x 4'`

const useStyles = makeStyles({
  paper: {
    width: '100%',
  },
  button: {
    margin: 2,
  },
  extendedIcon: {
    marginRight: 2,
  },
  input: {
    display: 'none',
  },
  input_box: {
    paddingTop: 100,
    backgroundColor: 'red',
    marginTop: 40,
  },

  right: {
    width: 200,
  },
  itemBox: {
    width: 500,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemBoxh5: {
    margin: 0,
    marginRight: 15,
  },
  colorBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightColorBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 30,
  },
  colorLabel: {
    width: 95,
    maxWidth: 95,
    minWidth: 95,
    display: 'flex',
  },
  rightColorLabel: {
    display: 'flex',
    justifyContent: 'end',
  },
  colorInput: {
    flex: 1,
    display: 'flex',
  },
  dimensionBox: {
    width: 225,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '16px',
  },
})

export const CustomEyeForm = ({ getOptionValue, setOptionValue }) => {
  const classes = useStyles()
  const [eyeColor, setEyeColor] = useState(getOptionValue(QRCodeConfigEnum.EyeColor))
  return (
    <>
      <div className={classes.itemBox} style={{ paddingTop: '16px' }}>
        <div style={{ display: 'flex', margin: '0 30px 0 0' }}>
          <h5 className={classes.itemBoxh5}>Body Shape:</h5>
          <Select
            labelId="label"
            id="select"
            variant="standard"
            onChange={(e) => {
              setOptionValue(QRCodeConfigEnum.BodyShape, e.target.value)
            }}
            value={getOptionValue(QRCodeConfigEnum.BodyShape)}
          >
            {BODY_SHAPE_MAP.map((name, index) => {
              // const name = `frame${index}`
              return (
                <MenuItem key={name} value={name}>
                  <img src={require(`../assets/images/qrcodestyle/${name}.png`)} alt={name} width={50} height={50} />
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <div style={{ display: 'flex', margin: '0 30px 0 0' }}>
          <h5 className={classes.itemBoxh5}>Eye Frame:</h5>
          <Select
            labelId="label"
            id="select"
            variant="standard"
            onChange={(e) => {
              setOptionValue(QRCodeConfigEnum.EyeFrameShape, e.target.value)
            }}
            value={getOptionValue(QRCodeConfigEnum.EyeFrameShape)}
          >
            {EYE_FRAME_SHAPE.map((name) => {
              return (
                <MenuItem key={name} value={name}>
                  <img src={require(`../assets/images/qrcodestyle/${name}.png`)} alt={name} width={50} height={50} />
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <h5 className={classes.itemBoxh5}>Eye Ball Shape:</h5>
        <Select
          labelId="label"
          variant="standard"
          id="select"
          onChange={(e) => {
            setOptionValue(QRCodeConfigEnum.EyeBallShape, e.target.value)
          }}
          value={getOptionValue(QRCodeConfigEnum.EyeBallShape)}
        >
          {EYE_BALL_SHAPE.map((name) => {
            return (
              <MenuItem key={name} value={name}>
                <img src={require(`../assets/images/qrcodestyle/${name}.png`)} alt={name} width={50} height={50} />
              </MenuItem>
            )
          })}
        </Select>
      </div>
      <Box className={classes.itemBox} sx={{ paddingTop: '16px' }}>
        <Typography className={classes.colorLabel}>Eye Color:</Typography>
        <MuiColorInput
          value={eyeColor}
          format="hex"
          onChange={(newValue) => {
            if (matchIsValidColor(newValue)) {
              setEyeColor(newValue)
              setOptionValue(QRCodeConfigEnum.EyeColor, newValue)
            }
          }}
          fallbackValue={'#000000'}
          isAlphaHidden
        />
      </Box>
    </>
  )
}

export const CommonQRCode = ({ getOptionValue, setOptionValue }) => {
  const classes = useStyles()
  const [bgColor, setBGColor] = useState(getOptionValue(QRCodeConfigEnum.BGColor))
  const [fillColor, setFillColor] = useState(getOptionValue(QRCodeConfigEnum.FillColor))

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: 500, justifyContent: 'space-between' }}>
        <Box className={classes.colorBox}>
          <Typography className={classes.colorLabel}>Background:</Typography>
          <Box className={classes.colorInput}>
            <MuiColorInput
              format="hex"
              value={bgColor}
              onChange={(newValue) => {
                if (matchIsValidColor(newValue)) {
                  setBGColor(newValue)
                  setOptionValue(QRCodeConfigEnum.BGColor, newValue)
                }
              }}
              fallbackValue={'#FFF'}
              isAlphaHidden
              // sx={{ minWidth: 160 }}
              fullWidth
            />
          </Box>
        </Box>
        <Box className={classes.rightColorBox}>
          <Typography className={classes.rightColorLabel}>Fill:</Typography>
          <Box className={classes.colorInput}>
            <MuiColorInput
              format="hex"
              value={fillColor}
              onChange={(newValue) => {
                if (matchIsValidColor(newValue)) {
                  setFillColor(newValue)
                  setOptionValue(QRCodeConfigEnum.FillColor, newValue)
                }
              }}
              fallbackValue={'#000'}
              isAlphaHidden
              sx={{ minWidth: 160 }}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.dimensionBox}>
        <Typography className={classes.colorLabel}>Dimension:</Typography>
        <Select
          labelId="label"
          id="select"
          variant="standard"
          className={classes.right}
          onChange={(e) => {
            setOptionValue(QRCodeConfigEnum.Size, e.target.value)
          }}
          value={getOptionValue(QRCodeConfigEnum.Size)}
        >
          <MenuItem value="500">{OneIn}</MenuItem>
          <MenuItem value="1000">{TwoIn}</MenuItem>
          <MenuItem value="1500">{ThreeIn}</MenuItem>
          <MenuItem value="2000">{FourIn}</MenuItem>
        </Select>
      </Box>
    </>
  )
}
