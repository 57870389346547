import { useRecordContext, TextFieldProps } from 'react-admin'
import get from 'lodash/get'
import { formatPhoneNumber } from '@/utils'
import * as React from 'react'
// import { TextFieldProps } from 'ra-ui-materialui/src/field/TextField'

const PhoneNumberFiled = (props: TextFieldProps) => {
  const record = useRecordContext(props)
  if (!props.source) return null
  const value = get(record, props.source)
  const phone = formatPhoneNumber(value)
  return <span style={{ whiteSpace: 'nowrap' }}>{phone}</span>
}

export default PhoneNumberFiled
