import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import WithDefaultData from '@/hoc/WithDefaultData'
import { Typography } from '@mui/material'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import { FieldItem } from '@utils/FieldType'
import useSetState from '@hooks/useSetState'
import { Template } from '@/types/entity'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  title: {
    color: '#466CB5',
    fontSize: 20,
    fontWeight: 600,
  },
  desc: {
    color: '#211F1F',
  },
}))

const language = 'en'

const MaintenanceRequestPanel: React.FC<{ isHub?: boolean; value: Template }> = ({ isHub, value }) => {
  const classes = useStyles()
  const [state, setState] = useSetState({
    Fields: (value?.Fields || []) as FieldItem[],
  })
  const { Fields } = state

  const onChangeField = useCallback((fields: FieldItem[]) => {
    setState({ Fields: fields || [] })
  }, [])
  useMemo(() => {
    Fields.map((item) => {
      item.DisabledDelete = true
      return item
    })
  }, [Fields])
  return (
    <div className={classes.container}>
      <DragFormPanel language={language} data={Fields} disabledEdit onChangeField={onChangeField} />
    </div>
  )
}

export default WithDefaultData(MaintenanceRequestPanel)
