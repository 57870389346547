import { ECBasicOption } from 'echarts/types/dist/shared'
import { EchartsProps, SeriesData, WordCloudProps } from './Echarts'
import { IWordCloud } from '@/types'

interface OptionsUtilsParams {
  type: string
  grid?: any
  xAxisPosition?: string
  xAxisData?: string[]
  seriesData: Array<[number, number, number]> | SeriesData | Array<{ name: string; value: number }>
}

export const getWordCloud = ({ seriesData }: { seriesData: unknown }) => {
  const options: ECBasicOption = {
    series: [
      {
        type: 'wordCloud',
        shape: 'circle',
        gridSize: 8,
        drawOutOfBound: false,
        layoutAnimation: false,
        // keepAspect: true,
        shrinkToFit: true,
        sizeRange: [16, 45],
        // rotationRange: [0, 0, 0, 0],
        rotationRange: [-90, 90],
        maskImage: false,
        // rotationStep: 45,
        textStyle: {
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          normal: {
            color: function () {
              return (
                'rgb(' +
                [
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                ].join(',') +
                ')'
              )
            },
          },
          emphasis: {
            shadowBlur: 10,
            shadowColor: '#333',
          },
          // normal: {
          //   color: function () {
          //     // bug does not take effect
          //     const colorArr = ['#E25858', '#7CBF85', '#FE8A2F']
          //     const index = Math.round(Math.random() * 2)
          //     return colorArr[index] || 'red'
          //   }
          // }
        },
        left: 'center',
        top: 'center',
        width: '100%',
        height: '100%',
        data: seriesData,
      },
    ],
  }
  return options as ECBasicOption
}
export const getOptions = ({ xAxisData, seriesData, type, xAxisPosition, grid }: OptionsUtilsParams) => {
  if (type === 'wordCloud') return getWordCloud({ seriesData })
  const rotate = type === 'heatmap' ? -60 : 0
  const options: ECBasicOption = {
    grid: grid || {
      height: '50%',
      top: '20%',
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      position: xAxisPosition || 'bottom',
      axisLine: {
        lineStyle: {
          width: 0,
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        rotate: rotate,
        textStyle: {
          color: '#989090',
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        textStyle: {
          color: '#949494',
        },
      },
    },
    series: {
      type: type,
      label: {
        color: '#949494',
        show: true,
        position: 'top',
      },
      barWidth: '20%',
      data: seriesData,
    },
  }
  if (type === 'heatmap') {
    options.series = [
      {
        name: '',
        type: type,
        data: seriesData,
        coordinateSystem: 'cartesian2d',
        itemStyle: {
          borderColor: '#E8E8E8',
          borderWidth: 1,
        },
        label: {
          formatter: (params: any) => {
            const { value } = params
            return value[2] < 1 ? 'NA' : value[2].toFixed(1)
          },
          show: true,
        },
      },
    ]
    options.visualMap = {
      show: false,
      orient: 'horizontal',
      left: 'center',
      type: 'piecewise',
      pieces: [
        { gt: 4.0, lte: 5, color: '#86C467' },
        { gt: 3.0, lte: 4.0, color: '#FEE934' },
        { gt: 2.0, lte: 3.0, color: '#F8C536' },
        { gt: 1, lte: 2.0, color: '#F1803A' },
        { value: 1, label: '', color: 'red' },
        { gte: 0, lt: 1, color: 'white' },
      ],
      bottom: '15%',
    }
    options.yAxis = {
      type: 'category',
      inverse: true,
      show: false,
      splitArea: {
        show: true,
      },
    }
  }
  return options
}

export const getServiceRequestWordOption = (seriesData: IWordCloud[]): WordCloudProps & EchartsProps => {
  return {
    id: 'cloudchart',
    type: 'wordCloud',
    width: '100%',
    height: '100%',
    seriesData: [...seriesData].slice(0, 100).map((item: IWordCloud) => {
      const colorArr = ['#E25858', '#FE8A2F', '#7CBF85'] //红色-消极 橙色-中性 绿色-积极
      let index = 1
      if (item.SentimentScore > 0) {
        index = 2
      }
      if (item.SentimentScore < 0) {
        index = 0
      }
      return {
        name: item.Label,
        value: item.Value,
        textStyle: {
          color: colorArr[index] || 'red',
        },
      }
    }),
  }
}

export const getServiceRequestOptions = ({ seriesData = [] }: { seriesData: IWordCloud[] }) => {
  const options: ECBasicOption = {
    series: [
      {
        type: 'wordCloud',
        shape: 'triangle',
        gridSize: 16,
        drawOutOfBound: false,
        layoutAnimation: false,
        keepAspect: false,
        shrinkToFit: true,
        sizeRange: [16, 45],
        // rotationRange: [0, 0, 0, 0],
        rotationRange: [-90, 90],
        // rotationStep: 45,
        textStyle: {
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          emphasis: {
            shadowBlur: 10,
            shadowColor: '#333',
          },
        },
        left: 'center',
        top: 'center',
        width: '95%',
        height: '95%',
        data: seriesData.slice(0, 100).map((item: IWordCloud) => {
          let index = 1
          const colorArr = ['#E25858', '#FE8A2F', '#7CBF85'] // 消极 中性 积极
          if (item.SentimentScore > 0) {
            index = 2
          }
          if (item.SentimentScore < 0) {
            index = 0
          }
          return {
            name: item.Label,
            value: item.Value,
            textStyle: {
              color: colorArr[index],
            },
          }
        }),
      },
    ],
  }
  return options as ECBasicOption
}
