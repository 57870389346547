import React from 'react'
import { FieldForm, Template } from '@activate-inc/activate-ui'

interface Props {
  template?: Template
  qrTypeName: string
  qrId: string
  hubItemId?: string
}

const BurnPermitForm: React.FC<Props> = ({ template, qrTypeName, qrId, hubItemId }) => {
  const language = 'en'
  const { Fields: data } = template || {}

  return <FieldForm qrTypeName={qrTypeName} data={data || []} language={language} btnText="SUBMIT" btnWidth={'50%'} />
}

export default BurnPermitForm
