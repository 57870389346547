import * as React from 'react'
import { useSelector } from 'react-redux'
import { Layout as MuiLayout, LayoutProps, useTranslate, MenuItemLink, Sidebar } from 'react-admin'
import classnames from 'classnames'
import { usePermissions } from 'react-admin'
import AppBarExtend from './AppBarExtend'
import Menu from './Menu'
import { makeStyles } from '@mui/styles'
import { PersonAdd as PersonAddIcon } from '@mui/icons-material'
import { useLocation } from 'react-router'
import { useMemo } from 'react'

const EmptyMenu = () => <div></div>

const AddMenu = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const { pathname, search } = window.location
  return (
    <div>
      <MenuItemLink
        className={classnames(classes.menulink)}
        to={{
          pathname: '/accountCreate',
        }}
        state={{ _scrollToTop: true }}
        primaryText={translate('pos.menu.create', {
          smart_count: 2,
        })}
        leftIcon={<PersonAddIcon />}
        dense={false}
      />
    </div>
  )
}

export const useStyles = makeStyles(() => ({
  layout: {
    '& .RaLayout-appFrame': {
      marginTop: 120,
    },
    '& .RaLayout-content': {
      padding: 0,
    },
    '& .RaList-root': {
      backgroundColor: 'red',
    },
    '& .RaBulkActionsToolbar-toolbar': {
      display: 'none',
    },
    '& .RaList-main': {
      width: 0,
      backgroundColor: 'white',
      borderRadius: 8,
      padding: 24,
      margin: '0 0 8px',
      '& .MuiToolbar-root': {
        minHeight: 'auto',
      },
    },
    '& .RaList-content': {
      borderRadius: 0,
      boxShadow: 'none',
    },
    '& .RaDatagrid-thead': {
      border: '1px solid #F5F5F5',
    },
    '& .RaDatagrid-tableWrapper': {
      border: '1px solid #F5F5F5',
      borderRadius: 8,
      overflowX: 'auto',
      boxShadow: 'inset -8px 0 8px -8px rgba(0, 0, 0, 0.25)',
    },
    '& .RaDatagrid-table': {
      boxShadow: 'inset -8px 0 0 #fff',
    },
    '& .RaDatagrid-tbody': {
      '& .RaDatagrid-row': {
        // borderBottom: '1px solid #ECECEC',
        '&:last-child': {
          borderBottom: 0,
        },
      },
    },
    '& .RaDatagrid-headerCell': {
      fontWeight: 700,
      fontSize: 16,
      fontFamily: 'Roboto',
      color: '#211F1F',
      backgroundColor: '#F0F6FC',
      border: 'none',
      height: 48,
      padding: '12px 16px',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
    },
    '& .RaDatagrid-rowCell': {
      padding: '12px 16px',
    },
    '& .RaDatagrid-hover': {
      background: '#F5F5F5 !important',
    },
    '& .RaDatagrid-row': {
      borderBottom: '1px solid #F5F5F5',
    },
  },
  menulink: {
    width: '240px',
  },
  slide: {
    height: 'calc(100vh - 120px)',
    '& .RaSidebar-appBarCollapsed': {
      marginTop: 0,
    },
    '& .RaSidebar-paper': {
      width: 249,
      padding: '0 12px 0',
    },
    '& .RaSidebar-fixed': {
      height: 'calc(100vh - 120px)',
    },
  },
}))
// @ts-ignore
const CustomSidebar = (props) => {
  const location = useLocation()
  const classes = useStyles()
  const hiddenBar = location.pathname.includes('/surveyWelcome')
  const { hiddenMenu } = useMemo(() => location.state || {}, [location.state])

  if (hiddenBar || hiddenMenu) {
    return null
  }
  return <Sidebar {...props} variant="persistent" open className={classes.slide} />
}

const Layout = (props: LayoutProps) => {
  const classes = useStyles()
  const location = useLocation()

  const { selectedGroupId } = useSelector((state: any) => state.profile)
  const { permissions } = usePermissions()
  const { GlobalManageCustomers } = permissions || {}
  const myMenu =
    location.pathname === '/accountCreate' ? AddMenu : GlobalManageCustomers && !selectedGroupId ? EmptyMenu : Menu
  return <MuiLayout {...props} appBar={AppBarExtend} menu={myMenu} sidebar={CustomSidebar} className={classes.layout} />
}
export default Layout
