import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { Button, Dialog as DialogUi, DialogActions, Typography, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#374053',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    paddingBottom: 40,
  },
  container: {
    width: 401,
    padding: '32px 35px 18px',
    backgroundColor: '#DEE4EF',
    borderRadius: 12,
  },
  desc: {
    textAlign: 'center',
    fontSize: 16,
    color: '#374053',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    paddingBottom: 40,
  },
  btn: {
    width: 158,
    textTransform: 'none',
    backgroundColor: 'white',
    color: '#374053',
  },
  actions: {
    justifyContent: 'space-around',
  },
  inputRoot: {
    margin: '0 22px 24px',
  },
  inputDesc: {
    fontSize: '12px',
    color: '#707070',
    margin: '-12px 22px 24px',
  },
}))

export default class Dialog {
  static show = ({
    title = '',
    desc = '',
    onAgree,
    onCancel,
    agreeText = 'OK',
    cancelText = 'Cancel',
    input = false,
    inputLabel = 'Enter a number',
    inputDesc = '',
    inputType = 'number',
  }: {
    title?: string
    desc?: string
    onAgree: (value?: any) => void
    onCancel?: () => void
    agreeText?: string
    cancelText?: string
    input?: boolean
    inputLabel?: string
    inputDesc?: string
    inputType?: 'number' | 'text'
  }) => {
    if (title === '') return
    const div = document.createElement('div')
    const root = document.getElementById('root')
    root?.appendChild(div)
    ReactDOM.render(
      <DialogCom
        title={title}
        desc={desc}
        onAgree={onAgree}
        onCancel={onCancel}
        agreeText={agreeText}
        cancelText={cancelText}
        input={input}
        inputLabel={inputLabel}
        inputDesc={inputDesc}
        inputType={inputType}
      />,
      div
    )
  }
}

const DialogCom: React.FC<{
  title?: string
  desc?: string
  onAgree: (value?: any) => void
  agreeText?: string
  cancelText?: string
  input?: boolean
  inputLabel?: string
  inputDesc?: string
  onCancel?: () => void
  inputType?: 'number' | 'text'
}> = ({
  title = '',
  desc = '',
  agreeText = 'OK',
  cancelText,
  onAgree,
  input = false,
  onCancel: onCancelCall,
  inputLabel,
  inputDesc,
  inputType,
}) => {
  const [open, setOpen] = useState(true)
  const [value, setValue] = useState('')
  const classes = useStyles()
  const removeDialog = () => {
    setOpen(false)
  }
  const onAgreeCall = () => {
    removeDialog()
    onAgree?.(input ? value : '')
  }

  const onCancel = () => {
    removeDialog()
    onCancelCall?.()
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      if (inputType === 'number' && /^[0-9]*$/.test(value)) {
        setValue(value)
      }
      if (inputType === 'text') {
        setValue(value)
      }
    },
    [inputType]
  )

  if (title === '') return null

  return (
    <DialogUi
      open={open}
      onClose={removeDialog}
      classes={{ paper: classes.container }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Typography className={classes.title}>{title}</Typography>
      {desc && <Typography className={classes.desc}>{desc}</Typography>}
      {input ? (
        <TextField
          value={value}
          variant="outlined"
          label={inputLabel}
          className={classes.inputRoot}
          onChange={(e) => handleChange(e)}
        />
      ) : null}
      {inputDesc && <Typography className={classes.inputDesc}>{inputDesc}</Typography>}
      <DialogActions className={classes.actions}>
        <Button onClick={onAgreeCall} color="primary" className={classes.btn} disabled={input && !value}>
          {agreeText}
        </Button>
        <Button onClick={onCancel} color="primary" className={classes.btn}>
          {cancelText}
        </Button>
      </DialogActions>
    </DialogUi>
  )
}
