import React from 'react'
import ReactDOM from 'react-dom'
import { Snackbar as SnackBarMui, Alert } from '@mui/material'
import { AlertColor } from '@mui/lab'

export default function SnackBar({
  msg = '',
  duration = 2000,
  type = 'error',
}: {
  msg: string | any
  duration?: number
  type?: AlertColor
}) {
  const message = msg.message || msg
  if (message === '') return
  const open = true
  const div = document.createElement('div')
  const root = document.getElementById('root')
  root?.appendChild(div)

  ReactDOM.render(
    <SnackBarMui open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={duration}>
      <Alert severity={type}>{message}</Alert>
    </SnackBarMui>,
    div
  )
  const removeSnackBar = () => {
    root?.removeChild(div)
  }
  setTimeout(removeSnackBar, duration)
}
