import React from 'react'
import { Datagrid, DatagridProps, useListContext } from 'react-admin'

const DatagridCustomize: React.FC<DatagridProps> = (props, context) => {
  const { selectedIds = [] } = useListContext()
  const {
    children,
    rowStyle = (record, index) => {
      const isSelected = selectedIds?.includes(record.id)
      return {
        backgroundColor: isSelected ? '#F0F6FC' : 'transparent',
      }
    },
    ...extra
  } = props
  return (
    <Datagrid {...extra} rowStyle={rowStyle}>
      {children}
    </Datagrid>
  )
}
export default DatagridCustomize
