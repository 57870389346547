import React, { useEffect, useCallback } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { Box, TextField, IconButton, Button } from '@mui/material'
import { DeleteOutline, AddCircle, Reorder } from '@mui/icons-material'
import { DragDropContext, Droppable, DropResult, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { reorder } from '@/utils/helpers'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import FieldType from '@/utils/FieldType'

const language = 'en'
type FieldItem = typeof FieldType.DropDownSelection

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px 16px',
      fontFamily: 'Roboto',
    },
    logoLabel: {
      color: '#000',
      fontSize: '14px',
      fontWeight: 500,
      margin: '16px 0',
    },
    url: {
      display: 'flex',
      alignItems: 'center',
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    margin: {
      marginTop: 0,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
    },
    itemText: {
      flex: 1,
    },
    date: {
      margin: '20px 0 0',
    },
    disabled: {
      fontSize: 12,
      color: '#777',
      lineHeight: '21px',
    },
    add: {
      justifyContent: 'flex-start',
      color: '#386BBF',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    underline: {
      '&:before': {
        borderWidth: 0,
      },
    },
  }
})

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
}

const RenewalPanel: React.FC<Props> = ({ onChange, value, functionType, disabledEdit = false }) => {
  const classes = useStyles()
  const [{ Fields, FieldText, Choices, Questions }, setState] = useSetState({
    Fields: (value?.Fields || []) as FieldItem[],
    Questions: value?.Questions,
    FieldText: value?.Questions[0]?.QuestionText?.[language],
    Choices: value?.Questions?.[1]?.Choices || [
      {
        Label: '',
        Text: { en: '', es: '' },
        Value: '',
        SubChoice: false,
      },
    ],
  })

  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Choices || [], source.index, destination.index)
    setState({ Choices: newItems })
  }

  const onRemove = (index: number) => {
    Choices.splice(index, 1)
    setState({ Choices: [...Choices] })
  }

  const onItemChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const { value } = event.target
    Choices[index].Text = { en: value, es: value }
    setState({ Choices: [...Choices] })
  }

  const onAdd = () => {
    const item = { ...Choices[0], isAdd: true }
    item.Label = String(Date.now())
    item.Text = { en: '', es: '' }
    item.Value = Math.max(...Choices.map((v) => v.Value))
    Choices.push(item)
    setState({ Choices: [...Choices] })
  }

  const onChangeField = useCallback((fields: FieldItem[]) => {
    setState({ Fields: fields || [] })
  }, [])

  useEffect(() => {
    if (onChange) {
      Questions[0].QuestionText = { en: FieldText, es: FieldText }
      Questions[1].Choices = Choices
      const newData = {
        Fields,
        Questions,
      }
      Object.assign(newData, { CanSave: isCanSavaQr(functionType, newData) })
      onChange(newData)
    }
  }, [onChange, Fields, FieldText, Choices, Questions])

  return (
    <div className={classes.container}>
      <CustomTextField
        label="Question 1"
        disabled={disabledEdit}
        value={FieldText}
        placeholder="Enter here"
        name="FieldText"
        onInputChange={onInput}
        variant="outlined"
      />

      <>
        <Box className={classes.logoLabel}>Reasons for not renewing</Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {Choices.map((item, index: number) => (
                  <Draggable key={item?.Label} draggableId={item?.Label} index={index} isDragDisabled={disabledEdit}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                      >
                        <Box className={classes.item}>
                          <TextField
                            className={classes.itemText}
                            label={''}
                            disabled={disabledEdit}
                            multiline
                            value={item?.Text?.[language] || ''}
                            InputProps={{
                              classes: {
                                underline: classes.underline,
                              },
                            }}
                            InputLabelProps={{ shrink: false }}
                            onChange={(e) => onItemChange(e, index)}
                            variant="standard"
                            placeholder="Enter here"
                          />
                          <IconButton disableRipple onClick={() => onRemove(index)} disabled={disabledEdit}>
                            <DeleteOutline />
                          </IconButton>
                          <div {...draggableProvided.dragHandleProps}>
                            <IconButton disabled={disabledEdit}>
                              <Reorder />
                            </IconButton>
                          </div>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button aria-label="add" className={classes.add} onClick={onAdd} disabled={disabledEdit}>
          <AddCircle color="primary" style={{ marginRight: 10 }} />
          Add New
        </Button>
      </>
      <Box className={classes.logoLabel}>Personal Details</Box>
      <DragFormPanel
        language={language}
        data={Fields}
        onChangeField={onChangeField}
        onlyMoveArr={['Q003', 'Q004', 'Q005', 'Q006']}
      />
    </div>
  )
}

export default WithDefaultData(RenewalPanel)
