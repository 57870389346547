import { FormControl, FormHelperText, Input, Box } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  item: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,

    '& .icon': {
      borderRadius: 4,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: '1px solid #1976D2',
      borderRightWidth: 0,
    },
    '& .input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderRadius: 4,
      border: '1px solid #C4C4C4',
      borderLeftWidth: 0,
    },
    ' &:hover': {
      '& .icon': {
        borderWidth: 2,
        borderRightWidth: 0,
      },
      '& .input': {
        border: '2px solid #1976D2',
        borderLeftWidth: 0,
      },
      boxShadow: '0px 3px 2px #00000029',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: '100%',
    backgroundColor: '#4268B3',
  },
  input: {
    flex: 1,
    height: '100%',
    padding: '0 12px',
  },
}))
const FormControlContainer: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.item}>{children}</div>
}

interface Props {
  id?: string
  placeholder: string
  name: string
  value?: string
  icon?: React.ReactNode
  type?: string
  autoComplete?: string
  helperText?: string
  className?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

const LoginInput: React.FC<Props> = ({
  id,
  placeholder,
  name,
  value,
  icon,
  onChange,
  type,
  autoComplete,
  helperText,
  className,
}) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState<string>()
  useEffect(() => {
    setInputValue(value)
  }, [value])
  const onChangeValue = useCallback(
    (event) => {
      setInputValue(event.target.value)
      onChange?.(event)
    },
    [setInputValue, onChange]
  )
  return (
    <FormControl sx={{ width: '100%' }} className={className}>
      <FormControlContainer>
        {icon && <Box className={classnames(classes.icon, 'icon')}>{icon}</Box>}
        <Input
          type={type}
          onChange={onChangeValue}
          className={classnames(classes.input, 'input')}
          disableUnderline
          id={id}
          aria-describedby="my-helper-text"
          name={name}
          autoComplete={autoComplete}
          value={inputValue}
          placeholder={placeholder}
        />
      </FormControlContainer>
      {!!helperText && <FormHelperText id="my-helper-text">{helperText}</FormHelperText>}
    </FormControl>
  )
}
export default LoginInput
