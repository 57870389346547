import React, { useCallback, useEffect, useRef } from 'react'
import { Box, Button, IconButton, Switch, Typography, Autocomplete, TextField } from '@mui/material'
import WithDefaultData from '@/hoc/WithDefaultData'
import { makeStyles } from '@mui/styles'
import {
  DragDropContext,
  Draggable,
  DraggableProps,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { AddCircle, ExpandMore, Menu } from '@mui/icons-material'
import CustomTextField from '@component/Form/CustomTextField'
import useSetState from '@hooks/useSetState'
import { reorder } from '@utils/helpers'
import MultipleSelect from '@component/Filter/MultipleSelect'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import Images from '@/assets/images'

interface MoveOutPanelProps {
  onChange?: (data: any) => void
  value?: any
  functionType: QRCodeFunctionType
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      padding: '0 16px 16px',
    },
    title: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto',
      color: '#4E4D4D',
      display: 'flex',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #BCBCBC',
      padding: 24,
      borderRadius: 8,
    },
    sectionName: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 24,
    },
    itemText: {
      marginTop: 0,
      marginRight: 16,
      minWidth: 220,
      flex: 1,
    },
    multiple: {
      minWidth: 220,
      flex: 1,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    add: {
      alignSelf: 'flex-start',
      padding: 0,
      marginTop: 16,
    },
    moveWrapper: {
      marginTop: 24,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    delete: {
      width: 24,
      height: 24,
    },
  }
})
const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

interface MoveItemProps {
  item?: any
  dragHandleProps?: DraggableProps
  index: number
  onRemoveSection?: (index: number) => void
  onSectionChange?: (title: string, index: number) => void
  onRemoveItem?: (index: number) => void
  onItemChange?: (items: Array<any>, index: number) => void
}
const OPTIONS = [
  {
    Name: 'Repair/Replace',
    Type: 'RepairReplace',
    Sort: 1,
  },
  {
    Name: 'Dirty',
    Type: 'Dirty',
    Sort: 20,
  },
  {
    Name: 'Partial/Full',
    Type: 'PartialFull',
    Sort: 30,
  },
] as unknown as IOption[]
const TAG_OPTIONS = [
  {
    label: 'Floors/Carpet',
    value: 'FloorsCarpet',
  },
  {
    label: 'Doors',
    value: 'Doors',
  },
  {
    label: 'Appliances',
    value: 'Appliances',
  },
  {
    label: 'Plumbing',
    value: 'Plumbing',
  },
  {
    label: 'Electrical',
    value: 'Electrical',
  },
  {
    label: 'Carpentry',
    value: 'Carpentry',
  },
  {
    label: 'Paint',
    value: 'Paint',
  },
  {
    label: 'Miscellaneous',
    value: 'Miscellaneous',
  },
].sort((item, item1) => item.label.localeCompare(item1.label))

const CONFIG_KEY = { label: 'Name', value: 'Type' }
const MoveItem: React.FC<MoveItemProps> = ({
  item,
  dragHandleProps,
  index,
  onRemoveSection,
  onItemChange,
  onSectionChange,
}) => {
  const classes = useStyles()
  const { Code, SectionName, Items = [] } = item || {}
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Items || [], source.index, destination.index)
    onItemChange?.(newItems, index)
  }
  const onRemove = useCallback(
    (itemIndex) => {
      Items.splice(itemIndex, 1)
      onItemChange?.(Items, index)
    },
    [Code, Items, onItemChange]
  )
  const onAdd = useCallback(() => {
    const items = [...(Items || [])]
    items.push({ Code: `Q${Date.now()}` })
    onItemChange?.(items, index)
  }, [Code, Items, onItemChange])
  const onInputItem = useCallback(
    (value, item, itemIndex) => {
      const items = [...(Items || [])]
      items.splice(itemIndex, 1, Object.assign({}, item, { ItemName: value }))
      onItemChange?.(items, index)
    },
    [Code, Items, onItemChange]
  )
  const onChangeButton = useCallback(
    (value: IOption[], item, itemIndex) => {
      const items = [...(Items || [])]
      items.splice(itemIndex, 1, Object.assign({}, item, { Buttons: value }))
      onItemChange?.(items, index)
    },
    [Code, Items, onItemChange]
  )
  const onChangeTag = useCallback(
    (value, item, itemIndex) => {
      const items = [...(Items || [])]
      items.splice(itemIndex, 1, Object.assign({}, item, { Tag: value?.value }))
      onItemChange?.(items, index)
    },
    [Code, Items, onItemChange]
  )
  const onInput = useCallback(
    (value: string) => {
      onSectionChange?.(value, index)
    },
    [index, onSectionChange]
  )
  return (
    <div className={classes.section}>
      <Typography className={classes.title}>
        <span style={{ flex: 1 }}>{`Section ${index + 1}`}</span>
        <div {...dragHandleProps}>
          <IconButton>
            <Menu htmlColor="#466CB5" />
          </IconButton>
        </div>
        <IconButton disableRipple onClick={() => onRemoveSection?.(index)}>
          <img src={Images.iconDelete} alt="delete" className={classes.delete} />
        </IconButton>
      </Typography>
      <div className={classes.sectionName}>
        <CustomTextField
          label="Section Name"
          className={classes.itemText}
          variant="outlined"
          disabled={false}
          onInputChange={onInput}
          value={SectionName}
          style={{ marginRight: 0 }}
        />
      </div>
      <Typography sx={{ marginTop: '16px' }}>Items</Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {Items?.map((item: any, index: number) => (
                <Draggable key={item?.Code} draggableId={item?.Code} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={classes.item}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    >
                      <CustomTextField
                        className={classes.itemText}
                        label="Item Name"
                        variant="outlined"
                        value={item.ItemName}
                        onInputChange={(value) => onInputItem(value, item, index)}
                        disabled={false}
                      />
                      <MultipleSelect
                        data={OPTIONS}
                        isMulti
                        label="Button Groups"
                        itemKey={CONFIG_KEY}
                        defaultValue={item.Buttons}
                        onChange={(value) => onChangeButton(value, item, index)}
                        className={classes.multiple}
                      />
                      <Autocomplete
                        disableClearable
                        value={TAG_OPTIONS.find((v) => v.value === item.Tag)}
                        onChange={(e, value) => onChangeTag(value, item, index)}
                        options={TAG_OPTIONS}
                        style={{ marginLeft: 16 }}
                        className={classes.multiple}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Tag" variant="outlined" />}
                        popupIcon={<ExpandMore />}
                      />
                      <div {...draggableProvided.dragHandleProps}>
                        <IconButton>
                          <Menu htmlColor="#466CB5" />
                        </IconButton>
                      </div>
                      <IconButton disableRipple onClick={() => onRemove(index)}>
                        <img src={Images.iconDelete} alt="delete" className={classes.delete} />
                      </IconButton>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button aria-label="add" className={classes.add} onClick={onAdd}>
        <AddCircle color="primary" style={{ marginRight: 10 }} />
        Add New
      </Button>
    </div>
  )
}
const MoveOutPanel: React.FC<MoveOutPanelProps> = ({ value, onChange, functionType }) => {
  const [state, setState] = useSetState(value || {})
  const effectRef = useRef<boolean>()
  //@ts-ignore
  const { Sections = [], AlternateEnable, ExternalUrl } = state || {}
  const classes = useStyles()
  const toggleChecked = () => {
    setState({ AlternateEnable: !AlternateEnable })
  }
  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Sections || [], source.index, destination.index)
    setState({ Sections: newItems })
  }
  const onItemChange = useCallback(
    (data, index) => {
      const item = Sections[index]
      item.Items = data
      Sections.splice(index, 1, item)
      setState({ Sections: [...Sections] })
    },
    [setState, Sections]
  )
  const onRemove = useCallback(
    (index) => {
      const item = Sections[index]
      Sections.splice(index, 1)
      setState({ Sections: [...Sections] })
    },
    [Sections, setState]
  )
  const onSectionChange = useCallback(
    (title, index) => {
      const item = Sections[index]
      Sections.splice(index, 1, Object.assign({}, item, { SectionName: title }))
      setState({ Sections: [...Sections] })
    },
    [Sections, setState]
  )
  const onAddSection = useCallback(() => {
    const nextSections = Sections || []
    nextSections.push(Object.assign({ Code: `Q${Date.now()}`, Items: [] }))
    setState?.({ Sections: nextSections })
  }, [Sections, setState])
  useEffect(() => {
    if (effectRef.current) {
      effectRef.current = true
      return
    }
    onChange?.(Object.assign({}, state, { CanSave: isCanSavaQr(functionType, state) }))
  }, [onChange, state])
  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable2">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {Sections?.map((item: any, index: number) => (
                <Draggable key={item?.Code} draggableId={item?.Code} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      className={classes.moveWrapper}
                      style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    >
                      <MoveItem
                        key={index}
                        item={item}
                        index={index}
                        onRemoveSection={onRemove}
                        onSectionChange={onSectionChange}
                        onItemChange={onItemChange}
                        //@ts-ignore
                        dragHandleProps={draggableProvided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        aria-label="addSection"
        variant="outlined"
        className={classes.add}
        style={{ padding: '6px 8px' }}
        onClick={onAddSection}
      >
        <AddCircle color="primary" style={{ marginRight: 4 }} />
        Add Section
      </Button>
      <Box>
        <Box className={classes.url}>
          <Typography className={classes.urlLabel}>Alternate URL</Typography>
          <Switch checked={AlternateEnable} name="checked" onChange={toggleChecked} />
        </Box>
        {AlternateEnable && (
          <CustomTextField
            label=""
            disabled={false}
            value={ExternalUrl}
            placeholder="Enter here"
            name="ExternalUrl"
            onInputChange={onInput}
            fullWidth
            noMargin
          />
        )}
      </Box>
    </div>
  )
}

export default WithDefaultData(MoveOutPanel)
