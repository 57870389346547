import SelectFileType from '@component/QRCode/SelectFileType'
import QRCodeButtons from '@component/QRCode/QRCodeButtons'
import { Box, Button, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { copyText } from '@/utils'

const useStyles = makeStyles((theme) => ({
  empty: {
    color: '#374053',
    fontSize: 22,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  qrcodeBox: {
    width: 426,
    display: 'flex',
    alignSelf: 'center',
    borderRadius: 4,
    padding: '40px 0',
    border: '1px solid #D5D5D5',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  copyRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    paddingLeft: 16,
    paddingRight: 115,
    marginRight: 16,
  },
  url: {
    flex: 1,
    border: '1px solid #D5D5D5',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    padding: '8px 16px',
  },
  copyBtn: {
    right: 0,
    width: 115,
    position: 'absolute',
    height: '100%',
    backgroundColor: '#386BBF',
    borderRadius: '0 3px 3px 0',
    color: 'white',
    '&:hover': {
      backgroundColor: '#386BBF',
    },
  },
  eamilContainer: {
    position: 'absolute',
    backgroundColor: 'red',
    left: 0,
    right: 0,
    bottom: 0,
  },
}))

interface Props {
  url?: string
  pointingUrl?: string
  qrId?: string
  onSaveQRRefresh?: () => void
  disabledEdit?: boolean
}

function getFileExtension(url: string) {
  if (!url) return
  return url.split(/[#?]/)[0]?.split('.')?.pop()?.trim()
}

const QrFrame: React.FC<Props> = ({ url, onSaveQRRefresh, pointingUrl, qrId, disabledEdit }) => {
  const classes = useStyles()
  const [downloadFileType, setDownloadFileType] = useState('')
  const ext = getFileExtension(url || '')
  // @ts-ignore
  const pngUrl = url?.replace(/(.eps)|(.pdf)/g, '.png')
  const onCopy = useCallback(() => {
    copyText(pointingUrl)
  }, [pointingUrl])
  return (
    <Box className={classes.qrcodeBox}>
      <Box className={classes.eamilContainer}></Box>
      <img
        src={pngUrl}
        style={{
          width: 280,
          height: 280,
          backgroundSize: 'contain',
        }}
      />
      {!!ext && <SelectFileType onChangeFileType={setDownloadFileType} fileType={ext} />}
      {!!ext && (
        <QRCodeButtons
          disabledEdit={disabledEdit}
          url={url}
          type={downloadFileType || ext}
          onSaveQRRefresh={onSaveQRRefresh}
          qrId={qrId}
        />
      )}
      {!!pointingUrl && (
        <div className={classes.copyRow}>
          <Typography className={classes.url}>{pointingUrl}</Typography>
          <Button variant="text" className={classes.copyBtn} onClick={onCopy}>
            Copy
          </Button>
        </div>
      )}
    </Box>
  )
}

export default QrFrame
