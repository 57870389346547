import React, { useCallback } from 'react'
import CustomerAccordion from '@component/Accordion/CustomerAccordion'
import { Typography } from '@mui/material'
import { FieldForm as FieldFormCom } from '@activate-inc/activate-ui'
import uploadFile, { uploadImage } from '@utils/uploadFile'
import { ProductQuestionITF } from '@/types/entity'
import { makeStyles } from '@mui/styles'
const FieldForm = React.memo(FieldFormCom)
interface Props {
  item: ProductQuestionITF
  index: number
  len: number
  gId: string
  onSubmitField: (data: Object[], code: string) => void
}
const useStyles = makeStyles(() => ({
  surveyQuestion: {
    zIndex: 2,
    marginBottom: 12,
    color: '#211F1F',
  },
  form: {
    marginTop: -44,
    padding: 0,
    backgroundColor: 'white',
  },
  cardClassName: {
    boxShadow: 'none',
    margin: '-24px 0',
    padding: 0,
  },
  saveClass: {
    margin: 0,
    width: 100,
    minWidth: 'unset',
  },
  btnContainerClass: {
    width: '100%',
    justifyContent: 'flex-end !important',
    margin: '24px 0',
  },
}))
const ProductQuestionItem: React.FC<Props> = ({ item, index, onSubmitField, len, gId }) => {
  const classes = useStyles()
  const onSubmit = useCallback((data) => onSubmitField(data, item.Code), [item.Code, onSubmitField])
  const onFileUpload = useCallback((files: FileList) => uploadFile(files, gId), [gId])
  const onUploadImage = useCallback((files: FileList) => uploadImage(files, gId), [gId])
  return (
    <CustomerAccordion key={item.Code} title={`${len + index + 1}. ${item.SectionTitle || ''}`}>
      <Typography className={classes.surveyQuestion}>{item.SurveyQuestion}</Typography>
      <FieldForm
        className={classes.form}
        cardClassName={classes.cardClassName}
        qrTypeName={''}
        data={item.Fields}
        language="en"
        onFileUpload={onFileUpload}
        beforeUpload={onUploadImage}
        btnText="Save"
        returnValueKey
        showDivider
        desktop
        onSubmit={onSubmit}
        saveClass={classes.saveClass}
        btnContainerClass={classes.btnContainerClass}
      />
    </CustomerAccordion>
  )
}

export default React.memo(ProductQuestionItem)
