import React, { useCallback, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import useSetState from '@hooks/useSetState'
import { ExpandMore } from '@mui/icons-material'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { Datagrid, Resource, DateField, TextField, List, TextFieldProps, useListContext } from 'react-admin'
import classnames from 'classnames'
import PeriodSelect from '@component/DateSelect/PeriodSelect'
import { DEFAULT_PERIOD_LAST_YEAR } from '@/global'
import { getCheckRecordSummariesFilter } from '@api'
import { PanelChoice } from '@/types/panel'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: '#F7F7F7',
    marginTop: '-98px',
  },
  header: {
    display: 'flex',
    padding: '18px 0',
    height: 100,
    justifyContent: 'flex-end',
  },
  list: {},
}))

type Column = TextFieldProps | Record<string, any>
const column: Column[] = [
  {
    source: 'Date',
    label: 'Date',
    sortable: true,
    sortBy: 'ClockIn',
  },
  { source: 'Name', label: 'Name', textAlign: 'left', sortable: true },
  { source: 'ClockInTime', label: 'Check In', sortable: true, sortBy: 'ClockIn' },
  { source: 'ClockOutTime', label: 'Check Out', sortable: true, sortBy: 'ClockOut' },
  { source: 'WorkdayHours', label: 'Workday Hours', sortable: true },
  { source: 'BreakHours', label: 'Break', sortable: true },
  { source: 'TotalHours', label: 'Total Hours', sortable: true },
]
const PropertyListContent = () => {
  const { resource } = useListContext()
  return (
    <Datagrid bulkActionButtons={false}>
      {column.map((record, index) => {
        const { source, sortable = false, textAlign = 'center' } = record
        if (source === 'CreatedDate') {
          //@ts-ignore
          return <DateField key={index} {...record} sortable={sortable} textAlign={textAlign} emptyText="N/A" />
        }
        return <TextField key={index} {...record} sortable={sortable} textAlign={textAlign} emptyText="N/A" />
      })}
    </Datagrid>
  )
}

interface StaffCheckInProps {
  gId?: string
  bId?: string
  tu?: string
  name?: string
}

const StaffCheckIn: React.FC<StaffCheckInProps> = ({ gId, bId, tu, name }) => {
  const classes = useStyles()
  return (
    <Box className={classes.list}>
      <List filter={{ gId, bId, tu, name }} actions={false} perPage={25}>
        <PropertyListContent />
      </List>
    </Box>
  )
}
const StaffCheckInReport = () => {
  const classes = useStyles()
  const { selectedGroupId: gId, selectedPropertyId: bId } = useSelector((state) => state.profile)

  const [{ employees, employee, period }, setState] = useSetState<{
    employees?: Array<PanelChoice>
    employee: string
    period: typeof DEFAULT_PERIOD_LAST_YEAR
    loading: boolean
  }>({
    employee: '',
    period: DEFAULT_PERIOD_LAST_YEAR,
    loading: false,
  })
  const tu = period.value as string
  const onInput = (value: string) => {}
  const getFilter = useCallback(() => {
    getCheckRecordSummariesFilter<{ Employee: string[] }>({ gId, bId }).then((res) => {
      setState({ employees: res.Employee?.map((item) => ({ label: item, value: item })) || [] })
    })
  }, [])
  useEffect(() => {
    localStorage.setItem(
      'DashboardDate',
      JSON.stringify({
        tu,
        name: employee,
      })
    )
  }, [tu, employee])
  useEffect(() => {
    getFilter()
  }, [getFilter])
  return (
    <Box className={classnames(classes.root)}>
      <div className={classes.header}>
        <PanelSelection
          label="Employee"
          choices={employees || []}
          loading={!employees}
          value={employees?.find((item) => item.value === employee)}
          onChange={(item) => setState({ employee: item?.value as string })}
          popupIcon={<ExpandMore />}
          // width={'100%'}
          height={56}
        />
        <PeriodSelect
          style={{ marginLeft: '16px' }}
          period={period}
          onChange={(value) => setState({ period: value })}
          label="Date filter"
        />
      </div>
      <Resource name="staffCheckIn" list={<StaffCheckIn bId={bId} gId={gId} tu={tu} name={employee} />} />
    </Box>
  )
}

export default StaffCheckInReport
