import React, { useCallback, useState, useContext, useEffect, useRef } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import { toBase64 } from '@/utils'
import { uploadBase64 } from '@/apis'
import { Box, Button } from '@mui/material'
import { FormDataContext } from '../../context/FormDataContext'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 5,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    btnBox: {
      display: 'flex',
      flexDirection: 'row',
      margin: '10px auto',
    },
    table: {
      minWidth: 300,
    },
    img: {
      width: '30px',
      height: '30px',
      objectFit: 'cover',
    },
    upload: {
      paddingTop: 10,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      color: '#374053',
      textAlign: 'left',
    },
    input: {
      display: 'none',
    },
    photoCamera: {
      fontSize: 50,
    },
    logoImg: {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      marginRight: 15,
    },
  }
})

const ITEM = {
  Title: '',
  LogoImg: '',
  Url: '',
}

const S3_Bucket = process.env.REACT_APP_S3_BUCKET

interface PortfolioFormDataProps {
  onChange?: (staticForm: any) => void
  value: any
}

interface PortfolioItem {
  Title: string
  LogoImg: string
  Url: string
}

interface BasicTable {
  data: any[]
  onDelete: (index: number) => void
}

const BasicTable: React.FC<BasicTable> = ({ data, onDelete }) => {
  const classes = useStyles()
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Logo</TableCell>
            <TableCell align="right">Link</TableCell>
            <TableCell align="right">Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, i) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.Title?.en}
              </TableCell>
              <TableCell align="right">{<img src={row.LogoImg} className={classes.img} />}</TableCell>
              <TableCell align="right">
                <a href={row.Url}>Link</a>
              </TableCell>
              <TableCell align="right">
                <button onClick={() => onDelete(i)}>Delete</button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface PortfolistItem {
  clear: boolean
  onChange: (item: PortfolioItem) => void
}

const PortfolistItem: React.FC<PortfolistItem> = ({ onChange, clear }) => {
  const classes = useStyles()
  const [state, setState] = useState<PortfolioItem>({ ...ITEM })
  const { LogoImg } = state
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const handleImageChange = useCallback(async (event: any) => {
    const images = event.target.files
    if (images[0]) {
      const base64Image = await toBase64(images[0])
      const s3Image: any = await uploadBase64({
        base64Image,
        fileName: `Portfolio/${images[0].name}`,
        bucket: S3_Bucket,
      })
      setState((prevState) => ({
        ...prevState,
        LogoImg: s3Image.imageUrl,
      }))
    }
  }, [])

  useEffect(() => {
    onChange({ ...state })
  }, [state])

  useEffect(() => {
    setState({ ...ITEM })
  }, [clear])

  return (
    <Box className={classes.container}>
      <CustomTextField
        label="CompanyName"
        value={state?.Title}
        disabled={false}
        placeholder="Company Name"
        onChange={handleChange}
        name="Title"
      />
      <Box className={classes.logo}>Logo</Box>
      <Box className={classes.upload}>
        {LogoImg && <img src={LogoImg} alt="LogoImg" className={classes.logoImg} />}
        <input
          accept="image/*"
          className={classes.input}
          id="icon-button-file"
          onChange={handleImageChange}
          type="file"
        />
        <label htmlFor="icon-button-file">
          <PhotoCamera className={classes.photoCamera} />
        </label>
      </Box>
      <CustomTextField
        label="Link"
        value={state?.Url}
        disabled={false}
        placeholder="Link "
        onChange={handleChange}
        name="Url"
      />
    </Box>
  )
}

const PortfolioFormData: React.FC<PortfolioFormDataProps> = ({ onChange, value }) => {
  const classes = useStyles()
  const {
    portfolioData: { Items },
    updatePortfolio,
  } = useContext(FormDataContext)
  const [state, setState] = useState<{ items: any[] }>({ items: value?.Items || Items })
  const [clear, setClear] = useState(false)
  const { items = [] } = state
  const itemData = useRef({ ...ITEM })

  useEffect(() => {
    // updatePortfolio({ Items: items })
    onChange?.({ Items: items, CanSave: items.length > 0 })
  }, [items])

  const onAdd = () => {
    const { Title, LogoImg, Url } = itemData.current
    if (Title && LogoImg && Url) {
      const newTitle = {
        en: Title,
        es: Title,
      }
      setState({ ...state, items: [...items, { ...itemData.current, Title: newTitle }] })
      setClear((v) => !v)
    }
  }
  const onDelete = (i: number) => {
    items.splice(i, 1)
    setState({ ...state, items: [...items] })
  }

  return (
    <Box className={classes.container}>
      <PortfolistItem
        clear={clear}
        onChange={(item: { Title: string; LogoImg: string; Url: string }) => {
          itemData.current = item
        }}
      />
      <Box className={classes.btnBox}>
        <Button variant="outlined" color="primary" size="medium" onClick={onAdd}>
          Add New Portoflio
        </Button>
      </Box>

      <BasicTable data={items} onDelete={(index: number) => onDelete(index)} />
    </Box>
  )
}

export default PortfolioFormData
