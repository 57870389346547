import React, { useContext, useState, useEffect, useImperativeHandle } from 'react'
import { Box, Typography, Theme } from '@mui/material'
import QRCodeContext from '../QRCodeContext'
import QRCodeEngine from '../../apis/qrcode-generator'
import { QRCodeConfigEnum, QRCodeType } from '../../types'
import { useDebouncedCallback } from 'use-debounce'
import SnackBar from '@component/SnackBar'
import { globalAdminUpdateQr } from '@/apis'
import { QRType, Template } from '@/types/entity'
import { QRCodeFunctionType } from '@/types'
import { beforeSave } from '@component/EditPanel/FireExtinguisherPanel'
import QrFrame from '@component/QRCode/QrFrame'
import { makeStyles } from '@mui/styles'
import Images from '@assets/images'

interface Props {
  groupId: string
  buildingId: string
  editTemplate: Template | undefined | null
  generatorTime?: number
  refreshTime?: number
  entityId?: string
  customEntity: any
  qrType?: QRType | null
  canGenerator: boolean
  templateId?: string
  qrName: string
  handleSuccess: (img: string, result: any) => void
  childRef: React.MutableRefObject<any>
  nameEn?: string
  qrId?: string
  url?: string
  pointingUrl?: string
  isEdit?: boolean
  mechanicalSpace?: string
  equipmentType?: string
  stopType?: string
  disabledEdit?: boolean
  inspectionType?: string
  Accessible?: string
  TenantId?: string
  TenantName?: string
  TagType?: string
  Skip1FA?: boolean
}

const useStyles = makeStyles<Theme>((theme) => ({
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    margin: theme.spacing(2),
    background: '#fff',
    paddingBottom: 20,
    paddingTop: 20,
    border: '1px solid #D5D5D5',
  },
}))

const Inner: React.FC<Props> = (props) => {
  const classes = useStyles()
  const {
    groupId,
    editTemplate,
    entityId,
    customEntity,
    qrType,
    canGenerator,
    templateId,
    qrName,
    handleSuccess,
    qrId,
    childRef,
    nameEn,
    url,
    isEdit,
    pointingUrl,
    mechanicalSpace: MechanicalSpace,
    equipmentType,
    stopType,
    disabledEdit,
    inspectionType,
    Accessible,
    TenantId,
    TenantName,
    TagType,
  } = props
  const { Skip1FA } = props

  const [imageUrl, setImageUrl] = useState(url)
  const [pointUrl, setPointUrl] = useState(pointingUrl)
  const [qrCodeId, setQrCodeId] = useState(qrId)
  const { getOptionValue } = useContext(QRCodeContext)
  const qrConfigType = getOptionValue(QRCodeConfigEnum.QRType)
  const ecLevel = getOptionValue(QRCodeConfigEnum.ECLevel)
  const bgColor = getOptionValue(QRCodeConfigEnum.BGColor)
  const fillColor = getOptionValue(QRCodeConfigEnum.FillColor)
  const eyeColor = getOptionValue(QRCodeConfigEnum.EyeColor)
  const eyeFrame = getOptionValue(QRCodeConfigEnum.EyeFrameShape)
  const eyeBall = getOptionValue(QRCodeConfigEnum.EyeBallShape)
  const bodyShape = getOptionValue(QRCodeConfigEnum.BodyShape)
  const logoImage = getOptionValue(QRCodeConfigEnum.LogoImage)
  const size = getOptionValue(QRCodeConfigEnum.Size)
  const backgroundDimming: string = getOptionValue(QRCodeConfigEnum.BackgroundDimming)
  const fileType = getOptionValue(QRCodeConfigEnum.FileType)

  const { CanSave, FieldsChange = false, withNewLocation, ...extraData } = editTemplate || {}
  const templateFormData = Object.assign({}, extraData)
  if (templateId) {
    Object.assign(templateFormData, { acId: templateId })
  }
  const updateQrCode = useDebouncedCallback((isfresh) => {
    updateQrInner(isfresh)
  }, 2000)

  const onSaveQRRefresh = () => {
    if (qrId) {
      updateQrCode(true)
    } else {
      generateQRCodeInner()
    }
  }

  const generateQRCodeInner = async () => {
    if (!entityId) return
    const options: Record<string, any> = {
      correctLevel: ecLevel,
      bgColor,
      body: bodyShape,
      bodyColor: fillColor,
      size: Number(size),
      eye: eyeFrame,
      eyeBall: eyeBall,
      eye1Color: eyeColor,
      eye2Color: eyeColor,
      eye3Color: eyeColor,
      eyeBall1Color: eyeColor,
      eyeBall2Color: eyeColor,
      eyeBall3Color: eyeColor,
      // text: url,
      outFileType: fileType,
    }
    let acId = ''
    if (
      FieldsChange &&
      [QRCodeFunctionType.FireExtinguisher, QRCodeFunctionType.AEDInspection].includes(
        qrType?.FunctionalityType as QRCodeFunctionType
      )
    ) {
      acId = await beforeSave({
        ...templateFormData,
        FunctionalityType: qrType?.FunctionalityType,
        GroupId: groupId,
        EntityId: entityId,
        FormTemplateId: templateId,
      })
    }
    const businessOptions = {
      GroupId: groupId,
      EntityId: entityId,
      Floor: customEntity?.Floor || '',
      Unit: customEntity?.Unit || '',
      Name: qrName || qrType?.Name,
      QrType: qrType?.Name,
      TemplateId: acId || templateId,
      QrStyle: qrConfigType,
      NameEn: nameEn,
      TemplateFormData: templateFormData,
      Accessible,
      TenantId,
      TenantName,
      TagType,
      Skip1FA,
    }
    customEntity?.areaId && Object.assign(businessOptions, { AreaId: customEntity?.areaId })
    MechanicalSpace && Object.assign(businessOptions, { MechanicalSpace })
    equipmentType && Object.assign(businessOptions, { EquipmentType: equipmentType, StopType: stopType })
    inspectionType && Object.assign(businessOptions, { InspectionType: inspectionType })
    switch (qrConfigType) {
      case QRCodeType.Custom: {
        setImageUrl(Images.loadingWait)
        options.image = logoImage
        options.logo = logoImage
        // @ts-ignore
        try {
          const result = await QRCodeEngine.generatorCustomBackground(businessOptions, options, templateFormData)
          const { imageUrl } = result || {}
          SnackBar({ msg: 'Successfully saved!', type: 'success' })
          handleSuccess && handleSuccess(imageUrl, result)
          setImageUrl(imageUrl + '?date=' + Date.now())
        } catch (e) {
          SnackBar({ msg: 'Failed to generate QR code!' })
        }
        break
      }
      default: {
        setImageUrl(Images.loadingWait)
        const backgroundOptions = {
          correctLevel: Number(ecLevel),
          colorDark: fillColor,
          colorLight: bgColor,
          backgroundImage: logoImage,
          size: Number(size),
          file: fileType,
          print: fileType === 'pdf' ? fileType : '',
          backgroundDimming,
        }
        try {
          const result = await QRCodeEngine.generator(businessOptions, backgroundOptions, templateFormData)
          const { imageUrl, pointingUrl, qrId } = result || {}
          setImageUrl(imageUrl)
          setPointUrl(pointingUrl)
          setQrCodeId(qrId)
          SnackBar({ msg: 'Successfully saved!', type: 'success' })
          handleSuccess && handleSuccess(imageUrl, result)
          setImageUrl(imageUrl + '?date=' + Date.now())
        } catch (e) {
          SnackBar({ msg: 'Failed to generate QR code!' })
        }
        break
      }
    }
  }

  const updateQrInner = async (isfresh?: boolean) => {
    let acId = ''
    if (
      FieldsChange &&
      [QRCodeFunctionType.FireExtinguisher, QRCodeFunctionType.AEDInspection].includes(
        qrType?.FunctionalityType as QRCodeFunctionType
      )
    ) {
      acId = await beforeSave({
        ...templateFormData,
        FunctionalityType: qrType?.FunctionalityType,
        GroupId: groupId,
        EntityId: entityId,
        FormTemplateId: templateId,
      })
    }
    const updateParams = {
      GroupId: groupId,
      EntityId: entityId,
      QrId: qrId,
      Name: qrName,
      Floor: customEntity.Floor,
      Unit: customEntity.Unit,
      CustomEntityType: customEntity.Type,
      QrType: qrType?.Name,
      TemplateId: acId || templateId,
      QrStyle: qrConfigType,
      NameEn: nameEn,
      TemplateFormData: templateFormData,
      Accessible,
      TenantId,
      TenantName,
      TagType,
      Skip1FA,
    }
    MechanicalSpace && Object.assign(updateParams, { MechanicalSpace })
    equipmentType && Object.assign(updateParams, { EquipmentType: equipmentType, StopType: stopType })
    inspectionType && Object.assign(updateParams, { InspectionType: inspectionType })
    isfresh && Object.assign(updateParams, { ForceRefresh: true })
    try {
      const result: any = await globalAdminUpdateQr(updateParams)
      const { ImageUrl } = result
      handleSuccess && handleSuccess(ImageUrl, result)
      SnackBar({ msg: 'Successfully saved!', type: 'success' })
    } catch (e) {
      SnackBar({ msg: 'Failed to generate QR code!' })
    }
  }

  // useEffect(() => {
  //   if (!canGenerator) return
  //   if (qrId) {
  //     updateQrCode(false)
  //   } else {
  //     generateQRCode()
  //   }
  // }, [generatorTime])

  useEffect(() => {
    setImageUrl(url)
  }, [url])

  useEffect(() => {
    qrId && setQrCodeId(qrId)
  }, [qrId])

  useImperativeHandle(
    childRef,
    () => ({
      ...childRef.current,
      onSaveQRRefresh,
    }),
    [onSaveQRRefresh]
  )

  const EmptyView: React.FC<{ title: string }> = ({ title }) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <Typography className={classes.empty}>{title}</Typography>
      </Box>
    )
  }
  const showFrame = isEdit || (!isEdit && imageUrl)
  const isLoading = imageUrl === Images.loadingWait
  if (showFrame && !isLoading) {
    return (
      <QrFrame
        url={imageUrl}
        pointingUrl={pointUrl || pointingUrl}
        qrId={qrCodeId}
        onSaveQRRefresh={onSaveQRRefresh}
        disabledEdit={disabledEdit}
      />
    )
  }

  return (
    <Box className={classes.qrContainer}>
      {isLoading ? (
        <img
          src={Images.loadingWait}
          style={{
            width: 280,
            height: 280,
            backgroundSize: 'contain',
          }}
          alt="loading"
        />
      ) : (
        <EmptyView title={!canGenerator ? 'Please input require data' : 'Ready to generator'} />
      )}
    </Box>
  )
}

export const MemoizedQRConfig = React.memo(Inner)
