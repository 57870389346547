export const FeedBackMock = [
  {
    Required: true,
    Code: 'Q001',
    Level: 1,
    QuestionStyle: 'StarRating',
    AnswerType: 'SingleChoice',
    QuestionType: 'Question',
    QuestionText: {
      zh: 'How was the Bathroom?',
      en: 'How was the Bathroom?',
    },
    Choices: [
      {
        Label: 'A',
        Text: {
          en: 'Poor',
          zh: 'Poor',
        },
        Value: 1,
        SubChoice: false,
      },
      {
        Label: 'B',
        Text: {
          en: 'Not Good',
          zh: 'Not Good',
        },
        Value: 2,
        SubChoice: false,
      },
      {
        Label: 'C',
        Text: {
          en: 'OK',
          zh: 'OK',
        },
        Value: 3,
        SubChoice: false,
      },
      {
        Label: 'D',
        Text: {
          en: 'Good',
          zh: 'Good',
        },
        Value: 4,
        SubChoice: false,
      },
      {
        Label: 'E',
        Text: {
          en: 'Excellent',
          zh: 'Excellent',
        },
        Value: 5,
        SubChoice: false,
      },
    ],
    AnswerChoices: [],
  },
  {
    Required: true,
    Code: 'Q002',
    Level: 1,
    QuestionStyle: 'MultipleChoiceAndText',
    AnswerType: 'SingleChoiceAndText',
    QuestionType: 'Question',
    QuestionText: {
      zh: 'What Areas Could be Improved?',
      en: 'What Areas Could be Improved?',
    },
    Choices: [
      {
        Label: 'A',
        Text: {
          en: 'Cleaning',
          zh: 'Cleaning',
        },
        Value: 0,
        SubChoice: false,
      },
      {
        Label: 'B',
        Text: {
          en: 'Lighting',
          zh: 'Lighting',
        },
        Value: 0,
        SubChoice: false,
      },
      {
        Label: 'C',
        Text: {
          en: 'Security',
          zh: 'Security',
        },
        Value: 0,
        SubChoice: false,
      },
      {
        Label: 'D',
        Text: {
          en: 'Decorating',
          zh: 'Decorating',
        },
        Value: 0,
        SubChoice: false,
      },
    ],
    AnswerChoices: [],
  },
]

export const PurchaseFormData = {
  title: 'Please Provide the following information:',
  data: [
    {
      Required: true,
      Code: 'Q001',
      FieldText: {
        en: 'Full Name',
        es: 'Full Name',
      },
      FieldType: 'Name',
      FieldStyle: 'Text',
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q002',
      FieldText: {
        en: 'Unit',
        es: 'Unit',
      },
      FieldType: 'Default',
      FieldStyle: 'Text',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q003',
      FieldText: {
        en: 'Mobile No.',
        es: 'Mobile No.',
      },
      FieldType: 'PhoneNumber',
      FieldStyle: 'Text',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q004',
      FieldText: {
        en: 'Email',
        es: 'Email',
      },
      FieldType: 'Email',
      FieldStyle: 'Text',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q005',
      FieldText: {
        en: 'Date of Party',
        es: 'Date of Party',
      },
      FieldType: 'Date',
      FieldStyle: 'Date',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q006',
      FieldText: {
        en: 'Time of Party',
        es: 'Time of Party',
      },
      FieldType: 'Duration',
      FieldStyle: 'Duration',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q007',
      FieldText: {
        en: 'Number of People',
        es: 'Number of People',
      },
      FieldType: 'Quantity',
      FieldStyle: 'DropDownSelection',
      Choices: [
        {
          Label: 'A',
          Text: {
            en: '01',
            es: '01',
          },
          Value: 1,
        },
        {
          Label: 'B',
          Text: {
            en: '02',
            es: '02',
          },
          Value: 2,
        },
        {
          Label: 'C',
          Text: {
            en: '03',
            es: '03',
          },
          Value: 3,
        },
        {
          Label: 'D',
          Text: {
            en: '04',
            es: '04',
          },
          Value: 4,
        },
        {
          Label: 'E',
          Text: {
            en: '05',
            es: '05',
          },
          Value: 5,
        },
        {
          Label: 'F',
          Text: {
            en: '06',
            es: '06',
          },
          Value: 6,
        },
        {
          Label: 'G',
          Text: {
            en: '07',
            es: '07',
          },
          Value: 7,
        },
      ],
      ValueTexts: [],
      ValueChoices: [],
    },
  ],
}

export const CommunicationMockData = {
  title: 'Thanks for your interest SterlingBay! Tell me more about yourself',
  Fields: [
    {
      Required: true,
      Code: 'Q001',
      FieldText: {
        en: 'Full Name',
        es: 'Full Name',
      },
      ValueType: 'Text',
      FieldType: 'Name',
      FieldStyle: 'Text',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q002',
      FieldText: {
        en: 'Mobile No.',
        es: 'Mobile No.',
      },
      ValueType: 'Text',
      FieldType: 'PhoneNumber',
      FieldStyle: 'Text',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q003',
      FieldText: {
        en: 'Email Address',
        es: 'Email Address',
      },
      ValueType: 'Text',
      FieldType: 'Email',
      FieldStyle: 'Text',
      Choices: [],
      ValueTexts: [],
      ValueChoices: [],
    },
    {
      Required: true,
      Code: 'Q004',
      FieldText: {
        en: 'Year',
        es: 'Year',
      },
      ValueType: 'SingleChoice',
      FieldType: 'Default',
      FieldStyle: 'DropDownSelection',
      Choices: [
        {
          Label: 'A',
          Text: {
            en: 'Freshman',
            es: 'Freshman',
          },
          Value: 'Freshman',
        },
        {
          Label: 'B',
          Text: {
            en: 'Sophomore',
            es: 'Sophomore',
          },
          Value: 'Sophomore',
        },
        {
          Label: 'C',
          Text: {
            en: 'Junior',
            es: 'Junior',
          },
          Value: 'Junior',
        },
        {
          Label: 'D',
          Text: {
            en: 'Senior',
            es: 'Senior',
          },
          Value: 'Senior',
        },
      ],
      ValueTexts: [],
      ValueChoices: [],
    },
  ],
}
