import React, { forwardRef, useCallback } from 'react'
import { FormControl, FormHelperText } from '@mui/material'
import { COLOR } from '@constant/app'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import { makeStyles } from '@mui/styles'
import 'react-phone-input-2/lib/material.css'
import MaskedInput from 'react-text-mask'
import classnames from 'classnames'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

interface Information {
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  value?: string
  type?: 'Select' | 'TextField' | 'Phone'
  option?: Record<string, string>[]
  optionData?: Record<'USA' | 'Canada', string[]>
  error?: boolean
  onChangePhone?: (value: string, index: number) => void
  errorText?: string
  index?: number
  showFirst?: boolean
  variant?: 'outlined' | 'standard'
  className?: string
  shrink?: boolean
  labelClassName?: string
  fullWidth?: boolean
  sx?: SxProps<Theme>
}

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: 230,
    flex: '20%',
    // margin: '0 12px 24px',
  },
  standardTextField: {
    marginTop: 20,
    '& .MuiInputBase-input': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .Mui-focused': {
      color: COLOR.grey37,
    },
  },
  phoneInput: {
    marginTop: '0 !important',
    padding: '11px 15px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    // position:'relative',
    '& .form-control': {
      paddingLeft: '14px',
      width: '100%',
      borderRadius: 10,
    },
    '& .special-label': {
      left: 10,
      '&:after': {
        content: '"*"',
        marginLeft: 3,
        color: '#ff0000',
      },
    },
    '& .flag-dropdown': {
      display: 'none',
    },
  },
  label: {
    color: COLOR.grey37,
    '& .Mui-disabled': {
      color: COLOR.grey91,
    },
    '& .Mui-focused': {
      color: 'COLOR.grey37',
    },
    '& span': {
      color: 'red',
    },
  },
  outlinedLabel: {
    backgroundColor: 'white',
    padding: '0 3px',
  },
  underline: {
    '&.Mui-disabled:before': {
      border: 0,
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '&:before': {
      border: 0,
    },
    '&:after': {
      border: 0,
    },
  },
  standardUnderline: {
    '&.Mui-disabled:before': {
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
    },
    '&:before': {
      borderBottomColor: '#D5D5D5',
    },
    '&:after': {
      borderBottomColor: '#D5D5D5',
    },
  },
  phoneLabelDisable: {
    color: COLOR.grey91,
  },
  phoneShrink: {
    // transform: 'translate(14px, -6px) scale(0.75)',
  },
  standardDisabled: {
    borderBottomColor: theme.palette.divider,
  },
}))
const TextMaskCustom = forwardRef((props, ref) => {
  // @ts-ignore
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      name="textPhone"
      ref={(ref: any) => {
        inputRef?.(ref ? ref.inputElement : null)
      }}
      guide={false}
      showMask
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      id="maskedInput"
    />
  )
})

const MobilePhone = (props: Information) => {
  const classes = useStyles()
  const {
    required,
    error,
    label,
    value = '',
    onChangePhone,
    errorText = 'Required',
    index = 0,
    variant = 'outlined',
    className = '',
    disabled,
    shrink,
    labelClassName = '',
    fullWidth,
    sx,
  } = props
  const phoneValue = value?.length === 11 ? value.slice(1) : value
  const onChange = useCallback((e) => {
    const { value } = e.target
    onChangePhone && onChangePhone(value, index)
  }, [])

  const onBlur = () => {
    let result = value.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
    result = result?.length === 10 ? `1${result}` : result
    onChangePhone && onChangePhone(result, index)
  }

  return (
    <FormControl
      required={required}
      variant={variant}
      fullWidth={fullWidth}
      sx={sx}
      className={classnames({
        [classes.textField]: variant === 'outlined',
        [classes.standardTextField]: variant === 'standard',
        [className]: true,
      })}
      error={error}
    >
      <InputLabel
        htmlFor="formatted-text-mask-input"
        className={classnames({
          [classes.label]: true,
          [classes.phoneShrink]: variant === 'outlined',
          [classes.outlinedLabel]: variant === 'outlined',
          [classes.phoneLabelDisable]: disabled,
          [labelClassName]: true,
        })}
        shrink={variant === 'standard' || shrink ? true : undefined}
      >
        {label}
      </InputLabel>
      <Input
        className={classnames({
          [classes.phoneInput]: variant === 'outlined',
          [classes.underline]: variant === 'outlined',
          [classes.standardDisabled]: variant === 'standard',
          [classes.standardUnderline]: variant === 'standard',
        })}
        value={phoneValue}
        onChange={onChange}
        disabled={disabled}
        inputComponent={TextMaskCustom as any}
        id="formatted-text-mask-input"
        onBlur={onBlur}
      />
      <FormHelperText>{error ? errorText : ''}</FormHelperText>
    </FormControl>
  )
}

export default MobilePhone
