import React, { useCallback } from 'react'
import { IconButton, Link, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Images from '@assets/images'
import classnames from 'classnames'
import PicturePreview from '@component/Modal/PicturePreview'

interface Props {
  photo?: string | Array<string>
  reason?: string
}

const useStyles = makeStyles(() => ({
  naText: {
    color: '#9B9B9B',
    fontSize: 14,
  },
  icon: {
    width: 16,
    height: 16,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
    padding: '6px 12px',
    color: '#1976D2',
    textDecoration: 'underline',
  },
  noPhoto: {
    color: '#F0890F',
    textDecoration: 'none',
  },
  photo: {
    fontSize: 14,
    marginLeft: 8,
  },
  pic: {
    width: 24,
    height: 24,
  },
}))
const PhotoTip: React.FC<Props> = ({ photo, reason }) => {
  const classes = useStyles()
  const isMissPhoto = reason === 'Missed Photo'
  const noPhoto = !photo || (Array.isArray(photo) && photo.length === 0)
  const onClick = useCallback(() => {
    const urls = Array.isArray(photo) ? photo : [photo || '']
    PicturePreview.show({
      urls,
    })
  }, [photo])
  if (noPhoto && !isMissPhoto) return <Typography className={classes.naText}>N/A</Typography>
  if (isMissPhoto) {
    return (
      <Link className={classnames(classes.row, classes.noPhoto)}>
        <img src={Images.iconYellowWarn} className={classes.icon} />
        <Typography className={classes.photo}>{isMissPhoto ? 'No Photo' : 'Photo'}</Typography>
      </Link>
    )
  }

  return (
    <IconButton onClick={onClick}>
      <img src={Images.iconPhoto} className={classes.pic} />
    </IconButton>
  )
}

export default PhotoTip
