import React, { useEffect, useState } from 'react'
import { Radio } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { QRCodeType } from '../../types'
import EmailHover from '@component/QRCode/EmailHover'

interface FileTypeProps {
  qrType?: string | QRCodeType
  onChangeFileType: (type: string) => void
  fileType?: string
}

const DownloadFileTypeMaps = {
  png: ['png'],
  pdf: ['png', 'pdf'],
  svg: ['svg'],
  eps: ['png', 'eps'],
}

const SelectFileType: React.FC<FileTypeProps> = ({ onChangeFileType, fileType = 'eps' }) => {
  const [value, setValue] = useState(fileType)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
    onChangeFileType((event.target as HTMLInputElement).value)
  }
  useEffect(() => {
    fileType && setValue(fileType)
  }, [fileType])
  const list = fileType ? DownloadFileTypeMaps[fileType] : ['png', 'pdf', 'svg', 'eps']

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="filetype"
        name="filetype"
        defaultValue={fileType}
        value={value}
        onChange={handleChange}
      >
        {list?.map((type: string) => (
          <FormControlLabel
            key={type}
            value={type}
            control={<Radio color="primary" size="small" />}
            label={type.toUpperCase()}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default SelectFileType
