import React, { useEffect, useCallback, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import EditAndSave from '@component/Button/EditAndSave'
import { getUpsellProducts, saveProductDetail } from '@api'
import Images from '@assets/images'
import { ArrowDropDown } from '@mui/icons-material'
import UpsellModal from '@component/Modal/UpsellModal'

const useStyles = makeStyles(() => {
  return {
    container: {
      backgroundColor: '#fff',
      position: 'relative',
      fontFamily: 'Roboto',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gap: '16px',
      marginBottom: 32,
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 6,
      minHeight: 56,
      border: '1px solid #BCBCBC',
    },
    icon: {
      display: 'flex',
      borderRadius: 7,
      width: 14,
      height: 14,
    },
    title: {
      paddingTop: 36,
      paddingBottom: 16,
      color: '000',
      fontWeight: 700,
    },
    text: {
      flex: 1,
      paddingLeft: 8,
      fontSize: 16,
      textAlign: 'left',
      color: '#211F1F',
    },
  }
})

interface Props {
  disabled?: boolean
  isEdit?: boolean
  onBack?: () => void
  gId: string
  bId: string
}
export enum StateEnum {
  Active = 'Active',
  Implementing = 'Implementing',
  Interested = 'Interested',
  Never = 'Never',
}

export const ColorMap = {
  [StateEnum.Active]: '#018786',
  [StateEnum.Implementing]: '#F0890F',
  [StateEnum.Interested]: '#E05050',
}
interface NoteITF {
  CreatedDate: number
  Note: string
  UserId: string
}
export interface ProductCategoriesItemITF {
  Category: string
  Product: string
  Text: string
  State: string
  Notes: NoteITF[]
}
interface ProductCategoriesITF {
  [key: string]: ProductCategoriesItemITF[]
}
const UpsellInfo = (props: Props) => {
  const { isEdit, disabled, onBack, gId, bId } = props
  const classes = useStyles()
  const [state, setState] = useSetState<{
    ProductCategories?: ProductCategoriesITF
    showModal: boolean
    info?: ProductCategoriesItemITF
  }>({ showModal: false })
  const { ProductCategories, showModal, info } = state
  const [onlyView, setOnlyView] = useState(isEdit)

  const onEdit = () => {
    setOnlyView(false)
  }
  const onSave = () => {}
  const onClickBack = () => {
    // if (onBack) {
    //   return onBack()
    // }
    setOnlyView(true)
  }
  const onSaveModal = (note: string, state: string) => {
    if (!info) return
    saveProductDetail({ GroupId: gId, BuildingId: bId, Product: info.Product, State: state, Note: note }).then(
      (res) => {
        const next = Object.assign({}, ProductCategories)
        const category = next[info.Category]
        const index = category.findIndex((item) => item.Product === info.Product)
        if (!category[index].Notes) {
          category[index].Notes = []
        }
        category[index].Notes.push({ CreatedDate: Date.now(), Note: note } as NoteITF)
        category[index].State = state
        setState({ ProductCategories: next, showModal: false })
      }
    )
  }

  const getData = useCallback(async () => {
    getUpsellProducts<{ ProductCategories: ProductCategoriesITF }>({ gId, bId }).then((res) => {
      setState({ ProductCategories: res.ProductCategories })
    })
  }, [gId, bId, setState])
  const onClose = () => {
    setState({ showModal: false })
  }
  useEffect(() => {
    getData()
  }, [getData])

  return (
    <Box className={`${classes.container}`}>
      {showModal && info && <UpsellModal onSave={onSaveModal} onClose={onClose} info={info} />}
      {ProductCategories &&
        Object.keys(ProductCategories || {}).map((item) => {
          const data = ProductCategories[item]

          return (
            <Box key={item} className={classes.item}>
              <Typography className={classes.title}>{item}</Typography>
              <Box className={classes.content}>
                {data.map((item1) => {
                  const { State } = item1
                  let img = ''
                  let props = {}
                  if (!State) {
                    img = Images.iconStateEmpty
                  } else if (State === StateEnum.Never) {
                    img = Images.iconStateNever
                  } else {
                    props = ColorMap[State] ? { backgroundColor: ColorMap[State] } : {}
                  }
                  return (
                    <Button
                      key={item1.Text}
                      className={classes.box}
                      disabled={onlyView}
                      onClick={() => {
                        setState({ showModal: true, info: item1 })
                      }}
                    >
                      <Box className={classes.icon} {...props}>
                        {img && <img src={img} className={classes.icon} alt="" />}
                      </Box>
                      <Typography className={classes.text}>{item1.Text}</Typography>
                      <ArrowDropDown htmlColor="#211F1F" />
                    </Button>
                  )
                })}
              </Box>
            </Box>
          )
        })}
      {!disabled && (
        <EditAndSave showSave={!onlyView} onCancel={onClickBack} onEdit={onEdit} onSave={onSave} hiddenSave />
      )}
    </Box>
  )
}
export default React.memo(UpsellInfo)
