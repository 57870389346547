import React, { ChangeEvent, useCallback, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import { StaticFormTemplate } from '@/types/panel'
import { makeStyles } from '@mui/styles'
import { toBase64 } from '../../utils'
import { uploadFileBase64 } from '@api/index'
import { QRCodeFunctionType } from '@/types'
import AddIcon from '@mui/icons-material/Add'

const S3_Bucket = process.env.REACT_APP_S3_BUCKET

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
  },
  input: {
    marginTop: 10,
    width: '100%',
    ':&:disabled': {
      color: 'green',
    },
  },
  upload: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  button: {
    width: 200,
    marginBottom: 10,
  },
}))

interface StaticFormProps {
  groupId?: string
  functionType?: QRCodeFunctionType
  onChange?: (staticForm: StaticFormTemplate) => void
  value?: StaticFormTemplate
  disabledEdit?: boolean
}

export const STATIC_REQUIRED = {
  Url: '',
}

const StaticPanel: React.FC<StaticFormProps> = ({ groupId, functionType, onChange, value, disabledEdit }) => {
  const classes = useStyles()
  const [data, setData] = useState<StaticFormTemplate | undefined>(value)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [isSelected, setIsSelected] = useState(false)
  const isFile = functionType && [QRCodeFunctionType.PDF, QRCodeFunctionType.EmbeddedFile].includes(functionType)

  const onInputUrl = useCallback(
    (value) => {
      const canSave = value
      const newData = Object.assign({}, data, { Url: value, CanSave: !!canSave })
      setData(newData)
      onChange?.(newData)
    },
    [data]
  )

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
  }

  const handleSubmission = async () => {
    const base64 = await toBase64(selectedFile)
    const result = await uploadFileBase64({
      base64,
      fileName: `pdf/${groupId}/${selectedFile?.name}`,
      bucket: S3_Bucket,
    })
    // @ts-ignore
    const fileUrl = result?.fileUrl
    const canSave = fileUrl
    const newData = Object.assign({}, data, { Url: fileUrl, CanSave: !!canSave })
    setData(newData)
    onChange?.(newData)
  }

  return (
    <Box className={classes.container}>
      <CustomTextField
        label="Static URL"
        value={data?.Url || ''}
        disabled={disabledEdit || isFile}
        placeholder="Enter here"
        onInputChange={onInputUrl}
        variant="outlined"
      />
      {isFile && (
        <Box className={classes.upload}>
          <Button
            className={classes.button}
            disabled={disabledEdit}
            variant="contained"
            component="label"
            color="primary"
          >
            {' '}
            <AddIcon /> Upload a file
            <input
              type="file"
              disabled={disabledEdit}
              accept="image/png, image/jpeg, image/jpg,.svg,.xls,.xlsx,.doc,.docx,.pdf"
              hidden
              onChange={changeHandler}
            />
          </Button>
          {isSelected ? (
            <Typography>Filename: {selectedFile?.name}</Typography>
          ) : (
            <Typography>Select a file to show details</Typography>
          )}
          <Box className={classes.upload}>
            <Button
              className={classes.button}
              disabled={disabledEdit || !isSelected}
              variant="contained"
              component="label"
              color="primary"
              onClick={handleSubmission}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
export default StaticPanel
