import React from 'react'
import { Box } from '@mui/material'

const StaticWrapper = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'blod',
      }}
    >
      Preview not available for this type
    </Box>
  )
}

export default StaticWrapper
