import * as React from 'react'
import { useSelector } from 'react-redux'
import { Datagrid, List, ListProps, TopToolbar, TextField, ExportButton, DateField } from 'react-admin'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PhoneNumberFiled from '@component/List/PhoneNumberFiled'
import { useNavigate } from 'react-router-dom'
import Images from '@assets/images'
import ImageField from '@component/List/ImageField'

const column = [
  { source: 'GroupName.en', label: 'Account' },
  { source: 'BuildingName.en', label: 'Property Name' },
  {
    source: 'CreatedDate',
    label: 'Request Date',
    sortable: true,
    locales: 'en-US',
    options: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
  { source: 'Name.en', label: 'Full Name' },
  { source: 'Email', label: 'Email' },
  { source: 'PhoneNumber', label: 'Mobile No.' },
  { source: 'CompanyName', label: 'Company Name' },
  { source: 'Urgency', label: 'Priority' },
  { source: 'Issues', label: 'Support Request Details', style: { display: 'inline-block', minWidth: 200 } },
  { source: 'UploadImages', label: 'Photo' },
]

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F7F7F7',
  },
  list: {
    padding: 24,
    background: '#fff',
    marginTop: -16,
    border: '1px solid #F5F5F5',
    borderRadius: 8,
    '& .RaBulkActionsToolbar-toolbar': {
      display: 'none',
    },
    '& .RaList-content': {
      border: 0,
      padding: '0 8px',
      marginTop: 16,
    },
  },
  toolbar: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingTop: 0,
    minHeight: 'auto',
    flexDirection: 'column',
    marginBottom: 12,
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    fontFamily: 'Roboto',
    color: '#211F1F',
    marginBottom: 12,
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 18,
    background: '#FBFBFB',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginBottom: 12,
    width: '100%',
    height: 60,
    boxSizing: 'border-box',
  },
  actionIcon: {
    color: '#466CB5',
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'none',
  },
  dataGrid: {
    '& .RaDatagrid-headerCell': {
      fontWeight: 700,
      fontSize: 16,
      fontFamily: 'Roboto',
      color: '#211F1F',
      background: '#FFF',
      border: 'none',
      height: 48,
      whiteSpace: 'nowrap',
      padding: '8px 16px',
      boxSizing: 'border-box',
    },
    '& .RaDatagrid-rowCell': {
      border: 'none',
      padding: '8px 16px',
    },
    '& .RaDatagrid-row': {
      borderBottom: '1px solid #F5F5F5',
      '&:hover': {
        background: '#F0F6FC !important',
      },
    },
  },
  nameCellClass: {
    height: 48,
    borderBottom: 'none',
  },
  opacity: {
    height: 52,
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 1,
    borderRadius: 8,
    marginBottom: -51,
  },
}))

const PropertyListActions: React.FC = () => {
  const classes = useStyles()

  return (
    <TopToolbar className={classes.toolbar}>
      <Typography className={classes.title}>QR Support Report</Typography>
      <Box className={classes.action}>
        <ExportButton
          label="Download XLSX"
          className={classes.actionIcon}
          icon={<img src={Images.iconDownLoad} style={{ marginRight: 6 }} />}
        />
      </Box>
    </TopToolbar>
  )
}

const PropertyListContent = () => {
  const classes = useStyles()

  return (
    <Datagrid bulkActionButtons={false} className={classes.dataGrid}>
      {column.map((record, index) => {
        const { source, sortable = false } = record
        if (source === 'PhoneNumber') {
          return <PhoneNumberFiled key={index} {...record} sortable={sortable} cellClassName={classes.nameCellClass} />
        }
        if (source === 'UploadImages') {
          return <ImageField key={index} {...record} sortable={sortable} cellClassName={classes.nameCellClass} />
        }
        if (source === 'CreatedDate') {
          //@ts-ignore
          return <DateField key={index} {...record} sortable={sortable} cellClassName={classes.nameCellClass} />
        }
        return <TextField key={index} {...record} sortable={sortable} cellClassName={classes.nameCellClass} />
      })}
    </Datagrid>
  )
}
const QRSupportReport = (props: ListProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { selectedGroupId: gId } = useSelector((state) => state.profile)

  return (
    <Box className={classes.root}>
      <List
        className={classes.list}
        {...props}
        filter={{ gId }}
        perPage={25}
        actions={<PropertyListActions />}
        sort={{ field: 'CreatedDate', order: 'DESC' }}
      >
        <PropertyListContent />
      </List>
      <Box className={classes.opacity} />
    </Box>
  )
}

export default QRSupportReport
