import React from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, Card, CardContent, CardActions } from '@mui/material'
import { Button, Typography, IconButton, Divider } from '@mui/material'
import { Close } from '@mui/icons-material'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material'
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

interface CustomizedDialogsProps {
  open: boolean
  handleClose: () => void
  data: Record<string, any>
}

const CustomerPortalModal: React.FC<CustomizedDialogsProps> = ({ open, data, handleClose }) => {
  const { header, qrName, desc, carouselList, disabled } = data
  const classes = useStyles()
  const navigate = useNavigate()
  const { selectedPropertyId: bId, selectedGroupId } = useSelector((state) => state.profile)

  const onBuild = () => {
    navigate(`/qrcode/${selectedGroupId}/${bId}`, { state: { type: 'CustomerPortal', qrName } })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'lg'}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography className={classes.contactTypographyOuter}>{header}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ '&:hover': { color: '#de350b' }, m: 0 }} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4} container alignItems="center" justifyContent={'center'}>
            <Carousel
              navButtonsAlwaysVisible={true}
              NextIcon={<KeyboardArrowRight sx={{ color: '#466cb5' }} />}
              PrevIcon={<KeyboardArrowLeft sx={{ color: '#466cb5' }} />}
              navButtonsProps={{
                style: { backgroundColor: 'transparent' },
              }}
              interval={3000}
              autoPlay={false}
              fullHeightHover
              sx={{ width: '100%', height: 350 }}
              animation="slide"
            >
              {carouselList?.map((image: string, index: number) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  key={index}
                >
                  <img
                    key={index}
                    src={image}
                    alt=""
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      maxHeight: '320px',
                      width: 'auto',
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </Grid>
          <Grid item xs={8} container alignItems="center" justifyContent={'center'}>
            <Card className={classes.cardClass}>
              <CardContent>
                <Typography className={classes.videoTypography}>{header}</Typography>
                <Divider />
                <Typography className={classes.cardTypography}>{desc}</Typography>
              </CardContent>
              <CardActions className={classes.cardActionClass}>
                <Button
                  className={classes.buildQRCodeButtonClass}
                  onClick={onBuild}
                  variant="contained"
                  disabled={disabled}
                >
                  {disabled ? 'Contact Admin' : 'BUILD A QR'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  contactTypographyOuter: {
    fontWeight: 'bold !important',
    fontSize: '20px !important',
    marginLeft: '7px !important',
  },
  dialogContent: {
    backgroundColor: '#f5f5f5',
    paddingTop: '16px !important',
  },
  formControlClass: {
    backgroundColor: 'white',
    display: 'flex',
    width: '200px',
    height: '30px',
    justifyContent: 'center',
    borderRadius: '5px',
  },
  selectClass: {
    borderRadius: '5px',
    width: '200px',
    height: '30px',
  },
  logoClass: {
    display: 'flex',
    flexDirection: 'row',
  },
  customDropdownClass: {
    margin: '1px 0 0 30px',
  },
  headerIcon: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'right',
  },
  iconClass: {
    marginRight: '10px',
  },
  drawerBoxClass: {
    display: 'flex',
  },
  webBoxClass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    backgroundColor: '#fff !important',
    padding: '34px',
  },
  mobileBoxClass: {
    position: 'absolute',
    top: '140px',
    left: '2px',
    width: '355px',
  },
  dialogContentBoxClass: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    padding: '5px',
    marginBottom: 10,
    backgroundColor: '#f0f6fc',
    borderRadius: 2,
    typography: 'body1',
    '& > :not(style) + :not(style)': {
      marginLeft: 20,
    },
  },
  dialogContentLinkClass: {
    color: '#1876d2',
  },
  titleBox: {
    padding: '15px',
  },
  accordionSummaryClass: {
    fontSize: '16px !important',
    fontWeight: 'bold !important',
  },
  accordionDetailsClass: {
    padding: '9px',
    fontWeight: 'regular',
    fontSize: 16,
  },
  typographyFontClass: {
    fontWeight: '600 !important',
    fontSize: '20px !important',
  },
  accordionButtonClass: {
    backgroundColor: '#466cb5 !important',
    fontWeight: 'normal !important',
  },
  buildQRCodeButtonClass: {
    backgroundColor: '#466cb5 !important',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'normal !important',
  },
  cardActionClass: {
    position: 'absolute',
    bottom: 35,
    left: 250,
  },
  cardClass: {
    position: 'relative',
    width: '598px',
    height: '486px',
    padding: '30px',
    borderRadius: '12px',
  },
  videoTypography: {
    fontWeight: '500 !important',
    fontSize: '24px !important',
    color: '#386BBF',
  },
  cardTypography: {
    fontSize: '16px !important',
    marginTop: '30px !important',
  },
  techSpan: {
    color: '#1976D2',
    fontWeight: 'normal',
    padding: '4px',
    margin: '2px',
    backgroundColor: '#F0F6FC',
    borderRadius: '4px',
  },
  formControlLabelClass: {
    display: 'flex',
    justifyContent: 'center',
  },
  carouselClass: {
    border: '2px solid black',
  },
}))

export default CustomerPortalModal
