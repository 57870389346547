import React, { useCallback, useEffect, useState } from 'react'
import { Resource } from 'react-admin'
import { Box, Button, Modal } from '@mui/material'
import classnames from 'classnames'
import EquipmentList from './EquipmentList'
import { EquipmentFrom } from '@constant/qrcode'
import useStyles from './styles'
import { RaRecord } from 'ra-core'

interface Props {
  onClose: () => void
  onOk: (data: Record<string, any>[]) => void
  equipmentFrom: EquipmentFrom
  disabledArr?: string[]
}

const EquipmentModal: React.FC<Props> = React.memo((props) => {
  const { onOk, onClose, equipmentFrom, disabledArr } = props
  const classes = useStyles()
  const [data, setData] = useState<{ selectedIds?: string[]; data?: RaRecord[] }>({})
  const { selectedIds = [], data: raData = [] } = data || {}
  const onDataChange = useCallback((selectedIds: string[], data: RaRecord[]) => {
    setData({ selectedIds, data })
  }, [])
  const onAdd = () => {
    const arr: RaRecord[] = []
    selectedIds.forEach((v) => {
      const i = raData.find((item) => item.id === v)
      i && arr.push(i)
    })
    onOk(arr)
  }
  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classnames(classes.body)}>
        <Box style={{ flex: 1, overflowY: 'auto' }}>
          <Resource
            name="equipmentLibrary"
            key="equipmentLibrary"
            list={
              <EquipmentList
                isSelect
                equipmentFrom={equipmentFrom}
                disabledArr={disabledArr}
                onDataChange={onDataChange}
              />
            }
          />
        </Box>

        <Box sx={{ textAlign: 'right', marginRight: 3 }}>
          <Button onClick={onClose} className={classes.modalBtn}>
            Cancel
          </Button>

          <Button
            onClick={onAdd}
            variant="contained"
            color="primary"
            className={classes.modalBtn}
            disabled={selectedIds?.length === 0}
          >
            ADD TO ROUND
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

EquipmentModal.displayName = 'EquipmentModal'
export default EquipmentModal
