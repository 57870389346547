import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { Admin, localStorageStore, Resource, CustomRoutes } from 'react-admin'
import { PersistGate } from 'redux-persist/lib/integration/react'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { ThemeProvider } from '@mui/styles'
import { createBrowserHistory } from 'history'
import createAdminStore from './store'
import { QueryClientProvider, QueryClient } from 'react-query'
import authProvider from './authProvider'

import { AppContextProvider } from './context/AppContext'

import { Login, Layout } from './layout'
import { Dashboard } from './dashboard'

import customRoutes, { lazyRouter, resourceRouter } from './routes'
import englishMessages from './i18n/en'

import MarketingList from './analytics/marketing/MarketingList'
import TenantExperienceEvent from './analytics/tenant/Event'
import OperationsList from './analytics/operations'
import PropertyList from './analytics/property'
import SubscriptionList from '@pages/settings/SubscriptionList'
import AccountPropertyList from './account/PropertyList'
import PropertyAdd from './account/PropertyAdd'
import ImportQRCode from './qrcodes/ImportQRCode'
import { theme } from './themes'
import dataProvider from './dataProvider/dataProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import QRSupportReport from '@analytics/marketing/QRSupportReport'
import EquipmentQuestion from '@pages/roundsEngine/equipment/EquipmentQuestion'
import { Route } from 'react-router-dom'
import InspectionReport from '@analytics/inspection/InspectionReport'
import * as Sentry from '@sentry/react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
const history = createBrowserHistory()

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3bf71fa3f0690fac5e98a240aea43bfb@o4506359206445056.ingest.sentry.io/4506471185186816',
    ignoreErrors: ['Non-Error promise rejection captured'],
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false, // 阻止媒体捕获，减少数据量
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1, //  Capture 100% of the transactions
    environment: process.env.REACT_APP_TYPE,
    release: process.env.REACT_APP_TYPE,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === 'fr') {
      return import('./i18n/fr').then((messages) => messages.default)
    }
    return englishMessages
  },
  'en',
  { allowMissing: true }
)

// const httpClient = (url, options = {}) => {
//   if (!options.headers) {
//     options.headers = new Headers({ Accept: 'application/json' });
//   }
//   // const { token } = JSON.parse(localStorage.getItem('auth'));
//   // options.headers.set('Authorization', `Bearer ${token}`);

//   //options.credentials = 'include';
//   return fetchUtils.fetchJson(url, options);
// }

// const dataProvider = restProvider(
//   `${process.env.REACT_APP_API_HOST}`,
//   httpClient,
//   'X-Total-Count'
// )

// const dataProvider = dataProviderFactory(process.env.REACT_APP_API_HOST)
// const dataProvider = wrapDataProvider(simpleRestProvider(apiBase, httpClient), sseClient);
const Host = process.env.REACT_APP_API_HOST || 'https://v3tjybfbx0.execute-api.us-east-1.amazonaws.com/dev'
const myDataProvider = dataProvider(Host)
export const { store, persistor } = createAdminStore({ authProvider, dataProvider: myDataProvider })

const cacheStore = localStorageStore()
cacheStore.setItem('sidebar.open', false)
const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
      },
    },
  })
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <AppContextProvider>
              <PersistGate loading={null} persistor={persistor}>
                <Admin
                  dataProvider={myDataProvider}
                  authProvider={authProvider}
                  dashboard={Dashboard}
                  loginPage={Login}
                  layout={Layout}
                  i18nProvider={i18nProvider}
                  disableTelemetry
                  history={history}
                  theme={theme}
                  store={cacheStore}
                  queryClient={queryClient}
                >
                  {customRoutes}
                  {lazyRouter.map(({ path, element, noLayout, child }) => (
                    <CustomRoutes key={path} noLayout={noLayout}>
                      <Route path={path} key={path} element={<Suspense fallback="">{element}</Suspense>}>
                        {child?.map(({ path, element, child: childLevel3 }) => (
                          <Route path={path} key={path} element={<Suspense fallback="">{element}</Suspense>}>
                            {childLevel3?.map(({ path, element }) => (
                              <Route path={path} key={path} element={<Suspense fallback="">{element}</Suspense>} />
                            ))}
                          </Route>
                        ))}
                      </Route>
                    </CustomRoutes>
                  ))}
                  {(permissions) => [
                    <Resource key="destination" name="destination" />,
                    <Resource name="marketing" key="marketing" list={MarketingList} />,
                    <Resource name="reportMarketing" key="reportMarketing" list={MarketingList} />,
                    <Resource name="operations" key="operations" list={OperationsList} />,
                    <Resource name="tenantExpirenceEvent" key="tenantExpirenceEvent" list={TenantExperienceEvent} />,
                    <Resource name="inspectionReport" key="inspectionReport" list={InspectionReport} />,
                    <Resource
                      name="reportTenantExpirenceEvent"
                      key="reportTenantExpirenceEvent"
                      list={TenantExperienceEvent}
                    />,
                    <Resource name="propertyReport" key="propertyReport" list={PropertyList} />,
                    <Resource name="reportPropertyReport" key="reportPropertyReport" list={PropertyList} />,
                    <Resource name="subscription" key="subscription" list={SubscriptionList} />,
                    <Resource name="subscriptions" key="subscriptions" list={SubscriptionList} />,
                    <Resource name="properties" key="properties" list={AccountPropertyList} create={PropertyAdd} />,
                    <Resource name="importQRCode" key="importQRCode" list={ImportQRCode} />,
                    <Resource name="qrSupportReport" key="qrSupportReport" list={QRSupportReport} />,
                    <Resource name="equipmentQuestion" key="equipmentQuestion" list={EquipmentQuestion} />,
                  ]}
                  {resourceRouter.map(({ path, Element }) => (
                    <Resource
                      key={path}
                      name={path}
                      list={(props) => (
                        <Suspense fallback="">
                          <Element {...props} />
                        </Suspense>
                      )}
                    />
                  ))}
                </Admin>
              </PersistGate>
            </AppContextProvider>
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </QueryClientProvider>
  )
}

export default App
