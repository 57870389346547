import React, { useCallback, useMemo } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { ContentSurveyITF } from '@/types/entity'
import { ExpandMore } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Images from '@assets/images'
import { makeStyles } from '@mui/styles'
import useSetState from '@hooks/useSetState'

interface Props {
  data?: ContentSurveyITF
  onChange: (data: ContentSurveyITF) => void
}
const useStyles = makeStyles(() => ({
  btnRoot: {
    marginLeft: 10,
    padding: 0,
  },
  check: {
    marginTop: 24,
    marginBottom: 24,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 4,
    height: 56,
    flex: 1,
    padding: '0 12px',
    alignItems: 'center',
    border: '1px solid #BCBCBC',
  },
}))

const PropertyInfoEdit: React.FC<Props> = ({ data, onChange }) => {
  const classes = useStyles()
  const [state, setState] = useSetState(data || ({} as ContentSurveyITF))
  const {
    EnableBasicPropertyInfo,
    EnablePortfolioAccount,
    EnableBranding,
    EnableContacts,
    EnableManagementInfo,
    EnableConnectivities,
    EnableFloorsUnits,
    EnablePhysicalSpaces,
  } = state || {}

  const { list } = useMemo(() => {
    const list = [
      {
        label: 'Portfolio Account',
        value: 'EnablePortfolioAccount',
        show: EnablePortfolioAccount,
      },
      {
        label: 'Branding',
        value: 'EnableBranding',
        show: EnableBranding,
      },
      {
        label: 'Contacts',
        value: 'EnableContacts',
        show: EnableContacts,
      },
      {
        label: 'Building Management Info',
        value: 'EnableManagementInfo',
        show: EnableManagementInfo,
      },
      {
        label: 'Connectivity',
        value: 'EnableConnectivities',
        show: EnableConnectivities,
      },
      {
        label: 'Floors & Units',
        value: 'EnableFloorsUnits',
        show: EnableFloorsUnits,
      },
      {
        label: 'Physical Spaces',
        value: 'EnablePhysicalSpaces',
        show: EnablePhysicalSpaces,
      },
    ]
    return { list }
  }, [
    EnableBranding,
    EnableConnectivities,
    EnableContacts,
    EnableFloorsUnits,
    EnableManagementInfo,
    EnablePhysicalSpaces,
    EnablePortfolioAccount,
  ])
  const handleChange = (event: React.SyntheticEvent, checked: boolean) => {
    const state = list.reduce(
      (pre, item) => {
        return Object.assign(pre, { [item.value]: checked })
      },
      { EnableBasicPropertyInfo: checked }
    )
    setState(state)
    setTimeout(() => {
      onChange?.(state as ContentSurveyITF)
    }, 0)
  }
  const onRemoveItem = useCallback(
    (key: string) => {
      const data = Object.assign(state, {
        [key]: false,
      })
      if (['EnablePhysicalSpaces', 'EnableFloorsUnits'].includes(key)) {
        Object.assign(data, { EnablePhysicalSpaces: false, EnableFloorsUnits: false })
      }
      setState(data)
      onChange?.(state as ContentSurveyITF)
    },
    [setState, state]
  )
  return (
    <Box>
      <FormControlLabel
        className={classes.check}
        onChange={handleChange}
        control={<Checkbox checked={EnableBasicPropertyInfo} />}
        label="Basic property information"
      />
      {list.map((item) => {
        if (!item.show) return null
        return (
          <Box key={item.value + item.show} className={classes.item}>
            <Box className={classes.box}>
              <Typography>{item.label}</Typography>
              <ExpandMore />
            </Box>
            <IconButton disableRipple classes={{ root: classes.btnRoot }} onClick={() => onRemoveItem(item.value)}>
              <img src={Images.iconDelete} alt="delete" />
            </IconButton>
          </Box>
        )
      })}
    </Box>
  )
}

export default PropertyInfoEdit
