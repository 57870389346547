import React, { useEffect } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { Typography, Box, TextField, IconButton, InputAdornment } from '@mui/material'
import { DeleteOutline, AddCircle, Reorder, Event } from '@mui/icons-material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { DragDropContext, Droppable, DropResult, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { reorder } from '@/utils/helpers'
import { COLOR } from '@constant/app'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import Dialog from '@component/Modal/Dialog'
import { fireExtinguisherSaveTemplateFields, AEDSaveTemplateFields } from '@/apis'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'

const language = 'en'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    logoLabel: {
      color: COLOR.grey37,
      fontSize: 12,
      marginTop: 20,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    margin: {
      marginTop: 0,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
    },
    itemText: {
      flex: 1,
    },
    date: {
      margin: '20px 0 0',
    },
    disabled: {
      fontSize: 12,
      color: '#777',
      lineHeight: '21px',
    },
    add: {
      justifyContent: 'flex-start',
      color: '#386BBF',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    underline: {
      '&:before': {
        borderWidth: 0,
      },
    },
  }
})

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  userSelect: 'none',
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
}

export const FIRE_EXTINGUISHER_REQUIRED = {
  SerialNo: '',
  Company: '',
  ExtinguisherType: '',
  ExpirationDate: '',
}

export const AED_INSPECTION_REQUIRED = {
  SerialNo: '',
  ExpirationDate: '',
}

export const DialogMessage = {
  [QRCodeFunctionType.AEDInspection]: 'It will impact ALL AED QR Codes.',
  [QRCodeFunctionType.FireExtinguisher]: 'It will impact ALL Fire Extinguisher QR Codes for the building.',
}

const FireExtinguisherPanel: React.FC<Props> = ({ onChange, value, functionType, disabledEdit = false }) => {
  const classes = useStyles()
  const isFireExtinguisher = functionType === QRCodeFunctionType.FireExtinguisher
  const sliceIndex = isFireExtinguisher ? 1 : 0
  const [
    { Company, ExtinguisherType, ExpirationDate, Fields, SerialNo, FieldsChange, otherFields, showDialog, firstField },
    setState,
  ] = useSetState({
    SerialNo: value?.SerialNo || '',
    ExpirationDate: value?.ExpirationDate || new Date().getTime(),
    Company: value?.Company || '',
    ExtinguisherType: value?.ExtinguisherType || '',
    Fields: value?.Fields?.slice(sliceIndex, -2) || [{ Code: 'Q001', FieldText: { en: 'en', es: 'es' } }],
    FieldsChange: false,
    showDialog: false,
    otherFields: value?.Fields?.slice(-2) || [],
    firstField: value?.Fields?.slice(0, sliceIndex) || [],
  })

  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value, FieldsChange: true })
  }
  const handleDateChange = (date: Date | null, context: FieldChangeHandlerContext<any>) => {
    if (date === null) return
    setState({ ExpirationDate: new Date(date).getTime(), FieldsChange: true })
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Fields || [], source.index, destination.index)
    setState({ Fields: newItems, FieldsChange: true, showDialog: true })
  }

  const onRemove = (index: number) => {
    Fields.splice(index, 1)
    setState({ Fields: [...Fields], FieldsChange: true, showDialog: true })
  }

  const onItemChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const { value, name } = event.target
    Fields[index].FieldText = { en: value, es: value }
    setState({ Fields: [...Fields], FieldsChange: true, showDialog: true })
  }

  const onAdd = () => {
    const item = { ...Fields[0], isAdd: true }
    const code = Math.max(...Fields.map((v: { Code: string | any[] }) => Number(v.Code.slice(1)))) + 1
    item.Code = `Q${String(code).padStart(3, '0')}`
    item.FieldText = { en: '', es: '' }
    Fields.push(item)
    setState({ Fields: [...Fields], FieldsChange: true, showDialog: true })
  }

  useEffect(() => {
    if (onChange) {
      const newData = {
        SerialNo,
        Company,
        ExtinguisherType,
        ExpirationDate,
        Fields: firstField.concat(Fields, otherFields),
        FieldsChange,
        showDialog,
      }
      Object.assign(newData, { CanSave: isCanSavaQr(functionType, newData) })
      onChange(newData)
    }
  }, [
    onChange,
    SerialNo,
    Company,
    ExtinguisherType,
    ExpirationDate,
    Fields,
    FieldsChange,
    otherFields,
    functionType,
    showDialog,
    firstField,
  ])

  return (
    <div className={classes.container}>
      <CustomTextField
        label="Serial No."
        disabled={disabledEdit}
        value={SerialNo}
        placeholder="Enter here"
        name="SerialNo"
        onInputChange={onInput}
      />
      <MobileDatePicker
        disabled={disabledEdit}
        className={classes.date}
        label="Expiration Date"
        format="MM/dd/yyyy"
        value={ExpirationDate}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Event />
                </InputAdornment>
              ),
            },
          },
        }}
      />
      {isFireExtinguisher && (
        <CustomTextField
          label="Company"
          value={Company}
          disabled={disabledEdit}
          placeholder="Enter here"
          name="Company"
          onInputChange={onInput}
        />
      )}

      {isFireExtinguisher && (
        <CustomTextField
          label="Extinguisher Type"
          value={ExtinguisherType}
          disabled={disabledEdit}
          placeholder="Enter here"
          name="ExtinguisherType"
          onInputChange={onInput}
        />
      )}

      <>
        <Box className={classes.logoLabel}>Edit Selection</Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {Fields.map(
                  (item: { Code: React.Key | null | undefined; FieldText: { [x: string]: any } }, index: number) => (
                    <Draggable
                      isDragDisabled={disabledEdit}
                      key={item?.Code}
                      draggableId={item?.Code as string}
                      index={index}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          //@ts-ignore
                          style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                        >
                          <Box className={classes.item}>
                            <TextField
                              className={classes.itemText}
                              label={''}
                              multiline
                              disabled={disabledEdit}
                              value={item.FieldText?.[language] || ''}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              InputLabelProps={{ shrink: false }}
                              onChange={(e) => onItemChange(e, index)}
                              variant="standard"
                              placeholder="Enter here"
                            />
                            <IconButton disableRipple onClick={() => onRemove(index)} disabled={disabledEdit}>
                              <DeleteOutline />
                            </IconButton>
                            <div {...draggableProvided.dragHandleProps}>
                              <IconButton disabled={disabledEdit}>
                                <Reorder />
                              </IconButton>
                            </div>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <IconButton aria-label="add" className={classes.add} disabled={disabledEdit} onClick={onAdd}>
          <AddCircle color="primary" style={{ marginRight: 10 }} />
          Add New
        </IconButton>
      </>

      <CustomTextField label="Initials" value={''} disabled={true} placeholder="" name="Initials" />
      <Typography className={classes.disabled}>This field is not editable</Typography>
      <MobileDatePicker
        className={classes.date}
        disabled
        label="Date"
        format="MM/dd/yyyy"
        value={new Date()}
        onChange={() => {}}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Event />
                </InputAdornment>
              ),
            },
          },
        }}
      />
      <Typography className={classes.disabled}>This field is not editable</Typography>
    </div>
  )
}

export default WithDefaultData(FireExtinguisherPanel)

export async function beforeSave(data: any) {
  const { FunctionalityType, showDialog } = data

  if (showDialog) {
    await new Promise((res) => {
      Dialog.show({
        // @ts-ignore
        title: 'Are you sure you want to make edits?',
        desc: DialogMessage[FunctionalityType as keyof typeof DialogMessage],
        onAgree: () => {
          res(true)
        },
      })
    })
  }

  let api = fireExtinguisherSaveTemplateFields
  if (FunctionalityType === QRCodeFunctionType.AEDInspection) {
    api = AEDSaveTemplateFields
  }
  const result = await api(data)
  // @ts-ignore
  return result?.acId
}
