import { Box } from '@mui/material'
import React from 'react'

const WordPlaceHolder = () => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <img
      src="https://static.vecteezy.com/system/resources/previews/005/006/031/large_2x/no-result-data-document-or-file-not-found-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-etc-vector.jpg"
      height={250}
    />
  </Box>
)

export default WordPlaceHolder
