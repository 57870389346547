import * as React from 'react'
import { Fragment, ReactElement, ReactNode } from 'react'
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { useTranslate, useSidebarState } from 'react-admin'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 26,
    marginTop: 12,
    borderRadius: 6,
    fontFamily: 'Roboto, Regular',
    height: 33,
    color: '#a2a2a2',
    paddingRight: 0,
    '& .RaMenuItemLink-icon': {
      minWidth: 26,
    },
    // '& .MuiListItemIcon-icon':{
    //   minWidth: 26
    // }
  },
  headerText: {
    fontFamily: 'Roboto, Regular',
    fontSize: 14,
    fontWeight: 600,
    color: '#211F1F',
    flex: 1,
    paddingLeft: 12,
  },
  icon: {
    color: '#211F1F',
    width: 24,
    height: 24,
  },
  itemIcon: {
    minWidth: '26px !important',
  },
  list: {
    paddingLeft: 12,
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
      borderWidth: '7px',
    },
  },
}))

interface Props {
  dense: boolean
  handleToggle: () => void
  icon?: ReactElement | null
  isOpen: boolean
  name: string
  children: ReactNode
  hideExpand?: boolean
  inputStyle?: object
  rootStyle?: object
}

const SubMenu = (props: Props) => {
  const { handleToggle, isOpen, name, icon, children, dense, hideExpand, inputStyle, rootStyle } = props
  const translate = useTranslate()
  const classes = useStyles()
  const [open] = useSidebarState()

  const header = (
    //@ts-ignore
    <MenuItem dense={dense} onClick={handleToggle} className={classes.header} style={rootStyle}>
      {!hideExpand && (
        <ListItemIcon classes={{ root: classes.itemIcon }}>
          {/* {isOpen ? <ExpandMore className={classes.icon} /> : icon} */}
          {icon}
        </ListItemIcon>
      )}
      <Typography variant="inherit" color="textSecondary" style={inputStyle} className={classes.headerText}>
        {translate(name)}
      </Typography>
      {isOpen ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} />}
    </MenuItem>
  )

  return (
    <Fragment>
      {open || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List dense={dense} component="div" disablePadding className={classes.list}>
          {children}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default SubMenu
