import React, { useCallback, useMemo, useState, useContext } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { Typography, Autocomplete } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { COLOR } from '@constant/app'
import Button from '@mui/material/Button'
import { FormDataContext } from '../../context/FormDataContext'
import { ExpandMore } from '@mui/icons-material'

const NETWORK_TYPE = ['WPA', 'WPA2']
const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    title: {
      color: '#0D1F2C',
      fontSize: 12,
    },
    iconBtn: {
      padding: 0,
    },
    label: {
      color: COLOR.grey37,
      '&.Mui-disabled': {
        color: COLOR.grey91,
      },
      '&.Mui-focused': {
        color: COLOR.grey37,
      },
    },
    network: {
      '&.Mui-disabled:before': {
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
      },
      '&:before': {
        borderBottomColor: '#D5D5D5',
      },
      '&:after': {
        borderBottomColor: '#D5D5D5',
      },
    },
    customButton: {
      alignSelf: 'center',
      textTransform: 'none',
      backgroundColor: '#0D1F2C',
      fontWeight: 400,
      marginTop: 100,
      color: '#DFC9A3',
      border: 'none',
      borderRadius: 18,
      fontSize: 12,
      width: '43%',
      height: 36,
      cursor: 'pointer',
      marginBottom: 16,
      '&:hover': {
        backgroundColor: '#0D1F2C',
        opacity: 0.7,
      },
      ' &.Mui-disabled': {
        color: '#DFC9A3',
        opacity: 0.5,
      },
    },
  }
})

interface Props {
  onSubmit: (data: any) => void
}

const JoinWifiForm: React.FC<Props> = ({ onSubmit }) => {
  const classes = useStyles()
  const { wifiData } = useContext(FormDataContext)
  const [visibility, setVisibility] = useState(true)
  const [ssId, setSsid] = useState()
  const [netWorkType, setNetWorkType] = useState()
  const [password, setPassword] = useState()
  const onClickVisibility = useCallback(() => {
    setVisibility(!visibility)
  }, [visibility])
  const disabled = useMemo<boolean>(() => {
    return !ssId || !netWorkType || !password
  }, [password, ssId, netWorkType])
  const handleSubmit = useCallback(() => {
    onSubmit?.({
      SSID: ssId,
      Password: password,
      NetworkType: netWorkType,
    })
  }, [password, ssId, netWorkType])
  const onInputSSID = useCallback((event) => {
    setSsid(event.target.value)
  }, [])
  const onInputPassword = useCallback((event) => {
    setPassword(event.target.value)
  }, [])
  const onChangeNetWorkType = useCallback((event, newValue) => {
    setNetWorkType(newValue)
  }, [])

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Wifi</Typography>
      <CustomTextField
        label="SSID"
        value={wifiData.ssId}
        disabled={false}
        placeholder="Enter here"
        onChange={onInputSSID}
        variant="outlined"
      />
      <CustomTextField
        label="Password"
        disabled={false}
        placeholder="Enter here"
        type={visibility ? 'password' : ''}
        value={wifiData.password || ''}
        onChange={onInputPassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton className={classes.iconBtn} onClick={onClickVisibility}>
                {visibility ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      <Autocomplete
        disableClearable
        value={wifiData.netWorkType || null}
        options={NETWORK_TYPE}
        onChange={onChangeNetWorkType}
        popupIcon={<ExpandMore />}
        style={{ marginTop: 4 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Network Type"
            placeholder="Please select"
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.network,
              },
              type: 'search',
            }}
          />
        )}
      />
      <Button disabled={disabled} className={classes.customButton} onClick={handleSubmit}>
        Save
      </Button>
    </div>
  )
}

export default JoinWifiForm
