import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box, Grid, Typography, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useLocaleState } from 'react-admin'
import { getInspectionRoundReport } from '@api/index'
import EmptyDisplay from '@component/Empty/EmptyDisplay'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    padding: '0 0 16px',
    fontFamily: 'Roboto',
  },
  header: {
    fontFamily: 'Roboto',
    color: '#386BBF',
    fontSize: 24,
    padding: '16px 0',
    borderBottom: '1px solid #BCBCBC',
  },
  paper: {
    display: 'flex',
    backgroundColor: '#fff',
    margin: '0px 0px 20px 0',
    borderRadius: 8,
    padding: '0 0 16px 0',
  },
  gridItemCantainer: {
    paddingRight: 24,
  },
  rowItem: {
    lineHeight: '58px',
    height: 58,
    boxSizing: 'border-box',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: 18,
    color: '#222',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  gridChartContainer: {
    paddingTop: 0,
    paddingLeft: 0,
  },
  xAxis: {
    display: 'flex',
    height: 150,
    alignItems: 'flex-end',
  },
  label: {
    fontSize: 18,
    color: '#989090',
    transform: 'rotate(-30deg) translateY(12px)',
    transformOrigin: 'left bottom',
    width: 58,
    whiteSpace: 'nowrap',
    paddingLeft: 29,
  },
  rightRow: {
    display: 'flex',
    borderLeft: '1px solid #BCBCBC',
  },
  item: {
    width: 58,
    height: 58,
    boxSizing: 'border-box',
    borderRight: '1px solid #BCBCBC',
    borderBottom: '1px solid #BCBCBC',
    textAlign: 'center',
    lineHeight: '58px',
  },
  tip: {
    borderTop: '1px solid #BCBCBC',
    paddingLeft: '8px',
    color: '#989090',
  },
}))

export const Color = {
  Completed: ['#D8FFF7', 'C'],
  Overdue: ['#FFDBDB', 'O'],
  Upcoming: ['#FFF6DA', 'U'],
  NA: ['#FFF', 'NA'],
}

const InspectionReport = (props: any) => {
  const classes = useStyles()
  const [locale] = useLocaleState()
  const [data, setData] = useState<Record<string, any>[]>([])
  const { selectedGroupId } = useSelector((state) => state.profile)

  useEffect(() => {
    getInspectionRoundReport({ gId: selectedGroupId }).then((res: any) => {
      setData(res?.Result)
    })
  }, [selectedGroupId])

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <Grid container wrap="nowrap">
          <Grid item xs={3} className={classes.gridItemCantainer}>
            <Box style={{ padding: '150px 0 0 24px' }}>
              <Grid item xs={12}>
                {data.map((item) => (
                  <Typography key={item.BuildingId} className={classes.rowItem}>
                    {item.BuildingName?.[locale]}
                  </Typography>
                ))}
              </Grid>
            </Box>
          </Grid>

          {data.length === 0 && (
            <Grid item xs={12} sx={{ mt: '100px' }} alignSelf="center">
              <EmptyDisplay />
            </Grid>
          )}

          <Grid item className={classes.gridChartContainer}>
            <Box className={classes.xAxis}>
              {data[0]?.Inspections.map((item) => {
                return (
                  <Typography key={item.InspectionName} className={classes.label}>
                    {item.InspectionName}
                  </Typography>
                )
              })}
            </Box>

            {data.map(({ Inspections, BuildingId }, i) => {
              return (
                <Box className={classes.rightRow} key={BuildingId}>
                  {Inspections.map(({ InspectionName, SubmittedStatus = 'NA' }) => {
                    const [backgroundColor, text] = Color[SubmittedStatus]
                    return (
                      <Typography
                        key={InspectionName}
                        className={classes.item}
                        style={{ backgroundColor, borderTop: i === 0 ? '1px solid #BCBCBC' : '' }}
                      >
                        {text}
                      </Typography>
                    )
                  })}
                </Box>
              )
            })}
          </Grid>

          {data.length !== 0 && (
            <Grid item xs sx={{ ml: 10, maxWidth: '200px !important' }} alignSelf="flex-end">
              <Box sx={{ border: '1px solid #BCBCBC', borderRadius: '4px', minWidth: '140px' }}>
                <Typography style={{ textAlign: 'center', lineHeight: '48px' }}>Key</Typography>
                {['Completed', 'Overdue', 'Upcoming'].map((item) => {
                  const [backgroundColor, text] = Color[item]
                  return (
                    <Grid key={item} container className={classes.tip}>
                      <Grid item xs={8} sx={{ lineHeight: '36px' }}>
                        {item}
                      </Grid>
                      <Grid item xs={4} sx={{ backgroundColor, textAlign: 'center', lineHeight: '36px' }}>
                        {text}
                      </Grid>
                    </Grid>
                  )
                })}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default InspectionReport
