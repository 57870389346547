import React, { useCallback, useState } from 'react'
import { Box, Button, Modal, Typography, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import classnames from 'classnames'
import Images from '@assets/images'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import DragUploadFile from '@component/Form/DragUploadFile'

interface Props {
  onClose: () => void
  onImport: (fileUrl: string) => void
  templateUrl?: string
  title: string
  filePath?: string
}

const ImportModal: React.FC<Props> = React.memo(({ onClose, onImport, templateUrl, title, filePath }) => {
  const classes = useStyles()
  const [data, setData] = useState({ fileUrl: '' })
  const { fileUrl } = data

  const downLoadTemplate = useCallback(() => {
    window.open(templateUrl, '_blank')
  }, [templateUrl])

  const onDelete = () => setData((data) => ({ ...data, fileUrl: '' }))
  const onSuccess = (file) => setData((data) => ({ ...data, fileUrl: file[0]?.fileUrl }))

  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classnames(classes.body, classes.import)}>
        <Box className={classes.header}>
          <Box>Import {title}</Box>
          <IconButton onClick={onClose} style={{ padding: 0 }}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>
        {!!templateUrl && (
          <Box onClick={downLoadTemplate} className={`${classes.actionIcon} ${classes.download} `}>
            <img src={Images.iconDownLoad} style={{ marginRight: 6 }} />
            Click here to download {title} template file
          </Box>
        )}
        <Typography className={classes.uploadTitle}>Upload File</Typography>
        <DragUploadFile type="excel" filePath={filePath} onSuccess={onSuccess} onDelete={onDelete} />
        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={onClose} className={classes.modalBtn}>
            Cancel
          </Button>

          <Button
            onClick={() => onImport(fileUrl)}
            variant="contained"
            color="primary"
            className={classes.modalBtn}
            disabled={!fileUrl}
          >
            Import
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

ImportModal.displayName = 'ImportModal'
export default ImportModal
