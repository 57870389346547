import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, TextField, Autocomplete } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { getScheduledWorkOrders, getWorkOrderComplianceOverview, getWorkOrderEquipmentTypes } from '@api/index'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import PeriodSelect from '@component/DateSelect/PeriodSelect'
import useSetState from '@hooks/useSetState'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import { DEFAULT_PERIOD_LAST_YEAR } from '@/global'
import RoundCard from '@component/Card/RoundCard'
import Images from '@assets/images'

function msToTime(duration: number) {
  const minutes = Math.floor(duration % 60)
  const hours = Math.floor(duration / 60)
  return `${hours} : ${String(minutes).padStart(2, '0')}`
}

const TENANT_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Equipment Type',
    source: 'EquipmentTypeText',
    width: 170,
  },
  {
    label: 'Name',
    source: 'Title',
    width: 205,
    align: 'left',
  },
  {
    label: 'Stop Type',
    source: 'StopType',
    width: 113,
    align: 'left',
  },
  {
    label: 'Floor',
    source: 'Floor',
    width: 71,
    align: 'left',
  },
  {
    label: 'Space',
    source: 'Suite',
    width: 202,
    align: 'left',
  },
  {
    label: 'Status',
    source: 'Status',
    width: 100,
    align: 'left',
  },
  {
    label: 'Date Due',
    source: 'DateDue',
    isDate: true,
    formatKey: 'keyboardDate',
    width: 122,
    align: 'left',
  },
  {
    label: 'Date Completed',
    source: 'DateCompleted',
    isDate: true,
    formatKey: 'keyboardDate',
    width: 170,
    align: 'left',
  },
  {
    label: 'Date Dispatched',
    source: 'DateDispatched',
    isDate: true,
    formatKey: 'keyboardDate',
    width: 170,
    align: 'left',
  },
  {
    label: 'Priority',
    source: 'Priority',
    width: 174,
    align: 'left',
  },
  {
    label: 'Assigned to',
    source: 'AssignedTo',
    width: 174,
    align: 'left',
  },
  {
    label: 'Description',
    source: 'Instructions',
    width: 174,
    align: 'left',
  },
  {
    label: 'Actual Time \n（h:mm）',
    source: 'MinutesWorked',
    width: 121,
    align: 'left',
    convertItem: (v) => msToTime(v?.MinutesWorked || 0),
  },
]
const StatusChoices = ['Open', 'Cancelled', 'Closed'].map((v) => ({
  label: v,
  value: v,
}))
const PriorityChoices = ['High', 'Medium', 'Low'].map((v) => ({
  label: v,
  value: v,
}))

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: 1400,
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 0px 18px',
    marginTop: -98,
  },
  header: {
    display: 'flex',
    backgroundColor: '#F5F5F5',
    marginBottom: 24,
  },
  tableHeader: {
    backgroundColor: '#F0F6FC',
    whiteSpace: 'pre-wrap',
  },
  card: {
    margin: '25px 0 0',
    borderRadius: 8,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: 20,
    lineHeight: '26px',
    color: '#466CB5',
    marginTop: 24,
    borderBottom: '1px solid #BCBCBC',
    padding: '24px 0 12px',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rootClass: {
    display: 'grid',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    margin: '24px 0 0',
  },
  date: {
    fontSize: 16,
  },
}))

const PMLibraryDashboard = () => {
  const classes = useStyles()
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const { bId = selectedPropertyId, gId = selectedGroupId } = useParams<{ type: string; bId: string; gId: string }>()

  const [{ period, List, status, eType, overview, loading, priority, eTypeOption }, setState] = useSetState({
    List: [],
    status: '',
    eType: '',
    priority: '',
    period: DEFAULT_PERIOD_LAST_YEAR,
    eTypeOption: [] as string[],
    overview: {
      Total: 0,
      InTime: 0,
      OverDue: 0,
      Compliance: 0,
    },
    loading: true,
  })
  const tu = period.value
  const onTimeValue = overview?.Compliance

  useEffect(() => {
    getWorkOrderComplianceOverview({ bId, gId, tu }).then((res: any) => {
      setState({ overview: res, loading: false })
    })
  }, [bId, gId, setState, tu])

  useEffect(() => {
    getScheduledWorkOrders({
      bId,
      gId,
      tu,
      status,
      eType,
      priority,
      take: 10000,
      skip: 0,
    }).then((res: any) => {
      setState({ List: res?.WorkOrders })
    })
  }, [bId, gId, tu, status, eType, setState, priority])

  useEffect(() => {
    getWorkOrderEquipmentTypes({ bId, gId }).then((res: any) => {
      setState({ eTypeOption: res })
    })
  }, [gId, bId])

  return (
    <Box className={classes.paper}>
      <div className={classes.headerRight}>
        <Autocomplete
          value={PriorityChoices.find((v) => v.value === priority)}
          onChange={(e, newValue) => setState({ priority: newValue?.value || '' })}
          options={PriorityChoices}
          style={{ marginRight: 16, width: 170 }}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          getOptionLabel={(option) => option?.label || ''}
          renderInput={(params) => <TextField {...params} label="Priority" />}
          popupIcon={<ExpandMore />}
          classes={{ inputRoot: classes.date }}
        />
        <Autocomplete
          value={StatusChoices.find((v) => v.value === status)}
          onChange={(e, newValue) => setState({ status: newValue?.value || '' })}
          options={StatusChoices}
          style={{ marginRight: 16, width: 170 }}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          getOptionLabel={(option) => option?.label || ''}
          renderInput={(params) => <TextField {...params} label="Status" />}
          popupIcon={<ExpandMore />}
          classes={{ inputRoot: classes.date }}
        />
        <Autocomplete
          value={eType}
          onChange={(e, newValue) => setState({ eType: newValue || '' })}
          options={eTypeOption}
          style={{ marginRight: 16, width: 200 }}
          renderInput={(params) => <TextField {...params} label="Equip Type" />}
          popupIcon={<ExpandMore />}
          classes={{ inputRoot: classes.date }}
        />
        <PeriodSelect
          period={period}
          style={{ width: 200 }}
          onChange={(value) => setState({ period: value })}
          label="Date"
          className={classes.date}
        />
      </div>

      <div className={classes.row}>
        <RoundCard value={overview?.Total} label="Total PMs" loading={loading} icon={Images.pmTotal} />
        <RoundCard
          isPercent
          value={onTimeValue}
          label="On-time PMs"
          desc={`${overview?.InTime} of ${overview?.Total}`}
          loading={loading}
        />
        <RoundCard
          isPercent
          value={1 - onTimeValue}
          label="Late PMs "
          desc={`${overview?.OverDue} of ${overview?.Total}`}
          loading={loading}
        />
      </div>

      <Typography className={classes.title}>PM Work Orders</Typography>

      <Box className={classes.card}>
        <TableList
          config={TENANT_CONFIG}
          data={List || []}
          rowKey="id"
          rootClass={classes.rootClass}
          tableLayout="fixed"
          headerClass={classes.tableHeader}
        />
      </Box>
    </Box>
  )
}

export default PMLibraryDashboard
