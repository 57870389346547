import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Echarts, { EchartsProps, HeatMapProps } from '@component/Chart/Echarts'
import { Box, TextField, Grid, Typography, Theme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Rating from '@mui/lab/Rating'
import { makeStyles } from '@mui/styles'
import { useTranslate, useLocaleState } from 'react-admin'
import { COLOR } from '@constant/app'
import { useCallback, useEffect } from 'react'
import { viewAnalytics } from '@api/index'
import { IBuilding, IBuildingSummary } from '@/types'
import { DEFAULT_PERIOD_LAST_YEAR, PERIOD_OPTIONS } from '../../global'
import EmptyDisplay from '@component/Empty/EmptyDisplay'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontFamily: 'Roboto',
    height: '27px',
    color: '#386BBF',
    margin: '10px 0px 0px 30px',
    fontSize: 24,
  },
  paper: {
    display: 'flex',
    minHeight: '90%',
    backgroundColor: '#fff',
    margin: '0px 10px 20px 10px',
    boxShadow: '0px 3px 6px #00000029',
  },
  title: {
    minHeight: 52,
    fontSize: 24,
    fontFamily: 'Roboto, Regular',
    color: '#5e5d5d',
  },
  gridCantainer: {},
  gridItemCantainer: {
    paddingRight: 100,
  },
  rowItem: {
    minHeight: 50,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#cdcfd0',
    },
  },
  gridChartCantainer: {
    paddingTop: 0,
    paddingLeft: 0,
    flex: 1,
  },
  chartBox: {
    margin: 0,
    display: 'flex',
    paddingBottom: 50,
    paddingRight: 20,
  },
  period: {
    width: '200px',
    color: '#4D4D4D',
    fontSize: 16,
    borderRadius: 6,
    height: 48,
    marginBottom: 70,
  },
  notchedOutline: {
    borderColor: theme.palette.divider,
  },
  name: {
    fontSize: 18,
    fontFamily: 'Roboto',
    color: COLOR.grey50,
    whiteSpace: 'nowrap',
    paddingRight: 8,
  },
  ratingItem: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  rating: {
    color: '#F8C636',
  },
  box: {
    flexWrap: 'nowrap',
    width: 'max-content',
    minWidth: '100%',
  },
}))

interface NameItemProps {
  data?: string
}

const NameItem: React.FC<NameItemProps> = ({ data }) => {
  const classes = useStyles()
  return (
    <Grid item className={classes.name}>
      {data}
    </Grid>
  )
}

interface RatingItemProps {
  data: number
}

const RatingItem: React.FC<RatingItemProps> = ({ data }) => {
  const classes = useStyles()
  return (
    <Grid item className={classes.ratingItem}>
      <Rating name="grid-size-large" readOnly value={data} precision={0.5} />
    </Grid>
  )
}

const transformData = (
  data: IBuildingSummary[]
): {
  xAxisData: string[]
  buildingList: IBuilding[]
  seriesData: Array<[number, number, number]>
  yAxisDataLength: number
} => {
  const buildingList: IBuilding[] = []
  const seriesData: Array<[number, number, number]> = []
  const xAxisData = data[0].EntityTypes.map((entityTypes) => entityTypes.EntityType)
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    buildingList.push({
      BuildingId: item.BuildingId,
      BuildingName: item.BuildingName,
      Score: item.Score,
    })
    for (let j = 0; j < item.EntityTypes.length; j++) {
      const score = item.EntityTypes[j].Score
      const currentScore = score === 'NA' ? 0.5 : score
      seriesData.push([j, i, currentScore])
    }
  }

  return { buildingList, xAxisData, yAxisDataLength: data.length, seriesData }
}

const getRandomData = ({
  xAxisData,
  seriesData,
  yAxisDataLength,
}: {
  yAxisDataLength: number
  xAxisData: string[]
  seriesData: Array<[number, number, number]>
}): EchartsProps & HeatMapProps => {
  return {
    id: 'heatchart',
    type: 'heatmap',
    width: `${150 + xAxisData.length * 50}px`,
    height: `${150 + yAxisDataLength * 50}px`,
    // width: xAxisData.length > 10 ? '100%' : `${xAxisData.length * 50}px`,
    // height: yAxisDataLength > 10 ? '800px' : `${150 + yAxisDataLength * 50}px`,
    xAxisData: xAxisData,
    xAxisPosition: 'top',
    grid: { top: '150px', left: 0, right: 0, bottom: 0 },
    seriesData: seriesData,
  }
}

const GlobalReport = (props: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [locale] = useLocaleState()
  const { pathname } = useLocation()
  const [dataset, setDataSet] = useState<(EchartsProps & HeatMapProps) | null>()
  const [buildingList, setBuildingList] = useState<IBuilding[]>([])
  const { selectedGroupId } = useSelector((state) => state.profile)
  const translate = useTranslate()
  const [period, setPeriod] = React.useState<IOption>(DEFAULT_PERIOD_LAST_YEAR)
  const showHeader = ['/globalReport'].includes(pathname)

  const handleRowClick = (BuildingId: string, BuildingName: string) => {
    navigate(`/analyticsFeedback/${BuildingId}`, {
      state: { name: BuildingName },
    })
  }

  const fetchData = useCallback(async (period) => {
    const { BuildingSummaries } = await viewAnalytics<{ BuildingSummaries: IBuildingSummary[] }>({
      tu: period.value,
      gId: selectedGroupId,
    })
    if (BuildingSummaries.length <= 0) {
      setBuildingList([])
      setDataSet(null)
      return
    }
    const { buildingList, xAxisData, seriesData, yAxisDataLength } = transformData(BuildingSummaries || [])
    setBuildingList(buildingList)
    setDataSet(getRandomData({ xAxisData, seriesData, yAxisDataLength }))
  }, [])

  useEffect(() => {
    fetchData(period)
  }, [fetchData, period])

  return (
    <Box sx={{ width: '100%', mt: 2, height: '100%', flexDirection: 'column' }} bgcolor="#f7f7f7">
      {showHeader && (
        <Box sx={{ mt: 2 }}>
          <Typography className={classes.header} variant="h3">
            {translate('pos.analytics.global_report')}
          </Typography>
        </Box>
      )}
      <Box className={classes.paper}>
        <Grid container className={classes.box}>
          <Grid item xs="auto" className={classes.gridItemCantainer}>
            <Box style={{ paddingLeft: 27, marginTop: 30 }}>
              <Autocomplete
                value={period}
                onChange={(event: any, newValue: IOption) => {
                  setPeriod(newValue)
                }}
                disableClearable
                id="controllable-states-demo"
                options={PERIOD_OPTIONS}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        className: classes.period,
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        readOnly: true,
                      }}
                      hiddenLabel
                      variant="outlined"
                    />
                  )
                }}
              />

              <Grid item xs="auto">
                {buildingList.map((item: IBuilding) => (
                  <Grid
                    container
                    justifyContent="space-between"
                    key={item.BuildingId}
                    onClick={() => handleRowClick(item.BuildingId, item.BuildingName[locale])}
                    className={classes.rowItem}
                  >
                    <NameItem data={item.BuildingName[locale]} />
                    <RatingItem data={item.Score} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          {buildingList.length <= 0 && (
            <Grid item container alignItems="center">
              <EmptyDisplay />
            </Grid>
          )}
          <Grid item className={classes.gridChartCantainer}>
            <Box className={classes.chartBox}>
              {/* @ts-ignore */}
              <Echarts {...dataset} id="heatchart" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default GlobalReport
