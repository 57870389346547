import React from 'react'
import { RatingForm } from '@activate-inc/activate-ui'

export default function RatingFormWrapper(props) {
  const onLinkClick = (index: number) => {
    props.onLinkClick?.()
  }
  const onSubmit = (data: any) => {
    props.onSubmit?.()
  }
  return <RatingForm {...props} onSubmit={onSubmit} onLinkClick={onLinkClick} />
}
