import { uploadFileBase64 } from '@api'
import compressImg from './compressImg'
import { format } from 'date-fns'

const toBase64 = async (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export async function uploadImage(files: FileList, groupId: string, folder?: string) {
  const fileName = folder || `group/${groupId}`
  const list = await Promise.all(
    Array.from(files).map(async (file) => {
      const { name, type } = file
      let compressFile = { file: file, origin: file }
      if (type.includes('image')) {
        compressFile = await compressImg(file)
      }
      const base64 = await toBase64(compressFile.file)
      const url = await uploadFileBase64({
        fileType: type,
        base64,
        fileName: `${fileName}/${format(new Date(), 'MM-dd-yyyy-HH-mm')}_${name}`,
        bucket: `${process.env.REACT_APP_S3_BUCKET}`,
      }).then((res: any) => ({
        file: compressFile.file,
        url: res?.fileUrl as string,
      }))
      return url
    })
  )

  return list
}

const uploadFile = async (files: FileList, groupId: string, folder?: string) => {
  const result = await uploadImage(files, groupId, folder)
  return result.map((item) => item.url)
}

export default uploadFile
