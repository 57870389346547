import { Box, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import Rating from '@mui/lab/Rating'
import Autocomplete from '@mui/material/Autocomplete'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    height: (props: { showDivider?: boolean; dataSize?: number; extensible?: boolean }) => {
      return props?.extensible && (props?.dataSize || 0) > 20 ? 800 : 400
    },
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 8,
    color: theme.palette.text.secondary,
  },
  header: {
    alignItems: 'center',
    padding: '0 16px',
    borderBottom: (props: { showDivider?: boolean; dataSize?: number }) => {
      if (props.showDivider) return `1px solid ${theme.palette.divider}`
      return undefined
    },
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: 24,
    fontFamily: 'Roboto',
    color: '#000000',
    paddingRight: 16,
    padding: '18px 0',
  },
  outlined: {
    height: 48,
    width: 160,
    color: '#4D4D4D',
    fontSize: 16,
    borderRadius: 6,
    '& ::placeholder': {
      color: '#4D4D4D',
    },
  },
  notchedOutline: {
    borderColor: '#A29D9D',
  },
}))

interface OverAllCardProps {
  title: string
  showRating?: boolean
  showDivider?: boolean
  ratingValue?: number | null
  actions?: any[]
  defaultAction?: any
  onActionChange?: (item: any) => void
  boxProps?: any
  loading?: boolean
  dataSize?: number
  extensible?: boolean
  md?: number
  container?: string
}

const OverAllCard: React.FC<OverAllCardProps> = ({
  children,
  title,
  showRating,
  ratingValue,
  showDivider,
  actions,
  defaultAction,
  onActionChange,
  boxProps,
  loading,
  dataSize = 0,
  md,
  container,
}) => {
  const classes = useStyles({ showDivider, dataSize })
  const onChange = useCallback(
    (event: any, newValue: string) => {
      onActionChange?.(newValue)
    },
    [onActionChange]
  )
  return (
    <Grid item sm={12} md={md || 6}>
      <Box className={classnames(classes.paper, container)}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          {showRating && <Rating name="size-large" value={ratingValue} size="large" readOnly precision={0.5} />}
          {actions && (
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <Autocomplete
                defaultValue={defaultAction}
                onChange={onChange}
                disableClearable
                id="controllable-states-demo"
                options={actions}
                getOptionLabel={(option) => {
                  const isObj = option && typeof option === 'object'
                  return isObj ? option.label : option
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        className: classes.outlined,
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      placeholder="All Comments"
                    />
                  )
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', margin: '0 16px 16px', flex: 1, ...boxProps, overflow: 'hidden' }}>
          {loading ? <Loading /> : children}
        </Box>
      </Box>
    </Grid>
  )
}

const Loading = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
    <CircularProgress disableShrink />
  </Box>
)

export default OverAllCard
