import React, { useCallback, useState } from 'react'
import { Box, CircularProgress, IconButton, ThemeProvider } from '@mui/material'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Close } from '@mui/icons-material'
import ReactDOM from 'react-dom'
import { makeStyles } from '@mui/styles'
import { theme } from '@/themes'
import classnames from 'classnames'
import Images from '@assets/images'
import { ActivateUtils } from '@activate-inc/activate-ui'
const { isVideo } = ActivateUtils

const useStyles = makeStyles(() => {
  return {
    pager: {
      margin: 32,
      maxWidth: 'none',
    },
    subTitle: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
    },
    box: {
      backgroundColor: '#fff',
      padding: '0 24px 24px !important',
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'row',
      height: 552,
      alignContent: 'flex-start',
    },
    right: {
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'scroll',
      paddingLeft: 16,
    },
    preview: {
      height: 528,
      width: 528,
      zIndex: 1,
      position: 'relative',
      borderRadius: 8,
    },
    downLoadBtn: {
      padding: 0,
      position: 'absolute',
      zIndex: 3,
      top: 20,
      right: 20,
      border: '1px solid white',
    },
    downLoad: {
      width: 32,
      height: 32,
    },
    circular: {
      position: 'absolute',
      padding: 0,
      top: '44%',
      right: '44%',
    },
    thumbnailBtn: {
      padding: 0,
      overflow: 'hidden',
      marginBottom: 16,
      borderRadius: 8,
    },
    thumbnail: {
      height: 128,
      zIndex: 1,
      position: 'relative',
      width: 128,
      objectFit: 'cover',
      display: 'block',
    },
    border: {
      border: '3px solid #1976D2',
    },
  }
})

interface Props {
  onClose?: () => void
  urls: Array<string>
}

interface ImageProps {
  src: string
  className?: string
}
const Image: React.FC<ImageProps> = ({ src, className }) => {
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const onLoad = useCallback(() => {
    setLoading(false)
  }, [])
  return (
    <div>
      {loading && <CircularProgress className={classes.circular} />}
      <img src={src} className={className} onLoad={onLoad} alt={src} />
    </div>
  )
}
const PicturePreviewModal: React.FC<Props> = ({ onClose, urls }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [index, setIndex] = useState(0)
  const removeDialog = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])
  const onClickClose = useCallback(() => {
    removeDialog()
  }, [removeDialog])

  const onClick = (event) => {
    event.stopPropagation()
  }
  const isVideoUrl = isVideo(urls[index])
  return (
    <Dialog open={open} disableEscapeKeyDown onClick={onClick} classes={{ paper: classes.pager }}>
      <DialogTitle id="scroll-dialog-title">
        <Box className={classes.subTitle}>
          <IconButton onClick={onClickClose}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.box}>
        <Box sx={{ justifyContent: 'center', position: 'relative' }}>
          <IconButton
            className={classes.downLoadBtn}
            onClick={() => {
              window.open(urls[index], '_blank')
            }}
          >
            <img src={Images.iconDownLoadBgGray} className={classes.downLoad} />
          </IconButton>
          {isVideoUrl ? (
            <video className={classes.preview} poster="" controls>
              <source src={urls[index]} />
            </video>
          ) : (
            <Image src={urls[index]} className={classes.preview} />
          )}
        </Box>
        <Box className={classes.right}>
          {urls.map((item, idx) => {
            const isVideoUrl = isVideo(item)
            return (
              <IconButton
                key={idx}
                onClick={() => setIndex(idx)}
                className={classnames(classes.thumbnailBtn, { [classes.border]: index === idx })}
              >
                {isVideoUrl ? (
                  <video className={classes.thumbnail}>
                    <source src={item} />
                  </video>
                ) : (
                  <Image src={item} className={classes.thumbnail} />
                )}
              </IconButton>
            )
          })}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default class PicturePreview {
  static show = (props: Props) => {
    const div = document.createElement('div')
    const root = document.getElementById('root')
    root?.appendChild(div)
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <PicturePreviewModal
          {...props}
          onClose={() => {
            props?.onClose?.()
            // root?.removeChild(div)
          }}
        />
      </ThemeProvider>,
      div
    )
  }
}
