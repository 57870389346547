import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import { FloorsUnitsITF, ProgressITF } from '@/types/entity'
import { makeStyles } from '@mui/styles'

export const FLOORS_CONFIG = [
  {
    label: 'Number of Floors',
    type: 'number',
    name: 'NumberOfFloors',
    helpText: 'Includes garages, non-public spaces and amenities.',
  },
  { label: 'Excluded Floor Numbers', name: 'ExcludeFloorNums', helpText: 'For example: Floor 13 is skipped' },
  {
    label: 'Floor Number Pattern',
    name: 'FloorNumPattern',
    helpText: 'For example: P3, P2, P1, LL2, LL1, Lobby, 1-12, 14-28, etc',
  },
  {
    label: 'Unit Numbers Pattern',
    name: 'UnitNumPattern',
    helpText: 'For example: The units on floor 2 are 201 to 206',
  },
]
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  save: {
    width: 90,
    marginTop: 20,
    fontSize: 14,
    alignSelf: 'flex-end',
  },
}))
interface Props {
  data?: FloorsUnitsITF
  onChangeData?: (data: FloorsUnitsITF | undefined, progress: ProgressITF) => void
  onSave?: (data: { FloorsUnits: FloorsUnitsITF; FloorsUnitsProgress: ProgressITF }) => void
}
const FloorsUnits: React.FC<Props> = ({ data, onChangeData, onSave }) => {
  const classes = useStyles()
  const [state, setState] = useState(data || ({} as FloorsUnitsITF))
  const calculate = useCallback((tem) => {
    const denominator = FLOORS_CONFIG.length
    const numerator = FLOORS_CONFIG.filter((item) => !!tem[item.name]).length
    return { Denominator: denominator, Numerator: numerator } as ProgressITF
  }, [])
  const onInputChange = useCallback((value: string, name: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }, [])
  const onClick = useCallback(() => {
    onSave?.({ FloorsUnits: state, FloorsUnitsProgress: calculate(state) })
  }, [onSave, state])
  useEffect(() => {
    onChangeData?.(state, calculate(state))
  }, [state])
  return (
    <Box className={classes.container}>
      <Typography>{"Please list your building's floors and units."}</Typography>
      <Grid container rowSpacing={0} columnSpacing={2}>
        {FLOORS_CONFIG.map((item) => {
          const { label, name, helpText, type } = item
          return (
            <Grid item xs={6} key={name}>
              <CustomTextField
                name={name}
                fullWidth
                type={type}
                value={state[name]}
                label={label}
                helperText={helpText}
                variant="outlined"
                disabled={false}
                onInputChange={onInputChange}
              />
            </Grid>
          )
        })}
      </Grid>
      <Button variant="contained" classes={{ contained: classes.save }} onClick={onClick}>
        SAVE
      </Button>
    </Box>
  )
}

export default FloorsUnits
