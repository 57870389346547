import { useEffect, useState } from 'react'
import { setAuthToken } from '@common/auth'

const usePuppeteer = () => {
  const [success, setSuccess] = useState(false)
  useEffect(() => {
    // Puppeteer token
    setAuthToken('f2be6240-d54f-11ee-a7d2-ed1f561ca2c7')
    localStorage.setItem('isPuppeteer', 'true')
    setSuccess(true)
    return () => {
      setAuthToken('')
      localStorage.setItem('isPuppeteer', '')
    }
  }, [])
  return { success }
}

export default usePuppeteer
