import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
// import { routerMiddleware, connectRouter } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import reducers from './reducers'
import rootSagas from './sagas'
// TODO admin redux
export default ({ authProvider, dataProvider }) => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['profile'],
  }
  const rootReducer = combineReducers({
    ...reducers,
    // admin: admin,
    // router: connectRouter(history),
  })

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const resettableAppReducer = (state, action) =>
    persistedReducer(action.type !== 'USER_LOGOUT' ? state : undefined, action)

  // todo adminSaga USER_LOGOUT
  const saga = function* rootSaga() {
    yield all(
      [
        // adminSaga(dataProvider, authProvider),
        // profileSaga,
        ...rootSagas,
      ].map(fork)
    )
  }

  const sagaMiddleware = createSagaMiddleware()

  const logoRedux = require('redux-logger').createLogger()
  const store = configureStore({
    reducer: resettableAppReducer,
    // preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([
        sagaMiddleware,
        // routerMiddleware(history),
        logoRedux,
      ]),
    devTools: true,
  })

  sagaMiddleware.run(saga)
  const persistor = persistStore(store)
  return { store, persistor }
}
