import React, { useCallback, useEffect, useRef } from 'react'
import { Box, Button, IconButton, Switch, Typography } from '@mui/material'
import WithDefaultData from '@/hoc/WithDefaultData'
import { makeStyles } from '@mui/styles'
import {
  DragDropContext,
  Draggable,
  DraggableProps,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { AddCircle, DeleteOutline, Reorder } from '@mui/icons-material'
import CustomTextField from '@component/Form/CustomTextField'
import useSetState from '@hooks/useSetState'
import { reorder } from '@utils/helpers'

interface MovePanelProps {
  onChange?: (data: any) => void
  value?: any
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      padding: '0 16px 16px',
    },
    title: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto',
      color: '#4E4D4D',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #BCBCBC',
      padding: 24,
      borderRadius: 8,
    },
    sectionName: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 24,
    },
    itemText: {
      marginTop: 0,
      flex: 1,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    add: {
      alignSelf: 'flex-start',
      padding: 0,
      marginTop: 16,
    },
    moveWrapper: {
      marginTop: 24,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
  }
})
const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

const DEFAULT = {
  Required: false,
  Code: '',
  Level: 2,
  Section: 1,
  QuestionText: { en: '', es: '' },
  QuestionStyle: 'SingleChoice',
  AnswerType: 'SingleChoiceAndUploadText',
  QuestionType: 'Question',
  Choices: [
    { Label: 'A', Value: 1, SubChoice: false },
    { Label: 'B', Value: 0, AllowEnterText: true, SubChoice: false },
  ],
  AnswerChoices: [],
}
interface MoveItemProps {
  item?: any
  level2Questions: Record<string, Array<any>>
  dragHandleProps?: DraggableProps
  index: number
  onRemoveSection?: (index: number) => void
  onSectionChange?: (title: string, index: number) => void
  onRemoveItem?: (index: number) => void
  onItemChange?: (level2Questions: Record<string, Array<any>>) => void
}

const handleDate = (value) => {
  const { Level1Questions, Level2Questions } = value

  const data = Level1Questions.filter((item) => {
    const { QuestionType, AnswerType } = item || {}
    return QuestionType === 'Section' || ['SingleChoiceAndText', 'SingleChoiceAndUploadText'].includes(AnswerType)
  }).map((item) => {
    const { Code } = item
    if (Array.isArray(Level2Questions[Code]) && Level2Questions[Code].length > 0) {
      Object.assign(item, {
        Level: 1,
        Section: 1,
        AnswerType: 'Text',
        QuestionType: 'Section',
        Choices: [],
        AnswerChoices: [],
      })
    } else {
      Level2Questions[item.Code] && delete Level2Questions[item.Code]
      Object.assign(item, {
        Required: false,
        Level: 1,
        Section: 1,
        QuestionStyle: 'SingleChoice',
        AnswerType: 'SingleChoiceAndUploadText',
        QuestionType: 'Question',
        Choices: [
          {
            Label: 'A',
            Value: 1,
            SubChoice: false,
          },
          {
            Label: 'B',
            Value: 0,
            AllowEnterText: true,
            SubChoice: false,
          },
        ],
        AnswerChoices: [],
      })
    }
    return item
  })
  return {
    Level1Questions: data,
    Level2Questions,
  }
}
const MoveItem: React.FC<MoveItemProps> = ({
  item,
  level2Questions,
  dragHandleProps,
  index,
  onRemoveSection,
  onItemChange,
  onSectionChange,
}) => {
  const classes = useStyles()
  const { Code, QuestionText, QuestionType, AnswerType } = item || {}
  const list = level2Questions?.[Code] || []
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(list || [], source.index, destination.index)
    level2Questions[Code] = newItems
    onItemChange?.(level2Questions)
  }
  const onRemove = useCallback(
    (index) => {
      list.splice(index, 1)
      level2Questions[Code] = [...list]
      onItemChange?.(level2Questions)
    },
    [Code, level2Questions, list, onItemChange]
  )
  const onAdd = useCallback(() => {
    DEFAULT.Code = `Q${Date.now()}`
    list.push(Object.assign({}, DEFAULT))
    level2Questions[Code] = [...list]
    onItemChange?.(level2Questions)
  }, [Code, level2Questions, list, onItemChange])
  const onInputItem = useCallback(
    (value, item, index) => {
      item.QuestionText = { en: value, es: value }
      list.splice(index, 1, item)
      level2Questions[Code] = [...list]
      onItemChange?.(level2Questions)
    },
    [Code, level2Questions, list, onItemChange]
  )
  const onInput = useCallback(
    (value: string) => {
      onSectionChange?.(value, index)
    },
    [index, onSectionChange]
  )
  if (!(QuestionType === 'Section' || ['SingleChoiceAndText', 'SingleChoiceAndUploadText'].includes(AnswerType))) {
    return null
  }
  return (
    <div className={classes.section}>
      <Typography className={classes.title}>{`Section ${index + 1}`}</Typography>
      <div className={classes.sectionName}>
        <CustomTextField
          label="Section Name"
          className={classes.itemText}
          variant="outlined"
          disabled={false}
          onInputChange={onInput}
          value={QuestionText?.en}
        />
        <IconButton disableRipple onClick={() => onRemoveSection?.(index)}>
          <DeleteOutline />
        </IconButton>
        <div {...dragHandleProps}>
          <IconButton>
            <Reorder />
          </IconButton>
        </div>
      </div>
      <Typography sx={{ marginTop: '16px' }}>Items</Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((item: any, index: number) => (
                <Draggable key={item?.Code} draggableId={item?.Code} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={classes.item}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    >
                      <CustomTextField
                        className={classes.itemText}
                        label="Item Name"
                        variant="outlined"
                        value={item.QuestionText?.en}
                        onInputChange={(value) => onInputItem(value, item, index)}
                        disabled={false}
                      />
                      <IconButton disableRipple onClick={() => onRemove(index)}>
                        <DeleteOutline />
                      </IconButton>
                      <div {...draggableProvided.dragHandleProps}>
                        <IconButton>
                          <Reorder />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button aria-label="add" className={classes.add} onClick={onAdd}>
        <AddCircle color="primary" style={{ marginRight: 10 }} />
        Add New
      </Button>
    </div>
  )
}
const MovePanel: React.FC<MovePanelProps> = ({ value, onChange }) => {
  const [state, setState] = useSetState(value || {})
  const effectRef = useRef<boolean>()
  const { Level1Questions, Level2Questions, AlternateEnable, ExternalUrl } = state || {}
  const classes = useStyles()
  const toggleChecked = () => {
    setState({ AlternateEnable: !AlternateEnable })
  }
  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Level1Questions || [], source.index, destination.index)
    setState({ Level1Questions: newItems })
  }
  const onItemChange = useCallback(
    (data) => {
      setState({ Level2Questions: { ...data } })
    },
    [setState]
  )
  const onRemove = useCallback(
    (index) => {
      const item = Level1Questions[index]
      Level1Questions.splice(index, 1)
      delete Level2Questions[item.Code]
      setState({ Level1Questions: [...Level1Questions] })
    },
    [Level1Questions, Level2Questions, setState]
  )
  const onSectionChange = useCallback(
    (title, index) => {
      const item = Level1Questions[index]
      Level1Questions.splice(index, 1, Object.assign({}, item, { QuestionText: { en: title, es: title } }))
      setState({ Level1Questions: [...Level1Questions] })
    },
    [Level1Questions, setState]
  )
  const onAddSection = useCallback(() => {
    DEFAULT.Code = `Q${Date.now()}`
    Level1Questions.push(Object.assign({}, DEFAULT))
    setState?.({ Level1Questions: [...Level1Questions] })
  }, [Level1Questions, setState])
  useEffect(() => {
    if (effectRef.current) {
      effectRef.current = true
      return
    }
    onChange?.(Object.assign({}, state, handleDate(state)))
  }, [onChange, state])

  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable2">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {Level1Questions.map((item: any, index: number) => (
                <Draggable key={item?.Code} draggableId={item?.Code} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      className={classes.moveWrapper}
                      style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    >
                      <MoveItem
                        key={index}
                        item={item}
                        index={index}
                        onRemoveSection={onRemove}
                        onSectionChange={onSectionChange}
                        level2Questions={Level2Questions}
                        onItemChange={onItemChange}
                        //@ts-ignore
                        dragHandleProps={draggableProvided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        aria-label="addSection"
        variant="outlined"
        className={classes.add}
        style={{ padding: '6px 8px' }}
        onClick={onAddSection}
      >
        <AddCircle color="primary" style={{ marginRight: 4 }} />
        Add Section
      </Button>
      <Box>
        <Box className={classes.url}>
          <Typography className={classes.urlLabel}>Alternate URL</Typography>
          <Switch checked={AlternateEnable} name="checked" onChange={toggleChecked} />
        </Box>
        {AlternateEnable && (
          <CustomTextField
            label=""
            disabled={false}
            value={ExternalUrl}
            placeholder="Enter here"
            name="ExternalUrl"
            onInputChange={onInput}
            fullWidth
            noMargin
          />
        )}
      </Box>
    </div>
  )
}
export default WithDefaultData(MovePanel)
