import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Datagrid, List, ListProps, TopToolbar } from 'react-admin'
import { TextField, RowClickFunction, FunctionField, PublicFieldProps } from 'react-admin'
import { Box, Typography, Button } from '@mui/material'

import { Edit } from '@mui/icons-material'
import useSetState from '@hooks/useSetState'
import classnames from 'classnames'
import EditQuestion from './EditQuestion'
import useStyles from './styles'
import BackButton from '@component/BackButton/BackButton'

const COLUMN: PublicFieldProps[] = [
  { source: 'StopType', label: 'Stop Type*' },
  { source: 'DisplayText.en', label: 'Type*' },
  { source: 'Action', label: 'Action', textAlign: 'right', sortable: false },
]

interface Props {
  rowClick?: RowClickFunction
}

const Actions: React.FC = React.memo(() => {
  const classes = useStyles()
  return (
    <TopToolbar className={classnames(classes.toolbar)}>
      <Typography className={classes.title}>Equipment/Location Settings</Typography>
    </TopToolbar>
  )
})
Actions.displayName = 'Actions'

const Table: React.FC<Props> = React.memo(() => {
  const classes = useStyles()
  const [{ showImport, sType, type }, setState] = useSetState({ showImport: false, sType: '', type: '' })
  const rowClick: RowClickFunction = useCallback((id, basePath, record) => {
    const { StopType, Type } = record
    setState({ showImport: true, sType: StopType, type: Type })
    return false
  }, [])
  return (
    <>
      <Datagrid rowClick={rowClick} bulkActionButtons={false}>
        {COLUMN.map((record, index) => {
          const { source } = record
          if (source === 'Action') {
            return (
              <FunctionField
                key={index}
                {...record}
                headerClassName={classes.actionHeader}
                render={() => {
                  return (
                    <Button startIcon={<Edit />} style={{ textTransform: 'none' }}>
                      Edit Question
                    </Button>
                  )
                }}
              />
            )
          }
          return <TextField key={index} {...record} />
        })}
      </Datagrid>
      {showImport && <EditQuestion onClose={() => setState({ showImport: false })} sType={sType} type={type} />}
    </>
  )
})
Table.displayName = 'Table'

const EquipmentQuestion = (props: ListProps) => {
  const classes = useStyles()
  const { selectedGroupId: gId, selectedPropertyId: bId } = useSelector((state) => state.profile)
  const filter = useMemo(() => ({ gId, bId }), [gId, bId])
  const sort = useMemo(() => ({ field: 'EquipmentType', order: 'DESC' }), [])

  return (
    <Box className={classes.equipmentQuestionList}>
      <Box sx={{ marginBottom: '32px' }}>
        <BackButton />
      </Box>
      <List
        className={`${classes.list}`}
        {...props}
        disableSyncWithLocation
        filter={filter}
        perPage={1000}
        pagination={false}
        actions={<Actions />}
        sort={sort}
      >
        <Table />
      </List>
    </Box>
  )
}

export default EquipmentQuestion
