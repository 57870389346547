import * as React from 'react'
import { useGetList, Loading, Error } from 'react-admin'
import { makeStyles } from '@mui/styles'
import { Theme, Typography, Box, Button } from '@mui/material'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import parsePhoneNumber from 'libphonenumber-js'
//https://github.com/AlexCherepanov86/ticketCrud/blob/a62896043e8ee7f3a80a1e103334b7db9e66eed4/src/tickets/TicketList.js

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 800,
    width: '100%',
    padding: '10px 10px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: 20,
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
  },
  table: {
    border: 1,
  },
  export: {
    display: 'flex',
    marginTop: 12,
    marginBottom: 10,
    justifyContent: 'flex-end',
    marginRight: 100,
  },
}))

const BaseColumns: GridColDef[] = [
  {
    field: 'Name',
    headerName: 'Full name',
    width: 200,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.Name.en
    },
  },
  {
    field: 'Email',
    headerName: 'Email',
    width: 200,
    editable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.Email
    },
  },
  {
    field: 'PhoneNumber',
    headerName: 'Phone No.',
    type: 'number',
    width: 200,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const phoneNumber = parsePhoneNumber('+' + params.row.PhoneNumber)
      return phoneNumber?.formatNational()
    },
  },
  { field: 'Status', headerName: 'Status', width: 150 },
]

const VacancyColumns: GridColDef[] = [
  ...BaseColumns,
  {
    field: 'CreatedDate',
    headerName: 'Date',
    sortable: true,
    width: 200,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return new Date(params.row.SubmitDate).toLocaleString()
    },
  },
]

const FieldMarketingColumns: GridColDef[] = [
  ...BaseColumns,
  { field: 'Year', headerName: 'Year', width: 150 },

  {
    field: 'SubmitDate',
    headerName: 'Submit Date',
    sortable: true,
    width: 200,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return new Date(params.row.CreatedDate).toLocaleString()
    },
  },
]

const EventColumns: GridColDef[] = [
  ...BaseColumns,
  { field: 'CheckedIn', headerName: 'CheckedIn', type: 'boolean', width: 150 },
  {
    field: 'SubmitDate',
    headerName: 'Submit Date',
    sortable: true,
    width: 200,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return new Date(params.row.SubmitDate).toLocaleString()
    },
  },
]

const COLUMNS_MAP = {
  FieldMarketing: FieldMarketingColumns,
  Vacancy: VacancyColumns,
  Event: EventColumns,
  // Feedback: FeedbackColumns
}

const SubscriptionReport = () => {
  const { type = '', rId } = useParams<{ type: string; rId: string }>()
  const classes = useStyles()
  const { isLoading, error, data } = useGetList('report', {
    filter: {
      tableName: type.toLowerCase(),
      rId: rId,
    },
    sort: { field: 'Email', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
  })

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} resetErrorBoundary={() => {}} />
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{`Daily Report for ${type}`}</Typography>
      <Box className={classes.export}>
        <Button variant="outlined">Export</Button>
      </Box>
      <DataGrid rows={data as any[]} columns={COLUMNS_MAP[type]} disableRowSelectionOnClick />
    </Box>
  )
}

export default SubscriptionReport
