import React, { useCallback, useEffect } from 'react'
import { Box, Modal, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { getScanTrendData } from '@api'
import CircularProgress from '@mui/material/CircularProgress'
import useSetState from '@hooks/useSetState'
import Echarts from '@component/Chart/Echarts'
import * as echarts from 'echarts'
import { ECBasicOption } from 'echarts/types/dist/shared'
import { formartMonth } from '@utils/DateUtil'

const option: ECBasicOption = {
  grid: {
    right: 20,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  xAxis: {
    axisLabel: {
      color: '#211F1F',
      fontSize: 16,
    },
    axisLine: {
      lineStyle: {
        color: '#BCBCBC',
      },
    },
    type: 'category',
    axisTick: {
      show: false,
    },
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: 'value',
    minInterval: 1,
    splitNumber: 5,
    axisLabel: {
      color: '#211F1F',
      fontSize: 16,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        color: '#BCBCBC',
      },
    },
  },
  series: [
    {
      data: [],
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 12,
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(25, 118, 210, 0.20)',
          },
          {
            offset: 1,
            color: 'rgba(25, 118, 210, 0)',
          },
        ]),
      },
    },
  ],
}

const useStyles = makeStyles(() => ({
  modal: {
    width: 700,
    padding: 24,
    maxHeight: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 32,
  },
  modalBtn: {
    fontSize: 15,
    fontWeight: 500,
    padding: '8px 16px',
    borderRadius: 4,
    marginLeft: 16,
  },
  wrap: {
    display: 'flex',
    minHeight: 300,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

interface Props {
  onClose: () => void
  gId: string
  bId: string
  type?: string
  qr?: string
  lt?: string
}

const ScansModal: React.FC<Props> = React.memo(({ onClose, gId, bId, type, qr, lt }) => {
  const classes = useStyles()
  const [data, setData] = useSetState({ loading: true, list: [] })
  const { loading } = data
  const getTrend = useCallback(() => {
    getScanTrendData({ gId, bId, qr, lt, type }).then((res) => {
      // @ts-ignore
      option.xAxis.data = ['']
      // @ts-ignore
      option.series[0].data = ['']
      // @ts-ignore
      res.map((item) => {
        // @ts-ignore
        option.xAxis.data.push(formartMonth(item.Month))
        // @ts-ignore
        option.series[0].data.push(item.Count)
      })
      // @ts-ignore
      option.xAxis.data.push('')
      // @ts-ignore
      option.series[0].data.push('')
      setData({ loading: false })
    })
  }, [gId, bId, qr])
  useEffect(() => {
    getTrend()
  }, [getTrend])
  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.modal}>
        <Box className={classes.header}>
          <Box>Monthly Trend</Box>
          <IconButton onClick={onClose} style={{ padding: 0 }}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>
        <Box className={classes.wrap}>
          {loading ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              {/* @ts-ignore */}
              <Echarts id="scansModal" width="100%" options={option} />
            </>
          )}
        </Box>
      </Box>
    </Modal>
  )
})

export default ScansModal
