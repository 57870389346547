import { CONTACTS_ROLES } from '@constant/contacts'

export const toPercentText = (num?: number, ceil?: boolean) => {
  if (!num) return '0%'
  return ceil ? `${Math.ceil(num * 100)}%` : `${Math.round(num * 100)}%`
}
export const formatNumber = (num?: number) => {
  return num?.toLocaleString('en-US') || 0
}
export const formatSymbolNumber = (num?: number) => {
  let symbol = ''
  if (typeof num !== 'number') return ''
  if (num > 0) {
    symbol = '+'
  }
  return `${symbol}${num}`
}

export const formatDate = (date?: Date) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
  if (!date) return ''
  return new Date(date).toLocaleDateString('en-US', options)
}

export const canUpdateContact = (selfRole, contactRole, contactRoleOption = CONTACTS_ROLES) => {
  const selfRoleWeight = contactRoleOption.find((item) => item.Key === selfRole)?.Weight || 1
  const contactRoleWeight = contactRoleOption.find((item) => item.Key === contactRole)?.Weight || 0
  return selfRoleWeight >= contactRoleWeight
}

export const isEmpty = (data: string | undefined | number) => {
  if (typeof data === 'number') return false
  return !data
}

export const formatCurrency = (value: number = 0) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
}
