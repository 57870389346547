import { FieldForm, SuccessForm } from '@activate-inc/activate-ui'
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { Field } from '@activate-inc/activate-ui/dist/types/components/types'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { EntityITF } from '@/types/entity'
import { QRCodeFunctionTypeName } from '@constant/app'
import { QRCodeFunctionType } from '@/types'

interface Props {
  data: Field[]
  title: string
  qrTypeName: string
  language: LanguageType
  entity: EntityITF
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 24px',
      marginBottom: 24,
      fontFamily: 'Roboto',
    },
    title: {
      color: '#211F1F',
      fontWeight: 600,
      fontSize: 34,
      lineHeight: '36px',
    },
    address: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: '#707070',
      marginBottom: 8,
    },
  }
})

const Header: React.FC<{ address: string; title: string }> = ({ address, title }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Typography className={classes.address}>{address}</Typography>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
    </Box>
  )
}

const MaintenanceRequestWrapper: React.FC<Props> = ({ data, language, title, entity, qrTypeName }) => {
  const [submit, setSubmit] = useState(false)
  const { text, subText } = QRCodeFunctionTypeName[QRCodeFunctionType.Maintenance]

  const onSubmit = useCallback((data) => {
    setSubmit(true)
  }, [])

  if (!data) return null

  if (submit) {
    return <SuccessForm qrTypeName={qrTypeName} text={text} subText={subText} />
  }
  return (
    <FieldForm qrTypeName={qrTypeName} data={data} language={language} showHelp onSubmit={onSubmit} disabledVerify />
  )
}

export default MaintenanceRequestWrapper
