import React, { useCallback, useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import FieldType from '@/utils/FieldType'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import CustomTextField from '@component/Form/CustomTextField'

const language = 'en'

type FieldItem = typeof FieldType.DropDownSelection

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    date: {
      margin: '20px 0 0',
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
}

const CommentCard: React.FC<Props> = ({ onChange, value, disabledEdit = false }) => {
  const classes = useStyles()
  const refValue = useRef(value)
  const fixedLength = 7
  const [state, setState] = useSetState({
    Fields: (value?.Fields || []).slice(0, fixedLength) as FieldItem[],
    extraFields: (value?.Fields || []).slice(fixedLength) as FieldItem[],
  })
  const { Fields, extraFields } = state

  const onChangeField = useCallback((fields: FieldItem[]) => {
    setState({ extraFields: fields || [] })
  }, [])

  useEffect(() => {
    if (onChange) {
      const data = {}
      const newData = {
        Fields: [...(Fields || []), ...(extraFields || [])],
      }
      Object.assign(data, refValue.current, newData, { CanSave: isCanSavaQr(QRCodeFunctionType.CommentCard, newData) })
      onChange(data)
    }
  }, [onChange, Fields, extraFields])

  useEffect(() => {
    refValue.current = value
  }, [value])

  return (
    <div className={classes.container}>
      {Fields.slice(0, fixedLength).map((v, i) => {
        if (i <= 2) {
          return null
        }
        return (
          <CustomTextField
            key={i}
            label={v.FieldText[language]}
            value={''}
            disabled
            onInputChange={() => {}}
            variant="outlined"
          />
        )
      })}
      <DragFormPanel disabled={disabledEdit} language={language} data={extraFields} onChangeField={onChangeField} />
    </div>
  )
}

export default WithDefaultData(CommentCard)
