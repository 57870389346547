import { globalAdminGenerateQr } from '@api'

const QRCodeEngine = {
  generator: async (businessOptions, customOptions, templateFormData) => {
    const body = {
      ...businessOptions,
      ...customOptions,
    }
    templateFormData && Object.assign(body, { TemplateFormData: templateFormData })
    const data = await globalAdminGenerateQr(body)
    return { imageUrl: data?.ImageUrl, qrId: data.acId, pointingUrl: data.PointingUrl, ...data }
  },
  generatorCustomBackground: async (businessOptions, customOptions, templateFormData) => {
    const config = {}
    for (const key in customOptions) {
      if (customOptions[key]) {
        config[key] = customOptions[key]
      }
    }
    const body = {
      ...businessOptions,
      data: config.text,
      config,
      size: config.size,
      download: config.download,
      file: config.outFileType,
    }
    templateFormData && Object.assign(body, { TemplateFormData: templateFormData })
    const data = await globalAdminGenerateQr(body)
    return { imageUrl: data?.ImageUrl, qrId: data.acId, pointingUrl: data.PointingUrl, ...data }
  },
}

export default QRCodeEngine

//
