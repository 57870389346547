import React, { useCallback, useMemo, useState } from 'react'
import WithDefaultData from '@/hoc/WithDefaultData'
import { makeStyles } from '@mui/styles'
import { FieldForm, Template } from '@activate-inc/activate-ui'
import { QRCodeFunctionType } from '@/types'
import UploadFile from '@component/Form/UploadFile'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      padding: 0,
    },
    upload: {
      marginTop: 20,
    },
  }
})

interface Props {
  value: Template
  onChange?: (value: any) => void
  functionType: QRCodeFunctionType
  groupId: string
  buildingId: string
}
const FieldFormPanel: React.FC<Props> = ({ value, functionType, onChange, groupId, buildingId }) => {
  const classes = useStyles()
  // @ts-ignore
  const { Fields = [], TermConditionUrl } = value || {}
  const [file, setFile] = useState(TermConditionUrl)
  const onChangeFile = useCallback(
    (file: string) => {
      const newData = Object.assign({}, value, { TermConditionUrl: file, CanSave: true })
      setFile(file)
      onChange?.(newData)
    },
    [onChange, value]
  )
  const hasTeams = useMemo(() => [QRCodeFunctionType.Vacancy].includes(functionType), [functionType])

  return (
    <div className={classes.container}>
      <FieldForm
        data={Fields}
        className={classes.content}
        header={<div />}
        language={'en'}
        onlyShow
        onSubmit={() => {}}
      />
      {hasTeams && (
        <UploadFile
          className={classes.upload}
          value={file}
          filePath={`Teams/${groupId}/${buildingId}`}
          onChange={onChangeFile}
          disabledInput
          label="Terms & Conditions (optional)"
        />
      )}
    </div>
  )
}

export default WithDefaultData(FieldFormPanel)
