import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CheckCircleOutline, DeleteOutline } from '@mui/icons-material'
import EngineeringBuilder from './engineer/EngineeringBuilder'
import { EquipmentFrom } from '@constant/qrcode'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#f7f7f7',
    flex: 1,
    padding: 16,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
  },
  tab: {
    fontSize: 14,
    padding: 0,
    textTransform: 'none',
    marginRight: 16,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    flex: 1,
    height: 40,
    borderBottom: '1px solid #ccc',
  },
  button: {
    fontSize: 14,
    height: 40,
    marginLeft: 16,
  },
}))

const RoundsEngineBuilder = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { type, id = '' } = useParams<{ type?: EquipmentFrom; id?: string }>()
  const [saveCount, setSave] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const onBack = () => {
    navigate(-1)
  }

  return (
    <Box className={classes.paper}>
      <Box className={classes.title}>
        <Box className={classes.tabs} />
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          className={classes.button}
          endIcon={<DeleteOutline />}
          onClick={onBack}
        >
          Discard Changes
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={disabled}
          className={classes.button}
          endIcon={<CheckCircleOutline />}
          onClick={() => setSave(saveCount + 1)}
        >
          Save
        </Button>
      </Box>

      <EngineeringBuilder id={id} saveCount={saveCount} setDisabled={setDisabled} type={type} />
    </Box>
  )
}

export default RoundsEngineBuilder
