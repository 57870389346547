import React, { useCallback, useState } from 'react'
import { Box } from '@mui/material'
import { EntityITF } from '../../types/entity'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { MoveFormTemplate, FUNCRION_TYPE_ENUM } from '@activate-inc/activate-ui/dist/types/components/types'
import { SuccessForm } from '@activate-inc/activate-ui'
import { MoveForm, MoveFeedback } from '@activate-inc/activate-ui'
import { QRCodeFunctionTypeName } from '@/constant/app'
import { QRCodeFunctionType } from '@/types'

interface Props {
  data: MoveFormTemplate
  entity: EntityITF
  language: LanguageType
  qrTypeName: string
  type: FUNCRION_TYPE_ENUM
}

const MoveWrapper: React.FC<Props> = ({ data: template, language, entity, type, qrTypeName }) => {
  const [status, setStatus] = useState(0)
  const [submit, setSubmit] = useState(false)

  const { text, subText } = QRCodeFunctionTypeName[type]
  const onMoveFormSubmit = useCallback(
    async (data) => {
      setStatus(1)
    },
    [entity, template]
  )

  const onFeedbackSubmit = useCallback(async (data) => {
    setSubmit(true)
  }, [])
  if (submit) {
    return <SuccessForm text={text} subText={subText} qrTypeName={qrTypeName} />
  }

  const onLinkClick = () => {
    setSubmit(true)
  }
  if (Object.keys(template || {}).length <= 1) return null
  return (
    <Box sx={{ mt: 1, ml: 1, mr: 1 }}>
      {status === 0 && (
        <MoveForm data={template} onSubmit={onMoveFormSubmit} language={language} qrTypeName={qrTypeName} />
      )}
      {status === 1 && (
        <MoveFeedback
          data={template}
          language={language}
          onSubmit={onFeedbackSubmit}
          type={type}
          onLinkClick={onLinkClick}
        />
      )}
    </Box>
  )
}

export default MoveWrapper
