import { FieldForm, HubPage, Ladder, SuccessForm } from '@activate-inc/activate-ui'
import Images from '@assets/images'
import React, { useCallback, useMemo, useState } from 'react'
import { Template } from '@/types/entity'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

interface Props {
  template?: Template
  qrTypeName: string
  qrId: string
  bId: string
  hubItemId?: string
}

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    color: '#211F1F',
    fontSize: 20,
    marginTop: 36,
    fontWeight: '500',
  },
}))

export const FORM = [
  {
    Required: true,
    Code: 'FirstName',
    FieldText: {
      en: 'First Name',
      es: 'First Name',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Required: true,
    Code: 'LastName',
    FieldText: {
      en: 'Last Name',
      es: 'Last Name',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Code: 'Date',
    FieldText: {
      en: 'Date',
      es: 'Date',
    },
    ValueType: 'Text',
    FieldType: 'Date',
    FieldStyle: 'Date',
    Disabled: true,
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
]
const LadderForm: React.FC<Props> = ({ template, qrTypeName }) => {
  const classes = useStyles()

  // @ts-ignore
  const { Ladders, acId } = template || {}
  const [item, setItem] = useState<any>()
  const [status, setStatus] = useState<'success' | 'finish' | 'hub'>()

  const items = useMemo(() => {
    if (Ladders?.length !== 2) return []
    const btns = [
      {
        Type: 'Item',
        Name: Ladders?.[0].Name,
        Icon: Images.stepLadder,
        RenderAsImg: true,
        FunctionType: 'HtmlPage',
        ...(Ladders?.[0] || {}),
      },
      {
        Type: 'Item',
        Name: 'Leave Feedback',
        Icon: Images.extensionLadder,
        RenderAsImg: true,
        FunctionType: 'HtmlPage',
        ...(Ladders?.[1] || {}),
      },
    ]
    return btns
  }, [Ladders])
  const handleOpen = useCallback(
    (data) => {
      setItem(data)
    },
    [acId]
  )
  const onFinish = useCallback(() => {
    setStatus('finish')
  }, [])
  const onSubmit = useCallback((data) => {
    setStatus('success')
  }, [])
  if (item) {
    if (status === 'finish') {
      return (
        <FieldForm
          header={
            <Typography className={classes.title}>
              Please enter your name and date to confirm you‘ve completed your ladder safety.
            </Typography>
          }
          data={FORM}
          language="en"
          onSubmit={onSubmit}
          qrTypeName={item.Name}
        />
      )
    }
    return (
      <Ladder
        qrTypeName={item.Name}
        // @ts-ignore
        type={item.Type}
        onFinish={onFinish}
      />
    )
  }
  return (
    <HubPage
      s3Host=""
      qrTypeName={qrTypeName}
      // @ts-ignore
      items={items}
      handleOpen={handleOpen}
      // @ts-ignore
      buttonStyle={'Squares'}
    />
  )
}
export default LadderForm
