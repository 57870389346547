import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  paper: {
    backgroundColor: '#fff',
    padding: 16,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginTop: 16,
    color: '#211F1F',
    position: 'relative',
    flex: '1 0 0',
    overflowY: 'auto',
    textTransform: 'none',
  },
  title: {
    fontWeight: 600,
    fontSize: 23,
    marginBottom: 32,
  },
  container: {
    overflow: 'auto',
    boxSizing: 'border-box',
    border: 'none',
    width: '100%',
    padding: '0 16px 16px',
  },
  absolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 'auto',
  },
  table: {
    tableLayout: 'fixed',
  },
  tableHead: {
    background: '#EDF4FB',
    borderRadius: 6,
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  cell: {
    border: 'none',
  },
  frequencyCell: {
    display: 'flex',
    alignItems: 'center',
  },
  stickyHeader: {},
  tableRow: {
    verticalAlign: 'top',
  },
  checked: {},
  textField: {
    width: '100%',
    border: 'none',
  },
  autocomplete: {
    '&[class*=MuiOutlinedInput-root]': {
      padding: '0 9px',
      borderRadius: 4,
    },
  },
  option: {
    minWidth: 150,
  },
  paperMax: {
    wordBreak: 'break-all',
    minWidth: 300,
  },
  frequencyOption: {
    minWidth: 50,
  },
  listbox: {
    width: 'auto',
    maxHeight: 300,
    overflowY: 'auto',
  },
  select: {
    maxHeight: 300,
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  cellInputRoot: {
    borderRadius: 4,
  },
  cellInput: {
    padding: '8.5px 10px',
  },
  frequencyTimes: {
    width: 75,
  },
  frequencyText: {
    minWidth: 70,
    padding: '0 4px',
    textAlign: 'center',
  },
  add: {
    display: 'flex',
    alignItems: 'center',
    color: '#1976D2',
    textTransform: 'none',
    padding: '8px 16px',
    marginTop: 8,
  },
  firstCell: {
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontWeight: 600,
    fontSize: 20,
  },
  color: {
    color: '#000',
  },
  borderLeft: {
    borderLeft: '1px solid #1976D2',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 18,
    background: '#FBFBFB',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginBottom: 32,
    height: 60,
    boxSizing: 'border-box',
  },
  actionIcon: {
    color: '#466CB5',
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabled: {
    opacity: '0.4 !important',
  },
  multiline: {
    alignItems: 'flex-start',
    padding: 0,
  },
  date: {
    borderRadius: 4,
    height: 39,
    paddingRight: 0,
  },
  dateIcon: {
    padding: 8,
  },
  body: {
    width: '90%',
    padding: '32px 32px 18px',
    height: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '10%',
    transform: 'translateX(-50%)',
    overflowY: 'auto',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 32,
  },
  modalBtn: {
    fontSize: 15,
    fontWeight: 500,
    padding: '8px 16px',
    borderRadius: 4,
    marginLeft: 16,
    textTransform: 'none',
  },
  import: {
    width: 777,
    height: 528,
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  download: {
    background: '#F0F6FC',
    padding: 6,
    justifyContent: 'center',
    margin: '0 0 32px 0',
    borderRadius: 8,
  },
  uploadTitle: {
    color: '#211F1F',
    fontSizes: 20,
    marginBottom: 32,
    fontWeight: 600,
  },
  uploadBody: {
    flex: 1,
    border: '1px dashed #BCBCBC',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#211F1F',
    fontSizes: 20,
    fontWeight: 600,
    textAlign: 'center',
  },
  xlsx: {
    color: '#707070',
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 16,
  },
  uploadText: {
    fontSizes: 20,
    fontWeight: 600,
    margin: 16,
    cursor: 'pointer',
  },
  imgBox: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(25, 118, 210, 0.2)',
    '& img': {
      display: 'block',
    },
  },
  fileName: {
    width: '90%',
    background: '#F5F5F5',
    display: 'flex',
    padding: 16,
    alignItems: 'center',
    textAlign: 'left',
    borderRadius: 8,
  },
  indeterminate: {
    color: '#386BBF',
  },
}))
