import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Typography, Box, Autocomplete, TextField, Divider, Switch, FormControlLabel } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails, Stepper, Step, StepLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DropResult } from 'react-beautiful-dnd'
import PanelTextField from '@component/EditPanel/PanelTextField'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { ButtonStyleChoice, NumberOfButtons } from '@constant/panel'
import EditButtonItem from '@component/EditPanel/EditButtonItem'
import { EditPanelITF, HubButtonITF, PanelChoice } from '@/types/panel'
import { DynamicEditePanelComponentsMaps, isSaveTemplateId } from '../../qrcodes/DynamicForm'
import { EditPanelContext } from '@context/EditPanelContext'
import { saveHub, getIcons } from '@/apis'
import { getFunctionalityTypeDefaultTemplate } from '@api'
import { EntityITF, QRType } from '@/types/entity'
import { Template } from '@activate-inc/activate-ui'
import DraggableList from '@component/Draggable/DraggableList'
import { QRCodeFunctionType } from '@/types'
import SnackBar from '@component/SnackBar'
import { reorder } from '@/utils/helpers'
import SelectTemplatePanel from '@component/EditPanel/SelectTemplatePanel'
import Dialog from '@component/Modal/Dialog'
import { handlerHub, isCanSavaQr } from '@/utils'
import { setDefaultData } from '@utils/globalData'
import { Destination_Icon } from '@component/FormData/GoogleMapFormData'
import { QRStatusType, HubItemStage } from '@/types'
import { ExpandMore } from '@mui/icons-material'
import DragUploadFile, { DragUploadSuccessFile } from '@component/Form/DragUploadFile'

const useStyles = makeStyles(() => ({
  paper: {
    boxSizing: 'border-box',
    borderRadius: 3,
    padding: 16,
    margin: 16,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#386BBF',
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  outlined: {
    height: 34,
    border: '1px solid #1976D2',
    color: '#1976D2',
    borderRadius: 4,
    width: 89,
  },
  save: {
    height: 34,
    width: 89,
    color: 'white',
    marginLeft: 16,
    backgroundColor: '#1976D2',
    borderRadius: 4,
  },
  item: {
    width: '100%',
    marginTop: '24px',
  },
  accordion: {
    border: 'none',
    boxShadow: 'none',
    margin: '12px 0 0 !important',
    '&:before': {
      height: 0,
    },
  },
  accordionSummary: {
    padding: '0 12px',
    margin: '4px 0 0',
    border: '1px solid #BCBCBC',
    borderRadius: 4,
    height: 56,
    minHeight: 'unset !important',
  },
  accordionSummaryExpanded: {
    minHeight: 'unset !important',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F3F5F9',
  },
  stepper: {
    margin: '16px -12px 0',
  },
  incident: {
    margin: ' 24px 0',
  },
}))

interface EditPanelProps {
  groupId: string
  entity?: EntityITF
  onChange?: (template: Template) => void
  saveSuccess?: () => void
  hubInfo?: any
  buildingId: string
  qrId?: string
  qrType: QRType
  getTemplate?: boolean
  isHub?: boolean
  isEdit?: boolean
  disabledEdit?: boolean
  qrStatus?: string
}

const checkHubHaveEmptyItem = (item: HubButtonITF) => {
  const { CanSave, FunctionalityType, Icon, Name, QrType } = item
  if ([QRCodeFunctionType.Announcements].includes(FunctionalityType)) {
    return false
  }
  return !CanSave || !Icon || !Name || !QrType
}

const EditPanel: React.FC<EditPanelProps> = ({
  groupId,
  entity,
  onChange,
  hubInfo,
  buildingId,
  saveSuccess,
  qrType,
  isHub,
  qrId,
  getTemplate,
  isEdit,
  disabledEdit,
  qrStatus,
}) => {
  const classes = useStyles()
  const {
    panelData,
    initEditPanel,
    updateEditPanelButton,
    updateEditPanel,
    setCurrentEditPanelIndex,
    onRemoveItem,
    setIcons,
    editPanelIndex = -1,
  } = useContext(EditPanelContext)
  const { acId, Type } = entity || {}
  const [hideButton, setHideButton] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    updateEditPanel?.(hubInfo)
  }, [hubInfo])

  useEffect(() => {
    if (editPanelIndex > -1) {
      setActiveStep(1)
    }
  }, [editPanelIndex])

  const getHubIcons = useCallback(async () => {
    const result = await getIcons<{ Icons: Array<any> }>({ st: '', take: '', skip: '', type: 'SVG' })
    setIcons(result?.Icons || [])
  }, [])
  useEffect(() => {
    getHubIcons()
  }, [])
  const getDefaultTemplate = useCallback(async () => {
    try {
      if (!qrType || !entity?.acId) return
      const result = await getFunctionalityTypeDefaultTemplate({
        gId: groupId,
        bId: buildingId,
        fType: qrType?.FunctionalityType || '',
        qType: qrType.Name || '',
        eId: entity?.acId || '',
      })
      handlerHub(result)
      //@ts-ignore
      updateEditPanel?.(Object.assign(result, { CanSave: true }))
      setDefaultData(qrType?.FunctionalityType, result)
    } catch (e) {
      SnackBar({ msg: e })
    }
  }, [acId, qrType])

  useEffect(() => {
    if (getTemplate) {
      onCancel()
      getDefaultTemplate()
    }
  }, [entity, getDefaultTemplate])

  const onCancel = useCallback(() => {
    setCurrentEditPanelIndex(-1)
  }, [])

  const onClick = useCallback((item, index) => {
    setCurrentEditPanelIndex(index)
  }, [])
  const onRemove = useCallback((item, index) => {
    onRemoveItem?.(index)
  }, [])
  const onChangeEditButton = useCallback((data, index) => {
    updateEditPanelButton?.(data, index)
  }, [])
  const onChangeHubName = useCallback((name) => {
    updateEditPanel({ Name: name })
  }, [])
  const onChangeStyle = useCallback((value) => {
    updateEditPanel({ Style: value.value })
  }, [])
  const onSuccess = (value: DragUploadSuccessFile[]) => {
    updateEditPanel({ HeaderImage: value[0]?.fileUrl })
  }
  const onDelete = () => {
    updateEditPanel({ HeaderImage: '' })
  }
  const buttons = panelData?.Items
  const editButton = buttons?.[editPanelIndex]

  const { FunctionalityType, TemplateId } = editButton || ({} as HubButtonITF)
  const onChangeTemplate = useCallback(
    (value) => {
      if (!value) return
      const isTemplate = isSaveTemplateId(FunctionalityType)
      const data: Record<string, any> = {
        TemplateId: value.acId || value.TemplateFormData?.acId,
        CanSave: isTemplate ? true : value?.CanSave,
        TemplateFormData: value,
      }
      if (FunctionalityType === QRCodeFunctionType.GoogleMap) {
        //@ts-ignore
        data.Icon = Destination_Icon[value?.DestinationType] || ''
      }
      if (FunctionalityType === QRCodeFunctionType.RoundInspection) {
        data.Name = value?.Name
        data.TemplateId = value?.TemplateId
      }

      updateEditPanelButton?.(data, editPanelIndex)
    },
    [editPanelIndex, FunctionalityType]
  )

  // @ts-ignore
  const FormCom =
    FunctionalityType &&
    ![
      QRCodeFunctionType.Announcements,
      QRCodeFunctionType.AngusOnDemandWorkOrder,
      QRCodeFunctionType.ServiceRequests,
    ].includes(FunctionalityType)
      ? DynamicEditePanelComponentsMaps[FunctionalityType] || SelectTemplatePanel
      : null
  const getFromProps = () => {
    return { groupId, buildingId, functionType: editButton?.FunctionalityType, entityType: Type, qrType: editButton }
  }

  const handleSave = useCallback(async () => {
    if (!panelData?.Items) return
    const planDataNew = {
      ...panelData,
      Items: panelData.Items.map((item: HubButtonITF) => {
        const { TemplateId: tId, TemplateFormData, ...extra } = item
        if (tId && TemplateFormData) {
          const { selectTemplate, CanSave, isChange, ...extraTemplateFormData } = TemplateFormData
          isChange || !selectTemplate
            ? Object.assign(extra, {
                TemplateFormData: extraTemplateFormData,
                TemplateId: tId,
              })
            : Object.assign(extra, { TemplateId: selectTemplate.acId })
        } else if (tId) {
          Object.assign(extra, { TemplateId: tId })
        } else if (TemplateFormData) {
          const { selectTemplate, CanSave, isChange, ...extraTemplateFormData } = TemplateFormData
          isChange || !selectTemplate
            ? Object.assign(extra, { TemplateFormData: extraTemplateFormData })
            : Object.assign(extra, { TemplateId: selectTemplate.acId })
        }
        return extra
      }),
    }

    const saveParams = {
      GroupId: groupId,
      HubId: hubInfo?.acId,
      ...planDataNew,
      EntityId: entity?.acId,
      QrCodeId: qrId,
      Status: qrStatus === 'PendingApproval' ? 'PendingApproval' : hubInfo?.Status,
    }

    try {
      const result = await saveHub<Template>(saveParams)
      handlerHub(result)
      updateEditPanel({ HubId: result?.acId })
      Object.assign(result, { CanSave: true })
      onChange?.(result)
      saveSuccess?.()
      SnackBar({ msg: 'Successfully saved', type: 'success' })
    } catch (e) {
      SnackBar({ msg: 'Failed to save' })
    }
  }, [panelData, editButton, entity])

  const onClickSave = useCallback(async () => {
    if (editButton) {
      const isCan = isCanSavaQr(
        editButton.FunctionalityType,
        editButton.TemplateFormData,
        editButton.TemplateId || editButton.TemplateFormData?.TemplateId
      )
      isCan && setCurrentEditPanelIndex(-1)
      return
    }
    const { Name, Style, Items = [], Singleton = false, HeaderImage } = panelData || ({} as EditPanelITF)
    if (!Name || !Style) {
      return false
    }
    // const hasEmptyItems = Items.filter(checkHubHaveEmptyItem)
    // if (hasEmptyItems.length > 0) {
    //   return false
    // }
    if (Singleton) {
      Dialog.show({
        title: `The edits which you've made will be applied to all ${Name} QR's for the property.`,
        desc: 'Are you sure?',
        onAgree: () => {
          handleSave()
        },
      })
    } else {
      handleSave()
    }
  }, [panelData, editButton, qrType, handleSave])

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder<HubButtonITF>(buttons || [], source.index, destination.index)
    updateEditPanel({ Items: newItems })
  }

  const handleNext = () => {
    if (activeStep === 1) {
      onClickSave()
    } else {
      setActiveStep(1)
    }
  }

  const onBack = () => {
    if (editButton) {
      return onCancel()
    }
    setActiveStep(0)
  }

  if (!acId) {
    return null
  }

  const baseProps = getFromProps()

  const panelDataStyle = panelData?.Style
    ? ButtonStyleChoice.find((i) => i.label === panelData.Style)
      ? panelData?.Style
      : ButtonStyleChoice[0].label
    : ''

  const defaultStyle: PanelChoice | null = panelData
    ? {
        label: panelDataStyle,
        value: panelDataStyle,
      }
    : null

  const defaultButtons: PanelChoice | null =
    (panelData?.Items?.length || 0) > 0
      ? {
          label: panelData?.Items?.length + '' || '0',
          value: panelData?.Items?.length || 0,
        }
      : null

  return (
    <Accordion className={classes.accordion} defaultExpanded={isEdit}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          root: classes.accordionSummary,
          expanded: classes.accordionSummaryExpanded,
        }}
      >
        <Typography>Build a Hub</Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {['Hub Style', 'Button Details'].map((label, index) => {
            const completed = index < activeStep
            let labelText = label
            if (index === 1 && editButton) {
              labelText = 'Name, QR Type & Icon'
            }
            return (
              <Step key={label} completed={completed}>
                <StepLabel>{labelText}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <Box style={{ display: activeStep === 0 ? '' : 'none' }}>
          <PanelTextField
            disabled={disabledEdit}
            label="Hub Name"
            defaultValue={panelData?.Name}
            onChange={onChangeHubName}
          />
          <PanelSelection
            disabled={disabledEdit}
            label="Button Style"
            value={defaultStyle}
            className={classes.item}
            choices={ButtonStyleChoice}
            onChange={onChangeStyle}
            popupIcon={<ExpandMore />}
            width={'100%'}
            height={56}
          />
          <PanelSelection
            disabled={disabledEdit}
            label="No. of Buttons"
            className={classes.item}
            value={defaultButtons}
            choices={NumberOfButtons}
            onChange={(value) => {
              initEditPanel?.({ buttonNumber: value?.value || 1 })
            }}
            popupIcon={<ExpandMore />}
            width={'100%'}
            height={56}
          />
          <DragUploadFile
            onSuccess={onSuccess}
            onDelete={onDelete}
            type="picture"
            uploadSuffix="header image"
            defaultUrl={panelData?.HeaderImage}
          />
          <FormControlLabel
            className={classes.incident}
            value="start"
            disabled={disabledEdit}
            control={
              <Switch
                color="primary"
                checked={panelData?.EnableIncident}
                onChange={() => {
                  updateEditPanel({ EnableIncident: !panelData?.EnableIncident })
                }}
                size="medium"
              />
            }
            label="Incident Report"
            labelPlacement="start"
          />
        </Box>

        {editButton && (
          <EditButtonItem
            item={editButton}
            showEditBtn={true}
            qrType={editButton.QrType}
            entity={entity}
            disabled={disabledEdit}
            onRemove={onRemove}
            index={editPanelIndex}
            // onChangeTemplate={onChangeTemplate}
            onChange={onChangeEditButton}
          />
        )}

        {!editButton && activeStep === 1 && (
          <DraggableList
            items={buttons}
            onDragEnd={onDragEnd}
            onClick={onClick}
            disabled={disabledEdit}
            onChange={onChangeEditButton}
            // onChangeTemplate={onChangeIndexTemplate}
            onRemove={onRemove}
          />
        )}

        {FormCom && activeStep === 1 && (
          <>
            <Divider sx={{ borderColor: '#BCBCBC', margin: '24px 0px 0px' }} />
            <Box sx={{ margin: '0 -16px' }}>
              <FormCom
                isHub={isHub}
                isEdit={isEdit}
                entity={entity}
                key={`${editButton?.QrType}${editButton?.FunctionalityType}`}
                {...baseProps}
                provideStatus={(e: any) => setHideButton(e)}
                onChange={onChangeTemplate}
                value={editButton?.TemplateFormData}
                disabledEdit={disabledEdit}
                templateId={TemplateId}
              />
            </Box>
          </>
        )}
        {editButton ? (
          <Autocomplete
            value={HubItemStage.find((v) => v.value === editButton?.Stage) || null}
            onChange={(e, newValue) => {
              onChangeEditButton({ Stage: newValue?.value }, editPanelIndex)
            }}
            options={HubItemStage}
            style={{ marginTop: 16 }}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => <TextField {...params} label="Stage" />}
            popupIcon={<ExpandMore />}
          />
        ) : null}

        {!hideButton && (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={onBack} sx={{ mr: 1 }}>
              {activeStep === 0 ? '' : 'Back'}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} disabled={disabledEdit && activeStep === 1}>
              {activeStep === 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default EditPanel
