import React from 'react'
import { FieldForm } from '@activate-inc/activate-ui'

const CONFIG = [
  {
    Required: true,
    Code: 'Name',
    FieldText: {
      en: 'Permit Name',
      es: 'Permit Name',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Required: true,
    Code: 'Location',
    FieldText: {
      en: 'Location',
      es: 'Location',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Required: true,
    Code: 'Floor',
    FieldText: {
      en: 'Floor',
      es: 'Floor',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Code: 'ExpirationDate',
    FieldStyle: 'Date',
    FieldType: 'Date',
    FieldText: {
      en: 'Expiration Date',
      es: 'Expiration Date',
    },
    Required: true,
    ValueTexts: [],
    ValueChoices: [],
    ValueText: '',
  },
  {
    Code: 'Details',
    Required: false,
    FieldText: {
      en: 'Permit Details',
      es: 'Permit Details',
    },
    FieldType: 'Comments',
    FieldStyle: 'TextBox',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Required: false,
    ShowSkip: false,
    Code: 'Photos',
    FieldText: {
      en: 'Tap to upload',
      es: 'Tap to upload',
    },
    FieldType: 'Image',
    OnlyImage: true,
    FieldStyle: 'TakePhoto',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
]
const PermitForm = ({ qrTypeName }) => {
  return (
    <FieldForm
      isCenter={false}
      qrTypeName={qrTypeName}
      data={CONFIG}
      language="en"
      onSubmit={() => {}}
      btnWidth="100px"
      disabledVerify
    />
  )
}

export default PermitForm
