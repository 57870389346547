import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
  TablePagination,
} from '@mui/material'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import Autocomplete from '@mui/material/Autocomplete'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { generatePropertyEntitiesAndQrs, getEntityTypes } from '@api'
import SnackBar from '@component/SnackBar'
import { useGetQRTypes } from '@hooks/useGetQRTypes'
import { QRType } from '@/types/entity'
import { MaterialKey, MaterialObj, MaterialOptions, QRLimit } from '@constant/qrcode'
import Dialog from '@component/Modal/Dialog'
import classnames from 'classnames'
import BackButton from '@component/BackButton/BackButton'
import { getCachePrintTemplates } from '@utils/qrUtil'

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#f7f7f7',
      padding: '0 0 24px 16px',
      flex: 1,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
    },
    backdrop: {
      zIndex: 4,
      color: '#fff',
    },
    rows: {
      marginRight: 10,
    },
    inputRoot: {
      borderRadius: 4,
    },
    input: {
      padding: 10,
    },
    main: {
      position: 'relative',
      padding: 16,
      backgroundColor: '#fff',
      marginRight: 16,
    },
    container: {
      overflowX: 'auto',
      flex: 1,
      width: 0,
    },
    table: {
      tableLayout: 'fixed',
    },
    textField: {
      width: '100%',
      border: 'none',
    },
    headCell: {
      zIndex: 3,
    },
    cell: {
      width: 100,
      height: 30,
      padding: 8,
      '&:last-child': {
        borderRight: 'none',
      },
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: '#fff',
      },
      '&:nth-child(2)': {
        position: 'sticky',
        left: 64,
        zIndex: 2,
        backgroundColor: '#fff',
      },
    },
    stickyHeader: {
      top: 57,
      '&:first-child': {
        zIndex: 3,
      },
      '&:nth-child(2)': {
        zIndex: 3,
      },
    },
    select: {
      maxHeight: 300,
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    cellInputRoot: {
      borderRadius: 4,
    },
    cellInput: {
      padding: 10,
    },
    title: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto',
      color: '#4E4D4D',
      marginLeft: 10,
    },
    button: {
      display: 'flex',
      marginBottom: 24,
    },
    autocomplete: {
      '&[class*=MuiOutlinedInput-root]': {
        padding: '0 9px',
        borderRadius: 4,
      },
    },
    option: {},
    paper: {
      minWidth: 150,
    },
    paperMax: {
      wordBreak: 'break-all',
      minWidth: 300,
    },
    listbox: {
      width: 'auto',
      maxHeight: 300,
      overflowY: 'auto',
    },
    tooltip: {
      backgroundColor: '#3A3838',
      pointerEvents: 'auto',
      cursor: 'pointer',
      margin: '0 0 0 0',
      padding: '8px 16',
      borderRadius: 5,
    },
    action: {
      position: 'sticky',
      left: 16,
      zIndex: 2,
      width: 300,
      height: 24,
    },
    tableRow: {
      '&:hover': {
        backgroundColor: '#F5F5F6',
      },
    },
    checked: {
      backgroundColor: '#F5F5F6',
      '&:nth-child(1)': {
        backgroundColor: '#F5F5F6',
      },
      '&:nth-child(2)': {
        backgroundColor: '#F5F5F6',
      },
    },
    pagination: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 16px',
      backgroundColor: '#fff',
      borderRadius: '0 0 6px 6px',
      marginRight: 16,
    },
    addBtn: {
      padding: '0 16px',
      height: 34,
      fontSize: 13,
      fontFamily: 'Roboto',
      marginRight: 16,
    },
    paginationItem: {
      border: 'none',
    },
  }
})

const language = 'en'

interface DataItem {
  QrName: string
  Floor: string
  Type: string
  NameEn: string
  copyCount?: number
  label?: Record<string, any>
  checked: boolean
  idx?: string
  [k: string]: string | number | boolean | Record<string, any> | undefined
}

const getConfigData = (data: QRType[], option: any) => {
  const configData = [
    { name: 'QrType', title: '*QR Type', option: data, isObj: true, valueKey: 'Name', width: 200 },
    { name: 'Type', title: '*QR Location', isObj: true, valueKey: 'Name', option: option.Type || [] },
    { name: 'Unit', title: '*Unit' },
    { name: 'QrName', title: '*QR Name', width: 200 },
    { name: 'Floor', title: '*Floor' },
    { name: 'NameEn', title: 'Installation Note' },
    { name: 'Quantity', title: 'Quantity' },
    { name: 'Material', title: 'Material', option: MaterialOptions, width: 150 },
    { name: 'Size', title: 'Size', option: [] },
    { name: 'Orientation', title: 'Orientation', option: ['Landscape', 'Portrait', 'NA'] },
    { name: 'Color', title: 'Color', option: ['Light Gray', 'Light Blue', 'Dark Blue', 'Dark Gray', 'NA'] },
    {
      name: 'PrintTemplate',
      title: 'Print Template',
      option: [],
      width: 200,
      valueKey: 'Code',
      isObj: true,
      isDropDown: true,
      isMax: true,
    },
    { name: 'FileFormat', title: 'File Format', option: ['png', 'svg', 'eps', 'pdf'] },
  ]
  const requiredMsg = configData
    .filter((v) => v.title.includes('*'))
    .map((item) => item.title)
    .join(',')
  return {
    configData,
    requiredMsg,
  }
}

const sourceData: DataItem[] = [
  {
    QrName: '',
    Floor: '',
    Type: '',
    NameEn: '',
    QrType: '',
    Quantity: '',
    State: '',
    Material: '3mm Sintra',
    Size: '5 × 8',
    Orientation: 'Landscape',
    Color: 'Light Gray',
    PrintTemplate: '',
    FileFormat: 'eps',
    Unit: 'N/A',
    checked: false,
  },
]

const PropertyQrGenerator = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { fromQr = '' } = useParams<Record<string, string>>()
  const { selectedGroupId, selectedPropertyId, buildingCode = '' } = useSelector((state) => state.profile)
  const tableData = useMemo<DataItem[]>(() => [], [])
  const [, setRender] = useState(false)
  const [loading, setLoading] = useState(false)
  const [option, setOption] = useState<Record<string, any>>({})
  const { data } = useGetQRTypes(QRLimit.QrCode, selectedPropertyId)
  const { configData, requiredMsg } = useMemo(() => {
    return getConfigData(data, option)
  }, [data, option])

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const selectedIds: number[] = []
  tableData.forEach((v, i) => {
    if (v.checked) {
      selectedIds.push(i)
    }
  })
  const isAll = selectedIds.length === tableData.length
  const shouldSaveToLocal = useRef(true)

  const onAdd = () => {
    tableData.push({ ...sourceData[0] })
    setRender((v) => !v)
  }
  const onCancel = () => {
    shouldSaveToLocal.current = false
    navigate(-1)
  }

  const onSave = () => {
    let canSave = true
    const data = tableData
      .filter((v) => v.QrName)
      .map((v) => {
        const { QrName, Floor, Type, QrType } = v as DataItem
        const errorArr = [QrName, Floor, Type, QrType].filter((v) => ['', undefined].includes(v as string))
        if (errorArr.length) {
          canSave = false
          SnackBar({ msg: `${requiredMsg} are Required`, type: 'error', duration: 3000 })
        }
        return v
      })

    if (canSave && data.length) {
      setLoading(true)
      generatePropertyEntitiesAndQrs({
        GroupId: selectedGroupId,
        BuildingId: selectedPropertyId,
        QrBuilders: data,
      })
        .then(() => {
          shouldSaveToLocal.current = false
          localStorage.removeItem('PropertyQRGeneratorData')
          navigate('/library/qrcodelist')
        })
        .catch((err) => {
          SnackBar({ msg: err, type: 'error', duration: 3000 })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const selectAll = useCallback(() => {
    tableData.forEach((v) => (v.checked = !isAll))
    setRender((v) => !v)
  }, [isAll])

  const onDelete = () => {
    Dialog.show({
      title: 'Are you sure you want to delete this?',
      agreeText: 'Delete',
      onAgree: () => {
        tableData.forEach((v, i) => (v.index = i))
        selectedIds.forEach((v) => {
          const idx = tableData.findIndex((w) => w.index === v)
          tableData.splice(idx, 1)
        })
        setRender((v) => !v)
      },
    })
  }

  const onCopy = () => {
    Dialog.show({
      title: 'How many times you would like to multiply this QR Code',
      agreeText: 'MULTIPLY',
      input: true,
      onAgree: (number) => {
        let firstEmptyIdx = tableData.findIndex((v) => !v.QrType)
        firstEmptyIdx = firstEmptyIdx > -1 ? firstEmptyIdx : tableData.length
        const copyItem = tableData[selectedIds[0]]
        for (let index = 0; index < number; index++) {
          let newIdx = firstEmptyIdx + index
          let empty = true
          while (empty) {
            if (tableData[newIdx]?.QrType) {
              newIdx++
              break
            }
            empty = false
          }

          tableData[newIdx] = {
            ...copyItem,
            checked: false,
            copyCount: 1,
            idx: String(`${Date.now()}-${index}`),
          }
        }

        setRender((v) => !v)
      },
    })
  }

  useEffect(() => {
    getEntityTypes({}).then((res: any) => {
      const option = res.sort((a, b) => a.DisplayText?.[language].localeCompare(b.DisplayText?.[language]))
      setOption((v) => Object.assign({}, v, { Type: option }))
    })
  }, [selectedGroupId])

  useEffect(() => {
    for (let index = 0; index < 30; index++) {
      tableData.push({
        ...sourceData[0],
        idx: String(`${Date.now()}-${index}`),
      })
    }
    const localStorageData = JSON.parse(
      localStorage.getItem('PropertyQRGeneratorData') || JSON.stringify({ buildingCode: '' })
    )
    if (localStorageData?.buildingCode === buildingCode) {
      localStorageData?.tableData?.forEach((v: DataItem, i: number) => {
        tableData[i] = v
      })
    }
    setRender((v) => !v)

    return () => {
      shouldSaveToLocal.current &&
        localStorage.setItem(
          'PropertyQRGeneratorData',
          JSON.stringify({
            buildingCode,
            tableData: tableData.filter((v) => v.QrName),
          })
        )
    }
  }, [buildingCode])

  return (
    <Box className={`${classes.content}`}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ mb: 2 }}>
        <BackButton />
      </Box>

      <Box className={classes.main}>
        <Box className={classes.button}>
          <Typography className={classes.title}>Property QR Generator</Typography>
          {fromQr && (
            <Button variant="outlined" color="primary" onClick={onCancel} className={classes.addBtn}>
              CANCEL
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={onSave} className={classes.addBtn}>
            SAVE
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader className={classes.table}>
              <colgroup>
                <col width={64} />
                {configData.map((v, i) => (
                  <col key={i} width={v.width || 120} />
                ))}
              </colgroup>

              <TableHead>
                <TableRow>
                  {selectedIds.length ? (
                    <TableCell colSpan={14} className={classes.headCell}>
                      <Box className={classes.action}>
                        {selectedIds.length === 1 && (
                          <Tooltip title={'Clone row'} placement="bottom" classes={{ tooltip: classes.tooltip }}>
                            <IconButton onClick={onCopy} style={{ padding: 4, marginRight: 8 }}>
                              <FileCopyOutlinedIcon color="primary" style={{ fontSize: 20 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title={'Delete'} placement="bottom" classes={{ tooltip: classes.tooltip }}>
                          <IconButton onClick={onDelete} style={{ padding: 4 }}>
                            <DeleteOutlined color="primary" style={{ fontSize: 24 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell align="center" colSpan={6} className={classes.headCell}>
                        REQUIRED FIELDS
                      </TableCell>
                      <TableCell align="center" colSpan={8} style={{ zIndex: 3 }}>
                        PRINT INFORMATION
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell
                    key="check"
                    classes={{
                      root: classes.cell,
                      stickyHeader: classes.stickyHeader,
                    }}
                  >
                    <Checkbox
                      checked={isAll}
                      color="primary"
                      onChange={selectAll}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                  {configData.map((v) => (
                    <TableCell
                      key={v.title}
                      classes={{
                        root: classes.cell,
                        stickyHeader: classes.stickyHeader,
                      }}
                    >
                      {v.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, i) => {
                  return (
                    <Row
                      key={row.idx}
                      idx={i + page * rowsPerPage}
                      tableData={tableData}
                      buildingCode={buildingCode}
                      //@ts-ignore
                      configData={configData}
                      setRender={setRender}
                      types={option.Type}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box className={classes.pagination}>
        <Button variant="contained" color="primary" onClick={onAdd} className={classes.addBtn}>
          ADD NEW ROW
        </Button>
        <TablePagination
          className={classes.paginationItem}
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  )
}

interface ConfigOption {
  name: string
  title: string
  isObj?: boolean
  option?: Array<string>
  labelObj?: Array<string>
  isDropDown?: boolean
  valueKey?: string
  isMax?: boolean
}

interface Props {
  idx: number
  tableData: DataItem[]
  buildingCode: string
  configData: ConfigOption[]
  types: any[]
  setRender: (v: (v: boolean) => boolean) => void
}

const Row = React.memo((props: Props) => {
  const { idx, tableData, buildingCode, configData, setRender, types } = props
  const classes = useStyles()
  const [data, setData] = useState<DataItem>(tableData[idx])
  const [checked, setChecked] = useState(tableData[idx]?.checked || false)
  const [fileTemplate, setFileTemplate] = useState()
  const { Material } = data
  const { copyCount = 0, checked: tableDataChecked, idx: key } = tableData[idx]
  const getData = useCallback(async (qrType, location) => {
    const result = await getCachePrintTemplates(qrType, location)
    setFileTemplate(result)
  }, [])
  useEffect(() => {
    if (!data.QrType || (data.QrType === 'Feedback' && !data.Type)) return
    getData(data.QrType, data.Type)
  }, [data.QrType, data.Type])

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {}>,
      type: string,
      option: any,
      valueKey: string
    ) => {
      const { name = type, value: newValue } = event.target as any
      const rowValue = option || newValue
      // @ts-ignore
      const value = typeof rowValue === 'string' ? rowValue : rowValue[valueKey]
      // @ts-ignore
      const displayText = typeof rowValue === 'string' ? rowValue : rowValue.DisplayText?.[language] || option?.FileName
      setData((pre) => {
        if (name === 'Quantity' && !/^[0-9]*$/.test(value)) {
          return pre
        }
        pre[name] = value
        if (!pre.label) pre.label = {}
        pre.label[name] = displayText
        const { Material } = pre
        if (name === 'Material') {
          pre['Size'] = MaterialObj[Material as MaterialKey]['default']
        }
        if (name === 'QrType') {
          const location = rowValue.Location && types?.find((item) => item.Name === rowValue.Location)
          if (location) {
            pre.label.Type = location.DisplayText?.[language]
          }
          pre['Type'] = rowValue.Location
          pre['QrName'] = `${rowValue?.DefaultQrName || pre.label?.QrType || ''}`
          pre['PrintTemplate'] = ''
          pre.label.PrintTemplate = ''
        }
        if (name === 'Type' && pre.QrType === 'Feedback') {
          pre['PrintTemplate'] = ''
          pre.label.PrintTemplate = ''
        }
        if (name === 'QrType' && (pre[name] === 'ResidentHub' || pre[name] === 'TenantHub')) {
          pre['Unit'] = ''
        }
        if (name === 'Type' && pre[name] === 'Digital') {
          pre['Floor'] = 'NA'
          pre['NameEn'] = ''
          pre['Quantity'] = '1'
          pre['Material'] = 'NA'
          pre['Size'] = 'NA'
          pre['Orientation'] = 'NA'
          pre['Color'] = 'NA'
          pre['PrintTemplate'] = 'NA'
          pre['FileFormat'] = 'png'
        }
        if (name === 'QrType' && value === 'InstallationHub') {
          pre['Material'] = '80# Gloss'
          pre['Size'] = '8.5 × 11'
          pre['Orientation'] = 'Portrait'
          pre['Color'] = 'NA'
          pre['PrintTemplate'] = ''
        }

        tableData[idx] = pre
        return { ...pre }
      })
    },
    [buildingCode, types]
  )

  const onChecked = () => {
    setChecked(!checked)
    tableData[idx].checked = !checked
    setRender((v) => !v)
  }

  useEffect(() => {
    if (copyCount) {
      setData(tableData[idx])
    }
  }, [copyCount])

  useEffect(() => {
    setChecked(tableDataChecked)
  }, [tableDataChecked])

  useEffect(() => {
    setData(tableData[idx])
  }, [idx, key])

  return (
    <TableRow className={classnames(classes.tableRow, { [classes.checked]: checked })}>
      <TableCell classes={{ root: classnames(classes.cell, { [classes.checked]: checked }) }}>
        <Checkbox
          checked={checked}
          onChange={onChecked}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </TableCell>

      {configData.map((v, j) => {
        const { name, option = [], isObj, isDropDown, valueKey, isMax } = v
        let options = option
        if (name === 'Size') {
          if (Material === 'NA') {
            options = ['NA']
          } else {
            const key = Material as MaterialKey
            options = MaterialObj[key]['option']
          }
        }
        if (name === 'PrintTemplate') {
          options = fileTemplate || []
        }
        // @ts-ignore
        const value = isObj ? options.find((item) => item[valueKey] === data[name]) : data[name]

        if (options.length > 0 || isDropDown) {
          return (
            <TableCell key={j} classes={{ root: classnames(classes.cell, { [classes.checked]: checked }) }}>
              <Autocomplete
                disableClearable
                onChange={(e, value) => handleChange(e, name, value, valueKey as string)}
                className={classes.textField}
                options={options}
                value={value || ''}
                getOptionLabel={(option: any) => option?.DisplayText?.en || option?.FileName || option}
                classes={{
                  inputRoot: classes.autocomplete,
                  option: classes.option,
                  paper: classnames({ [classes.paper]: !isMax, [classes.paperMax]: isMax }),
                  listbox: classes.listbox,
                }}
                renderInput={(params) => <TextField {...params} name={name} variant="outlined" />}
              />
            </TableCell>
          )
        }
        return (
          <TableCell key={j} classes={{ root: classnames(classes.cell, { [classes.checked]: checked }) }}>
            <TextField
              name={name}
              value={value}
              variant="outlined"
              className={classes.textField}
              SelectProps={{
                displayEmpty: true,
                classes: {
                  select: classes.select,
                },
                type: 'search',
                MenuProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cellInputRoot,
                  input: classes.cellInput,
                },
              }}
              //@ts-ignore
              onChange={handleChange}
            ></TextField>
          </TableCell>
        )
      })}
    </TableRow>
  )
})
Row.displayName = 'Row'

export default React.memo(PropertyQrGenerator)
