import React, { useCallback, useRef, useState } from 'react'
import {
  EmailOutlined,
  FileCopyOutlined as FileCopyOutlinedIcon,
  GetAppRounded as GetAppRoundedIcon,
  PrintOutlined as PrintOutlinedIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import { copyText, downLoadFile } from '@/utils'
import EmailHover, { EmailHandler } from '@component/QRCode/EmailHover'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 18,
    margin: '16px auto 0',
    '& button': {
      borderRadius: 4,
      marginRight: 8,
      width: 80,
      height: 34,
      borderColor: '#D5D5D5',
    },
  },
}))

interface QRCodeButtonsProps {
  url?: string
  type?: string
  qrId?: string
  onSaveQRRefresh?: () => void
  disabledEdit?: boolean
}

const QRCodeButtons: React.FC<QRCodeButtonsProps> = ({ url = '', type, onSaveQRRefresh, qrId, disabledEdit }) => {
  const classes = useStyles()
  const childRef = useRef<EmailHandler>()
  const onSaveQRCode = useCallback(() => {
    const downloadUrl = type ? url?.substring(0, url?.lastIndexOf('.')) + `.${type}` : url
    downLoadFile({ url: downloadUrl })
  }, [url, type])
  const onCopy = useCallback(() => {
    copyText(url)
  }, [url])
  const onEmail = useCallback(
    (ev) => {
      if (!qrId) return
      childRef.current?.setAnchor(ev.currentTarget)
    },
    [qrId]
  )
  return (
    <div className={classes.buttonGroup}>
      <EmailHover childRef={childRef} qrId={qrId} />
      <Button variant="outlined" onClick={onEmail}>
        <EmailOutlined />
      </Button>
      <Button variant="outlined" onClick={onCopy}>
        <FileCopyOutlinedIcon />
      </Button>
      <Button variant="outlined">
        <PrintOutlinedIcon />
      </Button>
      <Button variant="outlined" onClick={onSaveQRCode}>
        <GetAppRoundedIcon />
      </Button>
      {onSaveQRRefresh && (
        <Button
          disabled={disabledEdit}
          variant="outlined"
          style={{ border: 0, margin: 0, width: 'unset', padding: 0, minWidth: 'unset' }}
          onClick={onSaveQRRefresh}
        >
          <RefreshIcon />
        </Button>
      )}
    </div>
  )
}

export default QRCodeButtons
