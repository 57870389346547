import React from 'react'
import { FireAndAED } from '@activate-inc/activate-ui'
import { Field } from '@activate-inc/activate-ui/dist/types/components/types'
import { QRCodeFunctionType } from '@/types'

const FireAndAEDWrapper = (props: any) => {
  const { data, qrTypeName, functionalityType } = props || {}

  if (data) {
    const { SerialNo = '', ExpirationDate = '', Company = '', ExtinguisherType = '', Fields = [] } = data
    const arr = [
      { label: 'Serial Number', value: SerialNo },
      {
        label: 'Expiration Date',
        value: new Date(ExpirationDate).toLocaleDateString('en-US', {
          month: 'long',
          year: 'numeric',
        }),
      },
    ]
    if (functionalityType === QRCodeFunctionType.FireExtinguisher) {
      arr.push(
        {
          label: 'Company',
          value: Company,
        },
        {
          label: 'Extinguisher Type',
          value: ExtinguisherType,
        }
      )
    }

    return <FireAndAED qrTypeName={qrTypeName} fields={Fields as Field[]} data={arr} onSubmit={(data: any) => {}} />
  }
  return null
}

export default FireAndAEDWrapper
