import React, { useCallback, useEffect, useState } from 'react'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { DateValidationError, MobileDatePicker } from '@mui/x-date-pickers'
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types'
import { InputAdornment } from '@mui/material'
import { Event } from '@mui/icons-material'

interface Props {
  onChange?: (staticForm: any) => void
  value?: any
  disabledEdit?: boolean
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    },
    title: {
      color: '#0D1F2C',
      fontSize: 12,
    },
    date: {
      margin: '20px 0 0',
    },
  }
})

const SocialFormPanel: React.FC<Props> = ({ onChange, value, disabledEdit = false }) => {
  const [state, setState] = useState(value || {})
  const classes = useStyles()
  const handle = useCallback(
    (name, value) => {
      setState((prevState: any) => ({
        ...prevState,
        [name]: value,
      }))
      const newData = Object.assign({}, state, { [name]: value, isChange: true })
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.EquipmentInspection, newData) })
      setState(newData)
      onChange?.(newData)
    },
    [state]
  )
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      handle(name, value)
    },
    [handle]
  )
  const handleDateChange = (date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    let dateNum: number | undefined = undefined
    // @ts-ignore
    if (!date || date === 'Invalid Date') {
      dateNum = undefined
    } else {
      dateNum = new Date(date as unknown as string).getTime()
    }
    handle('ExpirationDate', dateNum)
  }
  useEffect(() => {
    setState(value)
    if (!value?.ExpirationDate) {
      handle('ExpirationDate', new Date().getTime())
    }
  }, [value, handle])

  return (
    <div className={classes.container}>
      <CustomTextField
        label="Serial Number"
        value={state?.SerialNo}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="SerialNo"
        variant="outlined"
      />
      <MobileDatePicker
        className={classes.date}
        label="Expiration Date"
        format="MM/dd/yyyy"
        value={state?.ExpirationDate || null}
        onChange={handleDateChange}
        disabled={disabledEdit}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Event />
                </InputAdornment>
              ),
            },
          },
        }}
      />
      <CustomTextField
        label="Manufacturer"
        value={state?.Manufacturer}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="Manufacturer"
        variant="outlined"
      />
      <CustomTextField
        label="Extinguisher Type"
        value={state?.ExtinguisherType}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="ExtinguisherType"
        variant="outlined"
      />
    </div>
  )
}

export default SocialFormPanel
