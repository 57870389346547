import React, { useCallback, useEffect, useRef } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import DateFnsUtils from '@date-io/date-fns'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import FieldType from '@/utils/FieldType'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import { InputAdornment } from '@mui/material'
import { Event, AccessTime } from '@mui/icons-material'

const language = 'en'

type FieldItem = typeof FieldType.DropDownSelection

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px 16px',
    },
    date: {
      margin: '20px 0 0',
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
}

const dateFns = new DateFnsUtils()

const ResidentCheckOutPanel: React.FC<Props> = ({ onChange, value, functionType }) => {
  const classes = useStyles()
  const refValue = useRef(value)
  const [state, setState] = useSetState({
    Fields: (value?.Fields || []) as FieldItem[],
  })
  const { Fields } = state
  const onChangeField = useCallback(
    (fields: FieldItem[]) => {
      const data = Object.assign({}, refValue.current, { Fields: fields || [] })
      Object.assign(data, { CanSave: isCanSavaQr(functionType, data) })
      onChange?.(data)
      setState({ Fields: fields || [] })
    },
    [functionType, onChange, setState]
  )

  return (
    <div className={classes.container}>
      <DragFormPanel language={language} data={Fields} onChangeField={onChangeField} onlyMoveArr={['Q001', 'Q003']} />
    </div>
  )
}

export default WithDefaultData(ResidentCheckOutPanel)
