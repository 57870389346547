import TextField from '@mui/material/TextField'
import React, { useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { COLOR } from '@constant/app'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MaskedInput from 'react-text-mask'
import FormControl from '@mui/material/FormControl'
import classnames from 'classnames'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => {
  return {
    textField: {
      borderRadius: 4,
      marginTop: (props: { noMargin?: boolean }) => {
        return props?.noMargin ? 0 : 20
      },
    },
    label: {
      '&.Mui-disabled': {
        color: COLOR.grey91,
      },
    },
    underline: {
      '&.Mui-disabled:before': {
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
      },
      '&:before': {
        borderBottomColor: '#D5D5D5',
      },
      '&:after': {
        borderBottomColor: '#D5D5D5',
      },
    },
    disabled: {
      borderBottomColor: theme.palette.divider,
    },
    phoneLabelDisable: {
      color: COLOR.grey91,
    },
    shrink: {
      transform: 'translate(0, 1.5px) scale(0.75)',
    },
    multiline: {
      minHeight: 110,
      alignItems: 'flex-start',
    },
    notchedOutline: {
      borderRadius: 4,
    },
  }
})

export interface Props {
  id?: string
  label?: string
  type?: string
  disabled?: boolean
  value?: string
  onInputChange?: (value: string, name: string) => void
  onChange?: (value: any) => void
  noMargin?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  labelDisabled?: boolean
}

export type CustomTextFieldProps = Props & TextFieldProps

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  type = 'text',
  disabled = true,
  value = '',
  onInputChange,
  className,
  children,
  onChange,
  noMargin,
  variant = 'standard',
  labelDisabled = false,
  ...textFieldProps
}) => {
  const classes = useStyles({ noMargin })
  const onChangeData = useCallback(
    (event) => {
      const { value, name } = event.target
      let newValue: string = value
      if (type === 'phone') {
        newValue = newValue.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
        newValue = newValue?.length === 10 ? `1${newValue}` : newValue
      }
      onInputChange ? onInputChange(newValue, name) : onChange?.(event)
    },
    [onInputChange, onChange, type]
  )

  const TextMaskCustom = useCallback(
    (props) => {
      const { ...other } = props
      return (
        <MaskedInput
          {...other}
          name="textPhone"
          guide={false}
          showMask
          mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          placeholderChar={'\u2000'}
        />
      )
    },
    [type]
  )

  if (type === 'phone') {
    const phoneValue = value?.length === 11 ? value.slice(1) : value
    return (
      <FormControl className={classnames(classes.textField, className)}>
        <InputLabel
          htmlFor="formatted-text-mask-input"
          className={`${classes.label} ${disabled ? classes.phoneLabelDisable : classes.shrink} `}
        >
          {label}
        </InputLabel>
        <Input
          disabled={disabled}
          className={`${classes.disabled} ${classes.underline}`}
          value={phoneValue}
          onChange={onChangeData}
          inputComponent={TextMaskCustom as any}
          id="formatted-text-mask-input"
        />
      </FormControl>
    )
  }
  return (
    <TextField
      {...textFieldProps}
      className={classnames(classes.textField, className)}
      label={label}
      type={type}
      disabled={disabled}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
        disabled: labelDisabled,
        shrink: variant === 'standard' ? true : undefined,
      }}
      InputProps={{
        ...textFieldProps.InputProps,
        classes: {
          disabled: classes.disabled,
          underline: variant === 'standard' ? classes.underline : '',
          multiline: classes.multiline,
          notchedOutline: classes.notchedOutline,
        },
      }}
      onChange={onChangeData}
      value={value || ''}
      variant={variant}
    >
      {children}
    </TextField>
  )
}

export default CustomTextField
