export const CONTACTS_ROLES = [
  {
    Weight: 1,
    DisplayText: 'Offboarded',
    Key: 'Offboarded',
  },
  {
    Weight: 1,
    DisplayText: 'Visitor',
    Key: 'Visitor',
  },
  {
    Weight: 1,
    DisplayText: 'Resident',
    Key: 'Resident',
  },
  {
    Weight: 1,
    DisplayText: 'Tenant',
    Key: 'Tenant',
  },
  {
    Weight: 1,
    DisplayText: 'Contractor',
    Key: 'Contractor',
  },
  {
    Weight: 1,
    DisplayText: 'Supplier',
    Key: 'Supplier',
  },
  {
    Weight: 1,
    DisplayText: '3rd party vendor',
    Key: 'Vendor',
  },
  {
    Weight: 1,
    DisplayText: 'COI Manager',
    Key: 'COIManager',
  },
  {
    Weight: 1,
    DisplayText: 'Prospect',
    Key: 'Prospect',
  },
  {
    Weight: 10,
    DisplayText: 'Employee',
    Key: 'Employee',
  },
  {
    Weight: 20,
    DisplayText: 'Chief Engineer',
    Key: 'ChiefEngineer',
  },
  {
    Weight: 20,
    DisplayText: 'GM',
    Key: 'GM',
  },
  {
    Weight: 20,
    DisplayText: 'PM',
    Key: 'PM',
  },
  {
    Weight: 30,
    DisplayText: 'Manager',
    Key: 'Manager',
  },
  {
    Weight: 30,
    DisplayText: 'Marketing',
    Key: 'Marketing',
  },
  {
    Weight: 50,
    DisplayText: 'Executive',
    Key: 'Executive',
  },
  {
    Weight: 90,
    DisplayText: 'Admin',
    Key: 'Admin',
  },
  {
    Weight: 100,
    DisplayText: 'AcAdmin',
    Key: 'AcAdmin',
  },
]

export const DEFAULT_PERMISSIONS = {
  Employee: {
    Contacts: false,
    Marketing: false,
    Reporting: false,
  },
  'Chief Engineer': {
    Contacts: true,
    Marketing: false,
    Reporting: true,
  },
  Marketing: {
    Contacts: true,
    Marketing: true,
    Reporting: false,
  },
  Executive: {
    Contacts: true,
    Marketing: true,
    Reporting: true,
  },
  GM: {
    Contacts: true,
    Marketing: true,
    Reporting: true,
  },
  PM: {
    Contacts: true,
    Marketing: true,
    Reporting: true,
  },
}
