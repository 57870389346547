import React, { useCallback } from 'react'
import SelectTemplate from '@component/Entity/SelectTemplate'
import { makeStyles } from '@mui/styles'
import { QRType, Template } from '@/types/entity'
import { QRCodeFunctionType } from '@/types'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface SelectTemplatePanelProps {
  groupId: string
  buildingId: string
  functionType: QRCodeFunctionType
  qrType: QRType
  onChange?: (template: any) => void
  value?: Template
  templateId?: string
  disabledEdit?: boolean
}

const SelectTemplatePanel: React.FC<SelectTemplatePanelProps> = ({
  groupId,
  buildingId,
  qrType,
  onChange,
  value,
  templateId,
  functionType,
  disabledEdit,
}) => {
  const classes = useStyles()
  const onChangeTemplate = useCallback((template) => {
    if (typeof template === 'string') return
    onChange?.(Object.assign(template || {}, { TemplateId: template?.acId, CanSave: true }))
  }, [])
  return (
    <div className={classes.container}>
      <SelectTemplate
        defaultValue={value}
        qrType={qrType}
        groupId={groupId}
        disabled={disabledEdit}
        functionType={functionType}
        buildingId={buildingId}
        onChange={onChangeTemplate}
        templateId={templateId}
      />
    </div>
  )
}

export default SelectTemplatePanel
