import React, { useEffect, useState, useCallback, useImperativeHandle, useMemo } from 'react'
import { Box, TextField, Typography, Button } from '@mui/material'
import { Autocomplete } from '@mui/material'
import MobilePhone from './MobilePhone'
import { makeStyles } from '@mui/styles'
import { getBuildingTypes, GetStates, addPropertyTag, getPropertyTags } from '@api'
import { AccessTimeFilled } from '@mui/icons-material'
import { generateTimeOptions } from '@utils/DateUtil'
import { isValidPostalCode } from '@utils/helpers'
import Dialog from '@component/Modal/Dialog'
import EditAndSave from '@component/Button/EditAndSave'
const timeOptions = generateTimeOptions()

const i18nName = ['AccountName', 'Name', 'AddressLine1', 'AddressLine2', 'City', 'State', 'Country']
const language = 'en'
const NewTag = 'New Property Tag'
interface Information {
  name: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  value?: string | Record<string, string>[]
  type?: 'Select' | 'TextField' | 'Phone' | 'Time'
  option?: Record<string, string>[]
  optionData?: Record<'USA' | 'Canada', string[]>
  [key: string]: any
}

const sourceData: Information[] = [
  {
    name: 'AccountName',
    label: 'Parent Account',
    disabled: true,
    value: '',
  },
  {
    name: 'Name',
    label: 'Building Name',
    required: true,
  },
  {
    name: 'PropertyTag',
    label: 'Property Tag',
    type: 'Select',
    option: [{ name: 'PropertyTag', label: NewTag, value: NewTag }],
    PropertyTagId: '',
  },
  {
    name: 'PropertyType',
    label: 'Building Type',
    required: true,
    value: 'Single',
    type: 'Select',
    option: [
      { name: 'PropertyType', label: 'Single' },
      { name: 'PropertyType', label: 'Multiple' },
    ],
  },
  {
    name: 'BuildingType',
    label: 'Building Vertical',
    required: true,
    type: 'Select',
    value: 'Building',
    option: [],
  },
  {
    name: 'TotalSquareFeet',
    label: 'Total Square ft.',
    required: true,
  },
  { name: 'AddressLine1', label: 'Address Line 1', placeholder: 'AddressLine1', required: true },
  { name: 'AddressLine2', label: 'Address Line 2', placeholder: 'AddressLine2' },
  { name: 'City', label: 'City', placeholder: 'City', required: true },
  {
    name: 'State',
    label: 'State',
    placeholder: 'State',
    required: true,
    type: 'Select',
    option: [],
    optionData: { USA: [], Canada: [] },
  },
  { name: 'Zip', label: 'Zip Code', placeholder: 'Zip Code', required: true },
  {
    name: 'Country',
    value: 'USA',
    label: 'Country',
    placeholder: 'Country',
    required: true,
    option: [
      { name: 'Country', label: 'USA' },
      { name: 'Country', label: 'Canada' },
    ],
    type: 'Select',
  },
  { name: 'ContactPhoneNumber', label: 'Building Phone Number', required: false, type: 'Phone' },
  { name: 'ContactEmail', label: 'Building Email', required: true },
  { name: 'Website', label: 'Building Website', required: false },
  { name: 'Region', label: 'Region', required: false },
  { name: 'Market', label: 'Market', required: false },
  // { name: 'PinCodes', label: 'QR General Pin', required: true },
  {
    name: 'Shifts',
    label: 'Shift-1',
    value: [
      { StartTime: '', EndTime: '' },
      { StartTime: '', EndTime: '' },
      { StartTime: '', EndTime: '' },
    ],
    type: 'Time',
    option: timeOptions,
  },
]
export const PROPERTY_I18NAME = ['Name', 'AddressLine1', 'AddressLine2', 'City', 'State', 'Country']

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
    },
    information: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    textField: {
      minWidth: 230,
      flex: '20%',
      margin: '0 12px 24px',
    },
    empty: {
      height: 0,
      margin: '0 12px',
    },
    shrink: {
      backgroundColor: '#fff',
      padding: '0 5px',
    },
    asterisk: {
      color: '#ff0000',
    },
    select: {
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    timePicker: {
      display: 'flex',
    },
    timePickerItem: {
      flex: 1,
      '&:first-child': {
        marginRight: 10,
      },
    },
    timePickerRoot: {
      paddingRight: '24px !important',
    },
    dateInput: {
      padding: '0px 5px 0 4px !important',
    },
    popupIndicator: {
      transform: 'rotate(0deg)',
    },
    timePickerLabel: {
      transform: 'translate(14px, 8px) scale(1)',
    },
    timePickerLabelShrink: {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  }
})

interface Props {
  validate: boolean
  informationRef: React.MutableRefObject<{ informationData: Information[] }>
  isEdit: boolean
  editData: any
  disabled?: boolean
  hiddenCancel?: boolean
  showSave?: boolean
  onSave: () => void
  onChangeData?: () => void
  isPropertyAdd?: boolean
  hiddenShifts?: boolean
  gId: string
}
const PropertyInformation = (props: Props) => {
  const { validate, informationRef, isEdit, editData, onSave, disabled, onChangeData, hiddenCancel, gId, showSave } =
    props
  const { isPropertyAdd, hiddenShifts } = props
  const classes = useStyles()
  const [render, setRender] = useState(false)
  const [onlyView, setOnlyView] = useState(isEdit && !showSave)

  const informationData = useMemo<Information[]>(() => JSON.parse(JSON.stringify(sourceData)), [])
  const Country = informationData.find((v) => v.name === 'Country')?.value as string
  const onCancel = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    const idx = informationData.findIndex((v) => v.name === name)
    if (['TotalSquareFeet'].includes(name) && !/^[0-9]*$/.test(value)) {
      return
    }
    if (['PinCodes'].includes(name) && !/^[0-9]{0,4}$/.test(value)) {
      return
    }

    informationData[idx]['value'] = value
    setRender((v) => !v)
  }, [])

  const onChangePhone = useCallback((phoneStr: string, index: number) => {
    informationData[index]['value'] = phoneStr
    setRender((v) => !v)
  }, [])

  const onChangeSelect = (event: React.ChangeEvent<{}>, value: any, itemName?: string, index?: number[]) => {
    const { name: optionName, label } = value
    const name = optionName || itemName

    if (!value) return
    const idx = informationData.findIndex((v) => v.name === name)

    if (name === 'PropertyTag' && label === NewTag) {
      return Dialog.show({
        title: 'Add New Tag',
        input: true,
        inputType: 'text',
        inputLabel: 'Property Tag',
        onAgree: (value) => {
          addPropertyTag({ GroupId: gId, Name: value }).then((res: any) => {
            const option = informationData[idx]['option'] as Record<string, string>[]
            informationData[idx]['PropertyTagId'] = res?.acId
            informationData[idx]['value'] = value
            option.push({ name: 'PropertyTag', label: value, value: res?.acId })
            setRender((v) => !v)
          })
        },
      })
    }

    if (name.includes('Shift')) {
      // @ts-ignore
      const key = index[1] === 0 ? 'StartTime' : 'EndTime'
      // @ts-ignore
      informationData[idx]['value'][index[0]][key] = label
    } else {
      informationData[idx]['value'] = label
    }
    if (name === 'Country') {
      const stateIdx = informationData.findIndex((v) => v.name === 'State')
      informationData[stateIdx]['value'] = ''
    }
    if (name === 'PropertyType' && label === 'Single') {
      const item = informationData.find((v) => v.name === 'PropertyTag') as Information
      item['value'] = ''
      item['PropertyTagId'] = ''
    }
    if (name === 'PropertyTag') {
      informationData[idx]['PropertyTagId'] = value.value
    }
    setRender((v) => !v)
  }

  useEffect(() => {
    onChangeData?.()
  }, [onChangeData, render])

  useImperativeHandle(
    informationRef,
    () => {
      return {
        ...informationRef.current,
        informationData,
      }
    },
    []
  )

  useEffect(() => {
    informationData[4]['value'] = ''
    setRender((v) => !v)
  }, [])

  useEffect(() => {
    if (isPropertyAdd && !isEdit && editData) {
      informationData[0]['value'] = editData.AccountName?.[language]
      setRender((v) => !v)
    }
  }, [isPropertyAdd, isEdit, editData])

  useEffect(() => {
    if (isEdit && editData) {
      informationData.forEach((v, i) => {
        let value = editData[v.name]
        if (i18nName.includes(v.name)) {
          value = value?.[language]
        }
        if (['PinCodes'].includes(v.name)) {
          value = value?.[0]?.['Code']
        }
        if (v.type === 'Time') {
          value =
            value?.length > 0
              ? value
              : [
                  { StartTime: '', EndTime: '' },
                  { StartTime: '', EndTime: '' },
                  { StartTime: '', EndTime: '' },
                ]
        }
        v.value = value
        if (v.name === 'Type') {
          v.disabled = true
          v.value = 'Building'
        }
        if (v.type === 'Select') {
          if (v.name === 'Country' && !['USA', 'Canada'].includes(value)) {
            v.value = 'USA'
          }
        }
        if (v.name === 'PropertyType') {
          v.value = value || 'Single'
        }
        if (v.name === 'PropertyTag') {
          v.value = value?.Name || ''
          v.PropertyTagId = value?.PropertyTagId || ''
        }
      })
      setRender((v) => !v)
    }
  }, [isEdit, editData, informationData])

  useEffect(() => {
    getBuildingTypes({}).then((res: any) => {
      const item = informationData.find((v) => v.name === 'BuildingType') as Information
      item['option'] = res.map((v) => ({
        name: 'BuildingType',
        label: v?.Name,
        displayLabel: v?.DisplayText?.[language],
      }))
      setRender((v) => !v)
    })

    GetStates({}).then((res: any) => {
      const item = informationData.find((v) => v.name === 'State') as Information
      item['optionData'] = {
        USA: res.USA.map((v) => ({ name: 'State', label: v })),
        Canada: res.Canada.map((v) => ({ name: 'State', label: v })),
      }
      const Country = informationData.find((v) => v.name === 'Country')?.value as string
      item['option'] = item['optionData'][Country]
      setRender((v) => !v)
    })

    getPropertyTags({ gId }).then((res: any) => {
      const item = informationData.find((v) => v.name === 'PropertyTag') as Information
      item['option'] = item['option']?.concat(
        res.map((v) => ({
          name: 'PropertyTag',
          label: v?.Name,
          value: v?.acId,
        }))
      )
      setRender((v) => !v)
    })
  }, [gId])

  useEffect(() => {
    const item = informationData.find((v) => v.name === 'State') as Information
    //@ts-ignore
    item['option'] = item['optionData'][Country] || item['optionData']['USA']
    setRender((v) => !v)
  }, [Country])
  console.log('informationData', informationData)
  return (
    <Box className={`${classes.content} ${classes.information}`}>
      {informationData.map((v, i) => {
        const { name, required, label, value = '', type = 'TextField', option = [] } = v
        let error = required && validate && !value
        let helperText = error ? 'Required' : ''
        if (name === 'Shifts') return null
        if (type === 'Phone') {
          return (
            // @ts-ignore
            <MobilePhone
              disabled={disabled || onlyView}
              showFirst={false}
              index={i}
              key={name}
              error={error}
              {...v}
              onChangePhone={onChangePhone}
              className={classes.textField}
            />
          )
        }
        if (name === 'PropertyTag') {
          const buildingTypeValue = informationData.find((v) => v.name === 'PropertyType')?.value
          v.disabled = buildingTypeValue === 'Single'
          error = validate && !v.disabled && !value
        }
        if (type === 'Select') {
          return (
            <Autocomplete
              disableClearable
              key={name}
              disabled={disabled || onlyView || v.disabled}
              // @ts-ignore
              value={option.find((v) => [v.label, v?.value].includes(value)) || ''}
              options={option}
              className={classes.textField}
              onChange={(event, value) => onChangeSelect(event, value)}
              getOptionLabel={(option) => option.displayLabel || option.label || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  error={error}
                  label={label}
                  placeholder={label}
                  variant="outlined"
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{ ...params.InputProps, type: 'search' }}
                  helperText={error ? 'Required' : ''}
                />
              )}
            />
          )
        }
        if (validate && name === 'Zip' && !isValidPostalCode(value as string, Country)) {
          helperText = 'Invalid Zip Code'
          error = true
        }
        return (
          <TextField
            key={name}
            error={error}
            {...v}
            value={value}
            placeholder={label}
            variant="outlined"
            className={classes.textField}
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk,
              },
            }}
            disabled={disabled || onlyView || v.disabled}
            SelectProps={{
              displayEmpty: true,
              classes: {
                select: classes.select,
              },
              type: 'search',
            }}
            onChange={handleChange}
            helperText={helperText}
          />
        )
      })}

      {new Array(3).fill(true).map((v, i) => (
        <Box key={i} className={`${classes.textField} ${classes.empty}`} />
      ))}

      {!hiddenShifts && (
        <Box sx={{ flex: '100%' }} className={classes.information}>
          {informationData
            .filter((item) => item.name === 'Shifts')
            .map((v, i) => {
              const { name, value = [], option = [] } = v

              return (
                <>
                  {/* @ts-ignore */}
                  {value?.map((w, j) => {
                    return (
                      <Box key={j} className={`${classes.textField} ${classes.timePicker}`}>
                        {['Start', 'End'].map((label, k) => {
                          const { StartTime, EndTime } = w
                          const value = label === 'Start' ? StartTime : EndTime

                          return (
                            <Box key={k} className={classes.timePickerItem}>
                              <Typography sx={{ marginBottom: '8px', fontSize: '14px' }}>
                                Shift-{j + 1}
                                <span style={{ color: 'rgba(0,0,0,0.6)' }}> {label}</span>
                              </Typography>
                              <Autocomplete
                                disableClearable
                                disabled={disabled || onlyView || v.disabled}
                                // @ts-ignore
                                value={option.find((v) => v.label === value) || ''}
                                options={option}
                                onChange={(event, value) => onChangeSelect(event, value, name, [j, k])}
                                getOptionLabel={(option) => option.displayLabel || option.label || ''}
                                popupIcon={<AccessTimeFilled />}
                                classes={{
                                  popupIndicator: classes.popupIndicator,
                                  input: classes.dateInput,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select"
                                    variant="outlined"
                                    InputLabelProps={{
                                      classes: {
                                        asterisk: classes.asterisk,
                                        root: classes.timePickerLabel,
                                        shrink: classes.timePickerLabelShrink,
                                      },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                      classes: {
                                        root: classes.timePickerRoot,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          )
                        })}
                      </Box>
                    )
                  })}
                </>
              )
            })}
          {new Array(3).fill(true).map((v, i) => (
            <Box key={i} className={`${classes.textField} ${classes.empty}`} />
          ))}
        </Box>
      )}
      {!disabled && (
        <EditAndSave
          showSave={showSave || !onlyView}
          onCancel={onCancel}
          onEdit={onEdit}
          onSave={onSave}
          hiddenCancel={hiddenCancel}
        />
      )}
    </Box>
  )
}
export default PropertyInformation
