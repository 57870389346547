import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import { Field } from '@activate-inc/activate-ui/dist/types/components/types'
import { makeStyles } from '@mui/styles'
import {
  DragDropContext,
  Draggable,
  DraggableProps,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { AddCircle, DeleteOutline, Reorder } from '@mui/icons-material'
import CustomTextField from '@component/Form/CustomTextField'
import useSetState from '@hooks/useSetState'
import { reorder } from '@utils/helpers'
import { ProductQuestionITF } from '@/types/entity'
import DragFormPanel, { FieldTypeArrITF } from '@component/Form/Form/DragFormPanel'
import FieldType, { FieldItem } from '@utils/FieldType'

interface AdditionalQuestionProps {
  onChange?: (data: Array<ProductQuestionITF>) => void
  value?: any
  changeRef: React.MutableRefObject<{ setData: (list: ProductQuestionITF[]) => void }>
  list?: Array<ProductQuestionITF>
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      paddingBottom: 24,
    },
    title: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto',
      color: '#4E4D4D',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #BCBCBC',
      padding: 24,
      borderRadius: 8,
    },
    sectionName: {
      display: 'flex',
      alignItems: 'center',
    },
    itemText: {
      marginTop: 24,
      flex: 1,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    add: {
      alignSelf: 'flex-start',
      padding: 0,
      marginTop: 16,
    },
    moveWrapper: {
      marginTop: 24,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
  }
})
const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

const DEFAULT = {
  Required: false,
  Code: '',
  Level: 2,
  Section: 1,
  QuestionText: { en: '', es: '' },
  QuestionStyle: 'SingleChoice',
  AnswerType: 'SingleChoiceAndUploadText',
  QuestionType: 'Question',
  Choices: [
    { Label: 'A', Value: 1, SubChoice: false },
    { Label: 'B', Value: 0, AllowEnterText: true, SubChoice: false },
  ],
  AnswerChoices: [],
}

interface ProductItemProps {
  item?: ProductQuestionITF
  dragHandleProps?: DraggableProps
  index: number
  onRemoveSection?: (index: number) => void
  onRemoveItem?: (index: number) => void
  onItemChange?: (item: ProductQuestionITF, index: number) => void
}
const FieldTypeArr = [
  { label: 'Checkbox', value: FieldType.SingleCheckBox },
  { label: 'Date', value: FieldType.Date },
  { label: 'DropDown', value: FieldType.DropDownSelection },
  { label: 'Multiple Choice', value: FieldType.CheckBox },
  { label: 'Text field', value: FieldType.Text },
  { label: 'Yes/No', value: FieldType.SingleCheckBox },
  { label: 'Upload', value: FieldType.UploadFiles },
  { label: 'DownLoad', value: FieldType.DownLoadFile },
] as FieldTypeArrITF
const ProductItem: React.FC<ProductItemProps> = ({ item, dragHandleProps, index, onRemoveSection, onItemChange }) => {
  const classes = useStyles()
  const { Code, ProductName, SectionTitle, SurveyQuestion, Fields = [] } = item || {}
  const onChangeField = useCallback(
    (fields: FieldItem[]) => {
      onItemChange?.(Object.assign({}, item, { Fields: fields as Array<Field> }), index)
    },
    [index, item, onItemChange]
  )

  const onInput = useCallback(
    (value: string, name: string) => {
      // @ts-ignore
      onItemChange?.(Object.assign({}, item, { [name]: value }), index)
    },
    [index, item, onItemChange]
  )

  return (
    <div className={classes.section}>
      <div className={classes.sectionName}>
        <Typography className={classes.title}>{`${ProductName || 'Additional'}`}</Typography>
        <IconButton disableRipple onClick={() => onRemoveSection?.(index)}>
          <DeleteOutline />
        </IconButton>
        <div {...dragHandleProps}>
          <IconButton>
            <Reorder />
          </IconButton>
        </div>
      </div>
      <CustomTextField
        label="Section Title"
        className={classes.itemText}
        variant="outlined"
        name="SectionTitle"
        disabled={false}
        onInputChange={onInput}
        value={SectionTitle}
      />
      <CustomTextField
        label="Survey Question"
        className={classes.itemText}
        variant="outlined"
        disabled={false}
        name="SurveyQuestion"
        onInputChange={onInput}
        value={SurveyQuestion}
      />
      <Typography sx={{ marginTop: '16px' }}>Question</Typography>
      <DragFormPanel
        fieldTypeArr={FieldTypeArr}
        language="en"
        data={Fields as FieldItem[]}
        onChangeField={onChangeField}
        addText="Add Question"
      />
    </div>
  )
}
const AdditionalQuestion: React.FC<AdditionalQuestionProps> = ({ list, onChange, changeRef }) => {
  const [state, setState] = useSetState({ questions: list })
  const { questions = [] } = state || {}
  const classes = useStyles()
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(questions || [], source.index, destination.index)
    setState({ questions: newItems })
  }
  const onItemChange = useCallback(
    (data, index) => {
      questions.splice(index, 1, data)
      const nextQuestions = [...questions]
      onChange?.(nextQuestions)
      setState({ questions: nextQuestions })
    },
    [onChange, questions, setState]
  )
  const onRemove = useCallback(
    (index) => {
      questions.splice(index, 1)
      const nextQuestions = [...questions]
      onChange?.(nextQuestions)
      setState({ questions: nextQuestions })
    },
    [questions, setState]
  )
  const onAddProduct = useCallback(() => {
    setState?.((pre) => {
      const next = pre.questions || []
      const nextQuestions = [...next, { Code: `Q${Date.now()}`, Fields: [] } as unknown as ProductQuestionITF]
      onChange?.(nextQuestions)
      return {
        ...pre,
        questions: nextQuestions,
      }
    })
  }, [onChange, setState])
  useImperativeHandle(
    changeRef,
    () => ({
      setData: (data: ProductQuestionITF[]) => setState({ questions: data }),
    }),
    []
  )
  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable2">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {questions.map((item: any, index: number) => (
                <Draggable key={item?.Code} draggableId={item?.Code} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      className={classes.moveWrapper}
                      style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    >
                      <ProductItem
                        key={index}
                        item={item}
                        index={index}
                        onRemoveSection={onRemove}
                        onItemChange={onItemChange}
                        //@ts-ignore
                        dragHandleProps={draggableProvided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        aria-label="addSection"
        variant="outlined"
        className={classes.add}
        style={{ padding: '6px 8px' }}
        onClick={onAddProduct}
      >
        <AddCircle color="primary" style={{ marginRight: 4 }} />
        Add Products
      </Button>
    </div>
  )
}
export default AdditionalQuestion
