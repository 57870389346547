import { useCallback, useEffect, useState } from 'react'
import { getQrTypesFlat } from '@api'
import SnackBar from '@component/SnackBar'
import { QRType } from '@/types/entity'
import { QRLimit } from '@constant/qrcode'

type CacheData = { [key: string]: Array<QRType> }
const cacheData: CacheData = {}
export const useGetQRTypes = (limit?: QRLimit, bId?: string) => {
  const [data, setData] = useState<CacheData>(cacheData)
  const [limitData, setLimitData] = useState<Array<QRType>>([])
  const [loadingDefault, setLoadingDefault] = useState(true)
  const getDefaultData = useCallback(async () => {
    try {
      // if(!bId) return
      const result = await getQrTypesFlat<Array<QRType>>({ bId })
      bId && (cacheData[bId] = result || [])
      setData(Object.assign({}, cacheData))
      setLoadingDefault(false)
    } catch (e) {
      // @ts-ignore
      SnackBar({ msg: e.message })
    }
  }, [bId])

  useEffect(() => {
    if (bId && cacheData?.[bId]?.length > 0) return
    getDefaultData()
  }, [getDefaultData])
  useEffect(() => {
    let limitData = (bId ? data?.[bId] : []) || []
    if (limit) {
      limitData = limitData.filter((item) => !item.Limit || (item.Limit && item.Limit.includes(limit)))
    }
    setLimitData(limitData)
  }, [limit, data, bId])
  return { loading: loadingDefault, allData: data, data: limitData }
}
