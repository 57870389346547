// import faker from 'faker';

// a little function to help us with reordering the result
import { PASSWORD_TIPS } from '@constant/app'

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape

export const isEmail = (email?: string) => {
  return email && EMAIL_REGEX.test(email)
}

export const isPasswordValid = (password = '') => {
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const numericRegex = /[0-9]/
  const specialCharRegex = /[~!@#$%^*-_+=\\[{\]}/;:,.?]/
  const uniqueChars = new Set(password.split('')).size
  let conditionsMet = 0

  if (uppercaseRegex.test(password)) {
    conditionsMet++
  }

  if (lowercaseRegex.test(password)) {
    conditionsMet++
  }

  if (numericRegex.test(password)) {
    conditionsMet++
  }

  if (specialCharRegex.test(password)) {
    conditionsMet++
  }
  let msg = ''
  const checked = password?.length >= 10 && uniqueChars >= 5 && conditionsMet >= 3 && !/\s/.test(password)
  if (password?.length < 10 || password?.length > 100) {
    msg = PASSWORD_TIPS[0]
  } else if (uniqueChars < 5) {
    msg = PASSWORD_TIPS[1]
  } else if (conditionsMet < 3) {
    msg = PASSWORD_TIPS[2]
  } else if (!checked) {
    msg = PASSWORD_TIPS[3]
  }
  return {
    msg,
    checked,
  }
}

export const isValidPostalCode = (postalCode: string, country: string) => {
  let regex

  switch (country) {
    case 'USA':
      // 美国邮编的正则表达式
      regex = /^[0-9]{5}(?:-[0-9]{4})?$/
      break
    case 'Canada':
      // 加拿大邮编的正则表达式
      regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
      break
    default:
      return false
  }

  return regex.test(postalCode)
}
