import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { toPercentText } from '@utils/common'
import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '16px 24px 24px',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
  },
  wrapper: {
    flex: 1,
    height: 20,
    borderRadius: 20,
    marginLeft: 16,
    backgroundColor: '#E7EBF1',
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    height: 20,
    left: 0,
    padding: '0 8px',
    borderRadius: 20,
    textAlign: 'end',
    backgroundColor: '#1ECD5A',
  },
  percentText: {
    height: 20,
    color: 'white',
    fontWeight: '600',
    lineHeight: '20px',
  },
}))
interface Props {
  percent?: number
  className?: string
  title?: React.ReactNode
}
const ProgressBarWithTitle: React.FC<Props> = ({ percent = 0, className, title }) => {
  const classes = useStyles()
  const percentText = toPercentText(percent)
  return (
    <Box className={classnames(classes.container, className)}>
      {title ? title : <Typography>Progress Bar</Typography>}
      <Box className={classes.wrapper}>
        <Box className={classes.inner} style={{ minWidth: percentText }}>
          <Typography className={classes.percentText}>{percentText}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
export default ProgressBarWithTitle
