import React from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { Box, Typography } from '@mui/material'
import { CheckBoxOutlineBlank, Close, RadioButtonUnchecked } from '@mui/icons-material'
import { FieldItem } from '@utils/FieldType'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'

interface Props {
  item: FieldItem
  language: LanguageType
  index: number
  isSingleChoice?: boolean
  hiddenOption?: boolean
  disabled?: boolean
  hiddenIcon?: boolean
  onItemChange: (value: string, name: string, index: number, i?: number) => void
}

const useStyles = makeStyles(() => {
  return {
    choicesItem: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
      color: 'rgba(0,0,0,0.54)',
    },
    choicesItemCenter: {
      flex: 1,
      margin: '0 16px',
    },
    disabled: {
      color: '#BCBCBC',
    },
  }
})
const SelectCom: React.FC<Props> = ({
  item,
  index,
  language,
  onItemChange,
  isSingleChoice,
  hiddenOption = false,
  disabled,
  hiddenIcon,
}) => {
  const classes = useStyles()

  return (
    <Box>
      <CustomTextField
        label=""
        value={item.FieldText?.[language]}
        disabled={disabled}
        name="FieldText"
        fullWidth
        noMargin
        onInputChange={(value, name) => onItemChange(value, name, index)}
        variant="outlined"
        placeholder="question"
      />
      {!hiddenOption &&
        item.Choices.map((v, i) => (
          <Box key={i} className={classnames(classes.choicesItem, classes.disabled)}>
            {!hiddenIcon && (isSingleChoice ? <RadioButtonUnchecked /> : <CheckBoxOutlineBlank />)}
            <CustomTextField
              label=""
              className={classes.choicesItemCenter}
              placeholder="option"
              value={v.Text?.[language]}
              disabled={disabled || false}
              name="Choices"
              fullWidth
              noMargin
              onInputChange={(value, name) => onItemChange(value, name, index, i)}
            />
            <Close onClick={() => !disabled && onItemChange('', 'delete', index, i)} />
          </Box>
        ))}
      {!hiddenOption && !disabled && (
        <Box
          style={{ display: item?.Choices.length >= 10 ? 'none' : '' }}
          className={classes.choicesItem}
          onClick={() => onItemChange('', 'add', index)}
        >
          {!hiddenIcon && (isSingleChoice ? <RadioButtonUnchecked /> : <CheckBoxOutlineBlank />)}
          <Typography className={classes.choicesItemCenter}>Add option</Typography>
        </Box>
      )}
    </Box>
  )
}

export default SelectCom
