import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useRedirect, Notification, useLocaleState, useAuthProvider } from 'react-admin'
import { selectedGroup, setUserData } from '@reducer/profile'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import { getAuthToken } from '@common/auth'
import SnackBar from '@component/SnackBar'
import { ACTIVATE_COMPANY_LOGO_WHITE } from '@/global'
import LoginForm from '@component/Auth/LoginForm'

const useStyles = makeStyles(() => ({
  leftBg: {
    background: 'transparent linear-gradient(132deg, #4268B3 0%, #1B365D 100%) 0% 0% no-repeat padding-box',
    justifyContent: 'center',
  },
  main: {
    minHeight: '100vh',
  },
  leftPanel: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightForm: {
    padding: '172px 135px 0',
  },
}))

const Login = () => {
  const classes = useStyles()
  const redirectTo = useRedirect()
  const dispatch = useDispatch()
  const location = useLocation()
  const authProvider = useAuthProvider()
  const { nextPathname, redirectToNextPath } = useMemo(() => location.state || {}, [location.state])
  const [locale] = useLocaleState()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const accessToken = getAuthToken()
    if (accessToken) {
      redirectTo('/')
    }
  }, [redirectTo])
  const handleSubmit = useCallback(
    (auth) => {
      const { email, password, checked } = auth
      setLoading(true)
      if (checked) {
        localStorage.setItem('user', email)
        localStorage.setItem('password', encodeURIComponent(password))
      } else {
        localStorage.removeItem('user')
        localStorage.removeItem('password')
      }
      authProvider
        .login({ username: email, password: password })
        .then((user) => {
          const { UserContext = {} } = user
          dispatch(setUserData(user))
          const {
            GlobalManageCustomers = false,
            ManageEntities = false,
            ManageMarketing = false,
          } = UserContext?.PermissionsHash || {}
          const isGlobalManageCustomers = GlobalManageCustomers
          const isSurveyWelcome = nextPathname?.includes('surveyWelcome')
          if (!isGlobalManageCustomers || isSurveyWelcome) {
            dispatch(
              selectedGroup({
                groupId: UserContext?.CurrentGroupId,
                groupName: UserContext?.CurrentGroupName?.[locale],
                group: {
                  acId: UserContext?.CurrentGroupId,
                  GroupName: UserContext?.CurrentGroupName,
                  LogoFilename: UserContext?.CurrentGroupLogoFilename,
                },
              })
            )
          }
          if (isSurveyWelcome || (redirectToNextPath && nextPathname)) {
            redirectTo(nextPathname)
          } else if (isGlobalManageCustomers) {
            redirectTo('/adminSearch')
          } else {
            if (ManageEntities) {
              return redirectTo('/properties')
            }
            if (ManageMarketing) {
              return redirectTo('/marketing')
            }
            redirectTo('/')
          }
        })
        .catch((error) => {
          SnackBar({ msg: error })
          setLoading(false)
        })
    },
    [authProvider, dispatch, locale, nextPathname, redirectTo, redirectToNextPath]
  )

  const onForgot = useCallback(() => {
    redirectTo('/reset')
  }, [redirectTo])
  return (
    <Grid container className={classes.main}>
      <Hidden only={['xs', 'sm']}>
        <Grid item xs={6} className={classes.leftBg}>
          <Box className={classes.leftPanel}>
            <img id="loginicon" alt="loginlogo" src={ACTIVATE_COMPANY_LOGO_WHITE} width={600} />
          </Box>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6} className={classes.rightForm}>
        <LoginForm disabled={loading} onSubmit={handleSubmit} onForgot={onForgot} />
      </Grid>
      <Notification />
    </Grid>
  )
}

export default Login
