import React, { useState, useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: 'unset',
    maxWidth: 'unset',
    padding: '32px 35px 18px',
    backgroundColor: 'white',
    borderRadius: 12,
  },
  close: {
    alignSelf: 'flex-end',
  },
  img: {
    width: 400,
    height: 602,
  },
  qr: {
    position: 'absolute',
    width: 190,
    height: 190,
    left: 130,
    bottom: 135,
  },
  qrLandscape: {
    position: 'absolute',
    width: 190,
    height: 190,
    right: 106,
    bottom: 140,
  },
  imgLandscape: {
    height: 400,
    width: 602,
  },
  qr810Landscape: {
    position: 'absolute',
    width: 180,
    height: 180,
    right: 95,
    bottom: 130,
  },
  img810Landscape: {
    height: 490,
    width: 600,
  },
  qr810Portrait: {
    position: 'absolute',
    width: 180,
    height: 180,
    left: 180,
    bottom: 130,
  },
  img810Portrait: {
    height: 600,
    width: 490,
  },
  imgDefault: {
    maxWidth: 600,
    maxHeight: 600,
    objectFit: 'contain',
  },
  content: {
    position: 'relative',
  },
}))

interface Props {
  orientation: string
  url: string
  size: string
  qrImg: string
}
export default class PrintProofView {
  static show = (props: Props) => {
    if (props?.url === '') return
    const div = document.createElement('div')
    const root = document.getElementById('root')
    root?.appendChild(div)
    ReactDOM.render(<PrintProofViewCom {...props} />, div)
  }
}

const PrintProofViewCom: React.FC<Props> = ({ url = '', orientation, size, qrImg }) => {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const data = useMemo(() => {
    const key = (size + '-' + orientation).replace(/\s*/g, '')
    const MAP = {
      '5x8-Portrait': { img: classes.img },
      '5x8-Landscape': { img: classes.imgLandscape, qr: classes.qrLandscape },
      '8x10-Portrait': { img: classes.img810Portrait, qr: classes.qr810Portrait },
      '8x10-Landscape': { img: classes.img810Landscape, qr: classes.qr810Landscape },
    }
    console.log('key', key, MAP[key])
    return Object.assign({ img: classes.imgDefault, qr: classes.qr }, MAP[key], { show: !!MAP[key] })
  }, [size, orientation, classes])

  useEffect(() => {}, [])
  const removeDialog = () => {
    setOpen(false)
  }

  if (url === '') return null
  const qrCodeImg = qrImg?.replace(/(.eps)|(.pdf)/g, '.png')
  return (
    <Dialog open={open} onClose={removeDialog} classes={{ paper: classes.container }}>
      <IconButton onClick={removeDialog} className={classes.close}>
        <Close htmlColor="#211F1F" fontSize="large" />
      </IconButton>
      <DialogContent className={classes.content}>
        <img src={url} className={data.img} />
        {data.show && <img src={qrCodeImg} className={data.qr} />}
      </DialogContent>
    </Dialog>
  )
}
