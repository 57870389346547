import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  mobileFrame: {
    minHeight: 550,
    maxHeight: 551,
    maxWidth: 315,
    minWidth: 314,
    display: 'flex',
    alignItems: 'center',
  },
  viewTitle: {
    fontSize: 18,
    marginBottom: 20,
    fontWeight: '600',
    color: '#434343',
  },
  contentView: {
    display: 'block',
    overflow: 'hidden',
    maxHeight: 400,
    overflowY: 'auto',
  },
  linkView: {
    width: '100%',
    borderTop: '1px solid #ccc',
    lineHeight: '2.6',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    color: '#000',
    textDecoration: 'none',
    padding: '3px 6px',
    margin: '1px',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      color: '#386bbf',
      fontSize: 18,
    },
  },
  contentPanel: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 315,
    minWidth: 314,
    //   margin:'5px 5px',
    //   backgroundColor: 'red',
  },
  pagePanel: {
    padding: 10,
  },
  marginTop2: {
    marginTop: theme.spacing(5),
  },
  input: {
    display: 'none',
  },

  margin: {
    margin: theme.spacing(1),
  },

  tabs: {
    background: '#E0DDDD',
    padding: 2,
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
      margin: 'auto',
      '& button': {
        width: '49%',
      },
    },
  },

  tabSection: {
    background: '#fff',
    padding: 30,
    border: '1px solid #D5D5D5',
    '& .MuiButtonBase-root': {
      fontSize: 15,
      padding: 6,
      height: 30,
      lineHeight: 30,
      color: '#605A5A !important',
      borderRadius: 4,
      opacity: '1',
      fontWeight: '400 !important',
    },
    '& .Mui-selected': {
      background: '#fff',
      color: '#000 !important',
      fontWeight: '400 !important',
    },
    '& .Mui-selected:last-child': {
      background: '#000 !important',
      color: '#fff !important',
      fontWeight: '400 !important',
    },
    '& .MuiTabs-indicator': {
      display: 'none !important',
    },
    [theme.breakpoints.down('md')]: {
      background: ' #fff',
      width: ' calc(100% + 80px)',
      marginTop: '-44px',
      padding: '6px 4px !important',
      marginRight: '-24px',
    },
  },

  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 6px 0',
    '& button': {
      padding: '20px !important',
    },
  },
  imgView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
    '& img': {
      maxWidth: 200,
      width: '100%',
      height: 'auto',
    },
  },

  tabMPadding: {
    [theme.breakpoints.down('md')]: {},
  },

  tabContentSection: {},

  swiper: {
    overflow: 'hidden !important',
  },

  tabContentImg: {
    height: 'auto',
    width: '99%',
    [theme.breakpoints.down('md')]: {
      width: '99%',
    },
  },

  button: {
    color: '#434343',
    height: 46,
    borderRadius: 0,
    background: '#fff',
    fontSize: 18,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  buttonIcon: {
    color: '#386BBF',
    height: 24,
    width: 24,
  },
}))
