import React, { useEffect } from 'react'

interface ActivitySchedulePanelProps {
  onChange?: (data: any) => void
  value: any
}
const ActivitySchedulePanel: React.FC<ActivitySchedulePanelProps> = ({ onChange, value }) => {
  const { Items = [] } = value || {}
  useEffect(() => {
    if (!value) {
      onChange?.({ Reminders: [], CanSave: true })
    }
  }, [onChange, value])
  return <div />
}
export default ActivitySchedulePanel
