import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { ActivitySchedule, ActivityScheduleITF } from '@activate-inc/activate-ui'
import { GetPropertyContacts } from '@api/index'
import { useSelector } from 'react-redux'
import { Template } from '@/types/entity'

interface Props {
  template?: Template
  qrTypeName: string
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 56px)',
      flexBasis: 'auto',
      '@supports (-webkit-touch-callout: none)': {
        minHeight: '-webkit-fill-available',
      },
    },
  }
})

const ActivityScheduleForm: React.FC<Props> = ({ template, qrTypeName }) => {
  const classes = useStyles()
  const [contacts, setContacts] = useState()
  const { selectedPropertyId } = useSelector((state) => state.profile)

  useEffect(() => {
    if (!selectedPropertyId) return
    GetPropertyContacts({
      filter: JSON.stringify({ bId: selectedPropertyId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      const datas = (res.Contacts || []).map((item) => ({ ...item, label: item.FullName.es, value: item.UserId }))
      setContacts(datas)
    })
  }, [selectedPropertyId])
  return (
    <ActivitySchedule
      className={classes.container}
      qrTypeName={qrTypeName}
      onSave={() => {}}
      contacts={contacts}
      data={template as ActivityScheduleITF}
    />
  )
}
export default ActivityScheduleForm
