import React, { useEffect, ChangeEvent, useState, useRef } from 'react'
import { Typography, Box, IconButton } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { DeleteOutlined, ArrowUpward } from '@mui/icons-material'
import uploadImg from '@/assets/images/qr-code/feedback/upload.svg'

const EMPTY_LOGO_IMG = process.env.PUBLIC_URL + '/no-image-icon.png'
const useStyles = makeStyles<UploadLogoProps>({
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 16,
  },

  imgBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20,
  },
  imgTitle: {
    fontFamily: 'Roboto',
    width: (props: { labelWidth?: number }) => {
      return props?.labelWidth || 'auto'
    },
  },
  input: {
    display: 'none',
  },
  input_box: {
    paddingTop: 100,
    backgroundColor: 'red',
    marginTop: 40,
  },
  img: {
    width: (props: { imgWidth?: number }) => {
      return props?.imgWidth || 100
    },
    objectFit: 'contain',
    height: (props: { imgHeight?: number }) => {
      return props?.imgHeight || 100
    },
  },
  imgAvatar: {
    height: 100,
    width: 100,
    objectFit: 'cover',
  },
  example: {
    marginLeft: 10,
  },
  ml: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  sizeSmall: {
    padding: '0 3px 5px',
  },
})

export interface UploadLogoProps {
  id?: string
  label?: string
  cleanup?: () => void
  src?: string
  imgUrl?: string
  hasExample?: boolean
  exampleUrl?: string
  handleImageChange: (event: React.ChangeEvent<{}>) => void
  isAvatarImage?: boolean
  imgWidth?: number | string
  imgHeight?: number | string
  type: string
  labelWidth?: number | string
  disabled?: boolean
}

export const ImageUpload = (props: UploadLogoProps) => {
  const classes = useStyles(props)
  const {
    type,
    label,
    cleanup,
    src,
    handleImageChange,
    hasExample,
    imgUrl,
    isAvatarImage,
    exampleUrl,
    disabled = false,
  } = props
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (src) {
      typeof src === 'string' ? setImageUrl(src) : setImageUrl(URL.createObjectURL(src))
      const iconinput = document.getElementById('icon-button-file' + type)
      // @ts-ignore
      iconinput.value = ''
    } else {
      setImageUrl('')
    }
  }, [src])
  const showExample = hasExample && !imageUrl
  const showCleanup = !!(cleanup && imageUrl)
  return (
    <Box className={classes.imgBox}>
      {label && <Typography className={classes.imgTitle}>{label}</Typography>}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          id={'logopreview' + type}
          alt="company logo"
          className={isAvatarImage ? classes.imgAvatar : classes.img}
          src={imageUrl || EMPTY_LOGO_IMG}
        />
        {showExample && (
          <Box className={classes.example}>
            <a href={exampleUrl} target="_blank" rel="noreferrer">
              Example
            </a>
          </Box>
        )}
      </Box>
      <Box>
        <input
          name={'input' + type}
          accept=".png, .jpg, .jpeg"
          className={classes.input}
          id={'icon-button-file' + type}
          onChange={handleImageChange}
          type="file"
          disabled={disabled}
        />
        <label htmlFor={'icon-button-file' + type}>
          <img src={uploadImg} alt="upload" className={classes.ml} />
        </label>
      </Box>
      {showCleanup && (
        <IconButton size="small" onClick={cleanup} classes={{ sizeSmall: classes.sizeSmall }}>
          <DeleteOutlined color="primary" />
        </IconButton>
      )}
    </Box>
  )
}

export default ImageUpload
