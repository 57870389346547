import React, { useState, useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, Typography, Box, InputAdornment } from '@mui/material'
import { PanelChoice } from '@/types/panel'
import Popper from '@mui/material/Popper'
import { Search, ArrowRight, ArrowDropDown, ExpandMore, ExpandLess } from '@mui/icons-material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { HubIcon } from '@activate-inc/activate-ui'
import CONFIG from '@constant/config'

const useStyles = makeStyles(() => {
  return {
    inputRoot: {
      width: '100%',
      borderRadius: 6,
      backgroundColor: '#fff',
      '&[class*="MuiOutlinedInput-root"]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    input: {
      padding: 8,
      '&:first-child': {
        color: '#4D4D4D',
      },
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #bcbcbc',
      borderRadius: 6,
      height: 64,
      width: 72,
      padding: '0 8px',
      '& img': {
        display: 'block',
      },
      margin: '0 16px',
      cursor: 'pointer',
      position: 'relative',
    },
    label: {
      backgroundColor: '#F3F5F9',
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 12,
      position: 'absolute',
      top: -9,
      left: 8,
      padding: '0 4px',
    },
    popper: {
      boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
      border: '1px solid #bcbcbc',
      marginTop: 12,
      borderRadius: 6,
      width: 350,
      height: 400,
      backgroundColor: '#FFFFFF',
      padding: '12px 12px 0',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      fontSize: 14,
    },
    inputAdornedEnd: {
      paddingRight: 0,
    },
    imgBox: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      overflowY: 'auto',
      flex: 1,
    },
    imgItem: {
      padding: '8px 3px',
    },
    iconType: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'rgba(175, 193, 222, 0.2)',
      margin: '0 -12px',
      cursor: 'pointer',
      paddingLeft: 12,
    },
    iconTypeMask: {
      position: 'absolute',
      right: 0,
      top: 0,
      left: 0,
      bottom: 30,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      padding: 4,
      paddingTop: 58,
      display: 'flex',
      flexDirection: 'column',
    },
    iconTypeBody: {
      flex: 1,
      overflowY: 'auto',
      backgroundColor: '#fff',
      borderRadius: 4,
      padding: 4,
      '& img': {
        display: 'block',
      },
    },
    iconTypeItem: {
      padding: 4,
      cursor: 'pointer',
      fontSize: 14,
    },
    popperMask: {
      zIndex: 1,
    },
    iconStyle: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      border: '6px solid #fff',
      margin: '0 8px 0 0',
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
    svgBox: {
      width: 36,
      height: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      '& > span': {
        display: 'flex',
      },
    },
  }
})

const IconStyle = [
  ['#585858', '#D7D7D7'], // [color, backgroundColor]
  ['#1976D2', '#D4E2EF'],
  ['#018786', '#D0E4E4'],
  ['#E05050', '#F2DCDC'],
  ['#F0890F', '#FFF5DB'],
  ['#8C510C', '#ECE0D2'],
  ['#6640FF', '#D9D4EF'],
]
const getSrc = (src) => {
  return src.endsWith('.svg') ? `${CONFIG.iconSvgFolder}${src}` : `${CONFIG.iconFolder}${src}`
}

interface PanelSelectionProps {
  choices: Array<PanelChoice>
  value?: string
  defaultIcon: string
  onChange?: (item: Record<string, any>) => void
  iconTypeArr: string[]
  disabled?: boolean
  fill: string
  background: string
}

const IconSelection: React.FC<PanelSelectionProps> = ({
  choices,
  value,
  onChange,
  defaultIcon,
  iconTypeArr,
  disabled,
  fill,
  background,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [iconType, setIconType] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [typeOption, setTypeOption] = useState(false)
  const [iconStyle, setIconStyle] = useState(IconStyle[0])
  const open = Boolean(anchorEl)
  const list = useMemo(() => {
    let list = choices
    if (iconType) {
      list = choices.filter((v) => v.Section === iconType)
    }
    if (inputValue) {
      const lowerCaseStr = inputValue.toLowerCase()
      list = list.filter((v) => v.Name.toLowerCase()?.includes(lowerCaseStr))
    }
    return list
  }, [choices, iconType, inputValue])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onChangeOption = (Icon: string) => {
    onChange?.({ Icon, SVGFill: iconStyle[0], SVGBackground: iconStyle[1] })
    setAnchorEl(null)
  }

  const onIconTypeOptionClick = (value) => {
    setIconType(value)
    setTypeOption(false)
  }

  const onClickAway = () => {
    setAnchorEl(null)
    setIconType('')
    setInputValue('')
    setTypeOption(false)
  }
  const id = open ? 'simple-popper' : undefined

  return (
    <ClickAwayListener onClickAway={onClickAway} disabled={disabled}>
      <Box>
        <Box className={classes.box} onClick={handleClick} aria-describedby={id}>
          <span className={classes.label}>Icon</span>
          <Box style={{ margin: '0 auto' }}>
            {value && (
              <HubIcon
                src={getSrc(value)}
                fill={fill}
                background={background}
                className={classes.svgBox}
                width="18px"
              />
            )}
          </Box>
          {open ? <ExpandLess htmlColor="rgba(0,0,0,0.54)" /> : <ExpandMore htmlColor="rgba(0,0,0,0.54)" />}
        </Box>
        <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popperMask} placement="bottom-start">
          <Box className={classes.popper}>
            <TextField
              className={classes.inputRoot}
              onChange={(e) => setInputValue(e.target.value)}
              label=""
              variant="outlined"
              placeholder="Select Icon"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                classes: {
                  input: classes.input,
                  adornedEnd: classes.inputAdornedEnd,
                },
              }}
            />
            <Box className={classes.imgBox}>
              <Box style={{ flex: '100% 0 0' }}>
                <Typography style={{ fontSize: 16, color: '#000', margin: '24px 0 12px' }}>Choose A Style</Typography>
                <Box sx={{ display: 'flex', marginBottom: '24px' }}>
                  {IconStyle.map((v, i) => {
                    const [backgroundColor, borderColor] = v
                    const isActive = iconStyle[0] === backgroundColor
                    return (
                      <div
                        key={i}
                        className={classes.iconStyle}
                        style={{ backgroundColor, borderColor: isActive ? borderColor : '#fff' }}
                        onClick={() => setIconStyle(v)}
                      />
                    )
                  })}
                </Box>

                {defaultIcon && <Typography style={{ fontSize: 14 }}>Default</Typography>}
                {defaultIcon && (
                  <HubIcon src={getSrc(defaultIcon)} fill={fill} background={background} className={classes.svgBox} />
                )}
                <Typography style={{ fontSize: 14 }}>Icon List</Typography>
              </Box>
              {list.map((v) => {
                return (
                  <Box
                    key={v.Name}
                    className={classes.imgItem}
                    onClick={() => onChangeOption(v?.FileName)}
                    title={v.Name}
                  >
                    <HubIcon
                      src={v.Url}
                      fill={iconStyle[0]}
                      background={iconStyle[1]}
                      className={classes.svgBox}
                      width="18px"
                    />
                  </Box>
                )
              })}
            </Box>

            <Box className={classes.iconType} onClick={() => setTypeOption(true)}>
              <Typography>{iconType || 'All'}</Typography>
              {!typeOption ? <ArrowRight /> : <ArrowDropDown />}
            </Box>

            <Box className={classes.iconTypeMask} style={{ display: typeOption ? '' : 'none' }}>
              <Box className={classes.iconTypeBody}>
                {iconTypeArr.map((v) => (
                  <Typography key={v} className={classes.iconTypeItem} onClick={() => onIconTypeOptionClick(v)}>
                    {v}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export default IconSelection
