import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  body: {
    width: '90%',
    padding: '32px 0 18px',

    height: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '10%',
    transform: 'translateX(-50%)',
    overflowY: 'auto',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 32,
  },
  modalBtn: {
    fontSize: 15,
    fontWeight: 500,
    padding: '8px 16px',
    borderRadius: 4,
    marginLeft: 16,
  },
  import: {
    width: 777,
    height: 528,
    padding: '32px 32px 18px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  download: {
    background: '#F0F6FC',
    padding: 6,
    justifyContent: 'center',
    margin: '0 0 32px 0',
    borderRadius: 8,
  },
  uploadTitle: {
    color: '#211F1F',
    fontSizes: 20,
    marginBottom: 32,
    fontWeight: 600,
  },
  actionIcon: {
    color: '#466CB5',
    marginRight: 20,
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'none',
  },
}))
