import AmenitiesInstructions01 from '@/assets/images/CustomerPortal/AmenitiesInstructions_01.png'
import AmenitiesInstructions02 from '@/assets/images/CustomerPortal/AmenitiesInstructions_02.png'
import AmenitiesInstructions03 from '@/assets/images/CustomerPortal/AmenitiesInstructions_03.png'
import AmenitiesInstructions04 from '@/assets/images/CustomerPortal/AmenitiesInstructions_04.png'
import AmenitiesInstructions05 from '@/assets/images/CustomerPortal/AmenitiesInstructions_05.png'
import AmenitiesInstructions06 from '@/assets/images/CustomerPortal/AmenitiesInstructions_06.png'
import AmenitiesInstructions07 from '@/assets/images/CustomerPortal/AmenitiesInstructions_07.png'
import AmenitiesInstructions08 from '@/assets/images/CustomerPortal/AmenitiesInstructions_08.png'

import ConferenceRoomInstructions01 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_01.png'
import ConferenceRoomInstructions02 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_02.png'
import ConferenceRoomInstructions03 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_03.png'
import ConferenceRoomInstructions04 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_04.png'
import ConferenceRoomInstructions05 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_05.png'
import ConferenceRoomInstructions06 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_06.png'
import ConferenceRoomInstructions07 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_07.png'
import ConferenceRoomInstructions08 from '@/assets/images/CustomerPortal/ConferenceRoomInstructions_08.png'
import DownloadApp01 from '@/assets/images/CustomerPortal/DownloadApp_01.png'
import FAQs01 from '@/assets/images/CustomerPortal/FAQs_01.png'
import Html01 from '@/assets/images/CustomerPortal/Html_01.png'
import Html02 from '@/assets/images/CustomerPortal/Html_02.png'
import JoinWifi01 from '@/assets/images/CustomerPortal/JoinWifi_01.png'
import PDF01 from '@/assets/images/CustomerPortal/PDF_01.png'
import PDF02 from '@/assets/images/CustomerPortal/PDF_02.png'
import PDF03 from '@/assets/images/CustomerPortal/PDF_03.png'
import RoomRental01 from '@/assets/images/CustomerPortal/RoomRental_01.png'
import RoomRental02 from '@/assets/images/CustomerPortal/RoomRental_02.png'
import StaticUrl01 from '@/assets/images/CustomerPortal/StaticUrl_01.png'
import WatchVideo01 from '@/assets/images/CustomerPortal/WatchVideo_01.png'
import WifiInstructions01 from '@/assets/images/CustomerPortal/WifiInstructions_01.png'
import Collateral01 from '@/assets/images/CustomerPortal/Collateral_01.png'
import Contact01 from '@/assets/images/CustomerPortal/Contact_01.png'
import CheckIn01 from '@/assets/images/CustomerPortal/CheckIn_01.png'
import JobApplication01 from '@/assets/images/CustomerPortal/JobApplication_01.png'
import LeadCapture01 from '@/assets/images/CustomerPortal/LeadCapture_01.png'
import SocialMedia01 from '@/assets/images/CustomerPortal/SocialMedia_01.png'
import SocialMedia02 from '@/assets/images/CustomerPortal/SocialMedia_02.png'
import SocialMedia03 from '@/assets/images/CustomerPortal/SocialMedia_03.png'
import VacancyHub01 from '@/assets/images/CustomerPortal/VacancyHub_01.png'
import VacancyHub02 from '@/assets/images/CustomerPortal/VacancyHub_02.png'
import VacancyHub03 from '@/assets/images/CustomerPortal/VacancyHub_03.png'
import EmbeddedFile01 from '@/assets/images/CustomerPortal/EmbeddedFile_01.png'
import ConferenceRoomHub01 from '@/assets/images/CustomerPortal/ConferenceRoomHub_01.png'
import ConferenceRoomHub02 from '@/assets/images/CustomerPortal/ConferenceRoomHub_02.png'
import ConferenceRoomHub03 from '@/assets/images/CustomerPortal/ConferenceRoomHub_03.png'
import ConferenceRoomHub04 from '@/assets/images/CustomerPortal/ConferenceRoomHub_04.png'
import ConferenceRoomHub05 from '@/assets/images/CustomerPortal/ConferenceRoomHub_05.png'
import ConferenceRoomHub06 from '@/assets/images/CustomerPortal/ConferenceRoomHub_06.png'
import ConferenceRoomHub07 from '@/assets/images/CustomerPortal/ConferenceRoomHub_07.png'
import ConferenceRoomHub08 from '@/assets/images/CustomerPortal/ConferenceRoomHub_08.png'
import TenantHub01 from '@/assets/images/CustomerPortal/TenantHub_01.png'
import TenantHub02 from '@/assets/images/CustomerPortal/TenantHub_02.png'
import TenantHub03 from '@/assets/images/CustomerPortal/TenantHub_03.png'
import TenantHub04 from '@/assets/images/CustomerPortal/TenantHub_04.png'
import TenantHub05 from '@/assets/images/CustomerPortal/TenantHub_05.png'
import TenantHub06 from '@/assets/images/CustomerPortal/TenantHub_06.png'

const Images = {
  AmenitiesInstructions01,
  AmenitiesInstructions02,
  AmenitiesInstructions03,
  AmenitiesInstructions04,
  AmenitiesInstructions05,
  AmenitiesInstructions06,
  AmenitiesInstructions07,
  AmenitiesInstructions08,
  ConferenceRoomInstructions01,
  ConferenceRoomInstructions02,
  ConferenceRoomInstructions03,
  ConferenceRoomInstructions04,
  ConferenceRoomInstructions05,
  ConferenceRoomInstructions06,
  ConferenceRoomInstructions07,
  ConferenceRoomInstructions08,
  DownloadApp01,
  FAQs01,
  Html01,
  Html02,
  JoinWifi01,
  PDF01,
  PDF02,
  PDF03,
  RoomRental01,
  RoomRental02,
  StaticUrl01,
  WatchVideo01,
  WifiInstructions01,
  Collateral01,
  Contact01,
  CheckIn01,
  JobApplication01,
  LeadCapture01,
  SocialMedia01,
  SocialMedia02,
  SocialMedia03,
  VacancyHub01,
  VacancyHub02,
  VacancyHub03,
  EmbeddedFile01,
  ConferenceRoomHub01,
  ConferenceRoomHub02,
  ConferenceRoomHub03,
  ConferenceRoomHub04,
  ConferenceRoomHub05,
  ConferenceRoomHub06,
  ConferenceRoomHub07,
  ConferenceRoomHub08,
  TenantHub01,
  TenantHub02,
  TenantHub03,
  TenantHub04,
  TenantHub05,
  TenantHub06,
}
export default Images
