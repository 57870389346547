import React, { useCallback, useContext, useMemo } from 'react'
import { Box, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { COLOR } from '@constant/app'
import IconButton from '@mui/material/IconButton'
import { Edit, DragHandle, ExpandMore } from '@mui/icons-material'
import { DraggableProps } from 'react-beautiful-dnd'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { HubButtonITF, PanelChoice } from '@/types/panel'
import { Template } from '@activate-inc/activate-ui'
import { useSelector } from 'react-redux'
import { QRCodeFunctionType } from '@/types'
import { useGetQRTypes } from '@hooks/useGetQRTypes'
import { QRLimit } from '@constant/qrcode'
import classnames from 'classnames'
import { EntityITF } from '@/types/entity'
import { EditPanelContext } from '@context/EditPanelContext'
import IconSelection from '@component/EditPanel/IconSelection'
import Images from '@/assets/images'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 21,
    backgroundColor: '#F3F5F9',
  },
  label: {
    fontSize: 16,
    color: COLOR.black11,
  },
  item: {
    display: 'flex',
    flex: (props: { flex?: number }) => props.flex || 'unset',
    flexDirection: 'column',
    paddingRight: 1,
    '&:last-child': {
      paddingRight: 0,
    },
  },
  buttonName: {
    display: 'flex',
  },
  inputRoot: {
    flex: 1,
  },
  input: {
    height: 31,
  },
  redInput: {
    color: 'red',
  },
  editBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginRight: 16,
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  btnRoot: {
    marginLeft: 10,
    padding: 0,
  },
  padd: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  panelSelection: {
    flex: 1,
  },
  inputLabel: {
    transform: 'translate(12px, 20px)',
  },
}))

interface EditButtonItemProps {
  item: HubButtonITF
  index: number
  showEditBtn?: boolean
  onClick?: (item: HubButtonITF, index: number) => void
  onRemove?: (item: HubButtonITF, index: number) => void
  onChange: (data: any, index: number) => void
  dragHandleProps?: DraggableProps
  selectTemplate?: Template
  onChangeTemplate?: (value: any) => void
  entity?: EntityITF
  qrType?: string
  disabled?: boolean
}

const EditButtonItem: React.FC<EditButtonItemProps> = ({
  item,
  onClick,
  showEditBtn = true,
  index,
  onChange,
  dragHandleProps,
  onChangeTemplate,
  onRemove,
  entity,
  qrType,
  disabled,
}) => {
  const { selectedPropertyId } = useSelector((state) => state.profile)
  const classes = useStyles({})
  const { icons = [] } = useContext(EditPanelContext)
  const { Name = '', Icon, QrType, DefaultIcon = '', FunctionalityType, SVGFill = '', SVGBackground = '' } = item
  const { data } = useGetQRTypes(QRLimit.QrHub, selectedPropertyId)
  const qrTypes = useMemo(() => {
    return data.map((item) => ({ ...item, label: item.DisplayText?.en, value: item.Name } as PanelChoice)) || []
  }, [data])
  const onClickItem = useCallback(() => {
    onClick?.(item, index)
  }, [index])
  const onRemoveItem = useCallback(() => {
    onRemove?.(item, index)
  }, [item, index])
  const onChangeQrType = useCallback(
    (item) => {
      const data = {
        FunctionalityType: item.FunctionalityType,
        QrType: item.value,
        CanSave: item.FunctionalityType === QRCodeFunctionType.Announcements,
      }
      if (item.Icon) {
        Object.assign(data, { Icon: item.Icon })
      }
      onChange?.(data, index)
    },
    [index]
  )
  const onChangeIcon = useCallback(
    (value) => {
      onChange?.({ ...value }, index)
    },
    [index]
  )
  const onChangeName = useCallback(
    (event) => {
      onChange?.({ Name: event.target.value }, index)
    },
    [index]
  )

  const iconTypeArr = useMemo(() => [...new Set(icons.map((v) => v.Section))], [icons])

  return (
    <div className={classes.container}>
      {showEditBtn && (
        <Box className={classes.editBox}>
          <div {...dragHandleProps} className={classes.box}>
            <DragHandle htmlColor="#466CB5" />
          </div>
          <IconButton disableRipple classes={{ root: classes.btnRoot }} onClick={onClickItem}>
            <Edit htmlColor="#466CB5" />
          </IconButton>
        </Box>
      )}

      <PanelSelection
        disabled={disabled}
        value={qrTypes.filter((item: PanelChoice) => item.value === QrType)?.[0]}
        onChange={onChangeQrType}
        choices={qrTypes}
        label="QR Type"
        width={'100%'}
        height={64}
        className={classes.panelSelection}
        popupIcon={<ExpandMore />}
        inputLabelClass={classes.inputLabel}
      />

      <IconSelection
        disabled={disabled}
        value={Icon || ''}
        defaultIcon={DefaultIcon || ''}
        onChange={onChangeIcon}
        choices={icons}
        iconTypeArr={iconTypeArr}
        fill={SVGFill}
        background={SVGBackground}
      />

      <TextField
        value={Name}
        disabled={disabled || FunctionalityType === QRCodeFunctionType.RoundInspection}
        onChange={onChangeName}
        placeholder="Enter here"
        label={`Button ${index + 1}`}
        variant="outlined"
        className={classes.panelSelection}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classnames(classes.input, { [classes.redInput]: !item.CanSave }),
          },
        }}
      />

      {showEditBtn && (
        <IconButton disableRipple classes={{ root: classes.btnRoot }} onClick={onRemoveItem} disabled={disabled}>
          <img src={Images.iconDelete} alt="delete" />
        </IconButton>
      )}
    </div>
  )
}

export default EditButtonItem
