import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, IconButton, TextField, Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { CloseSharp, ExpandMore } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import CustomTextField from '@component/Form/CustomTextField'
import useSetState from '@hooks/useSetState'
import { isEmail } from '@utils/helpers'
import Autocomplete from '@mui/material/Autocomplete'
import { createSubscription, getSubscriptionTypes, updateSubscription, GetPropertyContacts } from '@api'
import SnackBar from '@component/SnackBar'
import { Loading } from 'ra-ui-materialui'
import { getTableList } from '@pages/Dashboard'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      flex: 1,
    },
    header: {
      height: 48,
      display: 'flex',
      alignItems: 'center',
    },
    headerText: {
      flex: 1,
      fontSize: 20,
      fontWeight: 600,
      color: '#211F1F',
    },
    outlined: {
      height: 56,
      borderRadius: 4,
    },
    label: {
      color: '#211F1F',
    },
    margin: {
      marginTop: 24,
    },
    btnContainer: {
      flex: 1,
      paddingBottom: 12,
      alignItems: 'flex-end',
      display: 'flex',
      alignSelf: 'flex-end',
    },
    cancel: {
      marginRight: 20,
      fontSize: 16,
      color: '#707070',
      textTransform: 'none',
      fontWeight: 400,
    },
    add: {
      backgroundColor: '#466CB5',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
      '&:hover': {
        backgroundColor: '#466CB5',
      },
    },
    from: {
      color: '#000',
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '26px',
      marginTop: 24,
    },
  }
})

const FREQUENCY = [
  { id: 'Daily', name: 'Daily' },
  { id: 'Weekly', name: 'Weekly' },
  { id: 'Monthly', name: 'Monthly' },
  { id: 'Quarterly', name: 'Quarterly' },
  { id: 'AdHoc', name: 'AdHoc' },
]

interface SubscriptionTypeITF {
  Type: string
  ReportName: string
}
const SubscriptionCreate: React.FC<{
  value: any
  onClose: () => void
  onSuccess: () => void
  type: string
  name: string
  hasBid: boolean
}> = ({ value, onClose, onSuccess, type, name }) => {
  const {
    selectedGroupId,
    user,
    selectedPropertyId,
    propertyTag: { PropertyTagId: ptId = '' } = {},
  } = useSelector((state) => state.profile)
  const [types, setTypes] = useState<Array<SubscriptionTypeITF>>()
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const classes = useStyles()
  const [state, setState] = useSetState({
    ReportName: value?.ReportName || name || '',
    Type: value?.Type || type || '',
    Email: value?.Email || '',
    Name: value?.Name || '',
    Frequency: value?.Frequency || '',
    RecipientId: value?.RecipientId || '',
  })
  const [contacts, setContacts] = useState<Record<string, any>[]>([])
  const [from, setFrom] = useState(value && !value?.RecipientId ? 'manually' : 'contacts')
  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }
  const onClick = useCallback(async () => {
    try {
      const params = { GroupId: currentGroupId, ptId }
      Object.assign(params, state)
      const api = value ? updateSubscription : createSubscription
      if (value) {
        Object.assign(params, { SubscriptionId: value.id })
      } else {
        Object.assign(params, { BuildingId: selectedPropertyId })
      }
      const item = getTableList().find((item) => item.value === state.Type && item.emailSendUrl)
      if (item) {
        Object.assign(params, {
          Path: `${item.path.substring(1)}/${selectedPropertyId}`,
        })
      }
      await api(params)
      onSuccess?.()
      SnackBar({ msg: 'Successfully saved', type: 'success' })
    } catch (e) {
      SnackBar({ msg: e })
    }
  }, [selectedPropertyId, currentGroupId, state, onClose, value])
  const getTypes = useCallback(async () => {
    try {
      const result = await getSubscriptionTypes<Array<SubscriptionTypeITF>>({})
      setTypes(result)
    } catch (e) {
      SnackBar({ msg: e })
    }
  }, [])
  useEffect(() => {
    getTypes()
  }, [getTypes])
  const { ReportName, Type, Name, Email, Frequency, RecipientId } = state
  const disabledBtn = useMemo(
    () => !ReportName || !Type || !Name || !isEmail(Email) || !Frequency,
    [ReportName, Type, Name, Email, Frequency]
  )
  const onlyContact = getTableList()
    .filter((v) => v.emailSendUrl)
    .map((v) => v.value)
    .includes(Type)

  useEffect(() => {
    GetPropertyContacts({
      filter: JSON.stringify({ bId: selectedPropertyId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      setContacts(res.Contacts)
    })
  }, [selectedPropertyId])

  useEffect(() => {
    if (onlyContact && from === 'manually') {
      setFrom('contacts')
      setState({ Name: '', Email: '' })
    }
  }, [onlyContact, from])

  if (!types) return <Loading />
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.headerText}>{`${value ? 'Update ' : 'Add New'} Subscription`}</Typography>
        <IconButton onClick={onClose}>
          <CloseSharp />
        </IconButton>
      </Box>
      <Autocomplete
        disableClearable
        disabled={!value && !!type}
        defaultValue={types.find((item) => item.Type === (type || Type))}
        classes={{ root: classes.margin }}
        popupIcon={<ExpandMore htmlColor="#466CB5" />}
        onChange={(event: any, newValue) => {
          setState({ ReportName: newValue.ReportName, Type: newValue.Type })
        }}
        getOptionLabel={(option) => option.ReportName || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: { root: classes.outlined },
              readOnly: true,
            }}
            label="Report Title"
          />
        )}
        options={types}
      />

      <Autocomplete
        disableClearable
        defaultValue={FREQUENCY.find((item) => item.id === Frequency)}
        classes={{ root: classes.margin }}
        popupIcon={<ExpandMore htmlColor="#466CB5" />}
        onChange={(event: any, newValue) => {
          onInput(newValue.id, 'Frequency')
        }}
        getOptionLabel={(option) => option.name || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: { root: classes.outlined },
              readOnly: true,
            }}
            label="Frequency"
          />
        )}
        options={FREQUENCY}
      />

      <Typography className={classes.from}>Add subscriber</Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={from}
        onChange={(e) => {
          setFrom(e.target.value)
          setState({ RecipientId: '' })
        }}
      >
        <FormControlLabel value="contacts" control={<Radio />} label="From contacts" />
        {from === 'contacts' && (
          <Autocomplete
            disableClearable
            value={contacts.find((item) => item.UserId === RecipientId) || null}
            popupIcon={<ExpandMore htmlColor="#466CB5" />}
            onChange={(event: any, newValue) => {
              setState({ Name: newValue.FullName?.en || '', Email: newValue.Email || '', RecipientId: newValue.UserId })
            }}
            getOptionLabel={(option) => option?.FullName?.en || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { root: classes.outlined },
                  readOnly: true,
                }}
                label="Select from Contacts"
              />
            )}
            options={contacts}
          />
        )}
        <FormControlLabel value="manually" control={<Radio disabled={onlyContact} />} label="Enter manually" />
        {from === 'manually' && (
          <>
            <CustomTextField
              label="Recipient Name"
              disabled={false}
              fullWidth
              name="Name"
              value={Name}
              onInputChange={onInput}
              variant="outlined"
            />
            <CustomTextField
              className={classes.margin}
              label="Recipient Email"
              disabled={false}
              fullWidth
              name="Email"
              value={Email}
              onInputChange={onInput}
              variant="outlined"
            />
          </>
        )}
      </RadioGroup>

      <Box className={classes.btnContainer}>
        <Button className={classes.cancel} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" className={classes.add} onClick={onClick} disabled={disabledBtn}>
          {`${value ? 'Update ' : 'Add'} Subscription`}
        </Button>
      </Box>
    </Box>
  )
}

export default SubscriptionCreate
