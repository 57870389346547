import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import LockOutlined from '@mui/icons-material/LockOutlined'
import PersonOutline from '@mui/icons-material/PersonOutline'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import LoginInput from '@component/Auth/LoginInput'

const useStyles = makeStyles((theme: Theme) => {
  return {
    title: {
      fontWeight: 'bold',
      fontSize: 38,
      color: '#4268B3',
    },
    subTitle: {
      color: '#949494',
      marginTop: 8,
      marginBottom: 48,
    },
    checkLabel: {
      color: theme.palette.grey[600],
      fontSize: 12,
    },
    password: {
      marginTop: 18,
    },
    forgot: {
      fontSize: 12,
      textTransform: 'none',
      color: '#4268B3',
    },
    signBtn: {
      textTransform: 'none',
      backgroundColor: '#4268B3',
      marginTop: 76,
    },
  }
})

export interface LoginFormInfo {
  email: string
  password: string
  checked: boolean
}

export interface LoginFormProps {
  disabled: boolean
  onSubmit: (value: LoginFormInfo) => void
  onForgot: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({ disabled, onSubmit, onForgot }) => {
  const [email, setEmail] = useState('')
  const [key, setKey] = useState('init')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const onChangeEmail = useCallback(
    (event) => {
      setEmail(event.target.value)
    },
    [setEmail]
  )
  const onChangePassword = useCallback((event) => {
    setPassword(event.target.value)
  }, [])
  const onClickCheck = useCallback(
    (event) => {
      setChecked(event.target.checked)
    },
    [setChecked]
  )
  const onClickSubmit = useCallback(() => {
    onSubmit?.({ email, password, checked })
  }, [email, password, checked])
  const onClickForgot = useCallback(() => {
    onForgot?.()
  }, [onForgot])

  useEffect(() => {
    document.getElementById('password')?.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        onClickSubmit()
      }
    })
  }, [onClickSubmit])

  useEffect(() => {
    const email = localStorage.getItem('user')
    const password = localStorage.getItem('password') || ''
    if (email) {
      setEmail(email)
      setPassword(decodeURIComponent(password))
      setChecked(true)
    }
  }, [])

  const classes = useStyles({})

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5" className={classes.title}>
        Welcome!
      </Typography>
      <Typography component="h6" variant="subtitle2" className={classes.subTitle}>
        Please enter your details.
      </Typography>
      <LoginInput
        id="email"
        placeholder="Email Address"
        name="email"
        onChange={onChangeEmail}
        icon={<PersonOutline htmlColor="#fff" />}
        value={email}
        autoComplete="off"
      />
      <LoginInput
        key={`${key}-password`}
        name="password"
        placeholder="Password"
        id="password"
        type="password"
        className={classes.password}
        value={password}
        onChange={onChangePassword}
        autoComplete="new-password"
        icon={<LockOutlined htmlColor="#fff" />}
      />
      <Grid container alignItems="center" justifyContent="space-between" sx={{ marginTop: '12px' }}>
        <Grid item>
          <FormControlLabel
            classes={{ root: classes.checkLabel }}
            control={<Checkbox value="remember" color="primary" onClick={onClickCheck} />}
            label="Keep me logged in"
          />
        </Grid>
        <Grid item>
          <Button variant="text" classes={{ root: classes.forgot }} onClick={onClickForgot}>
            Forgot Password?
          </Button>
        </Grid>
      </Grid>
      <Button
        disabled={disabled}
        onClick={onClickSubmit}
        fullWidth
        color="primary"
        classes={{
          root: classes.signBtn,
        }}
        variant="contained"
      >
        Sign In
      </Button>
    </Box>
  )
}

export default LoginForm
