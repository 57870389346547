import React from 'react'
import { CustomerFeedback, LanguageType } from '@activate-inc/activate-ui'
import { Question } from '@activate-inc/activate-ui/dist/types/components/Form/RatingForm/RatingForm.types'
import { makeStyles } from '@mui/styles'
interface Props {
  data: Question[]
  language: LanguageType
  qrTypeName: string
}
const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiGrid-root .MuiGrid-item': {
      // '& button':{
      //   width: 40,
      //   height: 40
      // }
    },
  },
}))
const WrapCustomerFeedback: React.FC<Props> = ({ language, data, qrTypeName }) => {
  const clasess = useStyles()
  return (
    <div className={clasess.container}>
      <CustomerFeedback data={data || []} language={language} qrTypeName={qrTypeName} />
    </div>
  )
}
export default WrapCustomerFeedback
