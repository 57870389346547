import * as React from 'react'
import { useEffect, useState } from 'react'
import { useRedirect } from 'react-admin'
import { Box, Button, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AddCircleOutlineRounded, Replay } from '@mui/icons-material'
import EngineeringRoundsList from './table/EngineeringRoundsTable'
import { useEvent } from '@hooks/useEvent'
import { PUSHER_EVENT } from '@constant/app'
import SnackBar from '@component/SnackBar'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#f5f5f5',
    flex: 1,
    padding: '16px 24px',
  },
  tab: {
    fontSize: 14,
    padding: 0,
    textTransform: 'none',
    marginRight: 16,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tabs: {
    flex: 1,
    borderBottom: '1px solid #ccc',
  },
  button: {
    fontSize: 14,
    height: 40,
    marginLeft: 16,
  },
  replay: {
    marginLeft: 16,
    padding: 8,
    backgroundColor: '#D4E2EF',
  },
}))

const RoundsEngine = () => {
  const classes = useStyles()
  const redirectTo = useRedirect()
  const [reload, setReload] = useState(false)
  const { event } = useEvent(PUSHER_EVENT.savedround)
  useEffect(() => {
    if (event) {
      setReload((v) => !v)
    }
  }, [event])
  const onAdd = () => {
    redirectTo('/roundsEngineBuilder')
  }

  const onReload = () => setReload((v) => !v)

  return (
    <Box className={classes.paper}>
      <Box className={classes.title}>
        <IconButton className={classes.replay} onClick={onReload}>
          <Replay htmlColor="#1976D2" />
        </IconButton>

        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.button}
          endIcon={<AddCircleOutlineRounded />}
          onClick={onAdd}
        >
          CREATE NEW ROUND
        </Button>
      </Box>

      <EngineeringRoundsList reload={reload} />
    </Box>
  )
}

export default RoundsEngine
