import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  userToken: '',
}

let authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    // fetchUserInfoProcessing: state => ({
    //     ...state,
    //     isLoading: true,
    // }),
    // fetchUserInfoSuccess: (state, action) => ({
    //     ...state,
    //     userInfo: action.payload,
    // }),
    setUserToken: function (state, action) {
      state.userToken = action.payload
    },
    resetReducer: () => INITIAL_STATE,
  },
  // sagas: (A) => ({
  //   *[A.setUserToken]({ payload }) {
  //       // const response = yield call(confApi.admin.getEnvironments());
  //       // const { environments } = yield call(getData(response));
  //       // yield put(A.getAllSuccess(environments));
  //   }
})

export const { setUserToken } = authSlice.actions
export default authSlice.reducer
