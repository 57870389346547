import { Box } from '@mui/material'
import PanelSelection from '@component/EditPanel/PanelSelection'
import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { getQrCodesByGroupId } from '@api'
import useSetState from '@hooks/useSetState'
import { PanelChoice } from '@/types/panel'
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
interface QrSelectProps {
  qrId?: string
  label?: string
  qrType?: string
  location?: string
  bId?: string
  gId?: string
  onChange?: (value: string, data: any) => void
}

const QrSelect: React.FC<QrSelectProps> = ({ label = 'QR Code', qrType, bId, gId, location, qrId, onChange }) => {
  const classes = useStyles()
  const [data, setData] = useSetState<{ list: Array<PanelChoice>; currentId?: string }>({ list: [], currentId: qrId })
  const { list, currentId } = data
  const getData = useCallback(() => {
    const filter = {
      EntityType: location,
      QrType: qrType,
      gId,
      bId,
    }

    const query = {
      pagination: JSON.stringify({ page: 0, perPage: 9999 }),
      range: JSON.stringify([0, 9999]),
      filter: JSON.stringify(filter),
    }
    getQrCodesByGroupId(query).then((res: any) => {
      setData({ list: (res?.QrCodes || [])?.map((item) => ({ label: item.Name, value: item.id, ...item })) })
    })
  }, [bId, gId, location, qrType, setData])
  const onChangeQr = useCallback(
    (value: PanelChoice | null) => {
      const id = value?.value as string
      setData({ currentId: id })
      onChange?.(id, value)
    },
    [setData, onChange]
  )
  useEffect(() => {
    getData()
  }, [getData])
  const value = (list || []).find((item) => item.value === currentId) || null
  const pngUrl = (value?.ImageUrl as string)?.replace(/(.eps)|(.pdf)/g, '.png')

  return (
    <Box className={classes.container}>
      <PanelSelection choices={list || []} value={value} height={50} label={label} width="100%" onChange={onChangeQr} />
      {!!pngUrl && (
        <Box sx={{ height: 140, alignSelf: 'center', marginTop: '20px' }}>
          <img src={pngUrl} style={{ width: 140, height: 140, backgroundSize: 'contain' }} />
        </Box>
      )}
    </Box>
  )
}

export default QrSelect
