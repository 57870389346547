import React from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import BackButton from '@component/BackButton/BackButton'
import { TabPanel } from '@component/Tabs/TabPanel'
import TwoQrContent from '@pages/twoQRLibrary/components/TwoQrContent'
import { useParams } from 'react-router-dom'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: 20,
  },
  tabSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    background: '#fff',
    borderBottom: '1px solid #BCBCBC',
    marginBottom: 16,
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: 'Roboto',
    height: 20,
    lineHeight: 20,
    borderRadius: 4,
    opacity: '1',
  },
  tabPanel: {
    display: 'flex',
    background: '#fff',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 16px',
  },
}))
const TwoQREdit: React.FC = () => {
  const classes = useStyles()
  const { pId = '' } = useParams<Record<string, string>>()
  const [tabId, setTabId] = React.useState(0)
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabId(newValue)
  }
  return (
    <Box className={classes.root}>
      <BackButton title="Build a New 2 QR Code" />
      <Tabs value={tabId} onChange={handleTabChange} className={classes.tabSection}>
        <Tab label="Content" value={0} className={classes.tabLabel} />
      </Tabs>
      <TabPanel value={tabId} index={0}>
        <TwoQrContent pId={pId} />
      </TabPanel>
    </Box>
  )
}

export default TwoQREdit
