import React from 'react'
import { Box } from '@mui/material'
import { HtmlViewer } from '@activate-inc/activate-ui'

interface Props {
  isHubPanel: boolean
  template: any
  qrTypeName: string
}

const HtmlWrapper: React.FC<Props> = ({ template, qrTypeName }) => {
  const htmlContent = template?.HtmlContent
  return (
    <Box>
      <HtmlViewer title={qrTypeName} htmlContent={htmlContent} />
    </Box>
  )
}

export default HtmlWrapper
