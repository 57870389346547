import React, { useState, useCallback, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { AnnouncementForm } from '@activate-inc/activate-ui'
import { getAnnouncement } from '@api'

const mockData = [
  {
    AnnouncementId: '10273eb0-c08a-11ec-aa57-43916ebe48f2',
    Title: 'Office Space available for Lease',
    Text: 'Contact: (312) 381-1000',
  },
  {
    AnnouncementId: '11273eb0-c08a-11ec-aa57-43916ebe48f2',
    Title: 'Manager Schedual',
    Text: 'carld.paile@gmail.com',
  },
]
const AnnouncementFormWrapper = ({ entityId }) => {
  const [data, setData] = useState()
  const getData = useCallback(async () => {
    try {
      if (!entityId) return
      const { Announcements } = (await getAnnouncement({ eId: entityId, take: 100, skip: 0 })) as any
      // @ts-ignore
      setData(Announcements)
    } catch (e) {
      console.log(e)
    }
  }, [entityId])

  useEffect(() => {
    getData()
  }, [getData])

  if (!Array.isArray(data)) {
    return <Typography align="center">Data load failed</Typography>
  }
  return (
    <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
      <AnnouncementForm data={data} />
    </Box>
  )
}

export default AnnouncementFormWrapper
