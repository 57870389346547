import * as React from 'react'
import { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid, Typography, Theme, Autocomplete, TextField, InputAdornment, Button } from '@mui/material'
import Card from '@mui/material/Card'
import { makeStyles } from '@mui/styles'
import EmptyDisplay from '@component/Empty/EmptyDisplay'
import { COLOR } from '@constant/app'
import { AccountSearch } from '@/dashboard/AdminSearch'
import { PropertySearch } from '@/dashboard/PropertySearchBar'
import PeriodSelect from '@component/DateSelect/PeriodSelect'
import useSetState from '@hooks/useSetState'
import { DEFAULT_PERIOD_LAST_YEAR } from '@/global'

import { getScanStats } from '@api/index'
import { IEntity } from '@/types'
import { GroupITF } from '@/types/group'
import { QRLimit } from '@constant/qrcode'
import { useGetQRTypes } from '@hooks/useGetQRTypes'
import { ExpandMore, Search } from '@mui/icons-material'
import Images from '@/assets/images'
import ScansModal from '@component/Modal/ScansModal'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 1648,
    flex: 1,
    flexDirection: 'column',
    fontFamily: 'Roboto',
    padding: '0 24px 24px',
    backgroundColor: '#f5f5f5',
  },
  title: {
    fontSize: 32,
    color: '#000',
    borderBottom: '1px solid #BCBCBC',
    marginBottom: 24,
  },
  tabs: {
    borderBottom: '1px solid #BCBCBC',
  },
  tab: {
    minWidth: 66,
    height: 26,
    fontSize: 16,
    fontFamily: 'Roboto, Regular',
    textTransform: 'capitalize',
    color: COLOR.grey78,
    ' & .MuiTab-textColorInherit .Mui-selected': {
      color: '#386bbf',
      fontFamily: 'Roboto, Bold',
    },
  },
  sectonBox: {
    padding: '24px 0 30px 0',
    minHeight: 'calc(100% - 48px)',
    boxSizing: 'border-box',
  },
  sectionTitle: {
    color: '#211F1F',
    fontSize: '24px',
    fontWeight: 600,
    marginBottom: 24,
  },
  gridCantainer: {
    padding: '0px 0px 20px 0px',
  },
  scanBox: {
    height: 147,
    width: 380,
    border: 'none',
    borderRadius: 12,
  },
  scanBoxButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 147,
    width: 380,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
    padding: 24,
  },
  scanBoxText: {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  upText: {
    fontSize: 20,
    fontFamily: 'Roboto',
    color: '#707070',
    fontWeight: 'bold',
    lineHeight: '26px',
  },
  downText: {
    fontFamily: 'Roboto',
    fontSize: 50,
    color: '#211F1F',
    fontWeight: '600',
    lineHeight: '55px',
  },
  header: {
    padding: '8px 0 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '0 24px',
  },
  textField: {
    height: 56,
    backgroundColor: '#f7f7f7',
    '&[class*=MuiOutlinedInput-root]': {
      padding: '9px 9px',
      fontSize: 16,
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 16,
    },
  },
}))

interface ScanItemProps {
  isQrName?: boolean
  item: IScanData
  gId: string
  bId: string
  type: string
}
interface ScanSectionProps {
  scanItems: IScanData[]
  isQrName: boolean
  gId: string
  bId: string
  qr: string
}

interface IScanData {
  DisplayText: string
  Location?: string
  QrName?: string
  ScanCount: number
}

const ScanBox: React.FC<ScanItemProps> = ({ item, isQrName, gId, bId, type }) => {
  const classes = useStyles()
  const { DisplayText, ScanCount, Location, QrName } = item
  const [show, setShow] = useState(false)
  const onClick = () => {
    setShow(true)
  }
  const onClose = () => {
    setShow(false)
  }
  return (
    <Card className={classes.scanBox}>
      {show && <ScansModal onClose={onClose} bId={bId} gId={gId} type={type} qr={QrName} lt={Location} />}
      <Button className={classes.scanBoxButton} onClick={onClick}>
        <img src={isQrName ? Images.qrName : Images.location} alt="logo" />
        <Box className={classes.scanBoxText}>
          <Typography className={classes.upText}>{DisplayText}</Typography>
          <Typography className={classes.downText}>{ScanCount}</Typography>
        </Box>
      </Button>
    </Card>
  )
}

const ScanSection: React.FC<ScanSectionProps> = ({ isQrName, scanItems, gId, bId, qr }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={3} className={classes.gridCantainer}>
      {scanItems.map((item, index) => (
        <Grid item md={3} sm={6} xs={12} key={index}>
          <ScanBox item={item} isQrName={isQrName} gId={gId} bId={bId} type={qr} />
        </Grid>
      ))}
    </Grid>
  )
}

const ScanReport = (props: any) => {
  const { type, isPuppeteer } = props
  const classes = useStyles()
  const value = type || 'Location'
  const [scanData, setScanData] = useState<IScanData[]>([])
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const currentGroupId = props?.gId || selectedGroupId || ''
  const currentPropertyId = props?.bId || selectedPropertyId || ''
  const { data: qrTypeList } = useGetQRTypes(QRLimit.QrCode, currentPropertyId)
  const [data, setData] = useSetState({
    gId: currentGroupId,
    bId: currentPropertyId,
    period: DEFAULT_PERIOD_LAST_YEAR,
    qrType: '',
    search: '',
    st: '',
  })
  const { period, gId, bId, qrType, search, st } = data
  const tu = props.tu || period.value
  const isQrName = value === 'QrName'
  const timerRef = useRef<NodeJS.Timeout>()
  const fetchData = useCallback(async () => {
    const countData = await getScanStats<IScanData[]>({
      gId,
      bId,
      tu,
      gy: value,
      type: qrType,
      st,
    })

    setScanData(countData)
  }, [gId, bId, tu, value, qrType, st])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onChangeAccount = useCallback(
    (account: GroupITF) => {
      setData({ gId: account.acId, bId: '' })
    },
    [setData]
  )

  const onChangeProperty = useCallback(
    (property: IEntity) => {
      setData({ bId: property.id })
    },
    [setData]
  )

  const onChange = useCallback(
    (value) => {
      setData({ period: value })
    },
    [setData]
  )
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value
    setData({ search: value })
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setData({ st: value })
    }, 3000)
  }

  return (
    <Box className={isPuppeteer ? '' : classes.root}>
      {!isPuppeteer && (
        <Box className={classes.header} style={{ gridTemplateColumns: isQrName ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)' }}>
          <AccountSearch
            label="Account"
            onChange={onChangeAccount}
            gId={gId || ''}
            inputRootClass={classes.textField}
          />
          <PropertySearch
            label="Building"
            gId={gId || ''}
            bId={bId || ''}
            onChange={onChangeProperty}
            inputRootClass={classes.textField}
          />
          <PeriodSelect
            period={period}
            onChange={onChange}
            label="Date"
            className={classes.textField}
            style={{ width: '100%' }}
          />
          <Autocomplete
            value={qrTypeList.find((v) => v.Name === qrType) || null}
            onChange={(event, newValue) => setData({ qrType: newValue?.Name || '' })}
            className={classes.textField}
            classes={{ inputRoot: classes.textField }}
            options={qrTypeList}
            getOptionLabel={(option) => option.DisplayText?.en || ''}
            renderInput={(params) => <TextField {...params} label="QR Type" variant="outlined" />}
            popupIcon={<ExpandMore />}
          />
          <TextField
            value={search}
            onChange={(e) => onSearchChange(e)}
            className={classes.textField}
            classes={{ root: classes.textField }}
            style={{ display: isQrName ? '' : 'none' }}
            label=""
            variant="outlined"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <Box className={classes.sectonBox}>
        {scanData?.length ? (
          <ScanSection scanItems={scanData} isQrName={isQrName} gId={gId} bId={bId} qr={qrType} />
        ) : null}
        {scanData?.length === 0 && (
          <Box style={{ paddingTop: '20%' }}>
            <EmptyDisplay />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ScanReport
