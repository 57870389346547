import React from 'react'
import { FieldForm } from '@activate-inc/activate-ui'

const CONFIG = [
  {
    Required: true,
    Code: 'Q001',
    FieldText: {
      en: 'Vendor name',
      es: 'Vendor name',
    },
    ValueType: 'Text',
    FieldType: 'Name',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
  {
    Required: true,
    Code: 'Q002',
    FieldText: {
      en: 'Vendor email',
      es: 'Vendor email',
    },
    ValueType: 'Text',
    FieldType: 'Email',
    FieldStyle: 'Text',
    Choices: [],
    ValueTexts: [],
    ValueChoices: [],
  },
]
const NewSupplierForm = ({ entityId }) => {
  return (
    <FieldForm
      isCenter={false}
      qrTypeName="Create New Vendor"
      data={CONFIG}
      language="en"
      onSubmit={() => {}}
      btnWidth="100px"
      disabledVerify
    />
  )
}

export default NewSupplierForm
