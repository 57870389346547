import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { List } from '@activate-inc/activate-ui'

import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  GetAppRounded as GetAppRoundedIcon,
  PrintOutlined as PrintOutlinedIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material'
import { getGenericEntitiesForFeedback } from '@api'
import { Typography, Box, Button } from '@mui/material'

// Styles
import useStyles from './listStyles'
import SnackBar from '@component/SnackBar'

const mockList = {
  data: [
    {
      _id: '6220310de7a1f5e33b760913',
      Code: '001-F3-BR-GE',
      GroupId: 'b122bfb0-897b-11ec-93e3-6b972f21ca55',
      BuildingId: '25594480-897c-11ec-a290-75ea66124e77',
      CreatedDate: 1646276877314,
      Functionalities: [
        {
          Type: 'Feedback',
          Access: 'Public',
          TemplateId: '2f66f7b0-897c-11ec-981b-61b87ef75942',
        },
      ],
      IsGeneric: true,
      ModifiedDate: 1646294174701,
      Name: {
        en: '3rd floor NE Bathroom',
        zh: '3rd floor NE Bathroom',
      },
      ProvisionId: '1d468080-9a9f-11ec-9413-bffaa0b795a7',
      Status: 'Active',
      Type: 'Bathroom',
      __v: 0,
      acId: '20188c40-9a9f-11ec-9413-bffaa0b795a7',
    },
  ],
}

interface RatingListWrapperProps {
  title?: string
  buildingId?: string
  entityName?: string
  onChangeItem?: (data: any) => void
}

const RatingListWrapper: React.FC<RatingListWrapperProps> = ({ title, buildingId, entityName, onChangeItem }) => {
  const classes = useStyles()
  const [data, setData] = useState()
  const getData = useCallback(async () => {
    try {
      if (!buildingId || !entityName) return
      const result = await getGenericEntitiesForFeedback({ bId: buildingId, cet: entityName })
      // @ts-ignore
      setData(result)
    } catch (e) {
      SnackBar({ msg: e })
    }
  }, [buildingId, entityName])

  const onRowClick = useCallback((item) => {
    const feedBack = item.Functionalities?.find((item) => item.Type === 'Feedback')
    if (!feedBack) return
    //@ts-ignore
    onChangeItem(feedBack)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <div className={classes.contentPanel}>
      <List
        subheader={title || 'Feedback on something else?'}
        data={data || []}
        convertItem={(item, index) => {
          return {
            id: item.acId + index,
            primary: item.Type,
          }
        }}
        onClick={onRowClick}
      />
    </div>
  )
}

export default RatingListWrapper
