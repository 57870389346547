import tinycolor from 'tinycolor2'

const primary = '#386BBF'
const secondary = '#386BBF'
const warning = '#FFC260'
const success = '#3CD4A0'
const info = '#9013FE'
const brandIcon = '#1D5075'
const brandColor = '#386BBF'

const lightenRate = 7.5
const darkenRate = 15

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: '#FFFFFF',
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    brandIcon: {
      main: brandIcon,
      light: tinycolor(brandIcon).lighten(lightenRate).toHexString(),
      dark: tinycolor(brandIcon).darken(darkenRate).toHexString(),
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
      brandColor: brandColor,
    },
    background: {
      default: '#FFF',
      light: '#FFF',
    },
  },
  customShadows: {
    widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: '#919191',
        borderColor: '#c6c6c6',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: '"Proxima-Nova",sans-serif',
          fontStyle: 'normal',
          fontWeight: '400',
          backgroundColor: '#F7F7F7',
          fontSize: 16,
        },
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: '"Proxima-Nova",sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: '"Proxima-Nova",sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 2,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiOutlinedInput: {
      multiline: {
        borderColor: '#707070',
        borderRadius: 4,
        padding: 10,
        fontFamily: 'Proxima-Nova",sans-serif',
        fontSize: 'initial',
        fontWeight: 'initial',
        lineHeight: 'initial',
        border: 'none',
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#707070',
        },
      },
    },
    MuiTab: {
      root: {
        fontFamily: '"Proxima-Nova",sans-serif',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 20,
        textTransform: 'capitalize',
        padding: 12,
        minWidth: 130,
        letterSpacing: '.2px',
        color: '#787D80 !important',
        '&$selected': {
          fontWeight: '600 !important',
          color: '#386BBF !important',
        },
      },
    },

    MuiButton: {
      label: {
        textTransform: 'capitalize',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: '"Proxima-Nova",sans-serif',
        fontStyle: 'normal',
        fontSize: 18,
        fontWeight: '400',
        color: '#374053',
      },
    },
    MuiBox: {
      root: {
        padding: '14px 30px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiSelect: {
      icon: {
        color: '#B9B9B9',
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: 50,
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: ' 0px 0px 3px #00000029',
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        '&$selected': {
          backgroundColor: '#F3F5FF !important',
          '&:focus': {
            backgroundColor: '#F3F5FF',
          },
        },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#F3F5FF',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
        paddingLeft: 24,
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
      },
    },
  },
}
export default defaultTheme
