import { makeStyles } from '@mui/styles'
import StatusBar from './status-bar.png'

const WIDTH = 320
const HEIGHT = 551
const SCALE = 0.82
export default makeStyles(() => ({
  mobileFrameContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: (props) => props.height || HEIGHT,
    width: (props) => props.width || WIDTH,
  },
  mobileFrame: {
    position: 'absolute',
    overflow: 'hidden',
    background: '#f5f5f5',
    border: '12px solid #000',
    boxSizing: 'border-box',
    borderRadius: (props) => props.radius || 48,
    height: (props) => (props.height || HEIGHT) / (props.scale || SCALE),
    width: (props) => (props.width || WIDTH) / (props.scale || SCALE),
    transform: (props) => `scale(${props.scale || SCALE})`,
    transformOrigin: 'top left',
    paddingBottom: 20,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },

    // '&::-webkit-scrollbar': {
    //   width: '2px !important'
    // },
    // '&::-webkit-scrollbar-track': {
    //   width: '2px !important',
    //   background: '#E4EFEF !important'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   width: '2px !important',
    //   background: '#9CAAD961',
    //   borderRadius: '4px'
    // },
  },
  statusBar: {
    marginLeft: 10,
    marginRight: 10,
    backgroundImage: `url(${StatusBar})`,
    height: 24,
    maxWidth: 300,
    minWidth: 300,
  },
  navBar: {
    width: 320,
    maxWidth: 320,
    background: 'red',
  },
  container: {
    // display: 'flex',
    height: '100%',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    overflowX: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 8,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: 'white',
    position: 'relative',
  },
  logo: {
    maxWidth: '60%',
    height: 40,
  },
  back: {
    position: 'absolute',
    left: 15,
    top: '48%',
    cursor: 'pointer',
  },
}))
