/**
 * 压缩图片方法
 * @param {file} file 文件
 * @param {Number} quality 图片质量(取值0-1之间默认0.92)
 * https://juejin.cn/post/6940430496128040967
 */

export default function compressImg(
  file: File,
  quality?: number
): Promise<{
  file: File
  origin: File
}> {
  const { size, name, type } = file
  const KB = Number((size / 1024).toFixed(2))
  let qualitys = 0.52
  if (KB < 1024) {
    qualitys = 0.85
  }
  if (5 * 1024 < KB) {
    qualitys = 0.92
  }
  if (quality) {
    qualitys = quality
  }

  return new Promise((resolve) => {
    if (KB < 300) {
      resolve({ file, origin: file })
    } else {
      const reader = new FileReader() // 创建 FileReader
      //@ts-ignore
      reader.onload = ({ target: { result: src } }) => {
        const image = new Image() // 创建 img 元素
        image.onload = async () => {
          let canvas = document.createElement('canvas') // 创建 canvas 元素
          const context = canvas.getContext('2d') as CanvasRenderingContext2D
          let targetWidth = image.width
          let targetHeight = image.height
          const originWidth = image.width
          const originHeight = image.height
          if (1 * 1024 <= KB && KB <= 10 * 1024) {
            const maxWidth = 1600
            const maxHeight = 1600
            targetWidth = originWidth
            targetHeight = originHeight
            // 图片尺寸超过的限制
            if (originWidth > maxWidth || originHeight > maxHeight) {
              if (originWidth / originHeight > maxWidth / maxHeight) {
                // 更宽，按照宽度限定尺寸
                targetWidth = maxWidth
                targetHeight = Math.round(maxWidth * (originHeight / originWidth))
              } else {
                targetHeight = maxHeight
                targetWidth = Math.round(maxHeight * (originWidth / originHeight))
              }
            }
          }
          if (10 * 1024 <= KB && KB <= 20 * 1024) {
            const maxWidth = 1400
            const maxHeight = 1400
            targetWidth = originWidth
            targetHeight = originHeight
            // 图片尺寸超过的限制
            if (originWidth > maxWidth || originHeight > maxHeight) {
              if (originWidth / originHeight > maxWidth / maxHeight) {
                // 更宽，按照宽度限定尺寸
                targetWidth = maxWidth
                targetHeight = Math.round(maxWidth * (originHeight / originWidth))
              } else {
                targetHeight = maxHeight
                targetWidth = Math.round(maxHeight * (originWidth / originHeight))
              }
            }
          }
          canvas.width = targetWidth
          canvas.height = targetHeight
          context.clearRect(0, 0, targetWidth, targetHeight)
          context.drawImage(image, 0, 0, targetWidth, targetHeight) // 绘制 canvas
          const canvasURL = canvas.toDataURL('image/jpeg', qualitys)
          const buffer = atob(canvasURL.split(',')[1])
          let length = buffer.length
          const bufferArray = new Uint8Array(new ArrayBuffer(length))
          while (length--) {
            bufferArray[length] = buffer.charCodeAt(length)
          }
          const miniFile = new File([bufferArray], name, {
            type,
          })
          console.log({
            file: miniFile,
            origin: file,
            beforeSrc: src,
            afterSrc: canvasURL,
            beforeKB: Number((size / 1024).toFixed(2)),
            afterKB: Number((miniFile.size / 1024).toFixed(2)),
            qualitys: qualitys,
          })
          //@ts-ignore
          canvas = null
          resolve({
            file: miniFile,
            origin: file,
          })
        }
        image.src = src as string
      }
      reader.readAsDataURL(file)
    }
  })
}
