import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePermissions, useRedirect } from 'react-admin'
import { ACTIVATE_COMPANY_LOGO_WHITE } from '@/global'
import { selectedProperty, clearGroup } from '@reducer/profile'
import { AppContext } from '../context/AppContext'

function Logo() {
  const { selectedPropertyId } = useSelector((state) => state.profile)
  const redirectTo = useRedirect()
  const dispatch = useDispatch()
  const { updateGroup } = useContext(AppContext)
  const { permissions } = usePermissions()
  const { GlobalManageCustomers } = permissions || {}

  const handleClick = () => {
    if (GlobalManageCustomers || selectedPropertyId) {
      dispatch(clearGroup({}))
      updateGroup({ currentGroupId: '', currentGroupName: '' })
      dispatch(selectedProperty({ propertyId: '', propertyName: '', buildingCode: '', propertyTag: {} }))
      redirectTo('/adminSearch')
    } else {
      redirectTo('/')
    }
  }

  return (
    <img
      src={ACTIVATE_COMPANY_LOGO_WHITE}
      style={{ height: 57, cursor: 'pointer' }}
      alt="group logo"
      onClick={handleClick}
    />
  )
}
export default Logo
