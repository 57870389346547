import { getPrintTemplates } from '@api'
import SnackBar from '@component/SnackBar'

const cacheData: { [key: string]: any } = {}

export const getCachePrintTemplates = async (qrType: string, location: string | undefined) => {
  try {
    const isFeedBack = qrType === 'Feedback'
    const cacheKey = isFeedBack ? `${qrType}${location}` : qrType
    if (cacheData[cacheKey]) return cacheData[cacheKey]
    const params = isFeedBack ? { qrType, location } : { qrType }
    const result = await getPrintTemplates(params)
    cacheData[cacheKey] = result
    return result
  } catch (e) {
    SnackBar({ msg: e })
  }
}
