import React from 'react'
import { MoveOutForm } from '@activate-inc/activate-ui'
import { QRCodeFunctionType } from '@/types'

const MoveOutWrapper = ({ data, language, qrTypeName, functionalityType }) => {
  return (
    <MoveOutForm
      data={data?.Sections || []}
      language={language}
      qrTypeName={qrTypeName}
      onSubmit={() => {}}
      showCharge={QRCodeFunctionType.MoveOut === functionalityType}
    />
  )
}

export default MoveOutWrapper
