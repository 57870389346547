import * as React from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Divider, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import BackButton from '@component/BackButton/BackButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f7f7f7',
    padding: 16,
  },
  back: {
    margin: '0px 0px 0px 0px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 46,
  },
  title: {
    flex: 1,
    fontFamily: 'Roboto',
    height: '27px',
    color: '#386BBF',
    fontSize: 24,
  },
  container: {
    marginTop: 24,
  },
}))

const Page = (props: any) => {
  const classes = useStyles()
  const { Title, children, hasBack, action } = props
  const { selectedGroupId, user } = useSelector((state) => state.profile)
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  return (
    <Box className={classes.root}>
      {hasBack && <BackButton />}
      <Box className={classes.header}>
        <Typography className={classes.title} variant="h3">
          {Title}
        </Typography>
        {action}
      </Box>
      <Divider />
      <Box className={classes.container}>{children}</Box>
    </Box>
  )
}

export default Page
