import * as React from 'react'
import { memo, isValidElement, ReactElement, useMemo } from 'react'
import { IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslate, useListFilterContext } from 'ra-core'
import { shallowEqual } from 'react-redux'
import matches from 'lodash/matches'
import pickBy from 'lodash/pickBy'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

const useStyles = makeStyles(
  () => ({
    listItem: {
      paddingLeft: 9,
      minHeight: 35,
      width: 200,
    },
    listItemText: {
      color: '#A2A2A2',
      fontSize: 12,
      fontWeight: 400,
      margin: 0,
      wordBreak: 'break-word',
      paddingLeft: 5,
    },
    listItemRoot: {
      flex: 1,
    },
  }),
  { name: 'RaFilterListItem' }
)

const FilterListItem = (props: {
  label: string | ReactElement
  value: any
  keyName: string
  toggle: () => void
  count?: string
}) => {
  const { label, value, toggle, count, keyName } = props
  const { filterValues, setFilters, displayedFilters } = useListFilterContext()
  const translate = useTranslate()
  const classes = useStyles(props)

  const isSelected = useMemo(() => {
    const select = filterValues[keyName]
    if (typeof select === 'object' && select) {
      return matches(pickBy(value, (val) => typeof val !== 'undefined'))(select)
    }
    return filterValues[keyName] === value
  }, [filterValues, value, keyName])

  const addFilter = () => {
    setFilters({ ...filterValues, [keyName]: value }, { ...displayedFilters, [keyName]: label }, false)
  }

  const removeFilter = () => {
    const { [keyName]: _, ...newFilterValues } = filterValues
    const { [keyName]: __, ...newDisplayedFilters } = displayedFilters
    setFilters(newFilterValues, newDisplayedFilters, false)
  }

  const toggleFilter = () => {
    toggle?.()
    isSelected ? removeFilter() : addFilter()
  }

  return (
    <ListItem button onClick={toggleFilter} className={classes.listItem}>
      <IconButton size="small" onClick={toggleFilter}>
        {isSelected ? <CheckBox htmlColor="#386BBF" /> : <CheckBoxOutlineBlank />}
      </IconButton>
      <ListItemText
        primary={isValidElement(label) ? label : translate(label, { _: label })}
        classes={{
          root: classes.listItemRoot,
          primary: classes.listItemText,
        }}
        data-selected={isSelected ? 'true' : 'false'}
      />
      {!!count && <Typography className={classes.listItemText}>({count})</Typography>}
    </ListItem>
  )
}

const arePropsEqual = (prevProps, nextProps) =>
  prevProps.label === nextProps.label && shallowEqual(prevProps.value, nextProps.value)

export default memo(FilterListItem, arePropsEqual)
