import React, { useEffect } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { Typography, Box, TextField, IconButton, Switch } from '@mui/material'
import { DeleteOutline, AddCircle, Reorder } from '@mui/icons-material'
import { DragDropContext, Droppable, DropResult, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { reorder } from '@/utils/helpers'
import { COLOR } from '@constant/app'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'

const language = 'en'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    logoLabel: {
      color: COLOR.grey37,
      fontSize: 12,
      marginTop: 20,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    margin: {
      marginTop: 0,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
    },
    itemText: {
      flex: 1,
    },
    date: {
      margin: '20px 0 0',
    },
    disabled: {
      fontSize: 12,
      color: '#777',
      lineHeight: '21px',
    },
    add: {
      justifyContent: 'flex-start',
      color: '#386BBF',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    underline: {
      '&:before': {
        borderWidth: 0,
      },
    },
  }
})

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
}

const BathroomServicePanel: React.FC<Props> = ({ onChange, value, functionType, disabledEdit = false }) => {
  const classes = useStyles()

  const [{ Fields, FieldText, Choices, AlternateEnable, ExternalUrl }, setState] = useSetState({
    Fields: value?.Fields || [{}],
    FieldText: value?.Fields?.[0]?.FieldText?.[language] || '',
    Choices: value?.Fields?.[0]?.Choices || [
      {
        Label: 'A',
        Text: { en: '', es: '' },
        Value: '',
      },
    ],
    AlternateEnable: value?.AlternateEnable || false,
    ExternalUrl: value?.ExternalUrl || 'https://',
  })

  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }

  const toggleChecked = () => {
    setState({ AlternateEnable: !AlternateEnable })
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Choices || [], source.index, destination.index)
    setState({ Choices: newItems })
  }

  const onRemove = (index: number) => {
    Choices.splice(index, 1)
    setState({ Choices: [...Choices] })
  }

  const onItemChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const { value, name } = event.target
    Choices[index].Text = { en: value, es: value }
    Choices[index].Value = value
    setState({ Choices: [...Choices] })
  }

  const onAdd = () => {
    const item = { ...Choices[0], isAdd: true }
    item.Label = String(Date.now())
    item.Text = { en: '', es: '' }
    item.Value = ''
    Choices.push(item)
    setState({ Choices: [...Choices] })
  }

  useEffect(() => {
    if (onChange) {
      const newData = {
        Fields: [{ ...Fields[0], Choices, FieldText: { en: FieldText, es: FieldText } }].concat(Fields.slice(1)),
        AlternateEnable,
        ExternalUrl,
      }
      Object.assign(newData, { CanSave: isCanSavaQr(functionType, newData) })
      onChange(newData)
    }
  }, [onChange, Fields, FieldText, Choices, AlternateEnable, ExternalUrl])

  return (
    <div className={classes.container}>
      <CustomTextField
        label="Edit Question"
        disabled={disabledEdit}
        value={FieldText}
        placeholder="Enter here"
        name="FieldText"
        onInputChange={onInput}
      />

      <>
        <Box className={classes.logoLabel}>Edit Selection</Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {Choices.map((item, index: number) => (
                  <Draggable key={item?.Code} draggableId={item?.Label} index={index} isDragDisabled={disabledEdit}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                      >
                        <Box className={classes.item}>
                          <TextField
                            className={classes.itemText}
                            label={''}
                            disabled={disabledEdit}
                            multiline
                            value={item?.Value || ''}
                            InputProps={{
                              classes: {
                                underline: classes.underline,
                              },
                            }}
                            InputLabelProps={{ shrink: false }}
                            onChange={(e) => onItemChange(e, index)}
                            variant="standard"
                            placeholder="Enter here"
                          />
                          <IconButton disableRipple onClick={() => onRemove(index)} disabled={disabledEdit}>
                            <DeleteOutline />
                          </IconButton>
                          <div {...draggableProvided.dragHandleProps}>
                            <IconButton disabled={disabledEdit}>
                              <Reorder />
                            </IconButton>
                          </div>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <IconButton aria-label="add" className={classes.add} onClick={onAdd} disabled={disabledEdit}>
          <AddCircle color="primary" style={{ marginRight: 10 }} />
          Add New
        </IconButton>
      </>

      <Box>
        <Box className={classes.url}>
          <Typography className={classes.urlLabel}>Alternate URL</Typography>
          <Switch disabled={disabledEdit} checked={AlternateEnable} name="checked" onChange={toggleChecked} />
        </Box>
        {AlternateEnable && (
          <CustomTextField
            label=""
            value={ExternalUrl}
            disabled={disabledEdit}
            placeholder="Enter here"
            name="ExternalUrl"
            onInputChange={onInput}
            fullWidth
            noMargin
          />
        )}
      </Box>
    </div>
  )
}

export default WithDefaultData(BathroomServicePanel)
