import React, { useCallback, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { RenewalOption, SuccessForm } from '@activate-inc/activate-ui'
import { RenewalForm } from '@activate-inc/activate-ui'
import { RenewalFeedback } from '@activate-inc/activate-ui'
import { Template } from '@activate-inc/activate-ui/dist/types/components/types'
import { EntityITF } from '@/types/entity'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { QRCodeFunctionTypeName } from '@constant/app'
import { QRCodeFunctionType } from '@/types'

interface Props {
  data: Template
  entity: EntityITF
  qrTypeName: string
  language: LanguageType
}

const RenewalWrapper: React.FC<Props> = ({ data: template, language, entity, qrTypeName }) => {
  const [status, setStatus] = useState(0)
  const [submit, setSubmit] = useState(false)
  const { text, subText } = QRCodeFunctionTypeName[QRCodeFunctionType.Renewal]

  const onChangeStatus = (status: number) => {
    setStatus(status)
  }

  const onThumbUpSubmit = useCallback(
    async (data) => {
      setSubmit(true)
    },
    [entity, template]
  )
  if (submit) {
    return <SuccessForm text={text} subText={subText} qrTypeName={qrTypeName} />
  }
  if (!template || Object.keys(template || {}).length <= 1) {
    return (
      <Box>
        <Typography align="center">Please select a template</Typography>
      </Box>
    )
  } else {
    return (
      <Box>
        {status === 0 && (
          <RenewalOption
            onChangeStatus={onChangeStatus}
            template={template}
            language={language}
            qrTypeName={qrTypeName}
          />
        )}
        {status === 1 && (
          <RenewalFeedback language={language} template={template} onSubmit={onThumbUpSubmit} qrTypeName={qrTypeName} />
        )}
        {status === 2 && (
          <RenewalForm
            template={template}
            onSubmit={onThumbUpSubmit}
            language={language}
            qrTypeName={qrTypeName}
            status="2"
          />
        )}
      </Box>
    )
  }
}

export default RenewalWrapper
