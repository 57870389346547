import * as React from 'react'
import { useSelector } from 'react-redux'
import { Fragment, useCallback } from 'react'
import { DatagridProps, List, ListProps, useListContext } from 'react-admin'
import { Box, Divider, Tabs, Tab } from '@mui/material'
import Echarts from '@component/Chart/Echarts'
import { makeStyles } from '@mui/styles'

const useDatagridStyles = makeStyles({
  total: {
    fontWeight: 'bold',
  },
  tab: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
    height: 50,
  },
  dataGird: {
    marginTop: 60,
  },
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: 'contain',
    },
  },
  chartBox: {
    margin: 0,
    display: 'flex',
    paddingBottom: 50,
    paddingRight: 20,
  },
})

const tabs = [
  { id: 'movein', name: 'movein', label: 'Move In' },
  { id: 'moveout', name: 'moveout', label: 'Move Out' },
]

const barDataOption = {
  id: 'bar',
  type: 'bar',
  width: '800px',
  height: '500px',
  xAxisData: ['5-Star', '4-Star', '3-Star', '2-Star', '1-Star'],
  seriesData: [
    {
      value: 1,
      itemStyle: {
        color: '#4BCF5D',
      },
    },
    {
      value: 2,
      itemStyle: {
        color: '#FEE934',
      },
    },
    {
      value: 1,
      itemStyle: {
        color: '#F8C636',
      },
    },
    {
      value: 1,
      itemStyle: {
        color: '#FF8000',
      },
    },
    {
      value: 0,
      itemStyle: {
        color: '#E30613',
      },
    },
  ],
}

interface TabbedDatagridProps extends DatagridProps {}

const TabbedDatagrid = (props: TabbedDatagridProps) => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext
  const classes = useDatagridStyles()

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters && setFilters({ ...filterValues, tableName: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )
  return (
    <Fragment>
      <Tabs value={filterValues.tableName} indicatorColor="primary" onChange={handleChange} textColor="primary">
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.label} value={choice.id} className={classes.tab} />
        ))}
      </Tabs>
      <Divider />
      <Box className={classes.chartBox}>
        {/* @ts-ignore */}
        <Echarts {...barDataOption} id="barchart" />
      </Box>
    </Fragment>
  )
}

const OperationsList = (props: ListProps) => {
  const { selectedGroupId, selectedPropertyId, user } = useSelector((state) => state.profile)
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const { pathname } = window.location
  return (
    <List
      {...props}
      filterDefaultValues={{
        tableName: 'movein',
        gId: currentGroupId,
        BuildingId: pathname?.indexOf('report') > 0 ? selectedPropertyId : '',
      }}
      sort={{ field: 'Name.en', order: 'ASC' }}
      perPage={25}
      pagination={false}
    >
      <TabbedDatagrid />
    </List>
  )
}

export default OperationsList
