import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { editProperty, getCustomerSurvey, saveCustomerSurvey } from '@api'
import { makeStyles } from '@mui/styles'
import CustomerAccordion from '@component/Accordion/CustomerAccordion'
import ProgressBarWithTitle from './components/ProgressBarWithTitle'
import PropertyInformation, { PROPERTY_I18NAME } from '@/account/PropertyInformation'
import PropertyBranding, { BRANDING_BASE_NAME } from '@/account/PropertyBranding'
import useSetState from '@hooks/useSetState'
import classnames from 'classnames'
import SnackBar from '@component/SnackBar'
import FloorsUnits from '@pages/survey/components/FloorsUnits'
import {
  ConnectivityITF,
  ContentSurveyITF,
  FloorsUnitsITF,
  PhysicalSpacesITF,
  ProgressITF,
  ArchorTenantITF,
} from '@/types/entity'
import Connectivity from '@pages/survey/components/Connectivity'
import PhysicalSpaces from '@pages/survey/components/PhysicalSpaces'
import uuid from 'react-uuid'
import ContactSurvey from '@pages/survey/components/ContactSuvery'
import { Loading } from 'ra-ui-materialui'
import BackButton from '@component/BackButton/BackButton'
import Question from '@pages/survey/components/Question'
import { CheckCircle } from '@mui/icons-material'
import ContentSurveyWrap from '@pages/survey/ContentSurveyWrap'

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: 1000,
    padding: '16px 24px',
    backgroundColor: '#F7F7F7',
    textTransform: 'none',
  },
  largeMargin: {
    padding: '16px 80px',
  },
  title: {
    marginTop: 30,
    padding: 12,
    fontSize: 24,
    fontWeight: 600,
    backgroundColor: 'white',
    borderBottom: '1px solid #BCBCBC',
  },
  propertyInfo: {
    marginLeft: -12,
    marginRight: -12,
  },
  progress: {
    marginTop: 32,
    marginBottom: 24,
  },
  submit: {
    fontSize: 14,
    alignSelf: 'center',
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  complete: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 24,
    textAlign: 'center',
    marginTop: 24,
  },
}))

interface SurveyProgressITF {
  property?: ProgressITF
  branding?: ProgressITF
  floorsUnits?: ProgressITF
  physicalSpaces?: ProgressITF
  connectivity?: ProgressITF
  contacts?: ProgressITF
  managementInfoProgress?: ProgressITF
}

const calculatePercent = (progress: SurveyProgressITF) => {
  let numberator = 0
  let denominator = 0
  Object.keys(progress).forEach((key) => {
    const item = progress[key]
    numberator += item?.Numerator || 0
    denominator += item?.Denominator || 0
  })
  return numberator / denominator
}

const ManagementInfoQuestion = [
  {
    name: 'Workflow',
    type: 'multipleChoice',
    question: 'Which workflow software does your building use?',
    options: [
      { label: 'MRI (Angus)', value: 'MRI (Angus)' },
      { label: 'Building Engines', value: 'Building Engines' },
      { label: 'Impaq', value: 'Impaq' },
      { label: 'Yardi', value: 'Yardi' },
      { label: 'Cove', value: 'Cove' },
      { label: 'Other', value: 'Other' },
      { label: 'None', value: 'None' },
    ],
  },
  {
    name: 'HaveApp',
    type: 'Radio',
    question: 'Does your building have a mobile app?',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
  },
  {
    name: 'AppPlatform',
    type: 'Radio',
    question: 'If applicable, select your mobile app platform.',
    options: [
      { label: 'RISE', value: 'RISE' },
      { label: 'HQO', value: 'HQO' },
      { label: 'Host', value: 'Host' },
      { label: 'Cove', value: 'Cove' },
      { label: 'Customer', value: 'Customer' },
      { label: 'Other', value: 'Other' },
    ],
    dependOnKey: 'HaveApp',
    dependOnValue: 'Yes',
  },
  {
    name: 'Additional',
    type: 'Multiline',
    label: 'Additional Details',
  },
]
const ContentSurvey: React.FC = () => {
  const location = useLocation()
  const { bId: bIdT = '', gId: gIdT } = useParams<Record<string, string>>()
  const { gId, bId } = useMemo(() => location.state || {}, [location.state])
  return <ContentSurveyWrap hiddenMenu disabledBack gId={gIdT || gId} bId={bIdT || bId} />
}

export default ContentSurvey
