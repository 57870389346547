import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { getEntityTypes, GetPropertyContacts, getQrTypeNotifyRules, savePropertyNotifyRule, getTenantList } from '@api'
import { useSelector } from 'react-redux'
import { CriteriaITF, NotifyRuleITF } from '@/types/qrInfo'
import useSetState from '@hooks/useSetState'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material'
import { AddCircle, ExpandMore } from '@mui/icons-material'
import uuid from 'react-uuid'
import SnackBar from '@component/SnackBar'
import { generateTimeOptions, isAfter } from '@utils/DateUtil'
import { ContactsITF, GroupITF } from '@/types/group'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { PanelChoice } from '@/types/panel'
import { ChoiceITF, QRCodeFunctionType } from '@/types'
import { MethodOptions } from '@constant/qrcode'
import WeeklyDaySelect from '@component/DateSelect/WeeklyDaySelect'
import Images from '@assets/images'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 24px 18px',
  },
  accordion: {
    marginBottom: 24,
    marginTop: 0,
    borderRadius: '8px !important',
    border: '1px solid #BCBCBC',
    '&:before': {
      background: 'transparent !important',
    },
  },
  expanded: {
    margin: '0 0 24px !important',
    borderColor: '#1976D2',
    '& svg': {
      color: '#466CB5',
    },
  },
  summary: {
    margin: '0px !important',
    padding: '24px 0',
  },
  summaryText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
  },
  details: {
    padding: '0 0 24px',
    margin: '0 24px',
    borderTop: '0.5px solid #BCBCBC',
  },
  addSchedule: {
    fontSize: 14,
    padding: 0,
  },
  save: {
    fontSize: 14,
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  scheduleItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  sort: {
    fontSize: 20,
    fontWeight: 500,
    color: '#000',
    marginRight: 24,
  },
  scheduleInput: {
    flex: 1,
    marginRight: 18,
    minWidth: 202,
  },
  deleteIcon: {
    cursor: 'pointer',
    paddingLeft: 6,
  },
}))
const timeOptions = generateTimeOptions()

interface AccordionItemProps {
  item: NotifyRuleITF
  index: number
  contacts: ContactsITF[]
  property?: GroupITF
  tenants?: PanelChoice[]
}

interface ScheduleItemProps {
  contacts: ContactsITF[]
  index: number
  onChange: (data: any, index: number) => void
  onDelete: (index: number) => void
  data: any
  qrType: QRCodeFunctionType
  rules?: ChoiceITF[]
  property?: GroupITF
  locations?: PanelChoice[]
  tenants?: PanelChoice[]
}

const ScheduleItem: React.FC<ScheduleItemProps> = ({
  contacts,
  data,
  index,
  onChange,
  onDelete,
  qrType,
  rules,
  property,
  locations,
  tenants,
}) => {
  const classes = useStyles({})
  const {
    Method = '',
    RecipientId,
    Value,
    GoogleMessage,
    Schedule = [],
    Location,
    Accessible = '',
    TenantId = '',
  } = data || {}
  const { SupportChannel, OperationsChannel, OrgChannel } = property || {}
  const showRule = useMemo(() => rules && rules.length > 0, [rules])
  const googleSecondSelection = useMemo(() => {
    // 暂时注释，后端没有相关逻辑
    const arr = [
      // { label: 'SupportChannel', value: SupportChannel },
      // {
      //   label: 'OperationsChannel',
      //   value: OperationsChannel,
      // },
      // { label: 'OrgChannel', value: OrgChannel },
    ] as Array<PanelChoice>
    return arr.filter((i) => i.value) || []
  }, [SupportChannel, OperationsChannel, OrgChannel])

  const locationChoices = useMemo(() => {
    if (qrType === QRCodeFunctionType.BathroomService) {
      return locations?.filter((v) => v.Label === 'Bathroom')
    }
    return locations
  }, [locations, qrType])

  const onChangeMethod = useCallback(
    (item) => {
      onChange?.({ ...data, Method: item.value }, index)
    },
    [onChange, index, data]
  )
  const onChangeLocation = useCallback(
    (item) => {
      onChange?.({ ...data, Location: item.value }, index)
    },
    [onChange, index, data]
  )

  const onChangeContact = useCallback(
    (item) => {
      onChange?.({ ...data, RecipientId: item.UserId, PhoneNumber: item.Phone, Email: item.Email }, index)
    },
    [onChange, index, data]
  )

  const onChangeGoogleContact = useCallback(
    (item) => {
      onChange?.(Object.assign({}, data, { SpaceId: item.value }), index)
    },
    [onChange, data, index]
  )

  const onChangeRule = useCallback(
    (item) => {
      onChange?.(Object.assign({}, data, { Value: item.Value }), index)
    },
    [onChange, index, data]
  )
  const { Days, StartTimeString, EndTimeString } = Schedule?.[0] || {}
  const onChangeDay = useCallback(
    (day: string[]) => {
      const scheduleItem = Object.assign({}, Schedule?.[0] || {})
      Object.assign(scheduleItem, { Days: day })
      onChange?.(Object.assign({}, data, { Schedule: [scheduleItem] }), index)
    },
    [data, index, onChange, Schedule]
  )
  const onChangeStarTime = useCallback(
    (item) => {
      const scheduleItem = Object.assign({}, Schedule?.[0] || {})
      Object.assign(scheduleItem, { StartTimeString: item?.value })
      onChange?.(Object.assign({}, data, { Schedule: [scheduleItem] }), index)
    },
    [Schedule, onChange, data, index]
  )

  const onChangeEndTime = useCallback(
    (item) => {
      const scheduleItem = Object.assign({}, Schedule?.[0] || {})
      Object.assign(scheduleItem, { EndTimeString: item?.value })
      onChange?.(Object.assign({}, data, { Schedule: [scheduleItem] }), index)
    },
    [Schedule, onChange, data, index]
  )

  const onChangeAccessible = useCallback(
    (item) => {
      onChange?.(Object.assign({}, data, { Accessible: item.value, TenantId: '', TenantName: '' }), index)
    },
    [onChange, data, index]
  )

  const onChangeTenant = useCallback(
    (item) => {
      onChange?.(Object.assign({}, data, { TenantId: item.value, TenantName: item.label }), index)
    },
    [onChange, data, index]
  )

  const onClickFun = useCallback(() => {
    onDelete?.(index)
  }, [index, onDelete])
  const newMethodOptions =
    googleSecondSelection.length > 0
      ? [
          ...MethodOptions,
          {
            label: 'GoogleMessage',
            value: 'GoogleMessage',
          },
        ]
      : MethodOptions

  return (
    <Box className={classes.scheduleItem}>
      <Typography className={classes.sort}>{index + 1}</Typography>
      {[QRCodeFunctionType.Feedback, QRCodeFunctionType.BathroomService, QRCodeFunctionType.LeadCapture].includes(
        qrType
      ) && (
        <PanelSelection
          height={50}
          label="Location"
          width="100%"
          value={locationChoices?.find((item1: PanelChoice) => item1.value === Location)}
          className={classes.scheduleInput}
          onChange={onChangeLocation}
          choices={locationChoices || []}
        />
      )}
      {/* 暂时隐藏 */}
      {/* {qrType === QRCodeFunctionType.BathroomService && (
        <>
          <PanelSelection
            height={50}
            label="Private or Public"
            width="100%"
            value={AccessibleChoices?.find((item1) => item1.value === Accessible)}
            className={classes.scheduleInput}
            onChange={onChangeAccessible}
            choices={AccessibleChoices || []}
          />
          {Accessible === 'Private' && (
            <PanelSelection
              height={50}
              label="Tenant Name"
              width="100%"
              value={tenants?.find((item1) => item1.value === TenantId)}
              className={classes.scheduleInput}
              onChange={onChangeTenant}
              choices={tenants || []}
            />
          )}
        </>
      )} */}
      <PanelSelection
        height={50}
        label="Method"
        width="100%"
        value={newMethodOptions?.find((item1: PanelChoice) => item1.value === Method)}
        className={classes.scheduleInput}
        onChange={onChangeMethod}
        choices={newMethodOptions}
      />
      {Method !== 'GoogleMessage' ? (
        <PanelSelection
          height={50}
          label="Contact"
          width="100%"
          className={classes.scheduleInput}
          value={contacts?.find((item: PanelChoice) => item.UserId === RecipientId)}
          isOptionEqualToValue={(option: PanelChoice, value: PanelChoice) => option?.id === value?.id}
          getOptionLabel={(option: PanelChoice) => option?.FullName?.en || ''}
          onChange={onChangeContact}
          choices={contacts || []}
        />
      ) : (
        <PanelSelection
          height={50}
          label="Google Space"
          width="100%"
          className={classes.scheduleInput}
          value={googleSecondSelection?.find((item: PanelChoice) => item.value === GoogleMessage)}
          // @ts-ignore
          choices={googleSecondSelection || []}
          onChange={onChangeGoogleContact}
          getOptionLabel={(option: PanelChoice) => option?.label || ''}
        />
      )}
      {showRule && (
        <PanelSelection
          height={50}
          label="Rule"
          width="100%"
          className={classes.scheduleInput}
          value={rules?.find((item: PanelChoice) => {
            return item.Value?.toString() === Value?.toString()
          })}
          isOptionEqualToValue={(option: PanelChoice, value: PanelChoice) => option?.Value === value?.Value}
          getOptionLabel={(option: PanelChoice) =>
            `${qrType === QRCodeFunctionType.Feedback ? `${option?.Value || ''} - ` : ''}${option?.Text?.en || ''}`
          }
          onChange={onChangeRule}
          choices={rules || []}
        />
      )}
      <WeeklyDaySelect defaultValue={Days} onChange={onChangeDay} className={classes.scheduleInput} />
      <PanelSelection
        height={50}
        disableClearable={false}
        className={classes.scheduleInput}
        width="100%"
        label="Start Time"
        // @ts-ignore
        value={timeOptions?.find((item: PanelChoice) => item.label === StartTimeString)}
        // @ts-ignore
        choices={timeOptions || []}
        onChange={onChangeStarTime}
        getOptionLabel={(option: PanelChoice) => option?.label || ''}
      />
      <PanelSelection
        height={50}
        disableClearable={false}
        className={classes.scheduleInput}
        width="100%"
        label="End Time"
        // @ts-ignore
        value={timeOptions?.find((item: PanelChoice) => item.label === EndTimeString)}
        // @ts-ignore
        choices={timeOptions || []}
        onChange={onChangeEndTime}
        getOptionLabel={(option: PanelChoice) => option?.label || ''}
      />
      <img src={Images.iconDelete} className={classes.deleteIcon} onClick={onClickFun} alt="" />
    </Box>
  )
}

const AccordionItem: React.FC<AccordionItemProps> = ({ item, index, contacts, property, tenants }) => {
  const classes = useStyles()
  const { Criteria = [], QrType, Choices, acId } = item || {}
  const [data, setData] = useState<CriteriaITF[]>(Criteria || [])
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const [location, setLocation] = useState<PanelChoice[]>()
  const fetchLocation = useCallback(async () => {
    try {
      if (
        ![QRCodeFunctionType.Feedback, QRCodeFunctionType.BathroomService, QRCodeFunctionType.LeadCapture].includes(
          QrType
        )
      )
        return
      const data = await getEntityTypes({})
      // @ts-ignore
      const sortData = data.sort((a, b) => a.DisplayText?.['en'].localeCompare(b.DisplayText?.['en']))
      setLocation(
        sortData.map((item) => ({ label: item.DisplayText?.['en'], value: item.Name, Label: item?.Label || '' }))
      )
    } catch (e) {
      SnackBar({ msg: e, type: 'error', duration: 3000 })
    }
  }, [QrType, setLocation])
  useEffect(() => {
    fetchLocation()
  }, [fetchLocation])
  const onAdd = useCallback(() => {
    setData((prevState) => {
      const schedules = [...(prevState || [])]
      const item = { Key: uuid() } as CriteriaITF
      schedules.push(item)
      return [...schedules]
    })
  }, [])
  const onChange = useCallback((data, index) => {
    setData((prevState) => {
      const schedules = prevState || []
      schedules.splice(index, 1, data)
      return [...schedules]
    })
  }, [])
  const onDelete = useCallback(
    (index) => {
      setData((prevState) => {
        const schedules = prevState
        schedules.splice(index, 1)
        return [...schedules]
      })
    },
    [index]
  )
  const onClickSave = useCallback(async () => {
    const criteria = data || []
    let scheduleTimeInValid = false
    const empty = criteria.find((item) => {
      item.Schedule?.forEach((item1) => {
        if (item1.EndTimeString && item1.StartTimeString && isAfter(item1.EndTimeString, item1.StartTimeString)) {
          scheduleTimeInValid = true
        }
      })
      const isCanSaveRule = !Choices?.length || (Choices.length > 0 && !!item.Value)
      let save =
        !!item.Method &&
        ((item.Method !== 'GoogleMessage' && item.RecipientId) || (item.Method === 'GoogleMessage' && item.SpaceId)) &&
        isCanSaveRule
      if ([QRCodeFunctionType.Feedback, QRCodeFunctionType.LeadCapture].includes(QrType)) {
        save = save && !!item.Location
      }
      return !save
    })
    if (scheduleTimeInValid) return SnackBar({ msg: 'End time should be after start time' })
    if (empty) return SnackBar({ msg: 'Please select' })
    const params = {
      Criteria: criteria,
      RuleId: acId,
      GroupId: selectedGroupId,
      BuildingId: selectedPropertyId,
      QrType,
    }
    try {
      await savePropertyNotifyRule(params)
      SnackBar({ msg: 'Successfully saved', type: 'success' })
    } catch (e) {
      SnackBar({ msg: e, type: 'error' })
    }
  }, [data, acId, selectedGroupId, selectedPropertyId, QrType, Choices?.length])
  return (
    <Accordion
      elevation={0}
      classes={{ root: classes.accordion, expanded: classes.expanded }}
      defaultExpanded={index === 0}
    >
      <AccordionSummary
        classes={{ content: classes.summary }}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.summaryText}>{item.DisplayText}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        {data.map((item, index) => (
          <ScheduleItem
            key={item.Key}
            contacts={contacts}
            index={index}
            rules={Choices}
            onChange={onChange}
            onDelete={onDelete}
            data={item}
            property={property}
            qrType={QrType}
            locations={location}
            tenants={tenants}
          />
        ))}
        <div className={classes.btnGroup}>
          <Button className={classes.addSchedule} onClick={onAdd}>
            <AddCircle style={{ marginRight: 8 }} />
            Add Schedule
          </Button>
          <Button variant="contained" classes={{ contained: classes.save }} onClick={onClickSave}>
            SAVE
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
const Notifications: React.FC = () => {
  const classes = useStyles()
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const [data, setData] = useSetState<{
    rules?: NotifyRuleITF[]
    contacts?: Array<ContactsITF>
    property?: GroupITF
    tenants?: PanelChoice[]
  }>({})
  const { rules = [], contacts = [], property, tenants = [] } = data
  const getData = useCallback(async () => {
    const result = await Promise.all([
      GetPropertyContacts<{ Contacts: Array<ContactsITF> }>({
        filter: JSON.stringify({ bId: selectedPropertyId }),
        pagination: JSON.stringify({ page: 0, perPage: 10000 }),
        range: JSON.stringify([0, 10000]),
      }),
      getQrTypeNotifyRules<NotifyRuleITF[]>({ gId: selectedGroupId, bId: selectedPropertyId }),
      // getPropertyDetail<GroupITF>({ eId: selectedPropertyId }),
    ])
    result?.[1].forEach((item) => {
      item.Criteria.forEach((item1) => {
        item1.Key = uuid()
      })
    })
    setData({ rules: result[1], contacts: result[0]?.Contacts })
  }, [selectedGroupId, selectedPropertyId, setData])
  useEffect(() => {
    getData()
  }, [getData])
  useEffect(() => {
    getTenantList({
      filter: JSON.stringify({ bId: selectedPropertyId, gId: selectedGroupId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      setData({ tenants: res.Tenants.map((v) => ({ label: v.Name, value: v.acId })) })
    })
  }, [selectedGroupId, selectedPropertyId, setData])
  return (
    <div className={classes.paper}>
      {rules.map((item, index) => (
        <AccordionItem
          key={item.acId}
          item={item}
          index={index}
          contacts={contacts}
          property={property}
          tenants={tenants}
        />
      ))}
    </div>
  )
}

export default Notifications
