import React, { Fragment } from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddCircle from '@mui/icons-material/AddCircle'
import { COLOR } from '@constant/app'

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 47,
      paddingBottom: 10,
      alignItems: 'flex-end',
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .MuiSvgIcon-root': {
        marginRight: 10,
      },
      '& .MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
        opacity: 0.5,
        borderRadius: 8,
      },
    },
    title: {
      color: theme.palette.primary.main,
      flex: 1,
      fontFamily: '',
    },
    addBtn: {
      color: COLOR.grey78,
      fontWeight: 400,
      fontFamily: '',
    },
    line: {
      height: 2,
      marginTop: 28,
      paddingBottom: 0,
      marginBottom: 16,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      marginLeft: (props: { marginLeft?: number }) => {
        return props?.marginLeft || 0
      },
    },
  }
})

export interface SectionLabelProps {
  title?: string
  bottomLine?: boolean
  btnText?: string
  onClickAdd?: () => void
  onlyLine?: boolean
  lineOffset?: number
}

const SectionLabel: React.FC<SectionLabelProps> = ({ title, onClickAdd, btnText, onlyLine, lineOffset }) => {
  const classes = useStyles({ marginLeft: lineOffset })
  const showBtn = !!btnText
  if (onlyLine) {
    return <Box className={clsx(classes.container, classes.line)} />
  }
  return (
    <Box className={classes.container}>
      {!!title && (
        <Fragment>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          {showBtn && (
            <IconButton onClick={onClickAdd}>
              <AddCircle color="primary" fontSize="large" />
              <Typography variant="h6" className={classes.addBtn}>
                {btnText}
              </Typography>
            </IconButton>
          )}
        </Fragment>
      )}
    </Box>
  )
}

export default SectionLabel
