import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { getUpsellMatrix } from '@api'
import UpsellMatrix, { HeaderConfig, TableBoxConfig } from '@component/Table/UpsellMatrix'
import { CustomI18NITF } from '@activate-inc/activate-ui/dist/types/common.types'
import { ProductCategoriesItemITF } from '@/account/UpsellInfo'
import useSetState from '@hooks/useSetState'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'white',
    flex: 1,
    padding: 16,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    maxWidth: 'calc(100vw - 60px)',
  },
  rowClassName: {
    '&:last-child td': {
      color: '#211F1F',
      fontWeight: '700',
      borderTop: '1px solid #BCBCBC',
    },
  },
}))

const UpsellMatrixReport: React.FC = () => {
  const classes = useStyles()
  const { selectedGroupId: gId } = useSelector((state) => state.profile)
  const [{ data, headConfig, dataConfig }, setData] = useSetState<{
    headConfig?: HeaderConfig[]
    data?: any[]
    dataConfig?: TableBoxConfig[]
  }>({})
  const getData = useCallback(() => {
    getUpsellMatrix<{ UpsellMatrix: Array<{ BuildingName: CustomI18NITF; Products: ProductCategoriesItemITF[] }> }>({
      gId,
    }).then((res) => {
      const allProducts = res.UpsellMatrix.reduce((previousValue, currentValue) => {
        currentValue.Products.forEach((item) => {
          if (previousValue[item.Category]) {
            const data = previousValue[item.Category].find((item1) => item1.Product === item.Product)
            if (!data) {
              previousValue[item.Category].push(item)
            }
          } else {
            previousValue[item.Category] = [item]
          }
        })
        return previousValue
      }, {} as { [key: string]: ProductCategoriesItemITF[] })
      const HEAD = { label: '', style: { flex: 1, justifyContent: 'center' } }
      const HEADER_CONFIG: HeaderConfig[] = []
      const DATA_CONFIG: TableBoxConfig[] = []

      Object.keys(allProducts).forEach((item) => {
        const value = allProducts[item]
        const head = JSON.parse(JSON.stringify(HEAD))
        head.label = item
        head.style.flex = value.length
        HEADER_CONFIG.push(head)
        value.forEach((item) => {
          DATA_CONFIG.push({
            label: item.Text,
            source: 'State',
          })
        })
      })
      setData({ headConfig: HEADER_CONFIG, dataConfig: DATA_CONFIG, data: res.UpsellMatrix })
    })
  }, [gId, setData])
  useEffect(() => {
    getData()
  }, [getData])
  return (
    <div className={classes.paper}>
      {dataConfig && (
        <UpsellMatrix
          className={classes.content}
          headerWidth={160}
          header={headConfig}
          config={dataConfig}
          data={data || []}
        />
      )}
    </div>
  )
}

export default UpsellMatrixReport
