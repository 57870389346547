import React, { useMemo } from 'react'
import { EventForm } from '@activate-inc/activate-ui'
import LuxonAdapter from '@date-io/luxon'
import { EntityITF, Template } from '@/types/entity'
import { QRCodeFunctionType } from '@/types'
import Images from '@assets/images'

interface Props {
  template?: Template
  entity?: EntityITF | null
  type: QRCodeFunctionType
  qrTypeName?: string
}

const format = (date: number, str = '@') => {
  const luxon = new LuxonAdapter({ locale: 'en-US' })
  if (!date) return ''
  const luxonDate = luxon.date(new Date(date))
  const currDesc =
    luxon.format(luxonDate, 'weekday') +
    ' , ' +
    luxon.format(luxonDate, 'monthAndDate') +
    ` ${str} ` +
    luxon.format(luxonDate, 'fullTime12h').replace('PM', 'pm')
  return currDesc
}

const EventWrapper: React.FC<Props> = ({ template, qrTypeName, type, entity }) => {
  const { EventName, StartDate, EventLocation, EventDate, Details, Fields = [], CoverImage } = template || {}
  const isCheckIn = type === QRCodeFunctionType.CheckIn
  const desc = useMemo(() => {
    if ([QRCodeFunctionType.FieldMarketing, QRCodeFunctionType.EventRegistration].includes(type)) {
      const luxon = new LuxonAdapter({ locale: 'en-US' })
      if (!EventDate) return ''
      const luxonDate = luxon.date(new Date(EventDate))
      let currDesc =
        luxon.format(luxonDate, 'weekday') +
        ' , ' +
        luxon.format(luxonDate, 'monthAndDate') +
        ' @ ' +
        luxon.format(luxonDate, 'fullTime12h').replace('PM', 'pm')
      if (EventLocation) {
        currDesc = currDesc + ` in ${EventLocation}`
      }
      if (Details) {
        currDesc = currDesc + `\n ${Details}`
      }
      return currDesc
    }
    return Details
  }, [EventDate, EventLocation, Details])
  const data = useMemo(() => {
    if ([QRCodeFunctionType.CheckIn].includes(type)) {
      const arr = [{ label: 'Time', value: format(StartDate || Date.now(), 'at') }]
      if (EventLocation) {
        arr.push({ label: 'Location', value: EventLocation })
      }
      return arr
    }
    if ([QRCodeFunctionType.RoomRental, QRCodeFunctionType.EVRequest].includes(type)) {
      const arr: any[] = []
      if (EventLocation) {
        arr.push({ label: 'Location', value: EventLocation })
      }
      return arr
    }
    return []
  }, [StartDate, EventLocation])
  let icon
  let btnText
  if (type === QRCodeFunctionType.RoomRental) {
    icon = Images.iconRoomRental
    btnText = 'SEND REQUEST'
  }
  return (
    <EventForm
      title={EventName || ''}
      coverImage={CoverImage}
      desc={desc || ''}
      isHtml={isCheckIn}
      icon={icon}
      btnText={btnText}
      qrTypeName={qrTypeName}
      fields={Fields}
      data={data}
      onSubmit={() => {}}
    />
  )
}

export default EventWrapper
