import React from 'react'
import { IconButton } from '@mui/material'
import { EmailRounded, GroupOutlined, SaveAltOutlined } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    position: 'sticky',
    paddingTop: 18,
    paddingBottom: 21,
    zIndex: 999,
    backgroundColor: '#F5F5F5',
    top: 120,
  },
  btn: {
    width: 48,
    height: 48,
    marginRight: 16,
    backgroundColor: '#D4E2EF',
  },
  range: {
    width: 180,
    height: 56,
  },
}))

const IconButtons: React.FC = () => {
  const classes = useStyles()

  return (
    <div>
      <IconButton className={classes.btn}>
        <EmailRounded htmlColor="#466CB5" />
      </IconButton>
      <IconButton className={classes.btn}>
        <SaveAltOutlined htmlColor="#466CB5" />
      </IconButton>
      <IconButton className={classes.btn}>
        <GroupOutlined htmlColor="#466CB5" />
      </IconButton>
    </div>
  )
}
export default IconButtons
