import React, { useCallback, useEffect, useState } from 'react'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'

interface SocialFormPanelProps {
  onChange?: (staticForm: any) => void
  value?: any
  disabledEdit?: boolean
}

export const SOCIAL_REQUIRED = {
  FacebookUrl: '',
  TikTokUrl: '',
  TwitterUrl: '',
  InstagramUrl: '',
  LinkedInUrl: '',
  YoutubeUrl: '',
  VimeoUrl: '',
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      padding: '0 16px 16px',
    },
    title: {
      color: '#0D1F2C',
      fontSize: 12,
    },
  }
})

const SocialFormPanel: React.FC<SocialFormPanelProps> = ({
  onChange,
  value = SOCIAL_REQUIRED,
  disabledEdit = false,
}) => {
  const [state, setState] = useState(value || {})
  const classes = useStyles()

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }))
      const newData = Object.assign({}, state, { [name]: value, isChange: true })
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.SocialMedia, newData) })
      setState(newData)
      onChange?.(newData)
    },
    [state]
  )
  useEffect(() => {
    setState(value)
  }, [value])
  return (
    <div className={classes.container}>
      <CustomTextField
        label="Facebook"
        value={state?.FacebookUrl}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="FacebookUrl"
        variant="outlined"
      />
      <CustomTextField
        label="TikTok"
        value={state?.TikTokUrl}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="TikTokUrl"
        variant="outlined"
      />
      <CustomTextField
        label="Twitter"
        value={state?.TwitterUrl}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="TwitterUrl"
        variant="outlined"
      />
      <CustomTextField
        label="Instagram"
        value={state?.InstagramUrl}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="InstagramUrl"
        variant="outlined"
      />
      <CustomTextField
        label="Linkedin"
        value={state?.LinkedInUrl}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="LinkedInUrl"
        variant="outlined"
      />
      <CustomTextField
        label="YouTube"
        value={state?.YoutubeUrl}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="YoutubeUrl"
        variant="outlined"
      />
      <CustomTextField
        label="Vimeo Url"
        value={state?.VimeoUrl}
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="VimeoUrl"
        variant="outlined"
      />
    </div>
  )
}

export default WithDefaultData(SocialFormPanel)
