import React, { useCallback, useEffect, useState } from 'react'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'

interface LadderSafetyPanelProps {
  onChange?: (data: any) => void
  value?: any
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      padding: '0 16px 16px',
    },
    title: {
      color: '#0D1F2C',
      fontSize: 12,
    },
  }
})

const LadderSafetyPanel: React.FC<LadderSafetyPanelProps> = ({ onChange, value }) => {
  const [state, setState] = useState(value || {})
  const { Ladders } = state || {}
  const classes = useStyles()

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      const ladders = [...Ladders]
      if (Ladders?.length > 1) {
        name === 'ButtonName1' && (Ladders[0].Name = value)
        name === 'ButtonName2' && (Ladders[1].Name = value)
      }
      const newData = Object.assign({}, state, { Ladders: ladders, isChange: true })
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.LadderSafety, newData) })
      setState(newData)
      onChange?.(newData)
    },
    [state, Ladders]
  )
  useEffect(() => {
    setState(value)
  }, [value])
  return (
    <div className={classes.container}>
      <CustomTextField
        label="Button Name"
        value={Ladders[0]?.Name}
        placeholder="Enter here"
        onChange={handleChange}
        disabled={false}
        name="ButtonName1"
        variant="outlined"
      />
      <CustomTextField
        label="Button Name"
        value={Ladders[1]?.Name}
        placeholder="Enter here"
        onChange={handleChange}
        disabled={false}
        name="ButtonName2"
        variant="outlined"
      />
    </div>
  )
}

export default WithDefaultData(LadderSafetyPanel)
