import React, { useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Button, Modal, InputAdornment } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import PanelSelection from '@component/EditPanel/PanelSelection'
import useSetState from '@hooks/useSetState'
import CustomTextField from '@component/Form/CustomTextField'
import Images from '@assets/images'
import { ColorMap, ProductCategoriesItemITF, StateEnum } from '@/account/UpsellInfo'
import { format } from 'date-fns'

const useStyles = makeStyles(() => ({
  modal: {
    width: 500,
    padding: 24,
    maxHeight: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
  inputLabel: {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, -8px) scale(0.75)',
    },
  },
  icon: {
    display: 'flex',
    width: 14,
    height: 14,
    borderRadius: 7,
  },
}))
interface Props {
  onClose: () => void
  onSave: (data: string, state: string) => void
  info: ProductCategoriesItemITF
}

const UpsellModal: React.FC<Props> = React.memo(({ onClose, onSave, info }) => {
  const classes = useStyles()
  const [data, setData] = useSetState({ Note: '', Notes: info.Notes || [], State: info.State })
  const { Note, State, Notes } = data
  const CATEGORY = useMemo(() => {
    return Object.keys(StateEnum || {}).map((item) => ({ label: item, value: item }))
  }, [])

  const onSaveClick = () => {
    onSave(Note, State)
  }
  let img = ''
  let props = {}
  if (!State) {
    img = Images.iconStateEmpty
  } else if (State === StateEnum.Never) {
    img = Images.iconStateNever
  } else {
    props = ColorMap[State] ? { backgroundColor: ColorMap[State] } : {}
  }
  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.modal}>
        <Typography variant="h5" sx={{ fontSize: '16px', marginBottom: '24px' }}>
          Product Detail
        </Typography>
        <PanelSelection
          label="State"
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Box className={classes.icon} sx={{ marginRight: '8px' }} {...props}>
                  {img && <img src={img} className={classes.icon} />}
                </Box>
              </InputAdornment>
            ),
          }}
          sx={{ marginTop: '20px' }}
          choices={CATEGORY}
          inputLabelClass={classes.inputLabel}
          value={CATEGORY.find((item) => item.value === State)}
          onChange={(item) => setData({ State: item?.value as string })}
          popupIcon={<ExpandMore />}
          width={'100%'}
          height={56}
        />
        {Notes && Notes.length > 0 && (
          <CustomTextField
            style={{ width: '100%' }}
            label="Recent Notes"
            value={Notes.map((item) => format(item.CreatedDate, 'MM/dd/yyyy') + ':  ' + item.Note).join('\n')}
            disabled
            placeholder="Enter here..."
            variant="outlined"
            multiline
          />
        )}
        <CustomTextField
          style={{ width: '100%' }}
          label="Note"
          value={Note}
          disabled={false}
          placeholder="Enter here..."
          name="Details"
          onInputChange={(value) => setData({ Note: value })}
          variant="outlined"
          multiline
        />
        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: '16px' }}
            onClick={onSaveClick}
            variant="contained"
            color="primary"
            disabled={!Note || !State}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

export default UpsellModal
