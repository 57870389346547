import React, { useCallback, useEffect, useState } from 'react'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import RichTextEdit from '@component/Form/richText'
import { makeStyles } from '@mui/styles'
import { getHtmlTemplates, saveHtmlTemplate } from '@api'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { PanelChoice } from '@/types/panel'
import { Close } from '@mui/icons-material'
import SnackBar from '@component/SnackBar'

interface HtmlITF {
  HtmlContent?: string
}

interface HtmlFormPanelProps {
  groupId?: string
  buildingId?: string
  onChange?: (staticForm: HtmlITF) => void
  value?: HtmlITF
  disabledEdit?: boolean
}

export const HTML_REQUIRED = {
  HtmlContent: '',
}
const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 5,
      alignItems: 'flex-start',
    },
    selectContainer: {
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      paddingRight: 30,
      color: '#211F1F',
      fontSize: 12,
      fontFamily: 'Roboto',
    },
    dialog: {
      padding: 32,
      backgroundColor: 'white',
      borderRadius: 32,
      width: 450,
    },
    dTitle: {
      fontSize: 20,
      fontWeight: 600,
      color: '#211F1F',
      lineHeight: '26px',
      marginBottom: 8,
    },
    actions: {
      display: 'flex',
      alignSelf: 'end',
      marginTop: 16,
    },
    input: {
      height: 45,
    },
    label: {
      transform: 'translate(14px, 12px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)',
      },
    },
    dBtn: {
      width: 90,
      margin: 8,
    },
  }
})

interface TemplateDialogProps {
  onClose?: (refresh?: boolean) => void
  html: string
  gId?: string
  bId?: string
  templates?: Array<PanelChoice>
}

const TemplateDialog: React.FC<TemplateDialogProps> = ({ onClose, templates, html, gId, bId }) => {
  const [open, setOpen] = useState(true)
  const [checkValue, setCheckValue] = useState()
  const [data, setData] = useState<{ title?: string; templateId?: string }>({ title: '', templateId: '' })
  const classes = useStyles()
  const removeDialog = () => {
    setOpen(false)
    onClose?.()
  }
  const onChangeTemplate = useCallback((value: PanelChoice | null) => {
    setData({ templateId: value?.id, title: value?.label })
  }, [])
  const onInput = useCallback((event) => {
    setData({ title: event.target.value })
  }, [])
  const onSave = async () => {
    try {
      await saveHtmlTemplate({
        TemplateId: data.templateId,
        GroupId: gId,
        BuildingId: bId,
        HtmlContent: html,
        Title: data.title,
      })
      onClose?.(true)
      SnackBar({ msg: 'Successfully saved', type: 'success' })
    } catch (e) {
      SnackBar({ msg: e })
    }
  }
  const disabled = !(checkValue && (data.title || data.templateId))
  return (
    <Dialog open={open} onClose={removeDialog} classes={{ paper: classes.dialog }}>
      <Typography className={classes.dTitle}>Save HTML Template</Typography>
      <IconButton
        aria-label="close"
        onClick={removeDialog}
        sx={{
          position: 'absolute',
          right: 24,
          top: 24,
        }}
      >
        <Close htmlColor="#211F1F" />
      </IconButton>
      <RadioGroup
        name="radio-buttons-group"
        onChange={(e) => {
          // @ts-ignore
          setCheckValue(e.target.value)
          setData({})
        }}
      >
        <FormControlLabel value="new" control={<Radio />} label="Save as a new template" />
        <FormControlLabel value="old" control={<Radio />} label="Overwrite existing template" />
      </RadioGroup>
      {checkValue &&
        (checkValue === 'new' ? (
          <TextField
            variant="outlined"
            placeholder="input name"
            label="Template Name"
            onInput={onInput}
            InputProps={{
              classes: {
                root: classes.input,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
          />
        ) : (
          <PanelSelection height={45} choices={templates || []} onChange={onChangeTemplate} />
        ))}
      <Box className={classes.actions}>
        <Button onClick={removeDialog} color="primary" variant="outlined" className={classes.dBtn}>
          CANCEL
        </Button>
        <Button onClick={onSave} color="primary" variant="contained" className={classes.dBtn} disabled={disabled}>
          SAVE
        </Button>
      </Box>
    </Dialog>
  )
}
const HtmlFormPanel: React.FC<HtmlFormPanelProps> = ({ groupId, buildingId, onChange, value, disabledEdit }) => {
  const classes = useStyles()
  const [state, setState] = useState(value || HTML_REQUIRED)
  const [templates, setTemplates] = useState<Array<PanelChoice>>()
  const [show, setShow] = useState<boolean>()
  const getTemplates = useCallback(async () => {
    const result = await getHtmlTemplates()
    // @ts-ignore
    setTemplates(result.Templates?.map((item) => ({ label: item.Title, value: item.HtmlContent, id: item.TemplateId })))
  }, [])
  useEffect(() => {
    getTemplates()
  }, [getTemplates])
  const handleChange = (value: string) => {
    const canSave = isCanSavaQr(QRCodeFunctionType.Html, state)
    const newData = Object.assign({}, { HtmlContent: value }, { CanSave: !!canSave })
    setState((prevState) => ({
      ...prevState,
      ...newData,
    }))
    const newState = { ...newData, CanSave: canSave }

    if (onChange) {
      onChange(newState)
      return
    }
  }
  const onSubmit = (value: string) => {
    handleChange(value)
  }
  const onSaveTemplate = () => {
    setShow(true)
  }
  const onClose = (refresh?: boolean) => {
    setShow(false)
    if (refresh) getTemplates()
  }
  const onChangeTemplate = useCallback(
    (value: PanelChoice | null) => {
      handleChange((value?.value as string) || '')
    },
    [handleChange]
  )
  const disabled = !state.HtmlContent || state.HtmlContent === '<p><br></p>'
  return (
    <Box className={classes.container}>
      <Box className={classes.selectContainer}>
        <Typography className={classes.title}>Content</Typography>
        <PanelSelection height={50} label="Select Template" choices={templates || []} onChange={onChangeTemplate} />
      </Box>
      <RichTextEdit htmlContent={state?.HtmlContent} onSubmit={onSubmit} disabledEdit={disabledEdit} />
      <Button variant="outlined" onClick={onSaveTemplate} disabled={disabled}>
        Save as template
      </Button>
      {show && (
        <TemplateDialog
          onClose={onClose}
          templates={templates}
          html={state?.HtmlContent || ''}
          gId={groupId}
          bId={buildingId}
        />
      )}
    </Box>
  )
}
export default HtmlFormPanel
