import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { Typography, Box, Switch } from '@mui/material'
import { COLOR } from '@constant/app'
import useSetState from '@/hooks/useSetState'
import ImageUpload from '@component/Image/ImageUpload'
import { uploadBase64 } from '@api'
import { toBase64, isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'

const S3_Bucket = process.env.REACT_APP_S3_BUCKET
const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    logoLabel: {
      color: COLOR.grey37,
      fontSize: 12,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    margin: {
      marginTop: 0,
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  disabledEdit?: boolean
}

export const DOWNLOAD_APP_REQUIRED = {
  LogoFilename: '',
  AppStoreUrl: '',
  PlayStoreUrl: '',
}

const DownloadAppPanel: React.FC<Props> = ({ onChange, value, disabledEdit = false }) => {
  const classes = useStyles()
  const { selectedPropertyId } = useSelector((state) => state.profile)

  const [{ LogoFilename, AppStoreUrl, PlayStoreUrl, ExternalUrl, AlternateEnable }, setState] = useSetState({
    LogoFilename: value?.LogoFilename || '',
    AppStoreUrl: value?.AppStoreUrl || 'https://',
    PlayStoreUrl: value?.PlayStoreUrl || 'https://',
    AlternateEnable: value?.AlternateEnable || false,
    ExternalUrl: value?.ExternalUrl || 'https://',
  })

  const handleQRLogoChange = async (event: any) => {
    const images = event.target.files as File[]
    if (images[0]) {
      const base64Image = await toBase64(images[0])
      const s3Image: any = await uploadBase64({
        base64Image,
        fileName: `DownloadAppFilename/${selectedPropertyId}/${images[0].name}`,
        bucket: S3_Bucket,
      })
      // @ts-ignore
      setState({ LogoFilename: s3Image.imageUrl as string })
    }
  }

  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }
  const toggleChecked = () => {
    setState({ AlternateEnable: !AlternateEnable })
  }

  useEffect(() => {
    if (onChange) {
      const newData = { LogoFilename, AppStoreUrl, PlayStoreUrl, AlternateEnable, ExternalUrl }
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.DownloadApp, newData) })
      onChange(newData)
    }
  }, [onChange, LogoFilename, AppStoreUrl, PlayStoreUrl, AlternateEnable, ExternalUrl])

  return (
    <div className={classes.container}>
      <Typography className={classes.logoLabel}>Upload Logo</Typography>
      <ImageUpload
        type="DownloadAppFilename"
        imgWidth={100}
        disabled={disabledEdit}
        imgHeight={'auto'}
        src={LogoFilename}
        handleImageChange={(e) => handleQRLogoChange(e)}
      />
      <CustomTextField
        noMargin
        label="App store"
        value={AppStoreUrl}
        disabled={disabledEdit || AlternateEnable}
        placeholder="Enter here"
        name="AppStoreUrl"
        onInputChange={onInput}
      />
      <CustomTextField
        label="Play Store store"
        value={PlayStoreUrl}
        disabled={disabledEdit || AlternateEnable}
        placeholder="Enter here"
        name="PlayStoreUrl"
        onInputChange={onInput}
      />
      <Box>
        <Box className={classes.url}>
          <Typography className={classes.urlLabel}>Alternate URL</Typography>
          <Switch disabled={disabledEdit} checked={AlternateEnable} name="checked" onChange={toggleChecked} />
        </Box>
        {AlternateEnable && (
          <CustomTextField
            label=""
            value={ExternalUrl}
            disabled={disabledEdit}
            placeholder="Enter here"
            name="ExternalUrl"
            onInputChange={onInput}
            fullWidth
            noMargin
          />
        )}
      </Box>
    </div>
  )
}

export default DownloadAppPanel
