import React, { useCallback } from 'react'
import { Box, FormControlLabel, Switch } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import WithDefaultData from '@/hoc/WithDefaultData'
import { QRType, QuestionITF, Template } from '@/types/entity'
import useSetState from '@hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import { CustomI18NITF } from '@activate-inc/activate-ui/dist/types/common.types'

const useStyle = makeStyles(() => {
  return {
    item: {
      marginTop: 24,
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      alignSelf: 'flex-start',
      height: 26,
      marginLeft: 0,
    },
  }
})

interface EditItemProps {
  label: string
  question?: QuestionITF
  index: number
  onChangeItem?: (question: QuestionITF, index: number) => void
  disabledEdit?: boolean
}

const EditItem: React.FC<EditItemProps> = ({ label, question, onChangeItem, index, disabledEdit }) => {
  const classes = useStyle()
  const { QuestionText, Hide } = question || {}
  const onSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!question) return null
      onChangeItem?.({ ...question, Hide: !Hide }, index)
    },
    [question, Hide]
  )
  const onInputChange = useCallback(
    (value) => {
      if (!question) return
      if (!question?.QuestionText) question.QuestionText = {} as CustomI18NITF
      question.QuestionText.en = value
      question.QuestionText.es = value
      onChangeItem?.({ ...question }, index)
    },
    [onChangeItem]
  )

  if (!question) return null
  return (
    <Box className={classes.item}>
      <FormControlLabel
        className={classes.formControl}
        value="start"
        disabled={disabledEdit}
        control={<Switch color="primary" checked={!Hide} onChange={onSwitch} size="medium" />}
        label={label}
        labelPlacement="start"
      />
      <CustomTextField
        disabled={disabledEdit || Hide}
        variant="outlined"
        label={`${label} Question`}
        value={QuestionText?.[language]}
        onInputChange={onInputChange}
      />
    </Box>
  )
}
interface Props {
  qrType: QRType
  onChange?: (template: any) => void
  value?: Template
  disabledEdit?: boolean
}
const language = 'en'

const CustomerFeedback: React.FC<Props> = ({ onChange, value, disabledEdit }) => {
  const [{ Questions, FieldText }, setState] = useSetState({
    Questions: value?.Questions || ([] as QuestionITF[]),
    FieldText: value?.Fields?.[0]?.FieldText?.[language] || '',
  })
  const onChangeItem = useCallback(
    (question: QuestionITF, index: number) => {
      let questionsTem = Questions
      setState((pre) => {
        pre.Questions.splice(index, 1, question)
        questionsTem = pre.Questions
        const newData = { Questions: questionsTem }
        onChange?.(
          Object.assign({}, value, newData, { CanSave: isCanSavaQr(QRCodeFunctionType.CustomerFeedback, newData) })
        )
        return {
          ...pre,
          Questions: [...pre.Questions],
        }
      })
    },
    [Questions, setState, onChange, value]
  )
  return (
    <Box sx={{ padding: '0 16px' }}>
      <EditItem
        label="CSAT"
        disabledEdit={disabledEdit}
        question={Questions?.[0]}
        onChangeItem={onChangeItem}
        index={0}
      />
      <EditItem
        label="NPS"
        disabledEdit={disabledEdit}
        question={Questions?.[1]}
        onChangeItem={onChangeItem}
        index={1}
      />
      <EditItem
        label="CES"
        disabledEdit={disabledEdit}
        question={Questions?.[2]}
        onChangeItem={onChangeItem}
        index={2}
      />
    </Box>
  )
}

export default WithDefaultData(CustomerFeedback)
