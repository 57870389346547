import React, { useCallback, useEffect, useRef } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import DateFnsUtils from '@date-io/date-fns'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import FieldType from '@/utils/FieldType'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import { InputAdornment } from '@mui/material'
import { Event, AccessTime } from '@mui/icons-material'

const language = 'en'

type FieldItem = typeof FieldType.DropDownSelection

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px 16px',
    },
    date: {
      margin: '20px 0 0',
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
}

const dateFns = new DateFnsUtils()

const FieldMarketing: React.FC<Props> = ({ onChange, value, functionType }) => {
  const classes = useStyles()
  const refValue = useRef(value)

  const [state, setState] = useSetState({
    EventName: value?.EventName || '',
    EventDate: value?.EventDate || Date.now(),
    EventLocation: value?.EventLocation || '',
    Details: value?.Details || '',
    Fields: (value?.Fields || []) as FieldItem[],
  })
  const { Fields, EventName, EventDate, EventLocation, Details } = state
  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }
  const onChangeField = useCallback((fields: FieldItem[]) => {
    setState({ Fields: fields || [] })
  }, [])

  useEffect(() => {
    if (onChange) {
      const data = {}
      const newData = {
        EventName,
        EventDate,
        EventLocation,
        Details,
        Fields: Fields || [],
      }
      Object.assign(data, refValue.current, newData, {
        CanSave: isCanSavaQr(functionType, newData),
      })
      onChange(data)
    }
  }, [onChange, Fields, EventName, EventDate, EventLocation, Details])

  useEffect(() => {
    refValue.current = value
  }, [value])
  const prefix =
    {
      [QRCodeFunctionType.FieldMarketing]: 'Field Marketing Event',
      [QRCodeFunctionType.EventRegistration]: 'Event Registration',
    }[functionType] || ''
  return (
    <div className={classes.container}>
      <CustomTextField
        required
        label={`${prefix} Name`}
        disabled={false}
        value={EventName}
        name="EventName"
        onInputChange={onInput}
        variant="outlined"
      />
      <MobileDatePicker
        className={classes.date}
        label={`${prefix} Date`}
        format="MM/dd/yyyy"
        value={EventDate}
        onChange={(a, b) => {
          const dateF = dateFns.date(EventDate)
          if (dateFns.isValid(a)) {
            const currentValue = dateFns.date(a)
            dateF.setFullYear(currentValue.getFullYear())
            dateF.setMonth(currentValue.getMonth())
            dateF.setDate(currentValue.getDate())
            setState({ EventDate: dateF.getTime() })
          }
        }}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Event />
                </InputAdornment>
              ),
            },
          },
        }}
      />

      <MobileTimePicker
        className={classes.date}
        label={`${prefix} Time`}
        value={EventDate}
        onChange={(a, b) => {
          const dateF = dateFns.date(EventDate)
          if (dateFns.isValid(a)) {
            const currentValue = dateFns.date(a)
            dateF.setHours(currentValue.getHours())
            dateF.setMinutes(currentValue.getMinutes())
            setState({ EventDate: dateF.getTime() })
          }
        }}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <AccessTime />
                </InputAdornment>
              ),
            },
          },
        }}
      />

      <CustomTextField
        label={`${prefix} Location`}
        value={EventLocation}
        disabled={false}
        name="EventLocation"
        onInputChange={onInput}
        variant="outlined"
      />

      <CustomTextField
        label="Details"
        value={Details}
        disabled={false}
        placeholder="type here..."
        name="Details"
        onInputChange={onInput}
        variant="outlined"
        multiline
      />

      <DragFormPanel
        language={language}
        data={Fields}
        onChangeField={onChangeField}
        onlyMoveArr={['Q001', 'Q002', 'Q003']}
      />
    </div>
  )
}

export default WithDefaultData(FieldMarketing)
