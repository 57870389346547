import React, { useCallback, useRef, useLayoutEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  accordion: {
    marginBottom: 24,
    marginTop: 0,
    overflow: 'hidden',
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: '8px !important',
    backgroundColor: 'white',
    minHeight: 'unset !important',
    border: '1px solid #BCBCBC',
    '&:before': {
      background: 'transparent !important',
    },
  },
  expanded: {
    margin: '0 0 24px !important',
    borderColor: '#1976D2',
  },
  expandedSummary: {
    borderBottom: '1px solid #BCBCBC',
    '& svg': {
      color: '#466CB5',
    },
  },
  summary: {
    // borderRadius: 16,
    minHeight: 'unset !important',
    margin: 0,
    backgroundColor: 'white',
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 24,
    '& .Mui-expanded': {
      borderColor: 'transparent',
    },
  },
  summaryContent: {
    margin: '0 !important',
  },
  summaryText: {
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: '600',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0 24px',
  },
}))

interface Props {
  title: string
  disabled?: boolean
  defaultExpanded?: boolean
  autoScroll?: boolean
  contentClass?: string
  summaryClass?: string
  accordionClass?: string
}
const CustomerAccordion: React.FC<Props> = ({
  children,
  title,
  disabled,
  contentClass,
  defaultExpanded,
  autoScroll,
  summaryClass,
  accordionClass,
}) => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement | null>(null)
  const onChange = useCallback((event, expand) => {
    // if (expand) {
    //   setTimeout(() => {
    //     ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    //   }, 200)
    // }
  }, [])
  useLayoutEffect(() => {
    if (autoScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [autoScroll])
  return (
    <Accordion
      elevation={0}
      ref={ref}
      classes={{ root: classnames(classes.accordion, accordionClass), expanded: classes.expanded }}
      onChange={onChange}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        classes={{
          root: classnames(classes.summary, summaryClass),
          content: classes.summaryContent,
          expanded: classes.expandedSummary,
        }}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div ref={ref}></div>
        <Typography className={classes.summaryText}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classnames(classes.details, contentClass) }}>{children}</AccordionDetails>
    </Accordion>
  )
}

export default CustomerAccordion
