import * as React from 'react'
import { useSelector } from 'react-redux'
import { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Datagrid,
  DatagridProps,
  DateField,
  Identifier,
  List,
  ListContextProvider,
  ListProps,
  TextField,
  useListContext,
  useRecordContext,
  ImageField,
} from 'react-admin'
import { Divider, Tabs, Tab, Theme } from '@mui/material'

import { makeStyles } from '@mui/styles'

const useDatagridStyles = makeStyles({
  total: {
    fontWeight: 'bold',
  },
  tab: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
    height: 70,
  },
  dataGird: {
    marginTop: 60,
  },
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: 'contain',
    },
  },
})

const PurpleTextField = ({ source }) => {
  const record = useRecordContext()
  const list = record[source].split(',')
  if (!record[source] || list.length <= 0) return null
  return (
    <div>
      {list.map((value) => {
        return (
          <React.Fragment key={value}>
            <input type="checkbox" disabled checked id={value} />
            <span style={{ marginRight: '30px' }}>{value}</span>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const CustomImageField = (props) => {
  const record = useRecordContext(props)
  const classes = useDatagridStyles()
  const imgurl = record.Image
  return imgurl ? (
    <a href={imgurl} target="_blank" rel="noopener noreferrer">
      <ImageField className={classes.imgContainer} source={props.source} />
    </a>
  ) : null
}
CustomImageField.defaultProps = { label: 'Image2' }

const tabs = [
  { id: 'maintenance', name: 'maintenance', label: 'Maintenance' },
  { id: 'supplier', name: 'supplier', label: 'Supplier CheckIn' },
]

interface TabbedDatagridProps extends DatagridProps {}

const TabbedDatagrid = (props: TabbedDatagridProps) => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext
  const classes = useDatagridStyles()

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters && setFilters({ ...filterValues, tableName: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )
  return (
    <Fragment>
      <Tabs value={filterValues.tableName} indicatorColor="primary" onChange={handleChange} textColor="primary">
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.label} value={choice.id} className={classes.tab} />
        ))}
      </Tabs>
      <Divider />
      <div>
        {filterValues.tableName === 'maintenance' && (
          <ListContextProvider value={{ ...listContext }}>
            <Datagrid {...props} optimized className={classes.dataGird}>
              <DateField source="Date" />
              <PurpleTextField source="Area" />
              <TextField source="Urgency" label="Urgency" />
              <TextField source="Detail" label="Detail" />
              <CustomImageField source="Image" label="Image" />
            </Datagrid>
          </ListContextProvider>
        )}
      </div>
    </Fragment>
  )
}

// const DynamicGridFields = (props) => {
//     if(!props?.data) return ''
//     const columns = []
//     const data = Object.values(props.data)
//     const columnList = Object.keys(data[0])
//     for(let index in columnList){
//         const column = columnList[index]
//         columns.push({
//             field: column,
//             headerName: column,
//             width: 180,
//             type: column == "id" ? "string" : "number" // Could do some type checking
//         })
//     }
//     return <Datagrid columns={columns} rows={data}/>
// }

const OperationsList = (props: ListProps) => {
  const { selectedGroupId, selectedPropertyId, user } = useSelector((state) => state.profile)
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  return (
    <List
      {...props}
      filterDefaultValues={{ tableName: 'maintenance', gId: currentGroupId, BuildingId: selectedPropertyId }}
      sort={{ field: 'Name.en', order: 'ASC' }}
      perPage={25}
    >
      <TabbedDatagrid />
    </List>
  )
}

export default OperationsList
