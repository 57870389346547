import React, { useEffect, useState, useRef, useMemo } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { QRCodeFunctionType } from '../../types'
import {
  getCommunicationTemplatesByGroupId,
  getEmergenciesByGroupId,
  getEventTemplates,
  getFieldMarketingTemplates,
  getPurchaseTemplatesByGroupId,
  getSocialMediasByGroupId,
  getTemplatesByGroupId,
  getVacanciesByGroupId,
  getRenewalTemplates,
  getRentalTemplatesByGroupId,
  getMaintenanceTemplates,
  getDigitalQrHub,
  getFrontDeskTemplates,
  getSimpleTemplatesByGroupId,
  getMoveInTemplatesByGroupId,
  getMoveOutTemplatesByGroupId,
  getSupplierCheckinTemplates,
  getCustomerSupportTemplatesByGroupId,
} from '../../apis'
import { QRType, Template } from '@/types/entity'
import { ExpandMore } from '@mui/icons-material'

const API_MAP = {
  [QRCodeFunctionType.SocialMedia]: getSocialMediasByGroupId,
  [QRCodeFunctionType.Feedback]: getTemplatesByGroupId,
  [QRCodeFunctionType.Purchase]: getPurchaseTemplatesByGroupId,
  [QRCodeFunctionType.Communication]: getCommunicationTemplatesByGroupId,
  [QRCodeFunctionType.FieldEvent]: getEventTemplates,
  [QRCodeFunctionType.Vacancy]: getVacanciesByGroupId,
  [QRCodeFunctionType.Renewal]: getRenewalTemplates,
  [QRCodeFunctionType.Maintenance]: getMaintenanceTemplates,
  [QRCodeFunctionType.QrHub]: getDigitalQrHub,
  [QRCodeFunctionType.FrontDesk]: getFrontDeskTemplates,
  [QRCodeFunctionType.SimpleForm]: getSimpleTemplatesByGroupId,
  [QRCodeFunctionType.MoveIn]: getMoveInTemplatesByGroupId,
  [QRCodeFunctionType.MoveOut]: getMoveOutTemplatesByGroupId,
  [QRCodeFunctionType.SupplierCheckin]: getSupplierCheckinTemplates,
  [QRCodeFunctionType.Support]: getCustomerSupportTemplatesByGroupId,
  [QRCodeFunctionType.MoveOutResident]: getMoveOutTemplatesByGroupId,
}
interface Props {
  qrType: QRType
  functionType: QRCodeFunctionType
  entityType?: string
  groupId: string
  templateId?: string
  buildingId: string
  defaultValue?: Template
  onChange: (tem: Template | null) => void
  disabled?: boolean
}
const SelectTemplate: React.FC<Props> = (props) => {
  const language = 'en'
  const { qrType, entityType, groupId, onChange, defaultValue, templateId, buildingId, functionType, disabled } = props
  const lastValue = useRef()
  const [key, setKey] = useState<string>()
  const [templates, setTemplates] = useState<Template[] | null>([])
  const [value, setValue] = React.useState<Template | undefined>(defaultValue)
  const [inputValue, setInputValue] = React.useState('')
  const currentType = useMemo(() => qrType?.FunctionalityType || functionType, [])
  const fetchTemplates = async () => {
    // @ts-ignore
    const data: any = await API_MAP[qrType?.FunctionalityType || functionType]?.({
      gId: groupId,
      et: entityType,
      bId: buildingId,
      qType: qrType?.QrType,
    })
    const list = data?.Templates || data
    setTemplates(list)
    if (templateId) {
      const select = list.find((o: Template) => o.acId === templateId)
      setValue(select)
      setKey(templateId)
    } else if (list?.length === 1) {
      setValue(list[0])
      onChange(Object.assign({}, list[0], { CanSave: true }))
    }
  }

  const fetchTemplateData = async () => {}
  useEffect(() => {
    fetchTemplates()
  }, [entityType, qrType, functionType])

  useEffect(() => {
    fetchTemplateData()
  }, [templateId])

  useEffect(() => {
    if (currentType && lastValue.current) {
      setValue(undefined)
      setInputValue('')
      onChange(null)
      lastValue.current = undefined
    }
  }, [currentType])
  return (
    <Autocomplete
      value={value}
      key={key}
      disableClearable
      disabled={disabled}
      onChange={(event: any, newValue: Template) => {
        // @ts-ignore
        lastValue.current = newValue
        setValue(newValue)
        onChange(Object.assign({}, newValue, { CanSave: true }))
      }}
      groupBy={(option) => option.Type || option.Style || ''} //EntityType
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      id="controllable-states-demo"
      options={templates || []}
      isOptionEqualToValue={(option, value) => option?.acId === templateId || option?.acId === value?.acId}
      getOptionLabel={(option: any) => {
        if (qrType?.FunctionalityType === QRCodeFunctionType.QrHub) {
          return option.Name + ` (${option.Code})`
        }
        return (
          option.Title?.[language] ||
          option.Code ||
          option.Name ||
          (option.PhoneNumber ? `CAS-${option.PhoneNumber}` : '')
        )
      }}
      style={{ margin: '16px 16px 0', width: 'auto' }}
      fullWidth
      popupIcon={<ExpandMore />}
      renderInput={(params) => <TextField {...params} label="Select Template" variant="outlined" />}
    />
  )
}

export default SelectTemplate
