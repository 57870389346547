import React, { useState } from 'react'
import { Grid, Box, Tab, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { TabList, TabPanel, TabContext } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { customerQRList } from '@constant/qrcode'
import CustomerPortalModal from '@component/QRCode/CustomerPortalModal'
import BackButton from '@component/BackButton/BackButton'
import { useLocation } from 'react-router'

export default function CustomerPortal() {
  const classes = useStyles()
  const [value, setValue] = useState('1')
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState<Record<string, string>>({})
  const { pathname } = useLocation()
  const isMarketing = pathname.includes('/marketing')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleClickMopdalOpen = (data: any) => {
    setOpen(true)
    setModalData(data)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box className={classes.marketingOuterBoxClass}>
      {!isMarketing && <BackButton />}

      <TabContext value={value}>
        {!isMarketing && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab className={classes.tabClass} label="Learn & Build" value="1" />
            </TabList>
          </Box>
        )}

        <TabPanel value="1" sx={{ padding: isMarketing ? '0' : '32px 0 0' }}>
          <Grid container spacing={4}>
            {customerQRList.map((cardData, index) => (
              <Grid item lg={4} md={6} xs={12} sm={12} key={cardData.header}>
                <Card onClick={() => handleClickMopdalOpen(cardData)} className={classes.marketingCardClass}>
                  <CardMedia
                    component="img"
                    src={cardData.img}
                    height="130px"
                    sx={{ objectFit: 'contain' }}
                    className={classes.cardMediaClass}
                  />
                  <CardContent className={classes.cardContentClass}>
                    <Typography fontSize="1.5rem" className={classes.marketingTypographyClass}>
                      {cardData.header}
                    </Typography>
                    <Typography component="div" className={classes.marketingCardDescClass}>
                      {cardData.desc}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <CustomerPortalModal data={modalData} open={open} handleClose={handleClose} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  paregraphClass: {
    color: '#386BBF',
    fontSize: '20px',
    margin: '0px',
  },
  toolTipClass: {
    marginRight: '30px !important',
  },
  buttonClass: {
    fontWeight: 600,
    fontSize: 20,
  },
  outerPaperClass: {
    padding: 20,
  },
  innerPaperClass: {
    width: '100%',
    marginBottom: '20px',
    backgroundColor: '#fbfbfb !important',
  },
  boxClass: {
    border: '1px solid #e0e0e0',
    borderRadius: 7,
    padding: '20px',
  },
  marketingOuterBoxClass: {
    minWidth: '100%',
    minHeight: '100%',
    padding: '16px 24px',
    backgroundColor: '#F7F7F7',
  },
  marketingInnerBoxClass: {
    borderBottom: '10px !important',
  },
  tabClass: {
    textTransform: 'capitalize',
    fontweight: 'bold',
  },
  cardMediaClass: {
    backgroundColor: '#e2edff',
    padding: '2em',
  },
  cardContentClass: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '24px',
    height: '170px',
  },
  marketingTypographyClass: {
    marginBottom: '14px !important',
  },
  marketingCardClass: {
    '&:hover': {
      '& img': {
        display: 'none',
      },
      '& div': {
        overflow: 'visible',
        WebkitLineClamp: 'unset',
      },
      backgroundColor: '#e2edff',
    },
    height: '300px',
    borderRadius: '12px !important',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '3px 3px 4px #00000029',
  },
  marketingCardDescClass: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  tableTitleClass: {
    fontSize: '16px !important',
    fontWeight: 'bold !important',
    marginBottom: '10px !important',
    letterSpacing: '0.15px',
  },
}))
