import React, { useCallback, useState } from 'react'
import { Box, Button, Modal, TextField, Typography, IconButton } from '@mui/material'
import { Replay, InsertDriveFile, Close, CancelRounded } from '@mui/icons-material'
import classnames from 'classnames'
import useStyles from './styles'
import SnackBar from '@component/SnackBar'
import Images from '@assets/images'
import { useSelector } from 'react-redux'

import CONFIG from '@constant/config'
import { excelAccept } from '@constant/app'
import { uploadEquipmentFile, uploadFileBase64 } from '@api'
import { toBase64 } from '@/utils'

interface Props {
  onClose: () => void
  onOk: () => void
}

const EquipmentImport: React.FC<Props> = React.memo(({ onClose, onOk }) => {
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const classes = useStyles()
  const [data, setData] = useState({ uploading: false, fileUrl: '', fileName: '', fileType: true })
  const { uploading, fileUrl, fileName, fileType } = data
  const importState = !(uploading || fileUrl)

  const onDelete = () => setData((data) => ({ ...data, fileUrl: '', fileName: '' }))

  const downLoadTemplate = useCallback(() => {
    window.open(CONFIG.equipmentImportTemplate, '_blank')
  }, [])

  const onFileChange = useCallback(async (event: any) => {
    event.preventDefault()
    let file = event.target.files?.[0] as File
    if (!file) {
      file = event.dataTransfer.files[0] as File
    }
    const { name, type } = file
    const fileType = excelAccept.includes(type)
    if (!fileType) {
      return setData((data) => ({ ...data, fileType }))
    }

    setData((data) => ({ ...data, uploading: true, fileType: true }))
    const base64 = await toBase64(file)
    const result: any = await uploadFileBase64({
      fileType: type,
      base64,
      fileName: `${selectedGroupId}/${selectedPropertyId}/Equipment/${Date.now()}_${name}`,
      bucket: CONFIG.s3_Bucket,
    }).finally(() => setData((data) => ({ ...data, uploading: false })))
    setData({ uploading: false, fileUrl: result?.fileUrl, fileName: name, fileType })
  }, [])

  const onImport = () => {
    uploadEquipmentFile({
      FilePath: fileUrl,
      GroupId: selectedGroupId,
      BuildingId: selectedPropertyId,
    })
      .then((res) => {
        SnackBar({ msg: 'Import Successful', type: 'success' })
        onOk()
        onClose()
      })
      .catch(() => {
        SnackBar({ msg: 'fails', duration: 3000 })
      })
  }

  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classnames(classes.body, classes.import)}>
        <Box className={classes.header}>
          <Box>Import Equipment</Box>
          <IconButton onClick={onClose} style={{ padding: 0 }}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>

        <Box onClick={downLoadTemplate} className={`${classes.actionIcon} ${classes.download} `}>
          <img src={Images.iconDownLoad} style={{ marginRight: 6 }} />
          Click here to download Equipment template file
        </Box>

        <Typography className={classes.uploadTitle}>Upload File</Typography>

        <Box className={classes.uploadBody} onDrop={onFileChange} onDragOver={(e) => e.preventDefault()}>
          {!fileUrl && (
            <Box className={classes.imgBox}>
              {uploading ? <Replay htmlColor="#1976D2" /> : <img src={Images.iconImport} />}
            </Box>
          )}

          <input accept={excelAccept} style={{ display: 'none' }} id="AED-file" onChange={onFileChange} type="file" />
          <label htmlFor="AED-file" style={{ display: importState ? '' : 'none' }}>
            <Typography className={classes.uploadText}>
              Drag & drop or <span style={{ color: '#1976D2' }}>choose file</span> to upload
            </Typography>
          </label>

          {uploading && (
            <Typography className={classes.uploadText} style={{ color: '#1976D2' }}>
              Uploading...
            </Typography>
          )}

          <Typography style={{ display: importState ? '' : 'none' }} className={classes.xlsx}>
            XLSX format
          </Typography>

          {!fileType && (
            <Box className={classes.fileName} style={{ width: 'auto', padding: '6px 12px' }}>
              <img src={Images.iconWarn} width="18" />
              <Typography style={{ paddingLeft: 16, color: '#E04F4F' }}>
                Incorrect file type, please try again
              </Typography>
            </Box>
          )}

          {!!fileUrl && (
            <Box className={classes.fileName}>
              <InsertDriveFile htmlColor="#1976D2" />
              <Typography style={{ flex: 1, paddingLeft: 16 }}>{fileName}</Typography>
              <CancelRounded htmlColor="rgba(0,0,0,0.5)" onClick={onDelete} />
            </Box>
          )}
        </Box>

        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={onClose} className={classes.modalBtn}>
            Cancel
          </Button>

          <Button
            onClick={onImport}
            variant="contained"
            color="primary"
            className={classes.modalBtn}
            disabled={!fileUrl}
          >
            IMPORT
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

EquipmentImport.displayName = 'EquipmentImport'
export default EquipmentImport
