import React, { useEffect, useState, useCallback, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import 'react-phone-input-2/lib/material.css'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'
import { GetRoles } from '@api'
import MobilePhone from './MobilePhone'
import EditAndSave from '@component/Button/EditAndSave'
const tableHead = ['Name', 'User Role', 'Phone', 'Email', 'Title']

interface DataItem {
  Name: string
  Role: string
  Phone: string
  Email: string
  Title: string
}

const informationData = [{ name: 'Name' }, { name: 'Role' }, { name: 'Phone' }, { name: 'Email' }, { name: 'Title' }]

const sourceData: DataItem[] = [
  {
    Name: '',
    Role: '',
    Phone: '',
    Email: '',
    Title: '',
  },
]

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
      boxShadow: '0px 3px 2px #00000029',
      borderRadius: '0px 0px 4px 4px',
      padding: '32px 16px',
    },
    information: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    textField: {
      width: '100%',
      border: 'none',
    },
    cell: {
      width: '20%',
      height: 30,
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    shrink: {
      backgroundColor: '#fff',
      padding: '0 5px',
    },
    asterisk: {
      color: '#ff0000',
    },
    select: {
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    phoneInput: {
      margin: '0 !important',
    },
  }
})

interface Props {
  informationRef: React.MutableRefObject<{ informationData: DataItem[] }>
  isEdit: boolean
  editData: any
  onSave: () => void
}

const PropertyContacts = (props: Props) => {
  const { informationRef, isEdit, editData, onSave } = props
  const classes = useStyles()
  const [contactsData] = useState(JSON.parse(JSON.stringify(sourceData)))
  const [, setRender] = useState(false)
  const [option, setOption] = useState<any[]>([])
  const [onlyView, setOnlyView] = useState(isEdit)

  const onCancel = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
    const { name, value } = event.target
    // @ts-ignore
    contactsData[idx][name] = value
    setRender((v) => !v)
  }, [])

  const onAdd = () => {
    contactsData.push({
      Name: '',
      Role: '',
      Phone: '',
      Email: '',
      Title: '',
    })
    setRender((v) => !v)
  }

  const onChangePhone = useCallback((phoneStr: string, idx: number) => {
    contactsData[idx]['Phone'] = phoneStr
    setRender((v) => !v)
  }, [])

  useEffect(() => {
    GetRoles({}).then((res: any) => setOption(res))
  }, [])

  useImperativeHandle(
    informationRef,
    () => ({
      ...informationRef.current,
      contactsData,
    }),
    []
  )

  useEffect(() => {
    if (isEdit) {
      editData?.Contacts?.forEach((v, i) => {
        contactsData[i] = v
      })
      setRender((v) => !v)
    }
  }, [isEdit, editData])

  return (
    <Box className={`${classes.content} ${classes.information}`}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHead.map((v) => (
                <TableCell key={v} classes={{ root: classes.cell }}>
                  {v}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contactsData.map((row: any, i) => (
              <TableRow key={i}>
                {informationData.map((v, j) => {
                  const { name } = v
                  return (
                    <TableCell key={j} classes={{ root: classes.cell }}>
                      {name === 'Phone' ? (
                        // @ts-ignore
                        <MobilePhone
                          showFirst={false}
                          index={i}
                          key={name}
                          {...v}
                          disabled={onlyView}
                          value={row[name]}
                          className={classes.phoneInput}
                          onChangePhone={(value) => onChangePhone(value, i)}
                        />
                      ) : (
                        <TextField
                          name={name}
                          select={j === 1}
                          value={row[name]}
                          variant="outlined"
                          className={classes.textField}
                          disabled={onlyView}
                          SelectProps={{
                            displayEmpty: true,
                            classes: {
                              select: classes.select,
                            },
                            type: 'search',
                          }}
                          onChange={(e) => handleChange(e, i)}
                        >
                          {option.map((v) => {
                            return (
                              <MenuItem key={v} value={v}>
                                {v}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" style={{ marginTop: 16 }} onClick={onAdd} disabled={onlyView}>
        ADD NEW ROW
      </Button>
      <EditAndSave showSave={!onlyView} onCancel={onCancel} onEdit={onEdit} onSave={onSave} />
    </Box>
  )
}
export default React.memo(PropertyContacts)
