import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import { useTranslate, useLocaleState, useSetLocale, Title } from 'react-admin'
import { makeStyles } from '@mui/styles'
import { changeTheme } from '@reducer/setting'

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
})

const Configuration = () => {
  const translate = useTranslate()
  const [locale] = useLocaleState()
  const setLocale = useSetLocale()
  const classes = useStyles()
  const theme = useSelector((state) => state.setting.theme)
  console.log('theme', theme)
  const dispatch = useDispatch()
  return (
    <Card>
      <Title title={translate('pos.configuration')} />
      <CardContent>
        <div className={classes.label}>{translate('pos.theme.name')}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'light' ? 'primary' : 'inherit'}
          onClick={() => dispatch(changeTheme('light'))}
        >
          {translate('pos.theme.light')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'dark' ? 'primary' : 'inherit'}
          onClick={() => dispatch(changeTheme('dark'))}
        >
          {translate('pos.theme.dark')}
        </Button>
      </CardContent>
      <CardContent>
        <div className={classes.label}>{translate('pos.language')}</div>
        <Button variant="contained" className={classes.button} color={'primary'} onClick={() => setLocale('en')}>
          en
        </Button>
        <Button variant="contained" className={classes.button} color={'primary'} onClick={() => setLocale('fr')}>
          fr
        </Button>
      </CardContent>
    </Card>
  )
}

export default Configuration
