let template = {}

export function clearDefaultData() {
  template = {}
}

export function setDefaultData(key, value) {
  Object.assign(template, { [key]: value })
}

export function getDefaultData(key) {
  return template?.[key]
}
