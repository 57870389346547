import React, { useState, useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { Map } from '@activate-inc/activate-ui'
import { FormDataContext } from '../../context/FormDataContext'

const GoogleMapWrapper = ({ isHubPanel, data, entityId, functionalityType, qrTypeName }) => {
  const { mapData: { Latitude = '', Longitude = '', DestinationType = '' } = {}, updateMapData } =
    useContext(FormDataContext)
  const [latitude, setLatitude] = useState(isHubPanel ? data?.Latitude : Latitude)
  const [longitude, setLongitude] = useState(isHubPanel ? data?.Longitude : Longitude)
  const [destinationType, setDestinationType] = useState(isHubPanel ? data?.DestinationType : DestinationType)
  const keyword = isHubPanel ? qrTypeName : ''
  const buildingCenter = {
    lat: Number(latitude),
    lng: Number(longitude),
  }

  useEffect(() => {
    if (data && typeof data !== 'string') {
      const { Latitude, Longitude, DestinationType } = data
      setLatitude(isHubPanel ? data?.Latitude : Latitude)
      setLongitude(isHubPanel ? data?.Longitude : Longitude)
      setDestinationType(isHubPanel ? data?.DestinationType : DestinationType)
      updateMapData?.({ Latitude, Longitude, DestinationType })
    }
  }, [data])

  const checkFormStatus = () => {
    if (isHubPanel) {
      return !latitude || !longitude || !destinationType
    }
    return !Latitude || !Longitude || !DestinationType
  }

  if (checkFormStatus()) {
    return (
      <Box sx={{ display: 'flex', mt: 2, width: 310, height: 300, justifyContent: 'center' }}>
        Please fill in required fields.
      </Box>
    )
  }

  return (
    <Box sx={{ mt: 2, width: 365, height: '100%' }}>
      <Map
        apiKey="AIzaSyBzyaT5Rk22FbDRNvMWDETsKY9Pk1AruM4"
        center={buildingCenter}
        zoom={17}
        type={destinationType}
        keyword={keyword}
      />
    </Box>
  )
}

export default GoogleMapWrapper
