import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import WithDefaultData from '@/hoc/WithDefaultData'
import UploadFile from '@component/Form/UploadFile'
import { FormControlLabel, Switch, Box, TextField, IconButton, Autocomplete, Button } from '@mui/material'
import { AddCircle, ExpandMore, Menu } from '@mui/icons-material'
import useSetState from '@hooks/useSetState'
import { QRCodeFunctionType } from '@/types'
import { isCanSavaQr } from '@/utils'
import { reorder } from '@/utils/helpers'
import Images from '@/assets/images'
import { getQrHubsByGroupId } from '@api'
import { DragDropContext, Droppable, DropResult, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

const language = 'en'
const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})
const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    },
    upload: {
      marginTop: 20,
    },
    switch: {
      alignSelf: 'self-start',
      marginLeft: 0,
    },
    carousel: {
      border: '1px solid #BCBCBC',
      borderRadius: 8,
      padding: 24,
    },
    logoLabel: {
      fontSize: 20,
      color: '#211F1F',
      fontWeight: 600,
      lineHeight: '26px',
    },
    url: {
      display: 'flex',
      alignItems: 'center',
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    margin: {
      marginTop: 0,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    itemText: {
      flex: 1,
      marginRight: 16,
    },
    date: {
      margin: '20px 0 0',
    },
    disabled: {
      fontSize: 12,
      color: '#777',
      lineHeight: '21px',
    },
    add: {
      justifyContent: 'flex-start',
      color: '#386BBF',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    underline: {
      '&:before': {
        borderWidth: 0,
      },
    },
  }
})

interface Props {
  value: any
  groupId: string
  buildingId: string
  functionType: QRCodeFunctionType
  onChange?: (data: any) => void
  disabledEdit?: boolean
}
interface HubItemProps {
  HubId: string
  HubName: string
  ItemId: string
  ButtonName: string
  Name: string
}

const RoofAccessCarousel: React.FC<Props> = ({
  value,
  groupId,
  buildingId,
  onChange,
  functionType,
  disabledEdit,
  ...extra
}) => {
  const classes = useStyles()
  const [data, setData] = useSetState({
    TermConditionUrl: value?.TermConditionUrl,
    EnableSignature: value?.EnableSignature,
    EnableSelfie: value?.EnableSelfie === undefined ? true : value?.EnableSelfie,
    Carousel: (value?.Carousel || []) as HubItemProps[],
  })
  const [state, setState] = useSetState({ HubList: [] as any[], HubId: value?.Carousel?.[0]?.HubId || '' })
  const { TermConditionUrl, EnableSignature, EnableSelfie, Carousel } = data
  const { HubList, HubId } = state
  const { Name: HubName, Items: HubItems = [] } = HubList.find((v) => v.acId === HubId) || {}
  const onChangeFile = useCallback(
    (file: string) => {
      const newData = Object.assign({}, value, data, { TermConditionUrl: file, CanSave: true })
      setData({ TermConditionUrl: file })
      onChange?.(newData)
    },
    [onChange, setData, value, data]
  )
  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = event.target.name
    const newData = Object.assign({}, value, data, { [name]: checked })
    Object.assign(newData, { CanSave: isCanSavaQr(functionType, newData) })
    //@ts-ignore
    setData({ [name]: checked })
    onChange?.(newData)
  }

  const onChangeHub = (value: { acId: string; Name: string }) => {
    const { acId: HubId = '', Name: HubName } = value
    setState({ HubId })
    setData({ Carousel: [{ HubId, HubName, ItemId: '', ButtonName: '', Name: '' }] })
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Carousel || [], source.index, destination.index)
    setData({ Carousel: newItems })
  }

  const onRemove = (index: number) => {
    Carousel.splice(index, 1)
    setData({ Carousel: [...Carousel] })
  }

  const onItemChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const { value } = event.target
    Carousel[index]['Name'] = value
    setData({ Carousel: [...Carousel] })
  }

  const onAdd = () => {
    Carousel.push({ HubId, HubName, ItemId: '', ButtonName: '', Name: '' })
    setData({ Carousel: [...Carousel] })
  }
  useEffect(() => {
    getQrHubsByGroupId({ gId: groupId, bId: buildingId, type: 'CustomHub' }).then((res: any) => {
      setState({ HubList: res })
    })
  }, [])
  useEffect(() => {
    setData((pre) => {
      const newData = Object.assign({}, value, pre)
      Object.assign(newData, { CanSave: isCanSavaQr(functionType, newData) })
      onChange?.(newData)
      return pre
    })
  }, [functionType, onChange, setData, Carousel])
  return (
    <div className={classes.container}>
      <UploadFile
        className={classes.upload}
        value={TermConditionUrl}
        filePath={`Teams/${groupId}/${buildingId}`}
        onChange={onChangeFile}
        disabledInput
        label="Terms & Conditions (optional)"
      />
      <FormControlLabel
        className={classes.switch}
        control={<Switch checked={EnableSignature} onChange={onChangeSwitch} name="EnableSignature" />}
        label="Finger Signature"
        labelPlacement="start"
      />
      <FormControlLabel
        className={classes.switch}
        control={<Switch checked={EnableSelfie} onChange={onChangeSwitch} name="EnableSelfie" />}
        label="Selfie"
        labelPlacement="start"
      />
      <Box sx={{ borderTop: '1px solid #BCBCBC', margin: '24px 0' }} />

      <Box className={classes.carousel}>
        <Box className={classes.logoLabel}>Carousel</Box>
        <Autocomplete
          value={HubList.find((v) => v.acId === HubId) || null}
          onChange={(e, value) => onChangeHub(value)}
          id="hub"
          options={HubList}
          getOptionLabel={(option) => option.Name || ''}
          style={{ width: '100%', marginBottom: '16px', marginTop: '24px' }}
          renderInput={(params) => <TextField {...params} label="Hub Name" variant="outlined" />}
          popupIcon={<ExpandMore />}
        />
        <Box className={classes.logoLabel} style={{ fontSize: 16, fontWeight: '400', marginBottom: '16px' }}>
          Carousel
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {Carousel.map((item: HubItemProps, index: number) => (
                  <Draggable isDragDisabled={disabledEdit} key={item?.ItemId} draggableId={item?.ItemId} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        //@ts-ignore
                        style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                      >
                        <Box className={classes.item}>
                          <Autocomplete
                            value={HubItems.find((v) => v.Id === item?.ItemId) || null}
                            disabled={disabledEdit || !HubId}
                            onChange={(e, value) => {
                              Carousel[index]['ItemId'] = value.Id
                              Carousel[index]['ButtonName'] = value.Name
                              Carousel[index]['Name'] = value.Name
                              setData({ Carousel: [...Carousel] })
                            }}
                            getOptionDisabled={(option) => Carousel.some((v) => v.ItemId === option.Id)}
                            className={classes.itemText}
                            options={HubItems}
                            getOptionLabel={(option) => option.Name || ''}
                            renderInput={(params) => (
                              <TextField {...params} label={`Carousel ${index + 1}`} variant="outlined" />
                            )}
                            popupIcon={<ExpandMore />}
                          />
                          <TextField
                            className={classes.itemText}
                            label={'Carousel Name'}
                            disabled={disabledEdit}
                            value={item.Name || ''}
                            onChange={(e) => onItemChange(e, index)}
                            variant="outlined"
                          />
                          <IconButton onClick={() => onRemove(index)} disabled={disabledEdit}>
                            <img src={Images.iconDelete} alt="delete" />
                          </IconButton>
                          <div {...draggableProvided.dragHandleProps}>
                            <IconButton disabled={disabledEdit}>
                              <Menu htmlColor="#466CB5" />
                            </IconButton>
                          </div>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button aria-label="add" className={classes.add} disabled={disabledEdit || !HubId} onClick={onAdd}>
          <AddCircle color="primary" style={{ marginRight: 10 }} />
          Add New
        </Button>
      </Box>
    </div>
  )
}

export default WithDefaultData(RoofAccessCarousel)
// export default RoofAccessCarousel
