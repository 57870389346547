import * as React from 'react'
import { memo, forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, AppBar, AppBarProps as MuiAppBarProps, Toolbar, useMediaQuery, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ComponentPropType } from 'ra-core'
import { UserMenu as DefaultUserMenu } from 'ra-ui-materialui'
import { HideOnScroll } from 'ra-ui-materialui'
import { MenuItemLink, usePermissions, useTranslate } from 'react-admin'
import SettingsIcon from '@mui/icons-material/Settings'
import MyUserMenu from './CustomUserMenu'
import { AccountSearch } from '@/dashboard/AdminSearch'
import { PropertySearch } from '@/dashboard/PropertySearchBar'

import Logo from './Logo'
import LogoutButton from '@component/Auth/LogoutButton'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Loading } from 'ra-ui-materialui'

const AccountSearchStyle = { width: '100%', maxWidth: 300, marginLeft: 20 }

const useStyles = makeStyles(
  (theme: Theme) => ({
    toolbar: {
      height: 120,
      paddingRight: 24,
      backgroundColor: '#386BBF',
    },
    menuButton: {
      [theme.breakpoints.up('sm')]: {
        width: 50,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: '0.2em',
      marginRight: '0.2em',
    },
    menuButtonIconClosed: {},
    menuButtonIconOpen: {},
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    spacer: {
      flex: 1,
    },
    logoBox: {
      display: 'flex',
      width: '100%',
      padding: '10px 16px',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    logo: {
      //    marginLeft: -100
    },
  }),
  { name: 'RaAppBar' }
)

// eslint-disable-next-line react/display-name
const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate()
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  )
})

// eslint-disable-next-line react/display-name
const AdminSearchMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate()
  return (
    <MenuItemLink
      ref={ref}
      to="/adminsearch"
      primaryText={translate('pos.search')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  )
})

// eslint-disable-next-line react/display-name
const PropertySearchMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate()
  return (
    <MenuItemLink
      ref={ref}
      to="/propertySearch"
      primaryText={'Change Property'}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  )
})

const CustomUserMenu = (props: any) => {
  return (
    <MyUserMenu {...props}>
      <LogoutButton />
    </MyUserMenu>
  )
}

const AppBarExtend = (props: AppBarProps): JSX.Element => {
  const {
    children,
    className,
    color = 'secondary',
    logout,
    open,
    title,
    userMenu,
    container: Container,
    ...rest
  } = props
  const classes = useStyles(props)
  const location = useLocation()
  const { selectedPropertyName, user } = useSelector((state) => state.profile)

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const disabled = location.pathname.includes('/surveyWelcome')
  const { hiddenBarProperty, showLoginGroup } = useMemo(() => location.state || {}, [location.state])
  const hiddenProperty = disabled || hiddenBarProperty

  if (!user) {
    return <Loading />
  }
  return (
    <AppBar className={className} color={color} {...rest}>
      <Toolbar disableGutters variant={isXSmall ? 'regular' : 'dense'} className={classes.toolbar}>
        <Box className={classes.logoBox}>
          <Logo />
          <AccountSearch
            style={AccountSearchStyle}
            isBarSearch
            onlyShowDefault={disabled || (showLoginGroup as boolean)}
          />
          {!hiddenProperty && <PropertySearch style={AccountSearchStyle} isBarSearch />}
        </Box>
        <CustomUserMenu logout={logout} />
      </Toolbar>
    </AppBar>
  )
}

AppBarExtend.propTypes = {
  children: PropTypes.node,
  // @ts-ignore
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
  container: ComponentPropType,
  logout: PropTypes.element,
  // @deprecated
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
}

AppBarExtend.defaultProps = {
  userMenu: <DefaultUserMenu />,
  container: HideOnScroll,
}

export interface AppBarProps extends Omit<MuiAppBarProps, 'title' | 'classes'> {
  container?: React.ElementType<any>
  logout?: React.ReactNode
  // @deprecated
  open?: boolean
  title?: string | JSX.Element
  userMenu?: JSX.Element | boolean
}

export default memo(AppBarExtend)
