import React from 'react'
import { DownloadApp } from '@activate-inc/activate-ui'

const DownloadAppWrapper = (props: any) => {
  const { data, qrTypeName } = props || {}

  if (data) {
    const { LogoFilename = '', AppStoreUrl = '', PlayStoreUrl = '' } = data
    return (
      <DownloadApp qrTypeName={qrTypeName} logo={LogoFilename} appStoreUrl={AppStoreUrl} playStoreUrl={PlayStoreUrl} />
    )
  }
  return null
}

export default DownloadAppWrapper
