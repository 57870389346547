import { FieldEventSuccess, FieldForm } from '@activate-inc/activate-ui'
import React, { useCallback, useState } from 'react'
import { Field } from '@activate-inc/activate-ui/dist/types/components/types'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { EntityITF } from '@/types/entity'
import { QRCodeFunctionTypeName } from '@constant/app'
import { QRCodeFunctionType } from '@/types'

interface Props {
  data: Field[]
  entity: EntityITF | null
  language: LanguageType
  qrTypeName: string
}

const FieldEventWrapper: React.FC<Props> = ({ data, language, entity, qrTypeName }) => {
  const [submit, setSubmit] = useState(false)
  const { Name, Location, LogoFilename } = entity || {}
  const onSubmit = useCallback(() => {
    setSubmit(true)
  }, [])
  const { text, subText } = QRCodeFunctionTypeName[QRCodeFunctionType.FieldEvent]

  if (submit) {
    return <FieldEventSuccess subTitle={Name?.[language]} desc={Location} qrTypeName={qrTypeName} />
  }
  return (
    <FieldForm
      disabledVerify
      data={data}
      qrTypeName={qrTypeName}
      language={language}
      btnText="Register"
      btnWidth="100%"
      onSubmit={onSubmit}
      isCenter
    />
  )
}

export default FieldEventWrapper
