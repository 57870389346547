import React, { useCallback, useEffect, useMemo } from 'react'
import BackHeader from '@component/Header/BackHeader'
import { makeStyles } from '@mui/styles'
import PeriodSelect from '@component/DateSelect/PeriodSelect'
import { DEFAULT_PERIOD_YEAR_TO_DATE } from '@/global'
import Breadcrumbs, { BreadcrumbsLink } from '@component/Header/Breadcrumbs'
import Images from '@assets/images'
import { formatNumber, formatSymbolNumber } from '@utils/common'
import useSetState from '@hooks/useSetState'
import { getCustomerFeedbacks, getCustomerFeedbackSummariesList, getCustomerFeedbackSummary } from '@api'
import CommonCard from '@component/Card/CommonCard'
import { Box, Typography } from '@mui/material'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import { useLocation } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: '0 24px 18px',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 98,
    paddingTop: 24,
  },
  topCard: {
    display: 'flex',
    marginTop: 22,
  },
  card: {
    marginTop: 25,
    borderRadius: 8,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: 20,
    color: '#211F1F',
    marginBottom: 12,
  },
}))
const ACTIVATE_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Account Name',
    convertItem: (item) => item.GroupName?.en,
  },
  {
    label: 'CSAT',
    source: 'CSAT',
    isNumber: true,
  },
  {
    label: 'CES',
    source: 'CES',
    isNumber: true,
  },
  {
    label: 'NPS',
    source: 'NPS',
    align: 'center',
    convertItem: (item) => formatSymbolNumber(item.NPS),
  },
]
const GROUP_CONFIG = [...ACTIVATE_CONFIG]
GROUP_CONFIG[0] = {
  label: 'Property Name',
  convertItem: (item) => item.BuildingName?.en,
}
const FEEDBACKS_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Name',
    source: 'UserName.en',
    convertItem: (item) => item.UserName?.en,
    width: 200,
  },
  {
    label: 'Company name',
    source: 'Company',
    align: 'left',
    width: 200,
  },
  {
    label: 'Date',
    align: 'left',
    formatKey: 'keyboardDate',
    source: 'CreatedDate',
    width: 163,
    isDate: true,
  },
  {
    label: 'User Role',
    align: 'left',
    source: 'Role',
    width: 163,
  },
  {
    label: 'CSAT',
    source: 'Score',
    width: 74,
  },
  {
    label: 'CES',
    source: 'CustomerEffortScore',
    width: 74,
  },
  {
    label: 'NPS',
    source: 'NetPromoterScore',
    align: 'center',
    width: 74,
    convertItem: (item) => item.NetPromoterScore,
  },
  {
    label: 'Comment',
    source: 'Comments',
  },
]

enum PAGE_TYPE {
  Activate = 'Activate',
  Group = 'Group',
  Property = 'Property',
}

interface SummariesListProps {
  period: IOption
  gId?: string
  isGroup: boolean
}

const SummariesList: React.FC<SummariesListProps> = ({ period, gId, isGroup }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [data, setData] = useSetState<{ summariesList: any[] }>({ summariesList: [] })
  const { summariesList } = data
  const fetchFeedbackSummariesList = useCallback(async () => {
    const params = { tu: period.value, scope: 'All' }
    if (gId) {
      params.scope = 'Group'
      Object.assign(params, { gId })
    }
    const result = await getCustomerFeedbackSummariesList<{ Summaries: any[] }>(params)
    setData({ summariesList: result?.Summaries || [] })
  }, [period.value])
  const onClickRow = useCallback((item) => {
    if (isGroup) {
      navigate(`/activateReport/${item.GroupId}/${item.BuildingId}`, {
        state: {
          _scrollToTop: true,
          type: PAGE_TYPE.Property,
          groupName: item.GroupName?.en,
          buildingName: item.BuildingName?.en,
        },
      })
    } else {
      navigate(`/activateReport/${item.GroupId}`, {
        state: {
          _scrollToTop: true,
          type: PAGE_TYPE.Group,
          groupName: item.GroupName?.en,
        },
      })
    }
  }, [])
  useEffect(() => {
    fetchFeedbackSummariesList()
  }, [fetchFeedbackSummariesList])
  return (
    <Box className={classes.card}>
      <Typography className={classes.title}>{isGroup ? 'Properties Report' : 'Account Report'}</Typography>
      <TableList
        onClickRow={onClickRow}
        config={isGroup ? GROUP_CONFIG : ACTIVATE_CONFIG}
        data={summariesList}
        tableLayout="fixed"
      />
    </Box>
  )
}

interface FeedBacksProps {
  period: IOption
  gId?: string
  bId: string
  title: string
}

const FeedBacks: React.FC<FeedBacksProps> = ({ period, gId, bId, title }) => {
  const classes = useStyles()
  const [data, setData] = useSetState<{ feedbacks: any[] }>({ feedbacks: [] })
  const { feedbacks } = data
  const fetchCustomerFeedbacks = useCallback(async () => {
    const params = { tu: period.value, gId, bId }
    const result = await getCustomerFeedbacks<{ CustomerFeedbacks: any[] }>(params)
    setData({ feedbacks: result?.CustomerFeedbacks || [] })
  }, [period.value, gId, bId])
  useEffect(() => {
    fetchCustomerFeedbacks()
  }, [fetchCustomerFeedbacks])
  return (
    <Box className={classes.card}>
      <Typography className={classes.title}>{title}</Typography>
      <TableList config={FEEDBACKS_CONFIG} data={feedbacks} />
    </Box>
  )
}
const CustomerFeedbackReport: React.FC = () => {
  const location = useLocation()
  const { bId = '', gId } = useParams()
  const navigate = useNavigate()
  const { type, groupName, buildingName } = useMemo(() => location.state || {}, [location.state])
  const classes = useStyles()
  const [data, setData] = useSetState<{ top: any; period: IOption }>({
    top: null as any,
    period: DEFAULT_PERIOD_YEAR_TO_DATE,
  })
  const links = useMemo<BreadcrumbsLink[]>(() => {
    const linkArr = [
      {
        text: 'Activate',
      },
    ]
    if (groupName) {
      linkArr.push({
        text: groupName,
      })
    }
    if (buildingName) {
      linkArr.push({
        text: buildingName,
      })
    }
    return linkArr
  }, [])
  const { period, top } = data
  const onChange = useCallback((value) => {
    setData({ period: value })
  }, [])
  const fetchCustomerFeedbackSummary = useCallback(async () => {
    const params = { tu: period.value }
    if (bId) Object.assign(params, { bId })
    if (gId) Object.assign(params, { gId })
    const result = await getCustomerFeedbackSummary(params)
    setData({ top: result })
  }, [period.value, bId, gId])
  const onClick = useCallback(
    (item: BreadcrumbsLink, index: number) => {
      if (index === 0 && links.length > 2) {
        navigate(-2)
      } else {
        navigate(-1)
      }
    },
    [links]
  )
  useEffect(() => {
    fetchCustomerFeedbackSummary()
  }, [fetchCustomerFeedbackSummary])
  return (
    <div className={classes.paper}>
      <BackHeader title="Activate Customer Feedback" disabled={!type} />
      <div className={classes.container}>
        <div className={classes.top}>
          <Breadcrumbs links={links} onClick={onClick} />
          <PeriodSelect period={period} onChange={onChange} label="Date" />
        </div>
        <div className={classes.topCard}>
          <CommonCard label="CSAT" value={formatNumber(top?.CSAT)} loading={!top} desc="Out of 5" icon={Images.star} />
          <CommonCard isPercent value={top?.CES / 7} valueText={top?.CES || '0'} label="CES" loading={!top} />
          <CommonCard label="NPS" value={formatSymbolNumber(top?.NPS || 0)} loading={!top} icon={Images.thumbUp} />
        </div>
        {type === PAGE_TYPE.Property ? (
          <FeedBacks gId={gId} bId={bId} period={period} title={`${buildingName || ''} Report`} />
        ) : (
          <SummariesList period={period} isGroup={type === PAGE_TYPE.Group} gId={gId} />
        )}
      </div>
    </div>
  )
}

export default CustomerFeedbackReport
