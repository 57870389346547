import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getQrCodeDetail, getSolutionBuilderById } from '@api'
import { useParams } from 'react-router-dom'
import usePuppeteer from '@hooks/usePuppeteer'
import { BuilderSolutionITF, BuilderSolutionPageITF } from '@component/Modal/BuildSolutionModal'
import Images from '@assets/images'
import Config from '@constant/config'
import { QrCodeInfo } from '@/types/entity'
import MobileFrame from '@component/MobileFrame/MobileFrame'
import { DynamicComponentsMaps } from '@/qrcodes/DynamicForm'
import { QRCodeFunctionType } from '@/types'
import { PDF_MAP } from '@constant/app'
import { CommunicationMockData } from '@constant/mock'

const useStyles = makeStyles(() => {
  return {
    root: {
      background: '#FFF',
      // padding: 24,
      fontFamily: 'Roboto',
      width: '1920px',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 'bold',
      color: '#466CB5',
      marginBottom: 24,
      textAlign: 'right',
    },
    bg: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      objectFit: 'cover',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    page: {
      position: 'relative',
      pageBreakBefore: 'always',
      breakBefore: 'always',
      height: 1080,
      overflow: 'hidden',
      backgroundColor: '#8FA9D8',
    },
    cover: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 1080,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.40)0%, rgba(0, 0, 0, 0.90)100%)',
    },
    coverPageName: {
      flex: 1,
      width: '100%',
      whiteSpace: 'pre-line',
      textAlign: 'center',
      padding: '0 160px',
      marginTop: 160,
      fontWeight: 900,
      fontSize: 160,
      color: 'white',
      alignContent: 'center',
    },
    logoBox: {
      display: 'flex',
      paddingBottom: 80,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
    },
    itemIcon: {
      width: 120,
      height: 120,
      marginRight: 28,
    },
    itemRight: {
      display: 'flex',
      flexDirection: 'column',
    },
    itemLabel: {
      fontSize: 24,
    },
    itemNum: {
      fontSize: 56,
      fontWeight: 900,
    },
    itemLine: {
      height: 50,
      backgroundColor: '#313131',
      width: 2,
      margin: '0 48px',
    },
    hubBg: {
      display: 'flex',
      height: 700,
      position: 'relative',
    },
    hubContent: {
      position: 'relative',
      zIndex: 99,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(36, 56, 93, 0.84)',
    },
    qrName: {
      marginLeft: 80,
      marginTop: 88,
      width: 1020,
      fontSize: 72,
      fontWeight: 900,
      textAlign: 'center',
      color: 'white',
    },
    qrCode: {
      position: 'absolute',
      bottom: 80,
      left: 415,
      width: 360,
      height: 360,
      borderRadius: 24,
    },
    hubBottom: {
      height: 224,
      display: 'flex',
      backgroundColor: 'white',
      paddingLeft: 80,
      alignItems: 'center',
    },
    noType: {
      textAlign: 'center',
      fontFamily: 'Roboto',
      marginTop: 200,
      fontSize: 16,
      fontWeight: 400,
    },
    qrMobile: {
      zIndex: 100,
      position: 'absolute',
      right: 160,
      top: 72,
    },
  }
})

interface Params {
  sId: string
}

interface TipItem {
  icon: string
  label: string
  num: number
  hiddenLine: boolean
}
const TipItem: React.FC<TipItem> = ({ icon, label, num, hiddenLine }) => {
  const classes = useStyles()
  return (
    <Box className={classes.item}>
      <img src={icon} className={classes.itemIcon} />
      <Box className={classes.itemRight}>
        <Typography className={classes.itemNum}>{num}</Typography>
        <Typography className={classes.itemLabel}>{label}</Typography>
      </Box>
      {!hiddenLine && <Box className={classes.itemLine} />}
    </Box>
  )
}
interface QrItemProp {
  index: number
  buildingUrl?: string
  item: BuilderSolutionPageITF
  coverPageName?: string
}
const showList = (number?: number) => {
  return number || typeof number !== 'undefined'
}
const QrItem: React.FC<QrItemProp> = ({ index, buildingUrl, item, coverPageName }) => {
  const classes = useStyles()
  const [qrInfo, setQrInfo] = useState<QrCodeInfo>()
  const { BackgroundImage, CustomPages, Functions, Integrations, QrCodeId, AlternativeName } = item
  const list = [
    { label: 'Custom Pages', icon: Images.iconPages, num: CustomPages, show: showList(CustomPages) },
    { label: 'Functions', icon: Images.iconFunctions, num: Functions, show: showList(Functions) },
    { label: 'Integrations', icon: Images.iconIntegrations, num: Integrations, show: showList(Integrations) },
  ].filter((item) => item.show)
  const getData = useCallback(async () => {
    const result: any = await getQrCodeDetail({ qrId: QrCodeId })
    setQrInfo(result)
  }, [QrCodeId])
  useEffect(() => {
    getData()
  }, [getData])
  const functionType = qrInfo?.FunctionalityType
  const getFromProps = (functionalityType: string | undefined) => {
    const newTemplate = qrInfo?.TemplateData
    const qrTypeName = qrInfo?.Name
    const baseProps = {
      functionalityType: functionalityType,
      isHubPanel: true,
      qrType: functionalityType,
      entity: qrInfo?.Entity,
      entityId: qrInfo?.Entity?.acId,
      language: 'en',
      logo: qrInfo?.HeaderLogo,
      selectedPropertyName: coverPageName,
      qrTypeName,
    }
    if (functionalityType === QRCodeFunctionType.QrHub) {
      Object.assign(baseProps, { data: newTemplate, bg: newTemplate?.HeaderImage })
    }
    switch (functionalityType) {
      case QRCodeFunctionType.Static:
      case QRCodeFunctionType.EmbeddedStaticUrl:
      case QRCodeFunctionType.PDF:
      case QRCodeFunctionType.Video: {
        // @ts-ignore
        const prop = PDF_MAP[functionType] || {}
        // @ts-ignore
        const url = newTemplate?.Url
        return {
          ...baseProps,
          url: url,
          title: newTemplate?.Title,
          ...prop,
          isPdf: QRCodeFunctionType.PDF === functionalityType,
        }
      }
      case QRCodeFunctionType.EmbeddedFile: {
        // @ts-ignore
        const url = newTemplate?.Url
        return { ...baseProps, url: url }
      }
      case QRCodeFunctionType.SimpleForm:
        return { ...baseProps, data: newTemplate?.Fields || newTemplate || [] }
      case QRCodeFunctionType.Html:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.SocialMedia:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.CustomerFeedback:
      case QRCodeFunctionType.Feedback:
        return { ...baseProps, data: newTemplate?.Questions }
      case QRCodeFunctionType.Purchase:
      case QRCodeFunctionType.Communication:
        return { ...baseProps, data: CommunicationMockData.Fields, title: CommunicationMockData.title }
      case QRCodeFunctionType.SupplierCheckin:
      case QRCodeFunctionType.StudentCheckin:
      case QRCodeFunctionType.GuestCheckin:
      case QRCodeFunctionType.TourCheckin:
      case QRCodeFunctionType.RoofAccess:
      case QRCodeFunctionType.RoofAccessCarousel:
      case QRCodeFunctionType.TenantHVAC:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          EnableSelfie: newTemplate?.EnableSelfie,
        }
      case QRCodeFunctionType.FieldEvent:
      case QRCodeFunctionType.BathroomService:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.JoinWifi:
        return { ...baseProps, isFormData: true }
      case QRCodeFunctionType.Vacancy:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          title: newTemplate?.Title?.en,
          btnText: 'SEND REQUEST',
          btnWidth: '50%',
        }
      case QRCodeFunctionType.GoogleMap:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.Renewal:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.MoveOut:
      case QRCodeFunctionType.MoveIn:
      case QRCodeFunctionType.MoveOutResident:
        return { ...baseProps, data: newTemplate, type: functionalityType }
      case QRCodeFunctionType.FrontDesk:
      case QRCodeFunctionType.DownloadApp:
      case QRCodeFunctionType.AEDInspection:
      case QRCodeFunctionType.FireExtinguisher:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.AngusServiceRequest:
      case QRCodeFunctionType.BEServiceRequest:
      case QRCodeFunctionType.Maintenance:
      case QRCodeFunctionType.MaintenanceRequest:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.Support:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.EVRequest:
      case QRCodeFunctionType.LeadCapture:
      case QRCodeFunctionType.RoomRental:
      case QRCodeFunctionType.FieldMarketing:
      case QRCodeFunctionType.CheckIn:
      case QRCodeFunctionType.EventRegistration:
        return { ...baseProps, template: newTemplate, title: newTemplate?.Title?.en, type: functionalityType }
      case QRCodeFunctionType.LadderSafety:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.Inventory:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.BurnPermit:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.CommentCard:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          qrTypeName: 'Comment Card',
          btnText: 'Send Feedback',
          btnWidth: '50%',
        }
      case QRCodeFunctionType.ResidentCheckOut:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          btnText: 'CHECK-OUT',
          btnWidth: '50%',
        }
      default:
        return { ...baseProps }
    }
  }

  // @ts-ignore
  const FormCompName = DynamicComponentsMaps[functionType]
  const passProps = getFromProps(functionType)
  const pngUrl = (qrInfo?.ImageUrl as string)?.replace(/(.eps)|(.pdf)/g, '.png')

  return (
    <Box className={classes.page} key={index}>
      <Box className={classes.qrMobile}>
        <MobileFrame logo={qrInfo?.HeaderLogo} height={1190} width={570} scale={1.3} radius={64}>
          {FormCompName ? (
            <FormCompName {...passProps} />
          ) : (
            <Typography className={classes.noType}>Preview is not available for this type</Typography>
          )}
        </MobileFrame>
      </Box>
      <Box style={{ backgroundColor: 'white', display: 'flex' }}>
        <img alt="" src={buildingUrl} style={{ height: 100, margin: '28px 48px' }} />
      </Box>
      <Box className={classes.hubBg}>
        <img alt="" src={BackgroundImage} className={classes.bg} />
        <Box className={classes.hubContent}>
          <Typography className={classes.qrName}>{AlternativeName}</Typography>
          <img alt="" src={pngUrl} className={classes.qrCode} />
        </Box>
      </Box>
      <Box className={classes.hubBottom}>
        {list.map((item1, index1) => {
          return (
            <TipItem
              key={index1}
              label={item1.label}
              icon={item1.icon}
              num={item1.num}
              hiddenLine={list.length === index1 + 1}
            />
          )
        })}
      </Box>
    </Box>
  )
}
const PdfBuilderSolution: React.FC = () => {
  usePuppeteer()
  const classes = useStyles()
  const { sId } = useParams() as unknown as Params
  const [data, setData] = useState<BuilderSolutionITF>()
  const { BackgroundImage, CoverPageName, Pages, BuildingUrl } = data || {}
  useEffect(() => {
    if (sId) {
      getSolutionBuilderById<BuilderSolutionITF>({ sId }).then((res) => {
        setData(res)
      })
    }
  }, [sId])

  return (
    <Box className={classes.root}>
      <Box className={classes.page}>
        <img src={BackgroundImage} className={classes.bg} alt="" />
        <Box className={classes.cover}>
          <Typography className={classes.coverPageName}>{CoverPageName}</Typography>
          <Box className={classes.logoBox}>
            <img src={Config.activateLogo} style={{ height: 80 }} alt="" />
            <Box style={{ height: 80, width: 2, backgroundColor: 'white', margin: '0 48px' }} />
            <img src={BuildingUrl} style={{ height: 80 }} alt="" />
          </Box>
        </Box>
      </Box>
      {Pages?.map((item, index) => {
        return <QrItem item={item} index={index} buildingUrl={BuildingUrl} key={index} coverPageName={CoverPageName} />
      })}
    </Box>
  )
}
export default PdfBuilderSolution
