import React, { useCallback, useRef, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import uuid from 'react-uuid'
import { AddCircle, ArrowDropDown } from '@mui/icons-material'
import { ContactsITF } from '@/types/group'
import { ProgressITF, TenantInfoITF } from '@/types/entity'
import InputAdornment from '@mui/material/InputAdornment'
import TenantSurvey from '@pages/survey/components/TenantSurvey'
import get from 'lodash/get'
import ContactCreate from '@/account/ContactCreate'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  add: {
    marginTop: 20,
    alignSelf: 'flex-start',
  },
  select: {
    height: 56,
    marginTop: 20,
  },
  save: {
    width: 90,
    marginTop: 20,
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  iconBtn: {
    padding: 0,
  },
  netType: {
    marginTop: 20,
  },
  title: {
    fontSize: 20,
    color: '#211F1F',
  },
  desc: {
    fontSize: 16,
  },
  line: {
    marginTop: 20,
    backgroundColor: '#BCBCBC',
    height: 1,
  },
}))
interface Props {
  contacts?: ContactsITF
  tenant?: TenantInfoITF
  onChangeData?: (progress: ProgressITF) => void
  onSave?: (data: { TenantInfo: TenantInfoITF }) => void
  gId: string
  bId: string
}
interface WifiFormProps {
  data: ContactsITF
  index: number
  onChange?: (item: ContactsITF, index: number) => void
  gId: string
  bId: string
}

const END_ADORNMENT = (
  <InputAdornment position="end">
    <ArrowDropDown />
  </InputAdornment>
)
const CONTACTS_CONFIG = [
  { label: 'Job Title', name: 'Title.en' },
  { label: 'Role', name: 'Role', endAdornment: END_ADORNMENT },
  { label: 'Ownership', name: 'Ownership', endAdornment: END_ADORNMENT },
  { label: 'Name', name: 'FullName.en' },
  { label: 'Email', name: 'Email' },
  { label: 'Mobile', name: 'Phone' },
]
const ContactItem: React.FC<WifiFormProps> = ({ data, index, onChange, gId, bId }) => {
  const [state, setState] = useState(data || {})
  const [modalVisible, setModalVisible] = useState(false)

  const onInputChange = useCallback(
    (value: string, name: string) => {
      setState((prevState) => {
        //@ts-ignore
        const cur = Object.assign({}, prevState, { [name]: value }) as ContactsITF
        onChange?.(cur, index)
        return cur
      })
    },
    [onChange, index]
  )
  const onClick = useCallback(() => {
    setModalVisible(true)
  }, [])

  const onClose = (data?: ContactsITF) => {
    if (data) {
      setState((prevState) => {
        const cur = Object.assign({}, data)
        onChange?.(cur, index)
        return cur
      })
    }
    setModalVisible(false)
  }
  return (
    <Grid container rowSpacing={0} columnSpacing={2} onClick={onClick}>
      {modalVisible && <ContactCreate onClose={onClose} item={state} gId={gId} bId={bId} survey />}
      {CONTACTS_CONFIG.map((item) => {
        const { label, name, endAdornment } = item
        return (
          <Grid item xs={12 / CONTACTS_CONFIG.length} key={name}>
            <CustomTextField
              label={label}
              value={get(state, name)}
              fullWidth
              variant="outlined"
              disabled={false}
              placeholder="Enter here"
              InputProps={{
                readOnly: true,
                endAdornment,
              }}
              onInputChange={onInputChange}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

const calculate = (data: ContactsITF[], tenant: TenantInfoITF | undefined) => {
  const Denominator = 2
  const Numerator = data.filter((item) => item.MemberId).length
  const TenantNumerator =
    tenant?.ArchorTenants?.filter((item) => item.Name && item.Floor && item.SquareFootage).length || 0
  return { Numerator: (Numerator > 0 ? 1 : 0) + (TenantNumerator > 0 ? 1 : 0), Denominator }
}

const ContactSurvey: React.FC<Props> = ({ contacts: contactList, tenant, onChangeData, onSave, gId, bId }) => {
  const classes = useStyles()
  const [contacts, setContacts] = useState(
    contactList?.map((item) => ({ ...item, FrontItemKey: uuid() } as ContactsITF)) || []
  )
  const refTenant = useRef<TenantInfoITF | undefined>(tenant)
  const onChange = useCallback((item: ContactsITF, index: number) => {
    setContacts((prevState) => {
      const data = prevState || []
      data.splice(index, 1, item)
      onChangeData?.(calculate(data, refTenant.current))
      return [...data]
    })
  }, [])
  const onAdd = useCallback(() => {
    setContacts((prevState) => {
      const data = [...prevState, { FrontItemKey: uuid() }]
      onChangeData?.(calculate(data, refTenant.current))
      return data
    })
  }, [])
  const onChangeDataTenant = useCallback(
    (data: TenantInfoITF) => {
      refTenant.current = data
      onChangeData?.(calculate(contacts, data))
    },
    [contacts]
  )
  const onClick = useCallback(
    (data) => {
      onSave?.(Object.assign({}, data, { ContactProgress: calculate(contacts, refTenant.current) }))
    },
    [contacts, onSave]
  )
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Add Contact Info</Typography>
      <Typography className={classes.desc}>
        {"Please provide the contact information of your property's team."}
      </Typography>
      {contacts?.map((item, index) => {
        return <ContactItem data={item} key={item.FrontItemKey} index={index} onChange={onChange} bId={bId} gId={gId} />
      })}
      <Button className={classes.add} onClick={onAdd}>
        <AddCircle style={{ marginRight: 8 }} />
        Add Contact
      </Button>
      <div className={classes.line} />
      <Typography className={classes.title} sx={{ marginTop: '20px' }}>
        Tenants
      </Typography>
      <Typography className={classes.desc}>
        {"Please list your property's anchor tenants, which floors they have, and their square footage."}
      </Typography>
      <TenantSurvey data={tenant} onSave={onClick} onChangeData={onChangeDataTenant} />
    </Box>
  )
}

export default ContactSurvey
