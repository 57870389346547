import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { WeeklyDay } from '@constant/panel'
import { makeStyles } from '@mui/styles'
import { useCallback, useState } from 'react'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
  label?: string
  className?: string
  defaultValue?: string[]
  onChange?: (value: string[]) => void
  disabled?: boolean
}

const useStyles = makeStyles(() => {
  return {
    inputRoot: {
      height: 50,
      '&[class*="MuiOutlinedInput-root"]': {
        zIndex: 1,
      },
    },
    input: {
      opacity: '0 !important',
    },
  }
})
const WeeklyDaySelect: React.FC<Props> = ({ label = 'Day', className, defaultValue, onChange, disabled }) => {
  const classes = useStyles()
  const [value, setValue] = useState<string[]>(defaultValue || [])
  const onChangeOption = useCallback(
    (event: any, newValue: string[]) => {
      setValue(newValue)
      onChange?.(newValue)
    },
    [onChange]
  )
  return (
    <Autocomplete
      multiple
      id="checkboxes-weekly-day"
      options={WeeklyDay}
      value={value || []}
      className={className}
      onChange={onChangeOption}
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={(label) => label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option}
        </li>
      )}
      style={{ width: 100, height: 50 }}
      classes={{
        inputRoot: classes.inputRoot,
      }}
      inputValue=""
      renderTags={(value) => {
        if (value?.length > 0)
          return (
            <div
              style={{
                position: 'absolute',
                overflow: 'hidden',
                left: 10,
                right: 40,
                color: disabled ? 'rgba(0, 0, 0, 0.38)' : '#4D4D4D',
                zIndex: -1,
                textOverflow: 'ellipsis',
              }}
            >
              {value?.join(',')}
            </div>
          )
        return null
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            readOnly: true,
            classes: {
              input: classes.input,
            },
          }}
          placeholder=""
        />
      )}
    />
  )
}

export default WeeklyDaySelect
