import React, { useContext } from 'react'
import useStyles from './styles'
import { EditPanelContext } from '@context/EditPanelContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function MobileFrame({ children, logo, ...props }) {
  var classes = useStyles({ height: props.height, width: props.width, scale: props.scale, radius: props.radius })
  const { editPanelIndex = -1 } = useContext(EditPanelContext)
  const { setCurrentEditPanelIndex } = useContext(EditPanelContext)
  return (
    <div className={classes.mobileFrameContainer}>
      <div className={classes.mobileFrame}>
        <div className={classes.header}>
          <div className={classes.statusBar} />
          <img alt="logo" src={logo} className={classes.logo} />
          {editPanelIndex > -1 ? (
            <div className={classes.back} onClick={() => setCurrentEditPanelIndex(-1)}>
              <ArrowBackIcon fontSize="large" />
            </div>
          ) : null}
        </div>
        <div className={classes.container}>
          {children}
          {props.title}
        </div>
      </div>
    </div>
  )
}
