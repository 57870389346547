import React from 'react'
import { TableCell as TableCellCom } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { TableCellProps } from '@mui/material/TableCell/TableCell'

interface Props {
  width?: number | string | undefined
  isHeader?: boolean
  emptyText?: string
  isPuppeteer?: boolean
}

const useStyles = makeStyles(() => ({
  text: {
    fontSize: (props: { isPuppeteer?: boolean }) => {
      return props?.isPuppeteer ? 10 : 16
    },
    padding: (props: { isPuppeteer?: boolean }) => {
      return props?.isPuppeteer ? '8px 12px' : 16
    },
    color: '#211F1F',
    fontWeight: 400,
    wordBreak: 'break-word',
    borderBottom: '1px solid #F5F5F5',
  },
  header: {
    fontWeight: 700,
    lineHeight: (props: { isPuppeteer?: boolean }) => {
      return props?.isPuppeteer ? 1.43 : '1.5rem'
    },
  },
  naText: {
    color: '#9B9B9B',
  },
}))
const TableCell: React.FC<Props & TableCellProps> = ({
  className,
  width,
  children,
  isHeader,
  emptyText,
  isPuppeteer,
  ...props
}) => {
  const classes = useStyles({ isPuppeteer })
  const isNa = typeof children === 'undefined' || children === '' || children === null
  const NAText = emptyText ?? 'N/A'
  return (
    <TableCellCom
      {...props}
      width={width}
      className={classnames(classes.text, { [classes.header]: isHeader, [classes.naText]: isNa }, className)}
    >
      {isNa ? NAText : children}
    </TableCellCom>
  )
}
export default TableCell
