import React, { useCallback, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Button, Tooltip } from '@mui/material'
import {
  Datagrid,
  TextField,
  DateField,
  useGetList,
  ListContextProvider,
  useRecordContext,
  Pagination,
} from 'react-admin'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useSelector } from 'react-redux'
import { uploadQrBuilderFile, uploadFileBase64 } from '@api'
import { toBase64, downLoadFile } from '@/utils'
import SnackBar from '@component/SnackBar'
import get from 'lodash/get'
const S3_Bucket = process.env.REACT_APP_S3_BUCKET
const templateUrl = 'https://activate-dev-env.s3.us-east-2.amazonaws.com/Provision/template.xlsx'
const currentSort = { field: 'id', order: 'DESC' }
const tipMessage = 'Error data will be emailed to you when upload fails.'
const accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: '#f7f7f7',
    padding: '0px 24px',
    height: '100%',
    flexDirection: 'column',
  },
  content: {
    backgroundColor: '#fff',
    padding: '16px',
    borderRadius: '4px',
    minHeight: 'calc(100% - 60px)',
  },
  header: {
    height: '27px',
    color: '#222',
    fontSize: 24,
  },
  uploadBox: {
    border: '1px solid #eee',
    width: '60%',
    margin: '20px auto 10px',
    padding: '16px',
  },
  text: {
    fontSize: 14,
    opacity: 0.8,
    wordBreak: 'break-all',
  },
  upload: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    display: 'none',
  },
  button: {
    textTransform: 'none',
    marginTop: 10,
  },
  tooltipWiper: {
    textAlign: 'center',
    color: '#386bbf',
    fontSize: 20,
    marginTop: 32,
    cursor: 'pointer',
  },
  tooltip: {
    padding: 12,
    fontSize: 14,
    maxWidth: 500,
  },
}))

const DownloadField = (props: any) => {
  const { source } = props || {}
  const record = useRecordContext(props)
  const value = get(record, source)

  const onDownload = (event: any) => {
    event.stopPropagation()
    downLoadFile({ url: value })
  }

  return (
    <Button
      onClick={onDownload}
      disabled={source === 'OutputFile' && record?.Status !== 'Completed'}
      variant="text"
      color="primary"
      style={{ textTransform: 'none', width: 30 }}
      startIcon={<GetAppIcon />}
    />
  )
}

function ImportQrCode() {
  const { selectedGroupId, user } = useSelector((state) => state.profile)
  const groupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const ddd = useGetList('importQRCode', {
    pagination: { page, perPage: perPage },
    sort: currentSort,
    filter: {
      type: 'QrBuilder',
      gId: groupId,
    },
  })
  const { data, isLoading, total } = ddd
  const changeHandler = useCallback(async (event: any) => {
    const file = event.target.files[0] as File
    const { name, type } = file
    const base64 = await toBase64(file)
    const result: any = await uploadFileBase64({
      fileType: type,
      base64,
      fileName: `Provision/${groupId}/${name}`,
      bucket: S3_Bucket,
    })
    await uploadQrBuilderFile({
      FilePath: result?.fileUrl,
      GroupId: groupId,
    }).catch(() => {
      SnackBar({ msg: 'fails', type: 'error', duration: 3000 })
    })
    SnackBar({ msg: 'success', type: 'success', duration: 3000 })
    setPage((v) => v + 1)
  }, [])

  const downLoadTemplate = useCallback(() => {
    downLoadFile({ url: templateUrl })
  }, [downLoadFile])

  return (
    <Box sx={{ mt: 2 }} className={classes.box}>
      <Box sx={{ mt: 2 }} className={classes.content}>
        <Typography className={classes.header} variant="h3">
          QrCode Template
        </Typography>
        <Box className={classes.uploadBox}>
          <Typography variant="h5"> QrCode Template</Typography>
          <Typography variant="h5" className={classes.text}>
            The file extension must be xls or xlsx, and the file size must not be larger than 5M{' '}
          </Typography>
          <Box className={classes.upload}>
            <input
              accept={accept}
              className={classes.input}
              id="icon-button-file"
              onChange={changeHandler}
              type="file"
            />
            <label htmlFor="icon-button-file">
              <Button component="span" variant="contained" color="primary" className={classes.button}>
                Upload
              </Button>
            </label>
          </Box>
        </Box>

        <Box className={classes.uploadBox}>
          <Typography variant="h5"> QrCode Template File</Typography>
          <Typography variant="h5" className={classes.text}>
            Please prepare to import data according to the format of the data template, the table header name cannot be
            changed, the header row cannot be deleted, and the imported data cannot exceed 100 items a time
          </Typography>
          <Button variant="outlined" color="primary" className={classes.button} onClick={downLoadTemplate}>
            Download
          </Button>
        </Box>

        {!isLoading && total ? (
          <Box sx={{ m: 2 }}>
            <ListContextProvider
              value={{
                //@ts-ignore
                data,
                total,
                page,
                sort: currentSort,
                perPage,
                setPerPage,
                setPage,
                basePath: '/importQRCode',
                resource: 'importQRCode',
              }}
            >
              <Datagrid>
                <TextField source="Type" label="Type" />
                <DateField source="CreatedDate" label="Created Data" showTime />
                <DateField source="ModifiedDate" label="Modified Date" showTime />
                <TextField source="Status" label="Status" />
                <DownloadField source="FilePath" label="Download Template" />
                <DownloadField source="OutputFile" label="Download Zip" />
              </Datagrid>
              <Pagination />
            </ListContextProvider>
          </Box>
        ) : null}

        <Tooltip
          arrow
          placement="top"
          title={tipMessage}
          className={classes.tooltipWiper}
          classes={{ tooltip: classes.tooltip }}
        >
          <Typography>FAQ</Typography>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ImportQrCode
