import React, { useState, useEffect } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { Typography, Box, Switch, Theme, Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import { COLOR } from '@constant/app'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import { ExpandMore } from '@mui/icons-material'

const NETWORK_TYPE = [
  { label: 'WPA', value: 'WPA' },
  { label: 'WPA2', value: 'WPA2' },
  { label: 'Unsecured', value: 'nopass' },
]
const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    },
    title: {
      color: '#0D1F2C',
      fontSize: 12,
    },
    iconBtn: {
      padding: 0,
    },
    label: {
      color: COLOR.grey37,
      '&.Mui-disabled': {
        color: COLOR.grey91,
      },
      '&.Mui-focused': {
        color: COLOR.grey37,
      },
    },
    network: {
      '&.Mui-disabled:before': {
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
      },
      '&:before': {
        borderBottomColor: '#D5D5D5',
      },
      '&:after': {
        borderBottomColor: '#D5D5D5',
      },
    },
    customButton: {
      alignSelf: 'center',
      textTransform: 'none',
      backgroundColor: '#0D1F2C',
      fontWeight: 400,
      marginTop: 100,
      color: '#DFC9A3',
      border: 'none',
      borderRadius: 18,
      fontSize: 12,
      width: '43%',
      height: 36,
      cursor: 'pointer',
      marginBottom: 16,
      '&:hover': {
        backgroundColor: '#0D1F2C',
        opacity: 0.7,
      },
      ' &.Mui-disabled': {
        color: '#DFC9A3',
        opacity: 0.5,
      },
    },
    url: {
      display: 'flex',
      alignItems: 'center',
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    margin: {
      marginTop: 0,
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  disabledEdit?: boolean
}

export const JOIN_WIFI_REQUIRED = {
  SSID: '',
  // NetworkType: '',
  // Password:'',
}

const JoinWifiFormData: React.FC<Props> = ({ onChange, value, disabledEdit = false }) => {
  const classes = useStyles()
  const valueUrl = value?.ExternalUrl || 'https://'
  const [SSID, setSSID] = useState<string>(value?.SSID)
  const [NetworkType, setNetworkType] = useState<any>(NETWORK_TYPE.find((item) => item.value === value?.NetworkType))
  const [Password, setPassword] = useState<string>(value?.Password || '')
  const [Url, setUrl] = useState<string>(valueUrl)
  const [checked, setChecked] = useState(value?.AlternateEnable || false)

  const onInputSSID = (value: string) => {
    setSSID(value)
  }
  const onInputPassword = (value: string) => {
    setPassword(value)
  }
  const onChangeNetworkType = (event: React.ChangeEvent<{}>, newValue: string) => {
    setNetworkType(newValue)
  }

  const toggleChecked = () => {
    setChecked(!checked)
  }

  const onInputUrl = (value: string) => {
    setUrl(value)
  }

  useEffect(() => {
    if (onChange) {
      const newData = { SSID, NetworkType: NetworkType?.value, Password, AlternateEnable: checked, ExternalUrl: Url }
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.JoinWifi, newData) })
      onChange(newData)
    }
  }, [onChange, SSID, NetworkType, Password, Url, checked])

  return (
    <div className={classes.container}>
      <CustomTextField
        label="SSID"
        value={SSID}
        disabled={disabledEdit || checked}
        placeholder="Enter here"
        onInputChange={onInputSSID}
        variant="outlined"
      />
      <CustomTextField
        label="Password"
        value={Password}
        disabled={disabledEdit || checked}
        placeholder="Enter here"
        onInputChange={onInputPassword}
        variant="outlined"
      />
      <Autocomplete
        disableClearable
        options={NETWORK_TYPE}
        onChange={onChangeNetworkType}
        value={NetworkType}
        disabled={disabledEdit || checked}
        getOptionLabel={(option) => option.label || ''}
        style={{ marginTop: '4px' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Network Type"
            placeholder="Please select"
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.network,
              },
              type: 'search',
            }}
          />
        )}
        popupIcon={<ExpandMore />}
      />

      <Box className={classes.url}>
        <Typography className={classes.urlLabel}>Alternate URL</Typography>
        <Switch disabled={disabledEdit} checked={checked} name="checked" onChange={toggleChecked} />
      </Box>
      {checked && (
        <CustomTextField
          label=""
          value={Url}
          disabled={disabledEdit}
          placeholder="Enter here"
          onInputChange={onInputUrl}
          fullWidth
          noMargin
          variant="outlined"
        />
      )}
    </div>
  )
}

export default JoinWifiFormData
