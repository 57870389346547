import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CommonCard from '@component/Card/CommonCard'
import Images from '@assets/images'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import useSetState from '@hooks/useSetState'
import TableCell from '@component/Table/TableCell'
import PeriodSelect from '@component/DateSelect/PeriodSelect'
import { DEFAULT_PERIOD_LAST_YEAR, PERIOD_OPTIONS2 } from '@/global'
import {
  getMaintenanceAnalysisForGroup,
  getMaintenanceAnalysisSummaryForGroup,
  getMaintenanceSummaries,
  getMaintenanceSummary,
  getTenantsRequestSummaryList,
} from '@api'
import { useSelector } from 'react-redux'
import { formatNumber } from '@utils/common'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: '0 0px 18px',
  },
  row: {
    display: 'flex',
    marginTop: 16,
  },
  card: {
    marginTop: 25,
    borderRadius: 8,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
    '& .borderLeft': {
      borderLeft: '1px solid #000000',
    },
  },
  title: {
    fontSize: 20,
    color: '#211F1F',
    marginBottom: 12,
  },
  borderBlack: {
    borderRight: '1px solid #000000',
    borderLeft: '1px solid #000000',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  total: {
    '&:last-child td, &:last-child th': { backgroundColor: '#F0F6FC', color: '#211F1F', fontWeight: '700' },
  },
}))
const YTD_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Buildings',
    source: 'BuildingName.en',
    convertItem: (item) => item.BuildingName?.es,
    width: 172,
  },
  {
    label: 'Total SRs',
    source: 'Total',
    width: 189,
  },
  {
    label: 'Resolution Time',
    source: 'TotalDuration',
    width: 157,
  },
  {
    label: '< 48 Hrs',
    source: 'ServiceLevel',
    convertItem: (item) => `${item.ServiceLevel || 0} %`,
  },
  {
    label: 'CSAT YTD',
    source: 'YTDAvgScore',
  },
  {
    label: 'CSAT 90',
    source: 'CSAT90',
  },
]
const TENANT_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Tenants',
    source: 'Tenant',
    width: 182,
  },
  {
    label: 'SR’s',
    source: 'Total',
    isNumber: true,
    width: 106,
  },
  {
    label: 'Resolution Time',
    source: 'AvgDuration',
    isNumber: true,
    width: 157,
  },
  {
    label: 'Weekly',
    className: 'borderLeft',
    source: 'CSAT',
    width: 107,
  },
  {
    label: 'YTD',
    source: 'YTDAvgScore',
    width: 107,
  },
  {
    label: 'Text',
    source: 'TextNumber',
    isNumber: true,
    className: 'borderLeft',
    // width: 107
  },
  {
    label: 'Chat',
    isNumber: true,
    source: 'LiveChatNumber',
    // width: 107
  },
  {
    label: 'Messages',
    isNumber: true,
    align: 'center',
    source: 'MessageNumber',
  },
]
const STARS_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Tenants',
    source: 'Tenant',
    width: 145,
  },
  {
    label: 'ID',
    source: 'IssueNo',
    width: 108,
  },
  {
    label: 'Date',
    isDate: true,
    formatKey: 'keyboardDate',
    source: 'Date',
    width: 126,
  },
  {
    label: 'Priority',
    source: 'Urgency',
    width: 93,
  },
  {
    label: 'Rating',
    source: 'Score',
    width: 82,
  },
  {
    label: 'Resolution Time',
    source: 'Duration',
    width: 157,
  },
  {
    label: 'Comment',
    source: 'Comment',
  },
]
const HIGH_PRIORITY_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Tenants',
    source: 'Tenant',
    width: 145,
  },
  {
    label: 'ID',
    source: 'IssueNo',
    width: 108,
  },
  {
    label: 'Date',
    isDate: true,
    formatKey: 'keyboardDate',
    source: 'Date',
    width: 126,
  },
  {
    label: 'Priority',
    source: 'Urgency',
    width: 93,
  },
  {
    label: 'Rating',
    source: 'Score',
    width: 82,
  },
  {
    label: 'Resolution Time',
    source: 'Duration',
    width: 97,
  },
  {
    label: 'Comment',
    source: 'Comment',
  },
]
const OPEN_SERVICE_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Tenants',
    source: 'Tenant',
    width: 145,
  },
  {
    label: 'ID',
    source: 'IssueNo',
    width: 108,
  },
  {
    label: 'Date',
    isDate: true,
    formatKey: 'keyboardDate',
    source: 'Date',
    width: 126,
  },
  {
    label: 'Priority',
    source: 'Urgency',
    width: 120,
  },
  {
    label: 'SR Details',
    source: 'Detail',
  },
]
const YTDContainer: React.FC<Props> = ({ gId }) => {
  const classes = useStyles()
  const [data, setData] = useSetState({ top: null as any, building: [] as any[] })
  const { top, building } = data
  const fetchMaintenanceSummary = useCallback(async () => {
    const result = await getMaintenanceAnalysisSummaryForGroup({ gId })
    setData({ top: result })
  }, [])
  const fetchTenantsRequestSummaryList = useCallback(async () => {
    const result = await getMaintenanceAnalysisForGroup<{ Result: [] }>({ gId })
    setData({ building: result?.Result || [] })
  }, [])
  useEffect(() => {
    fetchMaintenanceSummary()
    fetchTenantsRequestSummaryList()
  }, [])
  return (
    <div>
      <div className={classes.headerRight}>
        <PeriodSelect options={PERIOD_OPTIONS2} disabled defaultValue={PERIOD_OPTIONS2[0]} label="Date" />
      </div>
      <div className={classes.row}>
        <CommonCard label="Service Level" loading={!top} value={`${top?.ServiceLevel || 0}%`} icon={Images.tool} />
        <CommonCard
          label="Resolution Time"
          loading={!top}
          value={`${formatNumber(top?.TotalDuration)} hrs`}
          icon={Images.event}
        />
        <CommonCard label="CSAT YTD" loading={!top} value={top?.YTDAvgScore || 0} icon={Images.star} />
      </div>
      <Box className={classes.card}>
        <Typography className={classes.title}>Building Ratings</Typography>
        <TableList config={YTD_CONFIG} data={building} />
      </Box>
    </div>
  )
}
interface Props {
  gId: string
  bId: string
  ptId: string
  tu?: string
  isPuppeteer?: boolean
}
export const Container: React.FC<Props> = ({ gId, bId, ptId, isPuppeteer, tu: propsTu }) => {
  const classes = useStyles()
  const [data, setData] = useSetState({
    top: null as any,
    stars: [] as any,
    tenant: { Result: [], TotalSummary: {} } as any,
    highPriority: [] as any,
    openService: [] as any,
    period: DEFAULT_PERIOD_LAST_YEAR,
  })
  const { period, top, tenant, stars, highPriority, openService } = data
  const tu = propsTu || period.value
  const fetchMaintenanceSummary = useCallback(
    async (tu) => {
      const result = await getMaintenanceSummary({ gId, bId, tu, ptId })
      setData({ top: result })
    },
    [gId, bId, tu, ptId]
  )
  const fetchTenantsRequestSummaryList = useCallback(
    async (tu) => {
      const result = await getTenantsRequestSummaryList<any[]>({
        gId,
        bId,
        tu,
        ptId,
      })
      setData({ tenant: result })
    },
    [bId, gId, ptId, setData]
  )
  const fetchStars = useCallback(
    async (tu) => {
      const result = await getMaintenanceSummaries<any>({
        gId,
        bId,
        tu,
        scoreLT: 3,
        ptId,
      })
      setData({ stars: result?.Maintenances || [] })
    },
    [bId, gId, ptId, setData]
  )
  const fetchHighPriority = useCallback(
    async (tu) => {
      const result = await getMaintenanceSummaries<any>({
        gId,
        bId,
        tu,
        urgency: 'High',
        ptId,
      })
      setData({ highPriority: result?.Maintenances || [] })
    },
    [bId, gId, ptId, setData]
  )
  const fetchOpenService = useCallback(
    async (tu) => {
      const result = await getMaintenanceSummaries<any>({
        gId,
        bId,
        tu,
        status: 'InProgress',
        ptId,
      })
      setData({ openService: result?.Maintenances || [] })
    },
    [bId, gId, ptId, setData]
  )
  useEffect(() => {
    fetchMaintenanceSummary(tu)
    fetchTenantsRequestSummaryList(tu)
    fetchStars(tu)
    fetchHighPriority(tu)
    fetchOpenService(tu)
    localStorage.setItem(
      'DashboardDate',
      JSON.stringify({
        tu,
      })
    )
  }, [fetchHighPriority, fetchMaintenanceSummary, fetchOpenService, fetchStars, fetchTenantsRequestSummaryList, tu])
  return (
    <div>
      {!isPuppeteer && (
        <div className={classes.headerRight}>
          <PeriodSelect period={period} onChange={(value) => setData({ period: value })} label="Date Range" />
        </div>
      )}
      <div className={classes.row}>
        <CommonCard label="Total SRs" loading={!top} value={formatNumber(top?.Total)} icon={Images.tool} />
        <CommonCard
          label="Resolution Time"
          loading={!top}
          value={`${formatNumber(top?.TotalDuration)} hrs`}
          icon={Images.event}
        />
        <CommonCard label="CSAT YTD" loading={!top} value={top?.YTDAvgScore || 0} icon={Images.star} />
      </div>
      <Box className={classes.card}>
        <Typography className={classes.title}>Tenant Satisfaction</Typography>
        <TableList
          header={
            <>
              <TableCell isHeader colSpan={3}>
                {' '}
              </TableCell>
              <TableCell isHeader colSpan={2} className={classes.borderBlack} align="center">
                CSAT{' '}
              </TableCell>
              <TableCell isHeader colSpan={3} align="center">
                Activity
              </TableCell>
            </>
          }
          rowClassName={classes.total}
          config={TENANT_CONFIG}
          data={tenant?.Result || []}
        />
      </Box>
      <Box className={classes.card}>
        <Typography className={classes.title}>{'Service Requests < 3 Stars'}</Typography>
        <TableList config={STARS_CONFIG} data={stars || []} />
      </Box>
      <Box className={classes.card}>
        <Typography className={classes.title}>High Priority Service Requests </Typography>
        <TableList config={HIGH_PRIORITY_CONFIG} data={highPriority || []} />
      </Box>
      <Box className={classes.card}>
        <Typography className={classes.title}>Open Service Requests</Typography>
        <TableList config={OPEN_SERVICE_CONFIG} data={openService || []} />
      </Box>
    </div>
  )
}
const TenantPerformanceDashboard: React.FC<{ bId?: string; ptId?: string }> = ({ bId = '', ptId = '' }) => {
  const classes = useStyles()
  const { selectedGroupId } = useSelector((state) => state.profile)

  const isYTD = useMemo(() => !bId, [bId])
  return (
    <div className={classes.paper}>
      <div className={classes.container}>
        {isYTD ? (
          <YTDContainer gId={selectedGroupId} bId={bId} ptId={ptId} />
        ) : (
          <Container gId={selectedGroupId} bId={bId} ptId={ptId} />
        )}
      </div>
    </div>
  )
}

export default TenantPerformanceDashboard
