import React, { useReducer, createContext } from 'react'
import { EditPanelITF, HubButtonITF, PanelChoice } from '@/types/panel'
import { isCanSavaQr } from '@/utils'
import uuid from 'react-uuid'

const actions = {
  Update_Panel: 'Update_Panel',
  Update_Hub_Btn: 'Update_Hub_Btn',
  Init_Hub: 'Init_Hub',
  Set_Edit_Index: 'Set_Edit_Index',
  Remove_Hub_Btn: 'Remove_Hub_Btn',
  Set_Icons: 'Set_Icons',
}

interface EditPanelContextProps {
  panelData?: EditPanelITF
  icons: PanelChoice[]
  editPanelIndex?: number
  initEditPanel: (data: any) => void
  setIcons: (data: PanelChoice[]) => void
  updateEditPanelButton: (data: any, index: number) => void
  updateEditPanel: (data: any) => void
  onRemoveItem: (index: number) => void
  setCurrentEditPanelIndex: (index: any) => void
}

// @ts-ignore
export const EditPanelContext = createContext<EditPanelContextProps>({ editPanelIndex: -1, icons: [] })

export const editPanelReducer = (state, action) => {
  switch (action.type) {
    case actions.Update_Panel: {
      const newData = Object.assign({}, state.panelData, action.data)
      return { ...state, panelData: newData }
    }
    case actions.Set_Edit_Index: {
      return { ...state, editPanelIndex: action.index }
    }
    case actions.Init_Hub: {
      const { buttonNumber } = action.data
      const { Items = [] } = state.panelData || {}
      const isAdd = buttonNumber > Items.length
      const baseLen = Items.length || 0
      let newItems: Record<string, any>[] = []
      if (isAdd) {
        const addData = new Array(buttonNumber - Items.length).fill(0).map((_, i) => {
          return { DraggableId: uuid(), Icon: '' }
        })
        newItems = [...Items, ...addData]
      } else {
        newItems = Items.slice(0, buttonNumber)
      }
      return { ...state, panelData: Object.assign({}, state.panelData, { Items: newItems }) }
    }
    case actions.Update_Hub_Btn: {
      const preData = state.panelData
      const preItems = preData.Items as HubButtonITF[]
      const { data, index } = action
      const preItem = preData.Items[index] || {}
      const newItem =
        data.FunctionalityType && preItem?.FunctionalityType !== data.FunctionalityType
          ? Object.assign({}, preItem, data, { TemplateFormData: undefined, TemplateId: undefined })
          : Object.assign({}, preItem, data)
      const { FunctionalityType, TemplateFormData, TemplateId, Name, Icon } = newItem
      Object.assign(newItem, {
        CanSave: Name && Icon && FunctionalityType && isCanSavaQr(FunctionalityType, TemplateFormData, TemplateId),
      })
      preItems.splice(index, 1, newItem)
      return { ...state, panelData: { ...preData, Items: preItems } }
    }
    case actions.Set_Icons: {
      const { icons } = action
      return { ...state, icons: icons }
    }
    case actions.Remove_Hub_Btn: {
      const preData = state.panelData
      const preItems = preData.Items as HubButtonITF[]
      const { index } = action
      preItems.splice(index, 1)
      return { ...state, panelData: { ...preData, Items: preItems }, editPanelIndex: -1 }
    }
    default:
      return state
  }
}

const EditPanelContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(editPanelReducer, {})
  const value = {
    state,
    panelData: state?.panelData,
    editPanelIndex: state?.editPanelIndex,
    icons: state?.icons,
    initEditPanel: (data: any) => {
      dispatch({ type: actions.Init_Hub, data })
    },
    updateEditPanel: (data: any) => {
      dispatch({ type: actions.Update_Panel, data })
    },
    updateEditPanelButton: (data: any, index: number) => {
      dispatch({ type: actions.Update_Hub_Btn, data, index })
    },
    setIcons: (icons: PanelChoice[]) => {
      dispatch({ type: actions.Set_Icons, icons })
    },
    onRemoveItem: (index: number) => {
      dispatch({ type: actions.Remove_Hub_Btn, index })
    },
    setCurrentEditPanelIndex: (index: number) => {
      dispatch({ type: actions.Set_Edit_Index, index })
    },
  }

  return <EditPanelContext.Provider value={value}>{children}</EditPanelContext.Provider>
}

export default EditPanelContextProvider
