import React, { useContext, useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Autocomplete } from '@mui/material'
import { TextField, Box } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import CONFIG from '@constant/config'
import { QRCodeFunctionType } from '../../types'
import { FormDataContext } from '../../context/FormDataContext'
import { isCanSavaQr } from '@/utils'
import { getCoordinates } from '@common/map'
import { getPropertyDetail } from '@api'
import { ExpandMore } from '@mui/icons-material'

const API = 'AIzaSyBzyaT5Rk22FbDRNvMWDETsKY9Pk1AruM4'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
  },
  title: {
    fontFamily: 'Roboto',
    color: '#0D1F2C',
    fontSize: 16,
  },
  autoComplete: {
    // marginLeft: 40
  },
  formControl: {
    margin: '20px 0 10px 0',
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  renderOptionItem: {},
  address: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
  },
}))

interface MapFormDataProps {
  isHubPanel?: string
  buildingId?: string
  entityType?: string
  functionType?: QRCodeFunctionType
  onChange?: (item: any | null) => void
  value?: any
  disabledEdit?: boolean
}

export const Destination_Icon = {
  lodging: 'Hotel_icon.png',
  transit_station: 'Transit_icon.png',
  Attractions: 'Attractions_icon.png',
  parking: 'Parking_icon.png',
  restaurant: 'Restaurants_icon.png',
  pharmacy: 'Pharmacies_icon.png',
  bank: 'Bank_icon.png',
  cafe: 'Coffee_icon.png',
}
// value : https://developers.google.com/maps/documentation/places/web-service/supported_types?hl=zh-cn
export const Destination_Key_Words = [
  { Label: 'Hotels', value: 'lodging', img: CONFIG.iconFolder + 'Hotel_icon.png' },
  { Label: 'Transit', value: 'transit_station', img: CONFIG.iconFolder + 'Transit_icon.png' },
  { Label: 'Attractions', value: 'Attractions', img: CONFIG.iconFolder + 'Attractions_icon.png' },
  { Label: 'Parking', value: 'parking', img: CONFIG.iconFolder + 'Parking_icon.png' },
  { Label: 'Restaurants', value: 'restaurant', img: CONFIG.iconFolder + 'Restaurants_icon.png' },
  { Label: 'Pharmacies', value: 'pharmacy', img: CONFIG.iconFolder + 'Pharmacies_icon.png' },
  { Label: 'Bank', value: 'bank', img: CONFIG.iconFolder + 'Bank_icon.png' },
  { Label: 'Coffee', value: 'cafe', img: CONFIG.iconFolder + 'Coffee_icon.png' },
]

export const GOOGLE_REQUIRED = {
  Latitude: '',
  Longitude: '',
  DestinationType: '',
}

const GoogleMapFormData: React.FC<MapFormDataProps> = ({ onChange, value, disabledEdit = false }) => {
  const { selectedPropertyId } = useSelector((state) => state.profile)
  const { mapData, updateMapData } = useContext(FormDataContext)
  const classes = useStyles()
  const [state, setState] = useState(value || { ...mapData })
  const [address, setAddress] = useState('')

  const getIndex = (value: string) => {
    return value ? Destination_Key_Words.findIndex((item) => item.value === value) : -1
  }

  const handleData = useCallback(
    (name, value) => {
      setState((prevState: any) => {
        const newState = { ...prevState, [name]: value }
        Object.assign(newState, { CanSave: isCanSavaQr(QRCodeFunctionType.GoogleMap, newState) })
        if (onChange) {
          // when onChange is function, the it come from edit panel save & return
          onChange(newState)
        } else {
          updateMapData(newState)
        }
        return newState
      })
    },
    [onChange]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      handleData(name, value)
    },
    [state]
  )
  const getCoordinatesData = async (buildAddress) => {
    const latestAddress = buildAddress && typeof buildAddress === 'string' ? buildAddress : address
    const result = await getCoordinates(API, latestAddress)
    if (result) {
      handleData('Latitude', result.latitude)
      handleData('Longitude', result.longitude)
    }
  }

  const onPlaceSelected = (place: any) => {
    if (!place || !place?.place_id) {
      return
    }
    const lat = place?.geometry?.location.lat()
    const lng = place?.geometry?.location.lng()
    const place_id = place.place_id
    handleData('Latitude', lat)
    handleData('Longitude', lng)
  }

  const handleDestinationChange = (value: string) => {
    handleData('DestinationType', value)
  }

  const fetchProperties = async () => {
    if (!selectedPropertyId) return

    const properties: any = await getPropertyDetail({ eId: selectedPropertyId })

    handleData('Latitude', properties.Latitude)
    handleData('Longitude', properties.Longitude)

    // const address1 = properties?.AddressLine1?.[locale] || ''
    // const address2 = properties?.AddressLine2?.[locale] || ''
    // const city = properties?.City?.[locale] || ''
    // const state = properties?.State?.[locale] || ''
    // const zip = properties?.Zip|| ''
    // const country = properties?.Country?.[locale] || ''
    // const pinCodes = properties?.PinCodes?.[0]?.['Code']
    // const buildAddress = `${address1},${address2},${city},${state},${zip},${country}`.replaceAll(',,', ',')
    // setAddress(buildAddress)
    // getCoordinatesData(buildAddress)
    // setProperties(data)
  }

  useEffect(() => {
    if (!state?.Latitude) {
      fetchProperties()
    }
  }, [selectedPropertyId])

  // useEffect(() => {
  //   getCoordinatesData()
  // }, [address])

  return (
    <Box className={classes.container}>
      <CustomTextField
        value={state?.Latitude}
        label="Latitude"
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="Latitude"
        variant="outlined"
      />
      <CustomTextField
        value={state?.Longitude}
        label="Longitude"
        disabled={disabledEdit}
        placeholder="Enter here"
        onChange={handleChange}
        name="Longitude"
        variant="outlined"
      />
      <Autocomplete
        value={state?.DestinationType ? Destination_Key_Words[getIndex(state?.DestinationType)] : null}
        onChange={(event, newValue) => {
          // @ts-ignore
          handleDestinationChange(newValue?.value)
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
              <img loading="lazy" width="30" src={option.img} alt="" style={{ marginRight: 10 }} />
              <div className={classes.renderOptionItem}>{option.Label}</div>
            </li>
          )
        }}
        className={classes.autoComplete}
        id="controllable-states-demo"
        options={Destination_Key_Words}
        disabled={disabledEdit}
        getOptionLabel={(option) => option.Label || ''}
        style={{ width: '100%', marginTop: '20px' }}
        renderInput={(params) => <TextField {...params} label="Destination Type" variant="outlined" />}
        popupIcon={<ExpandMore />}
      />
    </Box>
  )
}

export default GoogleMapFormData
