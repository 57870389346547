import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '-100px',
      // backgroundColor: 'red',
      // width: '100%'
    },
    title: {
      height: '27px',
      color: '#5e5d5d',
      margin: '10px 0px 0px 30px',
      fontSize: 24,
    },
    subTitle: {
      height: '27px',
      color: '#a2a2a2',
      margin: '10px 0px 0px 30px',
      fontSize: 18,
    },
  }
})

const EmptyDisplay: React.FC = () => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>No Data to Display</Typography>
      <Typography className={classes.subTitle}>Please make a different filter selection</Typography>
    </Box>
  )
}

export default EmptyDisplay
