import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Autocomplete, TextField, Button, Modal, Checkbox } from '@mui/material'
import { CheckBoxOutlineBlank, CheckBox as CheckBoxIcon } from '@mui/icons-material'
import { getPropertyList } from '@api'
import { useSelector } from 'react-redux'

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
  onClose: () => void
  onOk: (buildings: string[]) => void
  gId?: string
}

const MultipleBuilding: React.FC<Props> = React.memo(({ onClose, onOk, gId: id }) => {
  const classes = useStyles()
  const { selectedGroupId } = useSelector((state) => state.profile)
  const gId = id || selectedGroupId
  const [list, setList] = useState<Record<string, any>[]>([])
  const [selectedContacts, setSelectedContacts] = useState<Record<string, any>[]>([])
  const sendDisabled = !selectedContacts.length

  const onSendClick = () => {
    onOk(selectedContacts.map((v) => v.id))
    onClose()
  }

  useEffect(() => {
    getPropertyList({
      filter: JSON.stringify({ gId }),
      pagination: JSON.stringify({ page: 0, perPage: 999 }),
    }).then((res: any) => {
      setList(res.Properties)
    })
  }, [gId])

  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.modal}>
        <Typography variant="h5" sx={{ fontSize: '16px', marginBottom: '24px' }}>
          Select Buildings
        </Typography>

        <Autocomplete
          disableClearable
          onChange={(_, newValue) => setSelectedContacts(newValue)}
          options={list}
          multiple
          disableCloseOnSelect
          sx={{ marginBottom: '24px' }}
          value={selectedContacts}
          getOptionLabel={(option: any) => option?.Name?.en}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Select Buildings" />}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option?.Name?.en}
              </li>
            )
          }}
          renderTags={(value) => {
            if (value?.length > 0)
              return (
                <div
                  style={{
                    position: 'absolute',
                    overflow: 'hidden',
                    left: 16,
                    right: 40,
                    color: '#4D4D4D',
                    zIndex: -1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {value.map((v) => v?.Name?.en)?.join(', ')}
                </div>
              )
            return null
          }}
        />

        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSendClick} variant="contained" color="primary" disabled={sendDisabled}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

const useStyles = makeStyles(() => ({
  modal: {
    width: 500,
    padding: 24,
    maxHeight: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
}))

export default MultipleBuilding
