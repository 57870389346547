import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
interface TitleWrapProps {
  title: string
  className?: string
  noBorder?: boolean
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid #BCBCBC',
      paddingTop: 36,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    noBorder: {
      borderBottom: 'none',
    },
    title: {
      color: '#466CB5',
      fontSize: 20,
      fontWeight: 600,
    },
  }
})
const TitleWrap: React.FC<TitleWrapProps> = ({ title, children, className, noBorder }) => {
  const classes = useStyles()
  return (
    <Box className={classnames(classes.container, { [classes.noBorder]: noBorder }, className)}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </Box>
  )
}

export default TitleWrap
