export const USER_TOKEN = 'userToken'
export const USER_NAME = 'userName'
export const USER_PERMISSIONS = 'permissions'
export const PERSIST_DATA = 'persist:root'
var cacheData = {}

const ALL_SAVE = [USER_TOKEN, USER_NAME, USER_PERMISSIONS]
const clearData = () => {
  cacheData = {}
  ALL_SAVE.forEach((key) => localStorage.removeItem(key))
}

function getAuthToken() {
  if (cacheData[USER_TOKEN]) return cacheData[USER_TOKEN]
  return JSON.parse(localStorage.getItem(USER_TOKEN))
}
function setAuthToken(UserToken) {
  cacheData[USER_TOKEN] = UserToken
  localStorage.setItem(USER_TOKEN, JSON.stringify(UserToken))
}

function getUserName() {
  if (cacheData[USER_NAME]) return cacheData[USER_NAME]
  return JSON.parse(localStorage.getItem(USER_NAME))
}
function setUserName(userName) {
  cacheData[USER_NAME] = userName
  localStorage.setItem(USER_NAME, JSON.stringify(USER_NAME))
}

function getPermissions() {
  if (cacheData[USER_PERMISSIONS]) return cacheData[USER_PERMISSIONS]
  return JSON.parse(localStorage.getItem('permissions'))
}

function setAuth(UserToken, UserName) {
  setAuthToken(UserToken)
  setUserName(UserName)
}

function setPermissions(permssions) {
  cacheData[USER_PERMISSIONS] = permssions
  localStorage.setItem(USER_PERMISSIONS, JSON.stringify(permssions))
}

const removeAuthToken = () => {
  clearData()
  // persistor.purge()
}

// function refreshNewToken() {
//   const storage = getAuthToken();
//   const data = refreshToken(storage.refreshToken);
//   return data;
// }

export { getPermissions, setPermissions, getAuthToken, getUserName, setAuth, removeAuthToken, setAuthToken }
