import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'

interface InventoryPanelProps {
  onChange?: (data: any) => void
  value: any
}
const useStyles = makeStyles(() => ({
  card: {
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    margin: 16,
    padding: '12px 16px',
  },
  empty: {
    fontStyle: 'italic',
  },
  item: {
    display: 'flex',
    padding: '16px 0',
    borderBottom: '1px solid #BCBCBC',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 4,
    },
  },
  title: {
    color: '#707070',
    fontSize: 12,
  },
  value: {
    color: '#211F1F',
    fontSize: 16,
    marginTop: 14,
  },
}))

const InventoryPanel: React.FC<InventoryPanelProps> = ({ value, onChange }) => {
  const { Items = [] } = value || {}
  const classes = useStyles()
  useEffect(() => {
    if (!value) {
      onChange?.({ Items: [], CanSave: true })
    }
  }, [onChange, value])
  const hasItems = Array.isArray(Items) && Items.length > 0
  return (
    <Box className={classes.card}>
      {hasItems ? (
        Items.map((item, index) => {
          return (
            <Box key={index} className={classes.item}>
              <Box sx={{ flex: 1, minWidth: '120px' }}>
                <Typography className={classes.title}>Item Name</Typography>
                <Typography className={classes.value}>{item.Name}</Typography>
              </Box>
              <Box sx={{ flex: 1, minWidth: '120px' }}>
                <Typography className={classes.title}>Unit Type</Typography>
                <Typography className={classes.value}>{item.UnitType}</Typography>
              </Box>
              <Box sx={{ flex: 1, minWidth: '100px' }}>
                <Typography className={classes.title}>Qty</Typography>
                <Typography className={classes.value}>{item.Quantity}</Typography>
              </Box>
            </Box>
          )
        })
      ) : (
        <Typography className={classes.empty}>No items have been added yet.</Typography>
      )}
    </Box>
  )
}

export default InventoryPanel
