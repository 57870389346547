import * as React from 'react'
import { Children, cloneElement, isValidElement, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslate, useGetIdentity } from 'ra-core'
import { Tooltip, IconButton, Menu, Button, Avatar, PopoverOrigin } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material'
const defaultIcon = <AccountCircle />

const useStyles = makeStyles(
  (theme) => ({
    user: { minWidth: 150 },
    userButton: {
      color: 'white',
      whiteSpace: 'pre-wrap',
      textTransform: 'none',
    },
    avatar: {
      color: 'white',
      marginLeft: 10,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }),
  { name: 'RaUserMenu' }
)

const AnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const TransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const CustomUserMenu = (props: CustomUserMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const translate = useTranslate()
  const { isLoading } = useGetIdentity()
  const classes = useStyles(props)
  const {
    UserPersonal: { FullName },
  } = useSelector((state) => state?.profile?.user) || { UserPersonal: { FullName: { en: '' } } }
  const identity = {
    fullName: FullName?.en,
  }
  const { children, label = 'ra.auth.user_menu', icon = defaultIcon, logout } = props

  if (!logout && !children) return null
  const open = Boolean(anchorEl)

  const handleMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  return (
    <div className={classes.user}>
      {!isLoading && identity?.fullName ? (
        <Button
          aria-label={label && translate(label, { _: label })}
          className={classes.userButton}
          color="inherit"
          startIcon={<AccountCircleIcon className={classes.avatar} />}
          onClick={handleMenu}
        >
          {identity.fullName}
        </Button>
      ) : (
        <Tooltip title={label && translate(label, { _: label })}>
          {/* @ts-ignore */}
          <IconButton
            aria-label={label && translate(label, { _: label })}
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup={true}
            color="inherit"
            onClick={handleMenu}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        // Make sure the menu is display under the button and not over the appbar
        // See https://v4.mui.com/components/menus/#customized-menus
        // getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, (menuItem) =>
          isValidElement(menuItem)
            ? cloneElement<any>(menuItem, {
                onClick: handleClose,
              })
            : null
        )}
        {logout}
      </Menu>
    </div>
  )
}

export interface CustomUserMenuProps {
  children?: React.ReactNode
  label?: string
  logout?: React.ReactNode
  icon?: React.ReactNode
}

export default CustomUserMenu
