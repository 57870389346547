import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  list: {
    padding: 24,
    borderRadius: 8,
    backgroundColor: 'white',
    '& .RaBulkActionsToolbar-toolbar': {
      display: 'none',
    },
    '& .RaList-content': {
      borderRadius: 8,
      border: '1px solid #F5F5F5',
      boxSizing: 'border-box',
      backgroundColor: 'white',
    },
    '& .RaDatagrid-tableWrapper': {
      overflowX: 'auto',
      // width: 'calc(100vw - 249px - 24 * 4px)', //左侧栏249px，padding margin 24*4px
      width: (props?: { isSelect?: boolean }) => {
        return props?.isSelect ? 'calc(100vw * 0.9 - 24px * 2)' : 'calc(100vw - 249px - 24 * 4px)'
      },
    },
  },
  equipmentQuestionList: {
    backgroundColor: '#F7F7F7',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 24px',
  },
  toolbar: {
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 0,
    minHeight: 'auto',
    flexDirection: 'column',
    marginBottom: 12,
  },
  directionRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    fontWeight: 600,
    fontSize: 20,
    fontFamily: 'Roboto',
    color: '#211F1F',
    marginBottom: 12,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    padding: 18,
    background: '#FBFBFB',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginBottom: 12,
    width: '100%',
    height: 60,
    boxSizing: 'border-box',
  },
  actionIcon: {
    color: '#466CB5',
    marginRight: 20,
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'none',
  },
  nameWidth: {
    width: 250,
  },
  actionHeader: {
    textAlign: 'right',
    paddingRight: '60px !important',
  },
  add: {
    display: 'flex',
    alignItems: 'center',
    color: '#1976D2',
    textTransform: 'none',
    padding: '8px 16px',
    marginTop: 8,
  },
  body: {
    width: '90%',
    padding: '32px 0 18px',
    height: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '10%',
    transform: 'translateX(-50%)',
    overflowY: 'auto',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 32,
  },
  modalBtn: {
    fontSize: 15,
    fontWeight: 500,
    padding: '8px 16px',
    borderRadius: 4,
    marginLeft: 16,
    textTransform: 'none',
  },
  import: {
    width: 777,
    height: 528,
    padding: '32px 32px 18px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  download: {
    background: '#F0F6FC',
    padding: 6,
    justifyContent: 'center',
    margin: '0 0 32px 0',
    borderRadius: 8,
  },
  uploadTitle: {
    color: '#211F1F',
    fontSizes: 20,
    marginBottom: 32,
    fontWeight: 600,
  },
  uploadBody: {
    flex: 1,
    border: '1px dashed #BCBCBC',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#211F1F',
    fontSizes: 20,
    fontWeight: 600,
    textAlign: 'center',
  },
  xlsx: {
    color: '#707070',
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 16,
  },
  uploadText: {
    fontSizes: 20,
    fontWeight: 600,
    margin: 16,
    cursor: 'pointer',
  },
  imgBox: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(25, 118, 210, 0.2)',
    '& img': {
      display: 'block',
    },
  },
  fileName: {
    width: '90%',
    background: '#F5F5F5',
    display: 'flex',
    padding: 16,
    alignItems: 'center',
    textAlign: 'left',
    borderRadius: 8,
  },
  hoverPaper: {
    border: 'none',
  },
  clearBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  clear: {
    textTransform: 'none',
    textDecoration: 'underline',
  },
  clearItem: {
    marginLeft: 8,
    height: 25,
    color: '#7F7F7F',
    borderRadius: 18,
    fontSize: 10,
    textTransform: 'none',
    padding: '0 16px',
    backgroundColor: '#FAFAFA',
    border: '0.5px solid #7F7F7F',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: 14,
    color: '#466CB5',
    padding: '6px 8px 6px 6px',
    marginLeft: 6,
  },
}))
