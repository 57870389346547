import React, { useCallback } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Typography,
  FormGroup,
  Checkbox,
} from '@mui/material'
import { FileDownloadOutlined, FileUploadOutlined } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { uploadFileBase64 } from '@api'
import { toBase64 } from '@/utils'
import CONFIG from '@constant/config'
import SnackBar from '@component/SnackBar'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    borderRadius: 8,
    fontFamily: 'Roboto',
  },
  item: {
    paddingBottom: '24px',
    borderBottom: '1px solid #BCBCBC',
    marginBottom: '24px',
    display: 'block',
  },
  question: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 16,
    display: 'inline-block',
  },
  radioLabel: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  desc: {
    backgroundColor: '#F7F7F7',
    padding: '24px',
    borderRadius: '8px',
    marginBottom: '24px',
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
  },
  uploadItem: {
    minWidth: '500px',
    display: 'flex',
    marginBottom: '24px',
    alignItems: 'center',
    paddingBottom: '24px',
  },
  uploadTitle: {
    paddingRight: 30,
    fontSize: 16,
    minWidth: 300,
  },
  upload: {
    color: '#fff',
    backgroundColor: '#386BBF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    borderRadius: '4px',
    fontSize: '14px',
    padding: '6px 16px',
    fontWeight: 500,
  },
}))

interface Props {
  className?: string
  dataKey: string
  question: Record<string, any>[]
  data?: Record<string, any>
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: Record<string, any>) => void
  onSave: () => void
  onBack?: () => void
}
const Question: React.FC<Props> = ({ className, onSave, onBack, question, data, handleChange, dataKey }) => {
  const classes = useStyles()
  const { UploadUrls = [] } = data || {}
  const onFileChange = useCallback(
    async (event: any, item: Record<string, any>) => {
      event.preventDefault()
      const file = event.target.files?.[0] as File
      const { name, type } = file
      const base64 = await toBase64(file)
      const result: any = await uploadFileBase64({
        fileType: type,
        base64,
        fileName: `/VerifySurvey/file/${Date.now()}_${name}`,
        bucket: CONFIG.s3_Bucket,
      })
      SnackBar({ msg: 'Uploaded Successfully', type: 'success' })
      item.value = result?.fileUrl
      handleChange(event, item)
    },
    [dataKey]
  )
  if (!data) return null
  return (
    <Box className={classnames(classes.container, className)}>
      {question.map((item: Record<string, any>) => {
        const { name, type, question, options, label, dependOnKey, dependOnValue } = item
        if (dependOnKey && data[dependOnKey] !== dependOnValue) {
          return null
        }
        if (type === 'Radio') {
          return (
            <FormControl key={name} className={classes.item}>
              <FormLabel id={name} className={classes.question}>
                {question}
              </FormLabel>
              <RadioGroup row name={name} value={data[name]} onChange={(e) => handleChange(e, { dataKey })}>
                {options.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      sx={{ marginRight: '32px', fontSize: '14px' }}
                      style={option.style}
                      classes={{ label: classes.radioLabel }}
                      control={<Radio size="small" />}
                      label={option.label}
                    />
                  )
                })}
              </RadioGroup>
            </FormControl>
          )
        }
        if (type === 'Multiline') {
          return (
            <TextField
              key={name}
              label={label}
              name={name}
              multiline
              rows={4}
              placeholder="Enter here..."
              value={data[name]}
              onChange={(e) => handleChange(e, { dataKey })}
              sx={{ width: '50%', marginBottom: '24px' }}
            />
          )
        }
        if (type === 'upload') {
          const { desc, downloadUrl, downloadTitle, uploadTitle } = item
          return (
            <Box key={name}>
              {desc && <Typography className={classes.desc}>{desc}</Typography>}
              {downloadTitle && (
                <Box sx={{ borderBottom: '1px solid #BCBCBC' }} className={classes.uploadItem}>
                  <Typography className={classes.uploadTitle}>{downloadTitle}</Typography>
                  <Button
                    variant="contained"
                    target="_blank"
                    href={downloadUrl}
                    endIcon={<FileDownloadOutlined />}
                    sx={{ width: '120px', fontSize: '14px' }}
                  >
                    DOWNLOAD
                  </Button>
                </Box>
              )}
              {uploadTitle && (
                <Box className={classes.uploadItem}>
                  <Typography className={classes.uploadTitle}>{uploadTitle}</Typography>
                  <input
                    style={{ display: 'none' }}
                    id={`${dataKey}-${name}`}
                    onChange={(e) => onFileChange(e, { dataKey, name, type })}
                    type="file"
                    accept=""
                    name={name}
                  />
                  <label htmlFor={`${dataKey}-${name}`} className={classes.upload}>
                    UPLOAD
                    <FileUploadOutlined sx={{ fontSize: '20px', marginLeft: '8px' }} />
                  </label>
                  {(UploadUrls || []).map((item, index) => {
                    return (
                      <a key={item} href={item} style={{ marginLeft: 20 }} target="_blank" rel="noopener noreferrer">
                        {`File ${index + 1}`}
                      </a>
                    )
                  })}
                </Box>
              )}
            </Box>
          )
        }
        if (type === 'multipleChoice') {
          return (
            <FormControl key={name} component="fieldset" variant="standard" className={classes.item}>
              <FormLabel id={name} className={classes.question}>
                {question}
              </FormLabel>
              <FormGroup row>
                {options.map((option) => {
                  const { label, value } = option
                  return (
                    <FormControlLabel
                      key={value}
                      sx={{ marginRight: '32px', fontSize: '14px' }}
                      control={
                        <Checkbox
                          checked={data[name]?.includes(value)}
                          onChange={(e) => handleChange(e, { dataKey, type, itemName: name })}
                          name={value}
                          size="small"
                        />
                      }
                      label={label}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
          )
        }
        return null
      })}
      <Box sx={{ flex: '100%', textAlign: 'right' }}>
        <Button variant="contained" color="primary" style={{ width: 90 }} onClick={onSave}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
export default Question
