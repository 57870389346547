import React, { useCallback, useEffect, useRef } from 'react'
import WithDefaultData from '@/hoc/WithDefaultData'
import { makeStyles } from '@mui/styles'
import CustomTextField from '@component/Form/CustomTextField'
import useSetState from '@/hooks/useSetState'
import DragAndDropList, { FieldsProps } from '@component/EditPanel/DragAndDropList'
import { Box } from '@mui/material'
import { COLOR } from '@constant/app'
import { isCanSavaQr } from '@/utils'
import { EntityITF, QRType, Template } from '@/types/entity'
import { QRCodeFunctionType } from '@/types'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    marginTop: 20,
  },
  dragListLabel: {
    color: COLOR.grey37,
    fontSize: 12,
    marginTop: 20,
  },
}))

const language = 'en'
interface FeedBackPanelProps {
  groupId: string
  buildingId: string
  functionType: QRCodeFunctionType
  qrType: QRType
  onChange?: (template: any) => void
  value?: Template
  templateId?: string
  defaultTemplate?: Template | null
  entity?: EntityITF
  isEdit?: boolean
  disabledEdit?: boolean
}

const FeedBackPanel: React.FC<FeedBackPanelProps> = ({
  onChange,
  value,
  templateId,
  functionType,
  defaultTemplate,
  entity,
  isEdit,
  disabledEdit = false,
}) => {
  const classes = useStyles()
  const [{ questions, forceChange }, setState] = useSetState({
    questions: value?.Questions || defaultTemplate?.Questions || [],
    forceChange: defaultTemplate?.Questions ? true : false,
  })
  const cacheTemplateRef = useRef({ defaultTemplate, value })
  const changeText = useCallback(
    (index: number, txt: string) => {
      const newQuestions = JSON.parse(JSON.stringify(questions))
      Object.keys(newQuestions[index].QuestionText).map((i) => {
        newQuestions[index].QuestionText[i] = txt
      })
      setState({ questions: newQuestions, forceChange: true })
    },
    [questions]
  )
  const onInputQuestion = useCallback(
    (value: string) => {
      changeText(0, value)
    },
    [changeText]
  )
  const onInput = useCallback(
    (value: string) => {
      changeText(1, value)
    },
    [changeText]
  )

  const changeFields = useCallback(
    (fields: any) => {
      const newQuestions = JSON.parse(JSON.stringify(questions))
      // @ts-ignore
      if (newQuestions[1]?.Choices?.length > 0) {
        newQuestions[1].Choices = fields
        setState({ questions: newQuestions, forceChange: true })
      }
    },
    [questions]
  )

  const dealWithLocation = useCallback(
    (temporaryQuestions, newQuestion) => {
      if (temporaryQuestions && entity?.TypeDisplayText?.[language]) {
        Object.keys(temporaryQuestions[0].QuestionText).map((i) => {
          temporaryQuestions[0].QuestionText[i] = newQuestion
        })
      } else {
        Object.keys(temporaryQuestions[0].QuestionText).map((i) => {
          temporaryQuestions[0].QuestionText[i] = newQuestion
        })
      }
      return temporaryQuestions
    },
    [entity]
  )
  useEffect(() => {
    cacheTemplateRef.current = { defaultTemplate, value }
  }, [defaultTemplate, value])
  useEffect(() => {
    if (isEdit) return
    let location = entity?.TypeDisplayText?.[language] || ''
    // @ts-ignore
    if (entity?.entityType === 'Building') {
      location = 'Building'
    }
    if (!isEdit && defaultTemplate?.Questions) {
      const ques = defaultTemplate?.Questions || []
      const newQuestion = `How was the ${location ? `${location}?` : ''}`
      setState({ questions: dealWithLocation(ques, newQuestion), forceChange: true })
    }
  }, [entity, isEdit])

  useEffect(() => {
    if (onChange && forceChange) {
      let template = defaultTemplate
      const acId = templateId || template?.acId || template?.TemplateId || value?.acId
      const newData = {
        TemplateId: acId,
        acId: acId,
        Questions: questions,
      }
      template = Object.assign({}, template, newData, { CanSave: isCanSavaQr(functionType, newData) })
      onChange({ ...template, withNewLocation: false })
      setState({ forceChange: false })
    }
  }, [onChange, questions, forceChange, templateId])

  return (
    <div className={classes.container}>
      {questions?.[0] && (
        <>
          <CustomTextField
            label="Edit Question"
            disabled={disabledEdit}
            value={questions?.[0]?.QuestionText?.[language] || ''}
            style={{ marginTop: 0 }}
            placeholder="Please enter"
            onInputChange={onInputQuestion}
            variant="outlined"
          />
        </>
      )}
      {questions?.[1] && (
        <>
          <CustomTextField
            label="Edit Secondary Question"
            disabled={disabledEdit}
            value={questions?.[1]?.QuestionText?.[language] || ''}
            placeholder="Please enter"
            onInputChange={onInput}
            variant="outlined"
          />
        </>
      )}
      {/* @ts-ignore */}
      {questions?.[1] && (
        <>
          <Box className={classes.dragListLabel}>Edit Button</Box>
          {/* @ts-ignore */}
          <DragAndDropList
            Fields={(questions?.[1]?.Choices as FieldsProps[]) || []}
            showAdd={true}
            disabledEdit={disabledEdit}
            changeFields={changeFields}
          ></DragAndDropList>
        </>
      )}
    </div>
  )
}

export default WithDefaultData(FeedBackPanel)
