import React, { useEffect, useState, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { Box, Typography, TextField, Switch, Autocomplete } from '@mui/material'
import { makeStyles } from '@mui/styles'
import EditAndSave from '@component/Button/EditAndSave'
import { getTenantList } from '@api'
import useSetState from '@hooks/useSetState'
import { ExpandMore } from '@mui/icons-material'

const sourceData = [
  {
    label: 'Vanity Domain',
    checkedName: 'HasVanityUrl',
    checked: false,
    urlName: 'VanityUrl',
    urlValue: '',
    placeholder: 'Enter Domain',
  },
  {
    label: 'Angus Integration',
    checkedName: 'HasAngusIntegration',
    checked: false,
    urlName: 'AngusIntegration',
    urlValue: '',
    placeholder: 'Enter Angus Key (Ask Tech)',
  },
  {
    label: 'Google Places ID',
    checkedName: 'HasGooglePlaceId',
    checked: false,
    urlName: 'GooglePlaceId',
    urlValue: '',
    placeholder: 'Enter Google Place Id',
  },
  {
    label: 'Google space Support channel',
    checkedName: 'HasSupportChannel',
    checked: false,
    urlName: 'SupportChannel',
    urlValue: '',
    placeholder: 'Enter Google Support channel',
  },
  {
    label: 'Google space operations channel',
    checkedName: 'HasOperationsChannel',
    checked: false,
    urlName: 'OperationsChannel',
    urlValue: '',
    placeholder: 'Enter Google Operations channel',
  },
  {
    label: 'Google space Organizational channel',
    checkedName: 'HasOrgChannel',
    checked: false,
    urlName: 'OrgChannel',
    urlValue: '',
    placeholder: 'Enter Google Organizational channel',
  },
  {
    label: 'Live Chat for Service Request',
    hiddenInput: true,
    checkedName: 'EnableLiveChat',
    checked: false,
    urlName: '',
    urlValue: '',
  },
  {
    label: 'Hide Too hot/cold express',
    checkedName: 'HideSrHotCold',
    hiddenInput: true,
    checked: false,
    urlName: '',
    urlValue: '',
  },
  {
    label: 'AI Cover',
    checkedName: 'EnableAICover',
    hiddenInput: true,
    checked: false,
    urlName: '',
    urlValue: '',
  },
  {
    label: 'Default Service Request Tenant',
    checkedName: 'EnableDefaultTenant',
    hiddenInput: true,
    checked: false,
    urlName: 'DefaultTenantId',
    urlValue: '',
    type: 'select',
    placeholder: 'Tenant',
  },
]

type ProductData = typeof sourceData

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
    },
    rowContent: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '16px 0',
    },
    columnBox: {
      // display: 'flex',
      // width: '50%'
    },
    information: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    textField: {
      width: 300,
      margin: '0 0 16px 58px',
      display: 'block',
    },
  }
})

interface Props {
  informationRef: React.MutableRefObject<{ productData: typeof sourceData }>
  isEdit: boolean
  editData: any
  onSave: () => void
  gId?: string
}

const PropertyProduct = (props: Props) => {
  const { informationRef, isEdit, editData, onSave, gId } = props
  const bId = editData?.BuildingId || ''
  const classes = useStyles()
  const productData = useMemo<ProductData>(() => JSON.parse(JSON.stringify(sourceData)), [])
  const [, setRender] = useState(false)
  const [onlyView, setOnlyView] = useState(isEdit)
  const [{ TenantList }, setState] = useSetState({ TenantList: [] as any[] })

  const onCancel = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
    const { name, value, checked = false, type } = event.target as Record<string, any>
    //@ts-ignore
    productData[idx][name] = type === 'text' ? value : checked
    setRender((v) => !v)
  }, [])

  useImperativeHandle(
    informationRef,
    () => ({
      ...informationRef.current,
      productData,
    }),
    []
  )

  useEffect(() => {
    if (isEdit) {
      productData.map((v) => {
        v.checked = editData?.[v.checkedName] || false
        if (!v.hiddenInput || v.type === 'select') {
          v.urlValue = editData?.[v.urlName] || ''
        }
      })
      setRender((v) => !v)
    }
  }, [isEdit, editData])

  useEffect(() => {
    if (!isEdit) return
    getTenantList({
      filter: JSON.stringify({ bId, gId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      setState({ TenantList: res.Tenants })
    })
  }, [isEdit, gId, bId])

  return (
    <Box className={`${classes.content}`}>
      <Box className={classes.rowContent}>
        {productData.map((v, i) => {
          const { label, placeholder, checked, urlValue, hiddenInput, type } = v
          return (
            <Box className={classes.columnBox} key={i}>
              <Switch disabled={onlyView} checked={checked} name={'checked'} onChange={(e) => handleChange(e, i)} />
              <Typography component="span">{label}</Typography>
              {!hiddenInput && (
                <TextField
                  name={'urlValue'}
                  fullWidth
                  disabled={onlyView || !checked}
                  placeholder={placeholder}
                  value={urlValue}
                  variant="outlined"
                  className={classes.textField}
                  onChange={(e) => handleChange(e, i)}
                />
              )}
              {type === 'select' && (
                <Autocomplete
                  value={TenantList.find((item) => item.acId === urlValue) || null}
                  onChange={(e, value) => {
                    productData[i]['urlValue'] = value?.acId
                    setRender((v) => !v)
                  }}
                  options={TenantList}
                  getOptionLabel={(option) => option.Name || ''}
                  className={classes.textField}
                  renderInput={(params) => (
                    <TextField {...params} label="" variant="outlined" placeholder={placeholder} />
                  )}
                  disabled={onlyView || !checked}
                  popupIcon={<ExpandMore />}
                />
              )}
            </Box>
          )
        })}
      </Box>
      <EditAndSave showSave={!onlyView} onCancel={onCancel} onEdit={onEdit} onSave={onSave} />
    </Box>
  )
}
export default React.memo(PropertyProduct)
