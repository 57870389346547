import React, { useCallback, useEffect, useRef, useState } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import FieldType from '@/utils/FieldType'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import UploadFile from '@component/Form/UploadFile'

const language = 'en'

type FieldItem = typeof FieldType.DropDownSelection
const onlyMoveArr = ['Q001', 'Q002', 'Q003']

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 16px 16px',
    },
    date: {
      margin: '20px 0 0',
    },
    upload: {
      marginTop: 20,
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
  groupId: string
  buildingId: string
}

const RoomRental: React.FC<Props> = ({ onChange, value, disabledEdit = false, groupId, buildingId }) => {
  const classes = useStyles()
  const refValue = useRef(value)

  const [state, setState] = useSetState({
    EventName: value?.EventName || '',
    EventLocation: value?.EventLocation || '',
    TermConditionUrl: value?.TermConditionUrl || '',
    Fields: (value?.Fields || []) as FieldItem[],
  })

  const { Fields, EventName, EventLocation, TermConditionUrl } = state
  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }
  const onChangeField = useCallback((fields: FieldItem[]) => {
    setState({ Fields: fields || [] })
  }, [])
  const onChangeFile = useCallback(
    (file: string) => {
      setState({ TermConditionUrl: file })
    },
    [onChange, value]
  )

  useEffect(() => {
    if (onChange) {
      const data = {}
      const newData = {
        EventName,
        EventLocation,
        TermConditionUrl,
        Fields: Fields || [],
      }
      Object.assign(data, refValue.current, newData, { CanSave: isCanSavaQr(QRCodeFunctionType.RoomRental, newData) })
      onChange(data)
    }
  }, [onChange, Fields, EventName, EventLocation, TermConditionUrl])

  useEffect(() => {
    refValue.current = value
  }, [value])
  return (
    <div className={classes.container}>
      <CustomTextField
        required
        label="Room Name"
        disabled={disabledEdit}
        value={EventName}
        name="EventName"
        onInputChange={onInput}
        variant="outlined"
      />
      <CustomTextField
        label="Suite #"
        value={EventLocation}
        disabled={disabledEdit}
        name="EventLocation"
        onInputChange={onInput}
        variant="outlined"
      />
      <DragFormPanel
        language={language}
        data={Fields}
        onChangeField={onChangeField}
        disabled={disabledEdit}
        onlyMoveArr={onlyMoveArr}
      />
      <UploadFile
        className={classes.upload}
        value={TermConditionUrl}
        filePath={`Teams/${groupId}/${buildingId}`}
        onChange={onChangeFile}
        disabledInput
        label="Terms & Conditions (optional)"
      />
    </div>
  )
}

export default WithDefaultData(RoomRental)
