import React, { useState, useEffect, useCallback } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import { Typography, Box, Switch, Button, MenuItem, Theme, IconButton, Autocomplete } from '@mui/material'
import { TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { ExpandMore, AddCircle } from '@mui/icons-material'
import { COLOR } from '@constant/app'
import { GetPropertyContacts, uploadBase64 } from '@api'
import { isCanSavaQr, toBase64 } from '@/utils'
import { addPropertyContact } from '@api'
import SnackBar from '@component/SnackBar'
import { QRCodeFunctionType } from '@/types'
import MobilePhone from '@/account/MobilePhone'
import Images from '@assets/images'

const S3_Bucket = process.env.REACT_APP_S3_BUCKET

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
      position: 'relative',
    },
    addIcon: {
      position: 'absolute',
      right: 0,
      top: '20px',
    },
    label: {
      color: COLOR.grey37,
      '&.Mui-disabled': {
        color: COLOR.grey91,
      },
      '&.Mui-focused': {
        color: COLOR.grey37,
      },
    },
    contacts: {
      '&.Mui-disabled:before': {
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
      },
      '&:before': {
        borderBottomColor: '#D5D5D5',
      },
      '&:after': {
        borderBottomColor: '#D5D5D5',
      },
    },
    logoImg: {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    upload: {
      marginTop: 24,
      boxSizing: 'border-box',
      border: '1px dashed #BCBCBC',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#211F1F',
      fontSizes: 20,
      fontWeight: 600,
      textAlign: 'center',
      padding: 36,
    },
    uploadText: {
      fontSizes: 20,
      fontWeight: 600,
      margin: 16,
      cursor: 'pointer',
    },
    input: {
      display: 'none',
    },
    photoCamera: {
      fontSize: 50,
    },
    title: {
      color: '#374053',
      padding: 0,
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '0.00938em',
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: 'top left',
    },
    greytitle: {
      color: '#919191',
    },
    url: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 24,
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
      width: 80,
    },
    cancel: {
      marginTop: 16,
      alignSelf: 'flex-end',
      height: 34,
      border: '1px solid #1976D2',
      color: '#1976D2',
      borderRadius: 4,
    },
    save: {
      marginTop: 16,
      alignSelf: 'flex-end',
      height: 34,
      width: 89,
      color: 'white',
      marginLeft: 16,
      backgroundColor: '#1976D2',
      borderRadius: 4,
    },
    phone: {
      margin: '24px 0 4px',
    },
    phoneLabel: {
      backgroundColor: '#F3F5F9',
    },
    adornedEnd: {
      right: '39px !important',
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  buildingId?: string
  groupId?: string
  provideStatus?: (e: any) => void
  disabledEdit?: boolean
  isHub?: boolean
}
interface Contact {
  FullName: { en: string }
  Phone?: string
  Email?: string
  AvatarUrl?: string
  ExternalUrl?: string
  AlternateEnable?: boolean
  UserId?: string
}
export const FRONT_DESK_REQUIRED = {
  Name: '',
}
const option = ['Marketing', 'Manager', 'Employee']
const FrontDeskPanel: React.FC<Props> = ({ onChange, value, buildingId, groupId, disabledEdit = false, ...other }) => {
  const { isHub } = other
  const classes = useStyles()
  const [contactsList, setContacts] = useState([])
  const [selectContacts, setSelectContacts] = useState('')
  const [selectLists, setSelectLists] = useState([])
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [LogoImg, setLogoImg] = useState('')
  const [Url, setUrl] = useState('')
  const [UserId, setUserId] = useState('')
  const [checked, setChecked] = useState(false)
  const [role, setRole] = useState('')
  const [canSave, setCanSave] = useState(false)
  const [add, setAdd] = useState(false)
  const [{ SendMessageEnable, PhoneCallEnable, SendEmailEnable }, setState] = useState({
    SendMessageEnable: value?.SendMessageEnable !== false,
    PhoneCallEnable: value?.PhoneCallEnable !== false,
    SendEmailEnable: value?.SendEmailEnable !== false,
  })
  const itemDisabled = disabledEdit || !add || checked ? true : false
  const uploadDisabled = disabledEdit || checked ? true : false
  const onChangeSelectContacts = (event: React.ChangeEvent<{}>, newValue: string) => {
    sureContact(newValue)
  }

  const onClickAdd = () => {
    setAdd(!add)
    const { provideStatus } = other
    provideStatus?.(!add)
    if (!add) {
      clearAddData()
    }
  }

  const onInputUrl = (value: string) => {
    setUrl(value)
  }

  const toggleChecked = () => {
    setChecked(!checked)
  }

  const initContacts = () => {
    GetPropertyContacts({
      filter: JSON.stringify({ bId: buildingId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      const contacts = res.Contacts?.map((i: { FullName: { en: any } }) => {
        return i.FullName.en
      })
      setContacts(res.Contacts || [])
      setSelectLists(contacts || [])
      sureContact((value && value.Name && value.Name.en) || '', res.Contacts)
    })
  }

  const sureContact = (i: any, res?: Contact[]) => {
    res = res ? res : contactsList
    const selectOne = res.filter((k) => {
      return k.FullName.en === i
    })?.[0]
    const result = selectOne || res[0]
    if (!result) return
    setSelectContacts(result.FullName.en)
    setName(result.FullName.en)
    setPhone(result.Phone || '')
    setEmail(result.Email || '')
    setLogoImg(result.AvatarUrl || value?.AvatarUrl || '')
    setUserId(result?.UserId || '')
  }
  const handleImageChange = useCallback(async (event: any) => {
    event.preventDefault()
    let file = event.target.files?.[0] as File
    if (!file) {
      file = event.dataTransfer.files[0] as File
    }
    const { name, type, size } = file
    const fileType = /^image\/[a-z]*$/.test(type)
    if (!fileType) {
      return SnackBar({ msg: 'The file is not an image', type: 'error', duration: 3000 })
    }
    if (size > 3 * 1024 * 1024) {
      return SnackBar({ msg: 'The file is larger than 3MB', type: 'error', duration: 3000 })
    }
    if (fileType) {
      const base64Image = await toBase64(file)
      const s3Image: any = await uploadBase64({
        base64Image,
        fileName: `static/avatar/${name}`,
        bucket: S3_Bucket,
      })
      setLogoImg(s3Image.imageUrl || '')
    }
  }, [])

  const onInputName = (value: string) => {
    setName(value)
  }

  const onInputPhone = (value: string) => {
    setPhone(value)
  }

  const onInputEmail = (value: string) => {
    setEmail(value)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({
      ...state,
      [event.target.name]: event.target.checked,
    }))
  }

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target
    setRole(value)
  }, [])
  const onClickSave = () => {
    if (canSave) {
      const payload: Record<string, any> = {
        GroupId: groupId,
        BuildingId: buildingId,
        ContactMemberId: '',
        ContactUserId: '',
        Contact: {
          Name: name,
          Role: role,
          Phone: phone,
          Email: email,
          AvatarUrl: LogoImg,
          AlternateEnable: checked,
          ExternalUrl: Url,
        },
      }
      const api = addPropertyContact

      api(payload)
        .then((res: any) => {
          onClickAdd()
        })
        .catch((err) => {
          SnackBar({ msg: err, type: 'error', duration: 3000 })
        })
    }
  }
  const clearAddData = () => {
    setSelectContacts('')
    setName('')
    setPhone('')
    setEmail('')
    setLogoImg('')
    setUserId('')
    setRole('')
    setChecked(false)
  }

  useEffect(() => {
    if (!add) {
      initContacts()
      setChecked(value?.AlternateEnable || false)
      setUrl(value?.ExternalUrl || 'https://')
    }
  }, [add])

  useEffect(() => {
    if (onChange) {
      const newData = {
        Name: name ? { en: name, es: name } : undefined,
        PhoneNumber: phone,
        Email: email,
        AvatarUrl: LogoImg,
        AlternateEnable: checked,
        ExternalUrl: Url,
        ...(LogoImg ? { UserId } : {}),
        forceHideButton: add,
        SendMessageEnable,
        PhoneCallEnable,
        SendEmailEnable,
      }
      const canSaveVar = isCanSavaQr(QRCodeFunctionType.FrontDesk, newData)
      Object.assign(newData, { CanSave: canSaveVar })
      setCanSave(!!canSaveVar)
      onChange(newData)
    }
  }, [onChange, name, phone, email, checked, Url, LogoImg, add, SendMessageEnable, PhoneCallEnable, SendEmailEnable])

  return (
    <div className={classes.container}>
      {!add ? (
        <Box style={{ position: 'relative' }}>
          <Autocomplete
            style={{ width: '100%' }}
            disabled={disabledEdit}
            options={selectLists}
            //@ts-ignore
            onChange={onChangeSelectContacts}
            getOptionDisabled={() => checked}
            //@ts-ignore
            value={selectContacts}
            classes={{
              endAdornment: classes.adornedEnd,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select from contacts"
                placeholder="Please select"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: classes.contacts,
                  },
                }}
              />
            )}
            popupIcon={<ExpandMore />}
          />
          <IconButton className={classes.addIcon} onClick={onClickAdd} disabled={disabledEdit}>
            <AddCircle style={{ fontSize: 30, color: '#386BBF' }} />
          </IconButton>
        </Box>
      ) : null}

      <CustomTextField
        label="Contact Name"
        value={name}
        disabled={itemDisabled}
        placeholder="NoData"
        style={add ? { margin: 0 } : {}}
        onInputChange={onInputName}
        variant="outlined"
      />

      <FormGroup row sx={{ display: add ? 'none' : '', marginTop: '20px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={PhoneCallEnable}
              onChange={handleCheckboxChange}
              name="PhoneCallEnable"
              disabled={disabledEdit}
            />
          }
          label="Call"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={SendMessageEnable}
              onChange={handleCheckboxChange}
              name="SendMessageEnable"
              disabled={disabledEdit}
            />
          }
          label="Send Message"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={SendEmailEnable}
              onChange={handleCheckboxChange}
              name="SendEmailEnable"
              disabled={disabledEdit}
            />
          }
          label="Send Email"
        />
      </FormGroup>

      <MobilePhone
        label="Mobile Number"
        className={classes.phone}
        variant="outlined"
        showFirst={false}
        disabled={itemDisabled}
        onChangePhone={onInputPhone}
        value={phone}
        labelClassName={isHub ? classes.phoneLabel : ''}
      />

      <CustomTextField
        label="Email"
        value={email}
        disabled={itemDisabled}
        placeholder="NoData"
        onInputChange={onInputEmail}
        variant="outlined"
      />

      <Box className={classes.upload} onDrop={handleImageChange} onDragOver={(e) => e.preventDefault()}>
        {LogoImg && (
          <Box style={{ borderBottom: '1px solid #BCBCBC', marginBottom: '16px', width: '100%' }}>
            <img src={LogoImg} alt="LogoImg" className={classes.logoImg} />
          </Box>
        )}
        <img src={Images.iconImport} alt="upload" />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <input
            accept="image/*"
            disabled={uploadDisabled}
            className={classes.input}
            id="icon-button-file"
            onChange={handleImageChange}
            type="file"
          />
          <label htmlFor="icon-button-file">
            <Typography className={classes.uploadText} style={{ color: itemDisabled ? '#707070' : '' }}>
              Drag & drop or <span style={{ color: uploadDisabled ? '#707070' : '#1976D2' }}>choose file</span> to
              upload profile picture
            </Typography>
            <span style={{ color: '#707070', fontWeight: 'normal' }}>SVG, PNG, JPG (Max 3MB)</span>
          </label>
        </Box>
      </Box>

      {add && (
        <Box style={{ width: '100%', marginTop: 24 }}>
          <TextField
            label="User Role"
            select={true}
            variant="outlined"
            value={role}
            SelectProps={{
              displayEmpty: true,
              type: 'search',
              IconComponent: ExpandMore,
            }}
            disabled={disabledEdit || checked ? true : false}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(e)}
          >
            {option?.map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              )
            })}
          </TextField>
        </Box>
      )}

      <Box>
        <Box className={classes.url}>
          <Typography className={classes.urlLabel}>Alternate URL</Typography>
          <Switch disabled={disabledEdit} checked={checked} name="checked" onChange={toggleChecked} />
        </Box>
        {checked && (
          <CustomTextField
            label=""
            value={Url}
            disabled={disabledEdit}
            placeholder="Enter here"
            onInputChange={onInputUrl}
            fullWidth
            noMargin
            variant="outlined"
          />
        )}
      </Box>

      {add && (
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" classes={{ outlined: classes.cancel }} onClick={onClickAdd}>
            CANCEL
          </Button>
          <Button variant="contained" disabled={!canSave} classes={{ contained: classes.save }} onClick={onClickSave}>
            SAVE
          </Button>
        </Box>
      )}
    </div>
  )
}

export default FrontDeskPanel
