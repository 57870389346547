import * as React from 'react'
import DraggableListItem from './DraggableListItem'
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import { HubButtonITF } from '@/types/panel'
import { QRCodeFunctionType } from '@/types'

export type DraggableListProps = {
  items?: HubButtonITF[]
  onDragEnd: OnDragEndResponder
  onClick?: (item: any, index: number) => void
  onRemove?: (item: any, index: number) => void
  onChange?: (data: any, index: number) => void
  onChangeTemplate?: (value: any, index: number, funcType: QRCodeFunctionType) => void
  disabled?: boolean
}

const DraggableList = (props: DraggableListProps) => {
  const { items, onDragEnd, onClick, onChange, onChangeTemplate, onRemove, disabled } = props
  if (!Array.isArray(items) || items.length === 0) {
    return null
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" isDropDisabled={disabled}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <DraggableListItem
                item={item}
                index={index}
                disabled={disabled}
                key={(item.DraggableId || item.Id) + index}
                onClick={onClick}
                onChange={onChange}
                // onChangeTemplate={ (value) => onChangeTemplate?.(value, index, item.FunctionalityType) }
                onRemove={onRemove}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList
