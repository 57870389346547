import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  paper: {
    backgroundColor: '#fff',
    padding: 16,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginTop: 16,
    color: '#211F1F',
    flex: '1 0 0',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 600,
    fontSize: 23,
    marginBottom: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  absolute: {
    flex: 1,
    width: 0,
  },
  table: {
    tableLayout: 'fixed',
  },
  tableHead: {
    background: '#EDF4FB',
    borderRadius: 6,
  },
  cell: {
    border: 'none',
  },
  frequencyCell: {
    display: 'flex',
    alignItems: 'center',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  questionStickyHeader: {
    position: 'sticky',
    top: 56,
    zIndex: 1,
  },
  tableRow: {
    verticalAlign: 'top',
  },
  checked: {},
  textField: {
    width: '100%',
    border: 'none',
  },
  autocomplete: {
    '&[class*=MuiOutlinedInput-root]': {
      padding: '0 9px',
      borderRadius: 4,
    },
  },
  option: {
    minWidth: 150,
  },
  paperMax: {
    wordBreak: 'break-all',
    minWidth: 300,
  },
  frequencyOption: {
    minWidth: 50,
  },
  listbox: {
    width: 'auto',
    maxHeight: 300,
    overflowY: 'auto',
  },
  select: {
    maxHeight: 300,
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  cellInputRoot: {
    borderRadius: 4,
  },
  cellInput: {
    padding: '8.5px 10px',
  },
  frequencyTimes: {
    width: 75,
  },
  frequencyText: {
    minWidth: 70,
    padding: '0 4px',
    textAlign: 'center',
  },
  add: {
    display: 'flex',
    alignItems: 'center',
    color: '#1976D2',
    textTransform: 'none',
    padding: '8px 16px',
    marginTop: 8,
  },
  firstCell: {
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontWeight: 600,
  },
  color: {
    color: '#000',
  },
  borderLeft: {
    borderLeft: '1px solid #1976D2',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 18,
    background: '#FBFBFB',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginBottom: 32,
    height: 60,
    boxSizing: 'border-box',
  },
  actionIcon: {
    color: '#466CB5',
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabled: {
    opacity: '0.4 !important',
  },
  multiline: {
    alignItems: 'flex-start',
    padding: 0,
  },
  readOnly: {
    border: 'none',
  },
}))
