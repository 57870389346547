import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  container: {
    padding: '12px 0',
  },
  backButton: {
    background: 'transparent',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    color: '#211F1F',
    cursor: 'pointer',
    fontSize: 16,
    '& svg': {
      marginRight: 4,
    },
  },
  backText: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 400,
  },
  border: {
    borderBottom: '1px solid #BCBCBC',
  },
  subTitle: {
    color: '#313131',
    fontSize: 16,
    lineHeight: '24px',
    paddingLeft: 16,
  },
}))
