import React, { useEffect, useState, useCallback, useImperativeHandle, useRef } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import EditAndSave from '@component/Button/EditAndSave'

interface Information {
  name: string
  label: string
  placeholder?: string
  value?: string
}

const sourceData: Information[] = [
  {
    name: 'MarketingBrochure',
    label: 'Marketing Brochure',
    placeholder: 'URL',
    value: '',
  },
  {
    name: 'FacebookUrl',
    label: 'Facebook ',
    placeholder: 'Facebook',
    value: '',
  },
  { name: 'LinkedInUrl', label: 'LinkedIn ', placeholder: 'LinkedIn ', value: '' },
  { name: 'AppStoreUrl', label: 'Building App on App Store:', placeholder: 'URL', value: '' },
  { name: 'InstagramUrl', label: 'Instagram ', placeholder: 'Instagram ', value: '' },
  { name: 'YoutubeUrl', label: 'Youtube', placeholder: 'Youtube', value: '' },
  { name: 'GooglePlayUrl', label: 'Building App on Google Play Store:', placeholder: 'URL', value: '' },
  { name: 'TwitterUrl', label: 'Twitter', placeholder: 'Twitter', value: '' },
  { name: 'TikTokUrl', label: 'TikTok', placeholder: 'TikTok', value: '' },
  { name: 'VimeoUrl', label: 'Vimeo Url', placeholder: 'Vimeo Url', value: '' },
]

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
    },
    information: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    textField: {
      minWidth: 230,
      flex: '25%',
      margin: '0 12px 24px',
    },
    empty: {
      height: 0,
      margin: '0 12px',
    },
    shrink: {
      backgroundColor: '#fff',
      padding: '0 5px',
    },
    asterisk: {
      color: '#ff0000',
    },
    select: {
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    phoneInput: {
      '& .form-control': {
        width: '100%',
        borderRadius: 10,
      },
      '& .special-label': {
        left: 10,
      },
    },
  }
})

interface Props {
  informationRef: React.MutableRefObject<{ contentData: Information[] }>
  isEdit: boolean
  editData: any
  onSave: () => void
}
const PropertyContent = (props: Props) => {
  const { informationRef, isEdit, editData, onSave } = props
  const classes = useStyles()
  const [informationData, setInformationData] = useState(JSON.parse(JSON.stringify(sourceData)))
  const [render, setRender] = useState(false)
  const [onlyView, setOnlyView] = useState(isEdit)

  const onCancel = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const idx = informationData.findIndex((v) => v.name === name)
    informationData[idx]['value'] = value
    setRender((v) => !v)
  }, [])

  useEffect(() => {
    if (isEdit) {
      informationData.map((v) => {
        v.value = editData[v.name]
      })
      setRender((v) => !v)
    }
  }, [isEdit, editData])

  useImperativeHandle(
    informationRef,
    () => ({
      ...informationRef.current,
      contentData: informationData,
    }),
    []
  )

  return (
    <Box className={`${classes.content} ${classes.information}`}>
      {informationData.map((v, i) => {
        const { name, label, value = '' } = v
        return (
          <TextField
            key={name}
            {...v}
            type="url"
            variant="outlined"
            disabled={onlyView}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
              classes: {
                asterisk: classes.asterisk,
              },
            }}
            onChange={handleChange}
          />
        )
      })}
      {new Array(2).fill(true).map((v, i) => (
        <Box key={i} className={`${classes.textField} ${classes.empty}`} />
      ))}
      <EditAndSave showSave={!onlyView} onCancel={onCancel} onEdit={onEdit} onSave={onSave} />
    </Box>
  )
}
export default React.memo(PropertyContent)
