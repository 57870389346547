import React, { useCallback, useEffect, useState } from 'react'
import { COLOR } from '@constant/app'
import { makeStyles } from '@mui/styles'
import { TextField, Theme, Typography, Autocomplete, OutlinedInputProps } from '@mui/material'
import { PanelChoice } from '@/types/panel'
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete'
import { SxProps } from '@mui/system'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, { height?: string | number; width?: string | number }>((theme) => {
  return {
    label: {
      fontSize: 16,
      width: 110,
      color: COLOR.black11,
    },
    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    inputRoot: {
      height: (props) => props.height || 31,
      borderRadius: 4,
      width: (props) => props.width || 200,
      '&[class*="MuiOutlinedInput-root"]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    textFiledRoot: {
      width: (props: { width?: string | number }) => props.width || 200,
    },
    inputLabel: {
      transform: 'translate(14px, 14px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, -8px) scale(0.75)',
      },
    },
    input: {
      padding: '0 !important',
      '&:first-child': {
        color: '#4D4D4D',
      },
    },
    formControl: {
      border: ' 1px solid #e0e0e3',
      borderRadius: 4,
      height: 31,
    },
  }
})

interface PanelSelectionProps {
  choices: Array<PanelChoice>
  label?: string
  value?: PanelChoice | null | undefined
  // editValue?: PanelChoice | null | undefined;
  onChange?: (item: PanelChoice | null) => void
  width?: string | number
  height?: string | number
  leftLabel?: string
  className?: string
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: PanelChoice,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode
  getOptionLabel?: (option: PanelChoice) => string
  isOptionEqualToValue?: (option: PanelChoice, value: PanelChoice) => boolean
  disabled?: boolean
  disableClearable?: boolean
  popupIcon?: React.ReactNode
  sx?: SxProps<Theme>
  inputLabelClass?: string
  InputProps?: OutlinedInputProps
  loading?: boolean
}

const PanelSelection: React.FC<PanelSelectionProps> = ({
  choices,
  value,
  label,
  onChange,
  width,
  leftLabel,
  renderOption,
  height,
  className,
  getOptionLabel,
  isOptionEqualToValue,
  disabled,
  disableClearable = true,
  popupIcon,
  sx,
  inputLabelClass,
  loading,
  InputProps,
}) => {
  const classes = useStyles({ width, height })

  // @ts-ignore
  const [rawValue, setRawValue] = useState<PanelChoice | undefined | null>(value || null)

  const onChangeOption = useCallback(
    (event: any, newValue: PanelChoice | null) => {
      setRawValue(newValue)
      onChange?.(newValue)
    },
    [onChange]
  )

  useEffect(() => {
    // @ts-ignore
    setRawValue(value || null)
  }, [value])

  return (
    <Autocomplete
      disableClearable={disableClearable}
      value={rawValue}
      sx={sx}
      className={className}
      options={choices}
      loading={loading}
      disabled={disabled}
      isOptionEqualToValue={(option: PanelChoice, value: PanelChoice) => {
        if (isOptionEqualToValue) {
          return isOptionEqualToValue(option, value)
        }
        return option?.value ? option?.value === value?.value : true
      }}
      getOptionLabel={(option: PanelChoice) => {
        if (getOptionLabel) {
          return getOptionLabel(option)
        }
        return option?.label || ''
      }}
      onChange={onChangeOption}
      renderOption={renderOption}
      classes={{
        input: classes.input,
        inputRoot: classes.inputRoot,
      }}
      popupIcon={popupIcon}
      renderInput={(params) => {
        return (
          <div className={classes.row}>
            {leftLabel && <Typography className={classes.label}>{leftLabel}</Typography>}
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, ...(InputProps || {}) }}
              label={label}
              classes={{
                root: classes.textFiledRoot,
              }}
              InputLabelProps={{ classes: { root: classnames(classes.inputLabel, inputLabelClass) } }}
              placeholder="select"
              variant="outlined"
            />
          </div>
        )
      }}
    />
  )
}

export default PanelSelection
