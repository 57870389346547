import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import makeStyles from '@mui/styles/makeStyles'
import EditButtonItem from '@component/EditPanel/EditButtonItem'
import { HubButtonITF } from '@/types/panel'

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
})

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  // padding: 5 * 2,
  // margin: `0 0 ${5}px 0`,
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

export type DraggableListItemProps = {
  item: HubButtonITF
  index: number
  onClick?: (item: any, index: number) => void
  onRemove?: (item: any, index: number) => void
  onChange?: (data: any, index: number) => void
  onChangeTemplate?: (value: any) => void
  disabled?: boolean
}

const DraggableListItem = ({
  item,
  index,
  onClick,
  onChange,
  onChangeTemplate,
  onRemove,
  disabled,
}: DraggableListItemProps) => {
  const classes = useStyles()
  return (
    <Draggable draggableId={item.DraggableId || item.Id} index={index} isDragDisabled={disabled}>
      {(draggableProvided, draggableSnapshot) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          // {...draggableProvided.dragHandleProps}
          style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
        >
          <EditButtonItem
            key={index}
            disabled={disabled}
            index={index}
            item={item}
            onClick={onClick}
            onRemove={onRemove}
            //@ts-ignore
            onChange={onChange}
            //@ts-ignore
            dragHandleProps={draggableProvided.dragHandleProps}
          />
        </div>
      )}
    </Draggable>
  )
}

export default DraggableListItem
