interface ChoicesProps {
  Label: string
  Text: {
    en: string
    es: string
  }
  Value: string
}

const FieldType = {
  Text: {
    Code: '',
    FieldStyle: 'Text',
    Additional: true,
    FieldType: '',
    FieldText: {
      en: '',
      es: '',
    },
    Required: false,
  },
  PhoneNumber: {
    Code: '',
    Additional: true,
    FieldStyle: 'Text',
    FieldType: 'PhoneNumber',
    FieldText: {
      en: 'Phone Number',
      es: 'Phone Number',
    },
    Required: false,
  },
  Email: {
    Code: '',
    Additional: true,
    FieldStyle: 'Text',
    FieldType: 'Email',
    FieldText: {
      en: 'Email',
      es: 'Email',
    },
    Required: false,
  },
  TextBox: {
    //多行文本
    Code: '',
    Additional: true,
    FieldText: {
      en: 'Details',
      es: 'Details',
    },
    FieldType: 'Details',
    FieldStyle: 'TextBox',
    Required: false,
    Multiline: true,
  },
  Date: {
    Code: '',
    Additional: true,
    FieldStyle: 'Date',
    FieldType: 'Date',
    FieldText: {
      en: 'Date',
      es: 'Date',
    },
    Required: false,
  },
  Duration: {
    Code: '',
    Additional: true,
    FieldStyle: 'Duration',
    FieldType: 'Duration',
    FieldText: {
      en: 'Time Range',
      es: 'Time Range',
    },
    Required: false,
    ValueChoices: [],
    ValueTexts: [],
  },
  TimeString: {
    Code: '',
    Additional: true,
    FieldStyle: 'TimeString',
    FieldType: 'TimeString',
    FieldText: {
      en: 'Time (No Range)',
      es: 'Time (No Range)',
    },
    Required: false,
    ValueChoices: [],
    ValueTexts: [],
  },
  DropDownSelection: {
    Code: '',
    Additional: true,
    DisabledDelete: false,
    FieldText: {
      en: '',
      es: '',
    },
    FieldType: '',
    ValueText: '',
    FieldStyle: 'DropDownSelection',
    Choices: [] as ChoicesProps[],
    Required: false,
  },
  UploadImage: {
    Code: '',
    Additional: true,
    FieldType: 'Image',
    FieldStyle: 'UploadImage',
    FieldText: {
      en: 'Add an image',
      es: 'Add an image',
    },
    Required: false,
  },
  Signature: {
    Code: '',
    Additional: true,
    FieldType: 'Signature',
    FieldStyle: 'Signature',
    FieldText: {
      en: 'Add your signature',
      es: 'Add your signature',
    },
    Required: true,
  },
  CheckBox: {
    Code: '',
    Additional: true,
    FieldText: { en: '', es: '' },
    FieldType: 'CheckBox',
    FieldStyle: 'CheckBox',
    Choices: [
      {
        Label: 'Label-0',
        Text: {
          en: 'option 1',
          es: 'option 1',
        },
        Value: 'option 1',
      },
      {
        Label: 'Label-1',
        Text: {
          en: 'option 2',
          es: 'option 2',
        },
        Value: 'option 2',
      },
    ],
    Required: false,
  },
  SingleChoice: {
    Code: '',
    Additional: true,
    FieldText: { en: '', es: '' },
    FieldType: 'SingleChoice',
    FieldStyle: 'SingleChoice',
    Choices: [
      {
        Label: 'Label-0',
        Text: {
          en: 'option 1',
          es: 'option 1',
        },
        Value: 'option 1',
      },
      {
        Label: 'Label-1',
        Text: {
          en: 'option 2',
          es: 'option 2',
        },
        Value: 'option 2',
      },
    ],
    Required: false,
  },
  SingleCheckBox: {
    Code: '',
    Additional: true,
    FieldType: 'SingleCheckBox',
    FieldStyle: 'SingleCheckBox',
    FieldText: {
      en: '',
      es: '',
    },
    Required: false,
  },
  DownLoadFile: {
    Code: '',
    Additional: true,
    FieldType: 'DownLoadFile',
    FieldStyle: 'DownLoadFile',
    FieldText: {
      en: 'Terms & Conditions',
      es: 'Terms & Conditions',
    },
    Required: false,
  },
  UploadFiles: {
    Code: '',
    Additional: true,
    FieldType: 'UploadFiles',
    FieldStyle: 'UploadFiles',
    FieldText: {
      en: 'UploadFiles',
      es: 'UploadFiles',
    },
    Required: false,
  },
}
export type FieldItem = typeof FieldType.DropDownSelection

export default FieldType
