import React, { useEffect, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { FileUploadOutlined, InsertDriveFile, Cancel } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import { uploadFileBase64 } from '@api'
import { toBase64 } from '@/utils'
import EditAndSave from '@component/Button/EditAndSave'

const S3_Bucket = process.env.REACT_APP_S3_BUCKET
const DataKey = {
  SupplierCheckInTerms: {
    label: 'Supplier Check In',
    value: '',
  },
  EVRequestTerms: {
    label: 'EV Request',
    value: '',
  },
  VacancyLeadTerms: {
    label: 'Vacancy Lead',
    value: '',
  },
  RoomRentalTerms: {
    label: 'Room Rental',
    value: '',
  },
  OtherLeadTerms: {
    label: 'Other Lead Capture',
    value: '',
  },
}
type StateKey = keyof typeof DataKey

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
      position: 'relative',
    },
    color: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    label: {
      marginBottom: 8,
      color: '#374053',
      fontSize: 16,
    },
    preview: {
      marginLeft: 20,
    },
    previewButton: {
      width: 161,
      textAlign: 'center',
      color: '#386BBF',
      marginTop: 24,
      textTransform: 'none',
    },
    emptyImg: {
      width: 161,
      height: 161,
      textAlign: 'center',
      lineHeight: '161px',
    },
    upload: {
      backgroundColor: '#386BBF',
      padding: '8px 16px',
      color: '#fff',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
    },
    img: {
      border: '1px solid #BCBCBC',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      cursor: 'pointer',
      flex: 1,
      marginLeft: 16,
      backgroundColor: '#F5F5F5',
      padding: '0 8px',
    },
    pdf: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '0 8px',
    },
    box: {
      display: 'flex',
      width: '800px',
      flexWrap: 'wrap',
    },
    formControl: {
      flex: 1,
      minWidth: '40%',
      marginBottom: 24,
      '&:nth-child(2n-1)': {
        marginRight: '24px',
      },
    },
    select: {
      padding: '12px 16px 4px',
    },
  }
})

interface Props {
  gId: string
  informationRef: React.MutableRefObject<any>
  disabled?: boolean
  isEdit?: boolean
  editData?: any
  onSave: () => void
  onChangeData?: (state: any) => void
  hiddenPreview?: boolean
  hiddenCancel?: boolean
}

const PropertyTerms = (props: Props) => {
  const { gId, informationRef, isEdit, editData, onSave, disabled, onChangeData, hiddenCancel } = props
  const classes = useStyles()
  const [onlyView, setOnlyView] = useState(isEdit)
  const cacheState = useRef<any>({})
  const [state, setState] = useSetState({})
  const getData = useCallback(async () => {
    return cacheState.current
  }, [])

  const onCancel = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const handleQRLogoChange = async (event: any, type: string) => {
    const images = event.target.files as File[]
    if (images[0]) {
      const base64 = await toBase64(images[0])
      const s3Image: any = await uploadFileBase64({
        base64,
        fileName: `${gId}/${type}_${Date.now()}/${images[0].name}`,
        bucket: S3_Bucket,
      })
      // @ts-ignore
      setState({ [type]: s3Image.fileUrl as string })
    }
  }

  useImperativeHandle(
    informationRef,
    () => ({
      ...informationRef.current,
      getTermsData: getData,
    }),
    [getData]
  )

  useEffect(() => {
    const state = {}
    for (const key in DataKey) {
      state[key as StateKey] = isEdit ? editData[key] || '' : ''
    }
    setState({ ...state })
  }, [isEdit, editData, DataKey])
  useEffect(() => {
    cacheState.current = state
    onChangeData?.(state)
  }, [state, onChangeData])
  return (
    <Box className={`${classes.content}`}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.entries(DataKey).map(([key, { label }]) => {
          const value = state[key as StateKey]
          return (
            <Box key={key} sx={{ flex: '48% 0 0', margin: '0 1% 24px 0' }}>
              <Typography className={classes.label}>{label}</Typography>
              <input
                accept=".pdf"
                style={{ display: 'none' }}
                id={key}
                onChange={(e) => handleQRLogoChange(e, key)}
                type="file"
                disabled={disabled || onlyView}
              />
              <Box style={{ display: 'flex' }}>
                <label htmlFor={key} className={classes.upload}>
                  Upload
                  <FileUploadOutlined sx={{ marginLeft: '8px' }} />
                </label>

                <Box className={classes.img}>
                  <InsertDriveFile style={{ fontSize: 24, color: '#1976D2' }} />
                  <span className={classes.pdf} onClick={() => value && window.open(value, '_blank')}>
                    {value ? value.substring(value.lastIndexOf('/') + 1) : 'No file uploaded'}
                  </span>
                  {!disabled && !onlyView && value && (
                    <Cancel style={{ fontSize: 24, color: '#313131' }} onClick={() => setState({ [key]: '' })} />
                  )}
                </Box>
              </Box>
            </Box>
          )
        })}
      </div>
      {!disabled && (
        <EditAndSave
          showSave={!onlyView}
          onCancel={onCancel}
          onEdit={onEdit}
          onSave={onSave}
          hiddenCancel={hiddenCancel}
        />
      )}
    </Box>
  )
}
export default React.memo(PropertyTerms)
