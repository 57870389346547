const axios = require('axios')

async function getCoordinates(apiKey, address) {
  const baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
  const params = {
    address: address,
    key: apiKey,
  }

  try {
    const response = await axios.get(baseUrl, { params: params })
    const data = response.data
    if (data.status === 'OK') {
      const latitude = data.results[0].geometry.location.lat
      const longitude = data.results[0].geometry.location.lng
      return { latitude, longitude }
    } else {
      return { latitude: null, longitude: null }
    }
  } catch (e) {
    return { latitude: null, longitude: null }
  }
}

export { getCoordinates }
