import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Modal, IconButton, Grid, Typography, Radio, Button } from '@mui/material'
import { KeyboardArrowRight, Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { getPrintSettingFilter, getScanTrendData } from '@api'
import useSetState from '@hooks/useSetState'
import * as echarts from 'echarts'
import { ECBasicOption } from 'echarts/types/dist/shared'
import { PanelChoice } from '@/types/panel'
import { PrintSettingITF } from '@/types/survey'
import MultipleSelect from '@component/Filter/MultipleSelect'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    padding: 24,
    height: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 32,
  },
  modalBtn: {
    fontSize: 15,
    fontWeight: 500,
    padding: '8px 16px',
    borderRadius: 4,
    marginLeft: 16,
  },
  wrap: {
    display: 'flex',
    flex: 1,
    minHeight: 300,
    flexDirection: 'column',
    alignItems: 'center',
  },
  filter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    marginBottom: 24,
  },
  inputLabel: {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, -8px) scale(0.75)',
    },
  },
  input: {
    flex: 1,
  },
  imgWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 192,
    width: '100%',
  },
  img: {
    maxWidth: '100%',
    maxHeight: 192,
  },
  grid: {
    marginBottom: 24,
  },
  gridContainer: {
    flex: 1,
    marginBottom: 12,
    maxHeight: 540,
    overflowY: 'scroll',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    padding: 0,
    width: '100%',
    overflow: 'hidden',
    border: '1px solid #BCBCBC',
    background: '#F5F5F5',
  },
  titleWrap: {
    display: 'flex',
    height: 58,
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 8px 0 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#211F1F',
    fontSize: 20,
    fontWeight: 600,
  },
}))

interface Props {
  onClose?: () => void
  printSettings?: PrintSettingITF[]
  selected?: PrintSettingITF
  onSelect: (item: PrintSettingITF) => void
  qrName?: string
}

interface PrintSettingFilterITF {
  Surface: Array<string>
  Size: Array<string>
  Substrate: Array<string>
  Orientation: Array<string>
  Color: Array<string>
}

const handleSelected = (data: Array<string>) => {
  const arr = data.map((item) => ({ label: item, value: item }))
  return arr as Array<PanelChoice>
}
const isEqual = (item: PrintSettingITF, filter: any, key: string) => {
  const equal =
    !filter?.[key] || filter?.[key]?.length === 0 || !!filter?.[key]?.find((label) => label.label === item[key])
  return equal
}
const PrintSettingSelected: React.FC<Props> = React.memo(({ selected, onClose, printSettings, onSelect, qrName }) => {
  const classes = useStyles()
  const [data, setData] = useSetState<{
    loading: boolean
    filter?: any
    itemSelected?: PrintSettingITF
    filters: {
      Surface: Array<PanelChoice>
      Size: Array<PanelChoice>
      Substrate: Array<PanelChoice>
      Orientation: Array<PanelChoice>
      Color: Array<PanelChoice>
    }
  }>({
    loading: true,
    itemSelected: selected,
    filters: { Surface: [], Size: [], Substrate: [], Orientation: [], Color: [] },
  })
  const { filters, loading, filter, itemSelected } = data
  const settings = useMemo(() => {
    return printSettings?.filter((item) => {
      return (
        isEqual(item, filter, 'Surface') &&
        isEqual(item, filter, 'Size') &&
        isEqual(item, filter, 'Substrate') &&
        isEqual(item, filter, 'Orientation') &&
        isEqual(item, filter, 'Color')
      )
    })
  }, [filter, printSettings])
  const getData = useCallback(() => {
    getPrintSettingFilter<PrintSettingFilterITF>({ qrName }).then((res) => {
      setData({
        filters: {
          Surface: handleSelected(res.Surface),
          Size: handleSelected(res.Size),
          Substrate: handleSelected(res.Substrate),
          Orientation: handleSelected(res.Orientation),
          Color: handleSelected(res.Color),
        },
      })
    })
  }, [qrName, setData])
  const onChangeSelection = useCallback(
    (item: PanelChoice[], key: string) => {
      setData((prevState) => {
        return Object.assign(prevState, { filter: Object.assign({}, prevState.filter, { [key]: item }) })
      })
    },
    [setData]
  )
  const onClickItem = (item: PrintSettingITF) => {
    if (item.acId === itemSelected?.acId) {
      setData({ itemSelected: undefined })
    } else {
      setData({ itemSelected: item })
    }
  }
  const onSelectOption = () => {
    if (!itemSelected) return
    onSelect(itemSelected)
  }
  useEffect(() => {
    getData()
  }, [getData])
  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.modal}>
        <Box className={classes.header}>
          <Box>Alternative Selection</Box>
          <IconButton onClick={onClose} style={{ padding: 0 }}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>
        <Box className={classes.wrap}>
          <Box className={classes.filter}>
            <MultipleSelect
              data={filters.Surface}
              label="Surface"
              isMulti
              onChange={(item) => onChangeSelection(item, 'Surface')}
              className={classes.input}
            />
            <MultipleSelect
              data={filters.Size}
              label="Size"
              isMulti
              className={classes.input}
              onChange={(value) => onChangeSelection(value, 'Size')}
            />
            <MultipleSelect
              data={filters.Substrate}
              label="Substrate"
              className={classes.input}
              isMulti
              onChange={(item) => onChangeSelection(item, 'Substrate')}
            />
            <MultipleSelect
              data={filters.Orientation}
              label="Orientation"
              isMulti
              className={classes.input}
              onChange={(item) => onChangeSelection(item, 'Orientation')}
            />
            <MultipleSelect
              data={filters.Color}
              isMulti
              label="Color"
              className={classes.input}
              onChange={(item) => onChangeSelection(item, 'Color')}
            />
          </Box>
          <Grid container columnSpacing="24px" className={classes.gridContainer}>
            {settings?.map((item, index) => {
              return (
                <Grid item key={item.acId} xs={4} className={classes.grid}>
                  <Button className={classes.item} onClick={() => onClickItem(item)}>
                    <Box className={classes.titleWrap}>
                      <Typography className={classes.title}>Option {index + 1}</Typography>
                      <Radio checked={itemSelected?.acId === item.acId} />
                    </Box>
                    <Box className={classes.imgWrap}>
                      <img src={item.PrintTemplateImage} className={classes.img} />
                    </Box>
                  </Button>
                </Grid>
              )
            })}
          </Grid>
          <Button variant="contained" sx={{ alignSelf: 'flex-end' }} disabled={!itemSelected} onClick={onSelectOption}>
            SELECT ALTERNATIVE OPTION
            <KeyboardArrowRight />
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

export default PrintSettingSelected
