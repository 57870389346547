import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/styles'
import { makeStyles } from '@mui/styles'
import { COLOR } from '@constant/app'

const useStyles = makeStyles((theme) => {
  return {
    label: {
      marginTop: 20,
      transform: 'scale(0.75)',
      color: COLOR.grey37,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 7,
      paddingBottom: 5,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .MuiButton-containedPrimary': {
        fontSize: '0.75rem',
        padding: '6px 20px',
        borderRadius: 3,
      },
    },
    root: {
      textTransform: 'none',
    },
    content: {
      flex: 1,
      fontFamily: '',
      color: COLOR.grey91,
    },
  }
})

export interface UploadProps {
  accept?: string
  label?: string
  fileName?: string
}

const Input = styled('input')({
  display: 'none',
})

const Upload: React.FC<UploadProps> = ({ label = '', fileName }) => {
  const classes = useStyles()
  const [content, setContent] = useState(fileName)
  const onChange = useCallback((event) => {
    setContent(event.target.value)
  }, [])
  return (
    <Box sx={{ minWidth: '53%' }}>
      <InputLabel className={classes.label}>{label || 'Upload File'}</InputLabel>
      <Box className={classes.container}>
        <Typography variant="subtitle1" className={classes.content}>
          {fileName}
        </Typography>
        <label htmlFor="contained-button-file">
          <Input accept="image/*" id="contained-button-file" type="file" onChange={onChange} />
          <Button variant="contained" component="span" color="primary" classes={{ root: classes.root }}>
            Upload
          </Button>
        </label>
      </Box>
    </Box>
  )
}

export default Upload
