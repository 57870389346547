import React, { useState, useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import { InventoryList, InventoryITF } from '@activate-inc/activate-ui'
import { Template } from '@/types/entity'

interface Props {
  template?: Template
  qrTypeName: string
  qrId: string
  hubItemId?: string
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 56px)',
      flexBasis: 'auto',
      '@supports (-webkit-touch-callout: none)': {
        minHeight: '-webkit-fill-available',
      },
    },
  }
})

const InventoryForm: React.FC<Props> = ({ template, qrTypeName, qrId, hubItemId }) => {
  const classes = useStyles()
  const { acId, Floor, Items, Location, Status, InstallationNote } = template || {}
  const [orderSuccess, setOrderSuccess] = useState(false)
  const [edit, setEdit] = useState<{ item: InventoryITF | null; index: number; showModal: boolean }>()
  const onEditItem = useCallback((data: InventoryITF, index: number) => {
    setEdit({ item: data, index, showModal: true })
  }, [])

  const onCloseItemModal = useCallback(() => {}, [])
  const onItemEdit = useCallback((data: InventoryITF, index: number) => {}, [])
  const onSave = useCallback(() => {}, [Items, acId])
  return (
    <InventoryList
      className={classes.container}
      qrTypeName={qrTypeName}
      floor={Floor}
      location={InstallationNote}
      items={Items}
      canOrder={false}
      onSave={onSave}
      showOrder={orderSuccess}
    />
  )
}
export default InventoryForm
