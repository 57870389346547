import React, { useCallback } from 'react'
import { SelfieForm } from '@activate-inc/activate-ui'
import Images from '@assets/images'

interface Props {
  propertyName: string
}
const StaffCheckInForm: React.FC<Props> = ({ propertyName }) => {
  const onHelp = useCallback(() => {}, [])
  return (
    <SelfieForm
      onHelp={onHelp}
      showHelp
      selfie={Images.iconResident}
      title={'Welcome [Staff Member Name]'}
      onChange={() => {}}
      pageTitle={propertyName}
      btnText={'CHECK-IN'}
      btnOnClick={() => {}}
    />
  )
}

export default StaffCheckInForm
