import { createTheme, keyframes } from '@mui/material/styles'
import defaultTheme from './default'
const primary = '#386BBF'
const blockBackgroundColor = '#e3e3e3'
const titleTextColor = '#a2a2a2'

// button按钮 全局防抖
const debounce = keyframes({
  from: {
    pointerEvents: 'none',
  },
  to: {
    pointerEvents: 'auto',
  },
})

const darkTheme = {
  palette: {
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#FBBA72',
    },
    textgray: {
      main: '#a2a2a2',
    },
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 240,
    closedWidth: 190,
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: '#ffffffb3',
        backgroundColor: '#616161e6',
      },
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },
}

const lightTheme = {
  //   palette: {
  //       primary: {
  //           main: '#4f3cc9',
  //       },
  //       secondary: {
  //           light: '#5f5fc4',
  //           main: '#283593',
  //           dark: '#001064',
  //           contrastText: '#fff',
  //       },
  //       background: {
  //           default: '#fcfcfe',
  //       },
  //       type: 'light',
  //   },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 240,
    closedWidth: 190,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
        fontSize: 16,
        fontFamily: 'Roboto, Regular',
        height: '48px',
        color: titleTextColor,
        '&:focus': {
          backgroundColor: blockBackgroundColor,
          color: primary,
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: primary,
          },
        },
      },
      active: {
        borderLeft: `3px solid ${primary}`,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: primary,
        boxShadow: 'none',
      },
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        border: 'none',
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },
}

const overrides = {
  typography: {
    h1: {
      fontSize: '3rem',
      fontFamily: 'proxima-nova,sans-serif',
    },
    h2: {
      fontSize: '2rem',
      fontFamily: 'proxima-nova,sans-serif',
    },
    h3: {
      fontSize: '1.4rem',
      fontFamily: 'proxima-nova,sans-serif',
    },
    h4: {
      fontSize: '1.3rem',
      fontFamily: 'proxima-nova,sans-serif',
    },
    h5: {
      fontSize: '1.285rem',
      fontFamily: 'proxima-nova,sans-serif',
    },
    h6: {
      fontSize: '1.142rem',
      fontFamily: 'proxima-nova,sans-serif',
    },
  },
}
export const theme = createTheme({
  palette: {
    primary: {
      main: '#386BBF',
    },
    divider: '#C4C4C4',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          animation: `${debounce} 1s step-end forwards`,
          '&:active': {
            animation: 'none',
          },
        },
      },
    },
  },
})

const themes = {
  default: createTheme({ ...defaultTheme, ...overrides }),
  defaultLight: createTheme({ ...defaultTheme, ...lightTheme, ...overrides }),
  defaultDark: createTheme({ ...defaultTheme, ...darkTheme, ...overrides }),
}

export default themes
