import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Images from '@assets/images'
import IconButtons from '@component/Header/IconButtons'

interface BackButtonProps {
  handleBack?: () => void
  title?: string
  disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 32px 16px 12px',
    backgroundColor: 'white',
  },
  flex: {
    flex: 1,
  },
  back: {
    marginRight: 12,
    width: 24,
    height: 24,
  },
  backButton: {
    padding: 12,
    textTransform: 'none',
  },
  title: {
    color: '#211F1F',
  },
}))
const BackHeader: React.FC<BackButtonProps> = ({ handleBack, title, disabled }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const back = useCallback(() => {
    if (handleBack) {
      handleBack()
    } else {
      navigate(-1)
    }
  }, [])
  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <Button className={classes.backButton} onClick={back} disabled={disabled}>
          {!disabled && <img src={Images.back} className={classes.back} />}
          <Typography className={classes.title}>{title || 'Back'}</Typography>
        </Button>
      </div>
      <IconButtons />
    </div>
  )
}
export default BackHeader
