import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { TextField, Grid, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from '@mui/styles'
import BackButton from '@component/BackButton/BackButton'
import CommentReviews from './CommentReviews'
import Echarts, { EchartsProps, WordCloudProps } from '@component/Chart/Echarts'
import OverAllCard from '@component/Card/OverAllCard'
import { getBathroomServiceWordClouds, getBathroomServiceComments, getBathroomServiceTopics } from '@api/index'
import { IComment, IWordCloud, ResponseComment, ResponseTopics } from '@/types'
import { DEFAULT_PERIOD_LAST_YEAR, PERIOD_OPTIONS } from '../../global'
import EmptyDisplay from '@component/Empty/EmptyDisplay'
import { useSelector } from 'react-redux'
import WordPlaceHolder from '@component/Empty/WordPlaceHolder'

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: 'Roboto',
    color: '#504c4c',
    fontSize: 20,
    marginLeft: 10,
  },
  container: {
    display: 'flex',
    padding: '16px 16px 21px',
  },
  title: {
    minHeight: 52,
    fontSize: 24,
    fontFamily: 'Roboto',
    color: '#5e5d5d',
  },
  divider: {
    marginTop: 10,
  },
  period: {
    height: 48,
    width: 200,
    color: '#4D4D4D',
    fontSize: 16,
    borderRadius: 6,
    backgroundColor: 'white',
  },
  notchedOutline: {
    borderColor: '#A29D9D',
  },
  topHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 32px',
    justifyContent: 'space-between',
  },
  topicsBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    height: 'auto',
    overflowY: 'auto',
    paddingTop: 16,
  },
  topicsItem: {
    borderRadius: '50%',
    borderColor: '#E25858',
    borderStyle: 'solid',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'solid 8px #fff',
    borderWidth: 10,
    boxShadow: '0px 0px 9px 6px #66666a',
    boxSizing: 'border-box',
  },
  topicsText: {
    fontSize: 16,
    color: '#333',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}))

const getWordOption = (seriesData: IWordCloud[]): WordCloudProps & EchartsProps => {
  return {
    id: 'cloudchart',
    type: 'wordCloud',
    width: '100%',
    height: '100%',
    seriesData: [...seriesData].slice(0, 100).map((item: IWordCloud) => {
      const colorArr = ['#E30613', '#F8C636', '#4BCF5D'] //红色-消极 橙色-中性 绿色-积极
      let index = 1
      if (item.SentimentScore > 0) {
        index = 2
      }
      if (item.SentimentScore < 0) {
        index = 0
      }
      return {
        name: item.Label,
        value: item.Value,
        textStyle: {
          color: colorArr[index] || 'red',
        },
      }
    }),
  }
}

const CommentPlaceHolder = () => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <img
      src="https://cdn.dribbble.com/users/2382015/screenshots/6065978/media/1273880a5b9cc8008301089835f37939.gif"
      height={300}
      alt="loading"
    />
  </Box>
)

const ReportBathroomService = () => {
  const classes = useStyles()
  const [loadingTopics, setLoadingTopics] = useState(true)
  const [loadingWordClouds, setLoadingWordClouds] = useState(true)
  const [loadingComments, setLoadingComments] = useState(true)
  const [topicsOption, setTopicsOption] = useState<any[]>([])
  const [comments, setComments] = useState<IComment[]>([])
  const [wordClouds, setWordClouds] = useState<WordCloudProps & EchartsProps>()
  const { eId } = useParams<{ bId: string; eId: string }>()
  const { selectedGroupId } = useSelector((state) => state.profile)

  const [period, setPeriod] = React.useState<IOption>(DEFAULT_PERIOD_LAST_YEAR)

  const fetchWordClouds = useCallback(async (period) => {
    const result = await getBathroomServiceWordClouds<IWordCloud[]>({ eId, tu: period.value, gId: selectedGroupId })
    setWordClouds(getWordOption(result))
    setLoadingWordClouds(false)
  }, [])
  const fetchEntityComments = useCallback(async (period) => {
    const result = await getBathroomServiceComments<ResponseComment>({ eId, tu: period.value, gId: selectedGroupId })
    setComments(result?.Comments)
    setLoadingComments(false)
  }, [])
  const fetchTopic = useCallback(async (period) => {
    const result = await getBathroomServiceTopics<ResponseTopics>({ eId, tu: period.value, gId: selectedGroupId })
    setTopicsOption(result.Topics)
    setLoadingTopics(false)
  }, [])

  useEffect(() => {
    fetchWordClouds(period)
    fetchEntityComments(period)
    fetchTopic(period)
  }, [period])

  return (
    <Box sx={{ width: '100%', mt: 2, flexDirection: 'column', bgcolor: '#f7f7f7' }}>
      <BackButton />
      <Box className={classes.topHeader}>
        <Typography className={classes.header} variant="h3">
          Bathroom Service Feedback
        </Typography>
        <Autocomplete
          value={period}
          onChange={(event: any, newValue: IOption) => {
            setPeriod(newValue)
          }}
          disableClearable
          id="controllable-states-demo"
          options={PERIOD_OPTIONS}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                className: classes.period,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
                readOnly: true,
              }}
              hiddenLabel
              variant="outlined"
            />
          )}
        />
      </Box>
      <Grid container spacing={1} className={classes.container}>
        <OverAllCard title="All Topics" loading={loadingTopics} md={24}>
          <Box className={classes.topicsBox}>
            {topicsOption.map((v, i) => {
              const { NegativeScoreCount, Topic } = v || { NegativeScoreCount: 0, Topic: '' }
              const width = Math.min(100 + (NegativeScoreCount - 1) * 20, 200)
              const fontSize = Math.min(12 + (NegativeScoreCount - 1) * 2, 20)
              const margin = width * 0.1
              return (
                <Box
                  key={Topic}
                  className={classes.topicsItem}
                  style={{ width: width, height: width, margin: `0 ${margin}px 24px` }}
                >
                  <Typography className={classes.topicsText} style={{ fontSize }}>
                    {Topic}
                  </Typography>
                </Box>
              )
            })}
            {topicsOption?.length === 0 ? <EmptyDisplay /> : null}
          </Box>
        </OverAllCard>
        <OverAllCard
          title="Sentiment Analysis"
          showDivider
          loading={loadingWordClouds}
          extensible={true}
          dataSize={wordClouds?.seriesData?.length}
          key={`${(wordClouds?.seriesData?.length || 0) > 20 ? '740' : '311'}`}
        >
          {wordClouds?.id ? <Echarts {...wordClouds} /> : <WordPlaceHolder />}
        </OverAllCard>
        <OverAllCard
          title="Comments"
          loading={loadingComments}
          showDivider
          extensible={true}
          dataSize={wordClouds?.seriesData?.length}
        >
          {comments?.length > 0 ? (
            <CommentReviews reviews={comments} dataSize={wordClouds?.seriesData?.length} showRating={false} />
          ) : (
            <CommentPlaceHolder />
          )}
        </OverAllCard>
      </Grid>
    </Box>
  )
}

export default ReportBathroomService
