import React from 'react'
import { SuccessForm } from '@activate-inc/activate-ui'
import RatingFormWrapper from './RatingFormWrapper'
import RatingListWrapper from './RatingListWrapper'
import { QRCodeFunctionTypeName } from '@constant/app'
import { QRCodeFunctionType } from '@/types'

const FeedbackWrapper = (props: any) => {
  const linkIndex = props.linkIndex || 0
  const { text, subText } = QRCodeFunctionTypeName[QRCodeFunctionType.Feedback] || {}

  return (
    <React.Fragment>
      {linkIndex === 0 && <RatingFormWrapper {...props} name={''} />}
      {linkIndex === 1 && <RatingListWrapper {...props} />}
      {linkIndex === 2 && <SuccessForm {...props} text={text} subText={subText} />}
    </React.Fragment>
  )
}

export default FeedbackWrapper
