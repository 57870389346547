import React, { useState, useEffect, useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AgentForm, Template } from '@activate-inc/activate-ui'
import { AgentFormProps } from '@activate-inc/activate-ui/dist/types/components/Form/AgentForm'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { EntityITF } from '../../types/entity'
import { FormDataContext } from '@/context/FormDataContext'
import { QRCodeFunctionType } from '@/types'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))

export interface FrontDeskProps {
  data: AgentFormProps
  entity: EntityITF
  language: LanguageType
  isHubPanel?: boolean
  selectedPropertyName: string
}

const FrontDeskWrapper: React.FC<FrontDeskProps> = ({ data: template, entity, isHubPanel, selectedPropertyName }) => {
  const classes = useStyles()
  const { state } = useContext(FormDataContext)
  const contextTemplate = state?.[QRCodeFunctionType.FrontDesk]
  const showTemplate = isHubPanel ? template : contextTemplate
  const {
    PhoneNumber = '',
    Email = '',
    Name = '',
    AvatarUrl: AvatarFileName = '',
    SendMessageEnable = true,
    PhoneCallEnable = true,
    SendEmailEnable = true,
  } = template || {}
  const [agent, setAgent] = useState<AgentFormProps>()

  const S3_URL = process.env.REACT_APP_S3_BUCKET_URL

  useEffect(() => {
    if (!entity) return
    let companyLogo = entity?.LogoFilename
    if (companyLogo && !companyLogo.startsWith('https://')) {
      companyLogo = S3_URL + '/group/' + (template as unknown as Template)?.GroupId + '/' + companyLogo
    }

    setAgent({
      Email: Email,
      Name: typeof Name === 'string' ? { en: Name, es: Name } : Name,
      PhoneNumber: PhoneNumber,
      AvatarUrl: AvatarFileName,
      pageTitle: selectedPropertyName,
      //@ts-ignore
      CompanyLogo: companyLogo,
      Address: entity?.AddressLine1,
      SendMessageEnable,
      PhoneCallEnable,
      SendEmailEnable,
    })
  }, [showTemplate, entity])

  return showTemplate && agent ? (
    <Box display="flex" sx={{ justifyContent: 'center' }}>
      <Box className={classes.container}>{<AgentForm {...agent}></AgentForm>}</Box>
    </Box>
  ) : (
    <Typography align="center">Please select one template</Typography>
  )
}

export default FrontDeskWrapper
