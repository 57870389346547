import React, { useCallback } from 'react'
import { DragDropContext, Droppable, DropResult, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { reorder } from '@/utils/helpers'
import { makeStyles } from '@mui/styles'
import { Box, TextField, Button, IconButton } from '@mui/material'
import { DeleteOutline, AddCircle, Reorder } from '@mui/icons-material'

const useStyles = makeStyles(() => {
  return {
    item: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #D5D5D5',
    },
    itemText: {
      flex: 1,
    },
    date: {
      margin: '20px 0 0',
    },
    disabled: {
      fontSize: 12,
      color: '#777',
      lineHeight: '21px',
    },
    add: {
      justifyContent: 'flex-start',
      color: '#386BBF',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    underline: {
      '&:before': {
        borderBottom: '0 !important',
      },
      '&:after': {
        borderBottom: '0 !important',
      },
    },
    btn: {
      alignSelf: 'self-start',
      marginTop: 10,
      color: '#386BBF',
    },
  }
})

const language = 'en'

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

export interface FieldsProps {
  Code?: string
  Label?: string
  Text?: { en: string; es: string }
  SubChoice: boolean
  Value: number
  FieldText?: { en: string; es: string }
}

interface StaticFormProps {
  Fields: Array<FieldsProps>
  ChoicesText?: string
  draggableIdKey?: string
  changeFields?: (field: any) => void
  showAdd?: boolean
  disabledEdit?: boolean
}

const DragAndDropList: React.FC<StaticFormProps> = ({
  Fields = [],
  ChoicesText = 'Text',
  draggableIdKey = 'Label',
  changeFields,
  showAdd = false,
  disabledEdit = false,
}) => {
  const classes = useStyles()

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Fields || [], source.index, destination.index)
    changeFields && changeFields(newItems)
  }

  const onRemove = useCallback(
    (index: number) => {
      const temporaryFields = JSON.parse(JSON.stringify(Fields))
      temporaryFields.splice(index, 1)
      changeFields && changeFields(temporaryFields)
    },
    [Fields]
  )

  const onItemChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
      const { value, name } = event.target
      // @ts-ignore
      Fields[index][ChoicesText] = { en: value, es: value }
      changeFields && changeFields(Fields)
    },
    [Fields]
  )

  const onAdd = useCallback(() => {
    const criteria = Fields || []
    const item = { ...Fields[0] }
    item.SubChoice = false
    item.Value = 0
    item.Text = { en: '', es: '' }
    let a_z = ''
    for (let i = 65; i < 91; i++) {
      a_z += String.fromCharCode(i) + ','
    }
    const createOnly: any = (arr: Array<string>) => {
      const temporaryLabel = new Array(3)
        .fill('')
        .map(() => a_z.split(',')[Math.floor(Math.random() * 26)])
        .join('')
      if (arr.findIndex((i) => i === temporaryLabel) !== -1) return createOnly(arr)
      return temporaryLabel
    }
    const label = createOnly(criteria.map((i) => i.Label))
    item.Label = label
    criteria.push(item as FieldsProps)
    changeFields && changeFields(criteria)
  }, [Fields])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {Fields.map((item, index: number) => (
              <Draggable key={item.Label} draggableId={item.Label || ''} index={index} isDragDisabled={disabledEdit}>
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                  >
                    <Box className={classes.item}>
                      <TextField
                        className={classes.itemText}
                        label={''}
                        multiline
                        disabled={disabledEdit}
                        value={item?.[ChoicesText]?.[language] || ''}
                        InputProps={{
                          classes: {
                            underline: classes.underline,
                          },
                        }}
                        InputLabelProps={{ shrink: false }}
                        onChange={(e) => onItemChange(e, index)}
                        variant="standard"
                        placeholder="Enter here"
                      />
                      <IconButton disableRipple onClick={() => onRemove(index)} disabled={disabledEdit}>
                        <DeleteOutline />
                      </IconButton>
                      <div {...draggableProvided.dragHandleProps}>
                        <IconButton disabled={disabledEdit}>
                          <Reorder />
                        </IconButton>
                      </div>
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {showAdd ? (
        <Button className={classes.btn} onClick={onAdd} disabled={disabledEdit}>
          <AddCircle color="primary" style={{ marginRight: 10, fontSize: 32 }} />
          Add New
        </Button>
      ) : null}
    </DragDropContext>
  )
}
export default DragAndDropList
