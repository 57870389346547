export enum ReportTypeEnum {
  Vacancy = 'Vacancy',
  EVRequest = 'EVRequest',
  Support = 'Support',
  LeadCapture = 'LeadCapture',
  FieldMarketing = 'FieldMarketing',
  Maintenance = 'Maintenance',
  BathroomService = 'BathroomService',
  Feedback = 'Feedback',
  RoomRental = 'RoomRental',
}

export const REPORT_TYPE = [
  { id: ReportTypeEnum.Vacancy, name: 'Vacancy-Lead' },
  { id: ReportTypeEnum.EVRequest, name: 'EV Request Report' },
  { id: ReportTypeEnum.Support, name: 'QR Support Report' },
  { id: ReportTypeEnum.Maintenance, name: 'Maintenance Report' },
  { id: ReportTypeEnum.LeadCapture, name: 'Lead Capture' },
  { id: ReportTypeEnum.FieldMarketing, name: 'Field Marketing' },
  { id: ReportTypeEnum.BathroomService, name: 'Bathroom Service' },
  { id: ReportTypeEnum.Feedback, name: 'Feedback' },
  { id: ReportTypeEnum.RoomRental, name: 'Room Rental' },
].sort((a, b) => a.name.localeCompare(b.name))
