import React, { useReducer, useContext, createContext } from 'react'
import { QRCodeFunctionType } from '../types'

const actions = {
  CHANGE_MAP: 'CHANGE_MAP',
  CHANGE_JOINWIFI: 'CHANGE_JOINWIFI',
  CHANGE_SOCIAL_MEDIA: 'CHANGE_SOCIAL_MEDIA',
  CHANGE_FRONT_DESK: 'CHANGE_FRONT_DESK',
  CHANGE_HTML: 'CHANGE_HTML',
  CHANGE_PORTFOLIO: 'CHANGE_PORTFOLIO',
}

export interface FormDataValues {
  [QRCodeFunctionType.GoogleMap]: any
  [QRCodeFunctionType.JoinWifi]: any
  [QRCodeFunctionType.Static]: any
  [QRCodeFunctionType.FrontDesk]: any
  [QRCodeFunctionType.Html]: any
  [QRCodeFunctionType.Portfolio]: any
}

const defaultValues = {
  [QRCodeFunctionType.GoogleMap]: {
    DestinationType: '',
    Latitude: '',
    Longitude: '',
  },
  [QRCodeFunctionType.JoinWifi]: {
    SsId: '',
    Password: '',
    NetworkType: '',
  },
  [QRCodeFunctionType.SocialMedia]: {
    facebook: '',
    linkedin: '',
    twitter: '',
    youTube: '',
    instagram: '',
    tikTok: '',
  },
  [QRCodeFunctionType.FrontDesk]: {
    PhoneNumber: '',
    Email: '',
    Name: '',
    AvatarUrl: '',
  },
  [QRCodeFunctionType.Html]: {
    Title: 'HTML Title',
    HtmlContent: '<div>test</div>',
    RawContent: '',
  },
  [QRCodeFunctionType.Portfolio]: {
    Items: [],
  },
}

export const FormDataContext = createContext<any>(defaultValues)

export const formDataReducer = (state, action) => {
  switch (action.type) {
    case actions.CHANGE_MAP:
      return { ...state, [QRCodeFunctionType.GoogleMap]: action.data }
    case actions.CHANGE_JOINWIFI:
      return { ...state, [QRCodeFunctionType.JoinWifi]: action.data }
    case actions.CHANGE_SOCIAL_MEDIA:
      return { ...state, [QRCodeFunctionType.SocialMedia]: action.data }
    case actions.CHANGE_FRONT_DESK:
      return { ...state, [QRCodeFunctionType.FrontDesk]: action.data }
    case actions.CHANGE_HTML:
      return { ...state, [QRCodeFunctionType.Html]: action.data }
    case actions.CHANGE_PORTFOLIO:
      return { ...state, [QRCodeFunctionType.Portfolio]: action.data }
    default:
      return state
  }
}

export const FormDataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formDataReducer, defaultValues)
  const nvalue = {
    state,
    htmlData: state[QRCodeFunctionType.Html],
    mapData: state[QRCodeFunctionType.GoogleMap],
    wifiData: state[QRCodeFunctionType.JoinWifi],
    frontDeskData: state[QRCodeFunctionType.FrontDesk],
    portfolioData: state[QRCodeFunctionType.Portfolio],
    updateJoinWifi: (data) => {
      dispatch({ type: actions.CHANGE_JOINWIFI, data })
    },
    updateMapData: (data) => {
      dispatch({ type: actions.CHANGE_MAP, data })
    },
    updateSocialData: (data) => {
      dispatch({ type: actions.CHANGE_SOCIAL_MEDIA, data })
    },
    updateFrontDeskData: (data) => {
      dispatch({ type: actions.CHANGE_FRONT_DESK, data })
    },
    updateHtml: (data) => {
      dispatch({ type: actions.CHANGE_HTML, data })
    },
    updatePortfolio: (data) => {
      dispatch({ type: actions.CHANGE_PORTFOLIO, data })
    },
  }
  return <FormDataContext.Provider value={nvalue}>{children}</FormDataContext.Provider>
}
