import React, { useEffect, useState } from 'react'
import { FormControl, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { COLOR } from '@constant/app'

const useStyles = makeStyles(() => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
  },
  label: {
    fontSize: 16,
    color: COLOR.black11,
    width: 110,
  },
}))

interface PanelTextFieldProps {
  label?: string
  onChange: (value: string) => void
  defaultValue?: string
  disabled?: boolean
}

const PanelTextField: React.FC<PanelTextFieldProps> = ({ label, defaultValue = '', onChange, disabled }) => {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue)
  const onChangeItem = (event) => {
    setValue(value)
    onChange?.(event.target.value)
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <FormControl classes={{ root: classes.formControl }}>
      <TextField variant="outlined" disabled={disabled} onChange={onChangeItem} value={value} label={label} fullWidth />
    </FormControl>
  )
}

export default PanelTextField
