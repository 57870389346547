import { useEffect, useState } from 'react'
import { PUSHER_EVENT } from '@constant/app'
import Config from '@constant/config'
interface PusherEventITF {
  time: number
  data: string
}
export const useEvent = (eventName: PUSHER_EVENT) => {
  const [event, setEvent] = useState<PusherEventITF>()
  useEffect(() => {
    const eventSource = new EventSource(Config.esbHost + eventName)
    eventSource.onopen = () => {
      console.log('onopen')
    }

    eventSource.onmessage = (event) => {
      if (event.data === 'Connection established') return
      console.log('onmessage', event)
      setEvent({ time: Date.now(), data: event.data as string })
    }

    eventSource.onerror = () => {}
    return () => {
      eventSource.close()
    }
  }, [eventName])

  return { event }
}
