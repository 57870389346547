import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Box, Button, Checkbox, TextField, Collapse, IconButton } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AddCircle, DragHandle } from '@mui/icons-material'
import { ErrorRuleOption, QuestionType } from '@constant/qrcode'
import classnames from 'classnames'
import useStyles from './styles'
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd'
import { getItemStyle } from './EquipmentTable'
const questionType = QuestionType //.filter((v) => !['Date'].includes(v.Name))

const configQuestion = [
  { name: 'QuestionType', title: 'Question Type*', isObj: true, option: questionType, width: 180 },
  { name: 'Question', title: 'Question*', width: 300 },
  { name: 'Required', title: 'Required', isBoolean: true, width: 100 },
  { name: 'PhotosEnable', title: 'Photo/Video', isBoolean: true, width: 100 },
  { name: 'AnswerOption1', title: 'Answer Option 1' },
  { name: 'AnswerOption2', title: 'Answer Option 2' },
  { name: 'AnswerOption3', title: 'Answer Option 3' },
  { name: 'AnswerOption4', title: 'Answer Option 4' },
  { name: 'AnswerOption5', title: 'Answer Option 5' },
  { name: 'AnswerOption6', title: 'Answer Option 6' },
  { name: 'AnswerOption7', title: 'Answer Option 7' },
  { name: 'AnswerOption8', title: 'Answer Option 8' },
  { name: 'AnswerOption9', title: 'Answer Option 9' },
  { name: 'AnswerOption10', title: 'Answer Option 10' },
  { name: 'CondQuestion', title: 'Conditional Questions ', option: [], width: 300 },
  { name: 'CondValue', title: 'Conditional Answer ', option: [] },
  { name: 'ErrorReporting', title: 'Error Reporting', isBoolean: true, width: 150 },
  { name: 'ErrorRule', title: 'Error Rule', option: ErrorRuleOption, isObj: true },
  { name: 'ErrorValue', title: 'Error Value' },
  { name: 'ToleranceMin', title: 'Tolerance min.' },
  { name: 'ToleranceMax', title: 'Tolerance max.' },
]

export const QuestionErrMsg = configQuestion
  .filter((v) => v.title.includes('*'))
  .map((item) => item.title)
  .join(', ')

export const TEMPLATE: Record<string, any> = {
  QuestionType: '',
  Question: '',
  Required: false,
  AnswerOption1: '',
  AnswerOption2: '',
  AnswerOption3: '',
  AnswerOption4: '',
  AnswerOption5: '',
  AnswerOption6: '',
  AnswerOption7: '',
  AnswerOption8: '',
  AnswerOption9: '',
  AnswerOption10: '',
  ErrorReporting: false,
  CondQuestion: '',
  CondValue: '',
  ErrorRule: '',
  ErrorValue: '',
  ToleranceMin: '',
  ToleranceMax: '',
  Code: '',
}

interface Props {
  FormTemplateFields: any[]
  open: boolean
  changeRef: React.MutableRefObject<any>
  equIdx: number
  colSpan: number
  PhotoRequired: boolean
  action: boolean
  setAction: React.Dispatch<React.SetStateAction<boolean>>
}

const QuestionTable = React.memo((props: Props) => {
  const { FormTemplateFields, open, changeRef, equIdx, setAction, PhotoRequired, colSpan } = props
  const classes = useStyles()
  const [, setRender] = useState(false)

  const onAdd = () => {
    FormTemplateFields.splice(-1, 0, {
      ...JSON.parse(JSON.stringify(TEMPLATE)),
      Code: String(Date.now()),
    })
    changeRef.current.isChanges = true
    setRender((v) => !v)
  }

  const booleanChange = (idx: number, name: string, value: boolean) => {
    FormTemplateFields[idx][name] = value
    changeRef.current.isChanges = true
    setRender((v) => !v)
    if (name === 'checked') {
      setAction((v) => !v)
    }
  }

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {}>,
      idx: number,
      type?: string,
      option?: any
    ) => {
      const { name = type, value: newValue } = event.target as any
      const rowValue = option || newValue
      // @ts-ignore
      const value = typeof rowValue === 'string' ? rowValue : rowValue.Name

      const pre = FormTemplateFields[idx]
      const { QuestionType } = pre
      if (['ToleranceMin', 'ToleranceMax'].includes(name) && !/^[0-9]*$/.test(value)) {
        return pre
      }
      if (QuestionType === 'Number' && name === 'ErrorValue' && !/^[0-9]*$/.test(value)) {
        return pre
      }
      if (name === 'QuestionType' && QuestionType) {
        pre.AnswerOption1 = ''
        pre.AnswerOption2 = ''
        pre.AnswerOption3 = ''
        pre.AnswerOption4 = ''
        pre.AnswerOption5 = ''
        pre.AnswerOption6 = ''
        pre.AnswerOption7 = ''
        pre.AnswerOption8 = ''
        pre.AnswerOption9 = ''
        pre.AnswerOption10 = ''
        pre.ErrorReporting = false
        pre.ErrorRule = ''
        pre.ErrorValue = ''
        pre.ToleranceMin = ''
        pre.ToleranceMax = ''
        pre.CondQuestion = ''
        pre.CondValue = ''
      }
      if (name === 'QuestionType' && value === 'BoolSingleChoice') {
        pre.AnswerOption1 = 'Yes'
        pre.AnswerOption2 = 'No'
      }
      if (name === 'QuestionType' && value === 'BoolOnOff') {
        pre.Question = 'Is the power on or off?'
        pre.AnswerOption1 = 'ON'
        pre.AnswerOption2 = 'OFF'
      }
      pre[name] = value
      changeRef.current.isChanges = true
      setRender((v) => !v)
    },
    [FormTemplateFields]
  )

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const [removed] = FormTemplateFields.splice(source.index, 1)
    FormTemplateFields.splice(destination.index, 0, removed)
    setRender((v) => !v)
  }

  useEffect(() => {
    const idx = FormTemplateFields.findIndex((v) => v.QuestionType === 'TakePhoto')
    if (idx === -1) {
      FormTemplateFields.push({
        ...JSON.parse(JSON.stringify(TEMPLATE)),
        QuestionType: 'TakePhoto',
        Question: 'Tap to Take Photo',
        Code: String(Date.now()),
      })
      setRender((v) => !v)
    }
  }, [])

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1} className={classnames(classes.borderLeft)}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="QuestionTable">
                {(provided) => (
                  <Table
                    className={classnames({ [classes.table]: true })}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <colgroup>
                      <col width={180} />
                      {configQuestion.map((v, i) => (
                        <col key={i} width={v.width || 182} />
                      ))}
                    </colgroup>

                    <TableHead className={classnames(classes.tableHead, classes.questionStickyHeader)}>
                      <TableCell key="check" classes={{ root: classes.cell }} />
                      {configQuestion.map((v) => (
                        <TableCell key={v.title} classes={{ root: classes.cell }}>
                          {v.title}
                        </TableCell>
                      ))}
                    </TableHead>

                    <TableBody>
                      {FormTemplateFields.map((data, idx) => {
                        const { checked = false, QuestionType, Readonly } = data
                        canSave(data, `${equIdx}-${idx + 1}`)
                        const isTakePhoto = QuestionType === 'TakePhoto'
                        const key = data?.addTime || idx
                        return (
                          <Draggable key={key} draggableId={String(key)} index={idx} isDragDisabled={isTakePhoto}>
                            {(draggableProvided, draggableSnapshot) => (
                              <TableRow
                                key={key}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                //@ts-ignore
                                style={getItemStyle(
                                  draggableSnapshot.isDragging,
                                  draggableProvided.draggableProps.style
                                )}
                                className={classnames(classes.tableRow, { [classes.checked]: checked })}
                              >
                                <TableCell>
                                  <Box className={classes.firstCell}>
                                    <Box sx={{ minWidth: 100 }}>Question {idx + 1}</Box>
                                    <Checkbox
                                      checked={!!checked}
                                      disabled={isTakePhoto || !!Readonly}
                                      onChange={() => booleanChange(idx, 'checked', !checked)}
                                      color="primary"
                                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    <div {...draggableProvided.dragHandleProps}>
                                      <IconButton size="small">
                                        <DragHandle className={classes.color} fontSize="medium" />
                                      </IconButton>
                                    </div>
                                  </Box>
                                </TableCell>

                                {configQuestion.map((v, j) => {
                                  const { name, isObj = false, isBoolean } = v
                                  const { disabled, options } = configOption({
                                    dataItem: data,
                                    configItem: v,
                                    PhotoRequired,
                                    FormTemplateFields,
                                    itemIndex: idx,
                                  })
                                  // @ts-ignore
                                  let value = isObj ? options.find((item) => item.Name === data[name]) : data[name]
                                  value = typeof value === 'number' ? value.toString() : value
                                  if (isTakePhoto && name === 'Required') {
                                    value = PhotoRequired
                                  }

                                  if (isBoolean) {
                                    return (
                                      <TableCell key={j}>
                                        <Checkbox
                                          checked={value}
                                          onChange={() => booleanChange(idx, name, !value)}
                                          color="primary"
                                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                                          disabled={disabled || isTakePhoto}
                                        />
                                      </TableCell>
                                    )
                                  }

                                  if (options.length > 0) {
                                    return (
                                      <TableCell key={j}>
                                        <Autocomplete
                                          disableClearable
                                          disabled={disabled}
                                          onChange={(e, value) => handleChange(e, idx, name, value)}
                                          className={classes.textField}
                                          options={options}
                                          value={value || ''}
                                          getOptionLabel={(option: any) => option?.DisplayText?.en || option}
                                          classes={{
                                            inputRoot: classes.autocomplete,
                                            popper: classes.option,
                                            listbox: classes.listbox,
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name={name}
                                              variant="outlined"
                                              InputProps={{
                                                ...params.InputProps,
                                                classes: { disabled: classes.disabled },
                                              }}
                                            />
                                          )}
                                        />
                                      </TableCell>
                                    )
                                  }
                                  return (
                                    <TableCell key={j}>
                                      <TextField
                                        name={name}
                                        value={value || ''}
                                        variant="outlined"
                                        disabled={disabled}
                                        className={classes.textField}
                                        SelectProps={{
                                          displayEmpty: true,
                                          classes: {
                                            select: classes.select,
                                          },
                                          type: 'search',
                                          MenuProps: {
                                            style: {
                                              maxHeight: 300,
                                            },
                                          },
                                        }}
                                        InputProps={{
                                          classes: {
                                            root: classes.cellInputRoot,
                                            input: classes.cellInput,
                                            disabled: classes.disabled,
                                          },
                                        }}
                                        onChange={(e) => handleChange(e, idx, '', '')}
                                      ></TextField>
                                    </TableCell>
                                  )
                                })}
                              </TableRow>
                            )}
                          </Draggable>
                        )
                      })}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Button className={classes.add} onClick={onAdd}>
            <AddCircle style={{ marginRight: 8 }} />
            Add New Questions
          </Button>
        </Collapse>
      </TableCell>
    </TableRow>
  )
})

QuestionTable.displayName = 'QuestionTable'
export default QuestionTable

interface ConfigOptionParams {
  dataItem: any
  configItem: any
  PhotoRequired?: boolean
  FormTemplateFields?: any[]
  itemIndex?: number
}
export function configOption(params: ConfigOptionParams) {
  const { dataItem, configItem, PhotoRequired, FormTemplateFields = [], itemIndex = 0 } = params
  const { QuestionType, ErrorReporting, ErrorRule, CondQuestion, Readonly } = dataItem
  const { name, option = [] } = configItem
  let options = option
  let disabled = Readonly || false

  switch (name) {
    case 'QuestionType': {
      if (QuestionType === 'TakePhoto') {
        disabled = true
        dataItem.Required = PhotoRequired
      } else {
        options = options.filter((v) => v.Name !== 'TakePhoto')
      }
      break
    }
    case 'Question':
    case 'Required': {
      if (QuestionType === 'TakePhoto') {
        disabled = true
      }
      break
    }
    case 'ErrorReporting': {
      if (['CheckBox', 'Text', 'TakePhoto', 'Date'].includes(QuestionType)) {
        disabled = true
      }
      break
    }
    case 'CondQuestion': {
      options = []
      for (let i = 0; i < itemIndex; i++) {
        const { QuestionType, Question } = FormTemplateFields[i]
        if (['BoolSingleChoice', 'BoolOnOff'].includes(QuestionType)) {
          options.push(Question)
        }
      }
      disabled = options.length === 0
      break
    }
    case 'CondValue': {
      if (!CondQuestion) {
        disabled = true
      } else {
        const dependOnItem = FormTemplateFields.find((v) => v.Question === CondQuestion)
        options = [
          dependOnItem?.AnswerOption1,
          dependOnItem?.AnswerOption2,
          dependOnItem?.AnswerOption3,
          dependOnItem?.AnswerOption4,
          dependOnItem?.AnswerOption5,
          dependOnItem?.AnswerOption6,
          dependOnItem?.AnswerOption7,
          dependOnItem?.AnswerOption8,
          dependOnItem?.AnswerOption9,
          dependOnItem?.AnswerOption10,
        ].filter((v) => !!v)
      }
      break
    }
    case 'ErrorValue': {
      if (QuestionType === 'Number' && ['OutsideRange', 'InsideRange'].includes(ErrorRule)) {
        disabled = true
      }
      break
    }
    case 'ToleranceMin':
    case 'ToleranceMax': {
      disabled = true
      if (['OutsideRange', 'InsideRange'].includes(ErrorRule)) {
        disabled = false
      }
      break
    }
    default:
      break
  }

  if (
    ['BooleanChoice', 'Number', 'Text', 'TakePhoto', 'BoolSingleChoice', 'Date', 'BoolOnOff'].includes(QuestionType) &&
    name.includes('AnswerOption')
  ) {
    disabled = true
  }

  if (!ErrorReporting && ['ErrorRule', 'ErrorValue', 'ToleranceMin', 'ToleranceMax'].includes(name)) {
    disabled = true
  }

  if (ErrorReporting && QuestionType === 'BooleanChoice') {
    if (name === 'ErrorRule') {
      options = options.filter((v) => v.Name === 'IsEqualTo')
    }
    if (name === 'ErrorValue') {
      // @ts-ignore
      options = ['TRUE', 'FALSE']
    }
  }
  if (ErrorReporting && ['SingleChoice', 'DropDown'].includes(QuestionType)) {
    if (name === 'ErrorRule') {
      options = options.filter((v) => ['IsEqualTo', 'IsNotEqualTo'].includes(v.Name))
    }
    if (name === 'ErrorValue') {
      // @ts-ignore
      options = [
        dataItem?.AnswerOption1,
        dataItem?.AnswerOption2,
        dataItem?.AnswerOption3,
        dataItem?.AnswerOption4,
        dataItem?.AnswerOption5,
        dataItem?.AnswerOption6,
        dataItem?.AnswerOption7,
        dataItem?.AnswerOption8,
        dataItem?.AnswerOption9,
        dataItem?.AnswerOption10,
      ].filter((v) => !!v)
    }
  }
  if (ErrorReporting && QuestionType === 'BoolSingleChoice') {
    if (name === 'ErrorRule') {
      options = options.filter((v) => v.Name === 'IsEqualTo')
    }
    if (name === 'ErrorValue') {
      // @ts-ignore
      options = ['Yes', 'No']
    }
  }

  if (ErrorReporting && QuestionType === 'BoolOnOff') {
    if (name === 'ErrorRule') {
      options = options.filter((v) => v.Name === 'IsEqualTo')
    }
    if (name === 'ErrorValue') {
      // @ts-ignore
      options = ['ON', 'OFF']
    }
  }

  return { disabled, options }
}

export function canSave(data: any, serialNo: string) {
  const { QuestionType, Question, AnswerOption1, ErrorReporting, CondQuestion, CondValue } = data

  if ([QuestionType, Question].some((v) => ['', undefined].includes(v))) {
    data.errMsg = `${serialNo} ${QuestionErrMsg} are Required`
    return (data.canSave = false)
  }

  if (['CheckBox', 'SingleChoice', 'DropDown'].includes(QuestionType) && !AnswerOption1) {
    data.errMsg = `${serialNo} AnswerOption1 is Required`
    return (data.canSave = false)
  }

  if (ErrorReporting) {
    const { ErrorRule, ErrorValue, ToleranceMin, ToleranceMax } = data
    if (!ErrorRule) {
      data.errMsg = `${serialNo} Error Rule is Required`
      return (data.canSave = false)
    }
    if (CondQuestion && !CondValue) {
      data.errMsg = `${serialNo} Conditional Answer is Required`
      return (data.canSave = false)
    }
    if (!['InsideRange', 'OutsideRange'].includes(ErrorRule) && !ErrorValue) {
      data.errMsg = `${serialNo} Error Value is Required`
      return (data.canSave = false)
    }
    if (['InsideRange', 'OutsideRange'].includes(ErrorRule) && !(ToleranceMax && ToleranceMin)) {
      data.errMsg = `${serialNo} Tolerance min.	Tolerance max. are Required`
      return (data.canSave = false)
    }
    if (Number(ToleranceMax) < Number(ToleranceMin)) {
      data.errMsg = `${serialNo} Tolerance max should be greater than Tolerance min`
      return (data.canSave = false)
    }
  }

  return (data.canSave = true)
}
