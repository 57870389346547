import React, { useEffect, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Box, Typography, Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material'
import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import { uploadBase64, generateQrStyle } from '@api'
import { toBase64 } from '@/utils'
import { EYE_FRAME_SHAPE, EYE_BALL_SHAPE, BODY_SHAPE_MAP } from '@/qrcodes/QRCodeConfig'
import Images from '@/assets/images'
import EditAndSave from '@component/Button/EditAndSave'

const S3_Bucket = process.env.REACT_APP_S3_BUCKET
export const BRANDING_BASE_NAME = {
  LogoFilename: '',
  FaviconFilename: '',
  BackgroundColor: '#FFF',
  FillColor: '#000000',
  BodyShape: 'circle',
  EyeFrame: 'frame2',
  EyeBall: 'ball0',
  EyeColor: '#000000',
}
const BRANDING_NAME = {
  ...BRANDING_BASE_NAME,
  Source: 'Property',
  QRStyleUrl: '',
}
type StateKey = keyof typeof BRANDING_NAME
const SHAPE_CONFIG = [
  { label: 'Body Shape', key: 'BodyShape', option: BODY_SHAPE_MAP },
  { label: 'Eye Frame', key: 'EyeFrame', option: EYE_FRAME_SHAPE },
  { label: 'Eye Ball Shape', key: 'EyeBall', option: EYE_BALL_SHAPE },
]

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
      position: 'relative',
    },
    color: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    label: {
      width: 200,
    },
    preview: {
      marginLeft: 20,
    },
    previewButton: {
      width: 161,
      textAlign: 'center',
      color: '#386BBF',
      marginTop: 24,
      textTransform: 'none',
    },
    emptyImg: {
      width: 161,
      height: 161,
      textAlign: 'center',
      lineHeight: '161px',
    },
    upload: {
      backgroundColor: '#386BBF',
      padding: '8px 16px',
      color: '#fff',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      margin: '16px 0',
    },
    img: {
      border: '1px solid #BCBCBC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 16px',
      borderRadius: 4,
      cursor: 'pointer',
    },
    box: {
      display: 'flex',
      width: '800px',
      flexWrap: 'wrap',
    },
    formControl: {
      flex: 1,
      minWidth: '40%',
      marginBottom: 24,
      '&:nth-child(2n-1)': {
        marginRight: '24px',
      },
    },
    select: {
      padding: '12px 16px 4px',
    },
  }
})

interface Props {
  gId: string
  informationRef: React.MutableRefObject<any>
  disabled?: boolean
  isEdit?: boolean
  editData?: any
  onSave: () => void
  onBack?: () => void
  onChangeData?: (state: any) => void
  hiddenPreview?: boolean
  hiddenCancel?: boolean
  showSave?: boolean
}

const PropertyBranding = (props: Props) => {
  const {
    gId,
    informationRef,
    isEdit,
    editData,
    onSave,
    onBack,
    disabled,
    hiddenPreview,
    onChangeData,
    hiddenCancel,
    showSave,
  } = props
  const classes = useStyles()
  const cacheState = useRef<any>({ ...BRANDING_NAME })
  const [state, setState] = useSetState({ ...BRANDING_NAME })
  const [onlyView, setOnlyView] = useState(isEdit && !showSave)

  const getData = useCallback(async () => {
    return cacheState.current
  }, [])
  const { LogoFilename, FaviconFilename, QRStyleUrl } = state
  const handleQRLogoChange = async (event: any, type: string) => {
    const images = event.target.files as File[]
    if (images[0]) {
      const base64Image = await toBase64(images[0])
      const s3Image: any = await uploadBase64({
        base64Image,
        fileName: `${type}/${gId}/${images[0].name}`,
        bucket: S3_Bucket,
      })
      // @ts-ignore
      setState({ [type]: s3Image.imageUrl as string })
    }
  }
  const onCancel = () => {
    if (onBack) {
      onBack()
      return
    }
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const colorChange = (newValue: string, key: string) => {
    if (matchIsValidColor(newValue)) {
      // @ts-ignore
      setState({ [key]: newValue })
    }
  }

  const onPreview = () => {
    generateQrStyle({
      ...state,
      BuildingId: editData?.BuildingId || '',
      GroupId: gId,
    }).then((res: any) => {
      setState({ QRStyleUrl: `${res?.ImageUrl}?${new Date().getTime()}` })
    })
  }

  useImperativeHandle(
    informationRef,
    () => ({
      ...informationRef.current,
      getData,
    }),
    [getData]
  )

  useEffect(() => {
    if (isEdit && editData) {
      console.log('222')
      const state = { ...BRANDING_NAME }
      for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key) && editData[key]) {
          state[key as StateKey] = editData[key]
        }
      }
      setState({ ...state })
    }
  }, [isEdit, editData, BRANDING_NAME])
  useEffect(() => {
    cacheState.current = state
    onChangeData?.(state)
  }, [state, onChangeData])
  return (
    <Box className={`${classes.content}`}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', width: '800px', marginBottom: '24px' }}>
            <Box sx={{ flex: 1, marginRight: '24px' }}>
              <Typography className={classes.label}>Header Logo:</Typography>
              <input
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
                id={'HeaderLogo'}
                onChange={(e) => handleQRLogoChange(e, 'LogoFilename')}
                type="file"
                disabled={disabled || onlyView}
              />
              <label htmlFor="HeaderLogo" className={classes.upload}>
                Upload
                <FileUploadOutlinedIcon sx={{ marginLeft: '8px' }} />
              </label>
              {LogoFilename && (
                <Box className={classes.img}>
                  <img src={LogoFilename} alt="HeaderLogo" height={33} />
                  {!disabled && !onlyView && (
                    <img src={Images.iconDelete} alt="delete" onClick={() => setState({ LogoFilename: '' })} />
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography className={classes.label}>QR Logo:</Typography>
              <input
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
                id={'QRLogo'}
                onChange={(e) => handleQRLogoChange(e, 'FaviconFilename')}
                type="file"
                disabled={disabled || onlyView}
              />
              <label htmlFor="QRLogo" className={classes.upload}>
                Upload
                <FileUploadOutlinedIcon sx={{ marginLeft: '8px' }} />
              </label>
              {FaviconFilename && (
                <Box className={classes.img}>
                  <img src={FaviconFilename} alt="QRLogo" height={33} />
                  {!disabled && !onlyView && (
                    <img src={Images.iconDelete} alt="delete" onClick={() => setState({ FaviconFilename: '' })} />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.box}>
            <MuiColorInput
              value={state.BackgroundColor}
              format="hex"
              onChange={(newValue) => colorChange(newValue, 'BackgroundColor')}
              label="Background Color"
              fallbackValue={BRANDING_NAME.BackgroundColor}
              isAlphaHidden
              className={classes.formControl}
              disabled={disabled || onlyView}
            />
            <MuiColorInput
              value={state.FillColor}
              format="hex"
              onChange={(newValue) => colorChange(newValue, 'FillColor')}
              label="Body Fill"
              fallbackValue={BRANDING_NAME.FillColor}
              isAlphaHidden
              className={classes.formControl}
              disabled={disabled || onlyView}
            />
            {SHAPE_CONFIG.map((v, i) => {
              const { label, key, option } = v
              return (
                <FormControl className={classes.formControl} key={i}>
                  <InputLabel>{label}</InputLabel>
                  <Select
                    // @ts-ignore
                    onChange={(e) => setState({ [key]: e.target.value as string })}
                    value={state[key as StateKey]}
                    label={label}
                    fullWidth
                    classes={{ select: classes.select }}
                    disabled={disabled || onlyView}
                  >
                    {option.map((name) => {
                      return (
                        <MenuItem key={name} value={name}>
                          <img src={require(`../assets/images/qrcodestyle/${name}.png`)} alt={name} height={33} />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )
            })}
            <MuiColorInput
              value={state.EyeColor}
              format="hex"
              onChange={(newValue) => colorChange(newValue, 'EyeColor')}
              label="Eye Color"
              fallbackValue={BRANDING_NAME.EyeColor}
              isAlphaHidden
              className={classes.formControl}
              disabled={disabled || onlyView}
            />
          </Box>
        </div>
        {!hiddenPreview && (
          <Box className={classes.preview}>
            <Box>
              {QRStyleUrl ? (
                <img src={QRStyleUrl} width={161} height={161} alt="QRStyleUrl" />
              ) : (
                <Box className={classes.emptyImg}>Not Available</Box>
              )}
            </Box>
            <Button
              variant="outlined"
              color="primary"
              className={classes.previewButton}
              onClick={onPreview}
              disabled={disabled || onlyView}
            >
              Preview
            </Button>
          </Box>
        )}
      </div>
      <EditAndSave
        showSave={showSave || !onlyView}
        onSave={onSave}
        onCancel={onCancel}
        hiddenCancel={hiddenCancel}
        onEdit={onEdit}
      />
    </Box>
  )
}
export default React.memo(PropertyBranding)
