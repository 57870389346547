import React, { useEffect, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Box, Typography, Button, TextField, InputAdornment } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import { formatCurrency } from '@/utils/common'
import EditAndSave from '@component/Button/EditAndSave'

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
      position: 'relative',
      fontFamily: 'Roboto',
    },
    label: {
      marginBottom: 8,
      color: '#374053',
      fontSize: 16,
    },
    coiTitle: {
      color: '#000',
      fontSize: '20px',
      fontWeight: 600,
      marginBottom: 16,
    },
  }
})

interface Props {
  informationRef: React.MutableRefObject<any>
  disabled?: boolean
  isEdit?: boolean
  editData?: any
  onSave: () => void
  hiddenCancel?: boolean
}

const PropertyCOI = (props: Props) => {
  const { informationRef, isEdit, editData, onSave, disabled, hiddenCancel } = props
  const classes = useStyles()
  const cacheState = useRef<any>({})
  const [state, setState] = useSetState({ Coverage: [] as any[], Additional: [] as string[] })
  const { Coverage, Additional } = state
  const [onlyView, setOnlyView] = useState(isEdit)

  const [focusID, setFocusID] = useState(-1)

  const onCancel = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }

  const getData = useCallback(async () => {
    return cacheState.current
  }, [])

  const onChange = (e, index) => {
    Coverage[index].Limit = e.target.value
    setState({ Coverage: [...Coverage] })
  }

  const onAdditionalChange = (e, index) => {
    Additional[index] = e.target.value
    setState({ Additional: [...Additional] })
  }

  const onAdd = () => {
    setState({ Additional: [...Additional, ''] })
  }

  const onDelete = (index) => {
    Additional.splice(index, 1)
    setState({ Additional: [...Additional] })
  }

  useImperativeHandle(
    informationRef,
    () => ({
      ...informationRef.current,
      getCOIData: getData,
    }),
    [getData]
  )

  useEffect(() => {
    if (isEdit) {
      setState(editData?.COI)
    }
  }, [isEdit, editData])

  useEffect(() => {
    cacheState.current = state
  }, [state])

  return (
    <Box className={`${classes.content}`}>
      <Typography className={classes.coiTitle}>COI requirements</Typography>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '24px 32px', marginBottom: 32 }}>
        {Coverage?.map((item, i) => {
          const isFocus = focusID === i
          return (
            <Box key={i}>
              <Typography className={classes.label}>{item?.Text}</Typography>
              <TextField
                value={isFocus ? item?.Limit : formatCurrency(item?.Limit)}
                label=""
                variant="outlined"
                onChange={(e) => onChange(e, i)}
                style={{ width: '100%', flex: 1, backgroundColor: '#F5F5F5' }}
                type={isFocus ? 'number' : 'text'}
                disabled={disabled || onlyView}
                onFocus={(e) => {
                  setFocusID(i)
                  const timer = setTimeout(() => {
                    e.target.select()
                    clearTimeout(timer)
                  })
                }}
                onBlur={() => setFocusID(-1)}
              />
            </Box>
          )
        })}
      </div>

      <Typography className={classes.coiTitle}>Additional Insured Requirements</Typography>
      {Additional?.map((item, i) => {
        return (
          <Box key={i}>
            <TextField
              value={item}
              label=""
              disabled={disabled || onlyView}
              variant="standard"
              onChange={(e) => onAdditionalChange(e, i)}
              style={{ width: '66.66%', height: '42px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={() => onDelete(i)}>
                    <DeleteOutline htmlColor="#1976D2" style={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onFocus={(e) => e.target.select()}
            />
          </Box>
        )
      })}
      <Button variant="outlined" onClick={onAdd} disabled={disabled || onlyView} style={{ marginTop: '16px' }}>
        Add requirement
      </Button>
      {!disabled && (
        <EditAndSave
          showSave={!onlyView}
          onCancel={onCancel}
          onEdit={onEdit}
          onSave={onSave}
          hiddenCancel={hiddenCancel}
        />
      )}
    </Box>
  )
}
export default React.memo(PropertyCOI)
