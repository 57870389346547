import React, { useCallback, useEffect, useState } from 'react'
import { useLocaleState, usePermissions, useRedirect } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button } from '@mui/material'

import { makeStyles } from '@mui/styles'
import CommonForm, { FORM_TYPE } from '@component/Form/CommonForm'
import SnackBar from '@component/SnackBar'
import { getGroup } from '@api'
import { selectedGroup } from '@reducer/profile'
import { GroupITF } from '@/types/group'
import { formatPhoneNumber } from '@/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    padding: 10,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  headerButton: {
    backgroundColor: '#386BBF',
    color: '#fff',
    width: '5%',
    textTransform: 'none',
    fontFamily: 'Roboto, Regular',
    fontWeight: 500,
    border: 'none',
    borderRadius: 4,
    fontSize: 13,
    float: 'right',
    height: 35,
    marginTop: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#386BBF',
      opacity: 0.7,
    },
    ' &.Mui-disabled': {
      color: '#DFC9A3',
      opacity: 0.5,
    },
  },
}))

const mockData = {
  text: '11',
  imgUrl: 'https://www.bitovi.com/hs-fs/hubfs/chicago-banner.png?width=2855&height=588&name=chicago-banner.png',
  data: [
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Name',
      value: 'Sterling Bay',
    },
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Address',
      value: '333 North Green, Suite 1100 Chicago, Illinois 60607',
    },
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Contact No.',
      value: '+1 – (312) 466-4100',
    },
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Email',
      value: 'hello@sterlingbay.com',
    },
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Website',
      value: 'www.sterlingbay.com',
    },
    {
      fromType: FORM_TYPE.section,
      onlyLine: true,
    },
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Primary User Name',
      value: 'Dustin Lauren',
    },
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Email',
      value: 'dustin.lauren@gmail.com',
    },
    {
      fromType: FORM_TYPE.textFiled,
      disabled: true,
      label: 'Phone Number',
      value: '',
    },
    // {
    //   fromType: FORM_TYPE.section,
    //   title: 'Import Properties'
    // }, {
    //   fromType: FORM_TYPE.upload,
    //   disabled: true,
    //   fileName: 'Website',
    // }
  ],
}

const groupKey = [
  'GroupName',
  'AddressLine1',
  'ContactPhoneNumber',
  'ContactEmail',
  'Website',
  '',
  'PrimaryUserDisplayName',
  'PrimaryUserEmail',
  'PrimaryUserPhoneNumber',
]

const covertData = (groupDetail: any, locale: string) => {
  groupKey.forEach((v, i) => {
    if (v) {
      const value = groupDetail?.[v] || ''
      mockData.data[i]['value'] = typeof value === 'string' ? value : value?.[locale]
      if (v === 'AddressLine1') {
        const address = groupDetail?.['AddressLine2']?.[locale] || ''
        const city = groupDetail?.['City']?.[locale] || ''
        const state = groupDetail?.['State']?.[locale] || ''
        const zip = groupDetail?.['Zip'] || ''
        const country = groupDetail?.['Country']?.[locale] || ''
        mockData.data[i]['value'] = [value?.[locale], address, city, state, zip, country].filter((v) => v).join(', ')
      }
      if (['ContactPhoneNumber', 'PrimaryUserPhoneNumber'].includes(v)) {
        mockData.data[i]['value'] = formatPhoneNumber(value)
      }
    }
  })
  return mockData
}

const AccountDetail = () => {
  const classes = useStyles()
  const [locale] = useLocaleState()
  const redirectTo = useRedirect()
  const dispatch = useDispatch()
  const { selectedGroupId, groupDetail } = useSelector((state) => state.profile)
  const [data, setData] = useState(covertData(groupDetail, locale))
  const { permissions } = usePermissions()
  const { GlobalManageCustomers } = permissions || {}
  const getData = useCallback(async (gId) => {
    try {
      const result = await getGroup<GroupITF>({ gId })
      const handlerData = covertData(groupDetail, locale)
      setData(handlerData)
      dispatch(selectedGroup({ groupId: result.acId, groupName: result.GroupName[locale], group: result }))
    } catch (e) {
      // @ts-ignore
      SnackBar({ msg: e, type: 'error', duration: 3000 })
    }
  }, [])
  useEffect(() => {
    if (!GlobalManageCustomers && selectedGroupId) {
      getData(selectedGroupId)
    }
  }, [selectedGroupId, GlobalManageCustomers])

  const onEdit = () => {
    redirectTo(`/accountCreate/${selectedGroupId}`)
  }

  return (
    <Box className={classes.root}>
      <CommonForm {...data} />
      <Button className={classes.headerButton} onClick={onEdit}>
        EDIT
      </Button>
    </Box>
  )
}

export default AccountDetail
