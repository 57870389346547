import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  List,
  ListProps,
  TextField,
  useListContext,
  useRefresh,
  useUnselectAll,
  TopToolbar,
  Button,
  TextFieldProps,
  useRecordContext,
  Resource,
} from 'react-admin'
import Page from '@component/Page'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Drawer, IconButton, Switch, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Dialog from '@component/Modal/Dialog'
import { deleteSubscription, updateSubscription } from '@api'
import SnackBar from '@component/SnackBar'
import ActionDelete from '@mui/icons-material/Delete'
import SubscriptionCreate from '@pages/settings/SubscriptionCreate'
import AddIcon from '@mui/icons-material/Add'
import { Clear, Edit } from '@mui/icons-material'
import { useLocation } from 'react-router'
import { REPORT_TYPE } from '@constant/report'
import get from 'lodash/get'
import DatagridCustomize from '@component/List/DatagridCustomize'

const useStyles = makeStyles(() => {
  return {
    toolbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
      minHeight: 'auto',
    },
    title: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto',
      color: '#4E4D4D',
      marginLeft: 10,
    },
    action: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 18,
      background: '#FBFBFB',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: 6,
      marginBottom: 12,
      width: '100%',
      height: 60,
      boxSizing: 'border-box',
    },
    actionIcon: {
      color: '#466CB5',
      marginLeft: 20,
      fontSize: 16,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      textTransform: 'none',
    },
    paper: {
      width: 510,
    },
    btn: {
      borderRadius: 4,
    },
    left: {
      flex: 1,
    },
    select: {
      display: 'flex',
      alignItems: 'center',
    },
  }
})

interface SubscriptionListActionProps {
  onClickEdit?: (data: Record<string, string>) => void
}

const SubscriptionListAction: React.FC<SubscriptionListActionProps> = ({ onClickEdit }) => {
  const classes = useStyles()
  const { selectedIds = [], data, resource } = useListContext()
  const { selectedGroupId } = useSelector((state) => state.profile)
  const isOne = selectedIds.length === 1
  const isSelect = selectedIds.length >= 1
  const refresh = useRefresh()
  const unselectAll = useUnselectAll(resource)
  const onEdit = () => {
    const id = selectedIds[0]
    const record = data.find((item) => item.id === id)
    onClickEdit?.(record)
  }
  const unselect = () => {
    unselectAll()
  }
  useEffect(() => {
    unselectAll()
  }, [])
  const onDelete = () => {
    Dialog.show({
      title: 'Are you sure you want to delete this?',
      agreeText: 'Delete',
      onAgree: () => {
        deleteSubscription({ SubscriptionIds: selectedIds, GroupId: selectedGroupId })
          .then(() => {
            unselectAll()
            refresh()
          })
          .catch((err) => SnackBar({ msg: err }))
      },
    })
  }

  return (
    <TopToolbar className={classes.toolbar}>
      <Box className={classes.action}>
        <Box className={classes.left}>
          {!isSelect && <Typography className={classes.title}>Subscription Report</Typography>}
          {isSelect && (
            <Box className={classes.select}>
              <IconButton onClick={unselect}>
                <Clear htmlColor="#211F1F" style={{ fontSize: 20 }} />
              </IconButton>
              <Typography style={{ flex: 1 }}>{selectedIds.length} item selected</Typography>
            </Box>
          )}
        </Box>
        {isOne && (
          <Button label="Edit" className={classes.actionIcon} onClick={onEdit}>
            <Edit />
          </Button>
        )}
        {isSelect && (
          <Button label="Delete" className={classes.actionIcon} onClick={onDelete}>
            <ActionDelete />
          </Button>
        )}
      </Box>
    </TopToolbar>
  )
}

const OnOffField = (props: TextFieldProps & { gId?: string }) => {
  const record = useRecordContext(props)
  const { source = {}, gId } = props
  // @ts-ignore
  const value = get(record, source)
  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      await updateSubscription({ GroupId: gId, OnOff: checked, SubscriptionId: record.acId })
      // @ts-ignore
      record.OnOff = checked
    },
    [gId, record]
  )
  if (!props.source) return null
  return <Switch defaultChecked={value} onChange={onChange} />
}

const SubscriptionList = (props: ListProps) => {
  const classesInter = useStyles()
  const location = useLocation()
  const { bId, type } = useMemo(() => location.state || {}, [location.state])
  const { name } = useMemo(() => {
    return REPORT_TYPE.find((item) => item.id === type) || { name: '' }
  }, [type])
  const { selectedGroupId, user, selectedPropertyId } = useSelector((state) => state.profile)
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const [edit, setEdit] = useState()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const refresh = useRefresh()
  const open = Boolean(anchorEl)
  const hasBid = location.pathname === '/analytics/subscription'

  const id = open ? 'simple-popover' : undefined
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setEdit(undefined)
  }
  const onClickEdit = useCallback((data) => {
    setEdit(data)
    setAnchorEl(data)
  }, [])
  const onSuccess = () => {
    handleClose()
    refresh()
  }

  return (
    <Page
      Title={name || 'Subscriptions'}
      hasBack={!!type}
      action={
        <Button
          className={classesInter.btn}
          variant="contained"
          label="Add Subscriber"
          alignIcon={'right'}
          onClick={handleClick}
        >
          <AddIcon />
        </Button>
      }
    >
      <Drawer
        classes={{
          paper: classesInter.paper,
        }}
        id={id}
        ModalProps={{ keepMounted: false }}
        open={open}
        anchor="right"
        onClose={handleClose}
      >
        <SubscriptionCreate
          type={type}
          name={name}
          value={edit}
          onClose={handleClose}
          onSuccess={onSuccess}
          hasBid={hasBid}
        />
      </Drawer>
      <List
        {...props}
        filter={{ gId: currentGroupId, bId: hasBid ? bId || selectedPropertyId : undefined }}
        actions={<SubscriptionListAction {...props} onClickEdit={onClickEdit} />}
        perPage={10}
        sort={{ field: 'CreatedDate', order: 'DESC' }}
      >
        <DatagridCustomize optimized>
          <TextField source="ReportName" label="Dashboard/Report Name" />
          <TextField source="Frequency" label="Schedule" />
          <TextField source="Name" label="Name" />
          <TextField source="Email" label="Email" />
          <TextField source="JobTitle" label="Job Title" />
          <OnOffField source="OnOff" label="ON/OFF" gId={currentGroupId} />
        </DatagridCustomize>
      </List>
    </Page>
  )
}
const SubscriptionListReport = () => {
  return <Resource name="subscriptions" key="subscriptions" list={SubscriptionList} />
}

export default SubscriptionListReport
