import * as React from 'react'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { Box, Autocomplete, Checkbox, IconButton, Typography, TextField } from '@mui/material'
import { Edit, Clear } from '@mui/icons-material'
import Dialog from '@component/Modal/Dialog'
import {
  getRounds,
  downloadQrCodesZipFile,
  archiveRounds,
  GetPropertyContacts,
  saveRoundAssigner,
  copyRound,
} from '@api'
import { makeStyles } from '@mui/styles'
import { EquipmentsItem } from '../builder/engineer/EquipmentTable'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import SnackBar from '@component/SnackBar'
import Images from '@/assets/images'
import classnames from 'classnames'
import MultipleBuilding from '@/components/Modal/MultipleBuilding'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#fff',
    flex: 1,
    padding: 16,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 16,
  },
  title: {
    fontWeight: 600,
    fontSize: 23,
    marginBottom: 24,
  },
  tableWarper: {
    overflowX: 'auto',
    flex: 1,
    width: 0,
    borderRadius: '8px',
    boxShadow: 'inset -8px 0 8px -8px rgba(0, 0, 0, 0.25)',
  },
  table: {
    tableLayout: 'fixed',
    border: '1px solid #F5F5F5',
    overflow: 'hidden',
    overflowX: 'auto',
    boxShadow: 'inset -8px 0 0 #fff',
  },
  tableHead: {
    backgroundColor: '#F0F6FC',
  },
  cell: {
    fontWeight: 700,
    fontSize: 16,
    fontFamily: 'Roboto',
    color: '#211F1F',
    border: 'none',
    height: 48,
  },
  rowCell: {
    wordWrap: 'break-word',
  },
  color: {
    color: '#000',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 18,
    background: '#FBFBFB',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginBottom: 32,
    height: 60,
    boxSizing: 'border-box',
  },
  actionIcon: {
    color: '#466CB5',
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  indeterminate: {
    color: '#386BBF',
  },
  textField: {
    fontSize: 14,
    padding: '0 4px !important',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      background: '#F5F5F5',
    },
  },
  select: {
    background: '#F0F6FC',
  },
  sortIcon: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}))

const configQuestion = [
  { name: 'QrName', sortBy: 'Name', title: 'Round Name*', sort: true },
  { name: 'RoundTypeDisplayText.en', title: 'Round Type*', width: 200 },
  { name: 'Frequency', title: 'Frequency*' },
  { name: 'Schedule', title: 'Schedule*' },
  { name: 'InstallationNote', title: 'Installation Note', width: 265 },
  { name: 'AssignedTo', title: 'Assigned To', width: 190 },
]

const EngineeringRoundsList: React.FC<{ reload?: boolean; isPm?: boolean }> = ({ reload, isPm }) => {
  const navigate = useNavigate()
  const { selectedGroupId: gId, selectedPropertyId: bId } = useSelector((state) => state.profile)
  const classes = useStyles()
  const [list, setList] = useState<EquipmentsItem[]>([])
  const [getList, setGetList] = useState(false)
  const [contacts, setContacts] = useState<any[]>([])
  const [sort, setSort] = useState<any[]>([])
  const [showMultipleBuilding, setShowMultipleBuilding] = useState(false)
  const selectedIds = useMemo(() => list.filter((v) => v.checked).map((v) => v.RoundId), [list])
  const checkedAll = selectedIds.length > 0 && selectedIds.length === list.length
  const selectedLength = selectedIds.length

  const selectAll = (value: boolean) => {
    setList((data) => {
      data.forEach((v) => (v.checked = value))
      return [...data]
    })
  }
  const onCheck = (idx: number, value: boolean) => {
    setList((data) => {
      data[idx]['checked'] = value
      return [...data]
    })
  }

  const onEdit = () => {
    const RoundType = list.filter((v) => v.checked)?.[0]?.RoundType
    navigate(`/roundsEngineBuilder/${RoundType}/${selectedIds[0]}`)
  }
  const onDownLoad = () => {
    const QrCodes = list.filter((v) => v.checked).map((v) => v.QrId)
    downloadQrCodesZipFile({ QrCodes }).then((res: any) => {
      window.open(res?.ZipFile, '_blank')
    })
  }
  const onDownLoadPDF = () => {
    const equipmentsItem = list.filter((v) => v.checked)?.[0]
    if (equipmentsItem?.PdfPreview) {
      window.open(equipmentsItem.PdfPreview, '_blank')
    } else {
      SnackBar({ msg: 'Test Kit does not exist ' })
    }
  }
  const onDelete = () => {
    Dialog.show({
      title: 'Are you sure you want to delete this?',
      agreeText: 'Delete',
      onAgree: () => {
        archiveRounds({ RoundIds: selectedIds })
          .then(() => {
            setGetList((v) => !v)
          })
          .catch((e) => {
            SnackBar({ msg: e, duration: 3000 })
          })
      },
    })
  }
  const onAssignedTo = (UserId: string, RoundId: string) => {
    saveRoundAssigner({ UserId, RoundId }).then(() => {
      SnackBar({ msg: 'Success', type: 'success' })
      setGetList((v) => !v)
    })
  }
  const onSortClick = useCallback((v) => {
    setSort((prevState) => {
      const sort = prevState?.[1] === 'ASC' ? 'DESC' : 'ASC'
      return [v.sortBy || v.name, sort]
    })
  }, [])
  const onCopyRound = (DestBuildingIds: string[]) => {
    const RoundId = list.filter((v) => v.checked)?.[0]?.RoundId
    copyRound({ RoundId, DestBuildingIds }).then(() => {
      SnackBar({
        msg: 'The selected round are being copied to the destination building. Please verify.',
        type: 'success',
        duration: 3000,
      })
    })
  }
  useEffect(() => {
    GetPropertyContacts({
      filter: JSON.stringify({ bId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      setContacts(res.Contacts || [])
    })
  }, [bId])

  useEffect(() => {
    getRounds({ filter: JSON.stringify({ gId, bId, type: isPm ? 'PM' : '' }), sort: JSON.stringify(sort) }).then(
      (res: any) => {
        setList(res?.Rounds)
      }
    )
  }, [gId, bId, getList, reload, isPm, sort])
  console.log('sortsortsort', sort)
  return (
    <Box className={classes.paper} sx={{ margin: isPm ? '24px' : 0 }}>
      <Box className={classes.title}>{isPm ? 'Activate PM Library' : 'Rounds Engine'}</Box>

      <Box className={classes.action} style={{ display: selectedLength > 0 ? '' : 'none' }}>
        <IconButton onClick={() => selectAll(false)} style={{ padding: 4, marginRight: 8 }}>
          <Clear className={classes.color} style={{ fontSize: 20 }} />
        </IconButton>
        <Typography style={{ flex: 1 }}>{selectedLength} item selected</Typography>
        {selectedIds.length === 1 && (
          <Box onClick={onEdit} className={classes.actionIcon}>
            <Edit color="primary" style={{ marginRight: 6 }} />
            Edit
          </Box>
        )}
        {selectedIds.length === 1 && (
          <Box onClick={() => setShowMultipleBuilding(true)} className={classes.actionIcon}>
            <img src={Images.clone} style={{ marginRight: 6 }} alt="clone" />
            Clone To Another Building
          </Box>
        )}
        <Box onClick={onDownLoad} className={classes.actionIcon}>
          <img src={Images.iconPrintKit} style={{ marginRight: 6 }} alt="Download" />
          Download Print Kit
        </Box>
        {selectedIds.length === 1 && (
          <Box onClick={onDownLoadPDF} className={classes.actionIcon}>
            <img src={Images.iconPrintKit} style={{ marginRight: 6 }} alt="Download" />
            Download the Test Kit
          </Box>
        )}
        <Box onClick={onDelete} className={classes.actionIcon}>
          <img src={Images.iconDelete} style={{ marginRight: 6 }} alt="Delete" />
          Delete
        </Box>
        {showMultipleBuilding && <MultipleBuilding onClose={() => setShowMultipleBuilding(false)} onOk={onCopyRound} />}
      </Box>

      <Box style={{ display: 'flex' }}>
        <Box className={classes.tableWarper}>
          <Table className={classes.table}>
            <colgroup>
              <col width={80} />
              {configQuestion.map((v, i) => (
                <col key={i} width={v.width || 182} />
              ))}
            </colgroup>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell classes={{ root: classes.cell }}>
                  <Checkbox
                    checked={checkedAll}
                    onChange={() => selectAll(!checkedAll)}
                    color="primary"
                    indeterminate={selectedLength > 0 && !checkedAll}
                    classes={{ indeterminate: classes.indeterminate }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                {configQuestion.map((v) => (
                  <TableCell key={v.title} classes={{ root: classes.cell }}>
                    {v.sort ? (
                      <TableSortLabel
                        active={sort?.[0] === (v.sortBy || v.name)}
                        direction={(sort?.[0] === (v.sortBy || v.name) ? sort[1] : 'ASC').toLowerCase()}
                        onClick={() => onSortClick(v)}
                        classes={{ icon: classes.sortIcon }}
                      >
                        {v.title}
                      </TableSortLabel>
                    ) : (
                      v.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {list.map((data, idx) => {
                const { checked = false, Times, Period, Frequency, RoundType, RoundId } = data
                const { UserId } = data?.Assigner || {}
                return (
                  <TableRow
                    key={data?.RoundId as string}
                    onClick={() => {
                      navigate(`/roundsEngineBuilder/${RoundType}/${RoundId}`)
                    }}
                    className={classnames(classes.row, { [classes.select]: checked })}
                  >
                    <TableCell>
                      <Checkbox
                        checked={!!checked}
                        onChange={() => onCheck(idx, !checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>

                    {configQuestion.map((v, j) => {
                      const { name } = v
                      if (name === 'Schedule') {
                        if (Frequency === 'AdHoc') {
                          return <TableCell key={j} />
                        }
                        return <TableCell key={j}>{`${Times} times per ${Period}`}</TableCell>
                      }
                      if (name === 'AssignedTo') {
                        return (
                          <TableCell key={j} onClick={(e) => e.stopPropagation()}>
                            <Autocomplete
                              key={j}
                              disableClearable
                              value={contacts.find((item) => item.UserId === UserId) || null}
                              onChange={(e, newValue) => onAssignedTo(newValue?.UserId, data.RoundId)}
                              options={contacts}
                              classes={{ input: classes.textField }}
                              getOptionLabel={(option) => option?.FullName?.en || ''}
                              renderInput={(params) => <TextField {...params} label={''} />}
                            />
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={j} className={classes.rowCell}>
                          {get(data, name)}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(EngineeringRoundsList)
