import { useRecordContext, TextFieldProps } from 'react-admin'
import get from 'lodash/get'
import * as React from 'react'
import PhotoTip from '@component/Round/PhotoTip'

const ImageField = (props: TextFieldProps) => {
  const record = useRecordContext(props)
  if (!props.source) return null
  const value = get(record, props.source)
  return <PhotoTip photo={value} />
}

export default ImageField
