import React, { useCallback, useEffect, useRef, useState } from 'react'
import SnackBar from '@component/SnackBar'
import { getFunctionalityTypeDefaultTemplate } from '@api'
import { Loading } from 'ra-ui-materialui'
import { makeStyles } from '@mui/styles'
import { getDefaultData, setDefaultData } from '@utils/globalData'
import { QRCodeFunctionType } from '@/types'

const useStyles = makeStyles(() => ({
  loading: {
    marginTop: 10,
    height: 'unset',
  },
}))

const WithDefaultData = (WrappedComponent) => {
  function Hoc(props) {
    const [loadingDefault, setLoadingDefault] = useState(true)
    const { value, qrType, entity, buildingId } = props
    const [entityType, setEntityType] = useState('')
    const functionType = qrType?.FunctionalityType
    const initValueRef = useRef(value)
    const [defaultTemplate, setDefaultTemplate] = useState(initValueRef.current || getDefaultData(functionType))
    const valueTem = value || defaultTemplate
    const classes = useStyles()

    const getDefaultTemplate = useCallback(
      async (withNewLocation) => {
        try {
          const { groupId, onChange } = props
          if (!qrType) return
          setLoadingDefault(true)
          const result = await getFunctionalityTypeDefaultTemplate({
            gId: groupId,
            fType: functionType || '',
            qType: qrType.QrType || qrType.Name || '',
            eId: entity?.acId || '',
            eType: entity?.Location,
            bId: buildingId,
          })
          if (qrType.FunctionalityType === QRCodeFunctionType.Feedback) {
            result.Questions?.[0] &&
              Object.keys(result.Questions[0].QuestionText).map((i) => {
                const location = entity?.TypeDisplayText?.en ? `${entity?.TypeDisplayText?.en}?` : ''
                result.Questions[0].QuestionText[i] = `How was the ${location}`
              })
          }
          if (withNewLocation) {
            Object.assign(result, { withNewLocation: true })
          }
          onChange?.(Object.assign(result, { CanSave: true }))
          setEntityType(entity?.Location) //第一次执行完 entityType 为 undefined 或者为 非空字符串
          setLoadingDefault(false)
          setDefaultTemplate(result)
          setDefaultData(functionType, result)
        } catch (e) {
          SnackBar({ msg: e.message })
        }
      },
      [entity, qrType, buildingId]
    )

    useEffect(() => {
      if (
        initValueRef.current &&
        !(Object.keys(initValueRef.current).length === 1 && Object.keys(initValueRef.current)[0] === 'CanSave')
      )
        return
      getDefaultTemplate()
    }, [])
    useEffect(() => {
      if (initValueRef.current) return
      if (entity?.Location && entity.Location !== entityType && entityType !== '') getDefaultTemplate(true)
    }, [entity])
    if (!initValueRef.current && loadingDefault) {
      return <Loading className={classes.loading} />
    }
    return <WrappedComponent {...props} value={valueTem} />
  }

  return Hoc
}

export default WithDefaultData
