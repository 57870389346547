import { createSlice } from '@reduxjs/toolkit'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

const INITIAL_STATE = {
  // selectedGroupId: '',
  lang: 'en',
  theme: 'light',
  properties: [
    { BuildingId: '101', Name: 'Lincoln Yard' },
    { BuildingId: '102', Name: '333 North Green' },
  ],
}

let settingSlice = createSlice({
  name: 'setting',
  initialState: INITIAL_STATE,
  reducers: {
    // selectedProperty: function (state, action) {
    //     state.selectedGroupId = action.payload
    // },
    changeTheme: function (state, action) {
      state.theme = action.payload
    },
    changeLang: function (state, action) {
      state.lang = action.payload
    },
    resetReducer: function (state) {
      // state.selectedGroupId = ''
      state = INITIAL_STATE
    },
  },
})

const { actions: reducerActions, reducer: settingReducer } = settingSlice

const settingSliceSaga = createSliceSaga({
  name: 'setting',
  caseSagas: {
    changeTheme: function* () {},
  },
  sagaType: SagaType.TakeEvery,
})

const { actions: sagaActions, saga: settingSaga } = settingSliceSaga
const settingActions = { ...reducerActions, ...sagaActions }

export const { changeTheme, changeLang, resetReducer } = settingSlice.actions
export default settingSlice.reducer

export { settingSliceSaga, settingReducer, settingActions, settingSaga }
