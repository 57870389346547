import React, { useCallback, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import { ArchorTenantITF, TenantInfoITF } from '@/types/entity'
import { makeStyles } from '@mui/styles'
import uuid from 'react-uuid'
import { AddCircle } from '@mui/icons-material'
import useSetState from '@hooks/useSetState'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  add: {
    marginTop: 20,
    alignSelf: 'flex-start',
  },
  save: {
    width: 90,
    marginTop: 20,
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  iconBtn: {
    padding: 0,
  },
  netType: {
    marginTop: 20,
  },
}))
interface Props {
  data?: TenantInfoITF
  onChangeData?: (data: TenantInfoITF) => void
  onSave?: (data: { TenantInfo: TenantInfoITF }) => void
}
interface TenantItemProps {
  data: ArchorTenantITF
  index: number
  onChange?: (item: ArchorTenantITF, index: number) => void
}

const TenantItem: React.FC<TenantItemProps> = ({ data, index, onChange }) => {
  const classes = useStyles()
  const [state, setState] = useState(data || {})
  const { Name, Floor, SquareFootage } = state
  const onInputChange = useCallback(
    (value: string, name: string) => {
      setState((prevState) => {
        const cur = Object.assign({}, prevState, { [name]: value }) as ArchorTenantITF
        onChange?.(cur, index)
        return cur
      })
    },
    [onChange, index]
  )

  return (
    <Grid container rowSpacing={0} columnSpacing={2}>
      <Grid item xs={4}>
        <CustomTextField
          label={`Anchor Tenant  ${index + 1}`}
          value={Name}
          name="Name"
          fullWidth
          variant="outlined"
          disabled={false}
          placeholder="Enter here"
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomTextField
          label="Floor"
          name="Floor"
          fullWidth
          disabled={false}
          placeholder="Enter here"
          variant="outlined"
          value={Floor || ''}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomTextField
          label="Square ft."
          name="SquareFootage"
          fullWidth
          type="number"
          disabled={false}
          placeholder="Enter here"
          variant="outlined"
          value={(SquareFootage as unknown as string) || ''}
          onInputChange={onInputChange}
        />
      </Grid>
    </Grid>
  )
}
const TenantSurvey: React.FC<Props> = ({ data, onChangeData, onSave }) => {
  const classes = useStyles()
  const [state, setState] = useSetState<TenantInfoITF>({
    ...data,
    ArchorTenants: data?.ArchorTenants?.map((item) => ({ ...item, FrontItemKey: uuid() })),
  })
  const { ArchorTenants } = state
  const onChange = useCallback(
    (item: ArchorTenantITF, index: number) => {
      setState((prevState) => {
        const pre = prevState.ArchorTenants || []
        pre.splice(index, 1, item)
        const data = {
          ...prevState,
          ArchorTenants: pre,
        } as TenantInfoITF
        onChangeData?.(data)
        return data
      })
    },
    [onChangeData, setState]
  )
  const onAdd = useCallback(() => {
    setState((prevState) => {
      const data = {
        ...prevState,
        ArchorTenants: [...(prevState.ArchorTenants || []), { FrontItemKey: uuid() } as ArchorTenantITF],
      } as TenantInfoITF
      onChangeData?.(data)
      return data
    })
  }, [onChangeData])

  const onClick = useCallback(() => {
    onSave?.({ TenantInfo: state })
  }, [state])

  return (
    <Box className={classes.container}>
      {ArchorTenants?.map((item, index) => {
        return <TenantItem data={item} key={item.FrontItemKey} index={index} onChange={onChange} />
      })}
      <Button className={classes.add} onClick={onAdd}>
        <AddCircle style={{ marginRight: 8 }} />
        Add Tenant
      </Button>
      <Button variant="contained" classes={{ contained: classes.save }} onClick={onClick}>
        Save
      </Button>
    </Box>
  )
}

export default TenantSurvey
