import React, { FunctionComponent, useCallback, useImperativeHandle, useState } from 'react'
import { Box, Button, Grid, IconButton, Popover, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CustomTextField from '@component/Form/CustomTextField'
import { CloseRounded } from '@mui/icons-material'
import { isEmail } from '@utils/helpers'
import SnackBar from '@component/SnackBar'
import { emailQrCode } from '@api'

export interface EmailHandler {
  setAnchor: (anchor: HTMLElement | null) => void
}

interface Props {
  childRef: React.MutableRefObject<any>
  qrId?: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    borderRadius: 0,
    flexDirection: 'column',
    width: 500,
    height: 150,
    padding: 30,
  },
  email: {
    marginTop: 30,
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  root: {
    width: '100%',
  },
  btn: {
    position: 'absolute',
    bottom: 30,
    marginTop: 20,
  },
}))
const EmailHover: FunctionComponent<Props> = ({ childRef, qrId }) => {
  const classes = useStyles()
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const [email, setEmail] = useState<string>()
  useImperativeHandle(
    childRef,
    () => ({
      setAnchor: (anchor: HTMLElement | null) => setAnchor(anchor),
    }),
    [setAnchor]
  )
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value)
  }, [])
  const onClose = useCallback(() => {
    setAnchor(null)
  }, [])
  const onSend = useCallback(async () => {
    if (!isEmail(email)) {
      SnackBar({ msg: 'Invalid email format.' })
      return
    }
    if (!qrId) return
    try {
      await emailQrCode({ RecipientEmail: email, QrCodeId: qrId })
      setAnchor(null)
    } catch (e) {
      SnackBar({ msg: e })
    }
  }, [email, qrId])

  return (
    <Popover
      classes={{ paper: classes.container }}
      anchorEl={anchor}
      open={anchor !== null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <IconButton className={classes.close} onClick={onClose}>
        <CloseRounded />
      </IconButton>
      <CustomTextField
        label="To Email"
        className={classes.email}
        disabled={false}
        name="Email"
        placeholder="Enter here"
        onChange={handleChange}
        value={email}
      />
      <Button variant="contained" className={classes.btn} color="primary" onClick={onSend}>
        <Typography>Send</Typography>
      </Button>
    </Popover>
  )
}

export default EmailHover
