import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import { Datagrid, List, TextFieldProps } from 'react-admin'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { useRecordContext } from 'ra-core'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { savePrintProofs } from '@api'
import SnackBar from '@component/SnackBar'
import BackButton from '@component/BackButton/BackButton'
import PrintProofView from '@component/Modal/PrintProofView'
import { useLocation } from 'react-router'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F7F7F7',
  },
  dataGrid: {
    '& .RaDatagrid-rowCell': {
      border: 'none',
      padding: 0,
    },
  },
  list: {
    margin: '0 24px 24px',
    padding: 22,
    borderRadius: 8,
    backgroundColor: 'white',
    '& .MuiToolbar-root': {
      minHeight: 'auto',
      justifyContent: 'flex-start',
    },
    '& .RaList-content': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '& .RaDatagrid-thead': {
      border: 'none',
    },
    '& .RaDatagrid-tableWrapper': {
      border: 'none',
    },
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 6,
    border: '1px solid #E8E8E8',
    marginBottom: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid #E8E8E8',
  },
  location: {
    color: '#000',
    fontWeight: 600,
    flex: 1,
  },
  qrType: {
    flex: 1,
    marginLeft: 24,
    color: '#000',
    fontWeight: 600,
  },
  noData: {
    padding: 16,
    textAlign: 'center',
  },
  controll: {
    width: 150,
  },
  input: {
    backgroundColor: 'white',
    height: 40,
    width: 100,
  },
  table: {},
  img: {},
  other: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#466CB5',
    textAlign: 'center',
    cursor: 'pointer',
  },
}))

const CONFIG = [
  { title: 'Selection', key: 'Selected', width: 200 },
  { title: 'Quantity', key: 'Quantity' },
  { title: 'Surface', key: 'Surface' },
  { title: 'Substrate', key: 'Substrate' },
  { title: 'Quality', key: 'Quality' },
  { title: 'Shape', key: 'Shape' },
  { title: 'Size (in)', key: 'Size', style: { minWidth: '100px' } },
  { title: 'Orientation', key: 'Orientation' },
  { title: 'Color', key: 'Color' },
  { title: 'Price', key: 'Price', prefix: '$' },
  { title: 'Template', key: 'Template' },
]

interface NumberInputProps {
  value: number
  onChange?: (value?: number) => void
}
const NumberInput: React.FC<NumberInputProps> = ({ value, onChange }) => {
  const classes = useStyles()
  const [showValue, setShowValue] = useState<number | undefined | string>(
    value && !isNaN(Number(value)) ? value : undefined
  )
  const [key, setKey] = useState('test')
  const [focused, setFocused] = useState(false)
  return (
    <TextField
      value={showValue}
      type="number"
      key={key}
      focused={focused}
      InputProps={{
        classes: {
          root: classes.input,
        },
      }}
      onChange={(event) => {
        const { value } = event.target
        if (!value) {
          setShowValue(undefined)
          onChange?.(undefined)
        } else if (!isNaN(Number(value)) && Number(value) > 0) {
          setShowValue(Number(value))
          onChange?.(Number(value))
        } else {
          setKey('key' + Math.random())
          setFocused(true)
        }
      }}
    />
  )
}
const PrintSetting: React.FC<TextFieldProps & { gId: string; bId: string }> = (props) => {
  const record = useRecordContext(props)
  const { gId, bId } = props
  const classes = useStyles()
  const { QrName = '', PrintSetting, ImageUrl } = record
  const hasData = PrintSetting?.length > 0
  const onSave = useCallback(async () => {
    let noData = false
    const printSettings = PrintSetting.filter((item) => {
      if (item.Selected && !item.Quantity) {
        noData = true
      }
      return !!item.Selected
    }).map((item) => {
      return {
        Quantity: item.Quantity,
        SettingId: item.SettingId,
        acId: item.acId,
      }
    })
    if (noData) {
      SnackBar({ msg: 'Please input Quantity' })
      return
    }
    savePrintProofs({ GroupId: gId, BuildingId: bId, QrName, PrintSettings: printSettings })
      .then((res) => {
        SnackBar({ msg: 'Successfully saved', type: 'success' })
      })
      .catch((err) => {
        SnackBar({ msg: 'Failed to save' })
      })
  }, [PrintSetting, gId, bId, QrName])
  const [showOther, setShowOther] = useState(false)
  return (
    <div className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.location}>{`QR Name: ${QrName}`}</Typography>
        {hasData && (
          <Button variant="contained" onClick={onSave}>
            SAVE
          </Button>
        )}
      </Box>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {CONFIG.map((item) => {
                return (
                  <TableCell key={item.title} style={item.style}>
                    {item.title}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(PrintSetting || []).map((row: any, i) => {
              if (!showOther && i > 1) return null
              return (
                <>
                  {i === 1 ? (
                    <TableRow key="other">
                      <TableCell
                        key={'Other Options'}
                        component="th"
                        scope="row"
                        colSpan={CONFIG.length}
                        className={classes.other}
                        onClick={() => setShowOther(!showOther)}
                      >
                        Other Options
                        {showOther ? (
                          <ExpandLess htmlColor="#466CB5" style={{ verticalAlign: 'middle' }} />
                        ) : (
                          <ExpandMore htmlColor="#466CB5" style={{ verticalAlign: 'middle' }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  <TableRow key={row.name} style={{ display: !showOther && i === 1 ? 'none' : '' }}>
                    {CONFIG.map((item) => {
                      const { key, title } = item
                      if (key === 'Template') {
                        return (
                          <TableCell key={title} component="th" scope="row">
                            {row.PrintTemplateImage ? (
                              <Button
                                key={title}
                                variant="outlined"
                                onClick={() => {
                                  PrintProofView.show({
                                    url: row.PrintTemplateImage,
                                    size: row.Size,
                                    qrImg: ImageUrl,
                                    orientation: row.Orientation,
                                  })
                                }}
                              >
                                VIEW
                              </Button>
                            ) : null}
                          </TableCell>
                        )
                      }
                      if (key === 'Selected') {
                        const value = row.Default === 'Y' ? 'Best Practice' : `Option ${i + 1}`
                        return (
                          <TableCell key={title} component="th" scope="row">
                            <FormControlLabel
                              control={<Checkbox defaultChecked={!!row.Selected} />}
                              label={value}
                              className={classes.controll}
                              onChange={(event: React.SyntheticEvent, checked: boolean) => {
                                row.Selected = checked
                              }}
                            />
                          </TableCell>
                        )
                      }
                      if (key === 'Quantity') {
                        return (
                          <TableCell key={title} component="th" scope="row">
                            <NumberInput
                              value={row[key]}
                              onChange={(value) => {
                                row.Quantity = value
                              }}
                            />
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={title} component="th" scope="row">
                          {row[key] ? `${item.prefix || ''}${row[key]}` : ''}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
        {!hasData && <Typography className={classes.noData}>No print setting available</Typography>}
      </TableContainer>
    </div>
  )
}
const PrintProof: React.FC = () => {
  const classes = useStyles()
  const { selectedGroupId: gId, selectedPropertyId: bId } = useSelector((state) => state.profile)
  const location = useLocation()
  const { qrType } = useMemo(() => location.state || {}, [location.state])
  const title = qrType === 'EquipmentInspection' ? 'Equipment/Location Library' : 'QR Library'
  return (
    <Box className={classes.root}>
      <BackButton title={`Back to ${title}`} />
      <List
        className={classes.list}
        filter={{ bId, gId, qrtype: qrType }}
        perPage={25}
        exporter={false}
        actions={<Typography sx={{ marginBottom: '24px' }}>Print Proof</Typography>}
        title={'Welcome to the administration'}
        sort={{ field: 'ModifiedDate', order: 'ASC' }}
        empty={false}
      >
        <Datagrid hover={false} header={() => <div></div>} bulkActionButtons={false} className={classes.dataGrid}>
          <PrintSetting source="Type" label="Contact Name" bId={bId} gId={gId} />
        </Datagrid>
      </List>
    </Box>
  )
}

export default PrintProof
