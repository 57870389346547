import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import CustomTextField, { CustomTextFieldProps } from './CustomTextField'
import Upload, { UploadProps } from './Upload'
import SectionLabel, { SectionLabelProps } from './SectionLabel'

// import Image from "../Image";

export enum FORM_TYPE {
  section = 'section',
  textFiled = 'textFiled',
  upload = 'upload',
}

const useStyles = makeStyles(() => {
  return {
    page: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    headerImg: {
      height: 182,
    },
    formPage: {},
  }
})

export interface CommonItemPros {
  title?: string
  fromType: FORM_TYPE
}

export interface CommonFormPros {
  text?: string
  imgUrl?: string
  data: (CommonItemPros & (CustomTextFieldProps | SectionLabelProps | UploadProps))[]
}

const mapTypeToComp: { [key in FORM_TYPE]: any } = {
  [FORM_TYPE.textFiled]: CustomTextField,
  [FORM_TYPE.upload]: Upload,
  [FORM_TYPE.section]: SectionLabel,
}
const CommonForm: React.FC<CommonFormPros> = ({ imgUrl, data }) => {
  const classes = useStyles()
  return (
    <Box className={classes.page}>
      {imgUrl && <img className={classes.headerImg} src={imgUrl} loading="lazy" />}
      {data.map((item, index) => {
        const { fromType, ...props } = item
        const Comp = mapTypeToComp[fromType]
        if (!Comp) return null
        return <Comp {...props} key={index} />
      })}
    </Box>
  )
}

export default CommonForm
