import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { Portfolio } from '@activate-inc/activate-ui'
import { FormDataContext } from '../../context/FormDataContext'

const PortfolioWrapper = ({ isHubPanel, template }) => {
  const {
    portfolioData: { Items },
  } = useContext(FormDataContext)

  return (
    <Box sx={{ mt: 2, width: 320, height: '100%' }}>{Items && <Portfolio items={Items} handleOpen={() => {}} />}</Box>
  )
}

export default PortfolioWrapper
