import React, { useEffect, useState, useContext, useCallback } from 'react'
import { getQrStylesImages } from '@api/index'
import { Box, Typography } from '@mui/material'
import { QRCodeConfigEnum, QRCodeType } from '../../types'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ImageUpload from '@component/Image/ImageUpload'
import { toBase64 } from '@/utils'
import { uploadBase64 } from '@/apis'
import { QrCodeInfo } from '@/types/entity'
import { CommonQRCode, CustomEyeForm } from '@/qrcodes/QRCodeConfig'
import CardActionArea from '@mui/material/CardActionArea'
import QRCodeContext from '@/qrcodes/QRCodeContext'
import CONFIG from '@constant/config'
interface QRCodeStyleCardProps {
  id: string
  selectedId?: string
  templateImg: string
  backgroundImg: string
  handleClick: (id: string, backgroundImg: string, qrType: QRCodeType) => void
  qrTypeStyle: QRCodeType
  fileType: string
}

const QRCodeStyleCard: React.FC<QRCodeStyleCardProps> = ({
  id,
  selectedId,
  templateImg,
  backgroundImg,
  handleClick,
  qrTypeStyle,
}) => {
  const classes = useStyles()
  const [errored, setErrored] = React.useState(false)
  const onError = () => {
    if (!errored) {
      setErrored(true)
    }
  }

  return (
    <Card className={id === selectedId ? classes.selected : classes.unselected}>
      <CardActionArea onClick={() => handleClick(id, backgroundImg, qrTypeStyle)}>
        <CardContent className={classes.cardContent}>
          <img
            id="preview"
            src={templateImg}
            onError={onError}
            style={{
              width: 150,
              height: 150,
              backgroundSize: 'contain',
            }}
          />
          <Typography className={classes.templateTitle}>{qrTypeStyle} Style</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

interface Props {
  gId: string
  bId: string
  status?: string
  isEdit?: boolean
  qrInfo?: QrCodeInfo
}

const PickADesign: React.FC<Props> = ({ gId, bId, status, isEdit = false, qrInfo }) => {
  const classes = useStyles()
  const [list, setList] = React.useState([])
  const { getOptionValue, setOptionValue } = useContext(QRCodeContext)
  const [selectedId, setSelectedId] = useState<string>('')
  const fileType = getOptionValue(QRCodeConfigEnum.FileType)
  const [qrCodeType, setQRCodeType] = React.useState(QRCodeType.Activate)
  const [qrLogo, setQRLogo] = useState<string>()
  const cleanup = () => {
    setOptionValue(QRCodeConfigEnum.LogoImage, '')
    setQRLogo('')
  }
  const handleClick = useCallback((id: string, backgroundImg: string, qrType: QRCodeType) => {
    setSelectedId(id)
    setQRCodeType(qrType)
    setOptionValue(QRCodeConfigEnum.QRType, qrType)
    setOptionValue(QRCodeConfigEnum.QrStyle, qrType)
    if (backgroundImg) {
      setOptionValue(QRCodeConfigEnum.LogoImage, backgroundImg)
      setQRLogo(backgroundImg)
    } else {
      cleanup()
    }
  }, [])

  const handleQRLogoChange = async (event: any) => {
    const images = event.target.files as File[]
    if (images[0]) {
      const base64Image = await toBase64(images[0])
      const s3Image: any = await uploadBase64({
        base64Image,
        fileName: `logo/qrcode/temp/${images[0].name}`,
        bucket: CONFIG.s3_Bucket,
      })
      setQRLogo(s3Image.imageUrl)
      setOptionValue(QRCodeConfigEnum.LogoImage, s3Image.imageUrl)
    }
  }

  const fetchStyleList = async (buildingId: string) => {
    if (!buildingId) return
    const result: any = await getQrStylesImages({ gId: gId, bId: buildingId })
    const data = result.map((item: any, index: number) => {
      return {
        styleId: `${item?.Key}_${index}`,
        exampleImg: item?.Url,
        bId: buildingId,
        qrType: item?.Key,
      }
    })
    setList(data)
  }

  useEffect(() => {
    fetchStyleList(bId)
  }, [bId])

  useEffect(() => {
    if (list && list.length > 0) {
      if (status) {
        const styleOne: any = list.filter((item) => {
          // @ts-ignore
          return item?.styleId.includes(status)
        })?.[0]
        setSelectedId(styleOne?.styleId)
        setQRCodeType(styleOne?.qrType)
        setOptionValue(QRCodeConfigEnum.QRType, styleOne?.qrType)
        setOptionValue(QRCodeConfigEnum.QrStyle, styleOne?.qrType)
        if (qrInfo?.QrConfig) {
          const qrConfig = qrInfo.QrConfig
          setQRLogo(qrConfig?.logo)
          setOptionValue(QRCodeConfigEnum.BGColor, qrConfig?.bgColor)
          setOptionValue(QRCodeConfigEnum.FillColor, qrConfig?.bodyColor)
          setOptionValue(QRCodeConfigEnum.Size, qrConfig?.size)
          setOptionValue(QRCodeConfigEnum.BodyShape, qrConfig?.body)
          setOptionValue(QRCodeConfigEnum.EyeFrameShape, qrConfig?.eye)
          setOptionValue(QRCodeConfigEnum.EyeBallShape, qrConfig?.eyeBall)
          setOptionValue(QRCodeConfigEnum.EyeColor, qrConfig?.eye1Color)
        }
      } else {
        const imgList = list.filter((item) => {
          // @ts-ignore
          return item.qrType !== 'Custom'
        })
        const { styleId, exampleImg, backgroundImg, qrType } = imgList.slice(-1)?.[0] || {}
        handleClick(styleId, backgroundImg, qrType)
      }
    }
  }, [list, status, handleClick])

  return (
    <Box className={classes.cardContainer}>
      {list.map(({ styleId, exampleImg, bId, backgroundImg, qrType }) => (
        <QRCodeStyleCard
          key={styleId}
          selectedId={selectedId}
          id={styleId}
          templateImg={exampleImg}
          handleClick={handleClick}
          backgroundImg={backgroundImg}
          qrTypeStyle={qrType}
          fileType={fileType}
        />
      ))}
      {qrCodeType === QRCodeType.Custom && (
        <Box style={{ position: 'relative', display: 'flex', width: '100%', flexWrap: 'wrap' }}>
          {isEdit && <Box className={classes.mask}></Box>}
          <Box className={classes.commonBox}>
            <Typography className={classes.imageUploadLabel}>QR Logo File:</Typography>
            <ImageUpload
              disabled={isEdit}
              type="qrcodelogo"
              imgWidth={68}
              imgHeight={68}
              src={qrLogo}
              handleImageChange={handleQRLogoChange}
              cleanup={cleanup}
            />
          </Box>
          <Box>
            <CommonQRCode getOptionValue={getOptionValue} setOptionValue={setOptionValue} />
          </Box>
          <CustomEyeForm getOptionValue={getOptionValue} setOptionValue={setOptionValue} />
        </Box>
      )}

      <Box className={classes.emptyCard} />
    </Box>
  )
}

export default PickADesign

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  selected: {
    border: '2px solid #386BBF',
  },
  emptyCard: {
    width: 150,
    height: 0,
  },
  unselected: {
    border: 'none',
    boxShadow: 'none',
  },
  cardContent: {
    width: 150,
    padding: '0px 0px 0px 0px',
    boxSizing: 'border-box',
  },
  templateTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#386BBF',
    textAlign: 'center',
  },
  commonBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // maxWidth: 450
  },
  mask: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    zIndex: 100,
  },
  imageUploadLabel: {
    width: 115,
  },
}))
