import React, { useCallback, useEffect, useMemo, useState, memo } from 'react'
import { Box, Button, Grid, IconButton, Table, TableBody, TableContainer, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { SaveAlt } from '@mui/icons-material'
import { MaterialKey, MaterialObj, MaterialOptions } from '@constant/qrcode'
import { QrCodeInfo } from '@/types/entity'
import { generatePrintKit, getPrintKits } from '@api'
import { useSelector } from 'react-redux'
import { QRStatusType } from '@/types'
import MenuItem from '@mui/material/MenuItem'
import SnackBar from '@component/SnackBar'
import { format } from 'date-fns'
import { getCachePrintTemplates } from '@utils/qrUtil'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#386BBF',
    fontSize: 16,
    marginTop: 50,
    marginBottom: 12,
  },
  textField: {
    width: '100%',
    border: 'none',
  },
  select: {
    maxHeight: 300,
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  cellInputRoot: {
    borderRadius: 4,
  },
  cellInput: {
    height: 30,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellText: {
    color: '#676767',
    fontSize: 12,
  },
  header: {
    backgroundColor: '#edf0f5',
  },
  headerTitle: {
    color: '#676767',
    boxSizing: 'border-box',
    fontWeight: 'normal',
    fontSize: 14,
  },
  row: {
    '&:last-child': {
      cell: {
        backgroundColor: 'red',
      },
    },
  },
  btn: {
    margin: 30,
    alignSelf: 'center',
    height: 34,
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#1976D2',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#1976D2',
    },
    '&.Mui-disabled': {
      color: 'white',
      opacity: 0.5,
    },
  },
}))
const getConfigData = (option: any) => {
  const configData = [
    { name: 'Quantity', title: 'Quantity' },
    { name: 'Material', title: 'Material', option: MaterialOptions, width: 150 },
    { name: 'Size', title: 'Size', option: [] },
    { name: 'Orientation', title: 'Orientation', option: ['Landscape', 'Portrait'] },
    { name: 'Color', title: 'Color', option: ['Light Gray', 'Light Blue', 'Dark Blue', 'Dark Gray'] },
    {
      name: 'PrintTemplate',
      title: 'Print Template',
      option: option.PrintTemplate || [],
      isObj: true,
      valueKey: 'Code',
      displayKey: 'FileName',
      width: 200,
    },
    { name: 'FileFormat', title: 'File Format', option: ['png', 'svg', 'eps', 'pdf'] },
  ]
  const requiredMsg = configData
    .filter((v) => v.title.includes('*'))
    .map((item) => item.title)
    .join(',')
  return {
    configData,
    requiredMsg,
  }
}

interface PrintKitPanelProps {
  configData: Array<MyObject>
  qrId: string
  gId: string
  signCode: string
  onGenerateSuccess: () => void
  language: LanguageType
  disabledEdit?: boolean
}

interface MyObject {
  [key: string]: any
}

const PrintKitPanel: React.FC<PrintKitPanelProps> = ({
  configData,
  signCode,
  qrId,
  gId,
  onGenerateSuccess,
  disabledEdit,
  language,
}) => {
  const classes = useStyles()
  const [showForm, setShowForm] = useState(false)
  const initData = useMemo(
    () => ({
      Quantity: '',
      Material: '3mm Sintra',
      Size: '5 × 8',
      Orientation: 'Landscape',
      Color: 'Light Gray',
      PrintTemplate: '',
      FileFormat: 'eps',
    }),
    []
  )
  const [data, setData] = useState<MyObject>(initData)
  const { Material } = data
  const onGenerate = useCallback(async () => {
    if (!showForm) {
      setShowForm(true)
      return
    }
    const { label, ...extra } = data
    const params = { ...extra }
    Object.assign(params, { QrCodeId: qrId, GroupId: gId })
    try {
      const result = await generatePrintKit(params)
      onGenerateSuccess()
      setShowForm(false)
      setData(initData)
    } catch (error) {
      // @ts-ignore
      SnackBar({ msg: error })
    }
  }, [showForm, data, gId, qrId])
  const handleChange = useCallback((name, rowValue, valueKey, displayKey) => {
    // @ts-ignore
    const value = typeof rowValue === 'string' ? rowValue : rowValue[valueKey || 'Name']
    // @ts-ignore
    const displayText =
      typeof rowValue === 'string' ? rowValue : rowValue[displayKey]?.[language] || rowValue[displayKey]
    setData((pre) => {
      if (name === 'Quantity' && !/^[0-9]*$/.test(value)) {
        return pre
      }
      pre[name] = value
      if (!pre.label) pre.label = {}
      pre.label[name] = displayText
      const { Material } = pre

      if (name === 'Material') {
        pre['Size'] = MaterialObj[Material as MaterialKey]['default']
      }
      return { ...pre }
    })
  }, [])
  return (
    <Box className={classes.container}>
      <Button onClick={onGenerate} className={classes.btn} disabled={disabledEdit}>
        Generate Print Kit
      </Button>
      {showForm && (
        <Grid container spacing={2}>
          {configData.map((v, j) => {
            const { name, option = [], isObj, title, disabled, valueKey, displayKey } = v
            let options = option
            if (name === 'Size') {
              const key = Material as MaterialKey
              options = MaterialObj[key]['option']
            }
            // @ts-ignore
            const value = isObj ? options.find((item) => item[valueKey || 'Name'] === data[name]) : data[name]
            return (
              <Grid item key={name} xs={6}>
                <TextField
                  name={name}
                  select={options.length > 0}
                  value={value}
                  variant="outlined"
                  label={title}
                  disabled={disabled}
                  placeholder={title}
                  className={classes.textField}
                  SelectProps={{
                    displayEmpty: true,
                    classes: {
                      select: classes.select,
                    },
                    type: 'search',
                    MenuProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cellInputRoot,
                      input: classes.cellInput,
                    },
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    handleChange(event.target.name, event.target.value, valueKey, displayKey)
                  }
                >
                  {options.map((v: any) => {
                    // @ts-ignore
                    const label = typeof v === 'string' ? v : v[displayKey]?.[language] || v[displayKey]
                    const key = typeof v === 'string' ? v : v[valueKey || 'Name']
                    return (
                      <MenuItem key={key} value={v}>
                        {label}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

interface Props {
  qrInfo?: QrCodeInfo
  disabledEdit?: boolean
}

const PrintKit: React.FC<Props> = ({ qrInfo, disabledEdit }) => {
  const classes = useStyles()
  const language = 'en'
  const [option, setOption] = useState({})
  const [data, setData] = useState<Array<any>>([])
  const { selectedGroupId, buildingCode } = useSelector((state) => state.profile)
  const { configData, requiredMsg } = useMemo(() => {
    return getConfigData(option)
  }, [data, option])
  const getPrintKit = useCallback(async () => {
    const result = await getPrintKits<Array<any>>({ qId: qrInfo?.acId, gId: selectedGroupId })
    setData(result || [])
  }, [selectedGroupId, qrInfo?.acId])

  const onGenerateSuccess = useCallback(() => {
    getPrintKit()
  }, [])

  useEffect(() => {
    getPrintKit()
  }, [])
  useEffect(() => {
    if (!qrInfo?.Type) return
    getCachePrintTemplates(qrInfo.Type, qrInfo.Entity?.Type).then((res: any) => {
      setOption((value) => Object.assign({}, value, { PrintTemplate: res || [] }))
    })
  }, [language, qrInfo])
  if (!qrInfo) return null
  const signCode = `${buildingCode}-${qrInfo.Floor}-${qrInfo.Entity?.Type || ''}`
  return (
    <Box>
      <PrintKitHistory data={data} />
      <PrintKitPanel
        disabledEdit={disabledEdit}
        configData={configData}
        signCode={signCode}
        qrId={qrInfo?.acId}
        language={language}
        gId={selectedGroupId}
        onGenerateSuccess={onGenerateSuccess}
      />
    </Box>
  )
}

export const PrintKitHistory: React.FC<{ data: any[] }> = memo(({ data }) => {
  const classes = useStyles()
  const onDownload = (zipFile: string) => {
    if (!zipFile) return
    window.open(zipFile, '_blank')
  }
  if (data?.length === 0) return null

  return (
    <>
      <Typography className={classes.title}>Print Kit History</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell width={120} className={classes.headerTitle}>
                Print Kit Date
              </TableCell>
              <TableCell align="left" className={classes.headerTitle}>
                S/K/U
              </TableCell>
              <TableCell width={150} align="right" className={classes.headerTitle}>
                Download Zip File
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, i) => (
              <TableRow key={row.acId} classes={{ root: classes.row }}>
                <TableCell align="right" classes={{ root: classes.cellText }}>
                  {format(row.CreatedDate, 'MM/dd/yyyy')}
                </TableCell>
                <TableCell align="left" classes={{ root: classes.cellText }}>
                  {row.SKU}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => onDownload(row.ZipFile)}>
                    <SaveAlt htmlColor="#333" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
})
PrintKitHistory.displayName = 'PrintKitHistory'

export default PrintKit
